// took from
// blue-stack-libs/catalog-grpc-libs/js/catalog/catalog_pb.d.ts

export enum AssetKind {
  UNDEFINED = -1,
  AWS_EC2 = 0,
  AZURE_VM = 1,
  VM_WARE_VM = 2,
  AWS_EBS = 3,
  AWS_EFS = 4,
  GENERIC_HOST = 5,
  GENERIC_FS = 6,
  AWS_S3 = 7,
  AWS_S3_OBJECTS = 8,
}
