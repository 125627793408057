import React, { useCallback, useState } from 'react'
import BaseModal from '@components-composite/modals/BaseModal'
import ConfigureViewForm from '@components-composite/configure-view/ConfigureViewForm'
import { EngineCallbackPure } from '@lib/engine-types'
import CustomTabs from '@components-simple/custom-tab/CustomTabs'
import MaterialTab from '@components-simple/material-tab/MaterialTab'
import { Tab } from '@mui/material'

interface Props {
  show: boolean
  onCancel: EngineCallbackPure
}

function ConfigureViewModal({ show, onCancel }: Props) {
  const [tab, setTab] = useState<number>(0)
  const onCancelCallback = useCallback(() => {
    onCancel()
  }, [])

  return (
    <BaseModal className="modalBigV2" onBgClick={onCancelCallback} show={show}>
      <div className="modalHeaderWrapV2">
        <CustomTabs value={tab} onChangeTab={setTab}>
          <Tab label="General" />
        </CustomTabs>
      </div>

      <MaterialTab value={tab} index={0}>
        <div className="modalHeaderWrapV2">
          <div className="mhTitle">Possible demo mods</div>
        </div>
        <ConfigureViewForm onCancel={onCancelCallback} />
      </MaterialTab>
    </BaseModal>
  )
}

export default ConfigureViewModal
