import { AuthProviderValue } from './types'

export const LOGO_PATH_PREFIX = '/images/auth-providers'

export const LOGO_MAP: { [key in AuthProviderValue]: string } = {
  [AuthProviderValue.Undefined]: 'empty',
  [AuthProviderValue.UsernamePassword]: 'empty',
  [AuthProviderValue.Google]: 'google',
  [AuthProviderValue.Microsoft]: 'microsoft',
}

export const LABELS_MAP: Record<AuthProviderValue, string> = {
  [AuthProviderValue.Undefined]: 'Undefined',
  [AuthProviderValue.UsernamePassword]: 'Username-Password',
  [AuthProviderValue.Google]: 'Google OAuth 2',
  [AuthProviderValue.Microsoft]: 'Windows Live',
}
