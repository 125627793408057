"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMockGenericHostAttributes = exports.GenericHostModel = void 0;
const threat_status_model_1 = require("./threat-status.model");
class GenericHostModel extends threat_status_model_1.AssetWithThreat {
    id;
    tenantName;
    accountId;
    region;
    cloudConnectorId;
    cloudConnectorAssetId;
    hostName;
    tags;
    createdAt;
    updatedAt;
    coveredByPolicies;
    associatedRetentionPolicies;
    coveredByPausedPolicies;
    osKind;
    cpuCores;
    constructor(attrs) {
        super(attrs);
        this.id = attrs.id;
        this.accountId = attrs.accountId;
        this.region = attrs.region;
        this.tenantName = attrs.tenantName;
        this.cloudConnectorId = attrs.cloudConnectorId;
        this.cloudConnectorAssetId = attrs.cloudConnectorAssetId;
        this.hostName = attrs.hostName;
        this.tags = attrs.tags;
        this.createdAt = attrs.createdAt;
        this.updatedAt = attrs.updatedAt;
        this.threatStatus = attrs.threatStatus;
        this.coveredByPolicies = attrs.coveredByPolicies;
        this.associatedRetentionPolicies = attrs.associatedRetentionPolicies;
        this.coveredByPausedPolicies = attrs.coveredByPausedPolicies;
        this.osKind = attrs.osKind;
        this.cpuCores = attrs.cpuCores;
    }
    getId() {
        return this.id;
    }
    getAccountId() {
        return this.accountId;
    }
    getRegion() {
        return this.region;
    }
    getTenantName() {
        return this.tenantName;
    }
    getCloudConnectorId() {
        return this.cloudConnectorId;
    }
    getCloudConnectorAssetId() {
        return this.cloudConnectorAssetId;
    }
    getHostName() {
        return this.hostName;
    }
    getTags() {
        return Array.from(this.tags);
    }
    getTagsMap() {
        return this.tags;
    }
    getCreatedAt() {
        return this.createdAt;
    }
    getUpdatedAt() {
        return this.updatedAt;
    }
    getCoveredByPolicies() {
        return this.coveredByPolicies;
    }
    isCoveredByPolicy() {
        return this.getCoveredByPolicies().length > 0;
    }
    getAssociatedRetentionPolicies() {
        return this.associatedRetentionPolicies;
    }
    getParsedCloudProviderAssetId() {
        return (this.cloudConnectorAssetId?.split(':')?.pop() ??
            this.cloudConnectorAssetId ??
            '');
    }
    isProtected() {
        return this.isLastRecoveryPointId() && this.isCoveredByPolicy();
    }
    getSize() {
        return '';
    }
    getVpcId() {
        return '';
    }
    isCoveredByPausedPolicies() {
        return this.coveredByPausedPolicies.length > 0;
    }
    getVolumeType() {
        return '';
    }
    getCloudProviderAssetId() {
        return this.getCloudConnectorAssetId();
    }
    getName() {
        return this.getHostName();
    }
    getOsKind() {
        return this.osKind;
    }
    getCpuCores() {
        return this.cpuCores;
    }
    // temporary solution until new Inventory models are implemented
    getThreats() {
        return 4444;
    }
    // temporary solution until new Inventory models are implemented
    getLastScan() {
        return Date.now();
    }
    // temporary solution until new Inventory models are implemented
    getLastBackup() {
        return Date.now();
    }
    getAssetType() {
        return 'Generic Host';
    }
}
exports.GenericHostModel = GenericHostModel;
function createMockGenericHostAttributes(currentId) {
    const region = ['us-east-1', 'us-east-2', 'eu-central-1', 'ap-southeast-1'][Math.floor((Math.random() * 10) % 4)] || 'us-east-1';
    const account = (Math.random() + 1).toString(10).substring(6);
    const hostName = 'generic-host' + currentId.toString();
    const policy = 'Policy' + Math.floor(Math.random() * 9).toString();
    const retentionPolicy = 'RetentionPolicy' + Math.floor(Math.random() * 9).toString();
    const pausedPolicy = 'PausedPolicy' + Math.floor(Math.random() * 9).toString();
    const osKind = [0, 1, 2][Math.floor((Math.random() * 10) % 3)] || 0;
    return {
        id: 'generic-host-' + currentId.toString(),
        tenantName: 'dev.staging.elastio.us',
        accountId: (Math.random() + 1).toString(10).substring(6),
        region: region,
        cloudConnectorId: (Math.random() + 1).toString(10).substring(6),
        cloudConnectorAssetId: `elastio:asset:generic-host:s:${account}:${region}:${hostName}`,
        hostName: hostName,
        tags: new Map(),
        createdAt: Math.floor(Math.random() * 100000),
        updatedAt: Math.floor(Math.random() * 100000),
        threatStatus: null,
        coveredByPolicies: [policy],
        associatedRetentionPolicies: [retentionPolicy],
        coveredByPausedPolicies: [pausedPolicy],
        osKind: osKind,
        cpuCores: Math.floor(Math.random() * 20),
    };
}
exports.createMockGenericHostAttributes = createMockGenericHostAttributes;
