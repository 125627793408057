import {
  TableRow as MUITableRow,
  TableCell,
  TableRowProps,
} from '@mui/material'
import React, { forwardRef, memo } from 'react'
import { SkeletonPreloader } from '../skeleton-preloader'

interface Props extends TableRowProps {
  isLoading?: boolean
  colSpanForCell?: number
}

const LoadingObservableTableRow = memo(
  forwardRef<HTMLTableRowElement, Props>(
    ({ isLoading, colSpanForCell, ...restProps }, ref) => {
      return (
        <MUITableRow
          ref={ref}
          {...restProps}
          sx={{
            height: '1px',
            width: '100%',
          }}
        >
          {isLoading ? (
            <TableCell colSpan={colSpanForCell} sx={{ borderBottom: 'none' }}>
              <SkeletonPreloader />
            </TableCell>
          ) : null}
        </MUITableRow>
      )
    }
  )
)

export default LoadingObservableTableRow
