/* eslint-disable import/no-extraneous-dependencies */
import {
  EBS,
  EBSSnapshot,
  EC2,
  EFS,
  GenericHost,
  S3Bucket,
} from 'blues-corejs/dist'
import {
  SliceableItem,
  SliceCriteria,
  SliceCriteriaTag,
  SliceCriteriaTags,
  SliceCriteriaTagsCombinationOperator,
  SliceOutput,
} from './types'

interface ManagementViewAttrs {
  ec2Instances: Array<EC2>
  ebsVolumes: Array<EBS>
  s3Buckets: Array<S3Bucket>
  filesystems: Array<EFS>
  genericHosts: Array<GenericHost>
  ebsSnapshots: Array<EBSSnapshot>
}

export class ManagementView {
  #ec2Instances: Array<EC2>

  #ebsVolumes: Array<EBS>

  #s3Buckets: Array<S3Bucket>

  #filesystems: Array<EFS>

  genericHosts: Array<GenericHost>

  #ebsSnapshots: Array<EBSSnapshot>

  constructor(attrs: ManagementViewAttrs) {
    this.#ec2Instances = attrs.ec2Instances
    this.#ebsVolumes = attrs.ebsVolumes
    this.#s3Buckets = attrs.s3Buckets
    this.#filesystems = attrs.filesystems
    this.genericHosts = attrs.genericHosts
    this.#ebsSnapshots = attrs.ebsSnapshots
  }

  slice(criteria: SliceCriteria): SliceOutput {
    const matchingInstanceList = this.#sliceAsset(this.#ec2Instances, criteria)
    const matchingS3BucketList = this.#sliceAsset(this.#s3Buckets, criteria)
    const matchingEfsList = this.#sliceAsset(this.#filesystems, criteria)
    const matchingVolumesList = this.#sliceVolumeList(
      criteria,
      matchingInstanceList
    )
    const matchingEbsSnapshotsList = this.#sliceEbsSnapshots(
      criteria,
      matchingVolumesList
    )

    return {
      ec2Instances: matchingInstanceList,
      ebsVolumes: matchingVolumesList,
      ebsSnapshots: matchingEbsSnapshotsList,
      s3Buckets: matchingS3BucketList,
      filesystems: matchingEfsList,
      genericHosts: this.genericHosts,
      allEc2Instances: this.#ec2Instances,
      allEbsVolumes: this.#ebsVolumes,
      allEbsSnapshots: this.#ebsSnapshots,
      allS3Buckets: this.#s3Buckets,
      allFilesystems: this.#filesystems,
      allGenericHosts: this.genericHosts,
    }
  }

  #sliceAsset<T extends SliceableItem>(
    list: Array<T>,
    { accountIds, regions, include_tags, exclude_tags }: SliceCriteria
  ): Array<T> {
    if (!accountIds && !regions && !include_tags && !exclude_tags) {
      return list
    }
    return list.filter((item) => {
      if (accountIds && !accountIds.includes(item.awsAccountId)) {
        return false
      }

      if (regions && !regions.includes(item.awsRegion)) {
        return false
      }

      if (include_tags && !this.#doesItemMatchTags(item, include_tags)) {
        return false
      }

      if (exclude_tags && this.#doesItemMatchTags(item, exclude_tags)) {
        return false
      }

      return true
    })
  }

  #doesItemMatchAllTags<T extends SliceableItem>(
    item: T,
    tags: Array<SliceCriteriaTag>
  ): boolean {
    const itemTags = item.tags

    for (const { key, value } of tags) {
      const itemValue = itemTags.find((tag) => tag.key === key)?.value
      if (itemValue !== undefined && (value === null || itemValue === value)) {
        continue
      } else {
        return false
      }
    }

    return true
  }

  #doesItemMatchAnyTag<T extends SliceableItem>(
    item: T,
    tags: Array<SliceCriteriaTag>
  ): boolean {
    const itemTags = item.tags

    for (const { key, value } of tags) {
      const itemValue = itemTags.find((tag) => tag.key === key)?.value
      if (itemValue !== undefined && (value === null || itemValue === value)) {
        return true
      }
    }

    return false
  }

  #doesItemMatchTags<T extends SliceableItem>(
    item: T,
    { tags, operator }: SliceCriteriaTags
  ): boolean {
    switch (operator) {
      case SliceCriteriaTagsCombinationOperator.AND:
        return this.#doesItemMatchAllTags(item, tags)
      case SliceCriteriaTagsCombinationOperator.OR:
        return this.#doesItemMatchAnyTag(item, tags)
      default:
        return false
    }
  }

  #sliceVolumeList(
    criteria: SliceCriteria,
    matchingInstancesList: Array<EC2>
  ): Array<EBS> {
    const matchingVolumesList = this.#sliceAsset(this.#ebsVolumes, criteria)

    const volumesMap = this.#ebsVolumes.reduce((acc, volume) => {
      acc.set(volume.id, volume)
      return acc
    }, new Map())

    const uniqueVolumesIds: Array<string> = [
      ...new Set([
        ...matchingVolumesList.flatMap((volume) => volume.id),
        ...matchingInstancesList.flatMap((instance) => instance.ebsIds),
      ]),
    ]

    return uniqueVolumesIds
      .map((id) => volumesMap.get(id))
      .filter(Boolean) as Array<EBS>
  }

  #sliceEbsSnapshots(
    criteria: SliceCriteria,
    matchingVolumesList: Array<EBS>
  ): Array<EBSSnapshot> {
    const matchingSnapshotsList = this.#sliceAsset(this.#ebsSnapshots, criteria)

    const snapshotMapByVolumeId = this.#ebsSnapshots.reduce((acc, snapshot) => {
      acc.set(snapshot.volumeId, snapshot)
      return acc
    }, new Map())

    const attachedSnapshotsToMatchingVolumes = matchingVolumesList
      .map((volume) => snapshotMapByVolumeId.get(volume.id))
      .filter(Boolean) as Array<EBSSnapshot>

    const uniqueSnapshotsMap = new Map(
      [...matchingSnapshotsList, ...attachedSnapshotsToMatchingVolumes].map(
        (snapshot) => [snapshot.id, snapshot]
      )
    )

    return Array.from(uniqueSnapshotsMap.values())
  }
}
