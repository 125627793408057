/* eslint-disable import/no-extraneous-dependencies */
import JobUnscannedIcon from '@inline-img/general/job-dashboard/JobUnscannedIcon'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ReportIcon from '@mui/icons-material/Report'
import { Box, Icon as IconMUI } from '@mui/material'
import { SummaryCheckStatus } from 'blues-corejs/dist/models/backups/backup'
import { ElementType } from 'react'

type StatusDescriptor = {
  label: string
  color: 'el-grey' | 'el-success' | 'el-error'
  Icon: ElementType
}

const SCAN_STATUS_MAP: Record<SummaryCheckStatus, StatusDescriptor> = {
  [SummaryCheckStatus.UNCHECKED]: {
    label: 'Unscanned',
    color: 'el-grey',
    Icon: JobUnscannedIcon,
  },
  [SummaryCheckStatus.GOOD]: {
    label: 'Completed',
    color: 'el-success',
    Icon: CheckCircleIcon,
  },
  [SummaryCheckStatus.BAD]: {
    label: 'Failed',
    color: 'el-error',
    Icon: ReportIcon,
  },
} as const

interface Props {
  status: SummaryCheckStatus
}

// TODO: refactor it
function StatusComponent({ status }: Props) {
  if (!SCAN_STATUS_MAP[status]) {
    return null
  }

  const { color, Icon } = SCAN_STATUS_MAP[status] as StatusDescriptor

  return (
    <Box display="flex" alignItems="center" gap="5px">
      <IconMUI color={color} fontSize="small" component={Icon} />
    </Box>
  )
}

export function StatusCell({
  status,
}: {
  status: number // TODO: Refactor it
}) {
  return <StatusComponent status={status} />
}
