import React from 'react'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'

import { RotatingIconOwnProps } from './types'
import { DEFAULT_ROTATION_DEGREE } from './const'

const IconButtonStyled = styled(IconButton)`
  padding: 0 0;
`
function RotatingIcon({
  rotationDegree = DEFAULT_ROTATION_DEGREE,
  Icon,
  isRotated,
}: RotatingIconOwnProps) {
  const rotationStyle = !isRotated
    ? { transform: `rotate(${rotationDegree}deg)` }
    : {}

  return (
    <IconButtonStyled
      role="icon-button"
      style={rotationStyle}
      disableRipple={true}
    >
      <Icon />
    </IconButtonStyled>
  )
}

export default RotatingIcon
