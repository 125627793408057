import { TenantContext } from '@lib/feature-flags/contexts/base-context'

interface Context {
  kind: string
  key: string
}

export function getContext(currentTenantName: string): Context {
  const tenantContext = new TenantContext(currentTenantName)
  return {
    kind: tenantContext.getKind(),
    key: tenantContext.getKey(),
  }
}
