import { useEffect, useState, useCallback } from 'react'
import { z } from 'zod'
import { TokenState } from '@features/authentication/types'

export const accessTokenSchema = z.object({
  accessToken: z.string().min(1, { message: 'Access token is required.' }),
  expiredAt: z
    .number()
    .int()
    .positive({ message: 'Expiration time must be a positive integer.' }),
  code: z.number().int(),
})

export const useSSRAccessToken = (): TokenState => {
  const [token, setToken] = useState<TokenState['token']>(undefined)
  const [isTokenLoading, setIsTokenLoading] =
    useState<TokenState['isTokenLoading']>(false)
  const [tokenError, setTokenError] =
    useState<TokenState['tokenError']>(undefined)

  const fetchToken = useCallback(async () => {
    setIsTokenLoading(true)
    setTokenError(undefined)
    try {
      const response = await fetch('/api/engine-init')

      if (!response.ok) {
        throw new Error('Failed to fetch access token')
      }

      const data = await response.json()
      accessTokenSchema.parse(data)

      setToken(data.accessToken)
    } catch (error) {
      if (error instanceof z.ZodError) {
        setTokenError(
          new Error(
            `Validation Error: ${error.errors.map((e) => e.message).join(', ')}`
          )
        )
      }
    } finally {
      setIsTokenLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchToken()
  }, [fetchToken])

  return {
    token,
    tokenError,
    isTokenLoading,
  }
}
