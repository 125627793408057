import React from 'react'
import CircleIcon from '@mui/icons-material/Circle'
import Box from '@mui/material/Box'

type ColorResolver = (isHealthy: boolean) => string

const defaultColorResolver: ColorResolver = (isHealthy) =>
  isHealthy ? 'var(--green500)' : 'var(--red400)'

type StatusCircleOwnProps = {
  colorResolver?: ColorResolver
  isHealthy: boolean
}

function StatusCircle({
  isHealthy,
  colorResolver = defaultColorResolver,
}: StatusCircleOwnProps) {
  return (
    <Box
      data-testid="status-circle"
      style={{
        fontSize: '8px',
        color: colorResolver(isHealthy),
      }}
    >
      <CircleIcon fontSize="inherit" />
    </Box>
  )
}

export default StatusCircle
