import { TableFooter as MUITableFooter, TableFooterProps } from '@mui/material'
import React from 'react'

interface Props extends TableFooterProps {}

const TableFooter = React.forwardRef<any, Props>(
  ({ children, ...restProps }, ref) => (
    <MUITableFooter ref={ref} {...restProps}>
      {children}
    </MUITableFooter>
  )
)

export default TableFooter
