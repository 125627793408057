import AccessTokenModel from '@lib/models/access-token.model'
import { Nullable } from '@lib/engine-types'
import FormTokenInterface from '@lib/interfaces/form/form-token.interface'

// list actions
export const REQUEST_TOKEN_LIST = 'REQUEST_TOKEN_LIST'
export const SET_TOKENS_LIST = 'SET_TOKENS_LIST'
export const REQUEST_REVOKED_TOKEN_LIST = 'REQUEST_REVOKED_TOKEN_LIST'
export const SET_REVOKED_TOKENS_LIST = 'SET_REVOKED_TOKENS_LIST'

// edit token
export const REQUEST_TOKEN = 'REQUEST_TOKEN'
export const SET_EDIT_TOKEN = 'SET_EDIT_TOKEN'
export const SET_SECRET_TOKEN = 'SET_SECRET_TOKEN'

// submit actions
export const SUBMIT_ADD_TOKEN = 'SUBMIT_ADD_TOKEN'
export const SUBMIT_EDIT_TOKEN = 'SUBMIT_EDIT_TOKEN'
export const SUBMIT_REVOKE_TOKEN_LIST = 'SUBMIT_REVOKE_TOKEN_LIST'

// scopes
export const REQUEST_POSSIBLE_SCOPES = 'REQUEST_POSSIBLE_SCOPES'
export const SET_POSSIBLE_SCOPES = 'SET_POSSIBLE_SCOPES'

// list actions
export const requestTokenList = () => ({
  type: REQUEST_TOKEN_LIST,
  payload: null,
})
export const setTokensList = (payload: Array<AccessTokenModel>) => ({
  type: SET_TOKENS_LIST,
  payload,
})
export const requestRevokedTokenList = () => ({
  type: REQUEST_REVOKED_TOKEN_LIST,
  payload: null,
})
export const setRevokedTokensList = (payload: Array<AccessTokenModel>) => ({
  type: SET_REVOKED_TOKENS_LIST,
  payload,
})

// request the token
export const requestToken = (tokenId: string) => ({
  type: REQUEST_TOKEN,
  payload: tokenId,
})

// edit token
export const setEditToken = (token: Nullable<AccessTokenModel>) => ({
  type: SET_EDIT_TOKEN,
  payload: token,
})

export const setSecretToken = (secretToken: string) => ({
  type: SET_SECRET_TOKEN,
  payload: secretToken,
})

// submit actions
export const submitAddToken = (tokenData: FormTokenInterface) => ({
  type: SUBMIT_ADD_TOKEN,
  payload: tokenData,
})
export const submitEditToken = (tokenData: FormTokenInterface) => ({
  type: SUBMIT_EDIT_TOKEN,
  payload: tokenData,
})
export const submitRevokeTokenList = (tokenIdList: Array<string>) => ({
  type: SUBMIT_REVOKE_TOKEN_LIST,
  payload: tokenIdList,
})
