"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListDetailsEfsForAssetItemEfsScan = void 0;
const types_1 = require("../../models/scans/malware/types");
const types_2 = require("../../models/scans/ransomware/types");
__exportStar(require("./types"), exports);
class ListDetailsEfsForAssetItemEfsScan {
    scans;
    threats;
    constructor({ scans, threats, }) {
        this.scans = scans;
        this.threats = threats;
    }
    execute() {
        return this.prepareOutput({ scans: this.scans });
    }
    prepareOutput({ scans, }) {
        const scansOutput = scans.map((scan) => {
            const malware = scan.malwares;
            const ransomware = scan.ransomwares;
            const unionScans = [
                malware,
                ransomware,
            ];
            const malwareThreatsNames = this.getThreatsNameByScanId(malware?.id);
            const ransomwareThreatsNames = this.getThreatsNameByScanId(ransomware?.id);
            const threats = this.getThreatsByScanId(malware?.id || ransomware?.id);
            const scanId = malware?.id || ransomware?.id || '';
            const lastCompleteTime = this.getLastCompleteTime(unionScans);
            return {
                isHealthy: this.isCleanScan([malware, ransomware]),
                malwareStatus: malware?.status,
                ransomwareStatus: ransomware?.status,
                completeTime: lastCompleteTime,
                threatsNames: [...malwareThreatsNames, ...ransomwareThreatsNames],
                malwareNames: malwareThreatsNames,
                ransomwareNames: ransomwareThreatsNames,
                threats: threats,
                scanId,
                scanData: { malwareScan: malware, ransomwareScan: ransomware },
            };
        });
        return {
            scans: scansOutput,
        };
    }
    isCleanScan(scans) {
        return scans.every((scan) => scan?.status === types_2.RansomwareScanStatus.CLEAN ||
            scan?.status === types_1.MalwareScanStatus.CLEAN);
    }
    getLastCompleteTime(scans) {
        return scans.reduce((lastCompleteTime, scan) => {
            if (scan && scan.createdAt > lastCompleteTime) {
                return scan.createdAt;
            }
            return lastCompleteTime;
        }, new Date(0));
    }
    getThreatsByScanId(scanId) {
        if (!scanId) {
            return [];
        }
        const threats = this.threats
            .filter((threat) => threat.scanIds.includes(scanId))
            .map((threat) => threat);
        return threats;
    }
    getThreatsNameByScanId(scanId) {
        if (!scanId) {
            return [];
        }
        const threatsNames = this.threats
            .filter((threat) => threat.scanIds.includes(scanId))
            .map((threat) => threat.name);
        return [...new Set(threatsNames)];
    }
}
exports.ListDetailsEfsForAssetItemEfsScan = ListDetailsEfsForAssetItemEfsScan;
