import Image from 'next/image'

import { useGlobalLoginCheck } from './use-global-login-check'

function LoadingScreen() {
  const isGlobalLogin = useGlobalLoginCheck()

  return (
    <div className="innerContent llContent llFullHeight">
      {!isGlobalLogin && (
        <div className="llTitle">Launching your tenant...</div>
      )}
      <Image
        width={280}
        height={220}
        alt=""
        src="/images/tenant-creation-loader.svg"
        placeholder="empty"
        priority={true}
      />
    </div>
  )
}

export default LoadingScreen
