/* eslint-disable import/no-extraneous-dependencies */
import {
  FilesystemScanTransformer,
  MalwareScanTransformer,
  RansomwareScanTransformer,
} from '@lib/clients/assets/transformers'
import {
  Request as ListScansForAssetRequest,
  Response,
} from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/scans/list_scans_for_asset_pb'
import { GrpcClient } from '@lib/clients/grpc-client'
import { ScansPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'

import {
  FilesystemScanCheck,
  MalwareScan,
  RansomwareScan,
} from 'blues-corejs/dist'
import { Pagination } from '@lib/clients/types'
import { ScansRun } from 'blues-corejs/dist/models/scans'
import { AbstractListRequest } from '@lib/clients/list-request'

export type DetailsScans = {
  filesystemChecks?: FilesystemScanCheck
  malwares?: MalwareScan
  ransomwares?: RansomwareScan
}

export type ListScansForAssetResponse = {
  runsList: Array<DetailsScans>
  scansRunList: Array<ScansRun>
}

export class ListScansForAssetClient extends GrpcClient<ScansPromiseClient> {
  #assetsClient: ScansPromiseClient

  constructor(hostName = '') {
    super()
    this.#assetsClient = this.getClient(hostName)
  }

  protected initClient(hostName: string): ScansPromiseClient {
    return new ScansPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'ListScansForAssetClient'
  }

  async listScansForAsset(
    request: AbstractListRequest<ListScansForAssetRequest>
  ): Promise<ListScansForAssetResponse & Pagination> {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#assetsClient.listScansForAsset(
            request.createGrpcRequest(),
            this.metadata()
          ),
        {
          requestName: 'ScansPromiseClient/listScansForAsset',
        }
      )
    ).toObject()

    return {
      ...this.#transformResponse(response),
      pageToken: response.pagination?.nextPageToken,
    }
  }

  #transformResponse(response: Response.AsObject): ListScansForAssetResponse {
    return {
      runsList: response.runsList.map(
        ({ filesystemChecks, malwares, ransomwares }) => ({
          filesystemChecks:
            filesystemChecks &&
            new FilesystemScanTransformer(filesystemChecks).transform(),
          malwares:
            malwares && new MalwareScanTransformer(malwares).transform(),
          ransomwares:
            ransomwares &&
            new RansomwareScanTransformer(ransomwares).transform(),
        })
      ),
      scansRunList: response.scanRunsList.map(
        ({ filesystemChecksList, malwareScansList, ransomwareScansList }) => ({
          filesystemChecksList: filesystemChecksList.map((filesystemChecks) =>
            new FilesystemScanTransformer(filesystemChecks).transform()
          ),
          malwareScansList: malwareScansList.map((malwares) =>
            new MalwareScanTransformer(malwares).transform()
          ),
          ransomwareScansList: ransomwareScansList.map((ransomwares) =>
            new RansomwareScanTransformer(ransomwares).transform()
          ),
        })
      ),
    }
  }
}
