import React from 'react'

function PoliciesIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5455 4.58398C3.88303 4.58398 3.33337 5.12983 3.33337 5.78769V14.2136C3.33337 14.8715 3.88303 15.4173 4.5455 15.4173H5.75762V14.2136H4.5455V5.78769H5.75762V4.58398H4.5455ZM14.2425 4.58398V5.78769H15.4546V14.2136H14.2425V15.4173H15.4546C16.117 15.4173 16.6667 14.8715 16.6667 14.2136V5.78769C16.6667 5.12983 16.117 4.58398 15.4546 4.58398H14.2425ZM5.75762 6.99139V8.1951H14.2425V6.99139H5.75762ZM5.75762 9.3988V10.6025H12.4243V9.3988H5.75762ZM5.75762 11.8062V13.0099H14.2425V11.8062H5.75762Z"
        fill="#999CAA"
      />
    </svg>
  )
}

export default PoliciesIcon
