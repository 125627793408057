"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloudConnector = exports.CloudConnectorStatus = void 0;
const types_1 = require("./types");
var types_2 = require("./types");
Object.defineProperty(exports, "CloudConnectorStatus", { enumerable: true, get: function () { return types_2.CloudConnectorStatus; } });
class CloudConnector {
    #redStackId;
    #awsAccountId;
    #awsRegion;
    #accountAlias;
    #status;
    #lastErrorMessage;
    #iamRole;
    #accountDescription;
    #lastWarningMessage;
    #awsAccountCfnVersion;
    #version;
    #createdAt;
    #updatedAt;
    #lastIntegrityCheckErrors;
    #tenantName;
    #cfnUpgradeRequired;
    constructor(attrs) {
        this.#redStackId = attrs.redStackId;
        this.#awsAccountId = attrs.awsAccountId;
        this.#awsRegion = attrs.awsRegion;
        this.#accountAlias = attrs.accountAlias;
        this.#status = attrs.status;
        this.#lastErrorMessage = attrs.lastErrorMessage;
        this.#iamRole = attrs.iamRole;
        this.#accountDescription = attrs.accountDescription;
        this.#lastWarningMessage = attrs.lastWarningMessage;
        this.#awsAccountCfnVersion = attrs.awsAccountCfnVersion;
        this.#version = attrs.version;
        this.#createdAt = attrs.createdAt;
        this.#updatedAt = attrs.updatedAt;
        this.#lastIntegrityCheckErrors = attrs.lastIntegrityCheckErrors;
        this.#tenantName = attrs.tenantName;
        this.#cfnUpgradeRequired = attrs.cfnUpgradeRequired;
    }
    get redStackId() {
        return this.#redStackId;
    }
    get awsAccountId() {
        return this.#awsAccountId;
    }
    get awsRegion() {
        return this.#awsRegion;
    }
    get accountAlias() {
        return this.#accountAlias;
    }
    get status() {
        return this.#status;
    }
    get lastErrorMessage() {
        return this.#lastErrorMessage;
    }
    get iamRole() {
        return this.#iamRole;
    }
    get accountDescription() {
        return this.#accountDescription;
    }
    get lastWarningMessage() {
        return this.#lastWarningMessage;
    }
    get awsAccountCfnVersion() {
        return this.#awsAccountCfnVersion;
    }
    get version() {
        return this.#version;
    }
    get createdAt() {
        return this.#createdAt;
    }
    get updatedAt() {
        return this.#updatedAt;
    }
    get lastIntegrityCheckErrors() {
        return this.#lastIntegrityCheckErrors;
    }
    get tenantName() {
        return this.#tenantName;
    }
    get cfnUpgradeRequired() {
        return this.#cfnUpgradeRequired;
    }
    get isInactive() {
        return this.status === types_1.CloudConnectorStatus.INACTIVE;
    }
    get isActive() {
        return this.status === types_1.CloudConnectorStatus.ACTIVE;
    }
}
exports.CloudConnector = CloudConnector;
