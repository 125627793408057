import { PolicyStatus } from '@lib/constants/policies.constant'
import { AssetSource } from '@lib/constants/retention-policy/asset-source.constant'
import { BackupType } from '@lib/constants/retention-policy/backup-type.constant'
import { SelectCategory } from '@lib/constants/retention-policy/select-category.constant'
import { Nullable, VIRow } from '@lib/engine-types'
import { FormPeriodInterface } from '@lib/interfaces/form/form-period.interface'
import Assets from '@lib/interfaces/retention-policy/assets.interface'
import RetentionDurationInterface from '@lib/interfaces/retention-policy/retention-duration.interface'
import Tags from '@lib/interfaces/retention-policy/tags.interface'
import Vault from '@lib/interfaces/retention-policy/vault.interface'
import BaseModel from '@lib/models/base-model/base.model'

export class Criterion {
  assetSource: AssetSource

  backupType: BackupType

  tags?: Tags

  assets?: Assets

  vault?: Vault

  awsAccountId: string

  awsRegion: string

  constructor(readonly params: any) {
    this.assetSource = params.assetSource
    this.backupType = params.backupType
    this.tags = params.tags
    this.assets = params.assets
    this.vault = params.vault
    this.awsAccountId = params.awsAccountId
    this.awsRegion = params.awsRegion
  }
}

export class Selector {
  criteriaList: Array<Criterion>

  constructor(readonly params: any) {
    this.criteriaList = params.criteriaList
  }
}

export class RetentionPolicy {
  keepAll?: RetentionDurationInterface

  keepHourly?: RetentionDurationInterface

  keepDaily?: RetentionDurationInterface

  keepWeekly?: RetentionDurationInterface

  keepMonthly?: RetentionDurationInterface

  keepYearly?: RetentionDurationInterface

  status: PolicyStatus

  constructor(readonly params: any) {
    this.keepAll = params.keepAll
    this.keepHourly = params.keepHourly
    this.keepDaily = params.keepDaily
    this.keepWeekly = params.keepWeekly
    this.keepMonthly = params.keepMonthly
    this.keepYearly = params.keepYearly
    this.status = params.status
  }
}

interface DefaultRetentionPolicy {
  id: string
  policy?: RetentionPolicy
}

interface CustomRetentionPolicy {
  id: string
  policy?: RetentionPolicy
  selectorsList?: Array<Selector>
}

class RetentionPolicyModel extends BaseModel {
  public readonly defaultPolicy?: DefaultRetentionPolicy

  public readonly customPolicy?: CustomRetentionPolicy

  public readonly coveredAssetCount: number

  public readonly coverageUpdatedAt?: Nullable<number>

  public readonly coverageInvalidatedAt?: Nullable<number>

  // extra
  public readonly policyName: string

  public readonly retention: string

  public readonly status: string

  public readonly policyStatus: PolicyStatus

  public readonly isDefault: boolean

  public readonly started: boolean

  public readonly dataForSelectedAssets?: VIRow

  public readonly dataForRecoveryPointTypes?: VIRow

  public readonly dataForPeriodForm: FormPeriodInterface

  constructor(readonly params: any = {}) {
    super(params)

    // base
    this.defaultPolicy = params.defaultPolicy
    this.customPolicy = params.customPolicy
    this.coveredAssetCount = params.coveredAssetCount
    this.coverageUpdatedAt = params.coverageUpdatedAt
    this.coverageInvalidatedAt = params.coverageInvalidatedAt

    // extra
    this.policyName = params.policyName
    this.isDefault = params.isDefault
    this.retention = params.retention
    this.policyStatus = params.policyStatus
    this.dataForPeriodForm = params.dataForPeriodForm
    this.dataForRecoveryPointTypes = params.dataForRecoveryPointTypes

    this.status = 'Paused'
    this.started = false

    if (this.defaultPolicy) {
      this.status =
        this.defaultPolicy.policy?.status ===
        PolicyStatus.POLICY_STATUS_DISABLED
          ? 'Paused'
          : 'Active'
      this.started =
        this.defaultPolicy.policy?.status === PolicyStatus.POLICY_STATUS_ENABLED
    }
    if (this.customPolicy) {
      this.status =
        this.customPolicy?.policy?.status ===
        PolicyStatus.POLICY_STATUS_DISABLED
          ? 'Paused'
          : 'Active'
      this.started =
        this.customPolicy?.policy?.status === PolicyStatus.POLICY_STATUS_ENABLED
    }

    const assetsRow: VIRow = []
    if (this.dataForRecoveryPointTypes) {
      this.dataForRecoveryPointTypes
        .filter((rpType) => rpType.name === SelectCategory.ASSETS)
        .map((item) => {
          item.options?.map((v) => {
            assetsRow.push(v)
          })
        })
    }

    this.dataForSelectedAssets = assetsRow
  }
}

export default RetentionPolicyModel
