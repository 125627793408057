"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MalwareScanBackup = exports.MalwareScanTarget = exports.MalwareScan = void 0;
const scan_1 = require("../scan");
const types_1 = require("./types");
class MalwareScan extends scan_1.Scan {
    #scanTarget;
    #status;
    #jobId;
    #finishedAt;
    #summary;
    #threatIds;
    constructor(parameters) {
        const { scanTarget, status, jobId, finishedAt, summary, threatIds, ...assetParameters } = parameters;
        super(assetParameters);
        this.#scanTarget = scanTarget;
        this.#status = status;
        this.#jobId = jobId;
        this.#finishedAt = finishedAt;
        this.#summary = summary;
        this.#threatIds = Object.freeze([...threatIds]);
    }
    get scanTarget() {
        return this.#scanTarget;
    }
    get status() {
        return this.#status;
    }
    get jobId() {
        return this.#jobId;
    }
    get finishedAt() {
        return this.#finishedAt;
    }
    get summary() {
        return this.#summary;
    }
    get threatIds() {
        return [...this.#threatIds];
    }
    set threatIds(newThreatIds) {
        this.#threatIds = Object.freeze([...newThreatIds]);
    }
    get infectedFilesCount() {
        return this.#summary.infectedFiles;
    }
    get cleanFilesCount() {
        return this.#summary.cleanFiles;
    }
    get targetIsBackup() {
        return this.scanTarget.target instanceof MalwareScanBackup;
    }
    get isInfected() {
        return this.status === types_1.MalwareScanStatus.INFECTED;
    }
    get isClean() {
        return !this.isInfected;
    }
}
exports.MalwareScan = MalwareScan;
class MalwareScanTarget {
    #target;
    constructor(target) {
        this.#target = target;
    }
    get target() {
        return this.#target;
    }
    get assetId() {
        return this.#target.assetId;
    }
}
exports.MalwareScanTarget = MalwareScanTarget;
class MalwareScanBackup {
    #backupId;
    #source;
    #provider;
    #timestamp;
    constructor(parameters) {
        const { backupId, source, provider, timestamp } = parameters;
        this.#backupId = backupId;
        this.#source = source;
        this.#provider = provider;
        this.#timestamp = timestamp;
    }
    get backupId() {
        return this.#backupId;
    }
    get source() {
        return this.#source;
    }
    get provider() {
        return this.#provider;
    }
    get timestamp() {
        return this.#timestamp;
    }
    get assetId() {
        if (this.#source.asset) {
            return this.#source.asset.backupAssetId;
        }
        else {
            return this.#source.assetItem?.backupAssetId;
        }
    }
}
exports.MalwareScanBackup = MalwareScanBackup;
