import React from 'react'

export default function EbsIcon({
  width = 46,
  height = 46,
}: {
  width?: number
  height?: number
}) {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      id="Layer_1"
      x={0}
      y={0}
      style={{
        // @ts-ignore
        enableBackground: 'new 0 0 46 46',
      }}
      viewBox="0 0 46 46"
    >
      <style>
        {
          '.st0{clip-path:url(#SVGID_00000151506081845893048630000016260795781922758579_)}.st1{fill:url(#Ellipse_531_00000023262850616350534000000000161691618324201642_)}'
        }
      </style>
      <g id="EBS">
        <defs>
          <path
            id="SVGID_1_"
            d="M23 0c12.7 0 23 10.3 23 23S35.7 46 23 46 0 35.7 0 23 10.3 0 23 0z"
          />
        </defs>
        <clipPath id="SVGID_00000041288661387105890650000001782641175923739271_">
          <use
            xlinkHref="#SVGID_1_"
            style={{
              overflow: 'visible',
            }}
          />
        </clipPath>
        <g
          style={{
            clipPath:
              'url(#SVGID_00000041288661387105890650000001782641175923739271_)',
          }}
        >
          <linearGradient
            id="Ellipse_531_00000107557711607345146110000007607466101659763610_"
            x1={-2276.999}
            x2={2323.001}
            y1={-2254.601}
            y2={2345.398}
            gradientTransform="matrix(1 0 0 -1 0 68.398)"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset={0}
              style={{
                stopColor: '#1b660f',
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: '#6cae3e',
              }}
            />
          </linearGradient>
          <circle
            id="Ellipse_531"
            cx={23}
            cy={23}
            r={23}
            style={{
              fill: 'url(#Ellipse_531_00000107557711607345146110000007607466101659763610_)',
            }}
          />
          <path
            id="Amazon-Elastic-Block-Store_Icon_64_Squid"
            d="M34.4 30.9h.9v4.4h-4.4v-.9h2.9l-3.6-3.6.6-.6 3.6 3.6v-2.9zm-18.5-.1-3.6 3.6h2.9v.9h-4.4v-4.4h.9v2.9l3.6-3.6.6.6zm19.4-20.1v4.4h-.9v-2.9l-3.6 3.6-.6-.6 3.6-3.6h-2.9v-.9h4.4zm-23.1.9 3.6 3.6-.6.6-3.6-3.6v2.9h-.9v-4.4h4.4v.9h-2.9zM27.7 29c-.5.5-2.2 1-4.9 1-3.2 0-5.1-.8-5.1-1.3V18.6c1.1.7 3.2.9 5.1.9 1.8 0 3.9-.3 5-.9v10.1s0 .1-.1.3zm-4.9-13c2.7 0 4.4.5 4.9 1 .1.1.1.2.1.3 0 .5-1.8 1.3-5 1.3-3.1 0-5.1-.8-5.1-1.3.1-.5 1.8-1.3 5.1-1.3zm5.9 1.3c0-.2-.1-.6-.4-.9-.8-.8-2.8-1.3-5.5-1.3s-5.9.6-5.9 2.2v11.4c0 1.6 3.2 2.2 5.9 2.2 2 0 4.6-.3 5.5-1.3.3-.3.4-.7.4-.9V17.3z"
            style={{
              fillRule: 'evenodd',
              clipRule: 'evenodd',
              fill: '#fff',
            }}
          />
        </g>
      </g>
    </svg>
  )
}
