import { GrpcClient } from '@lib/clients/grpc-client'
import { AssetsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import { EC2Transformer } from '@lib/clients/assets/transformers'
// eslint-disable-next-line import/no-extraneous-dependencies
import { EC2 } from 'blues-corejs/dist'
import { ListEc2 } from '@lib/clients/grpc-imports'
import { AbstractListRequest } from '@lib/clients/list-request'

export class ListEc2Client extends GrpcClient<AssetsPromiseClient> {
  #assetsClient: AssetsPromiseClient

  constructor(hostName = '') {
    super()
    this.#assetsClient = this.getClient(hostName)
  }

  protected innerClientTypeId(): string {
    return 'ListEc2Client'
  }

  protected initClient(hostName = ''): AssetsPromiseClient {
    return new AssetsPromiseClient(hostName, null, null)
  }

  async list(request: AbstractListRequest<ListEc2>): Promise<Array<EC2>> {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#assetsClient.listEC2(
            request.createGrpcRequest(),
            this.metadata()
          ),
        {
          requestName: 'AssetsPromiseClient/listEC2',
        }
      )
    ).toObject()

    return response.instancesList.map((item) =>
      new EC2Transformer(item).transform()
    )
  }
}
