import { TabVariant, TabViewInterface } from './types'
import { OverviewIcon, RecoveryIcon, SecurityIcon } from './icons'
import { ViewKind, ViewsVersion } from './types'

export const MIN_HEIGHT_FOR_TAB = 37

type IconComponent = (props: { selected: boolean }) => JSX.Element

export const CURRENT_VIEW_VERSION = ViewsVersion.V2

// Used to story in setting service temporary tab, we need it because we need render Infected Servers instead of AccountSummary by design :\
// Bad practice, but we have no time to refactor it
// If user have already temporary tab saved, and then we change that key - logic about rendering component will be broken
export const TEMPORARY_VIEW_ID = 'temporary-view'

export const VARIANTS_ICONS: Record<TabVariant, IconComponent> = {
  overview: OverviewIcon,
  security: SecurityIcon,
  recovery: RecoveryIcon,
  ova: OverviewIcon,
}

export const DEFAULT_VIEWS: Array<TabViewInterface> = [
  {
    id: 'default-view-overview',
    name: 'AWS Overview',
    slice: {},
    variant: 'overview',
    version: CURRENT_VIEW_VERSION,
    isDefaultDashboard: false,
    kind: ViewKind.AWS,
  },
]
