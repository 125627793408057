abstract class TextConstant {
  public static readonly NO_CLOUD_CONNECTORS_ARE_FOUND =
    'Your cloud connector is not available and backups and integrity scans cannot be monitored. Please click reconnect.  If this does not work, please click on “Get Support”.'

  public static readonly NEVER_SEND_AWS_CREDENTIALS =
    'Your AWS credentials are never sent to the Elastio servers.'

  public static readonly INSTALL_NOTIFICATION_TOP =
    'If you are installing Elastio over a previous release, please follow the instructions <a class="blueLink" target="_blank" rel="noreferrer noopener" href="https://docs.elastio.com/docs/get-started/update">here</a> to uninstall Elastio before proceeding with this install. As a note, for this version of EAP you can not preserve vaults between releases.  We will address this limitation in the next 2 sprints to remove this limitation.'

  public static readonly INSTALL_NOTIFICATION_BOTTOM =
    'Once the uninstall is complete, go to sources page and select the menu to deactivate the account. Now proceed with the deployment of the new version.'

  public static readonly RESTORE_BLOCK_DEVICE_DESCRIPTION =
    'Run the command below on the target client machine to restore the device.  Note: The command will restore to the original target device name.  You can change the target device name in the command line before you run it.  For more details <a class="blueLink" target="_blank" rel="noreferrer noopener" href="https://docs.elastio.com/docs/cli/how-to/protect-restore/backup-partitions">see help</a>.'

  public static readonly RESTORE_FILES_DESCRIPTION =
    'Run the command below on the target client machine to restore the file(s). By default, the command will restore the files to original location. For more details <a class="blueLink" target="_blank" rel="noreferrer noopener" href="https://docs.elastio.com/docs/cli/how-to/protect-restore/backup-files">see help</a>.'

  public static readonly RESTORE_S3_DESCRIPTION =
    'Run the command below on the target client machine to restore the recovery point. For more details <a class="blueLink" target="_blank" rel="noreferrer noopener" href="https://docs.elastio.com/docs/cli/how-to/protect-restore/backup-s3#restore-s3-bucket-in-elastio-cli">see help</a>.'

  public static readonly RESTORE_STREAM_DESCRIPTION =
    'Run the command below on the target client machine to restore the stream.  By default, the command will restore to <div class="codeBlock">stdout</div>. To restore the stream to a file use <div class="codeBlock mr6">--to-file <file-path></div> flag with the command below. For more details <a class="blueLink" target="_blank" rel="noreferrer noopener" href="https://docs.elastio.com/docs/cli/how-to/protect-restore/backup-streams">see help</a>.'

  public static readonly MOUNT_DESCRIPTION =
    'To mount the recovery point, run the command below on a target machine with access to the AWS account. Windows recovery points can be mounted on Windows and Linux machines. Linux recovery points must mounted on Linux machines. <div class="mt8"> <a class="blueLink" target="_blank" rel="noreferrer noopener" href="https://docs.elastio.com/docs/cli/how-to/mount-rp">See help</a> for more.</div>'

  public static readonly MOUNT_WARNING =
    'CLI needs to be installed in order to mount. If the CLI is not installed, please follow the installation instructions in the documentation.'

  public static readonly COMING_SOON_FEATURE =
    '<h2 class="flexRowCenter jsComingSoon">This feature is coming soon.</h2>'

  public static readonly INTEGRITY_SCAN_INFO = [
    'So you know if backups contain ransomware or malware.',
    'As a threat detection and response tool.',
    'Useful to satisfy some of the data security controls required for your SOC2 and cyber insurance audits.',
  ]

  public static readonly INTEGRITY_PROTECTION_INFO = [
    'To verify the integrity of the file systems to make ensure a successful recovery.',
    'As an early warning that the backups are unhealthy and could be an indicator of compromise or a sign of an application issue.',
    'Useful to satisfy some of the availability controls required for your SOC2 and cyber insurance audits.',
  ]

  public static readonly CLI_COMMAND_FIRST_BACKUP =
    'elastio ec2 backup --instance-id <insert instance id>'

  public static readonly CLI_COMMAND_MOUNT_TOAST =
    'Run it on the target client machine to mount the recovery point.'

  public static readonly CLI_COMMAND_RESTORE_TOAST =
    'Run it on the target client machine to restore the recovery point.'

  public static readonly CLI_COMMAND_RESTORE_TOAST_OTHER =
    'Run it on the target client machine to restore '

  public static readonly CLI_COMMAND_BACKUP_TOAST =
    'Run it on the target client machine to run your first backup.'

  public static readonly CLI_RESTORE_BLOCK_TEXT_EBS =
    'Optionally you can copy the command below and run it on the target client machine to recover the recovery point. For more details <a class="blueLink" target="_blank" rel="noreferrer noopener" href="https://docs.elastio.com/docs/cli/how-to/protect-restore/backup-ebs">visit help</a>'

  public static readonly CLI_RESTORE_BLOCK_TEXT_EC2 =
    'Optionally you can copy the command below and run it on the target client machine to recover the recovery point. For more details <a class="blueLink" target="_blank" rel="noreferrer noopener" href="https://docs.elastio.com/docs/cli/how-to/protect-restore/backup-ec2">visit help</a>'

  public static readonly CLI_EDGE_CASE_RESTORE_BLOCK_TEXT =
    'You can copy the command below and run it on the target client machine to recover the recovery point. For more details <a class="blueLink" target="_blank" rel="noreferrer noopener" href="https://docs.elastio.com/docs/cli/reference-guide">see help</a>'

  public static readonly CLI_COMMAND_RESTORE_BLOCK_TEXT_OTHER =
    'You can copy the command below and run it on the target client machine to restore '

  public static readonly CLI_COMMAND_MOUNT_BLOCK_TEXT =
    'You can copy the command below and run it on the target client machine to mount the recovery point. Note, that mount is currently unavailable for Mac OS.'

  public static readonly CLI_COMMAND_MOUNT_BLOCK_TEXT_LOCAL =
    'You can copy the command below and run it on the target client machine to mount the recovery point. Please, fill <div class="codeBlock">&lt;path from&gt; &lt;path to&gt;</div>. Note, that mount is currently unavailable for Mac OS.'

  public static readonly UNPROTECTED_CONDITION_TOOLTIP_TEXT =
    'Unprotected: this asset does not have any recovery points of the corresponding backup type thus it is considered unprotected (i.e. EC2 without an EC2 RP).'

  public static readonly ESTIMATED_PRICE_TOOLTIP_TEXT =
    'The price is estimated based on the median Amazon pricing, actual price can differ from this prognosis.'

  public static readonly ARCHIVED_SNAPSHOT_TOOLTIP_TEXT =
    'This snapshot is archived, the price cannot be calculated.'

  public static readonly CLI_MOUNT = 'Mount via CLI'

  public static readonly SPECIFIC_PATHS_DESCRIPTION =
    'A path is simply the name of the folder you want to protect. Regexp is all folders with the same name regardless of their parent folder, and is expressed something like: /*/foldername/files'

  public static readonly WARNING_S3_MESSAGE =
    'Cloud Connectors version should be 0.26.0 or higher. Please upgrade to the latest version of Cloud Connector to get S3 protection.'

  public static readonly WARNING_EFS_MESSAGE =
    'Backups are not supported for EFS.'

  public static readonly ASSETS_SELECT_LAST_BACKUP_MESSAGE =
    'Last Backup column displays the date of the latest Elastio recovery point. Other types of backup of AWS resources is not taken into account.'
}

export default TextConstant
