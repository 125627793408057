import { COMPLIANCE_REPORTS } from '@lib/constants/compliance-summary-reports.constant'
import ReportDateRangeConstant, {
  getReportDateRangeName,
} from '@lib/constants/report-date-range.constant'
import TimeHelper from '@lib/helpers/time.helper'
import ActionInterface from '@lib/interfaces/action.interface'
import {
  SET_COMPLIANCE_SUMMARY_REPORT_DATA,
  SET_COMPLIANCE_SUMMARY_REPORT_PERIOD,
  SET_COMPLIANCE_SUMMARY_REPORT_TYPE,
} from '@store/actions/reports.action'
import ComplianceSummaryReportState from '@store/states/compliance-summary-reports.state'

const initialState: ComplianceSummaryReportState = {
  reportPeriod: {
    period: {
      name: getReportDateRangeName(ReportDateRangeConstant.ThisMonth),
      value: ReportDateRangeConstant.ThisMonth,
    },
    dateRange: TimeHelper.currentMonthDateRange(),
  },
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  reportType: COMPLIANCE_REPORTS()[0]!,
  reportData: undefined,
}

function complianceSummaryReportReducer(
  prevState = initialState,
  action: ActionInterface
) {
  switch (action.type) {
    case SET_COMPLIANCE_SUMMARY_REPORT_PERIOD:
      return {
        ...prevState,
        reportPeriod: action.payload,
      }
    case SET_COMPLIANCE_SUMMARY_REPORT_TYPE:
      return {
        ...prevState,
        reportType: action.payload,
      }
    case SET_COMPLIANCE_SUMMARY_REPORT_DATA:
      return {
        ...prevState,
        reportData: action.payload,
      }
    default:
      return prevState
  }
}

export default complianceSummaryReportReducer
