/* eslint-disable import/no-extraneous-dependencies */
import PreloaderConstants from '@lib/constants/preloader.constant'
import {
  GET_ALL_RED_STACKS,
  INSTALL_RED_STACKS,
  REPAIR_VAULT,
  REQUEST_AWS_ACCOUNT_INFO_LIST,
  REQUEST_CF_LINK,
  REQUEST_CFN,
  REQUEST_CFN_SHADOW,
  REQUEST_DEPLOYMENT_FILES,
  REQUEST_IF_RED_STACK_EXIST,
  REQUEST_LIST_AVAILABILITY_ZONES,
  REQUEST_LIST_AWS_ACCOUNTS,
  REQUEST_LIST_AWS_SECURITY_GROUPS,
  REQUEST_POSSIBLE_REGIONS_LIST,
  REQUEST_POSSIBLE_VPC_LIST,
  REQUEST_POSSIBLE_VPC_LIST_BY_REGION_AND_ACCOUNT,
  requestPossibleVpcList,
  setAllRedStacks,
  setAwsAccountInfoList,
  setCFLink,
  setCfn,
  setDeploymentFiles,
  setIfRedStackExist,
  setListAvailabilityZones,
  setListAwsAccounts,
  setListAwsSecurityGroups,
  setPossibleRegionsList,
  setPossibleVpcList,
} from '@store/actions/rex.action'
import DeploymentFilesInterface from '@lib/interfaces/deployment-files.interface'
import ActionInterface from '@lib/interfaces/action.interface'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import {
  baseRequestScenario,
  sentryReThrowCatchHandler,
} from '@store/epics/epic-func'
import RedStackModel from '@lib/models/red-stack.model'
import VpcModel from '@lib/models/vpc.model'
import Router from 'next/router'
import StoreService from '@lib/services/store.service'
import { emptyAction } from '@store/actions/default/empty.action'
import ValueInterface from '@lib/interfaces/value.interface'
import CfnModel from '@lib/models/cfn.model'
import { CfnStatusConstant } from '@lib/constants/cfn-status.constant'
import CfnFactory from '@lib/factories/cfn.factory'
import RegexConstants from '@lib/constants/regex.constant'
import AwsAccountInfoModel from '@lib/models/aws-account-info.model'
import CloudDeploymentTaskInterface from '@lib/interfaces/deployment/cloud-deployment-task.interface'
import PagePathConstant from '@lib/constants/page-path.constant'
import ToastHelper from '@lib/helpers/toast.helper'
import GrpcRexService from '@lib/services/grpc/grpc-rex.service'
import GrpcVaultService from '@lib/services/grpc/grpc-vault.service'
import AvailabilityZoneModel from '@lib/models/availability-zone.model'
import AwsSecurityGroupsModel from '@lib/models/aws-security-groups.model'
import GrpcJobStatusService from '@lib/services/grpc/grpc-job-status.service'
import { ScheduleTenantJobUseCase } from '@features/scheduled-jobs-monitoring/use-cases'
import { ScheduledTenantJobsRepository } from '@features/scheduled-jobs-monitoring/repositories'
import { createLogger } from '@features/scheduled-jobs-monitoring/infrastructure/logging'
import { ScheduledTenantJobsClient } from '@features/scheduled-jobs-monitoring/clients'

const scheduleTenantJobUseCase = new ScheduleTenantJobUseCase(
  new ScheduledTenantJobsRepository(createLogger()),
  new ScheduledTenantJobsClient(),
  ToastHelper
)

export const installRedStacksEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [INSTALL_RED_STACKS],
      PreloaderConstants.INSTALL_RED_STACKS,

      // send all regions on the server and get an appropriate jobs ids
      (action: ActionInterface) => {
        const {
          accountId,
          isEnableSafetyLock,
          tasks,
        }: {
          accountId: string
          isEnableSafetyLock: boolean
          tasks: Array<CloudDeploymentTaskInterface>
        } = action.payload

        const requests = tasks.map((t) =>
          GrpcRexService.installRedstack(
            accountId,
            t.vpcId,
            t.regionName,
            isEnableSafetyLock,
            t.subnetIdsList
          )
        )

        return Promise.all(requests).catch(
          sentryReThrowCatchHandler('Cannot perform deployment')
        )
      },

      () => {
        Router.push(PagePathConstant.DEPLOYMENT)
        return emptyAction()
      },
      ErrorGroupConstants.REX
    )
  )

export const requestRexEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_DEPLOYMENT_FILES],
      PreloaderConstants.REQUEST_DEPLOYMENT_FILES,
      () => GrpcRexService.getDeploymentFiles(),
      (data: DeploymentFilesInterface) => setDeploymentFiles(data),
      ErrorGroupConstants.REX
    )
  )

export const getAllRedStacksEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [GET_ALL_RED_STACKS],
      PreloaderConstants.GET_ALL_RED_STACKS,
      () =>
        GrpcRexService.getAllRedstacks().catch(
          sentryReThrowCatchHandler('Cannot get a list of cloud connectors')
        ),
      (data: Array<RedStackModel>) => {
        return setAllRedStacks(data)
      },
      ErrorGroupConstants.REX
    )
  )

export const requestIfRedStackExistsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_IF_RED_STACK_EXIST],
      PreloaderConstants.REQUEST_IF_RED_STACK_EXIST,
      () =>
        GrpcRexService.getAllRedstacks()
          .then(async (data) => {
            const isRedStackInstalled = data.length > 0

            if (!isRedStackInstalled) {
              const isRedStackJobExist =
                await GrpcJobStatusService.isRedStackJob()
              return isRedStackJobExist
            }

            return isRedStackInstalled
          })
          .catch(
            sentryReThrowCatchHandler('Cannot get a list of cloud connectors')
          ),
      (exists: boolean) => {
        return setIfRedStackExist(exists)
      },
      ErrorGroupConstants.REX
    )
  )

export const requestPossibleVpcListEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_POSSIBLE_VPC_LIST],
      PreloaderConstants.REQUEST_POSSIBLE_VPC_LIST,
      (action: ActionInterface) =>
        GrpcRexService.listAwsVpcArr(action.payload).catch(
          sentryReThrowCatchHandler('Cannot get a list of VPC')
        ),
      (data: Array<VpcModel>) => {
        return setPossibleVpcList(data)
      },
      ErrorGroupConstants.REX
    )
  )

export const requestPossibleVpcListByRegionAndAccountEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_POSSIBLE_VPC_LIST_BY_REGION_AND_ACCOUNT],
      PreloaderConstants.REQUEST_POSSIBLE_VPC_LIST_BY_REGION_AND_ACCOUNT,
      (action: ActionInterface) => {
        const {
          accountId,
          region,
        }: {
          accountId: string
          region: string
        } = action.payload

        return GrpcRexService.listAwsVpcs(accountId, region).catch(
          sentryReThrowCatchHandler('Cannot get a list of VPC')
        )
      },
      (data: Array<VpcModel>) => {
        return setPossibleVpcList(data)
      },
      ErrorGroupConstants.REX
    )
  )

export const requestCFLinkEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_CF_LINK],
      PreloaderConstants.REQUEST_CF_LINK,
      () =>
        GrpcRexService.getCloudFormationLink().catch(
          sentryReThrowCatchHandler('Cannot get a CFN template link')
        ),
      (link: string) => setCFLink(link),
      ErrorGroupConstants.REX
    )
  )

export const requestCfnEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_CFN],
      PreloaderConstants.REQUEST_CFN,
      (action: ActionInterface) =>
        GrpcRexService.discoverInstalledCfn(action.payload)
          .then((cfnModel) => ({
            cfnModel,
            action,
          }))
          .catch(sentryReThrowCatchHandler('Cannot perform a CFN detection')),
      ({
        cfnModel,
        action,
      }: {
        cfnModel: CfnModel
        action: ActionInterface
      }) => {
        if (CfnStatusConstant.INSTALLED === cfnModel.status) {
          StoreService.dispatchAction(requestPossibleVpcList(action.payload))
        }
        return setCfn(cfnModel)
      },
      ErrorGroupConstants.REX
    )
  )

export const requestCfnShadowEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_CFN_SHADOW],
      PreloaderConstants.EMPTY,
      (action: ActionInterface) => {
        if (!RegexConstants.ACCOUNT_ID.test(action.payload)) {
          return Promise.resolve({
            model: CfnFactory.buildEmpty(),
            accountId: action.payload,
          })
        }

        return (
          GrpcRexService.discoverInstalledCfn(action.payload)
            .then((model) => ({
              model,
              accountId: action.payload,
            }))
            // ignore errors for the shadow handling
            .catch(() => ({
              model: CfnFactory.buildEmpty(),
              accountId: '',
            }))
        )
      },
      ({ model, accountId }: { model: CfnModel; accountId: string }) => {
        if (!model.isEmpty) {
          if (CfnStatusConstant.INSTALLED === model.status) {
            StoreService.dispatchAction(requestPossibleVpcList(accountId))
          }
          return setCfn(model)
        }
        return emptyAction()
      },
      ErrorGroupConstants.REX
    )
  )

export const requestPossibleRegionsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_POSSIBLE_REGIONS_LIST],
      PreloaderConstants.REQUEST_POSSIBLE_REGIONS_LIST,
      () =>
        GrpcRexService.listSupportedAwsRegions().catch(
          sentryReThrowCatchHandler('Cannot get a list of Regions')
        ),
      (data: Array<ValueInterface>) => setPossibleRegionsList(data),
      ErrorGroupConstants.REX
    )
  )

export const requestListAwsAccountInfoEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_AWS_ACCOUNT_INFO_LIST],
      PreloaderConstants.REQUEST_AWS_ACCOUNT_INFO_LIST,
      (action: ActionInterface) =>
        GrpcRexService.getAwsAccountInfo(action.payload).catch(
          sentryReThrowCatchHandler('Cannot get a list of Aws Account Info')
        ),
      (data: Array<AwsAccountInfoModel>) => setAwsAccountInfoList(data),
      ErrorGroupConstants.REX
    )
  )

export const repairVaultEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REPAIR_VAULT],
      PreloaderConstants.REPAIR_VAULT,
      (action: ActionInterface) =>
        GrpcVaultService.repairVault(
          action.payload.vaultName,
          action.payload.cloudConnectorId
        )
          .then((jobId) => ({
            jobId,
            cloudConnectorId: action.payload.cloudConnectorId,
          }))
          .catch(
            sentryReThrowCatchHandler('Cannot handle the repair vault request')
          ),
      async ({ jobId }: { jobId: string; cloudConnectorId: string }) => {
        await scheduleTenantJobUseCase.execute(jobId)
      },
      ErrorGroupConstants.REX
    )
  )

export const listAwsAccountsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_LIST_AWS_ACCOUNTS],
      PreloaderConstants.REQUEST_LIST_AWS_ACCOUNTS,
      () =>
        GrpcRexService.listAwsAccounts().catch((error: Error) => {
          ToastHelper.error(error.message)
          sentryReThrowCatchHandler('Cannot get a list of Aws Accounts')
        }),
      (data: Array<AwsAccountInfoModel>) => setListAwsAccounts(data),
      ErrorGroupConstants.REX
    )
  )

export const listAvailabilityZonesEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_LIST_AVAILABILITY_ZONES],
      PreloaderConstants.REQUEST_LIST_AVAILABILITY_ZONES,
      (action: ActionInterface) =>
        GrpcRexService.listAvailabilityZones(action.payload).catch(
          (error: Error) => {
            ToastHelper.error(
              `Cannot get a list of Availability Zones - ${error.message}`
            )
            sentryReThrowCatchHandler('Cannot get a list of Availability Zones')
          }
        ),
      (data: Array<AvailabilityZoneModel>) => setListAvailabilityZones(data),
      ErrorGroupConstants.REX
    )
  )

export const listAwsSecurityGroupsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_LIST_AWS_SECURITY_GROUPS],
      PreloaderConstants.REQUEST_LIST_AWS_SECURITY_GROUPS,
      (action: ActionInterface) =>
        GrpcRexService.listAwsSecurityGroups(
          action.payload.awsAccountId,
          action.payload.awsRegion
        ).catch((error: Error) => {
          ToastHelper.error(
            `Cannot get a list of Aws Security Groups - ${error.message}`
          )
          sentryReThrowCatchHandler('Cannot get a list of Aws Security Groups')
        }),
      (data: Array<AwsSecurityGroupsModel>) => setListAwsSecurityGroups(data),
      ErrorGroupConstants.REX
    )
  )
