import React from 'react'

function PlayIcon({ className = '' }) {
  return (
    <svg
      width="36"
      height="36"
      className={className}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6665 13.6833V22.3167C14.6665 22.975 15.3915 23.375 15.9498 23.0167L22.7332 18.7C23.2498 18.375 23.2498 17.625 22.7332 17.2917L15.9498 12.9833C15.3915 12.625 14.6665 13.025 14.6665 13.6833Z"
        fill="#4E5665"
      />
    </svg>
  )
}

export default PlayIcon
