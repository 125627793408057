import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/AddRounded'
import RemoveIcon from '@mui/icons-material/RemoveRounded'
import { Column } from './types'

interface Props<T> {
  data: Array<T>
  onRowClick?: (row: T) => void
}

export function useTable<T>({ data, onRowClick }: Props<T>) {
  const [expandedRows, setExpandedRows] = useState<Array<number>>([])

  const handleRowClick = (row: T) => {
    if (onRowClick) {
      onRowClick(row)
      return
    }

    const rowIndex = data.indexOf(row)
    if (rowIndex !== -1) {
      if (expandedRows.includes(rowIndex)) {
        setExpandedRows((prevRows) => prevRows.filter((r) => r !== rowIndex))
      } else {
        setExpandedRows((prevRows) => [...prevRows, rowIndex])
      }
    }
  }

  const renderExpandCollapseIcon = (
    column: Column<T>,
    row: T,
    rowIndex: number
  ) => {
    if (column.expand && column.expand.shouldBeExpanded(row)) {
      return (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          data-testid={`expand-collapse-icon-${rowIndex}`}
        >
          {expandedRows.includes(rowIndex) ? (
            <RemoveIcon
              onClick={() => handleRowClick(row)}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <AddIcon
              onClick={() => handleRowClick(row)}
              style={{ cursor: 'pointer' }}
            />
          )}
        </span>
      )
    }
    return null
  }

  const renderCellContent = (column: Column<T>, row: T) => {
    if (column.render) {
      return column.render(row, column)
    }
    const accessor = column.accessor as keyof T
    return row[accessor]
  }

  return {
    expandedRows,
    renderExpandCollapseIcon,
    renderCellContent,
  }
}
