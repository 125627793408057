/* eslint-disable import/no-extraneous-dependencies */
import { Request as GrpcListTenantJobsRequest } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/jobs/list_tenant_jobs_pb'
import { Job as JobPt } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/jobs/cloud_connector/job_pb'
import { TimeRange } from '@lib/clients/types'
import { EnumHelper } from '@lib/helpers'
import { AbstractListRequest } from '@lib/clients/list-request'
import {
  ListCloudConnectorJobsRequest,
  Timestamp,
} from '@lib/clients/grpc-imports'
import {
  JobCloudConnectorKind,
  CloudConnectorJobStatus,
} from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'

export interface ListCloudConnnectorJobsFilterRequestParams {
  assetIdsList?: Array<string>
  kindsList?: Array<JobCloudConnectorKind>
  statusesList?: Array<CloudConnectorJobStatus>
  ccIdsList?: Array<string>
  timeRange?: TimeRange
  pageSize?: number
}

export class ListCloudConnnectorJobsFilter extends AbstractListRequest<ListCloudConnectorJobsRequest> {
  #requestParams?: ListCloudConnnectorJobsFilterRequestParams

  constructor(filterParams?: ListCloudConnnectorJobsFilterRequestParams) {
    super()
    this.#requestParams = filterParams
  }

  createGrpcRequest(): ListCloudConnectorJobsRequest {
    const grpcRequest = ListCloudConnectorJobsRequest

    const initial = this.#setInitial()

    if (!this.#requestParams) {
      return new grpcRequest().setInitial(initial)
    }

    const filter = new grpcRequest.Initial.Filter()

    const { kindsList, timeRange, statusesList, assetIdsList } =
      this.#requestParams

    if (timeRange) {
      const timeRangeProto = this.#setupTimeRange({
        start: timeRange.start as number,
        end: timeRange.end as number,
      })

      filter.setTimeRange(timeRangeProto)
    }

    if (statusesList) {
      const statusesProtoList: Array<JobPt.Status> = []

      for (const status of statusesList) {
        statusesProtoList.push(this.#setJobStatus(status))
      }

      filter.setStatusesList(statusesProtoList)
    }

    if (kindsList) {
      const kindsProtoList: Array<JobPt.Kind> = []

      for (const kind of kindsList) {
        kindsProtoList.push(this.#setJobKind(kind))
      }

      filter.setKindsList(kindsProtoList)
    }

    if (assetIdsList) {
      filter.setAssetIdsList(assetIdsList)
    }

    return new grpcRequest().setInitial(
      initial
        .setFilter(filter)
        .setPageSize(
          this.#requestParams?.pageSize || AbstractListRequest.DEFAULT_PAGE_SIZE
        )
    )
  }

  #setInitial() {
    return new ListCloudConnectorJobsRequest.Initial().setPageSize(
      this.#requestParams?.pageSize || AbstractListRequest.DEFAULT_PAGE_SIZE
    )
  }

  #setupTimeRange({ start, end }: { start: number; end: number }) {
    const timeRange = new GrpcListTenantJobsRequest.Initial.Filter.TimeRange()
    if (start) {
      timeRange.setStart(this.#createTimestamp(start))
    }

    if (end) {
      timeRange.setEnd(this.#createTimestamp(end))
    }
    return timeRange
  }

  #createTimestamp(seconds: number) {
    const timestamp = new Timestamp()
    timestamp.setSeconds(seconds)
    return timestamp
  }

  #setJobKind(kind: JobCloudConnectorKind) {
    const ProtobufJobKind = JobPt.Kind

    const matchingKind = EnumHelper.getNumericEnumValues(ProtobufJobKind).find(
      (protobufKind) => protobufKind === kind
    )

    if (matchingKind === undefined) {
      throw new Error(`ListTenantJobsFilter: Invalid job kind: ${kind}`)
    }

    return matchingKind
  }

  #setJobStatus(status: CloudConnectorJobStatus): JobPt.Status {
    const ProtobufJobStatus = JobPt.Status

    const matchingStatus = EnumHelper.getNumericEnumValues(
      ProtobufJobStatus
    ).find((protobufStatus) => protobufStatus === status)

    if (matchingStatus === undefined) {
      throw new Error(`ListTenantJobsFilter: Invalid job status: ${status}`)
    }

    return matchingStatus
  }
}
