"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.snapshotsFactory = void 0;
const fishery_1 = require("fishery");
const models_1 = require("../../../models");
const common_1 = require("./common");
class SnapshotsFactory extends fishery_1.Factory {
    unencrypted() {
        return this.transient({
            encrypted: false,
        });
    }
    encrypted() {
        return this.transient({
            encrypted: true,
        });
    }
    withPublicPermissions() {
        return this.transient({
            publicPermissions: true,
        });
    }
    withPrivatePermissions() {
        return this.transient({
            publicPermissions: false,
        });
    }
}
const snapshotsFactory = SnapshotsFactory.define(({ sequence, transientParams }) => {
    return new models_1.SnapshotModel({
        accountId: transientParams.accountId ?? (0, common_1.awsAccountId)(),
        cloudConnectorId: transientParams.cloudConnectorId ?? (0, common_1.uuid)(),
        cloudProviderSnapshotId: transientParams.cloudProviderSnapshotId ?? (0, common_1.awsSnapshotId)(),
        cloudProviderVolumeId: transientParams.cloudProviderVolumeId ?? (0, common_1.awsVolumeId)(),
        cost: transientParams.cost ?? (0, common_1.awsCost)(),
        createdAt: transientParams.createdAt ?? Date.now(),
        encrypted: transientParams.encrypted ?? (0, common_1.bool)(),
        id: transientParams.id ?? (0, common_1.uuid)(),
        inventorySize: transientParams.inventorySize ?? Math.random(),
        name: transientParams.name ?? (0, common_1.name)('Snapshot', sequence),
        publicPermissions: transientParams.publicPermissions ?? (0, common_1.bool)(),
        region: transientParams.region ?? (0, common_1.awsRegion)(),
        storageType: transientParams.storageType ?? 'unknown',
        tags: transientParams.tags ?? new Map(),
        volumeId: transientParams.volumeId ?? (0, common_1.uuid)(),
    });
});
exports.snapshotsFactory = snapshotsFactory;
