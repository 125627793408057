import BaseModel from '@lib/models/base-model/base.model'
import ScheduleInterface from '@lib/interfaces/schedule.interface'
import { StringTupleArray } from '@lib/engine-types'
import IntegrityScanInterface from '@lib/interfaces/integrity-scan.interface'
import { ProtectNewImmediately } from '@lib/constants/policies.constant'
import { IntegrityCheckOptions } from '@lib/constants/integrity-check-options.constant'
import VaultModel from 'lib/models/vault.model'

// for policies "id" - is a unique name

class PolicyModel extends BaseModel {
  public readonly policyName: string

  public readonly schedule: ScheduleInterface

  public readonly tags: StringTupleArray

  public readonly iscan: IntegrityScanInterface

  public readonly assetsCount: number

  public readonly sourcesCount: number

  public readonly status: string

  public readonly started: boolean

  public readonly previousRun: number

  public readonly updatedAt: number

  public readonly kind: number

  public readonly integrityCheck: IntegrityCheckOptions

  public readonly protectNewImmediately: ProtectNewImmediately

  public readonly vaultList: Array<VaultModel>

  public readonly skipEbsBackup: boolean

  constructor(readonly params: any) {
    super(params)

    // base
    this.schedule = params.schedule
    this.tags = params.tags
    this.iscan = params.iscan
    this.assetsCount = params.assetsCount
    this.sourcesCount = params.sourcesCount
    this.policyName = params.policyName
    this.status = params.status
    this.started = params.started
    this.previousRun = params.previousRun
    this.updatedAt = params.updatedAt
    this.kind = params.kind
    this.integrityCheck = params.integrityCheck
    this.protectNewImmediately = params.protectNewImmediately
    this.vaultList = params.vaultList
    this.skipEbsBackup = params.skipEbsBackup

    //  extra
  }
}

export default PolicyModel
