import React from 'react'

function AssetItemStreamIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.49447 5L8.09769 10.5147L7.87397 9.70588H5.58941C5.37955 9.35551 4.99322 9.11765 4.54537 9.11765C3.87568 9.11765 3.33325 9.64412 3.33325 10.2941C3.33325 10.9441 3.87568 11.4706 4.54537 11.4706C4.99322 11.4706 5.37955 11.2327 5.58941 10.8824H6.94476L7.5958 13.2353H8.6564L9.87918 8.40763L10.9623 15H12.0324L12.9166 10.8824H14.4104C14.6203 11.2327 15.0066 11.4706 15.4545 11.4706C16.1242 11.4706 16.6666 10.9441 16.6666 10.2941C16.6666 9.64412 16.1242 9.11765 15.4545 9.11765C15.0066 9.11765 14.6203 9.35551 14.4104 9.70588H11.9317L11.5849 11.3189L10.5468 5H9.49447Z"
        fill="#1A1F28"
      />
    </svg>
  )
}

export default AssetItemStreamIcon
