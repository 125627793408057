"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fileFactory = void 0;
const file_1 = require("../../models/asset-items/file");
const factories_1 = require("../../use_cases/tests/factories");
const fishery_1 = require("fishery");
const common_1 = require("./common");
class FileFactory extends fishery_1.Factory {
}
exports.fileFactory = FileFactory.define(({ transientParams, sequence }) => {
    return new file_1.File({
        id: transientParams.id ?? (0, factories_1.uuid)(),
        assetId: transientParams.assetId ?? (0, factories_1.uuid)(),
        ccAssetId: transientParams.ccAssetId ?? (0, factories_1.uuid)(),
        path: transientParams.path ?? (0, factories_1.name)('Path', sequence),
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
    });
});
