import BaseDataConstant from '@lib/constants/data/base-data.constant'
import ValueInterface from '@lib/interfaces/value.interface'

// took from
// blue-stack-libs/js/catalog/messages/filters_pb.d.ts

class DataItemKindConstantInner extends BaseDataConstant<ValueInterface> {
  get KIND_UNDEFINED(): ValueInterface {
    return {
      name: '',
      value: -1,
      label: '',
    }
  }

  get KIND_AWS_EBS(): ValueInterface {
    return {
      name: 'aws_ebs',
      value: 0,
      label: 'EBS',
    }
  }

  get KIND_AWS_EFS(): ValueInterface {
    return {
      name: 'aws_efs',
      value: 1,
      label: 'EFS',
    }
  }

  get KIND_LOCAL_VOLUME(): ValueInterface {
    return {
      name: 'local_volume',
      value: 2,
      label: 'Local volume',
    }
  }

  get KIND_LOCAL_DISK(): ValueInterface {
    return {
      name: 'local_disk',
      value: 3,
      label: 'Local disk',
    }
  }

  get KIND_FILE(): ValueInterface {
    return {
      name: 'file',
      value: 4,
      label: 'File',
    }
  }

  get KIND_FS(): ValueInterface {
    return {
      name: 'fs',
      value: 5,
      label: 'File system',
    }
  }

  get KIND_STREAM(): ValueInterface {
    return {
      name: 'stream',
      value: 6,
      label: 'Stream Backup',
    }
  }

  get KIND_AWS_S3(): ValueInterface {
    return {
      name: 's3_bucket',
      value: 7,
      label: 'S3 Bucket',
    }
  }

  get KIND_AWS_S3_OBJECTS(): ValueInterface {
    return {
      name: 's3_objects',
      value: 8,
      label: 'S3 Objects',
    }
  }
}

const DataItemKindConstant = DataItemKindConstantInner.build(
  DataItemKindConstantInner,
  'KIND_'
)

export default DataItemKindConstant
