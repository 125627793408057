import { Request as ListScansRequest } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/scans/list_scans_for_asset_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { AbstractListRequest } from '@lib/clients/list-request'
import { ListScansForAssetFiltersParams } from './types'

export class ListScansForAssetFilter extends AbstractListRequest<ListScansRequest> {
  #requestParams: Partial<ListScansForAssetFiltersParams>

  constructor(requestParams: Partial<ListScansForAssetFiltersParams>) {
    super()
    this.#requestParams = requestParams
  }

  createGrpcRequest(): ListScansRequest {
    const grpcRequest = ListScansRequest

    const filter = this.#buildFilter()

    return new grpcRequest().setInitial(
      new ListScansRequest.Initial()
        .setFilter(filter)
        .setPageSize(
          this.#requestParams.pageSize || AbstractListRequest.DEFAULT_PAGE_SIZE
        )
    )
  }

  #createTimestamp(seconds: number) {
    const timestamp = new Timestamp()
    timestamp.setSeconds(seconds)
    return timestamp
  }

  #setupTimeRange({ start, end }: { start: number; end: number }) {
    const timeRange = new ListScansRequest.Initial.Filter.TimeRange()
    if (start) {
      timeRange.setStart(this.#createTimestamp(start))
    }

    if (end) {
      timeRange.setEnd(this.#createTimestamp(end))
    }
    return timeRange
  }

  #buildFilter() {
    const filter = new ListScansRequest.Initial.Filter()
    const { timeRange, assetIdList, assetItemId, onlyForAsset } =
      this.#requestParams

    if (assetIdList && assetIdList.length) {
      filter.setAssetIdList(assetIdList)
    }

    if (assetItemId && assetItemId.length) {
      filter.setAssetItemId(assetItemId)
    }

    if (onlyForAsset) {
      filter.setNone(new Empty())
    } else {
      filter.setAnyOrNone(new Empty())
    }

    if (timeRange) {
      const timeRangeProto = this.#setupTimeRange({
        start: timeRange.start as number,
        end: timeRange.end as number,
      })
      filter.setTimeRange(timeRangeProto)
    }

    return filter
  }
}
