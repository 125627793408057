/* eslint-disable import/no-extraneous-dependencies */
import { Job as ProtobufJob } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/jobs/cloud_connector/job_pb'
import { CloudConnectorJobStatus } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'

export class CloudConnectorJobStatusTransformer {
  #status: ProtobufJob.Status

  constructor(status: ProtobufJob.Status) {
    this.#status = status
  }

  transform() {
    const mapping = {
      [ProtobufJob.Status.STATUS_SCHEDULED]: CloudConnectorJobStatus.SCHEDULED,
      [ProtobufJob.Status.STATUS_STARTING]: CloudConnectorJobStatus.STARTING,
      [ProtobufJob.Status.STATUS_INPROGRESS]:
        CloudConnectorJobStatus.IN_PROGRESS,
      [ProtobufJob.Status.STATUS_FAILED]: CloudConnectorJobStatus.FAILED,
      [ProtobufJob.Status.STATUS_SUCCEEDED]: CloudConnectorJobStatus.SUCCEEDED,
      [ProtobufJob.Status.STATUS_ABORTED]: CloudConnectorJobStatus.ABORTED,
    }

    return mapping[this.#status]
  }
}
