import React from 'react'

function ToastErrorIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7667 0.5H5.23333C5.01667 0.5 4.8 0.591667 4.65 0.741667L0.741667 4.65C0.591667 4.8 0.5 5.01667 0.5 5.23333V10.7583C0.5 10.9833 0.591667 11.1917 0.741667 11.35L4.64167 15.25C4.8 15.4083 5.01667 15.5 5.23333 15.5H10.7583C10.9833 15.5 11.1917 15.4083 11.35 15.2583L15.25 11.3583C15.4083 11.2 15.4917 10.9917 15.4917 10.7667V5.23333C15.4917 5.00833 15.4 4.8 15.25 4.64167L11.35 0.741667C11.2 0.591667 10.9833 0.5 10.7667 0.5ZM8 12.4167C7.4 12.4167 6.91667 11.9333 6.91667 11.3333C6.91667 10.7333 7.4 10.25 8 10.25C8.6 10.25 9.08333 10.7333 9.08333 11.3333C9.08333 11.9333 8.6 12.4167 8 12.4167ZM8 8.83333C7.54167 8.83333 7.16667 8.45833 7.16667 8V4.66667C7.16667 4.20833 7.54167 3.83333 8 3.83333C8.45833 3.83333 8.83333 4.20833 8.83333 4.66667V8C8.83333 8.45833 8.45833 8.83333 8 8.83333Z"
        fill="#FF7878"
      />
    </svg>
  )
}

export default ToastErrorIcon
