import UserModel from '@lib/models/user.model'
import SessionModel from '@lib/models/session.model'
import UserRoleModel from '@lib/models/user-role.model'

export const getUsersList = (state: any): Array<UserModel> =>
  state.userState.usersList
export const getUser = (state: any): UserModel => state.userState.user
// eslint-disable-next-line max-len
export const getUserRoleScopes = (state: any): Array<string> =>
  state.userState.user.role.scopesList
export const getSessions = (state: any): Array<SessionModel> =>
  state.userState.sessions
// eslint-disable-next-line max-len
export const getPossibleRoles = (state: any): Array<UserRoleModel> =>
  state.userState.possibleRoles
export const getIsMfaEnabled = (state: any): boolean =>
  state.userState.isMfaEnabled
