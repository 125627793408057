import StrHelper from '@lib/helpers/str.helper'
import ValueInterface from '@lib/interfaces/value.interface'
import { VIRow } from '@lib/engine-types'
import UserModel from '@lib/models/user.model'

abstract class ScopeHelper {
  // aggregated by categories for grouped checkboxes
  // we are doing it because in future we can get list of scopes using API
  // or add/remove new scopes
  public static getScopeValues(
    possibleScopes: Array<string>
  ): Array<ValueInterface> {
    const result: Array<ValueInterface> = []
    possibleScopes.forEach((value: string) => {
      const [label, action] = value.split(':').map((v) => v.trim())
      const index = result.findIndex((v) => v.name === label)
      // add new
      if (index === -1) {
        result.push({
          name: label ?? '',
          label: StrHelper.buildHumanLabel(label ?? ''),
          options: [
            {
              name: value,
              label: StrHelper.buildHumanLabel(action ?? ''),
              value: false,
            },
          ],
        })
        return
      }
      // add into existing
      result[index]?.options?.push({
        name: value,
        label: StrHelper.buildHumanLabel(action ?? ''),
        value: false,
      })
    })
    return result
  }

  public static getSelectedScopeValues(
    selectedScopes: Array<string>,
    possibleScopes: Array<string>
  ): Array<ValueInterface> {
    const result: Array<ValueInterface> =
      ScopeHelper.getScopeValues(possibleScopes)
    result.forEach((value: ValueInterface) => {
      value.options?.forEach((option: ValueInterface) => {
        if (selectedScopes.includes(option.name)) {
          // eslint-disable-next-line no-param-reassign
          option.value = true
        }
      })
    })
    return result
  }

  public static selectedValuesToStrings(
    scopes: Array<ValueInterface>
  ): Array<string> {
    return scopes
      .map(
        (scope) =>
          scope.options?.filter((v) => v.value).map((s) => s.name) ?? []
      )
      .reduce((acc, curVal) => acc.concat(curVal), [])
  }

  public static isAnySelected(scopes: VIRow): boolean {
    return scopes.some((scope) => scope.options?.some((opt) => opt.value))
  }

  public static isUserHasAnyScopes(user: UserModel, scopes: VIRow): boolean {
    return scopes.some((scope) => user.role.scopesList.includes(scope.name))
  }

  public static isUserHasAllScopes(user: UserModel, scopes: VIRow): boolean {
    return scopes.every((scope) => user.role.scopesList.includes(scope.name))
  }
}

export default ScopeHelper
