import { EntropyDetectionNotificationsStatus as EntropyDetectionNotificationsStatusPb } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/tenant_settings/tenant_settings_pb'
import { TenantSettings } from '@lib/models'
import { TenantSettingsTransformer } from '@lib/transformers'
import { GrpcClient } from '../grpc-client'
import {
  GetTenantSettingsRequest,
  TenantSettingsModelPb,
  TenantSettingsPromiseClient,
  UpdateTenantSettingsRequest,
} from '../grpc-imports'
import { UpdateTenantSettingsRequestAttrs } from './types'
import { EntropyDetectionNotificationsStatus } from '@lib/models/tenant-settings/types'

export class TenantSettingsClient extends GrpcClient<TenantSettingsPromiseClient> {
  #client: TenantSettingsPromiseClient

  constructor(hostName = '') {
    super()
    this.#client = this.getClient(hostName)
  }

  protected innerClientTypeId(): string {
    return 'TenantSettingsClient'
  }

  protected initClient(hostname = ''): TenantSettingsPromiseClient {
    return new TenantSettingsPromiseClient(hostname, null, null)
  }

  async getTenantSettings(): Promise<TenantSettings> {
    const request = new GetTenantSettingsRequest()

    const response = (
      await this.retryGrpcCall(
        () => this.#client.getTenantSettings(request, this.metadata()),
        {
          requestName: 'TenantSettingsPromiseClient/getTenantSettings',
        }
      )
    ).toObject()

    return new TenantSettingsTransformer(response.tenantSettings!).transform()
  }

  async updateTenantSettings({
    entropyDetectionStatus,
  }: UpdateTenantSettingsRequestAttrs): Promise<TenantSettings> {
    const request = new UpdateTenantSettingsRequest()

    const tenantSettings = new TenantSettingsModelPb()

    if (entropyDetectionStatus) {
      tenantSettings.setEntropyDetectionNotifications(
        this.#transformEntropyDetectionStatusToProto(entropyDetectionStatus)
      )
    }

    request.setTenantSettings(tenantSettings)

    await this.retryGrpcCall(
      () => this.#client.updateTenantSettings(request, this.metadata()),
      {
        requestName: 'TenantSettingsPromiseClient/updateTenantSettings',
      }
    )

    return this.getTenantSettings()
  }

  #transformEntropyDetectionStatusToProto(
    status: EntropyDetectionNotificationsStatus
  ): EntropyDetectionNotificationsStatusPb {
    const mapping: Record<
      EntropyDetectionNotificationsStatus,
      EntropyDetectionNotificationsStatusPb
    > = {
      [EntropyDetectionNotificationsStatus.DISABLED]:
        EntropyDetectionNotificationsStatusPb.ENTROPY_DETECTION_NOTIFICATIONS_DISABLED,
      [EntropyDetectionNotificationsStatus.ENABLED]:
        EntropyDetectionNotificationsStatusPb.ENTROPY_DETECTION_NOTIFICATIONS_ENABLED,
    }

    return mapping[status]
  }
}
