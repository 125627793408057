import { useState, useEffect, useCallback } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { TokenState } from '../types'

export const useSPAAccessToken = (): TokenState => {
  const { getIdTokenClaims, getAccessTokenWithPopup } = useAuth0()
  const [token, setToken] = useState<TokenState['token']>(undefined)
  const [isTokenLoading, setIsTokenLoading] =
    useState<TokenState['isTokenLoading']>(false)
  const [tokenError, setTokenError] =
    useState<TokenState['tokenError']>(undefined)

  const fetchAccessToken = useCallback(async () => {
    try {
      setIsTokenLoading(true)
      setTokenError(undefined)
      const claims = await getIdTokenClaims()
      setToken(claims?.__raw)
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (error.message !== 'login_required') {
          console.error('Error fetching access token silently:', error)
          setTokenError(error)
          return
        }

        try {
          const accessToken = await getAccessTokenWithPopup({})
          setToken(accessToken)
        } catch (popupError: any) {
          console.error('Error fetching access token with popup:', popupError)
          setTokenError(popupError)
        }
      }
    } finally {
      setIsTokenLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchAccessToken()
  }, [fetchAccessToken])

  return {
    token,
    isTokenLoading,
    tokenError,
  }
}
