"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListEfsWithThreats = void 0;
const malware_1 = require("../../models/scans/malware");
const ransomware_1 = require("../../models/scans/ransomware");
const types_1 = require("../../models/threats/types");
class ListEfsWithThreats {
    #filesystems;
    #threats;
    #lastScans;
    #filesystemsWithScans;
    constructor(parameters) {
        this.#filesystems = parameters.filesystems;
        this.#threats = parameters.threats;
        this.#lastScans = parameters.lastScans;
        this.#filesystemsWithScans ||= this.#getFilesystemsWithScans();
    }
    execute(filters) {
        const filesystems = this.#findFilesystemMatchingFilters(filters);
        return this.#prepareOutput(filesystems);
    }
    #prepareOutput(filesystems) {
        return filesystems.map((filesystem) => {
            return {
                isInfected: this.#isInfected(filesystem),
                name: filesystem.name,
                efsId: filesystem.id,
                efsAwsId: filesystem.awsId,
                threats: this.#getThreatsForFilesystem(filesystem),
                accountId: filesystem.awsAccountId,
                region: filesystem.awsRegion,
                lastScan: this.#getLastScanForFilesystem(filesystem),
                status: filesystem.state,
            };
        });
    }
    #isInfected(filesystem) {
        return this.#getThreatsForFilesystem(filesystem).length > 0;
    }
    #getThreatsForFilesystem(filesystem) {
        return this.#threats.filter(({ source: { asset, assetItem } }) => {
            return (asset?.assetId === filesystem.id || assetItem?.assetId === filesystem.id);
        });
    }
    #getFilesystemsWithThreats() {
        return this.#filesystems.filter((filesystem) => this.#isInfected(filesystem));
    }
    #getLastScanForFilesystem(filesystem) {
        const scan = this.#filesystemsWithScans.find((scan) => scan.filesystem.id === filesystem.id);
        if (!scan) {
            return null;
        }
        const scansTime = scan.scans.map(({ createdAt }) => createdAt.getTime());
        return new Date(Math.max(...scansTime));
    }
    #mapFilesystemToScans(filesystem) {
        const scans = this.#filterScansForFilesystem(filesystem, this.#lastScans);
        return { filesystem, scans };
    }
    #getFilesystemsWithScans() {
        return this.#filesystems.map(this.#mapFilesystemToScans.bind(this));
    }
    #findFilesystemMatchingFilters(filters) {
        return this.#getFilesystemsWithThreats().filter((filesystem) => {
            if (!this.#matchesIncludeFilter(filters.accountIds, filesystem.awsAccountId)) {
                return false;
            }
            if (!this.#matchesIncludeFilter(filters.regions, filesystem.awsRegion)) {
                return false;
            }
            if (filters.malwaresList &&
                !this.#getEfsThreatByKind(filesystem, types_1.ThreatKind.MALWARE).some((malware) => filters.malwaresList?.includes(malware))) {
                return false;
            }
            if (filters.ransomwaresList &&
                !this.#getEfsThreatByKind(filesystem, types_1.ThreatKind.RANSOMWARE).some((ransomware) => filters.ransomwaresList?.includes(ransomware))) {
                return false;
            }
            if (filters.fsCheck &&
                !this.#getEfsThreatByKind(filesystem, types_1.ThreatKind.FILESYSTEM_ERROR).some((fs) => filters.fsCheck?.includes(fs))) {
                return false;
            }
            if (filters.idSearch &&
                !this.#getEfsSearchMatches(filters.idSearch, filesystem)) {
                return false;
            }
            return true;
        });
    }
    #getEfsSearchMatches(searchValue, efs) {
        const idMatches = efs.awsId.includes(searchValue);
        const nameMatches = efs.name.includes(searchValue);
        const tagsMatches = efs.tags.some(({ key, value }) => key.includes(searchValue) || value.includes(searchValue));
        return idMatches || nameMatches || tagsMatches;
    }
    #matchesIncludeFilter(filters, value) {
        if (!filters) {
            return true;
        }
        if (!value) {
            return false;
        }
        return filters.includes(value);
    }
    #getEfsThreatByKind(filesystem, kind) {
        return this.#threats
            .filter((threat) => {
            const isEfsThreat = threat.source.asset?.assetId === filesystem.id ||
                threat.source.assetItem?.assetId === filesystem.id;
            return isEfsThreat && threat.kind === kind;
        })
            .map((threat) => threat.name);
    }
    #isRansomwareScanTarget(target, filesystem) {
        if (target instanceof ransomware_1.RansomwareScanTarget) {
            if ('assetId' in target.target) {
                return target.target.assetId === filesystem.id;
            }
            if (target instanceof ransomware_1.RansomwareScanBackup && target.source.asset) {
                return target.source.asset.backupAssetId === filesystem.id;
            }
        }
    }
    #isMalwareScanTarget(target, filesystem) {
        if (target instanceof malware_1.MalwareScanTarget) {
            if ('assetId' in target.target) {
                return target.target.assetId === filesystem.id;
            }
            if (target instanceof malware_1.MalwareScanBackup && target.source.asset) {
                return target.source.asset.backupAssetId === filesystem.id;
            }
        }
    }
    #filterScansForFilesystem(filesystem, scans) {
        return scans.filter((scan) => {
            const ransomwareScanMatches = this.#isRansomwareScanTarget(scan.scanTarget, filesystem);
            const malwareScanMatches = this.#isMalwareScanTarget(scan.scanTarget, filesystem);
            return ransomwareScanMatches || malwareScanMatches;
        });
    }
}
exports.ListEfsWithThreats = ListEfsWithThreats;
