import { flexRender, Row } from '@tanstack/react-table'
import TableRow from '@mui/material/TableRow'
import React from 'react'
import { styled } from '@mui/material/styles'

import { TableCellStyled } from '@components/table/shared/styles'

interface Props<T> {
  row: Row<T>
}

const StyledTableRow = styled(TableRow)(({ index }: { index: number }) => ({
  backgroundColor: index % 2 === 0 ? 'var(--grey50)' : 'inherit',
}))

function TableRowComponent<T>(props: Props<T>) {
  const { row, ...restProps } = props
  return (
    <StyledTableRow {...restProps} index={row.index}>
      {row.getVisibleCells().map((cell) => (
        <TableCellStyled
          wordBreak={cell.column.columnDef.meta?.workBreak}
          align={cell.column.columnDef.meta?.textAlign}
          key={cell.id}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </TableCellStyled>
      ))}
    </StyledTableRow>
  )
}

export default TableRowComponent
