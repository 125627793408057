"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListInventorySnapshots = void 0;
const share_1 = require("../share");
__exportStar(require("./types"), exports);
class ListInventorySnapshots {
    inventory;
    constructor(inventory) {
        this.inventory = inventory;
    }
    execute(filters) {
        return this.prepareOutput(this.findSnapshotsMatchingFilters(filters));
    }
    prepareOutput(snapshots) {
        const snapshotsOutput = snapshots.map(({ snapshot, volumeName }) => {
            return {
                snapshotId: snapshot.getParsedCloudProviderSnapshotId(),
                snapshotName: snapshot.getName(),
                volumeId: snapshot.getParsedCloudProviderVolumeId(),
                volumeName,
                accountId: snapshot.getAccountId(),
                region: snapshot.getRegion(),
                inventorySize: snapshot.getInventorySize(),
            };
        });
        const summarizedData = this.summarizeSnapshotData(snapshots);
        return {
            snapshots: snapshotsOutput,
            summarizedData,
        };
    }
    summarizeSnapshotData(snapshots) {
        const snapshotsCount = new Set([
            ...snapshots.map(({ snapshot }) => snapshot.getCloudProviderSnapshotId()),
        ]).size;
        const volumesCount = new Set([
            ...snapshots.map(({ snapshot }) => snapshot.getCloudProviderVolumeId()),
        ]).size;
        const accountsCount = new Set([
            ...snapshots.map(({ snapshot }) => snapshot.getAccountId()),
        ]).size;
        const regionsCount = new Set([
            ...snapshots.map(({ snapshot }) => snapshot.getRegion()),
        ]).size;
        const inventorySize = snapshots.reduce((accum, { snapshot }) => accum + snapshot.getInventorySize(), 0);
        return {
            snapshotsCount,
            volumesCount,
            accountsCount,
            regionsCount,
            inventorySize,
        };
    }
    findSnapshotsMatchingFilters(filters) {
        return this.inventory
            .getSnapshotList()
            .filter((snapshot) => {
            if (!(0, share_1.matchesIncludeFilter)(filters.accountIds, snapshot.getAccountId())) {
                return false;
            }
            if (!(0, share_1.matchesIncludeFilter)(filters.regions, snapshot.getRegion())) {
                return false;
            }
            if (filters.idSearch &&
                !this.inventory.snapshotSearchMatches(filters.idSearch, snapshot)) {
                return false;
            }
            return true;
        })
            .map((snapshot) => {
            return {
                snapshot,
                volumeName: this.getVolumeName(snapshot),
            };
        });
    }
    getVolumeName(snapshot) {
        const volume = this.inventory
            .getVolumeList()
            ?.find((volume) => volume.getId() === snapshot.getVolumeId());
        return volume?.getName() ?? '';
    }
}
exports.ListInventorySnapshots = ListInventorySnapshots;
