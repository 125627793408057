import PolicyModel from '@lib/models/policy.model'
import NumHelper from '@lib/helpers/num.helper'
import TypeHelper from '@lib/helpers/type.helper'

abstract class PolicyFactory {
  public static build(params: any): PolicyModel {
    return new PolicyModel({
      id: NumHelper.numberHash(params.id),
      innerId: TypeHelper.stringValDef(params.id, ''),
      policyName: TypeHelper.stringValDef(params.id, ''),
      schedule: params.schedule,
      tags: TypeHelper.arrayStringValDef(params.tags, []),
      iscan: TypeHelper.booleanValDef(params.iscan, false),
      assetsCount: TypeHelper.numberValDef(params.assetsCount, 0),
      sourcesCount: TypeHelper.numberValDef(params.sourcesCount, 0),
      status: TypeHelper.stringValDef(params.status, ''),
      started: TypeHelper.booleanValDef(params.started, false),
      previousRun: TypeHelper.numberValDef(params.previousRun, 0),
      updatedAt: TypeHelper.numberValDef(params.updatedAt, 0),
      kind: TypeHelper.numberValDef(params.kind, -1),
      integrityCheck: TypeHelper.numberValDef(params.integrityCheck, -1),
      protectNewImmediately: TypeHelper.numberValDef(
        params.protectNewImmediately,
        -1
      ),
      vaultsList: TypeHelper.arrayStringValDef(params.vaultsList, []),
      skipEbsBackup: TypeHelper.booleanValDef(params.iscan, false),
    })
  }

  public static buildEmptyPolicy(): PolicyModel {
    return new PolicyModel({
      id: '',
      innerId: '',
      policyName: '',
      schedule: [],
      tags: [],
      iscan: false,
      assetsCount: 0,
      sourcesCount: 0,
      status: '',
      started: false,
      kind: -1,
      integrityCheck: -1,
      protectNewImmediately: -1,
      vaultsList: [],
      skipEbsBackup: false,
    })
  }
}

export default PolicyFactory
