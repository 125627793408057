/* eslint-disable import/no-extraneous-dependencies */
import { GrpcClient } from '@lib/clients/grpc-client'
import { OvaAccount } from 'blues-corejs/dist'
import { OvaAccountTransformer } from '@lib/clients/assets/transformers'
import {
  OvaAccountsPromiseClient,
  ListOvaAccountsRequest,
} from '@lib/clients/grpc-imports'

export class ListOvaAccountsClient extends GrpcClient<OvaAccountsPromiseClient> {
  #listOvaAccountsClient: OvaAccountsPromiseClient

  constructor(hostName = '') {
    super()
    this.#listOvaAccountsClient = this.getClient(hostName)
  }

  protected innerClientTypeId(): string {
    return 'ListOvaAccountsClient'
  }

  protected initClient(hostName = ''): OvaAccountsPromiseClient {
    return new OvaAccountsPromiseClient(hostName, null, null)
  }

  async getListOvaAccounts(): Promise<Array<OvaAccount>> {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#listOvaAccountsClient.listOvaAccounts(
            new ListOvaAccountsRequest(),
            this.metadata()
          ),
        {
          requestName: 'OvaAccountsPromiseClient/listOvaAccounts',
        }
      )
    ).toObject()

    return response.ovaAccountsList.map((ovaAccount) =>
      new OvaAccountTransformer(ovaAccount).transform()
    )
  }
}
