import { useCallback, useEffect, useState } from 'react'
import SettingsService from '@lib/services/high/settings.service'
import { UsersClient } from '@lib/clients'

export const useFetchUserConfig = (usersClient: UsersClient): boolean => {
  const [isConfigFetched, setConfigFetched] = useState(false)

  const fetchUserConfig = useCallback(async () => {
    const response = await usersClient.getUserConfig()
    SettingsService.init(response)
    setConfigFetched(true)
  }, [])

  useEffect(() => {
    fetchUserConfig()
  }, [fetchUserConfig])

  return isConfigFetched
}
