export enum UserSettings {
  OnBoardingCongratulationsShown = 'OnBoardingCongratulationsShown',
  Scenarios = 'Scenarios_v2',
  CfnUpgrade = 'CFN was deployed',
  RsUpgrade = 'RS upgrade',
  TimeZone = 'TimeZone',
  HideFirstProtectionPolicy = 'ShowFirstProtectionPolicy',
  TabViews = 'TabViews',
}

export const USER_SETTINGS_VERSIONS: {
  [key: string]: number
} = {
  [UserSettings.OnBoardingCongratulationsShown]: 1,
  [UserSettings.Scenarios]: 2,
}
