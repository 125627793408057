"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ec2Factory = void 0;
const ec2_1 = require("../../models/assets/aws/ec2");
const factories_1 = require("../../use_cases/tests/factories");
const fishery_1 = require("fishery");
const common_1 = require("./common");
const types_1 = require("../../models/assets/aws/ec2/types");
class EC2Factory extends fishery_1.Factory {
}
const ebsIds = () => Array.from({ length: 5 }, factories_1.awsVolumeId);
function osKind() {
    const kinds = [types_1.OsKind.LINUX, types_1.OsKind.WINDOWS, types_1.OsKind.OTHER];
    return kinds[Math.floor(Math.random() * kinds.length)].toString();
}
exports.ec2Factory = EC2Factory.define(({ transientParams }) => {
    return new ec2_1.EC2({
        id: transientParams.id ?? (0, factories_1.uuid)(),
        arn: transientParams.arn ?? (0, factories_1.uuid)(),
        awsAccountId: transientParams.awsAccountId ?? (0, factories_1.awsAccountId)(),
        awsRegion: transientParams.awsRegion ?? (0, factories_1.awsRegion)(),
        awsId: transientParams.awsId ?? (0, factories_1.awsInstanceId)(),
        state: transientParams.state ?? (0, common_1.randomValueFromEnum)(types_1.EC2State),
        type: transientParams.type ?? {
            family: '',
            size: '',
            spot: false,
        },
        hasReplicatedBackups: transientParams.hasReplicatedBackups ?? (0, factories_1.bool)(),
        tags: transientParams.tags ?? [],
        amiDetails: transientParams.amiDetails ?? {
            awsId: '',
            name: '',
            location: '',
        },
        ccAssetId: transientParams.ccAssetId ?? (0, factories_1.awsInstanceId)(),
        retentionPolicy: transientParams.retentionPolicy ?? [],
        backupPolicies: transientParams.backupPolicies ?? [],
        ebsIds: transientParams.ebsIds ?? ebsIds(),
        osKind: transientParams.osKind ?? osKind(),
        awsVpcId: transientParams.awsVpcId ?? (0, factories_1.uuid)(),
        startedAt: transientParams.startedAt ?? (0, common_1.date)(),
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
    });
});
