import React, { useCallback } from 'react'
import BaseModal from '@components-composite/modals/BaseModal'
import { EngineCallback, EngineCallbackPure, Nullable } from '@lib/engine-types'
import WarningIcon from '@mui/icons-material/Warning'
import { Button } from '@mui/material'

export interface AccessDeniedModalInputData {
  message: string
}

export interface AccessDeniedModalData {
  onCancel: EngineCallbackPure
  onSubmit: EngineCallback<any>
  data: Nullable<AccessDeniedModalInputData>
  show: boolean
}

function AccessDeniedModal({
  onCancel,
  onSubmit,
  data,
  show,
}: AccessDeniedModalData) {
  const onSubmitCallback = useCallback(() => {
    onSubmit(data)
  }, [data])

  const onCancelCallback = useCallback(() => {
    onCancel()
  }, [])

  return (
    <BaseModal
      className="modalDefaultV2Small modalWarningV2"
      onBgClick={onCancelCallback}
      show={show}
    >
      <div className="modalWarningIconV2">
        <WarningIcon />
      </div>
      <div className="modalWarningContentV2">
        <div>{data?.message}</div>
      </div>

      <div className="modalFooterWarningV2">
        <Button
          className="mfSubmit jsSubmit"
          data-testid="jsSubmit"
          variant="outlined"
          type="button"
          onClick={onSubmitCallback}
        >
          Close
        </Button>
      </div>
    </BaseModal>
  )
}

export default AccessDeniedModal
