import { useEffect } from 'react'
import { getIsRedStackExist } from '@store/selectors/rex.selector'
import { useDispatch, useSelector } from 'react-redux'
import PagePathConstant from '@lib/constants/page-path.constant'
import { requestIfRedStackExist } from '@store/actions/rex.action'
import { useRouter } from 'next/router'

export function useInitOnboarding() {
  const dispatch = useDispatch()
  const router = useRouter()

  const isRedStackExists = useSelector(getIsRedStackExist)

  const pagesWithoutCloudConnectors = [
    PagePathConstant.ONBOARDING,
    PagePathConstant.SETTINGS,
    PagePathConstant.CLOUD_CONFIGURE_DEPLOYMENT,
  ]

  useEffect(() => {
    dispatch(requestIfRedStackExist())
  }, [])

  useEffect(() => {
    if (
      !pagesWithoutCloudConnectors.includes(window.location.pathname) &&
      isRedStackExists === false
    ) {
      router.push(PagePathConstant.ONBOARDING)
    }
  }, [isRedStackExists])

  return {
    isRedStackExists,
  }
}
