import { SET_TENANT_NOTIFICATIONS_SETTINGS } from './../actions/tenant-notifications.action'
import ActionInterface from '@lib/interfaces/action.interface'
import TenantNotificationsState from '@store/states/tenant-notifications.state'

const initialState: TenantNotificationsState = {
  slackNotificationSettings: {
    sendErrors: false,
    sendWarnings: false,
    sendInfo: false,
  },
  emailNotificationSettings: {
    sendErrors: false,
    sendWarnings: false,
    sendInfo: false,
    emailsList: [],
  },
  slackSetupUrl: '',
}

function tenantNotificationsReducer(
  prevState = initialState,
  action: ActionInterface
) {
  switch (action.type) {
    case SET_TENANT_NOTIFICATIONS_SETTINGS:
      return {
        ...prevState,
        slackNotificationSettings: action.payload.slackNotificationSettings,
        emailNotificationSettings: action.payload.emailNotificationSettings,
        slackSetupUrl: action.payload.slackSetupUrl,
      }
    default:
      return prevState
  }
}

export default tenantNotificationsReducer
