"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListEfsInventory = void 0;
const share_1 = require("../share");
const types_1 = require("../../models/assets/aws/efs/types");
const malware_1 = require("../../models/scans/malware");
const ransomware_1 = require("../../models/scans/ransomware");
class ListEfsInventory {
    #filesystems;
    #threats;
    #lastScans;
    #filesystemsWithScans;
    constructor(parameters) {
        this.#filesystems = parameters.filesystems;
        this.#threats = parameters.threats;
        this.#lastScans = parameters.scans;
        this.#filesystemsWithScans ||= this.#getFilesystemsWithScans();
    }
    execute(filters) {
        return this.#prepareOutput(this.#findEfsMatchingFilters(filters));
    }
    #prepareOutput(filesystems) {
        const efsOutput = filesystems.map((filesystem) => {
            return {
                uuid: filesystem.id,
                isInfected: this.#isInfected(filesystem),
                name: filesystem.name,
                size: 0,
                accountId: filesystem.awsAccountId,
                region: filesystem.awsRegion,
                state: filesystem.state,
                backupPolicies: filesystem.backupPolicies,
                arn: filesystem.arn,
                awsId: filesystem.awsId,
                lastScan: this.#getLastScanForFilesystem(filesystem),
            };
        });
        const summarizedData = this.#summarizeEfsData(efsOutput);
        return {
            efs: efsOutput,
            summarizedData,
        };
    }
    #isInfected(filesystem) {
        if (filesystem.state === types_1.EfsState.DELETED) {
            return false;
        }
        return this.#threats.some((threat) => threat.source.asset?.assetId === filesystem.id ||
            threat.source.assetItem?.assetId === filesystem.id);
    }
    #getLastScanForFilesystem(filesystem) {
        const scan = this.#filesystemsWithScans.find((scan) => scan.filesystem.id === filesystem.id);
        if (!scan) {
            return null;
        }
        const scansTime = scan.scans.map(({ createdAt }) => createdAt.getTime());
        if (!scansTime.length) {
            return null;
        }
        return new Date(Math.max(...scansTime));
    }
    #mapFilesystemToScans(filesystem) {
        const scans = this.#filterScansForFilesystem(filesystem, this.#lastScans);
        return { filesystem, scans };
    }
    #getFilesystemsWithScans() {
        return this.#filesystems.map(this.#mapFilesystemToScans.bind(this));
    }
    #summarizeEfsData(efsList) {
        const nameCount = new Set([...efsList.map((efs) => efs.name)]).size;
        const accountCount = new Set([...efsList.map((efs) => efs.accountId)]).size;
        const regionCount = new Set([...efsList.map((efs) => efs.region)]).size;
        return {
            nameCount,
            accountCount,
            regionCount,
        };
    }
    #findEfsMatchingFilters(filters) {
        return this.#filesystems.filter((efsModel) => {
            const nameMatches = filters.idSearch &&
                (efsModel.name.includes(filters.idSearch) ||
                    efsModel.awsId.includes(filters.idSearch));
            if (filters.idSearch && !nameMatches) {
                return false;
            }
            if (!(0, share_1.matchesIncludeFilter)(filters.accountIds, efsModel.awsAccountId)) {
                return false;
            }
            if (!(0, share_1.matchesIncludeFilter)(filters.regions, efsModel.awsRegion)) {
                return false;
            }
            if ((filters.covered?.includes(true) && !efsModel.backupPolicies.length) ||
                (filters.covered?.includes(false) && efsModel.backupPolicies.length > 0)) {
                return false;
            }
            if ((filters.state?.includes(true) && efsModel.state !== types_1.EfsState.IN_USE) ||
                (filters.state?.includes(false) && efsModel.state !== types_1.EfsState.DELETED)) {
                return false;
            }
            return true;
        });
    }
    #isRansomwareScanTarget(target, filesystem) {
        if (target instanceof ransomware_1.RansomwareScanTarget) {
            if ('assetId' in target.target) {
                return target.target.assetId === filesystem.id;
            }
            if (target instanceof ransomware_1.RansomwareScanBackup && target.source.asset) {
                return target.source.asset.backupAssetId === filesystem.id;
            }
        }
    }
    #isMalwareScanTarget(target, filesystem) {
        if (target instanceof malware_1.MalwareScanTarget) {
            if ('assetId' in target.target) {
                return target.target.assetId === filesystem.id;
            }
            if (target instanceof malware_1.MalwareScanBackup && target.source.asset) {
                return target.source.asset.backupAssetId === filesystem.id;
            }
        }
    }
    #filterScansForFilesystem(filesystem, scans) {
        return scans.filter((scan) => {
            const ransomwareScanMatches = this.#isRansomwareScanTarget(scan.scanTarget, filesystem);
            const malwareScanMatches = this.#isMalwareScanTarget(scan.scanTarget, filesystem);
            return ransomwareScanMatches || malwareScanMatches;
        });
    }
}
exports.ListEfsInventory = ListEfsInventory;
