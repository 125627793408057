import BaseDataConstant from '@lib/constants/data/base-data.constant'
import ValueInterface from '@lib/interfaces/value.interface'

class PeriodConstantInner extends BaseDataConstant<ValueInterface> {
  get PERIOD_UNDEFINED(): ValueInterface {
    return {
      name: 'Undefined',
      value: -1,
    }
  }

  get PERIOD_KEEP_ALL(): ValueInterface {
    return {
      name: 'All',
      value: 0,
    }
  }

  get PERIOD_KEEP_HOURLY(): ValueInterface {
    return {
      name: 'Hourly',
      value: 1,
    }
  }

  get PERIOD_KEEP_DAILY(): ValueInterface {
    return {
      name: 'Daily',
      value: 2,
    }
  }

  get PERIOD_KEEP_WEEKLY(): ValueInterface {
    return {
      name: 'Weekly',
      value: 3,
    }
  }

  get PERIOD_KEEP_MONTHLY(): ValueInterface {
    return {
      name: 'Monthly',
      value: 4,
    }
  }

  get PERIOD_KEEP_YEARLY(): ValueInterface {
    return {
      name: 'Yearly',
      value: 5,
    }
  }
}

const PeriodConstant = PeriodConstantInner.build(PeriodConstantInner, 'PERIOD_')

export default PeriodConstant
