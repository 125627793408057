import { AssetsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import { Request as ListEc2Request } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/assets/list_ec2_pb'

import { GrpcClient } from '../grpc-client/grpc-client'
import { EC2Transformer } from './transformers'

type Tag = {
  key: string
  value: string
}

export interface ListEC2Filters {
  assetIdList?: Array<string>
  tagsList?: Array<Tag>
  excludeTagsList?: Array<Tag>
  accountIdsList?: Array<string>
  regionsList?: Array<string>
  vpcIdsList?: Array<string>
}

export class AssetsClient extends GrpcClient<AssetsPromiseClient> {
  #assetsClient: AssetsPromiseClient

  constructor(hostName = '') {
    super()
    this.#assetsClient = this.getClient(hostName)
  }

  protected innerClientTypeId(): string {
    return 'AssetsClient'
  }

  protected initClient(hostName = ''): AssetsPromiseClient {
    return new AssetsPromiseClient(hostName, null, null)
  }

  async listEC2(filters: ListEC2Filters) {
    const request = new ListEc2Request()
    const initial = new ListEc2Request.Initial()
    const filter = new ListEc2Request.Initial.Filter()

    if (filters.assetIdList && filters.assetIdList.length) {
      filter.setAssetIdList(filters.assetIdList)
    }

    initial.setFilter(filter)
    request.setInitial(initial)

    const response = await this.retryGrpcCall(
      () => this.#assetsClient.listEC2(request, this.metadata()),
      {
        requestName: 'AssetsPromiseClient/listEC2',
      }
    )

    // Add pagination if you need that
    return response
      .toObject()
      .instancesList.map((ec2) => new EC2Transformer(ec2).transform())
  }
}
