"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.efsFactory = void 0;
const fishery_1 = require("fishery");
const common_1 = require("./common");
const models_1 = require("../../../models");
class EfsFactory extends fishery_1.Factory {
    withNoBackups() {
        return this.transient({
            threatStatus: null,
        });
    }
}
const efsFactory = EfsFactory.define(({ sequence, transientParams }) => {
    const region = transientParams.region ?? (0, common_1.awsRegion)();
    const account = transientParams.accountId ?? (0, common_1.awsAccountId)();
    const efsName = transientParams.name ?? (0, common_1.name)('EFS', sequence);
    return new models_1.EfsModel({
        id: transientParams.id ?? (0, common_1.uuid)(),
        accountId: account,
        region,
        cloudConnectorId: transientParams.cloudConnectorId ?? (0, common_1.uuid)(),
        cloudConnectorAssetId: transientParams.cloudConnectorAssetId ??
            (0, common_1.awsCCAssetId)(account, region, efsName, 'aws-efs'),
        name: efsName,
        tags: transientParams.tags ?? new Map(),
        createdAt: transientParams.createdAt ?? Date.now(),
        updatedAt: transientParams.createdAt ?? Date.now(),
        threatStatus: transientParams.threatStatus ?? null,
        coveredByPolicies: transientParams.coveredByPolicies ?? [],
        coveredByPausedPolicies: transientParams.coveredByPausedPolicies ?? [],
        associatedRetentionPolicies: transientParams.associatedRetentionPolicies ?? [],
    });
});
exports.efsFactory = efsFactory;
