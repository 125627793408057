import React, { memo } from 'react'
import { useModal } from '@lib/hooks/useModal'
import ValueInterface from '@lib/interfaces/value.interface'
import Modal from '@lib/constants/modal.constant'
import DemoService from '@lib/services/demo.service'
import useDemo from '@lib/hooks/useDemo'
import ConfigureViewModal from '@components-composite/modals/ConfigureViewModal'
import SettingsIcon from '@mui/icons-material/Settings'

function ConfigureView() {
  const { openModal, modalProps } = useModal<Array<ValueInterface>>(
    Modal.configureView,
    () => null
  )

  const { isDevDomain } = useDemo({
    runIfDemoDomain: () => {
      DemoService.modeBodyClasses.forEach((name) =>
        document.body.classList.add(name)
      )
    },
  })

  return (
    <div className="wrap-1622699775545">
      {isDevDomain && (
        <div className="cvSettings">
          <SettingsIcon className="settingIcon" onClick={() => openModal()} />
        </div>
      )}

      <ConfigureViewModal {...modalProps} />
    </div>
  )
}

export default memo(ConfigureView)
