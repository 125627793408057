"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tenantsFactory = void 0;
const fishery_1 = require("fishery");
const models_1 = require("../../../models");
const tenantsFactory = fishery_1.Factory.define(({ transientParams }) => {
    return new models_1.TenantModel({
        created_at: transientParams.created_at ?? Date.now(),
        name: transientParams.name ?? 'dev.staging.elastio.us',
        updated_at: transientParams.created_at ?? Date.now(),
        vulnerabilities_enabled: transientParams.vulnerabilities_enabled ?? true,
    });
});
exports.tenantsFactory = tenantsFactory;
