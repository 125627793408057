"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListVolumesVulnerabilities = void 0;
const vulnerability_1 = require("../../models/vulnerability");
__exportStar(require("./types"), exports);
class ListVolumesVulnerabilities {
    #volumesByIds;
    #instances;
    #lastBackups;
    #scans;
    #volumesWithInstances;
    #snapshotsByVolumeId;
    #volumesWithScans;
    #instanceByIdMap;
    constructor(parameters) {
        this.#instances = parameters.instances;
        this.#lastBackups = parameters.lastBackups;
        this.#scans = parameters.scans;
        this.#volumesByIds = parameters.volumes.reduce((map, volume) => {
            map.set(volume.id, volume);
            return map;
        }, new Map());
        this.#instanceByIdMap = this.#instances.reduce((map, instance) => {
            map.set(instance.id, instance);
            return map;
        }, new Map());
        this.#volumesWithInstances ||= this.#getVolumesWithInstances();
        this.#volumesWithScans ||= this.#getVolumeWithScans();
        this.#snapshotsByVolumeId = parameters.snapshots.reduce((map, snapshot) => {
            map.set(snapshot.volumeId, snapshot);
            return map;
        }, new Map());
    }
    execute(filters, riskIdentificationStrategy) {
        return this.#prepareOutput(this.#findVolumesMatchingFilters(filters, riskIdentificationStrategy), riskIdentificationStrategy, filters);
    }
    #prepareOutput(volumesWithInstances, riskIdentificationStrategy, filters) {
        const selectedRiskType = filters?.riskType;
        const selectedVulnerabilities = filters?.ebsVulnerabilities;
        const volumesOutput = volumesWithInstances.map(({ volume, instances }) => {
            const vulnerabilitiesList = this.#getVolumesVulnerabilities(volume);
            const filteredVulnerabilitiesList = this.#filteredVulnerabilitiesList({
                vulnerabilitiesList,
                selectedRiskType,
                selectedVulnerabilities,
                riskIdentificationStrategy,
            });
            return {
                volumeId: volume.id,
                volumeAwsId: volume.awsId,
                volumeName: volume.name,
                accountId: volume.awsAccountId,
                region: volume.awsRegion,
                instancesList: instances,
                isAttached: instances.length > 0,
                lastBackup: this.#getLastBackup(volume),
                riskVulnerabilities: this.#getHighestRiskLevel(filteredVulnerabilitiesList, riskIdentificationStrategy),
                vulnerabilitiesList: filteredVulnerabilitiesList,
            };
        });
        const instancesOutput = volumesWithInstances.flatMap(({ instances }) => instances.map((instance) => {
            const attachedVolumes = this.#getAttachedVolumesToInstance(instance);
            const vulnerabilitiesList = this.#getAllVulnerabilitiesByAttachedVolumes(instance);
            const filteredVulnerabilitiesList = this.#filteredVulnerabilitiesList({
                vulnerabilitiesList,
                selectedRiskType,
                selectedVulnerabilities,
                riskIdentificationStrategy,
            });
            return {
                instanceId: instance.id,
                instanceAwsId: instance.awsId,
                instanceName: instance.name,
                accountId: instance.awsAccountId,
                region: instance.awsRegion,
                osKind: instance.osKind,
                instanceState: instance.state,
                attachedVolumesCount: attachedVolumes.length,
                lastBackup: this.#getLastBackup(instance),
                volumeVulnerabilities: filteredVulnerabilitiesList,
                riskVulnerabilities: this.#getHighestRiskLevel(filteredVulnerabilitiesList, riskIdentificationStrategy),
            };
        }));
        const uniqueInstances = Array.from(instancesOutput
            .reduce((acc, instance) => acc.set(instance.instanceId, instance), new Map())
            .values());
        return {
            volumesList: volumesOutput,
            instancesList: uniqueInstances,
        };
    }
    #getVolumesWithInstances() {
        return [...this.#volumesByIds.values()].map((volume) => {
            const instances = volume.attachedInstanceIds
                .map((instanceId) => this.#instanceByIdMap.get(instanceId))
                .filter(Boolean);
            return {
                volume,
                instances,
            };
        });
    }
    #getLastBackup(asset) {
        return this.#lastBackups.get(asset.id);
    }
    #getVolumeWithScans() {
        const map = new Map();
        for (const scan of this.#scans) {
            const volumesScans = map.get(scan.scanTarget.target.assetId);
            if (volumesScans) {
                volumesScans.push(scan);
            }
            else {
                map.set(scan.scanTarget.target.assetId, [scan]);
            }
        }
        return map;
    }
    #getLastScanForVolume(volume) {
        const volumeScans = this.#volumesWithScans.get(volume.id);
        if (!volumeScans || !volumeScans.length) {
            return null;
        }
        const scansTime = volumeScans.map(({ createdAt }) => createdAt.getTime());
        if (!scansTime.length) {
            return null;
        }
        return new Date(Math.max(...scansTime));
    }
    #getHighestRiskLevel(vulnerabilities, riskIdentificationStrategy) {
        const risks = vulnerabilities.map((vulnerability) => ({
            vulnerability,
            risk: riskIdentificationStrategy.identifyRiskLevel(vulnerability),
        }));
        return risks.reduce((accum, curr) => Math.max(accum, curr.risk), 0);
    }
    #getVolumesVulnerabilities(volume) {
        const volumeSnapshot = this.#snapshotsByVolumeId.get(volume.id);
        const result = [];
        if (volume.isNotAttached) {
            result.push(vulnerability_1.VolumeVulnerabilityKind.UNATTACHED);
        }
        if (volume.isUnencrypted) {
            result.push(vulnerability_1.VolumeVulnerabilityKind.UNENCRYPTED);
        }
        if (volume.encryptedWithoutCmk) {
            result.push(vulnerability_1.VolumeVulnerabilityKind.NOT_USING_CMK);
        }
        if (this.#getLastScanForVolume(volume) === null) {
            result.push(vulnerability_1.VolumeVulnerabilityKind.NOT_CYBER_SCANNED);
        }
        if (!this.#lastBackups.has(volume.id)) {
            result.push(vulnerability_1.VolumeVulnerabilityKind.NO_BACKUPS);
        }
        if (volumeSnapshot && this.#isSnapshotOlderAWeek(volumeSnapshot)) {
            result.push(vulnerability_1.VolumeVulnerabilityKind.SNAPSHOTS_OLDER_7DAYS);
        }
        return result;
    }
    #filteredVulnerabilitiesList({ vulnerabilitiesList, selectedRiskType, selectedVulnerabilities, riskIdentificationStrategy, }) {
        if (!selectedVulnerabilities && !selectedRiskType) {
            return vulnerabilitiesList;
        }
        const resultList = new Set();
        if (selectedVulnerabilities) {
            vulnerabilitiesList.forEach((vulnerability) => {
                const isSelectedVulnerability = selectedVulnerabilities.includes(vulnerability);
                const isSelectedRisk = !selectedRiskType ||
                    selectedRiskType.includes(riskIdentificationStrategy.identifyRiskLevel(vulnerability));
                if (isSelectedVulnerability && isSelectedRisk) {
                    resultList.add(vulnerability);
                }
            });
        }
        if (selectedRiskType && !selectedVulnerabilities) {
            vulnerabilitiesList.forEach((vulnerability) => {
                const isSelectedRisk = selectedRiskType.includes(riskIdentificationStrategy.identifyRiskLevel(vulnerability));
                if (isSelectedRisk) {
                    resultList.add(vulnerability);
                }
            });
        }
        return [...resultList];
    }
    #isSnapshotOlderAWeek(snapshot) {
        const millisecondsInWeek = 7 * 24 * 60 * 60 * 1000;
        return snapshot.age > millisecondsInWeek;
    }
    #getAttachedVolumesToInstance(instance) {
        return instance.ebsIds
            .map((id) => this.#volumesByIds.get(id))
            .filter(Boolean);
    }
    #getAllVulnerabilitiesByAttachedVolumes(instance) {
        const attachedVolumes = this.#getAttachedVolumesToInstance(instance);
        const vulnerabilities = new Set();
        attachedVolumes.map((volume) => this.#getVolumesVulnerabilities(volume).forEach((vulnerability) => vulnerabilities.add(vulnerability)));
        return [...vulnerabilities];
    }
    #findVolumesMatchingFilters(filters, riskIdentificationStrategy) {
        return this.#volumesWithInstances.filter(({ volume, instances }) => {
            const vulnerabilities = this.#getVolumesVulnerabilities(volume);
            if (vulnerabilities.length === 0) {
                return false;
            }
            const filteredVulnerabilitiesList = this.#filteredVulnerabilitiesList({
                vulnerabilitiesList: vulnerabilities,
                selectedRiskType: filters.riskType,
                selectedVulnerabilities: filters.ebsVulnerabilities,
                riskIdentificationStrategy,
            });
            const risks = [
                ...new Set(filteredVulnerabilitiesList.map(riskIdentificationStrategy.identifyRiskLevel)),
            ];
            if (!this.#matchesIncludeFilter(filters.volumeAwsIds, volume.awsId)) {
                return false;
            }
            if (filters.attachedInstancesAwsIds) {
                const attachedInstancesByFilter = instances.filter((i) => filters.attachedInstancesAwsIds?.includes(i.awsId));
                if (attachedInstancesByFilter.length === 0) {
                    return false;
                }
            }
            if (!this.#matchesIncludeFilter(filters.accountIds, volume.awsAccountId)) {
                return false;
            }
            if (!this.#matchesIncludeFilter(filters.regions, volume.awsRegion)) {
                return false;
            }
            if (filters.ebsVulnerabilities &&
                !this.#matchesEbsVulnerabilitiesFilter(filters.ebsVulnerabilities, filteredVulnerabilitiesList)) {
                return false;
            }
            if (filters.riskType?.length &&
                !filters.riskType?.some((risk) => risks.includes(risk))) {
                return false;
            }
            if (filters.idSearch &&
                !this.#matchesBySearchValue(filters.idSearch, volume)) {
                return false;
            }
            return true;
        });
    }
    #matchesIncludeFilter(filters, value) {
        if (!filters) {
            return true;
        }
        if (!value) {
            return false;
        }
        return filters.includes(value);
    }
    #matchesEbsVulnerabilitiesFilter(filters, vulnerabilities) {
        return filters.some((vulnerability) => vulnerabilities.includes(vulnerability));
    }
    #matchesBySearchValue(searchValue, volume) {
        const instances = this.#instances.filter((instance) => instance.ebsIds.includes(volume.id));
        const volumeNameMatches = volume.name.toLowerCase().includes(searchValue);
        const instanceAwsIdMatches = instances.some((instance) => instance.awsId.toLowerCase().includes(searchValue));
        const matchesAccountId = volume.awsAccountId.includes(searchValue);
        const matchesAwsId = volume.awsId.includes(searchValue);
        const volumeVulnerabilities = this.#getVolumesVulnerabilities(volume);
        const matchesVulnerabilities = volumeVulnerabilities.some((vulnerability) => vulnerability.toLowerCase().includes(searchValue));
        return (instanceAwsIdMatches ||
            matchesAccountId ||
            volumeNameMatches ||
            matchesAwsId ||
            matchesVulnerabilities);
    }
}
exports.ListVolumesVulnerabilities = ListVolumesVulnerabilities;
