import { TenantsPromiseClient } from 'blue-stack-libs/catalog-grpc-libs/js/catalog/catalog_grpc_web_pb'
import GrpcBaseService from './base/grpc-base.service'
// eslint-disable-next-line import/no-extraneous-dependencies
import { TenantModel } from 'blues-corejs/dist'
import {
  GetTenantRequest,
  UpdateTenantRequest,
} from 'blue-stack-libs/catalog-grpc-libs/js/catalog/catalog_pb'
import TenantsServiceFactory from '@lib/factories/tenants.factory'

class GrpcTenantsService extends GrpcBaseService {
  protected static client = new TenantsPromiseClient('', null, null)
  protected static clientName = 'Tenants service'

  public static async getTenant(): Promise<TenantModel> {
    const request = new GetTenantRequest()

    const result = await this.handleQueryRetry(this.client.getTenant, request)

    const tenant = result.toObject().tenant

    if (!tenant || tenant === undefined) {
      throw new Error('Tenant not found')
    }

    return TenantsServiceFactory.build(tenant)
  }

  public static async updateTenant(tenant: TenantModel) {
    const request = new UpdateTenantRequest()

    request.setVulnerabilitiesEnabled(tenant.getVulnerabilitiesEnabled())

    await this.handleQueryRetry(this.client.updateTenant, request)

    return tenant
  }
}

export default GrpcTenantsService
