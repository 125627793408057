import {
  USER_SETTINGS_VERSIONS,
  UserSettings,
} from '@lib/constants/settings.constant'
import { Nullable } from '@lib/engine-types'
import SettingInterface from '@lib/interfaces/setting.interface'
import StrHelper from '@lib/helpers/str.helper'
import GrpcUsersService from '@lib/services/grpc/grpc-users.service'

class SettingsServiceInner {
  private settings: Nullable<{
    [key: string]: SettingInterface
  }> = null

  public init(settings: any) {
    this.settings = settings
  }

  public updateSetting(name: UserSettings, value: any): Promise<any> {
    if (!this.settings) {
      this.settings = {}
    }
    this.settings[name] = {
      updatedAt: +Date.now(),
      version: USER_SETTINGS_VERSIONS[name] ?? 0,
      value: StrHelper.base64EncodeObj(value),
    }
    return GrpcUsersService.setUserConfig(JSON.stringify(this.settings))
  }

  public getSetting(name: UserSettings): any {
    if (this.settings && this.settings[name]) {
      return StrHelper.base64DecodeObj(this.settings[name]?.value ?? '')
    }
    return null
  }
}

const SettingsService = new SettingsServiceInner()

export default SettingsService
