import React, { memo, ReactNode } from 'react'

interface Props {
  children: ReactNode
  value: number
  index: number
  className?: string
}

function MaterialTab({ children, value, index, className = '' }: Props) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`material-tabpanel-${index}`}
      aria-labelledby={`material-tab-${index}`}
      className={`wrap-1603371509408 ${className}`}
    >
      {value === index && children}
    </div>
  )
}

export default memo(MaterialTab)
