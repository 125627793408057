"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EfsItem = void 0;
const asset_item_1 = require("../asset-item");
class EfsItem extends asset_item_1.AssetItem {
    #efsId;
    #selectors;
    #name;
    constructor(parameters) {
        const { efsId, selectors, name, ...assetParameters } = parameters;
        super(assetParameters);
        this.#efsId = efsId;
        this.#selectors = Object.freeze([...selectors]);
        this.#name = name;
    }
    get efsId() {
        return this.#efsId;
    }
    get selectors() {
        return [...this.#selectors];
    }
    get name() {
        return this.#name;
    }
}
exports.EfsItem = EfsItem;
