import { styled } from '@mui/material/styles'
import Typography, { TypographyProps } from '@mui/material/Typography'

interface DateTypographyProps extends TypographyProps {
  fontSize?: number | string
}

export const DateTypography = styled(Typography)<DateTypographyProps>(
  ({ fontSize = 14 }) => ({
    fontSize: typeof fontSize === 'number' ? `${fontSize}px` : fontSize,
    fontWeight: 'inherit',
  })
)

DateTypography.defaultProps = {
  variant: 'caption',
}
