import React, { CSSProperties, memo } from 'react'
import { AssetItemIconOwnProps } from './types'
import {
  AssetItemEfsIcon,
  AssetItemFileIcon,
  AssetItemFolderIcon,
  AssetItemS3Icon,
  AssetItemStreamIcon,
  AssetItemType,
  AssetItemVolumeIcon,
} from '@features/DashboardV4/common'
import AssetItemDiskIcon from '../icons/assets/asset-item-disk-icon'

const iconWrapperStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

function AssetItemIcon({ itemType }: AssetItemIconOwnProps) {
  let icon

  switch (itemType) {
    case AssetItemType.FILE:
      icon = <AssetItemFileIcon />
      break
    case AssetItemType.STREAM:
      icon = <AssetItemStreamIcon />
      break
    case AssetItemType.LOCAL_VOLUME:
      icon = <AssetItemVolumeIcon />
      break
    case AssetItemType.FOLDER:
      icon = <AssetItemFolderIcon />
      break
    case AssetItemType.FS_ITEM:
      icon = <AssetItemEfsIcon />
      break
    case AssetItemType.S3_ITEM:
      icon = <AssetItemS3Icon />
      break
    case AssetItemType.DISK:
      icon = <AssetItemDiskIcon />
      break

    default:
      icon = <></>
  }

  return <div style={iconWrapperStyle}>{icon}</div>
}

export default memo(AssetItemIcon)
