/* eslint-disable import/no-extraneous-dependencies */
import { Job as ProtobufJob } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/jobs/cloud_connector/job_pb'
import {
  JobCloudConnector,
  PhasedProgress,
  UnitsProgress,
} from 'blues-corejs/dist'
import TimeHelper from '@lib/helpers/time.helper'
import { PercentageProgress, Progress } from 'blues-corejs/dist'
import { MeasurementUnit } from 'blues-corejs/dist/models/jobs/cloud-connector/progress/units-progress'
import { CloudConnectorJobKindTransformer } from './cloud-connector-job-kind'
import { CloudConnectorJobStatusTransformer } from './cloud-connector-job-status'

type JobPt = ProtobufJob.AsObject

export class CloudConnectorJobTransformer {
  #job: JobPt

  constructor(job: JobPt) {
    this.#job = job
  }

  transform(): JobCloudConnector {
    return this.#transformJob(this.#job)
  }

  #transformJob(job: JobPt): JobCloudConnector {
    return new JobCloudConnector({
      id: job.id,
      ccId: job.ccId,
      ccJobId: job.ccJobId,
      name: job.name,
      parentId: job.parentId,
      scanIds: job.scanIdsList,
      status: new CloudConnectorJobStatusTransformer(job.status).transform(),
      statusMessage: job.statusMessage,
      targetAssetId: job.targetAssetId,
      finalized: job.finalized,
      abortRequested: job.abortRequested,
      kind: new CloudConnectorJobKindTransformer(job.kind).transform(),
      progress: this.#convertProgress(
        job.progress as ProtobufJob.Progress.AsObject
      ),
      createdAt: TimeHelper.secondsToDate(job.createdAt?.seconds),
      updatedAt: TimeHelper.secondsToDate(job.updatedAt?.seconds),
      finishedAt:
        job.finishedAt && TimeHelper.secondsToDate(job.finishedAt?.seconds),
      startedAt: TimeHelper.secondsToDate(job.startedAt?.seconds),
      childrenList: job.childrenList.map((child) => this.#transformJob(child)),
    })
  }

  #convertProgress(
    progress?: ProtobufJob.Progress.AsObject
  ): Progress | undefined {
    if (!progress) {
      return
    }

    const { percentage, phased, units } = progress

    if (percentage) {
      return new PercentageProgress({
        fraction: percentage.fraction?.value || 0,
        rate: percentage.rate?.value || 0,
      })
    }

    if (phased) {
      return new PhasedProgress({
        currentPhase: phased.currentPhase?.value || 0,
        phases: phased.phasesList,
      })
    }

    if (units) {
      return new UnitsProgress({
        current: units.current?.value || 0,
        expected: units.expected?.value || 0,
        measurement: this.#convertMeasurementUnit(units.measurement),
        rate: units.rate?.value || 0,
      })
    }
  }

  #convertMeasurementUnit(
    measurementUnit: ProtobufJob.Progress.Units.Measurement
  ): MeasurementUnit {
    const mapping = {
      [ProtobufJob.Progress.Units.Measurement.BYTES]: MeasurementUnit.BYTES,
    }

    return mapping[measurementUnit]
  }
}
