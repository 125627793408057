import React, { memo, ReactNode } from 'react'
import Link from 'next/link'
import { Tooltip } from '@mui/material'

interface Props {
  text?: ReactNode
  link?: string
  className?: string
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  variant?: 'normal' | 'inText' | 'table'
  sign?: string
}

function QuestionHint({
  text,
  placement = 'bottom',
  className = '',
  link,
  variant = 'normal',
  sign = '?',
}: Props) {
  return (
    <span className={`wrap-1603191783087 ${className} ${variant}Variant`}>
      {text && (
        <Tooltip placement={placement} title={text}>
          <span className="questionMark">{sign}</span>
        </Tooltip>
      )}
      {link && (
        <Link href={link}>
          <a>
            <span className="questionMark jsQuestionMark">?</span>
          </a>
        </Link>
      )}
    </span>
  )
}
export default memo(QuestionHint)
