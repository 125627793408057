import React, { memo } from 'react'
import Script from 'next/script'
import { ZE_WEB_MESSENGER_KEY } from '@lib/constants/app.constant'
import SystemHelper from '@lib/helpers/system.helper'

function ZeWebMessenger() {
  if (SystemHelper.isLocalEnv()) {
    return null
  }

  const onWebWidgetLoad = async () => {
    try {
      // @ts-ignore
      globalThis.zE('messenger', 'hide')
      // @ts-ignore
      window.zE('messenger:on', 'close', () => {
        // @ts-ignore
        globalThis.zE('messenger', 'hide')
      })
    } catch (e) {
      SystemHelper.throwErrorInLocalEnv(
        'Zen Web Widget messaging was not loaded'
      )
    }
  }
  return (
    <div data-testid="ze-web-messenger">
      <Script
        async
        id="ze-snippet"
        src={`https://static.zdassets.com/ekr/snippet.js?key=${ZE_WEB_MESSENGER_KEY}`}
        onLoad={onWebWidgetLoad}
      ></Script>
    </div>
  )
}

export default memo(ZeWebMessenger)
