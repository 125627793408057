import ValueInterface from '@lib/interfaces/value.interface'
import { FilterNamesConstant } from '@lib/constants/filters/filter-names.constant'

abstract class FilterHelper {
  public static getAssetsDataForPolicyCoverageFilter(
    filters?: Array<ValueInterface>,
    search?: string
  ) {
    const dataForNewTable: Record<string, any> = {}
    const isFilters = filters !== undefined && filters?.length > 0

    if (isFilters) {
      filters?.map((filter: ValueInterface) => {
        if (filter.name === FilterNamesConstant.ACCOUNT_ID) {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.accountIds) {
              dataForNewTable.accountIds = []
              dataForNewTable.accountIds?.push(value.name)
            } else {
              dataForNewTable.accountIds?.push(value.name)
            }
          })
        } else if (filter.name === FilterNamesConstant.REGIONS) {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.regions) {
              dataForNewTable.regions = []
              dataForNewTable.regions?.push(value.name)
            } else {
              dataForNewTable.regions?.push(value.name)
            }
          })
        } else if (filter.name === FilterNamesConstant.IS_PROTECTED) {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.isProtected) {
              dataForNewTable.isProtected = []
              dataForNewTable.isProtected.push(JSON.parse(value.name))
            } else {
              delete dataForNewTable.isProtected
            }
          })
        } else if (filter.name === FilterNamesConstant.IS_PAUSED_INSTANCES) {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.isPausedInstances) {
              dataForNewTable.isPausedInstances = []
              dataForNewTable.isPausedInstances.push(JSON.parse(value.name))
            } else {
              delete dataForNewTable.isPausedInstances
            }
          })
        } else if (filter.name === FilterNamesConstant.INSTANCE_IDS) {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.instanceIds) {
              dataForNewTable.instanceIds = []
              dataForNewTable.instanceIds?.push(value.name)
            } else {
              dataForNewTable.instanceIds?.push(value.name)
            }
          })
        } else if (filter.name === FilterNamesConstant.INSTANCE_TYPES) {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.instanceTypes) {
              dataForNewTable.instanceTypes = []
              dataForNewTable.instanceTypes?.push(value.name)
            } else {
              dataForNewTable.instanceTypes?.push(value.name)
            }
          })
        } else if (filter.name === FilterNamesConstant.VOLUME_IDS) {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.volumeIds) {
              dataForNewTable.volumeIds = []
              dataForNewTable.volumeIds?.push(value.name)
            } else {
              dataForNewTable.volumeIds?.push(value.name)
            }
          })
        } else if (filter.name === FilterNamesConstant.VOLUME_TYPES) {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.volumeTypes) {
              dataForNewTable.volumeTypes = []
              dataForNewTable.volumeTypes?.push(value.name)
            } else {
              dataForNewTable.volumeTypes?.push(value.name)
            }
          })
        } else if (filter.name === FilterNamesConstant.VPC_IDS) {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.vpcIds) {
              dataForNewTable.vpcIds = []
              dataForNewTable.vpcIds?.push(value.name)
            } else {
              dataForNewTable.vpcIds?.push(value.name)
            }
          })
        } else if (filter.name === FilterNamesConstant.TAGS) {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.tags) {
              dataForNewTable.tags = []
              dataForNewTable.tags?.push(value.name)
            } else {
              dataForNewTable.tags?.push(value.name)
            }
          })
        } else if (
          filter.name === FilterNamesConstant.VAULTS ||
          filter.name === FilterNamesConstant.CLOUD_CONNECTORS
        ) {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.cloudConnectors) {
              dataForNewTable.cloudConnectors = []
              dataForNewTable.cloudConnectors?.push({
                accountId: String(value.defaultValue),
                region: String(value.extraValue),
              })
            } else {
              dataForNewTable.cloudConnectors?.push({
                accountId: String(value.defaultValue),
                region: String(value.extraValue),
              })
            }
          })
        } else {
          delete dataForNewTable.accountIds
          delete dataForNewTable.regions
          delete dataForNewTable.isProtected
          delete dataForNewTable.isPausedInstances
          delete dataForNewTable.instanceIds
          delete dataForNewTable.instanceTypes
          delete dataForNewTable.volumeIds
          delete dataForNewTable.volumeTypes
          delete dataForNewTable.vpcIds
          delete dataForNewTable.tags
          delete dataForNewTable.cloudConnectors
        }
      })
    }

    if (search) {
      if (search !== '') {
        dataForNewTable.idSearch = search
      } else {
        delete dataForNewTable.idSearch
      }
    }
    return dataForNewTable
  }
}

export default FilterHelper
