import { useMemo, useState } from 'react'
import {
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  InitialTableState,
  TableOptions,
  getExpandedRowModel,
  RowSelectionState,
} from '@tanstack/react-table'

import { RowsExpanded, TableOwnProps } from './types'
import { useRowExpanded } from '@components/table/utilities/use-row-expanded'

type UseTableConfig<T> = Pick<TableOwnProps<T>, 'data' | 'columns'> & {
  initialTableState: InitialTableState
  rowsSelection?: {
    selectedRows: RowSelectionState
    setSelectedRows: (rows: RowSelectionState) => void
  }
  rowsExpanded?: RowsExpanded
  expandableRowKey?: keyof T
}

export function useTableConfig<T>({
  columns,
  data,
  initialTableState,
  rowsSelection,
  rowsExpanded,
  expandableRowKey,
}: UseTableConfig<T>): TableOptions<T> {
  const [sorting, setSorting] = useState<SortingState>(
    initialTableState.sorting ? initialTableState.sorting : []
  )
  const [uncontrolledRowSelection, setUncontrolledRowSelection] =
    useState<RowSelectionState>({})

  const setSelectedRows = useMemo(() => {
    return rowsSelection?.setSelectedRows
      ? rowsSelection.setSelectedRows
      : setUncontrolledRowSelection
  }, [rowsSelection?.setSelectedRows])

  const selectedRows = useMemo(() => {
    return rowsSelection?.selectedRows
      ? rowsSelection.selectedRows
      : uncontrolledRowSelection
  }, [rowsSelection?.selectedRows])

  const { setExpandedRows, expandedRows } = useRowExpanded({ rowsExpanded })

  return {
    columns,
    enableSortingRemoval: false,
    data,
    state: {
      sorting,
      rowSelection: selectedRows,
      expanded: expandedRows,
    },
    // @ts-ignore
    getSubRows: (row) => row[expandableRowKey],
    initialState: initialTableState,
    enableHiding: true,
    enableRowSelection: true,
    onRowSelectionChange: (updater) => {
      if (!(typeof updater === 'function')) {
        return
      }

      const newSelection = updater(selectedRows)

      setSelectedRows(newSelection)
    },
    onExpandedChange: (updater) => {
      if (!(typeof updater === 'function')) {
        return
      }

      const newExpanded = updater(expandedRows)

      setExpandedRows(newExpanded)
    },
    // @ts-ignore
    getSubRows: (row) => row[expandableRowKey],
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand: () => true,
    getExpandedRowModel: getExpandedRowModel(),
  } as const
}
