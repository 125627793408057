"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListVolumesWithThreats = void 0;
const filesystem_check_1 = require("../../models/scans/filesystem-check");
const malware_1 = require("../../models/scans/malware");
const ransomware_1 = require("../../models/scans/ransomware");
const types_1 = require("../../models/threats/types");
class ListVolumesWithThreats {
    #volumes;
    #instances;
    #threats;
    #lastBackups;
    #lastScans;
    #volumesWithThreats;
    #volumesWithScans;
    constructor(parameters) {
        this.#volumes = parameters.volumes;
        this.#instances = parameters.instances;
        this.#threats = parameters.threats;
        this.#lastBackups = parameters.lastBackups;
        this.#lastScans = parameters.lastScans;
        this.#volumesWithThreats ||= this.#getVolumesWithThreats();
        this.#volumesWithScans ||= this.#getVolumeWithScans();
    }
    execute(filters) {
        return this.#prepareOutput(this.#findVolumesMatchingFilters(filters));
    }
    #prepareOutput(volumes) {
        return volumes.map((volume) => {
            const attachedInstancesList = this.#getAttachedInstancesList(volume);
            const volumeOutput = {
                volumeId: volume.id,
                volumeAwsId: volume.awsId,
                volumeName: volume.name,
                accountId: volume.awsAccountId,
                volumeThreats: this.#getThreatsForVolume(volume),
                region: volume.awsRegion,
                lastBackup: this.#getLastBackup(volume),
                lastScan: this.#getLastScanForVolume(volume),
                isAttached: attachedInstancesList.length > 0,
                attachedInstancesList,
            };
            const instancesOutput = attachedInstancesList.map((instance) => {
                const attachedVolumes = this.#volumes.filter((volume) => instance.ebsIds.includes(volume.id));
                const threats = attachedVolumes.flatMap((volume) => this.#getThreatsForVolume(volume));
                return {
                    instanceId: instance.id,
                    instanceAwsId: instance.awsId,
                    instanceName: instance.name,
                    threats: threats,
                    accountId: instance.awsAccountId,
                    region: instance.awsRegion,
                    osKind: instance.osKind,
                    attachedVolumes: instance.ebsIds.length,
                    status: instance.state,
                    lastScan: this.#getLastScanForInstance(instance),
                    lastBackup: this.#getLastBackup(instance),
                };
            });
            return {
                volume: volumeOutput,
                instances: instancesOutput,
            };
        });
    }
    #getAttachedInstancesList(volume) {
        return this.#instances.filter((instance) => instance.ebsIds.includes(volume.id));
    }
    #mapVolumeToScans(volume) {
        const scans = this.#filterScansForVolume(volume, this.#lastScans);
        return { volume, scans };
    }
    #getVolumeWithScans() {
        return this.#volumes.map(this.#mapVolumeToScans.bind(this));
    }
    #getLastScanForVolume(volume) {
        const volumeScans = this.#volumesWithScans.find((scan) => scan.volume.id === volume.id);
        if (!volumeScans) {
            return null;
        }
        const scansTime = volumeScans.scans.map(({ createdAt }) => createdAt.getTime());
        if (!scansTime.length) {
            return null;
        }
        return new Date(Math.max(...scansTime));
    }
    #getLastScanForInstance(instance) {
        const attachedVolumes = this.#volumes.filter((volume) => instance.ebsIds.includes(volume.id));
        const scans = attachedVolumes.map((volume) => this.#getLastScanForVolume(volume));
        if (!scans.length) {
            return null;
        }
        const scansTime = scans.map((scan) => scan?.getTime() ?? 0).filter(Boolean);
        if (!scansTime.length) {
            return null;
        }
        return new Date(Math.max(...scansTime));
    }
    #getThreatsForVolume(volume) {
        return this.#threats.filter(({ source: { asset, assetItem } }) => asset?.assetId === volume.id || assetItem?.assetId === volume.id);
    }
    #getVolumesWithThreats() {
        return this.#volumes.filter((volume) => this.#getThreatsForVolume(volume).length > 0);
    }
    #getLastBackup(asset) {
        return this.#lastBackups.get(asset.id);
    }
    #findVolumesMatchingFilters(filters) {
        return this.#volumesWithThreats.filter((volume) => {
            if (!this.#matchesIncludeFilter(filters.accountIds, volume.awsAccountId)) {
                return false;
            }
            if (!this.#matchesIncludeFilter(filters.regions, volume.awsRegion)) {
                return false;
            }
            if (filters.malwaresList &&
                !this.#getVolumeThreatByKind(volume, types_1.ThreatKind.MALWARE).some((malware) => filters.malwaresList?.includes(malware))) {
                return false;
            }
            if (filters.ransomwaresList &&
                !this.#getVolumeThreatByKind(volume, types_1.ThreatKind.RANSOMWARE).some((ransomware) => filters.ransomwaresList?.includes(ransomware))) {
                return false;
            }
            if (filters.fsCheck &&
                !this.#getVolumeThreatByKind(volume, types_1.ThreatKind.FILESYSTEM_ERROR).some((fs) => filters.fsCheck?.includes(fs))) {
                return false;
            }
            if (filters.idSearch &&
                !this.#getVolumeSearchMatches(filters.idSearch, volume)) {
                return false;
            }
            return true;
        });
    }
    #getVolumeThreatByKind(volume, kind) {
        return this.#threats
            .filter((threat) => {
            const isVolumeThreat = threat.source.asset?.assetId === volume.id ||
                threat.source.assetItem?.assetId === volume.id;
            return isVolumeThreat && threat.kind === kind;
        })
            .map((threat) => threat.name);
    }
    #matchesIncludeFilter(filters, value) {
        if (!filters) {
            return true;
        }
        if (!value) {
            return false;
        }
        return filters.includes(value);
    }
    #isRansomwareScanTarget(target, volume) {
        if (target instanceof ransomware_1.RansomwareScanTarget) {
            if (target.target instanceof ransomware_1.RansomwareScanBackup) {
                return target.target.source.asset?.backupAssetId === volume.id;
            }
            if ('assetId' in target.target) {
                return target.target.assetId === volume.id;
            }
        }
    }
    #isMalwareScanTarget(target, volume) {
        if (target instanceof malware_1.MalwareScanTarget) {
            if (target.target instanceof malware_1.MalwareScanBackup) {
                return target.target.source.asset?.backupAssetId === volume.id;
            }
            if ('assetId' in target.target) {
                return target.target.assetId === volume.id;
            }
        }
    }
    #isFilesystemScanTarget(target, volume) {
        if (target instanceof filesystem_check_1.FilesystemScanTarget) {
            if ('assetId' in target.target) {
                return target.target.assetId === volume.id;
            }
            if (target instanceof filesystem_check_1.FilesystemScanBackup) {
                return target.source.asset?.backupAssetId === volume.id;
            }
        }
    }
    #filterScansForVolume(volume, scans) {
        return scans.filter((scan) => {
            const ransomwareScanMatches = this.#isRansomwareScanTarget(scan.scanTarget, volume);
            const malwareScanMatches = this.#isMalwareScanTarget(scan.scanTarget, volume);
            const filesystemScanMatches = this.#isFilesystemScanTarget(scan.scanTarget, volume);
            return (ransomwareScanMatches || malwareScanMatches || filesystemScanMatches);
        });
    }
    #getVolumeSearchMatches(searchValue, volume) {
        const idMatches = volume.awsId.includes(searchValue);
        const nameMatches = volume.name.includes(searchValue);
        const tagsMatches = volume.tags.some(({ key, value }) => key.includes(searchValue) || value.includes(searchValue));
        const volumeInstancesMatches = this.#isVolumeInstanceMatchesBySearch(searchValue, volume);
        return idMatches || nameMatches || tagsMatches || volumeInstancesMatches;
    }
    #isVolumeInstanceMatchesBySearch(searchValue, volume) {
        const volumesInstances = this.#instances.filter((instance) => instance.ebsIds.includes(volume.id));
        return volumesInstances.some((instance) => {
            const matchesId = instance.awsId.includes(searchValue);
            const matchesName = instance.name.includes(searchValue);
            const matchesTags = instance.tags.some(({ key, value }) => key.includes(searchValue) || value.includes(searchValue));
            return matchesId || matchesName || matchesTags;
        });
    }
}
exports.ListVolumesWithThreats = ListVolumesWithThreats;
