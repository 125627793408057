import React from 'react'

interface Props {
  fill?: string
}

function DeleteIcon({ fill = '#32B6F3' }: Props) {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.88889 0V0.777778H0V2.33333H0.777778V12.4444C0.777778 12.857 0.941666 13.2527 1.23339 13.5444C1.52511 13.8361 1.92077 14 2.33333 14H10.1111C10.5237 14 10.9193 13.8361 11.2111 13.5444C11.5028 13.2527 11.6667 12.857 11.6667 12.4444V2.33333H12.4444V0.777778H8.55556V0H3.88889ZM2.33333 2.33333H10.1111V12.4444H2.33333V2.33333ZM3.88889 3.88889V10.8889H5.44444V3.88889H3.88889ZM7 3.88889V10.8889H8.55556V3.88889H7Z"
        fill={fill}
      />
    </svg>
  )
}

export default DeleteIcon
