import { PolicyStatus } from '@lib/constants/policies.constant'
import { Nullable, VIRow } from '@lib/engine-types'
import { FormPeriodInterface } from '@lib/interfaces/form/form-period.interface'
import RetentionPolicyModel from '@lib/models/retention-policy.model'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'

export const getEditPolicyRetentionName = (state: any): string =>
  state.editPolicyRetentionState.policyName
export const getEditPolicyRetentionStatus = (state: any): PolicyStatus =>
  state.editPolicyRetentionState.policyStatus

export const getEditPolicyRetention = (
  state: any
): Nullable<RetentionPolicyModel> =>
  state.editPolicyRetentionState.policyRetention

export const getEditPolicyRetentionAccountIDs = (state: any): VIRow =>
  state.editPolicyRetentionState.policyRetentionAccountIds
export const getEditPolicyRetentionRegions = (state: any): VIRow =>
  state.editPolicyRetentionState.policyRetentionRegions
export const getEditPolicyRetentionBackupTypes = (state: any): VIRow =>
  state.editPolicyRetentionState.policyRetentionBackupTypes
export const getEditPolicyRetentionAssetSources = (state: any): VIRow =>
  state.editPolicyRetentionState.policyRetentionAssetSources
export const getEditPolicyRetentionTags = (state: any): VIRow =>
  state.editPolicyRetentionState.policyRetentionTags
export const getEditPolicyRetentionVaults = (state: any): VIRow =>
  state.editPolicyRetentionState.policyRetentionVaults

export const getEditPolicyRetentionAccountIDsInitial = (state: any): any =>
  state.editPolicyRetentionState.policyRetentionAccountIdsInitial
export const getEditPolicyRetentionRegionsInitial = (state: any): any =>
  state.editPolicyRetentionState.policyRetentionRegionsInitial
export const getEditPolicyRetentionBackupTypesInitial = (state: any): any =>
  state.editPolicyRetentionState.policyRetentionBackupTypesInitial
export const getEditPolicyRetentionAssetSourcesInitial = (state: any): any =>
  state.editPolicyRetentionState.policyRetentionAssetSourcesInitial
export const getEditPolicyRetentionTagsInitial = (state: any): any =>
  state.editPolicyRetentionState.policyRetentionTagsInitial
export const getEditPolicyRetentionVaultsInitial = (state: any): any =>
  state.editPolicyRetentionState.policyRetentionVaultsInitial

export const getEditPolicyRecoveryPeriod = (state: any): FormPeriodInterface =>
  state.editPolicyRetentionState.recoveryPeriod

export const getEditPolicyRecoveryPeriodInitial = (
  state: any
): FormPeriodInterface => state.editPolicyRetentionState.recoveryPeriodInitial

export const getEditPolicyCurrentAssetsSelected = (state: any): VIRow =>
  state.editPolicyRetentionState.currentAssetsSelected
export const getEditPolicyAssetsSelectedInitial = (
  state: any
): Array<AssetWithRelatedAssets<Asset>> =>
  state.editPolicyRetentionState.selectedAssetsInitial
export const getEditPolicyMissingAssetsSelected = (state: any): VIRow =>
  state.editPolicyRetentionState.missingAssetsSelected
export const getEditPolicyMissingAssetsSelectedInitial = (state: any): VIRow =>
  state.editPolicyRetentionState.missingAssetsSelectedInitial
export const getEditPolicyRetentionAssetsSelectedFilters = (
  state: any
): VIRow => state.editPolicyRetentionState.assetsSelectedFilters
