export default function defaultSecurityIcon({
  selected,
}: {
  selected?: boolean
}) {
  const color = selected ? 'white' : '#2F3745'
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 10.4493H7.5C7.7125 10.4493 7.89063 10.3781 8.03438 10.2358C8.17813 10.0935 8.25 9.91709 8.25 9.70668V7.47883C8.25 7.26842 8.17813 7.09205 8.03438 6.94972C7.89063 6.80738 7.7125 6.73621 7.5 6.73621V5.9936C7.5 5.58516 7.35313 5.23551 7.05938 4.94465C6.76563 4.6538 6.4125 4.50837 6 4.50837C5.5875 4.50837 5.23438 4.6538 4.94063 4.94465C4.64688 5.23551 4.5 5.58516 4.5 5.9936V6.73621C4.2875 6.73621 4.10937 6.80738 3.96562 6.94972C3.82187 7.09205 3.75 7.26842 3.75 7.47883V9.70668C3.75 9.91709 3.82187 10.0935 3.96562 10.2358C4.10937 10.3781 4.2875 10.4493 4.5 10.4493ZM5.25 6.73621V5.9936C5.25 5.78319 5.32187 5.60682 5.46562 5.46448C5.60937 5.32215 5.7875 5.25098 6 5.25098C6.2125 5.25098 6.39063 5.32215 6.53438 5.46448C6.67813 5.60682 6.75 5.78319 6.75 5.9936V6.73621H5.25ZM6 14.8307C5.9125 14.8307 5.83125 14.8245 5.75625 14.8122C5.68125 14.7998 5.60625 14.7812 5.53125 14.7565C3.84375 14.1995 2.5 13.1691 1.5 11.6653C0.5 10.1615 0 8.54325 0 6.81048V3.30162C0 2.99219 0.090625 2.71371 0.271875 2.46617C0.453125 2.21863 0.6875 2.03917 0.975 1.92778L5.475 0.256889C5.65 0.195005 5.825 0.164062 6 0.164062C6.175 0.164062 6.35 0.195005 6.525 0.256889L11.025 1.92778C11.3125 2.03917 11.5469 2.21863 11.7281 2.46617C11.9094 2.71371 12 2.99219 12 3.30162V6.81048C12 8.54325 11.5 10.1615 10.5 11.6653C9.5 13.1691 8.15625 14.1995 6.46875 14.7565C6.39375 14.7812 6.31875 14.7998 6.24375 14.8122C6.16875 14.8245 6.0875 14.8307 6 14.8307ZM6 13.3455C7.3 12.9371 8.375 12.1202 9.225 10.8949C10.075 9.66955 10.5 8.30808 10.5 6.81048V3.30162L6 1.63073L1.5 3.30162V6.81048C1.5 8.30808 1.925 9.66955 2.775 10.8949C3.625 12.1202 4.7 12.9371 6 13.3455Z"
        fill={color}
      />
    </svg>
  )
}
