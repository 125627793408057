"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportSchedule = void 0;
class ReportSchedule {
    constructor(args) {
        this.#id = args.id;
        this.#tenantName = args.tenantName;
        this.#name = args.name;
        this.#description = args.description;
        this.#recipientIds = args.recipientIds;
        this.#reportKindWithParams = args.reportKindWithParams;
        this.#schedule = args.schedule;
        this.#timezone = args.timezone;
        this.#nextReportGenerationAt = args.nextReportGenerationAt;
        this.#lastReportGenerationStartedAt = args.lastReportGenerationStartedAt;
        this.#lastReportGenerationCompletedAt = args.lastReportGenerationCompletedAt;
        this.#createdAt = args.createdAt;
        this.#updatedAt = args.updatedAt;
    }
    get id() {
        return this.#id;
    }
    get tenantName() {
        return this.#tenantName;
    }
    get name() {
        return this.#name;
    }
    set name(name) {
        this.#name = name;
    }
    get description() {
        return this.#description;
    }
    set description(description) {
        this.#description = description;
    }
    get recipientIds() {
        return this.#recipientIds;
    }
    get reportKindWithParams() {
        return this.#reportKindWithParams;
    }
    get schedule() {
        return this.#schedule;
    }
    get timezone() {
        return this.#timezone;
    }
    get nextReportGenerationAt() {
        return this.#nextReportGenerationAt;
    }
    get lastReportGenerationStartedAt() {
        return this.#lastReportGenerationStartedAt;
    }
    get lastReportGenerationCompletedAt() {
        return this.#lastReportGenerationCompletedAt;
    }
    get createdAt() {
        return this.#createdAt;
    }
    get updatedAt() {
        return this.#updatedAt;
    }
    #id;
    #tenantName;
    #name;
    #description;
    #recipientIds;
    #reportKindWithParams;
    #schedule;
    #timezone;
    #nextReportGenerationAt;
    #lastReportGenerationStartedAt;
    #lastReportGenerationCompletedAt;
    #createdAt;
    #updatedAt;
}
exports.ReportSchedule = ReportSchedule;
