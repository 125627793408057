import {
  baseRequestScenario,
  sentryReThrowCatchHandler,
} from '@store/epics/epic-func'
import {
  DELETE_ROLE,
  SUBMIT_ROLE_FOR_EDIT,
} from '@store/actions/management-roles.action'
import PreloaderConstants from '@lib/constants/preloader.constant'
import { requestPossibleRoles } from '@store/actions/user.action'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import { StateObservable } from 'redux-observable'
import { getRoleForEdit } from '@store/selectors/management-roles.selector'
import ToastHelper from '@lib/helpers/toast.helper'
import UserRoleModel from '@lib/models/user-role.model'
import GrpcRbacService from '@lib/services/grpc/grpc-rbac.service'

export const submitRoleForEditEpic = (
  action$: any,
  state$: StateObservable<any>
) =>
  action$.pipe(
    baseRequestScenario(
      [SUBMIT_ROLE_FOR_EDIT],
      PreloaderConstants.UPDATE_ROLE,
      () => {
        const role = getRoleForEdit(state$.value)
        let message: string
        let promiseFunc: Promise<UserRoleModel>

        if (role.innerId) {
          // updating
          message = 'The role has been updated'
          promiseFunc = GrpcRbacService.updateRole(role)
        } else {
          // creation
          message = 'The role has been created'
          promiseFunc = GrpcRbacService.createRole(role)
        }

        return promiseFunc
          .then(() => message)
          .catch(sentryReThrowCatchHandler('Cannot perform the role request'))
      },
      (message: string) => {
        ToastHelper.success(message)
        return requestPossibleRoles()
      },
      ErrorGroupConstants.MANAGEMENT_ROLES
    )
  )

export const deleteRoleEpic = (action$: any, state$: StateObservable<any>) =>
  action$.pipe(
    baseRequestScenario(
      [DELETE_ROLE],
      PreloaderConstants.DELETE_ROLE,
      () => {
        const role = getRoleForEdit(state$.value)
        return GrpcRbacService.deleteRole(role.innerId).catch(
          sentryReThrowCatchHandler('Cannot perform the role deletion')
        )
      },
      () => {
        ToastHelper.success('The role has been deleted')
        return requestPossibleRoles()
      },
      ErrorGroupConstants.MANAGEMENT_ROLES
    )
  )
