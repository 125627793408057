"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListAssetsForPolicyCoverage = void 0;
const models_1 = require("../../models");
const ebs_1 = require("../../models/assets/aws/ebs");
const ec2_1 = require("../../models/assets/aws/ec2");
const types_1 = require("../get_asset_filters_for_policy_coverage/types");
const types_2 = require("./types");
class ListAssetsForPolicyCoverage {
    #instances;
    #volumes;
    #s3Buckets;
    #efs;
    #lastBackups;
    #instancesWithRelatedAssets;
    #volumesWithRelatedAssets;
    #s3BucketsWithRelatedAssets;
    #efsWithRelatedAssets;
    constructor(parameters) {
        this.#instances = parameters.instances;
        this.#volumes = parameters.volumes;
        this.#s3Buckets = parameters.s3Buckets;
        this.#efs = parameters.efs;
        this.#lastBackups = parameters.lastBackups;
        this.#instancesWithRelatedAssets ||= this.#getInstancesWithRelatedAssets();
        this.#volumesWithRelatedAssets ||= this.#getVolumesWithRelatedAssets();
        this.#s3BucketsWithRelatedAssets ||= this.#getS3BucketsWithRelatedAssets();
        this.#efsWithRelatedAssets ||= this.#getEfsWithRelatedAssets();
    }
    execute(filters) {
        const assetList = [
            ...this.#instancesWithRelatedAssets,
            ...this.#volumesWithRelatedAssets,
            ...this.#s3BucketsWithRelatedAssets,
            ...this.#efsWithRelatedAssets,
        ];
        return assetList.filter(({ asset }) => {
            if (filters.cloudConnectors &&
                !this.#matchCloudConnectorsForAsset(asset, filters.cloudConnectors)) {
                return false;
            }
            if (filters.accountIds &&
                !filters.accountIds.includes(asset.awsAccountId)) {
                return false;
            }
            if (filters.regions && !filters.regions.includes(asset.awsRegion)) {
                return false;
            }
            if (filters.instanceIds) {
                if (!this.#matchesIncludeFilter(filters.instanceIds, asset.awsId)) {
                    return false;
                }
            }
            if (filters.instanceTypes) {
                if (asset instanceof ec2_1.EC2 && asset.type) {
                    if (!this.#matchesIncludeFilter(filters.instanceTypes, this.#getEc2TypeString(asset.type))) {
                        return false;
                    }
                }
                else {
                    return false;
                }
            }
            if (filters.vpcIds) {
                if (asset instanceof ec2_1.EC2) {
                    if (!this.#matchesIncludeFilter(filters.vpcIds, asset.awsVpcId)) {
                        return false;
                    }
                }
                else {
                    return false;
                }
            }
            if (filters.volumeIds) {
                if (asset instanceof ebs_1.EBS) {
                    if (!this.#matchesIncludeFilter(filters.volumeIds, asset.awsId)) {
                        return false;
                    }
                }
                else {
                    return false;
                }
            }
            if (filters.volumeTypes) {
                if (asset instanceof ebs_1.EBS) {
                    if (!this.#matchesIncludeFilter(filters.volumeTypes, this.#convertEbsTypeToString(asset.type))) {
                        return false;
                    }
                }
                else {
                    return false;
                }
            }
            if (filters.tags && !this.#matchesTags(filters.tags, asset)) {
                return false;
            }
            if ((filters.isProtected?.includes(true) &&
                !this.#isAssetProtected(asset)) ||
                (filters.isProtected?.includes(false) && this.#isAssetProtected(asset))) {
                return false;
            }
            if (filters.isPausedInstances) {
                if (asset instanceof ec2_1.EC2) {
                    const isPaused = this.#isAssetPaused(asset);
                    if (isPaused === null) {
                        return false;
                    }
                    if ((filters.isPausedInstances.includes(true) && !isPaused) ||
                        (filters.isPausedInstances.includes(false) && isPaused)) {
                        return false;
                    }
                }
                else {
                    return false;
                }
            }
            if (filters.idSearch && !asset.awsId.includes(filters.idSearch)) {
                return false;
            }
            return true;
        });
    }
    #getInstancesWithRelatedAssets() {
        const volumeByIdMap = this.#volumes.reduce((map, volume) => {
            map.set(volume.id, volume);
            return map;
        }, new Map());
        return this.#instances.map((instance) => {
            const relatedVolumes = instance.ebsIds
                .map((id) => volumeByIdMap.get(id))
                .filter(Boolean);
            return {
                asset: instance,
                relatedAssets: relatedVolumes,
                type: types_2.AssetKind.AWS_EC2,
            };
        });
    }
    #getVolumesWithRelatedAssets() {
        const instanceByIdMap = this.#instances.reduce((map, instance) => {
            map.set(instance.id, instance);
            return map;
        }, new Map());
        return this.#volumes.map((volume) => {
            const relatedInstances = volume.attachedInstanceIds
                .map((id) => instanceByIdMap.get(id))
                .filter(Boolean);
            return {
                asset: volume,
                relatedAssets: relatedInstances,
                type: types_2.AssetKind.AWS_EBS,
            };
        });
    }
    #getS3BucketsWithRelatedAssets() {
        return this.#s3Buckets.map((s3Bucket) => {
            return {
                asset: s3Bucket,
                relatedAssets: [],
                type: types_2.AssetKind.AWS_S3,
            };
        });
    }
    #getEfsWithRelatedAssets() {
        return this.#efs.map((efs) => {
            return {
                asset: efs,
                relatedAssets: [],
                type: types_2.AssetKind.AWS_EFS,
            };
        });
    }
    #matchCloudConnectorsForAsset(asset, cloudConnectors) {
        const itemRegion = asset.awsRegion;
        const itemAccount = asset.awsAccountId;
        return cloudConnectors?.some((cloudConnector) => cloudConnector.accountId === itemAccount &&
            cloudConnector.region === itemRegion);
    }
    #matchesIncludeFilter(filters, value) {
        if (!filters) {
            return true;
        }
        if (!value) {
            return false;
        }
        return filters.includes(value);
    }
    #matchesTags(tags, asset) {
        const filterTags = tags.map((tag) => {
            const [key, value] = tag.split('=');
            return { key, value };
        });
        for (const { key, value } of filterTags) {
            const assetTag = asset.tags.find((tag) => tag.key === key);
            if (assetTag !== undefined && assetTag.value === value) {
                return true;
            }
        }
        return false;
    }
    #isAssetCoveredByPolicies(asset) {
        return asset.backupPolicies.length > 0;
    }
    #isAssetPaused(asset) {
        if (asset.state === models_1.EC2State.TERMINATED) {
            return null;
        }
        if (asset.state === models_1.EC2State.STOPPED) {
            return true;
        }
        if (asset.state === models_1.EC2State.RUNNING) {
            return false;
        }
    }
    #isAssetProtected(asset) {
        return (this.#isAssetCoveredByPolicies(asset) && this.#lastBackups.has(asset.id));
    }
    #convertEbsTypeToString(type) {
        switch (type) {
            case models_1.EBSType.STANDARD:
                return types_1.EBSTypeString.STANDARD;
            case models_1.EBSType.GP2:
                return types_1.EBSTypeString.GP2;
            case models_1.EBSType.GP3:
                return types_1.EBSTypeString.GP3;
            case models_1.EBSType.IO1:
                return types_1.EBSTypeString.IO1;
            case models_1.EBSType.IO2:
                return types_1.EBSTypeString.IO2;
            case models_1.EBSType.SC1:
                return types_1.EBSTypeString.SC1;
            case models_1.EBSType.ST1:
                return types_1.EBSTypeString.ST1;
            default:
                return null;
        }
    }
    #getEc2TypeString(type) {
        return `${type.family}.${type.size}`;
    }
}
exports.ListAssetsForPolicyCoverage = ListAssetsForPolicyCoverage;
