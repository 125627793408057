import { NotificationsConfig } from '@lib/interfaces/notifications.interface'
import TenantNotificationsState from '@store/states/tenant-notifications.state'

export const REQUEST_TENANT_NOTIFICATIONS_SETTINGS =
  'REQUEST_TENANT_NOTIFICATIONS_SETTINGS'
export const REQUEST_TENANT_NOTIFICATIONS_SETTINGS_SHADOW =
  'REQUEST_TENANT_NOTIFICATIONS_SETTINGS_SHADOW'
export const SET_TENANT_NOTIFICATIONS_SETTINGS =
  'SET_TENANT_NOTIFICATIONS_SETTINGS'
export const UPDATE_TENANT_NOTIFICATIONS_SETTINGS =
  'UPDATE_TENANT_NOTIFICATIONS_SETTINGS'

export const DISCONNECT_SLACK_FROM_TENANT = 'DISCONNECT_SLACK_FROM_TENANT'

export const requestTenantNotificationsSettings = () => ({
  type: REQUEST_TENANT_NOTIFICATIONS_SETTINGS,
  payload: null,
})

export const setTenantNotificationsSettings = (
  config: TenantNotificationsState
) => ({
  type: SET_TENANT_NOTIFICATIONS_SETTINGS,
  payload: config,
})

export const updateTenantNotificationsSettings = (
  config: NotificationsConfig
) => ({
  type: UPDATE_TENANT_NOTIFICATIONS_SETTINGS,
  payload: config,
})

export const requestTenantNotificationsSettingsShadow = () => ({
  type: REQUEST_TENANT_NOTIFICATIONS_SETTINGS_SHADOW,
  payload: null,
})

export const requestDisconnectSlackFromTenant = () => ({
  type: DISCONNECT_SLACK_FROM_TENANT,
  payload: null,
})
