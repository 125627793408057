/* eslint-disable import/no-extraneous-dependencies */
import TimeHelper from '@lib/helpers/time.helper'
import { Ec2 as Ec2Proto } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/assets/aws/ec2_pb'
import { EC2, EC2State } from 'blues-corejs/dist'
import {
  EC2Type,
  AMIDetails,
} from 'blues-corejs/dist/models/assets/aws/ec2/types'

type Ec2Pt = Ec2Proto.AsObject

export class EC2Transformer {
  #instance: Ec2Pt

  constructor(instance: Ec2Pt) {
    this.#instance = instance
  }

  transform(): EC2 {
    return this.#transformInstance(this.#instance)
  }

  #transformInstance(instance: Ec2Pt): EC2 {
    return new EC2({
      state: this.#convertEC2State(instance.state),
      id: instance.id,
      awsId: instance.awsId,
      awsAccountId: instance.awsAccountId,
      arn: instance.arn,
      awsVpcId: instance.awsVpcId,
      awsRegion: instance.awsRegion,
      ebsIds: instance.ebsIdsList,
      osKind: instance.osKind,
      backupPolicies: instance.backupPoliciesList,
      ccAssetId: instance.ccAssetId,
      tags: instance.tagsList,
      type: this.#convertEC2Type(instance.type),
      retentionPolicy: instance.retentionPoliciesList,
      amiDetails: this.#convertAmiDetails(instance.amiDetails),
      createdAt: TimeHelper.secondsToDate(instance.createdAt?.seconds),
      updatedAt: TimeHelper.secondsToDate(instance.updatedAt?.seconds),
      startedAt: TimeHelper.secondsToDate(instance.startedAt?.seconds),
      hasReplicatedBackups: instance.hasReplicatedBackups,
    })
  }

  #convertEC2State(state: Ec2Proto.Ec2State): EC2State {
    const mapping = {
      [Ec2Proto.Ec2State.STATE_RUNNING]: EC2State.RUNNING,
      [Ec2Proto.Ec2State.STATE_STOPPED]: EC2State.STOPPED,
      [Ec2Proto.Ec2State.STATE_TERMINATED]: EC2State.TERMINATED,
      [Ec2Proto.Ec2State.STATE_UNKNOWN]: EC2State.UNKNOWN,
    }

    return mapping[state]
  }

  #convertAmiDetails(
    details?: Ec2Proto.AmiDetails.AsObject
  ): AMIDetails | undefined {
    if (!details) {
      return
    }

    return {
      awsId: details.awsId,
      location: details.location,
      name: details.name,
    }
  }

  #convertEC2Type(type?: Ec2Proto.Ec2Type.AsObject): EC2Type | undefined {
    if (!type) {
      return
    }

    return {
      family: type.family,
      size: type.size,
      spot: type.spot,
    }
  }
}
