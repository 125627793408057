import React, { PropsWithChildren } from 'react'
import { OnMenuClickData, useRowMenu } from '@tables/core/table-handlers'
import { EngineCallback } from '@lib/engine-types'
import BlueVerticalDots from '@inline-img/general/blue-vertical-dots-icon'
import EditIcon from '@inline-img/general/menu/edit-icon'
import DeleteIcon from '@inline-img/general/menu/delete-icon'
import UnlinkIcon from '@inline-img/general/menu/unlink-icon'
import LinkIcon from '@inline-img/general/menu/link-icon'
import InviteIcon from '@inline-img/general/menu/invite-icon'
import PauseResumeIcon from '@inline-img/general/menu/pause-resume-icon'
import { EmptyFunc } from '@lib/constants/app.constant'
import UpgradeRequiredIcon from '@inline-img/general/upgrade-required-icon'
import StrHelper from '@lib/helpers/str.helper'
import ActivatedIcon from '@inline-img/general/region-status/activated-icon'
import { IconButton, Menu, MenuItem } from '@mui/material'
import PlayIcon from '@inline-img/general/play-icon'

function drawIcon(label: string) {
  switch (label) {
    case 'Edit':
      return <EditIcon />
    case 'Remove':
    case 'Revoke':
    case 'Disable':
    case 'Delete':
      return <DeleteIcon />
    case 'Unlink':
    case 'Disconnect':
      return <UnlinkIcon />
    case 'Link':
    case 'Reconnect':
      return <LinkIcon />
    case 'Resend invite':
      return <InviteIcon />
    case 'Pause/Resume':
      return <PauseResumeIcon />
    case 'Upgrade':
      return <UpgradeRequiredIcon />
    case 'Enable':
      // TODO: not finished decision
      return <ActivatedIcon />
    case 'Execute':
      return <PlayIcon className="execute-policy" />
    default:
      return ''
  }
}

export type MenuAvailability<S extends string> = (menu: S) => boolean

interface Props<S extends string> {
  data: any
  menu?: Array<S>
  showMenu: boolean
  onMenuClick?: EngineCallback<OnMenuClickData<S>>
  menuIsAvailable?: MenuAvailability<S>
  menuIcon?: JSX.Element
}

function TableMenu<S extends string>({
  data,
  menu,
  showMenu,
  onMenuClick,
  menuIsAvailable = () => true,
  menuIcon = <BlueVerticalDots />,
}: PropsWithChildren<Props<S>>): JSX.Element | null {
  const [anchorEl, open, handleClick, handleClose, handleMenuClick] =
    useRowMenu<any, S>(data, onMenuClick ?? EmptyFunc)

  if (!menu || !onMenuClick || !showMenu || menu.length === 0) {
    return null
  }

  return (
    <>
      <div className="iconBtnWrap iconBtnNoHover jsTableMenu">
        <IconButton
          size="small"
          onClick={handleClick}
          aria-label="Table menu button"
        >
          {menuIcon}
        </IconButton>
      </div>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menu.map((m) => (
          <MenuItem
            key={m}
            onClick={() => handleMenuClick(m)}
            disabled={!menuIsAvailable(m)}
            aria-label={`Menu Item - ${m}`}
          >
            <div
              className={`tableIconRow ${StrHelper.buildClassName(m, 'js')}`}
            >
              <div className="tableIcon">{drawIcon(m)}</div>
              <div className="tableIconName">{m}</div>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default TableMenu
