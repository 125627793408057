import React from 'react'

function SelectArrowIcon({ className = '' }) {
  return (
    <svg
      width="20"
      height="21"
      className={className}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25829 10.2584L9.41663 12.4167C9.74163 12.7417 10.2666 12.7417 10.5916 12.4167L12.75 10.2584C13.275 9.73337 12.9 8.83337 12.1583 8.83337H7.84163C7.09996 8.83337 6.73329 9.73337 7.25829 10.2584Z"
        fill="#1A1F28"
      />
    </svg>
  )
}

export default SelectArrowIcon
