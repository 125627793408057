"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OsKind = exports.INTEGRITY_SCAN_FAILED = exports.matchesIncludeFilter = void 0;
function matchesIncludeFilter(filters, value) {
    if (!filters) {
        return true;
    }
    if (!value) {
        return false;
    }
    return filters.includes(value);
}
exports.matchesIncludeFilter = matchesIncludeFilter;
exports.INTEGRITY_SCAN_FAILED = 'Integrity Scan Failed';
var OsKind;
(function (OsKind) {
    OsKind[OsKind["OTHER"] = 0] = "OTHER";
    OsKind[OsKind["LINUX"] = 1] = "LINUX";
    OsKind[OsKind["WINDOWS"] = 2] = "WINDOWS";
})(OsKind = exports.OsKind || (exports.OsKind = {}));
