import PaginationModel from '@lib/models/pagination.model'
import TypeHelper from '@lib/helpers/type.helper'
import BaseModel from '@lib/models/base-model/base.model'

abstract class PaginationFactory {
  public static build(params: any): PaginationModel {
    return new PaginationModel({
      limit: TypeHelper.numberValDef(params.limit, 0),
      total: TypeHelper.numberValDef(params.total, 0),
      offset: TypeHelper.numberValDef(params.offset, 0),
    })
  }

  public static buildEmpty(): PaginationModel {
    return PaginationFactory.build({
      limit: 10,
      total: 0,
      offset: 0,
    })
  }

  public static buildFive(): PaginationModel {
    return PaginationFactory.build({
      limit: 5,
      total: 0,
      offset: 0,
    })
  }

  public static buildOne(): PaginationModel {
    return PaginationFactory.build({
      limit: 1,
      total: 0,
      offset: 0,
    })
  }

  public static clone(
    source: PaginationModel,
    params: any = {}
  ): PaginationModel {
    return BaseModel.clone(source, params)
  }

  public static cloneReset(source: PaginationModel) {
    return PaginationFactory.clone(source, { offset: 0 })
  }
}

export default PaginationFactory
