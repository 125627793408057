import ButtonsRowElement from '@lib/interfaces/buttons-row-element.interface'

export enum MoreBlockV2Entity {
  Default,
  SuccessText,
  SuccessLink,
  ErrorLink,
  TagsList,
  LinkText,
  ErrorsList,
  EmptyBlock,
  TextWithQuestionHint,
  NoneLink,
  CopyText,
  LinkCopyText,
  ButtonsRowBlock,
}

export interface ButtonsRowProps {
  data: Array<ButtonsRowElement>
}
