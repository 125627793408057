import { ManagementClient, CustomerElastioRegion } from '@lib/clients'
import { useEffect, useState } from 'react'
import { setupGrpcClientTokenIfNeeded } from '@lib/helpers/setup-grpc-client-token'
import { GrpcClient } from '@lib/clients/grpc-client'

const managementClient = new ManagementClient()

export function useFetchElastioRegions(): {
  elastioRegions: Array<CustomerElastioRegion>
  isLoading: boolean
  error: null | string
  setActiveRegion: (regionName: string) => void
  selectedRegion: string
} {
  const [elastioRegions, setElastioRegions] = useState<
    Array<CustomerElastioRegion>
  >([])
  const [selectedRegion, setSelectedRegion] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<null | string>(null)

  useEffect(() => {
    let isMounted = true
    const fetchElastioRegions = async () => {
      setIsLoading(true)
      setError(null)

      await setupGrpcClientTokenIfNeeded(GrpcClient)

      const response = await managementClient.listElastioRegions()
      if (isMounted) {
        setElastioRegions(response)
        setIsLoading(false)

        if (response.length) {
          setSelectedRegion(response[0]?.regionName || '')
        }
      }
    }

    fetchElastioRegions()

    return () => {
      isMounted = false
      setIsLoading(true)
    }
  }, [])

  const setActiveRegion = (regionName: string) => {
    setSelectedRegion(regionName)
  }

  return {
    elastioRegions,
    selectedRegion,
    setActiveRegion,
    isLoading,
    error,
  }
}
