import { Response } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/assets/list_live_assets_pb'
import { LiveAssets } from '@lib/clients/assets/types'
import { BackupProcessor } from './backup-processor'
import {
  EbsSnapshotTransformer,
  EBSTransformer,
  EC2Transformer,
  EFSTransformer,
  ElastioRecoveryPointTransformer,
  FilesystemScanTransformer,
  GenericHostTransformer,
  MalwareScanTransformer,
  RansomwareScanTransformer,
  S3BucketTransformer,
  ThreatTransformer,
} from './transformers'

export class ListLiveAssetsTransformer {
  transformResponse(response: Response): LiveAssets {
    const {
      ec2InstancesList,
      ebsVolumesList,
      s3BucketsList,
      efsList,
      threatsList,
      genericHostsList,
      scansList,
      lastEbsSnapshotsList,
      lastElastioBackupsMap,
    } = response.toObject()

    const lastBackupsMap = response.getLastBackupsMap()

    const firstBackupsMap = response.getFirstBackupsMap()

    return {
      genericHosts: genericHostsList.map((host) =>
        new GenericHostTransformer(host).transform()
      ),
      ec2Instances: ec2InstancesList.map((instance) =>
        new EC2Transformer(instance).transform()
      ),
      ebsVolumes: ebsVolumesList.map((volume) =>
        new EBSTransformer(volume).transform()
      ),
      s3Buckets: s3BucketsList.map(
        (bucket) => new S3BucketTransformer(bucket).transform
      ),
      efs: efsList.map((filesystem) =>
        new EFSTransformer(filesystem).transform()
      ),
      threats: threatsList.map((threat) =>
        new ThreatTransformer(threat).transform()
      ),
      lastEbsSnapshots: lastEbsSnapshotsList.map((snapshot) =>
        new EbsSnapshotTransformer(snapshot).transform()
      ),
      scans: scansList.map(
        ({ filesystemCheckScan, ransomwareScan, malwareScan }) => {
          return {
            filesystemCheckScan:
              filesystemCheckScan &&
              new FilesystemScanTransformer(filesystemCheckScan).transform(),
            malwareScan:
              malwareScan &&
              new MalwareScanTransformer(malwareScan).transform(),
            ransomwareScan:
              ransomwareScan &&
              new RansomwareScanTransformer(ransomwareScan).transform(),
          }
        }
      ),
      lastBackups: new BackupProcessor(lastBackupsMap),
      firstBackups: new BackupProcessor(firstBackupsMap),
      lastElastioBackupsMap: this.#transformLastElastioBackupsMap(
        lastElastioBackupsMap
      ),
    }
  }

  #transformLastElastioBackupsMap(
    lastElastioBackupsMap: Response.AsObject['lastElastioBackupsMap']
  ) {
    const map = new Map()
    for (const [, [assetId, backup]] of lastElastioBackupsMap.entries()) {
      map.set(assetId, new ElastioRecoveryPointTransformer(backup).transform())
    }
    return map
  }
}
