import VaultState from '@store/states/vault.state'
import ActionInterface from '@lib/interfaces/action.interface'
import {
  SET_ACCOUNT_RED_STACKS,
  SET_ACCOUNT_VAULT_LIST,
  SET_ALL_ACTIVE_RED_STACKS,
  SET_NEW_DEFAULT_VAULT,
  SET_VAULT_LIST,
  SET_SOURCES_VAULT_LIST,
  SET_VAULT,
  SET_VAULT_METRICS,
  UPDATE_VAULT_DATA,
} from '@store/actions/vault.action'
import VaultFactory from '@lib/factories/vault.factory'
import BaseModel from '@lib/models/base-model/base.model'

const initialState: VaultState = {
  vaultList: [],
  accountVaultList: [],
  accountRedStacks: [],
  allActiveRedStacks: [],
  sourcesVaultList: [],
  vaultModel: VaultFactory.buildEmptyVault(),
  vaultMetricsModel: VaultFactory.buildEmptyVaultMetrics(),
}

function vaultReducer(
  prevState = initialState,
  action: ActionInterface
): VaultState {
  switch (action.type) {
    case SET_VAULT_LIST:
      return {
        ...prevState,
        vaultList: action.payload,
      }

    case SET_ACCOUNT_VAULT_LIST:
      return {
        ...prevState,
        accountVaultList: action.payload,
      }

    case SET_SOURCES_VAULT_LIST:
      return {
        ...prevState,
        sourcesVaultList: action.payload,
      }

    case SET_ACCOUNT_RED_STACKS:
      return {
        ...prevState,
        accountRedStacks: action.payload,
      }

    case SET_ALL_ACTIVE_RED_STACKS:
      return {
        ...prevState,
        allActiveRedStacks: action.payload,
      }

    case SET_NEW_DEFAULT_VAULT:
      return {
        ...prevState,
        accountVaultList: prevState.accountVaultList.map((v) => {
          if (v.region !== action.payload.region) {
            return v
          }

          if (v.innerId !== action.payload.innerId) {
            return {
              ...v,
              isDefault: false,
            }
          }

          return {
            ...v,
            isDefault: true,
          }
        }),
      }

    case SET_VAULT:
      return {
        ...prevState,
        vaultModel: action.payload,
      }

    case UPDATE_VAULT_DATA:
      return {
        ...prevState,
        vaultModel: BaseModel.clone(prevState.vaultModel, {
          description: action.payload.description,
          vpcId: action.payload.vpcId,
          subnetIdsList: [...action.payload.subnetIdsList],
        }),
      }

    case SET_VAULT_METRICS:
      return {
        ...prevState,
        vaultMetricsModel: action.payload,
      }

    default:
      return prevState
  }
}

export default vaultReducer
