/* eslint-disable import/no-extraneous-dependencies */
import { AWSSliceCriteria } from 'blues-corejs/dist/models/inventory.model'
import { OVASliceCriteria } from 'blues-corejs/dist/models/ova-account'

export enum ViewsVersion {
  V1 = 1,
  V2 = 2,
}

export enum ViewKind {
  AWS = 0,
  OVA = 1,
}

export type TabVariant = 'overview' | 'security' | 'recovery' | 'ova'

/** @description If this interface gonna be changed, please follow up this changes in function `viewsConfigToLatestVersion`
 */
export interface TabViewInterface {
  id: string
  name: string
  slice: AWSSliceCriteria | OVASliceCriteria
  variant: TabVariant
  version: ViewsVersion
  isDefaultDashboard: boolean
  kind: ViewKind
}
