export default function RecoveryIcon({ selected }: { selected?: boolean }) {
  const color = selected ? 'white' : '#2F3745'

  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33331 6.50233C9.33331 5.76899 8.73331 5.16899 7.99998 5.16899C7.26665 5.16899 6.66665 5.76899 6.66665 6.50233C6.66665 7.23566 7.26665 7.83566 7.99998 7.83566C8.73331 7.83566 9.33331 7.23566 9.33331 6.50233ZM8.17331 0.502327C4.77998 0.408993 1.99998 3.13566 1.99998 6.50233H0.806646C0.506646 6.50233 0.35998 6.86233 0.573313 7.06899L2.43331 8.92899C2.56665 9.06233 2.77331 9.06233 2.90665 8.92899L4.76665 7.06899C4.97331 6.86233 4.82665 6.50233 4.52665 6.50233H3.33331C3.33331 3.90233 5.45331 1.80233 8.06665 1.83566C10.5466 1.86899 12.6333 3.95566 12.6666 6.43566C12.7 9.04233 10.6 11.169 7.99998 11.169C7.16665 11.169 6.38665 10.9423 5.70665 10.5623C5.44665 10.4157 5.12665 10.469 4.91998 10.6823C4.61331 10.989 4.67331 11.5157 5.05331 11.729C5.92665 12.2157 6.92665 12.5023 7.99998 12.5023C11.3666 12.5023 14.0933 9.72233 14 6.32899C13.9133 3.20233 11.3 0.588993 8.17331 0.502327Z"
        fill={color}
      />
    </svg>
  )
}
