import UserModel from '@lib/models/user.model'
import FormAddUserInterface from '@lib/interfaces/form/form-add-user.interface'
import SessionModel from '@lib/models/session.model'
import FormEditUserInterface from '@lib/interfaces/form/form-edit-user.interface'
import UserRoleModel from '@lib/models/user-role.model'
import FormChangePassword from '@lib/interfaces/form/form-change-password.interface'

export const REQUEST_POSSIBLE_ROLES = 'REQUEST_POSSIBLE_ROLES'
export const SET_POSSIBLE_ROLES = 'SET_POSSIBLE_ROLES'
export const REQUEST_USERS_LIST = 'REQUEST_USERS_LIST'
export const SET_USERS_LIST = 'SET_USERS_LIST'
export const REQUEST_USER = 'REQUEST_USER'
export const REMOVE_USER = 'REMOVE_USER'
export const DISABLE_USER = 'DISABLE_USER'
export const SET_USER = 'SET_USER'
export const REQUEST_UPDATE_USER = 'REQUEST_UPDATE_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const VERIFY_USER = 'VERIFY_USER'
export const INVITE_USER = 'INVITE_USER'
export const RESEND_INVITE_USER = 'RESEND_INVITE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const REQUEST_SESSIONS = 'REQUEST_SESSIONS'
export const SET_SESSIONS = 'SET_SESSIONS'
export const REQUEST_IS_MFA_ENABLED = 'REQUEST_IS_MFA_ENABLED'
export const SET_IS_MFA_ENABLED = 'SET_IS_MFA_ENABLED'
export const UPDATE_MFA_ENABLED = 'UPDATE_MFA_ENABLED'
export const RESET_MFA = 'RESET_MFA'
export const ENABLE_USER = 'ENABLE_USER'

export const requestUsersList = () => ({
  type: REQUEST_USERS_LIST,
  payload: null,
})

export const setUsersList = (payload: Array<UserModel>) => ({
  type: SET_USERS_LIST,
  payload,
})

export const requestUser = () => ({
  type: REQUEST_USER,
})

export const disableUser = (payload: string) => ({
  type: DISABLE_USER,
  payload,
})

export const removeUser = (payload: string) => ({
  type: REMOVE_USER,
  payload,
})

export const enableUser = (payload: string) => ({
  type: ENABLE_USER,
  payload,
})

export const requestUpdateUser = (user: UserModel) => ({
  type: REQUEST_UPDATE_USER,
  payload: user,
})

export const setUser = (model: UserModel) => ({
  type: SET_USER,
  payload: model,
})

export const logoutUser = () => ({
  type: LOGOUT_USER,
  payload: null,
})

export const inviteUser = (payload: FormAddUserInterface) => ({
  type: INVITE_USER,
  payload,
})

export const resendInviteUser = (email: string, roleId: string) => ({
  type: RESEND_INVITE_USER,
  payload: {
    email,
    roleId,
  },
})

export const updateUser = (payload: FormEditUserInterface) => ({
  type: UPDATE_USER,
  payload,
})

export const verifyUser = (payload: string) => ({
  type: VERIFY_USER,
  payload,
})

export const updatePassword = (formData: FormChangePassword) => ({
  type: UPDATE_PASSWORD,
  payload: formData,
})

export const requestSessions = () => ({
  type: REQUEST_SESSIONS,
  payload: null,
})

export const setSessions = (sessions: Array<SessionModel>) => ({
  type: SET_SESSIONS,
  payload: sessions,
})

export const requestPossibleRoles = () => ({
  type: REQUEST_POSSIBLE_ROLES,
  payload: null,
})

export const setPossibleRoles = (roles: Array<UserRoleModel>) => ({
  type: SET_POSSIBLE_ROLES,
  payload: roles,
})

export const requestIsMfaEnabled = () => ({
  type: REQUEST_IS_MFA_ENABLED,
  payload: null,
})

export const setIsMfaEnabled = (isMfaEnabled: boolean) => ({
  type: SET_IS_MFA_ENABLED,
  payload: isMfaEnabled,
})

export const updateMfaEnabled = (payload: boolean) => ({
  type: UPDATE_MFA_ENABLED,
  payload,
})

export const resetMfa = () => ({
  type: RESET_MFA,
  payload: null,
})
