import { VIRowReadonly } from '@lib/engine-types'

export const COMPLIANCE_REPORTS = (): VIRowReadonly => [
  {
    name: 'PolicyManagedEC2',
    label: 'Policy Managed EC2',
    value: 'policy_managed_instance_backups',
    defaultValue: 'policy_managed_instances',
    extraValue: 'policy-managed-EC2.mrt',
  },
  {
    name: 'PolicyManagedEBS',
    label: 'Policy Managed EBS',
    value: 'policy_managed_volume_backups',
    defaultValue: 'policy_managed_volumes',
    extraValue: 'policy-managed-EBS.mrt',
  },

  {
    name: 'CustomerManagedEC2',
    label: 'Customer Managed EC2',
    value: 'customer_managed_instance_backups',
    defaultValue: 'customer_managed_instances',
    extraValue: 'customer-managed-EC2.mrt',
  },
  {
    name: 'CustomerManagedEBS',
    label: 'Customer Managed EBS',
    value: 'customer_managed_volume_backups',
    defaultValue: 'customer_managed_volumes',
    extraValue: 'customer-managed-EBS.mrt',
  },
  {
    name: 'CustomerManagedBlock',
    label: 'Customer Managed Block',
    value: 'customer_managed_block_backups',
    defaultValue: 'customer_managed_block_backups',
    extraValue: 'customer-managed-block.mrt',
  },

  {
    name: 'CustomerManagedFile',
    label: 'Customer Managed File',
    value: 'customer_managed_file_backups',
    defaultValue: 'customer_managed_file_backups',
    extraValue: 'customer-managed-file.mrt',
  },
  {
    name: 'CustomerManagedStream',
    label: 'Customer Managed Stream',
    value: 'customer_managed_stream_backups',
    defaultValue: 'customer_managed_stream_backups',
    extraValue: 'customer-managed-stream.mrt',
  },
]
