import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const HEIGHT = '150px'
const SCROLLBAR_COLOR = 'var(--grey700) transparent'
const SCROLLBAR_WIDTH = '4px'
export const Root = styled(Box)`
 overflow: auto;
  height: calc(100vh - ${HEIGHT});
  &::-webkit-scrollbar: {
    width: ${SCROLLBAR_WIDTH};
  },
  &::-webkit-scrollbar-thumb: {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  },
  scrollbar-color: ${SCROLLBAR_COLOR};
`
