/* eslint-disable import/no-extraneous-dependencies */
import TimeHelper from '@lib/helpers/time.helper'
import { S3Item as S3ItemProto } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/asset_items/s3_item_pb'
import { S3Item } from 'blues-corejs/dist'
import {
  S3ItemKind,
  Selector,
} from 'blues-corejs/dist/models/asset-items/s3-item/types'

type S3Pt = S3ItemProto.AsObject

export class S3ItemTransformer {
  #S3Item: S3Pt

  constructor(s3Item: S3Pt) {
    this.#S3Item = s3Item
  }

  transform(): S3Item {
    return this.#transformS3Item(this.#S3Item)
  }

  #transformS3Item(item: S3Pt): S3Item {
    return new S3Item({
      id: item.id,
      ccAssetId: item.ccAssetId,
      assetId: item.assetId,
      selectors: this.#convertSelectors(item.selectorsList),
      createdAt: TimeHelper.secondsToDate(item.createdAt?.seconds),
      updatedAt: TimeHelper.secondsToDate(item.updatedAt?.seconds),
    })
  }

  #convertSelectors(
    selectors: Array<S3ItemProto.Selector.AsObject>
  ): Array<Selector> {
    return selectors.map((selector) => ({
      kind: this.#convertSelectorKind(selector.kind),
      value: selector.value,
    }))
  }

  #convertSelectorKind(kind: S3ItemProto.Selector.Kind): S3ItemKind {
    const mapping = {
      [S3ItemProto.Selector.Kind.KIND_GLOB]: S3ItemKind.GLOB,
      [S3ItemProto.Selector.Kind.KIND_KEY]: S3ItemKind.KEY,
      [S3ItemProto.Selector.Kind.KIND_PREFIX]: S3ItemKind.PREFIX,
    }

    return mapping[kind]
  }
}
