import RequestService from '@lib/services/request.service'
import { GrpcClient } from '@lib/clients/grpc-client'
import { grpcServices } from '@lib/hooks/engine/grpc-services'

function initializeClients(token: string) {
  RequestService.init(token)

  GrpcClient.setToken(token)

  grpcServices.forEach((service) => {
    service.init(token)
  })
}

export { initializeClients }
