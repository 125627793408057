/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import {
  CellWithButtonLink,
  createDisplayColumn,
  RowHeight,
} from '@components/table'
import { EngineCallback } from '@lib/engine-types'
import { OnMenuClickData } from '@tables/core/table-handlers'
import TableMenu from '@tables/core/table-menu'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'
import { Backup } from 'blues-corejs/dist'
import {
  BackupSizeCell,
  BackupSourceCell,
  StatusCell,
  ThreatIconsCell,
} from './cells'
import { getAvailableMenuItems } from '@features/asset-page/modals/recovery-point-details/components/recovery-point-details-header/backup-actions'

interface RecoveryPointsColumnDefinitionProps {
  onBackupClick: EngineCallback<Backup>
  onRowMenuClick: EngineCallback<OnMenuClickData>
}

export function getRecoveryPointsTableColumnDefinitions({
  onBackupClick,
  onRowMenuClick,
}: RecoveryPointsColumnDefinitionProps) {
  return [
    createDisplayColumn<Backup>({
      id: 'status',
      enableSorting: false,
      meta: {
        width: '40',
        height: RowHeight.SMALL,
      },
      cell: ({ row: { original } }) => {
        return <StatusCell status={original.summaryCheckStatus} />
      },
    }),

    createDisplayColumn<Backup>({
      id: 'scan-status',
      enableSorting: false,
      header: 'Risks',
      meta: {
        width: '100',
        height: RowHeight.SMALL,
      },
      cell: ({ row: { original } }) => {
        return <ThreatIconsCell backup={original} />
      },
    }),

    createDisplayColumn<Backup>({
      enableSorting: false,
      id: 'timestamp',
      header: 'Recovery Point Time',
      meta: {
        width: '120',
      },
      cell: ({ row: { original } }) => {
        const onRecoveryPointClick = () => {
          onBackupClick(original)
        }

        const timestamp = original.timestamp

        return (
          <CellWithButtonLink onClick={onRecoveryPointClick}>
            <DateTimeTooltip
              placement="top-start"
              date={timestamp}
              direction="row"
            />
          </CellWithButtonLink>
        )
      },
    }),

    createDisplayColumn<Backup>({
      id: 'backup-type',
      enableSorting: false,
      header: 'Backup Source',

      cell: ({ row: { original } }) => <BackupSourceCell backup={original} />,
    }),

    createDisplayColumn<Backup>({
      id: 'backup-size',
      enableSorting: false,
      header: 'Backup Size (GiB)',
      meta: {
        textAlign: 'right',
      },
      cell: ({ row: { original } }) => <BackupSizeCell backup={original} />,
    }),

    createDisplayColumn<Backup>({
      id: 'backup-actions',
      enableSorting: false,
      meta: {
        width: '40',
      },
      cell: ({ row: { original: backup } }) => {
        return (
          <TableMenu
            data={backup}
            menu={getAvailableMenuItems(backup)}
            showMenu
            onMenuClick={onRowMenuClick}
            menuIcon={<MoreVertIcon htmlColor="#1A1F28" />}
          />
        )
      },
    }),
  ]
}
