/* eslint-disable import/no-extraneous-dependencies */
import ActionInterface from '@lib/interfaces/action.interface'
import { DashboardState } from '@store/states/dashboard.state'
import {
  SET_ASSETS_EBS_VULNERABILITIES_SELECTED_FILTERS,
  SET_DASHBOARD_DATA,
  SET_SNAPSHOTS_VULNERABILITIES_SELECTED_FILTERS,
} from '@store/actions/dashboard.action'

const initialState: DashboardState = {
  dashboardData: null,
  selectedVolumesVulnerabilitiesFilters: [],
  selectedSnapshotsVulnerabilitiesFilters: [],
}

function dashboardReducer(
  prevState = initialState,
  action: ActionInterface
): DashboardState {
  switch (action.type) {
    case SET_DASHBOARD_DATA:
      return {
        ...prevState,
        dashboardData: action.payload,
      }

    case SET_ASSETS_EBS_VULNERABILITIES_SELECTED_FILTERS:
      return {
        ...prevState,
        selectedVolumesVulnerabilitiesFilters: action.payload,
      }

    case SET_SNAPSHOTS_VULNERABILITIES_SELECTED_FILTERS:
      return {
        ...prevState,
        selectedSnapshotsVulnerabilitiesFilters: action.payload,
      }

    default:
      return prevState
  }
}

export default dashboardReducer
