"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMockEfsAttributes = exports.EfsModel = void 0;
const threat_status_model_1 = require("./threat-status.model");
class EfsModel extends threat_status_model_1.AssetWithThreat {
    id;
    name;
    accountId;
    region;
    cloudConnectorId;
    cloudConnectorAssetId;
    tags;
    createdAt;
    updatedAt;
    coveredByPolicies;
    coveredByPausedPolicies;
    associatedRetentionPolicies;
    constructor(attrs) {
        super(attrs);
        this.id = attrs.id;
        this.name = attrs.name;
        this.accountId = attrs.accountId;
        this.region = attrs.region;
        this.cloudConnectorId = attrs.cloudConnectorId;
        this.cloudConnectorAssetId = attrs.cloudConnectorAssetId;
        this.tags = attrs.tags;
        this.createdAt = attrs.createdAt;
        this.updatedAt = attrs.updatedAt;
        this.threatStatus = attrs.threatStatus;
        this.coveredByPolicies = attrs.coveredByPolicies;
        this.coveredByPausedPolicies = attrs.coveredByPausedPolicies;
        this.associatedRetentionPolicies = attrs.associatedRetentionPolicies;
    }
    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getAccountId() {
        return this.accountId;
    }
    getRegion() {
        return this.region;
    }
    getCloudConnectorId() {
        return this.cloudConnectorId;
    }
    getCloudConnectorAssetId() {
        return this.cloudConnectorAssetId;
    }
    getTags() {
        return Array.from(this.tags);
    }
    getTagsMap() {
        return this.tags;
    }
    getCreatedAt() {
        return this.createdAt;
    }
    getUpdatedAt() {
        return this.updatedAt;
    }
    getCoveredByPausedPolicies() {
        return this.coveredByPausedPolicies;
    }
    getCoveredByPolicies() {
        return this.coveredByPolicies;
    }
    getAssociatedRetentionPolicies() {
        return this.associatedRetentionPolicies;
    }
    getParsedCloudProviderAssetId() {
        return (this.cloudConnectorAssetId?.split(':')?.pop() ??
            this.cloudConnectorAssetId ??
            '');
    }
    isCoveredByPolicy() {
        return this.coveredByPolicies.length > 0;
    }
    isProtected() {
        return this.isLastRecoveryPointId() && this.isCoveredByPolicy();
    }
    getSize() {
        return '';
    }
    getVpcId() {
        return '';
    }
    isCoveredByPausedPolicies() {
        return this.coveredByPausedPolicies.length > 0;
    }
    getVolumeType() {
        return '';
    }
    getCloudProviderAssetId() {
        return this.getCloudConnectorAssetId();
    }
}
exports.EfsModel = EfsModel;
function createMockEfsAttributes(currentId) {
    const region = ['us-east-1', 'us-east-2', 'eu-central-1', 'ap-southeast-1'][Math.floor((Math.random() * 10) % 4)] || 'us-east-1';
    const account = (Math.random() + 1).toString(10).substring(6);
    const efsName = 'efs-' + currentId.toString();
    const policy = 'Policy' + Math.floor(Math.random() * 9).toString();
    const policyPaused = 'PolicyPaused' + Math.floor(Math.random() * 9).toString();
    const retentionPolicy = 'RetentionPolicy' + Math.floor(Math.random() * 9).toString();
    return {
        id: 'efs-' + currentId.toString(),
        accountId: (Math.random() + 1).toString(10).substring(6),
        region: region,
        cloudConnectorId: (Math.random() + 1).toString(10).substring(6),
        cloudConnectorAssetId: `elastio:asset:aws-efs:s:${account}:${region}:${efsName}`,
        name: efsName,
        tags: new Map(),
        createdAt: Math.floor(Math.random() * 100000),
        updatedAt: Math.floor(Math.random() * 100000),
        threatStatus: null,
        coveredByPolicies: [policy],
        coveredByPausedPolicies: [policyPaused],
        associatedRetentionPolicies: [retentionPolicy],
    };
}
exports.createMockEfsAttributes = createMockEfsAttributes;
