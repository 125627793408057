import {
  ScheduledCcJob,
  ScheduledCcJobAttrs,
  ScheduledCcJobKind,
} from '@features/scheduled-jobs-monitoring/models'
import Dexie from 'dexie'
import {
  AbstractScheduledJobsRepository,
  SCHEDULED_CC_JOBS,
} from './abstract-scheduled-jobs'

export type ModelId = string

export class ScheduledCcJobsRepository extends AbstractScheduledJobsRepository<
  ScheduledCcJobAttrs,
  ScheduledCcJob,
  ModelId
> {
  async listByKinds(
    kinds: Array<ScheduledCcJobKind>
  ): Promise<Array<ScheduledCcJob>> {
    const result = await this.table.where('kind').anyOf(kinds).toArray()
    return result.map((ccJob) => this.instantiateModel(ccJob))
  }

  protected instantiateModel(attr: ScheduledCcJobAttrs): ScheduledCcJob {
    return new ScheduledCcJob(attr)
  }

  protected get table(): Dexie.Table<ScheduledCcJobAttrs> {
    return this.db.table(this.tableName)
  }

  private get tableName(): string {
    return SCHEDULED_CC_JOBS
  }
}
