export const SET_ROLE_FOR_EDIT = 'SET_ROLE_FOR_EDIT'
export const UPDATE_ROLE_FOR_EDIT_SCOPES = 'UPDATE_ROLE_FOR_EDIT_SCOPES'
export const SET_ROLE_FOR_CREATE = 'SET_ROLE_FOR_CREATE'
export const SUBMIT_ROLE_FOR_EDIT = 'SUBMIT_ROLE_FOR_EDIT'
export const CANCEL_ROLE_FOR_EDIT = 'CANCEL_ROLE_FOR_EDIT'
export const DELETE_ROLE = 'DELETE_ROLE'

export const setRoleForEdit = (roleName: string, index: number) => ({
  type: SET_ROLE_FOR_EDIT,
  payload: {
    roleName,
    index,
  },
})

export const updateRoleForEditScopes = (scope: string, value: boolean) => ({
  type: UPDATE_ROLE_FOR_EDIT_SCOPES,
  payload: {
    scope,
    value,
  },
})

export const setRoleForCreate = () => ({
  type: SET_ROLE_FOR_CREATE,
  payload: null,
})

export const submitRoleForEdit = (roleName: string) => ({
  type: SUBMIT_ROLE_FOR_EDIT,
  payload: roleName,
})

export const cancelRoleForEdit = () => ({
  type: CANCEL_ROLE_FOR_EDIT,
  payload: null,
})

export const deleteRole = () => ({
  type: DELETE_ROLE,
  payload: null,
})
