/* eslint-disable import/no-extraneous-dependencies */
import { CloudConnectorStatus } from '@lib/constants/cloud-connector-status.constant'
import { AssetKind } from '@lib/constants/grpc/asset-kind.constant'
import { TableTabsConstant } from '@lib/constants/policies.constant'
import PaginationFactory from '@lib/factories/pagination.factory'
import TableFactory from '@lib/factories/table.factory'
import DataHelper from '@lib/helpers/data.helper'
import objHelper from '@lib/helpers/obj.helper'
import ActionInterface from '@lib/interfaces/action.interface'
import BaseModel from '@lib/models/base-model/base.model'
import {
  ADD_EFS_SELECTED_ALL_PATHS,
  ADD_EFS_SELECTED_ASSET,
  ADD_EFS_SELECTED_SPECIFIC_PATHS,
  ADD_S3_SELECTED_ALL_PATHS,
  ADD_S3_SELECTED_ASSET,
  ADD_S3_SELECTED_SPECIFIC_PATHS,
  ADD_SELECTED_ASSET,
  REMOVE_EFS_SELECTED_ALL_PATHS,
  REMOVE_EFS_SELECTED_ASSET,
  REMOVE_S3_SELECTED_ALL_PATHS,
  REMOVE_S3_SELECTED_ASSET,
  REMOVE_SELECTED_ASSET,
  RESET_EFS_SELECTED_DATA,
  RESET_S3_SELECTED_DATA,
  SELECT_ALL_EBS_ASSETS,
  SELECT_ALL_EC2_ASSETS,
  SELECT_ALL_OTHER_ASSETS,
  SELECT_ALL_S3_ASSETS,
  SET_ALL_EFS_SELECTED_ASSETS,
  SET_ALL_S3_SELECTED_ASSETS,
  SET_ALL_SELECTED_ASSETS,
  SET_ASSETS_SELECT,
  SET_ASSETS_SELECT_ENABLED_TABS,
  SET_ASSETS_SELECT_FILTERS,
  SET_ASSETS_SELECT_PAGINATION,
  SET_ASSETS_SELECT_SEARCH,
  SET_ASSETS_SELECT_TAB_SELECTED_NUM,
  SET_ASSET_IDS_MISSING_IN_LIVE_ASSETS,
  SET_ASSET_LOADING_MARKER,
  SET_EDIT_MAIN_TAB_ASSETS,
  SET_EFS_ASSETS_SELECT_FILTERS,
  SET_EFS_ASSETS_SELECT_PAGINATION,
  SET_EFS_ASSETS_SELECT_SEARCH,
  SET_MISSING_SELECTED_ASSETS,
  SET_S3_ASSETS_SELECT_FILTERS,
  SET_S3_ASSETS_SELECT_PAGINATION,
  SET_S3_ASSETS_SELECT_SEARCH,
} from '@store/actions/assets-select.action'
import AssetsSelectState from '@store/states/assets-select.state'
import {
  SelectedS3AssetComplex,
  SelectSpecificPathsInterface,
} from '@lib/interfaces/assets-s3-select.interface'
import FilterFactory from '@lib/factories/filter.factory'
import { FilterId } from '@lib/constants/grpc/filter.constant'
import FilterHelper from '@store/reducers/utilits'
import { SelectedEfsAssetComplex } from '@lib/interfaces/assets-efs-select.interface'
import { ListAssetsForPolicyCoverage as ListAssetsForPolicies } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage'
import { EBS, EC2 } from '@lib/models/assets'
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import {
  GetAssetFiltersForPolicyCoverage,
  GetAssetFiltersForPolicyCoverageAttrs,
} from 'blues-corejs/dist/use_cases/get_asset_filters_for_policy_coverage'
import { VIRow } from '@lib/engine-types'

export const defaultSelectedFilters = () => [
  {
    name: 'cloudConnectorStatuses',
    label: 'Cloud Connector Statuses',
    options: [
      {
        type: 1,
        name: String(CloudConnectorStatus.CONNECTED),
        label: 'Connected',
        value: true,
        disabled: true,
      },
    ],
  },
]

export const selectedFiltersForAddPolicies = (): VIRow => [
  {
    name: 'cloudConnectorStatuses',
    label: 'Cloud Connector Statuses',
    options: [
      {
        type: 1,
        name: String(CloudConnectorStatus.CONNECTED),
        label: 'Connected',
        value: true,
        disabled: true,
      },
    ],
  },
  {
    name: 'isProtected',
    label: 'Protection',
    options: [
      {
        type: 1,
        name: 'false',
        label: 'Unprotected',
        value: true,
        disabled: false,
      },
    ],
  },
]

export const selectedFiltersForAddPoliciesWithProtected = () => [
  {
    name: 'cloudConnectorStatuses',
    label: 'Cloud Connector Statuses',
    options: [
      {
        type: 1,
        name: String(CloudConnectorStatus.CONNECTED),
        label: 'Connected',
        value: true,
        disabled: true,
      },
    ],
  },
  {
    name: 'isProtected',
    label: 'Protection',
    options: [
      {
        type: 1,
        name: 'true',
        label: 'Protected',
        value: true,
        disabled: true,
      },
      {
        type: 1,
        name: 'false',
        label: 'Unprotected',
        value: false,
        disabled: true,
      },
    ],
  },
]

export const selectedFiltersForImportAwsSnapshots = () => [
  {
    name: 'cloudConnectorStatuses',
    label: 'Cloud Connector Statuses',
    options: [
      {
        type: 1,
        name: String(CloudConnectorStatus.CONNECTED),
        label: 'Connected',
        value: true,
        disabled: true,
      },
    ],
  },
]

export const defaultSelectedFiltersForS3Buckets = () => [
  {
    name: 'cloudConnectorStatuses',
    label: 'Cloud Connector Statuses',
    options: [
      {
        type: 1,
        name: String(CloudConnectorStatus.CONNECTED),
        label: 'Connected',
        value: true,
        disabled: true,
      },
    ],
  },
]

export const preSelectedFiltersForAddPolicies = () => [
  {
    name: 'cloudConnectorStatuses',
    label: 'Cloud Connector Statuses',
    options: [
      {
        type: 1,
        name: String(CloudConnectorStatus.CONNECTED),
        label: 'Connected',
        value: true,
        disabled: true,
      },
    ],
  },
  {
    name: 'isProtected',
    label: 'Protection',
    options: [
      {
        type: 1,
        name: 'true',
        label: 'Protected',
        value: true,
        disabled: false,
      },
      {
        type: 1,
        name: 'false',
        label: 'Unprotected',
        value: false,
        disabled: true,
      },
    ],
  },
]

export enum AssetLoadingMarker {
  INITIAL = 0,
  LOADED = 1,
  LOADING = 2,
}

const initialState: AssetsSelectState = {
  inventory: null,
  assets: [],
  allFilters: [],
  filters: defaultSelectedFilters(),
  pagination: PaginationFactory.buildEmpty(),
  search: '',
  selectedAssets: [],
  tableTabsDataInit: {},
  tableTabsData: {},
  missingSelectedAssets: [],
  tabsEnabled: [],
  tabSelectedNum: 0,
  assetsMarker: 0,
  tabAssetTypeSelectedNum: 0,
  s3Assets: [],
  s3AllFilters: [],
  s3Filters: defaultSelectedFiltersForS3Buckets(),
  s3Pagination: PaginationFactory.buildEmpty(),
  s3Search: '',
  s3SelectedAssets: [],
  tableS3DataInit: [],
  tableS3Data: [],
  s3SelectedAllPaths: [],
  s3SelectedSpecificPaths: [],
  isUnprotectedAssets: false,
  efsAssets: [],
  efsAllFilters: [],
  efsFilters: defaultSelectedFiltersForS3Buckets(),
  efsPagination: PaginationFactory.buildEmpty(),
  efsSearch: '',
  efsSelectedAssets: [],
  tableEfsDataInit: [],
  tableEfsData: [],
  efsSelectedAllPaths: [],
  efsSelectedSpecificPaths: [],
  assetIdsMissingInLiveAssets: [],
  assetsLoadingMarker: AssetLoadingMarker.INITIAL,
}

function assetsSelectReducer(
  prevState: AssetsSelectState = initialState,
  action: ActionInterface
): AssetsSelectState {
  switch (action.type) {
    case SET_ASSETS_SELECT:
      const assetsList = new ListAssetsForPolicies(action.payload).execute(
        FilterHelper.getAssetsDataForPolicyCoverageFilter(
          prevState.filters,
          prevState.search
        )
      )

      const assetsListWithS3Filters = new ListAssetsForPolicies(
        action.payload
      ).execute(
        FilterHelper.getAssetsDataForPolicyCoverageFilter(
          prevState.s3Filters,
          prevState.search
        )
      )

      const assetsListWithEfsFilters = new ListAssetsForPolicies(
        action.payload
      ).execute(
        FilterHelper.getAssetsDataForPolicyCoverageFilter(
          prevState.efsFilters,
          prevState.search
        )
      )

      const ebsTotalCount = assetsList.filter(
        (item: AssetWithRelatedAssets<Asset>) => item.type === AssetKind.AWS_EBS
      ).length

      const ec2TotalCount = assetsList.filter(
        (item: AssetWithRelatedAssets<Asset>) => item.type === AssetKind.AWS_EC2
      ).length

      const s3TotalCount = assetsListWithS3Filters.filter(
        (item: AssetWithRelatedAssets<Asset>) => item.type === AssetKind.AWS_S3
      ).length

      const genericTotalCount = assetsList.filter(
        (item: AssetWithRelatedAssets<Asset>) =>
          item.type === AssetKind.GENERIC_FS ||
          item.type === AssetKind.GENERIC_HOST
      ).length

      const efsTotalCount = assetsListWithEfsFilters.filter(
        (item: AssetWithRelatedAssets<Asset>) => item.type === AssetKind.AWS_EFS
      ).length

      let paginationTotalCount =
        prevState.tabSelectedNum === 1 ? ec2TotalCount : ebsTotalCount
      // set current not disabled tab pagination
      if (prevState.tabsEnabled.length > 0) {
        if (prevState.tabsEnabled.includes(TableTabsConstant.EBS)) {
          paginationTotalCount = ebsTotalCount
        } else if (prevState.tabsEnabled.includes(TableTabsConstant.EC2)) {
          paginationTotalCount = ec2TotalCount
        } else {
          if (prevState.tabsEnabled.includes(TableTabsConstant.S3)) {
            paginationTotalCount = s3TotalCount
          } else if (prevState.tabsEnabled.includes(TableTabsConstant.EFS)) {
            paginationTotalCount = efsTotalCount
          } else {
            paginationTotalCount = genericTotalCount
          }
        }
      }

      const pagination = BaseModel.clone(prevState.pagination, {
        total: paginationTotalCount,
      })

      const tableTabsDataInit =
        TableFactory.assetsVIWithSortingByTypeAndSelected(
          assetsList,
          prevState.selectedAssets,
          prevState.assetIdsMissingInLiveAssets,
          action.payload.lastBackups
        )

      const filterAttrs: GetAssetFiltersForPolicyCoverageAttrs = {
        instances: action.payload.instances,
        volumes: action.payload.volumes,
        s3Buckets: action.payload.s3Buckets,
        efs: action.payload.efs,
      }

      const filtersAssets = new GetAssetFiltersForPolicyCoverage(
        filterAttrs
      ).execute()

      const dataForPossibleFilters = [
        {
          id: FilterId.ACCOUNT_IDS,
          accountIds: filtersAssets?.accountIds,
        },
        {
          id: FilterId.INSTANCE_IDS,
          instanceIds: filtersAssets?.instanceIds,
        },
        {
          id: FilterId.INSTANCE_TYPES,
          instanceTypes: filtersAssets?.instanceTypes,
        },
        {
          id: FilterId.VOLUME_IDS,
          volumeIds: filtersAssets?.volumeIds,
        },
        {
          id: FilterId.VOLUME_TYPES,
          volumeTypes: filtersAssets?.volumeTypes,
        },
        {
          id: FilterId.REGIONS,
          regions: filtersAssets?.regions,
        },
        {
          id: FilterId.VPC_IDS,
          vpcIds: filtersAssets?.vpcIds,
        },
        {
          id: FilterId.ASSET_TAGS,
          tags: filtersAssets?.tags,
        },
        {
          id: FilterId.PROTECTED,
          isProtected: filtersAssets?.isProtected,
        },
        {
          id: FilterId.PAUSED_INSTANCES,
          isPausedInstances: filtersAssets?.isPausedInstances,
        },
      ]

      const dataForPossibleS3Filters = [
        {
          id: FilterId.ACCOUNT_IDS,
          accountIds: filtersAssets?.accountIds,
        },
        {
          id: FilterId.REGIONS,
          regions: filtersAssets?.regions,
        },
        {
          id: FilterId.ASSET_TAGS,
          tags: filtersAssets?.tags,
        },
        {
          id: FilterId.PROTECTED,
          isProtected: filtersAssets?.isProtected,
        },
      ]

      const dataForPossibleEfsFilters = [
        {
          id: FilterId.ACCOUNT_IDS,
          accountIds: filtersAssets?.accountIds,
        },
        {
          id: FilterId.REGIONS,
          regions: filtersAssets?.regions,
        },
        {
          id: FilterId.ASSET_TAGS,
          tags: filtersAssets?.tags,
        },
        {
          id: FilterId.PROTECTED,
          isProtected: filtersAssets?.isProtected,
        },
      ]

      const unprotectedAssets = assetsList?.filter(
        (item) => !item.asset?.backupPolicies.length
      )

      // s3
      const s3Buckets = assetsListWithS3Filters.filter(
        (item) => item.type === AssetKind.AWS_S3
      )
      const paginationS3TotalCount = s3Buckets.length

      const paginationS3 = BaseModel.clone(prevState.s3Pagination, {
        total: paginationS3TotalCount,
      })

      const tableS3DataInit = TableFactory.assetsVIS3WithSelected(
        s3Buckets,
        prevState.s3SelectedAssets,
        prevState.s3SelectedAllPaths,
        prevState.s3SelectedSpecificPaths
      )

      // EFS
      const efs = assetsListWithEfsFilters.filter(
        (item) => item.type === AssetKind.AWS_EFS
      )
      const paginationEfsTotalCount = efs.length

      const paginationEfs = BaseModel.clone(prevState.efsPagination, {
        total: paginationEfsTotalCount,
      })

      const tableEfsDataInit = TableFactory.assetsVIS3WithSelected(
        efs,
        prevState.efsSelectedAssets,
        prevState.efsSelectedAllPaths,
        prevState.efsSelectedSpecificPaths
      )

      return {
        ...prevState,
        pagination,
        inventory: action.payload,
        assets: assetsList,
        tableTabsData: DataHelper.getPaginatedDataForSortedTableData(
          pagination,
          tableTabsDataInit
        ),
        isUnprotectedAssets: unprotectedAssets.length > 0,
        assetsMarker: prevState.inventory ? 1 : 2,
        allFilters: dataForPossibleFilters.map(FilterFactory.buildAssetsFilter),
        filters: prevState.filters,
        s3Pagination: paginationS3,
        s3Assets: s3Buckets,
        tableS3DataInit: tableS3DataInit,
        tableS3Data: DataHelper.getPaginatedDataForS3TableData(
          paginationS3,
          tableS3DataInit
        ),
        s3AllFilters: dataForPossibleS3Filters.map(
          FilterFactory.buildS3AssetsSelectedFromGrpc
        ),
        s3Filters: prevState.s3Filters,

        efsPagination: paginationEfs,
        efsAssets: efs,
        tableEfsDataInit: tableEfsDataInit,
        tableEfsData: DataHelper.getPaginatedDataForS3TableData(
          paginationEfs,
          tableEfsDataInit
        ),
        efsAllFilters: dataForPossibleEfsFilters.map(
          FilterFactory.buildEfsAssetsSelectedFromGrpc
        ),
        efsFilters: prevState.efsFilters,
        assetsLoadingMarker: AssetLoadingMarker.LOADED,
      }

    case SET_ASSETS_SELECT_FILTERS:
      const dataForNewTable: Record<string, any> =
        FilterHelper.getAssetsDataForPolicyCoverageFilter(
          action.payload,
          prevState.search
        )

      let assetsListFiltered: Array<AssetWithRelatedAssets<Asset>> = []
      if (prevState.inventory) {
        assetsListFiltered = new ListAssetsForPolicies(
          prevState.inventory
        ).execute(dataForNewTable ?? [])
      }

      const tableTabsDataInitFiltered =
        TableFactory.assetsVIWithSortingByTypeAndSelected(
          assetsListFiltered,
          prevState.selectedAssets,
          prevState.assetIdsMissingInLiveAssets,
          prevState.inventory?.lastBackups ?? null
        )

      const unprotectedAssetsFiltered = assetsListFiltered?.filter(
        (item) => !item.asset.backupPolicies.length
      )

      return {
        ...prevState,
        filters: action.payload,
        assets: assetsListFiltered,
        tableTabsDataInit: tableTabsDataInitFiltered,
        tableTabsData: DataHelper.getPaginatedDataForSortedTableData(
          prevState.pagination,
          tableTabsDataInitFiltered
        ),
        isUnprotectedAssets: unprotectedAssetsFiltered.length > 0,
        assetsMarker: prevState.inventory ? 1 : 2,
        pagination: PaginationFactory.cloneReset(prevState.pagination),
      }

    case SET_ASSETS_SELECT_PAGINATION:
      return {
        ...prevState,
        tableTabsData: DataHelper.getPaginatedDataForSortedTableData(
          action.payload,
          prevState.tableTabsDataInit
        ),
        pagination: action.payload,
      }

    case SET_ASSETS_SELECT_SEARCH:
      const dataSearchForNewTable: Record<string, any> =
        DataHelper.getDataForDashboardFilter(prevState.filters, action.payload)

      let assetsListNewSearch: Array<AssetWithRelatedAssets<Asset>> = []
      if (prevState.inventory) {
        assetsListNewSearch = new ListAssetsForPolicies(
          prevState.inventory
        ).execute(dataSearchForNewTable ?? [])
      }

      const tableTabsDataInitNewSearch =
        TableFactory.assetsVIWithSortingByTypeAndSelected(
          assetsListNewSearch,
          prevState.selectedAssets,
          prevState.assetIdsMissingInLiveAssets,
          prevState.inventory?.lastBackups ?? null
        )

      const unprotectedAssetsSearched = assetsListNewSearch?.filter(
        (item) => !item.asset.backupPolicies.length
      )

      let filtersInit = objHelper.cloneDeep(prevState.filters)

      if (action.payload !== '') {
        //remove Protected/Unprotected filters to search in full list
        filtersInit = DataHelper.getFiltersWithoutProtectedFilter(filtersInit)
      }

      return {
        ...prevState,
        search: action.payload,
        filters: filtersInit,
        assets: assetsListNewSearch,
        tableTabsDataInit: tableTabsDataInitNewSearch,
        tableTabsData: DataHelper.getPaginatedDataForSortedTableData(
          prevState.pagination,
          tableTabsDataInitNewSearch
        ),
        assetsMarker: prevState.inventory ? 1 : 2,
        isUnprotectedAssets: unprotectedAssetsSearched.length > 0,
        pagination: PaginationFactory.cloneReset(prevState.pagination),
      }

    case ADD_SELECTED_ASSET:
      const assetToAdd = action.payload
      const selectedAssetsForAdd = prevState.selectedAssets
        .filter((v) => {
          if (v.asset?.id === assetToAdd.asset?.id) {
            return false
          }
          if (
            v.relatedAssets?.find(
              (vv: EC2 | EBS) => vv?.id === assetToAdd.asset?.id
            )
          ) {
            return false
          }
          if (
            assetToAdd.relatedAssets?.find(
              (vv: EC2 | EBS) => vv?.id === v.asset?.id
            )
          ) {
            return false
          }
          return true
        })
        .concat([assetToAdd])

      const dataForInitTable =
        TableFactory.assetsVIWithSortingByTypeAndSelected(
          prevState.assets,
          selectedAssetsForAdd,
          prevState.assetIdsMissingInLiveAssets,
          prevState.inventory?.lastBackups ?? null
        )
      return {
        ...prevState,
        selectedAssets: selectedAssetsForAdd,
        tableTabsDataInit: dataForInitTable,
        tableTabsData: DataHelper.getPaginatedDataForSortedTableData(
          prevState.pagination,
          dataForInitTable
        ),
      }

    case REMOVE_SELECTED_ASSET:
      const selectedAssetsForRemove = prevState.selectedAssets.filter(
        (v) => v.asset?.id !== action.payload?.asset?.id
      )
      const dataForTable = TableFactory.assetsVIWithSortingByTypeAndSelected(
        prevState.assets,
        selectedAssetsForRemove,
        prevState.assetIdsMissingInLiveAssets,
        prevState.inventory?.lastBackups ?? null
      )
      return {
        ...prevState,
        selectedAssets: selectedAssetsForRemove,
        tableTabsDataInit: dataForTable,
        tableTabsData: DataHelper.getPaginatedDataForSortedTableData(
          prevState.pagination,
          dataForTable
        ),
      }

    case SET_ALL_SELECTED_ASSETS:
      const data = TableFactory.assetsVIWithSortingByTypeAndSelected(
        prevState.assets,
        action.payload,
        prevState.assetIdsMissingInLiveAssets,
        prevState.inventory?.lastBackups ?? null
      )
      return {
        ...prevState,
        selectedAssets: action.payload,
        tableTabsDataInit: data,
        tableTabsData: DataHelper.getPaginatedDataForSortedTableData(
          prevState.pagination,
          data
        ),
      }

    case SELECT_ALL_EBS_ASSETS:
      const headEBSCheckboxValue = action.payload
      let newSelectedAssets: Array<AssetWithRelatedAssets<Asset>>
      let otherSelectedAssets3: Array<AssetWithRelatedAssets<Asset>>
      const isSelectedEC2 = Boolean(
        prevState.selectedAssets.find(
          (asset) => asset.type === AssetKind.AWS_EC2
        )
      )
      const isSelectedS3WithEBS = Boolean(
        prevState.selectedAssets.find(
          (asset) => asset.type === AssetKind.AWS_S3
        )
      )
      if (headEBSCheckboxValue) {
        newSelectedAssets = prevState.selectedAssets.filter(
          (assetMod: AssetWithRelatedAssets<Asset>) =>
            assetMod.type !== AssetKind.AWS_EBS
        )
      } else {
        if (isSelectedEC2 || isSelectedS3WithEBS) {
          const newSelectedEBSAssetsWithoutRelated = prevState.assets.filter(
            (assetMod: AssetWithRelatedAssets<Asset>) =>
              assetMod.type === AssetKind.AWS_EBS &&
              assetMod?.relatedAssets?.length === 0
          )
          newSelectedAssets = prevState.selectedAssets.concat(
            newSelectedEBSAssetsWithoutRelated
          )
        } else {
          newSelectedAssets = prevState.assets.filter(
            (assetMod: AssetWithRelatedAssets<Asset>) =>
              assetMod.type === AssetKind.AWS_EBS
          )
        }
        otherSelectedAssets3 = prevState.selectedAssets.filter(
          (assetMod: AssetWithRelatedAssets<Asset>) =>
            assetMod.type === AssetKind.GENERIC_HOST
        )
        newSelectedAssets = newSelectedAssets.concat(otherSelectedAssets3)
      }

      const tableTabsDataInitDataNew =
        TableFactory.assetsVIWithSortingByTypeAndSelected(
          prevState.assets,
          newSelectedAssets,
          prevState.assetIdsMissingInLiveAssets,
          prevState.inventory?.lastBackups ?? null
        )
      return {
        ...prevState,
        selectedAssets: newSelectedAssets,
        tableTabsDataInit: tableTabsDataInitDataNew,
        tableTabsData: DataHelper.getPaginatedDataForSortedTableData(
          prevState.pagination,
          tableTabsDataInitDataNew
        ),
      }

    case SELECT_ALL_EC2_ASSETS:
      const headEC2CheckboxValue = action.payload
      let newCurSelectedAssets: Array<AssetWithRelatedAssets<Asset>>
      let otherSelectedAssets2: Array<AssetWithRelatedAssets<Asset>>
      const isSelectedEBS = Boolean(
        prevState.selectedAssets.find(
          (asset) => asset.type === AssetKind.AWS_EBS
        )
      )
      const isSelectedS3WithEc2 = Boolean(
        prevState.selectedAssets.find(
          (asset) => asset.type === AssetKind.AWS_S3
        )
      )

      if (headEC2CheckboxValue) {
        newCurSelectedAssets = prevState.selectedAssets.filter(
          (assetMod: AssetWithRelatedAssets<Asset>) =>
            assetMod.type !== AssetKind.AWS_EC2
        )
      } else {
        if (isSelectedEBS || isSelectedS3WithEc2) {
          const newSelectedEC2AssetsWithoutRelated = prevState.assets.filter(
            (assetMod: AssetWithRelatedAssets<Asset>) =>
              assetMod.type === AssetKind.AWS_EC2 &&
              assetMod?.relatedAssets?.length === 0
          )
          newCurSelectedAssets = prevState.selectedAssets.concat(
            newSelectedEC2AssetsWithoutRelated
          )
        } else {
          newCurSelectedAssets = prevState.assets.filter(
            (assetMod: AssetWithRelatedAssets<Asset>) =>
              assetMod.type === AssetKind.AWS_EC2
          )
        }
        otherSelectedAssets2 = prevState.selectedAssets.filter(
          (assetMod: AssetWithRelatedAssets<Asset>) =>
            assetMod.type === AssetKind.GENERIC_HOST
        )
        newCurSelectedAssets = newCurSelectedAssets.concat(otherSelectedAssets2)
      }

      const tableTabsDataInitData =
        TableFactory.assetsVIWithSortingByTypeAndSelected(
          prevState.assets,
          newCurSelectedAssets,
          prevState.assetIdsMissingInLiveAssets,
          prevState.inventory?.lastBackups ?? null
        )
      return {
        ...prevState,
        selectedAssets: newCurSelectedAssets,
        tableTabsDataInit: tableTabsDataInitData,
        tableTabsData: DataHelper.getPaginatedDataForSortedTableData(
          prevState.pagination,
          tableTabsDataInitData
        ),
      }

    case SELECT_ALL_S3_ASSETS:
      const headS3CheckboxValue = action.payload
      let newS3SelectedAssets: Array<AssetWithRelatedAssets<Asset>>
      let otherSelectedAssetsForS3: Array<AssetWithRelatedAssets<Asset>>
      const isSelectedEBSWithS3 = Boolean(
        prevState.selectedAssets.find(
          (asset) => asset.type === AssetKind.AWS_EBS
        )
      )
      const isSelectedEC2WithS3 = Boolean(
        prevState.selectedAssets.find(
          (asset) => asset.type === AssetKind.AWS_EC2
        )
      )

      if (headS3CheckboxValue) {
        newS3SelectedAssets = prevState.selectedAssets.filter(
          (asset: AssetWithRelatedAssets<Asset>) =>
            asset.type !== AssetKind.AWS_S3
        )
      } else {
        if (isSelectedEBSWithS3 || isSelectedEC2WithS3) {
          const newSelectedS3AssetsWithoutRelated = prevState.assets.filter(
            (asset: AssetWithRelatedAssets<Asset>) =>
              asset.type === AssetKind.AWS_S3 &&
              asset?.relatedAssets?.length === 0
          )
          newS3SelectedAssets = prevState.selectedAssets.concat(
            newSelectedS3AssetsWithoutRelated
          )
        } else {
          newS3SelectedAssets = prevState.assets.filter(
            (asset: AssetWithRelatedAssets<Asset>) =>
              asset.type === AssetKind.AWS_S3
          )
        }
        otherSelectedAssetsForS3 = prevState.selectedAssets.filter(
          (asset: AssetWithRelatedAssets<Asset>) =>
            asset.type === AssetKind.GENERIC_HOST
        )
        newS3SelectedAssets = newS3SelectedAssets.concat(
          otherSelectedAssetsForS3
        )
      }

      const tableS3TabsDataInitData =
        TableFactory.assetsVIWithSortingByTypeAndSelected(
          prevState.assets,
          newS3SelectedAssets,
          prevState.assetIdsMissingInLiveAssets,
          prevState.inventory?.lastBackups ?? null
        )
      return {
        ...prevState,
        selectedAssets: newS3SelectedAssets,
        tableTabsDataInit: tableS3TabsDataInitData,
        tableTabsData: DataHelper.getPaginatedDataForSortedTableData(
          prevState.pagination,
          tableS3TabsDataInitData
        ),
      }

    case SELECT_ALL_OTHER_ASSETS:
      const headOtherCheckboxValue = action.payload
      let newOtherSelectedAssets: Array<AssetWithRelatedAssets<Asset>>
      let otherSelectedAssets: Array<AssetWithRelatedAssets<Asset>>

      if (headOtherCheckboxValue) {
        newOtherSelectedAssets = prevState.selectedAssets.filter(
          (asset: AssetWithRelatedAssets<Asset>) =>
            asset.type !== AssetKind.GENERIC_HOST
        )
      } else {
        otherSelectedAssets = prevState.assets.filter(
          (asset) => asset.type === AssetKind.GENERIC_HOST
        )

        newOtherSelectedAssets =
          prevState.selectedAssets.concat(otherSelectedAssets)
      }

      const tableTabsDataInitOthers =
        TableFactory.assetsVIWithSortingByTypeAndSelected(
          prevState.assets,
          newOtherSelectedAssets,
          prevState.assetIdsMissingInLiveAssets,
          prevState.inventory?.lastBackups ?? null
        )
      return {
        ...prevState,
        selectedAssets: newOtherSelectedAssets,
        tableTabsDataInit: tableTabsDataInitOthers,
        tableTabsData: DataHelper.getPaginatedDataForSortedTableData(
          prevState.pagination,
          tableTabsDataInitOthers
        ),
      }

    // retention policy
    case SET_MISSING_SELECTED_ASSETS:
      return {
        ...prevState,
        missingSelectedAssets: action.payload,
      }

    // retention policy
    case SET_ASSETS_SELECT_ENABLED_TABS:
      return {
        ...prevState,
        tabsEnabled: action.payload,
      }

    case SET_ASSETS_SELECT_TAB_SELECTED_NUM:
      return {
        ...prevState,
        tabSelectedNum: action.payload,
      }

    case SET_EDIT_MAIN_TAB_ASSETS:
      return {
        ...prevState,
        tabAssetTypeSelectedNum: action.payload,
      }

    case SET_S3_ASSETS_SELECT_PAGINATION:
      return {
        ...prevState,
        tableS3Data: DataHelper.getPaginatedDataForS3TableData(
          action.payload,
          prevState.tableS3DataInit
        ),
        s3Pagination: action.payload,
      }

    case SET_S3_ASSETS_SELECT_FILTERS:
      const dataS3ForNewTable: Record<string, any> =
        FilterHelper.getAssetsDataForPolicyCoverageFilter(
          action.payload,
          prevState.search
        )

      let assetsListForS3: Array<AssetWithRelatedAssets<Asset>> = []
      if (prevState.inventory) {
        assetsListForS3 = new ListAssetsForPolicies(
          prevState.inventory
        ).execute(dataS3ForNewTable ?? [])
      }

      const s3ListFiltered = assetsListForS3.filter(
        (item) => item.type === AssetKind.AWS_S3
      )

      const paginationS3TotalCountFiltered = s3ListFiltered.length

      const paginationS3TotalFiltered = BaseModel.clone(
        prevState.s3Pagination,
        {
          total: paginationS3TotalCountFiltered,
        }
      )

      const tableDataInitFiltered = TableFactory.assetsVIS3WithSelected(
        s3ListFiltered,
        prevState.s3SelectedAssets,
        prevState.s3SelectedAllPaths,
        prevState.s3SelectedSpecificPaths
      )

      return {
        ...prevState,
        s3Filters: action.payload,
        s3Assets: s3ListFiltered,
        tableS3DataInit: tableDataInitFiltered,
        tableS3Data: DataHelper.getPaginatedDataForS3TableData(
          PaginationFactory.cloneReset(prevState.s3Pagination),
          tableDataInitFiltered
        ),
        s3Pagination: PaginationFactory.cloneReset(paginationS3TotalFiltered),
      }

    case SET_S3_ASSETS_SELECT_SEARCH:
      const dataSearchS3ForNewTable: Record<string, any> =
        DataHelper.getDataForDashboardFilter(
          prevState.s3Filters,
          action.payload
        )

      let assetListSearched: Array<AssetWithRelatedAssets<Asset>> = []
      if (prevState.inventory) {
        assetListSearched = new ListAssetsForPolicies(
          prevState.inventory
        ).execute(dataSearchS3ForNewTable ?? [])
      }

      const s3ListSearched = assetListSearched.filter(
        (item) => item.type === AssetKind.AWS_S3
      )

      const paginationS3TotalCountSearch = s3ListSearched.length

      const paginationS3TotalSearch = BaseModel.clone(prevState.s3Pagination, {
        total: paginationS3TotalCountSearch,
      })

      const tableDataS3InitFiltered = TableFactory.assetsVIS3WithSelected(
        s3ListSearched,
        prevState.s3SelectedAssets,
        prevState.s3SelectedAllPaths,
        prevState.s3SelectedSpecificPaths
      )

      let filtersS3Init = objHelper.cloneDeep(prevState.s3Filters)

      if (action.payload !== '') {
        //remove Protected/Unprotected filters to search in full list
        filtersS3Init =
          DataHelper.getFiltersWithoutProtectedFilter(filtersS3Init)
      }

      return {
        ...prevState,
        s3Search: action.payload,
        s3Filters: filtersS3Init,
        s3Assets: s3ListSearched,
        tableS3DataInit: tableDataS3InitFiltered,
        tableS3Data: DataHelper.getPaginatedDataForS3TableData(
          PaginationFactory.cloneReset(prevState.s3Pagination),
          tableDataS3InitFiltered
        ),
        s3Pagination: PaginationFactory.cloneReset(paginationS3TotalSearch),
      }

    case ADD_S3_SELECTED_ASSET:
      const assetS3ToAdd = action.payload
      const selectedAssetsS3ForAdd = prevState.s3SelectedAssets
        .filter((item) => {
          if (item.asset?.id === assetS3ToAdd.asset?.id) {
            return false
          }
          return true
        })
        .concat([assetS3ToAdd])

      const dataForS3InitTable = TableFactory.assetsVIS3WithSelected(
        prevState.s3Assets,
        selectedAssetsS3ForAdd,
        prevState.s3SelectedAllPaths,
        prevState.s3SelectedSpecificPaths
      )
      return {
        ...prevState,
        s3SelectedAssets: selectedAssetsS3ForAdd,
        tableS3DataInit: dataForS3InitTable,
        tableS3Data: DataHelper.getPaginatedDataForS3TableData(
          prevState.s3Pagination,
          dataForS3InitTable
        ),
      }

    case REMOVE_S3_SELECTED_ASSET:
      const assetS3ToRemove = action.payload
      const selectedS3AssetsForRemove = prevState.s3SelectedAssets.filter(
        (item) => item.asset?.id !== assetS3ToRemove.asset.id
      )
      const selectedAssetsS3IdForRemove = prevState.s3SelectedAllPaths.filter(
        (v) => v !== action.payload.id
      )
      const selectedSpecificPathsForRemove =
        prevState.s3SelectedSpecificPaths.filter(
          (item) => item.asset.asset?.id !== assetS3ToRemove.asset.id
        )

      const dataS3ForTable = TableFactory.assetsVIS3WithSelected(
        prevState.s3Assets,
        selectedS3AssetsForRemove,
        selectedAssetsS3IdForRemove,
        selectedSpecificPathsForRemove
      )
      return {
        ...prevState,
        s3SelectedAssets: selectedS3AssetsForRemove,
        s3SelectedAllPaths: selectedAssetsS3IdForRemove,
        s3SelectedSpecificPaths: selectedSpecificPathsForRemove,
        tableS3DataInit: dataS3ForTable,
        tableS3Data: DataHelper.getPaginatedDataForS3TableData(
          prevState.s3Pagination,
          dataS3ForTable
        ),
      }

    case ADD_S3_SELECTED_ALL_PATHS:
      const assetS3IdToAdd = action.payload.asset?.id
      const selectedAssetToAdd = action.payload
      const selectedAssetsS3IdForAdd = prevState.s3SelectedAllPaths
        .filter((v) => {
          if (v === assetS3IdToAdd) {
            return false
          }
          return true
        })
        .concat([assetS3IdToAdd])

      const selectedAssetsToAdd = prevState.s3SelectedAssets
        .filter((item) => {
          if (item.asset?.id === assetS3IdToAdd) {
            return false
          }
          return true
        })
        .concat([selectedAssetToAdd])

      const selectedSpecificPathsWithoutThis =
        prevState.s3SelectedSpecificPaths.filter(
          (v) => v.asset.asset?.id !== assetS3IdToAdd
        )

      const dataS3InitTable = TableFactory.assetsVIS3WithSelected(
        prevState.s3Assets,
        selectedAssetsToAdd,
        selectedAssetsS3IdForAdd,
        selectedSpecificPathsWithoutThis
      )
      return {
        ...prevState,
        s3SelectedAllPaths: selectedAssetsS3IdForAdd,
        s3SelectedAssets: selectedAssetsToAdd,
        s3SelectedSpecificPaths: selectedSpecificPathsWithoutThis,
        tableS3DataInit: dataS3InitTable,
        tableS3Data: DataHelper.getPaginatedDataForS3TableData(
          prevState.s3Pagination,
          dataS3InitTable
        ),
      }

    case REMOVE_S3_SELECTED_ALL_PATHS:
      const assetS3IdForRemove = prevState.s3SelectedAllPaths.filter(
        (v) => v !== action.payload.asset?.id
      )
      const dataS3SelectedForTable = TableFactory.assetsVIS3WithSelected(
        prevState.s3Assets,
        prevState.s3SelectedAssets,
        assetS3IdForRemove,
        prevState.s3SelectedSpecificPaths
      )
      return {
        ...prevState,
        s3SelectedAllPaths: assetS3IdForRemove,
        tableS3DataInit: dataS3SelectedForTable,
        tableS3Data: DataHelper.getPaginatedDataForS3TableData(
          prevState.s3Pagination,
          dataS3SelectedForTable
        ),
      }

    case ADD_S3_SELECTED_SPECIFIC_PATHS:
      const assetS3Id = action.payload.asset.asset.id
      const selectAssetsToAdd = action.payload.asset
      const selectedPathsToAdd = action.payload
      const selectedAllSpecificPathsWithoutCurrent =
        prevState.s3SelectedSpecificPaths.filter(
          (v) => v.asset.asset.id !== assetS3Id
        )

      const selectedAllSpecificPathsToAdd =
        selectedAllSpecificPathsWithoutCurrent.concat([selectedPathsToAdd])

      const selectAssetsS3ToAdd = prevState.s3SelectedAssets
        .filter((item) => {
          if (item.asset?.id === assetS3Id) {
            return false
          }
          return true
        })
        .concat([selectAssetsToAdd])

      const dataAllS3InitTable = TableFactory.assetsVIS3WithSelected(
        prevState.s3Assets,
        selectAssetsS3ToAdd,
        prevState.s3SelectedAllPaths,
        selectedAllSpecificPathsToAdd
      )
      return {
        ...prevState,
        s3SelectedAssets: selectAssetsS3ToAdd,
        s3SelectedSpecificPaths: selectedAllSpecificPathsToAdd,
        tableS3DataInit: dataAllS3InitTable,
        tableS3Data: DataHelper.getPaginatedDataForS3TableData(
          prevState.s3Pagination,
          dataAllS3InitTable
        ),
      }

    case SET_ALL_S3_SELECTED_ASSETS:
      const selectedAllPath: Array<string> = []
      const selectedSpecificPath: Array<SelectSpecificPathsInterface> = []

      action.payload?.allSelectedComplex?.map(
        (selectedS3AssetComplex: SelectedS3AssetComplex) => {
          // all path
          if (
            selectedS3AssetComplex?.selectedAllPath &&
            selectedS3AssetComplex?.assetModel
          ) {
            selectedAllPath.push(selectedS3AssetComplex?.assetModel?.asset?.id)
          } else {
            // specific path
            if (selectedS3AssetComplex?.assetModel !== undefined) {
              selectedSpecificPath.push({
                asset: selectedS3AssetComplex?.assetModel,
                selectedPaths:
                  selectedS3AssetComplex?.selectedSpecificPath as Record<
                    string,
                    Array<string>
                  >,
              })
            }
          }
        }
      )
      const allSelectedAssets = TableFactory.assetsVIS3WithSelected(
        prevState.s3Assets,
        action.payload.allSelected,
        selectedAllPath,
        selectedSpecificPath
      )

      return {
        ...prevState,
        s3SelectedAssets: action.payload?.allSelected ?? [],
        tableS3DataInit: allSelectedAssets,
        tableS3Data: DataHelper.getPaginatedDataForS3TableData(
          prevState.s3Pagination,
          allSelectedAssets
        ),
        s3SelectedAllPaths: selectedAllPath,
        s3SelectedSpecificPaths: selectedSpecificPath,
      }

    case RESET_S3_SELECTED_DATA:
      return {
        ...prevState,
        s3Filters: defaultSelectedFiltersForS3Buckets(),
        s3Pagination: PaginationFactory.buildEmpty(),
        s3Search: '',
        s3SelectedAssets: [],
        tableS3DataInit: [],
        tableS3Data: [],
        s3SelectedAllPaths: [],
        s3SelectedSpecificPaths: [],
      }

    //EFS
    case SET_EFS_ASSETS_SELECT_PAGINATION:
      return {
        ...prevState,
        tableEfsData: DataHelper.getPaginatedDataForS3TableData(
          action.payload,
          prevState.tableEfsDataInit
        ),
        efsPagination: action.payload,
      }

    case SET_EFS_ASSETS_SELECT_FILTERS:
      const dataEfsForNewTable: Record<string, any> =
        FilterHelper.getAssetsDataForPolicyCoverageFilter(
          action.payload,
          prevState.search
        )

      let assetsListForEfs: Array<AssetWithRelatedAssets<Asset>> = []
      if (prevState.inventory) {
        assetsListForEfs = new ListAssetsForPolicies(
          prevState.inventory
        ).execute(dataEfsForNewTable ?? [])
      }

      const efsListFiltered = assetsListForEfs.filter(
        (item) => item.type === AssetKind.AWS_EFS
      )
      const paginationEfsTotalCountFiltered = efsListFiltered.length

      const paginationEfsTotalFiltered = BaseModel.clone(
        prevState.efsPagination,
        {
          total: paginationEfsTotalCountFiltered,
        }
      )

      const tableDataEfsInitFiltered = TableFactory.assetsVIS3WithSelected(
        efsListFiltered,
        prevState.efsSelectedAssets,
        prevState.efsSelectedAllPaths,
        prevState.efsSelectedSpecificPaths
      )

      return {
        ...prevState,
        efsFilters: action.payload,
        efsAssets: efsListFiltered,
        tableEfsDataInit: tableDataEfsInitFiltered,
        tableEfsData: DataHelper.getPaginatedDataForS3TableData(
          PaginationFactory.cloneReset(prevState.efsPagination),
          tableDataEfsInitFiltered
        ),
        efsPagination: PaginationFactory.cloneReset(paginationEfsTotalFiltered),
      }

    case SET_EFS_ASSETS_SELECT_SEARCH:
      const dataSearchEfsForNewTable: Record<string, any> =
        DataHelper.getDataForDashboardFilter(
          prevState.efsFilters,
          action.payload
        )

      let assetListAllSearched: Array<AssetWithRelatedAssets<Asset>> = []
      if (prevState.inventory) {
        assetListAllSearched = new ListAssetsForPolicies(
          prevState.inventory
        ).execute(dataSearchEfsForNewTable ?? [])
      }

      const efsListSearched = assetListAllSearched.filter(
        (item) => item.type === AssetKind.AWS_EFS
      )

      const paginationEfsTotalCountSearch = efsListSearched.length

      const paginationEfsTotalSearch = BaseModel.clone(
        prevState.efsPagination,
        {
          total: paginationEfsTotalCountSearch,
        }
      )

      const tblDataEfsInitFiltered = TableFactory.assetsVIS3WithSelected(
        efsListSearched,
        prevState.efsSelectedAssets,
        prevState.efsSelectedAllPaths,
        prevState.efsSelectedSpecificPaths
      )

      let filtersEfsInit = objHelper.cloneDeep(prevState.efsFilters)

      if (action.payload !== '') {
        //remove Protected/Unprotected filters to search in full list
        filtersEfsInit =
          DataHelper.getFiltersWithoutProtectedFilter(filtersEfsInit)
      }

      return {
        ...prevState,
        efsSearch: action.payload,
        efsFilters: filtersEfsInit,
        efsAssets: efsListSearched,
        tableEfsDataInit: tblDataEfsInitFiltered,
        tableEfsData: DataHelper.getPaginatedDataForS3TableData(
          PaginationFactory.cloneReset(prevState.efsPagination),
          tblDataEfsInitFiltered
        ),
        efsPagination: PaginationFactory.cloneReset(paginationEfsTotalSearch),
      }

    case ADD_EFS_SELECTED_ASSET:
      const assetEfsToAdd = action.payload
      const selectedAssetsEfsForAdd = prevState.efsSelectedAssets
        .filter((item) => {
          if (item.asset?.id === assetEfsToAdd.asset?.id) {
            return false
          }
          return true
        })
        .concat([assetEfsToAdd])

      const dataForEfsInitTable = TableFactory.assetsVIS3WithSelected(
        prevState.efsAssets,
        selectedAssetsEfsForAdd,
        prevState.efsSelectedAllPaths,
        prevState.efsSelectedSpecificPaths
      )
      return {
        ...prevState,
        efsSelectedAssets: selectedAssetsEfsForAdd,
        tableEfsDataInit: dataForEfsInitTable,
        tableEfsData: DataHelper.getPaginatedDataForS3TableData(
          prevState.efsPagination,
          dataForEfsInitTable
        ),
      }

    case REMOVE_EFS_SELECTED_ASSET:
      const assetEfsToRemove = action.payload
      const selectedEfsAssetsForRemove = prevState.efsSelectedAssets.filter(
        (item) => item.asset?.id !== assetEfsToRemove.asset.id
      )
      const selectedAssetsEfsIdForRemove = prevState.efsSelectedAllPaths.filter(
        (v) => v !== action.payload.id
      )
      const selectedSpecificEfsPathsForRemove =
        prevState.efsSelectedSpecificPaths.filter(
          (item) => item.asset.asset?.id !== assetEfsToRemove.asset.id
        )

      const dataEfsForTable = TableFactory.assetsVIS3WithSelected(
        prevState.efsAssets,
        selectedEfsAssetsForRemove,
        selectedAssetsEfsIdForRemove,
        selectedSpecificEfsPathsForRemove
      )
      return {
        ...prevState,
        efsSelectedAssets: selectedEfsAssetsForRemove,
        efsSelectedAllPaths: selectedAssetsEfsIdForRemove,
        efsSelectedSpecificPaths: selectedSpecificEfsPathsForRemove,
        tableEfsDataInit: dataEfsForTable,
        tableEfsData: DataHelper.getPaginatedDataForS3TableData(
          prevState.efsPagination,
          dataEfsForTable
        ),
      }

    case ADD_EFS_SELECTED_ALL_PATHS:
      const assetEfsIdToAdd = action.payload.asset?.id
      const selectedEfsAssetToAdd = action.payload
      const selectedAssetsEfsIdForAdd = prevState.efsSelectedAllPaths
        .filter((v) => {
          if (v === assetEfsIdToAdd) {
            return false
          }
          return true
        })
        .concat([assetEfsIdToAdd])

      const selectedEfsAssetsToAdd = prevState.efsSelectedAssets
        .filter((item) => {
          if (item.asset?.id === assetEfsIdToAdd) {
            return false
          }
          return true
        })
        .concat([selectedEfsAssetToAdd])

      const selectedSpecificEfsPathsWithoutThis =
        prevState.efsSelectedSpecificPaths.filter(
          (v) => v.asset.asset?.id !== assetEfsIdToAdd
        )

      const dataEfsInitTable = TableFactory.assetsVIS3WithSelected(
        prevState.efsAssets,
        selectedEfsAssetsToAdd,
        selectedAssetsEfsIdForAdd,
        selectedSpecificEfsPathsWithoutThis
      )
      return {
        ...prevState,
        efsSelectedAllPaths: selectedAssetsEfsIdForAdd,
        efsSelectedAssets: selectedEfsAssetsToAdd,
        efsSelectedSpecificPaths: selectedSpecificEfsPathsWithoutThis,
        tableEfsDataInit: dataEfsInitTable,
        tableEfsData: DataHelper.getPaginatedDataForS3TableData(
          prevState.efsPagination,
          dataEfsInitTable
        ),
      }

    case REMOVE_EFS_SELECTED_ALL_PATHS:
      const assetEfsIdForRemove = prevState.efsSelectedAllPaths.filter(
        (v) => v !== action.payload.asset?.id
      )
      const dataEfsSelectedForTable = TableFactory.assetsVIS3WithSelected(
        prevState.efsAssets,
        prevState.efsSelectedAssets,
        assetEfsIdForRemove,
        prevState.efsSelectedSpecificPaths
      )
      return {
        ...prevState,
        efsSelectedAllPaths: assetEfsIdForRemove,
        tableEfsDataInit: dataEfsSelectedForTable,
        tableEfsData: DataHelper.getPaginatedDataForS3TableData(
          prevState.efsPagination,
          dataEfsSelectedForTable
        ),
      }

    case ADD_EFS_SELECTED_SPECIFIC_PATHS:
      const assetEfsId = action.payload.asset.asset.id
      const selectEfsAssetToAdd = action.payload.asset
      const selectedEfsPathsToAdd = action.payload
      const selectedEfsAllSpecificPathsWithoutCurrent =
        prevState.efsSelectedSpecificPaths.filter(
          (v) => v.asset.asset.id !== assetEfsId
        )

      const selectedEfsAllSpecificPathsToAdd =
        selectedEfsAllSpecificPathsWithoutCurrent.concat([
          selectedEfsPathsToAdd,
        ])

      const selectAssetsEfsToAdd = prevState.s3SelectedAssets
        .filter((item) => {
          if (item.asset?.id === assetEfsId) {
            return false
          }
          return true
        })
        .concat([selectEfsAssetToAdd])

      const dataAllEfsInitTable = TableFactory.assetsVIS3WithSelected(
        prevState.efsAssets,
        selectAssetsEfsToAdd,
        prevState.efsSelectedAllPaths,
        selectedEfsAllSpecificPathsToAdd
      )
      return {
        ...prevState,
        efsSelectedAssets: selectAssetsEfsToAdd,
        efsSelectedSpecificPaths: selectedEfsAllSpecificPathsToAdd,
        tableEfsDataInit: dataAllEfsInitTable,
        tableEfsData: DataHelper.getPaginatedDataForS3TableData(
          prevState.efsPagination,
          dataAllEfsInitTable
        ),
      }

    case SET_ALL_EFS_SELECTED_ASSETS:
      const selectedAllEfsPath: Array<string> = []
      const selectedSpecificEfsPath: Array<SelectSpecificPathsInterface> = []

      action.payload?.allSelectedComplex?.map(
        (selectedEfsAssetComplex: SelectedEfsAssetComplex) => {
          // all path
          if (
            selectedEfsAssetComplex?.selectedAllPath &&
            selectedEfsAssetComplex?.assetModel
          ) {
            selectedAllEfsPath.push(
              selectedEfsAssetComplex?.assetModel?.asset?.id
            )
          } else {
            // specific path
            if (selectedEfsAssetComplex?.assetModel !== undefined) {
              selectedSpecificEfsPath.push({
                asset: selectedEfsAssetComplex?.assetModel,
                selectedPaths:
                  selectedEfsAssetComplex?.selectedSpecificPath as Record<
                    string,
                    Array<string>
                  >,
              })
            }
          }
        }
      )
      const allSelectedEfsAssets = TableFactory.assetsVIS3WithSelected(
        prevState.efsAssets,
        action.payload.allSelected,
        selectedAllEfsPath,
        selectedSpecificEfsPath
      )

      return {
        ...prevState,
        efsSelectedAssets: action.payload?.allSelected ?? [],
        tableEfsDataInit: allSelectedEfsAssets,
        tableEfsData: DataHelper.getPaginatedDataForS3TableData(
          prevState.efsPagination,
          allSelectedEfsAssets
        ),
        efsSelectedAllPaths: selectedAllEfsPath,
        efsSelectedSpecificPaths: selectedSpecificEfsPath,
      }

    case RESET_EFS_SELECTED_DATA:
      return {
        ...prevState,
        efsFilters: defaultSelectedFiltersForS3Buckets(),
        efsPagination: PaginationFactory.buildEmpty(),
        efsSearch: '',
        efsSelectedAssets: [],
        tableEfsDataInit: [],
        tableEfsData: [],
        efsSelectedAllPaths: [],
        efsSelectedSpecificPaths: [],
      }

    case SET_ASSET_IDS_MISSING_IN_LIVE_ASSETS:
      return {
        ...prevState,
        assetIdsMissingInLiveAssets: action.payload,
      }

    case SET_ASSET_LOADING_MARKER:
      return {
        ...prevState,
        assetsLoadingMarker: action.payload,
      }

    default:
      return prevState
  }
}

export default assetsSelectReducer
