import React from 'react'

function AllJobsIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12250_362405)">
        <path
          d="M10.0468 15.5244C7.67168 15.5244 5.60118 14.0084 4.85089 11.8215L5.4622 12.0882L5.791 11.3347L4.08838 10.5918L3 12.2247L3.68416 12.6808L4.07514 12.0942C4.93939 14.6059 7.3183 16.3467 10.0468 16.3467C13.0954 16.3467 15.7076 14.174 16.2581 11.1806L15.4494 11.0319C14.9707 13.635 12.6986 15.5244 10.0468 15.5244Z"
          fill="#32B6F3"
        />
        <path
          d="M10.0468 4.4765C12.3984 4.4765 14.4633 5.97642 15.2263 8.13948L14.5006 7.84121L14.1881 8.60169L16.0032 9.3477L17 7.78023L16.3063 7.33898L15.9909 7.83493C15.1047 5.36448 12.7391 3.6543 10.0468 3.6543C7.02937 3.6543 4.42192 5.80077 3.84692 8.75813L4.654 8.91509C5.15406 6.34321 7.42209 4.4765 10.0468 4.4765Z"
          fill="#32B6F3"
        />
        <path
          d="M7.16626 13.8775H12.9253V7.12797L11.9433 6.16016H7.16626V13.8775ZM7.98846 6.98236H11.6062L12.1031 7.47207V13.0554H7.98846V6.98236Z"
          fill="#32B6F3"
        />
        <path
          d="M9.10608 7.87109H10.9873V8.6933H9.10608V7.87109Z"
          fill="#32B6F3"
        />
        <path
          d="M9.10608 9.60742H10.9873V10.4296H9.10608V9.60742Z"
          fill="#32B6F3"
        />
        <path
          d="M9.10608 11.3438H10.9873V12.166H9.10608V11.3438Z"
          fill="#32B6F3"
        />
      </g>
      <defs>
        <clipPath id="clip0_12250_362405">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AllJobsIcon
