// from https://github.com/elastio/blue-stack/blob/master/notifications-service/.helm/values.develop.yaml#L49
export enum EventTypeConstant {
  ACCOUNT_LEVEL_STACK = 'account-level-stack',
  ASSET = 'asset',
  BACKUP_POLICY = 'backup-policy',
  CLOUD_CONNECTOR = 'cloud-connector',
  LOCAL_PATH = 'local-path',
  RECOVERY_POINT = 'recovery-point',
  SNAPSHOTS = 'snapshots',
  VAULT = 'vault',
  USER = 'user',
  ROLE = 'role',
}
