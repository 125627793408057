"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.elastioRecoveryPointFactory = void 0;
const elastio_1 = require("../../models/backups/aws/elastio");
const types_1 = require("../../models/backups/aws/elastio/types");
const factories_1 = require("../../use_cases/tests/factories");
const fishery_1 = require("fishery");
const common_1 = require("./common");
class ElastioRecoveryPointFactory extends fishery_1.Factory {
    withBackupEntities(ebsIds) {
        return this.transient({
            backupEntities: ebsIds.map((id) => ({
                entity: {
                    assetId: id,
                },
            })),
        });
    }
}
const vault = (sequence) => ({
    name: (0, factories_1.name)('vault-name', sequence),
    shard: (0, factories_1.name)('shard', sequence),
});
const backupEntity = () => ({
    entity: {
        assetId: (0, factories_1.uuid)(),
    },
});
const backupEntities = () => Array.from({ length: 10 }, backupEntity);
const correspondingBackup = () => ({
    awsBackup: {
        awsbRpId: (0, factories_1.uuid)(),
    },
    ebsSnapshot: {
        snapId: (0, factories_1.uuid)(),
    },
});
const threatsIds = () => Array.from({ length: 10 }, factories_1.uuid);
const sizeInfo = () => ({
    optimizedSize: Math.floor(Math.random() * 100000),
});
exports.elastioRecoveryPointFactory = ElastioRecoveryPointFactory.define(({ transientParams, sequence, params }) => {
    const { sourceAssetId, kind } = params;
    return new elastio_1.ElastioRecoveryPoint({
        ransomwareCheckStatus: transientParams.ransomwareCheckStatus ??
            (0, common_1.randomValueFromEnum)(types_1.CheckStatus),
        malwareCheckStatus: transientParams.malwareCheckStatus ?? (0, common_1.randomValueFromEnum)(types_1.CheckStatus),
        fsCheckStatus: transientParams.fsCheckStatus ?? (0, common_1.randomValueFromEnum)(types_1.CheckStatus),
        id: transientParams.id ?? (0, factories_1.uuid)(),
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
        ccRpId: transientParams.ccRpId ?? (0, factories_1.uuid)(),
        ccId: transientParams.ccId ?? (0, factories_1.uuid)(),
        vault: transientParams.vault ?? vault(sequence),
        jobId: transientParams.jobId ?? (0, factories_1.uuid)(),
        kind: kind ?? (0, common_1.randomValueFromEnum)(types_1.ElastioRecoveryPointKind),
        status: transientParams.status ??
            (0, common_1.randomValueFromEnum)(types_1.ElastioRecoveryPointStatus),
        deletionStatus: transientParams.deletionStatus ?? (0, common_1.randomValueFromEnum)(types_1.StatusDeletion),
        sourceAssetId: sourceAssetId ?? (0, factories_1.uuid)(),
        backupEntities: transientParams.backupEntities ?? backupEntities(),
        correspondingBackup: transientParams.correspondingBackup ?? correspondingBackup(),
        tags: transientParams.tags ?? [],
        backupPolicyName: transientParams.backupPolicyName ?? (0, factories_1.name)('backupPolicyName', sequence),
        retentionPolicyName: transientParams.retentionPolicyName ??
            (0, factories_1.name)('retentionPolicyName', sequence),
        threatIds: transientParams.threatIds ?? threatsIds(),
        scanIds: transientParams.scanIds ?? [],
        sizeInfo: transientParams.sizeInfo ?? sizeInfo(),
        timestamp: transientParams.timestamp ?? (0, common_1.date)(),
    });
});
