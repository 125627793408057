import {
  LOGOUT_USER,
  SET_IS_MFA_ENABLED,
  SET_POSSIBLE_ROLES,
  SET_SESSIONS,
  SET_USER,
  SET_USERS_LIST,
} from '@store/actions/user.action'
import { UserState } from '@store/states/user.state'
import ActionInterface from '@lib/interfaces/action.interface'
import UserFactory from '@lib/factories/user.factory'

const initialState: UserState = {
  usersList: [],
  user: UserFactory.buildEmptyUser(),
  sessions: [],
  possibleRoles: [],
  isMfaEnabled: false,
}

function userReducer(
  prevState: UserState = initialState,
  action: ActionInterface
): UserState {
  switch (action.type) {
    case SET_POSSIBLE_ROLES:
      return {
        ...prevState,
        possibleRoles: [...action.payload].reverse(),
      }

    case SET_USERS_LIST:
      return {
        ...prevState,
        usersList: action.payload,
      }

    case SET_USER:
      return {
        ...prevState,
        user: action.payload,
      }

    case LOGOUT_USER:
      return {
        ...prevState,
        user: UserFactory.buildEmptyUser(),
      }

    case SET_SESSIONS:
      return {
        ...prevState,
        sessions: action.payload,
      }

    case SET_IS_MFA_ENABLED:
      return {
        ...prevState,
        isMfaEnabled: action.payload,
      }

    default:
      return prevState
  }
}

export default userReducer
