"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantModel = void 0;
class TenantModel {
    name;
    vulnerabilities_enabled;
    created_at;
    updated_at;
    constructor(attrs) {
        this.name = attrs.name;
        this.vulnerabilities_enabled = attrs.vulnerabilities_enabled;
        this.created_at = attrs.created_at;
        this.updated_at = attrs.updated_at;
    }
    getName() {
        return this.name;
    }
    getVulnerabilitiesEnabled() {
        return this.vulnerabilities_enabled;
    }
    getCreatedAt() {
        return this.created_at ? this.created_at * 1000 : undefined;
    }
    getUpdatedAt() {
        return this.updated_at ? this.updated_at * 1000 : undefined;
    }
    setVulnerabilitiesEnabled(vulnerabilities_enabled) {
        this.vulnerabilities_enabled = vulnerabilities_enabled;
    }
}
exports.TenantModel = TenantModel;
