"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.efsFactory = void 0;
const efs_1 = require("../../models/assets/aws/efs");
const types_1 = require("../../models/assets/aws/efs/types");
const factories_1 = require("../../use_cases/tests/factories");
const fishery_1 = require("fishery");
const common_1 = require("./common");
class EFSFactory extends fishery_1.Factory {
}
exports.efsFactory = EFSFactory.define(({ transientParams }) => {
    return new efs_1.EFS({
        state: transientParams.state ?? (0, common_1.randomValueFromEnum)(types_1.EfsState),
        id: transientParams.id ?? (0, factories_1.uuid)(),
        awsAccountId: transientParams.awsAccountId ?? (0, factories_1.awsAccountId)(),
        awsRegion: transientParams.awsRegion ?? (0, factories_1.awsRegion)(),
        awsId: transientParams.awsId ?? (0, factories_1.uuid)(),
        arn: transientParams.arn ?? (0, factories_1.uuid)(),
        tags: transientParams.tags ?? [],
        backupPolicies: transientParams.backupPolicies ?? [],
        ccAssetId: transientParams.ccAssetId ?? (0, factories_1.uuid)(),
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
    });
});
