abstract class RegexConstants {
  public static readonly EMAIL = /^([^.@]+)(\.[^.@]+)*@([^.@]+\.)+([^.@]+)$/

  public static readonly EMAILv2 =
    /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

  public static readonly TAG = /[^\s]*\s*(:\s*[^\s])?/

  // good article of explanation - https://www.regular-expressions.info/unicode.html
  // the original AWS doc
  // https://docs.aws.amazon.com/directoryservice/latest/devguide/API_Tag.html
  // they suggest using - ^([\p{L}\p{Z}\p{N}_.:/=+\-@]*)$
  // \p{L} or \p{Letter}: any kind of letter from any language
  // \p{Z} or \p{Separator}: any kind of whitespace or invisible separator.
  // \p{N} or \p{Number}: any kind of numeric character in any script.
  // in JS 3 above we replace with "\w\s"
  // public static readonly TAG_NAME = /[\w.:\/=+-@]+/u
  public static readonly TAG_NAME = /^[\w\s.:\/=+-@]+$/u

  public static readonly TAG_VALUE = /^[\w\s.:\/=+-@]*$/u

  public static readonly HAS_LOWER_CASE = /[a-z]+/

  public static readonly HAS_UPPER_CASE = /[A-Z]+/

  public static readonly HAS_NUMBER = /[0-9]+/

  public static readonly HAS_ONLY_NUMBER_STARTS_ONE = /^(?!(0))[0-9]+$/

  // we ignore "-", but calculating the amount of numbers - 12
  // also this code supposes that might be other variants
  // examples are:
  // 123412341234
  // 1234-1234-1234
  public static readonly ACCOUNT_ID =
    /^\d(-)?\d(-)?\d(-)?\d(-)?\d(-)?\d(-)?\d(-)?\d(-)?\d(-)?\d(-)?\d(-)?\d$/

  public static readonly ACCOUNT_ID_FILTER = /\d|-/g

  public static readonly ACCOUNT_ID_SERVER_FORMAT = /\D/g

  public static readonly VAULT_NAME = /^[a-z0-9][a-z0-9-]{1,34}[a-z0-9]$/

  public static readonly JOB_NAME_BACKUP = /^(Back up.+\s)(\w+-\S+)$/

  public static readonly POLICY_NAME =
    /^(?!\s)(?!.*\s$)^[A-Za-z0-9\-=\._:@][A-Za-z0-9\+\-=\._:@/\s+]{0,254}$/

  public static readonly SITE_WITH_HTTPS =
    /^https:\/\/([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/

  public static readonly ONLY_ASCII_CHARACTERS = /^[\x00-\x7F]+$/

  public static readonly WITH_WHITESPACE = /\s/
}

export default RegexConstants
