"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetItemType = void 0;
var AssetItemType;
(function (AssetItemType) {
    AssetItemType[AssetItemType["FILE"] = 0] = "FILE";
    AssetItemType[AssetItemType["STREAM"] = 1] = "STREAM";
    AssetItemType[AssetItemType["LOCAL_VOLUME"] = 2] = "LOCAL_VOLUME";
    AssetItemType[AssetItemType["DISK"] = 3] = "DISK";
    AssetItemType[AssetItemType["S3_ITEM"] = 4] = "S3_ITEM";
    AssetItemType[AssetItemType["FS_ITEM"] = 5] = "FS_ITEM";
    AssetItemType[AssetItemType["FOLDER"] = 6] = "FOLDER";
})(AssetItemType = exports.AssetItemType || (exports.AssetItemType = {}));
