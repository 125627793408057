import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  Dispatch,
  ReactNode,
} from 'react'

import { timeRangeReducer, INITIAL_STATE } from './time-range-reducer'
import { TimeRangeAction, TimeRangeState } from './types'

const TimeRangeContext = createContext<
  { state: TimeRangeState; dispatch: Dispatch<TimeRangeAction> } | undefined
>(undefined)

export function TimeRangeProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(timeRangeReducer, INITIAL_STATE)

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  )

  return (
    <TimeRangeContext.Provider value={value}>
      {children}
    </TimeRangeContext.Provider>
  )
}

export function useTimeRange() {
  const context = useContext(TimeRangeContext)
  if (context === undefined) {
    throw new Error('useTimeRange must be used within a TimeRangeProvider')
  }
  return context
}
