"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stream = void 0;
const asset_item_1 = require("../../asset-items/asset-item");
class Stream extends asset_item_1.AssetItem {
    #name;
    constructor(parameters) {
        const { name, ...assetItemParameters } = parameters;
        super(assetItemParameters);
        this.#name = name;
    }
    get name() {
        return this.#name;
    }
}
exports.Stream = Stream;
