import JobModel from '@lib/models/job.model'
import NumHelper from '@lib/helpers/num.helper'
import TypeHelper from '@lib/helpers/type.helper'
import JobStatusConstant from '@lib/constants/jobs/job-status.constant'
import JobKindConstant from '@lib/constants/jobs/job-kind.constant'
import { AssetKind } from '@lib/constants/grpc/asset-kind.constant'

abstract class JobFactory {
  private static buildJobParamsFromGRPC(params: any) {
    return {
      id: NumHelper.numberHash(params.id),
      innerId: TypeHelper.stringValDef(params.id, ''),
      name: TypeHelper.stringValDef(params.name, ''),
      status: TypeHelper.enumValDef(
        params.status,
        JobStatusConstant.UNDEFINED,
        JobStatusConstant
      ),
      kind: TypeHelper.enumValDef(
        params.kind,
        JobKindConstant.UNDEFINED,
        JobKindConstant
      ),
      statusMessage: TypeHelper.stringValDef(params.statusMessage, ''),
      createdAt: TypeHelper.numberValDef(params.createdAt?.seconds * 1000, 0),
      updatedAt: TypeHelper.numberValDef(params.updatedAt?.seconds * 1000, 0),
      recoveryPointAssetId: TypeHelper.stringValDef(
        params.relatedRecoveryPoint?.assetId,
        ''
      ),
      recoveryPointId: TypeHelper.stringValDef(
        params.relatedRecoveryPoint?.recoveryPointId,
        ''
      ),
      redStackRecoveryPointId: TypeHelper.stringValDef(
        params.relatedRecoveryPoint?.redStackRecoveryPointId,
        ''
      ),
      customStatus: TypeHelper.stringValDef(params.customStatus, ''),
      cloudConnectorId: TypeHelper.stringValDef(params.redStackId, ''),
      relatedAssetKind: TypeHelper.enumValDef(
        params.relatedAsset?.assetKind,
        AssetKind.UNDEFINED,
        AssetKind
      ),
      relatedAssetAwsAccountId: TypeHelper.stringValDef(
        params.relatedAsset?.awsAccountId,
        ''
      ),
      relatedAssetRegion: TypeHelper.stringValDef(
        params.relatedAsset?.region,
        ''
      ),
      relatedAssetResourceId: TypeHelper.stringValDef(
        params.relatedAsset?.resourceId,
        ''
      ),
      isAbortRequested: TypeHelper.booleanValDef(
        params.isAbortRequested,
        false
      ),
      // it makes no sense of the enum from theirs side
      // node_modules/blue-stack-libs/jobstatus-grpc-libs/js/jobstatus/jobstatus_pb.d.ts
      //     TENANT = 0,
      //     CLOUD_CONNECTOR = 1,
      isCloudConnectorJob: !!params.origin,
      awsAccountId: TypeHelper.stringValDef(params.awsAccountId, ''),
      awsRegion: TypeHelper.stringValDef(params.awsRegion, ''),
    }
  }

  private static buildSubJobFromGRPC(params: any): JobModel {
    return new JobModel(JobFactory.buildJobParamsFromGRPC(params))
  }

  public static buildJobFromGRPC(params: any): JobModel {
    return new JobModel({
      ...JobFactory.buildJobParamsFromGRPC(params),
      childrenList: params.childrenList?.map(JobFactory.buildSubJobFromGRPC),
    })
  }
}

export default JobFactory
