/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import SystemHelper from '@lib/helpers/system.helper'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import CheckIcon from '@mui/icons-material/Check'
import { StyledCopyIcon } from './styles'

const TIMEOUT = 1000

interface Props {
  text: string
  fontSize?: number
}

function CopyToClipboardIcon({ text, fontSize = 10 }: Props) {
  const [isCopied, setIsCopied] = useState(false)

  const handleOnCopy = async () => {
    await SystemHelper.copyToClipboard(text)

    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false)
    }, TIMEOUT)
  }

  return (
    <StyledCopyIcon
      data-testid="copy-icon-container"
      fontSize={fontSize}
      onClick={handleOnCopy}
    >
      {isCopied ? (
        <CheckIcon fontSize="inherit" />
      ) : (
        <FileCopyOutlinedIcon fontSize="inherit" />
      )}
    </StyledCopyIcon>
  )
}

export default CopyToClipboardIcon
