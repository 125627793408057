import React, { memo, useCallback, useState } from 'react'
import CheckboxBlock from '@components-simple/checkbox-block/CheckboxBlock'
import DemoService from '@lib/services/demo.service'
import { EngineCallbackPure, VIRow } from '@lib/engine-types'
import { Button } from '@mui/material'

interface Props {
  onCancel: EngineCallbackPure
}

function ConfigureViewForm({ onCancel }: Props) {
  const [configViewList, setConfigViewList] = useState<VIRow>(
    DemoService.getConfigs()
  )

  const onSubmitCallback = useCallback(() => {
    DemoService.updateConfigsWithReloading(configViewList)
  }, [configViewList])

  const onChangeCallback = useCallback((elements) => {
    setConfigViewList(elements)
  }, [])

  return (
    <div className="wrap-1622712676456">
      <div className="configureViewForm">
        <CheckboxBlock data={configViewList} onChange={onChangeCallback} />
        <div className="newFormHr" />
      </div>
      <div className="modalFooterV2">
        <Button
          className="mfCancel jsCancel"
          type="button"
          variant="text"
          color="primary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          className="mfSubmit jsSubmit"
          color="primary"
          variant="contained"
          type="button"
          onClick={onSubmitCallback}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default memo(ConfigureViewForm)
