import { ListCloudConnectorJobsRequest } from '@lib/clients/grpc-imports'
import { AbstractListRequest } from '@lib/clients/list-request'
import { Request as Pagination } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/pagination_pb'

export class ListCloudConnectorJobsPagination extends AbstractListRequest<ListCloudConnectorJobsRequest> {
  #pageToken: string

  constructor(pageToken: string) {
    super()
    this.#pageToken = pageToken
  }

  createGrpcRequest(): ListCloudConnectorJobsRequest {
    const grpcRequest = ListCloudConnectorJobsRequest

    return new grpcRequest().setPagination(
      new Pagination().setPageToken(this.#pageToken)
    )
  }
}
