import React from 'react'
import {
  useReactTable,
  TableOptions,
  getCoreRowModel,
  getSortedRowModel,
  ColumnDef,
  RowModel,
  Table,
} from '@tanstack/react-table'
import { VirtualTableConfig } from './types'
import Skeleton from '@mui/material/Skeleton'

function createCellSkeleton() {
  return <Skeleton />
}

const getSkeletonColumns = <T,>(
  columns: Array<ColumnDef<T>>,
  isLoading: boolean
): Array<ColumnDef<T>> => {
  return isLoading
    ? columns.map((column) => ({
        ...column,
        cell: createCellSkeleton,
      }))
    : columns
}

export function useTableConfig<T>(
  config: VirtualTableConfig<T>
): [Table<T>, RowModel<T>] {
  const columnsMemo = getSkeletonColumns(
    config.columns,
    config.state.isLoading || false
  )

  const tableConfig: TableOptions<T> = {
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    data: config.data,
    columns: columnsMemo,
    onRowSelectionChange: config.onRowSelectionChange,
    state: {
      rowSelection: config.state?.rowSelection,
    },
    initialState: config.initialState,
    enableRowSelection: true,
  }

  const table = useReactTable(tableConfig)

  return [table, table.getRowModel()]
}
