import useEngineInit from '@lib/hooks/engine/useEngineInit'
import store from '@store/store'
import React, { ReactNode } from 'react'
import { LoadingScreen } from '@components-simple/loading-screen'
import { PageMeta } from '@lib/engine-types'

function AuthenticationSSRRequired({
  children,
  pageMeta,
}: {
  children: ReactNode
  pageMeta: PageMeta
}) {
  const { initFinished } = useEngineInit(pageMeta, store.dispatch)

  if (!initFinished) {
    return <LoadingScreen />
  }

  return <>{children}</>
}

export default AuthenticationSSRRequired
