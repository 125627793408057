import { Retrier } from '@jsier/retrier'
import GrpcRequestError from '@lib/errors/grpc-request-error'
import SystemHelper from '@lib/helpers/system.helper'
import ToastHelper from '@lib/helpers/toast.helper'
import ServerErrorConstant from '@lib/constants/data/error/server-error.constant'
import { AuthTokenManager } from './helpers/token-manager'

const MAX_REQUEST_TIME = 60000 * 5 // 5 minutes
const MAX_LONG_REQUEST_TIME = 60000 * 8 // 8 minutes

const RETRIES_OPTIONS = {
  delay: 200,
  limit: 3,
}

abstract class GrpcBaseService {
  private static token = ''

  public static init(token: string) {
    this.token = token
  }

  // this function is complicated because of
  // minification - it removes most information
  private static getMethodName(clientFunc: Function): string {
    // @ts-ignore
    const allMethodsNames = Object.getOwnPropertyNames(this.client.__proto__)
    // @ts-ignore
    const currentMethod = allMethodsNames.find(
      // @ts-ignore
      (m) => this.client.__proto__[m] === clientFunc
    )
    // @ts-ignore
    return `${this.clientName}/${currentMethod}`
  }

  private static async handleQueryRetryInner<RequestType, ResponseType>(
    clientFunc: (
      request: RequestType,
      headers: Record<string, string>
    ) => Promise<ResponseType>,
    request: RequestType,
    requestTimeout: number
  ): Promise<ResponseType> {
    const methodName = this.getMethodName(clientFunc)
    const isJestRunning = process.env.JEST_WORKER_ID !== undefined
    const timer = setTimeout(() => {
      console.log(
        `Long query detected - [${methodName}] ${
          isJestRunning ? `in test: ${expect.getState().currentTestName}` : ''
        }`
      )
    }, requestTimeout)
    try {
      // if we put it as a general field of the class - it stops working
      const retryHandler = new Retrier(RETRIES_OPTIONS)
      const result = await retryHandler.resolve((attempt?: number) => {
        // in the future it is also better to send them as errors
        if (attempt) {
          // eslint-disable-next-line no-console

          console.log(
            `Run retry [attempt ${attempt + 1}] - "${methodName}" ${
              isJestRunning
                ? `in test: ${expect.getState().currentTestName}`
                : ''
            }`
          )
        }

        // @ts-ignore
        const client = this.client
        // @ts-ignore
        if (!client || !this.clientName) {
          throw new Error('Client must be set up')
        }
        return clientFunc.call(client, request, {
          Authorization: this.token,
          deadline: String(Date.now() + requestTimeout),
        })
      })
      return result
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error('GRPC Service Error', error)
      if (
        ServerErrorConstant.isTimeoutError(error.code) &&
        SystemHelper.shouldAddDebugCode()
      ) {
        ToastHelper.error(`API Timeout problem:
        ${methodName}`)
      }
      throw new GrpcRequestError(error.message, error.name, error.code)
    } finally {
      clearInterval(timer)
    }
  }

  protected static async handleQueryRetry<RequestType, ResponseType>(
    clientFunc: (
      request: RequestType,
      headers: Record<string, string>
    ) => Promise<ResponseType>,
    request: RequestType
  ): Promise<ResponseType> {
    new AuthTokenManager().ensureTokenValidity(this.token)
    return this.handleQueryRetryInner(clientFunc, request, MAX_REQUEST_TIME)
  }

  protected static async handleQueryRetryLong<RequestType, ResponseType>(
    clientFunc: (
      request: RequestType,
      headers: Record<string, string>
    ) => Promise<ResponseType>,
    request: RequestType
  ): Promise<ResponseType> {
    return this.handleQueryRetryInner(
      clientFunc,
      request,
      MAX_LONG_REQUEST_TIME
    )
  }

  private static async handleQueryInner<RequestType, ResponseType>(
    clientFunc: (
      request: RequestType,
      headers: Record<string, string>
    ) => Promise<ResponseType>,
    request: RequestType,
    requestTimeout: number
  ): Promise<ResponseType> {
    const methodName = this.getMethodName(clientFunc)
    const timer = setTimeout(() => {
      const isJestRunning = process.env.JEST_WORKER_ID !== undefined

      console.log(
        `Long query detected - [${methodName}] ${
          isJestRunning ? `in test: ${expect.getState().currentTestName}` : ''
        }`
      )
      // we subtract a value in order to fire it before the request timeout
    }, requestTimeout - 300)
    // the request below may throw errors, but it means that the request
    // was successfully done
    try {
      // @ts-ignore
      const client = this.client

      // @ts-ignore
      if (!client || !this.clientName) {
        throw new Error('Client must be set up')
      }
      return clientFunc
        .call(client, request, {
          waitForReady: 'true',
          Authorization: this.token,
          deadline: String(Date.now() + requestTimeout),
        })
        .catch((subError) => {
          // eslint-disable-next-line no-console
          console.error('GRPC Service Error', subError)
          if (
            ServerErrorConstant.isTimeoutError(subError.code) &&
            SystemHelper.shouldAddDebugCode()
          ) {
            ToastHelper.error(`API Timeout problem:
        ${methodName}`)
          }
          throw new GrpcRequestError(
            subError.message,
            subError.name,
            subError.code
          )
        })
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error('GRPC Service Error', error)
      if (
        ServerErrorConstant.isTimeoutError(error.code) &&
        SystemHelper.shouldAddDebugCode()
      ) {
        ToastHelper.error(`API Timeout problem:
        ${methodName}`)
      }
      throw new GrpcRequestError(error.message, error.name, error.code)
    } finally {
      clearInterval(timer)
    }
  }

  protected static async handleQuery<RequestType, ResponseType>(
    clientFunc: (
      request: RequestType,
      headers: Record<string, string>
    ) => Promise<ResponseType>,
    request: RequestType
  ): Promise<ResponseType> {
    return this.handleQueryInner(clientFunc, request, MAX_REQUEST_TIME)
  }

  protected static async handleQueryLong<RequestType, ResponseType>(
    clientFunc: (
      request: RequestType,
      headers: Record<string, string>
    ) => Promise<ResponseType>,
    request: RequestType
  ): Promise<ResponseType> {
    return this.handleQueryInner(clientFunc, request, MAX_LONG_REQUEST_TIME)
  }
}

export default GrpcBaseService
