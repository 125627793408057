/* eslint-disable import/no-extraneous-dependencies */
import { JobTenant } from 'blues-corejs/dist'
import { JobsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import { Response } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/jobs/list_tenant_jobs_pb'
import { GrpcClient } from '@lib/clients/grpc-client'
import {
  Request as GetTenantJobFilters,
  Response as GetTenantJobFiltersResponse,
} from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/jobs/get_tenant_job_filters_pb'
import { TenantJobTransformer } from '../assets/transformers'
import { ListTenantJobsRequest } from './tenant-jobs'
import {
  TenantJobKind,
  TenantJobStatus,
} from 'blues-corejs/dist/models/jobs/tenant/job/types'
import {
  TenantJobKindTransformer,
  TenantJobStatusTransformer,
} from '../assets/transformers/jobs/tenant-jobs'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { Pagination, TimeRange } from '../types'

export interface ListTenantJobs {
  jobsList: Array<JobTenant>
}

export interface PossibleTenantJobFilters {
  kindsList: Array<TenantJobKind>
  statusesList: Array<TenantJobStatus>
}

export class TenantJobsClient extends GrpcClient<JobsPromiseClient> {
  #jobsPromiseClient: JobsPromiseClient

  constructor(hostName = '') {
    super()
    this.#jobsPromiseClient = this.getClient(hostName)
  }

  protected innerClientTypeId(): string {
    return 'TenantJobsClient'
  }

  protected initClient(hostName: string): JobsPromiseClient {
    return new JobsPromiseClient(hostName, null, null)
  }

  async listTenantJobs(
    request: ListTenantJobsRequest
  ): Promise<ListTenantJobs & Pagination> {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#jobsPromiseClient.listTenantJobs(
            request.toGrpc(),
            this.metadata()
          ),
        {
          requestName: 'JobsPromiseClient/listTenantJobs',
        }
      )
    ).toObject()

    return this.#transformResponse(response)
  }

  async getTenantJobFilters(
    timeRange: TimeRange
  ): Promise<PossibleTenantJobFilters> {
    const timeRangeProto = new GetTenantJobFilters.TimeRange()

    const startTimeStamp = new Timestamp()
    const endTimeStamp = new Timestamp()

    if (timeRange.start) {
      timeRangeProto.setStart(startTimeStamp.setSeconds(timeRange.start))
    }

    if (timeRange.end) {
      timeRangeProto.setEnd(endTimeStamp.setSeconds(timeRange.end))
    }

    const response = (
      await this.retryGrpcCall(
        () =>
          this.#jobsPromiseClient.getTenantJobsFilters(
            new GetTenantJobFilters().setTimeRange(timeRangeProto),
            this.metadata()
          ),
        {
          requestName: 'JobsPromiseClient/getTenantJobsFilters',
        }
      )
    ).toObject()

    return this.#transformTenantJobFilters(response)
  }

  #transformResponse(response: Response.AsObject): ListTenantJobs & Pagination {
    return {
      jobsList: response.jobsList.map((job) =>
        new TenantJobTransformer(job).transform()
      ),
      pageToken: response.pagination?.nextPageToken,
    }
  }

  #transformTenantJobFilters(
    response: GetTenantJobFiltersResponse.AsObject
  ): PossibleTenantJobFilters {
    return {
      kindsList: response.kindsList.map((kind) =>
        new TenantJobKindTransformer(kind).transform()
      ),
      statusesList: response.statusesList.map((status) =>
        new TenantJobStatusTransformer(status).transform()
      ),
    }
  }
}
