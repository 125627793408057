"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Scan = void 0;
class Scan {
    #id;
    #createdAt;
    #updatedAt;
    constructor({ id, createdAt, updatedAt }) {
        this.#id = id;
        this.#createdAt = createdAt;
        this.#updatedAt = updatedAt;
    }
    get id() {
        return this.#id;
    }
    get createdAt() {
        return this.#createdAt;
    }
    get updatedAt() {
        return this.#updatedAt;
    }
    // TODO: change when backend is ready
    get completedAt() {
        return this.#createdAt;
    }
}
exports.Scan = Scan;
