"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OvaBackup = void 0;
const types_1 = require("./types");
const ova_backup_snapshot_1 = require("./ova-backup-snapshot");
const backup_1 = require("../../backup");
class OvaBackup extends backup_1.Backup {
    #provider;
    #ccBackupId;
    #backupProviderBackupId;
    #sourceAssetId;
    #snapshots;
    #totalSize;
    #scanIds;
    #threatIds;
    #malwareCheckStatus;
    #ransomwareCheckStatus;
    constructor(parameters) {
        super(parameters);
        this.#provider = parameters.provider;
        this.#ccBackupId = parameters.ccBackupId;
        this.#backupProviderBackupId = parameters.backupProviderBackupId;
        this.#sourceAssetId = parameters.sourceAssetId;
        // Shallow freeze each object in the array
        this.#snapshots = parameters.snapshots.map((snapshot) => new ova_backup_snapshot_1.OvaBackupSnapshot(snapshot));
        this.#totalSize = parameters.totalSize;
        this.#scanIds = [...parameters.scanIds];
        this.#threatIds = [...parameters.threatIds];
        this.#malwareCheckStatus = parameters.malwareCheckStatus;
        this.#ransomwareCheckStatus = parameters.ransomwareCheckStatus;
    }
    get provider() {
        return this.#provider;
    }
    get ccBackupId() {
        return this.#ccBackupId;
    }
    get backupProviderBackupId() {
        return this.#backupProviderBackupId;
    }
    get sourceAssetId() {
        return this.#sourceAssetId;
    }
    get snapshots() {
        return this.#snapshots;
    }
    get totalSize() {
        return this.#totalSize;
    }
    get threatIds() {
        return this.#threatIds;
    }
    get scanIds() {
        return this.#scanIds;
    }
    get malwareCheckStatus() {
        return this.#malwareCheckStatus;
    }
    get ransomwareCheckStatus() {
        return this.#ransomwareCheckStatus;
    }
    get filesystemIsCorrupted() {
        return false;
    }
    get filesystemIsNotChecked() {
        return true;
    }
    get filesystemIsNotCorrupted() {
        return false;
    }
    get isMountable() {
        return false;
    }
    get isRestorable() {
        return false;
    }
    get isScannable() {
        return false;
    }
    get malwareIsDetected() {
        return this.malwareCheckStatus === types_1.CheckStatus.BAD;
    }
    get malwareIsNotChecked() {
        return this.malwareCheckStatus === types_1.CheckStatus.UNCHECKED;
    }
    get malwareIsNotDetected() {
        return this.malwareCheckStatus === types_1.CheckStatus.GOOD;
    }
    get ransomwareIsDetected() {
        return this.ransomwareCheckStatus === types_1.CheckStatus.BAD;
    }
    get ransomwareIsNotChecked() {
        return this.ransomwareCheckStatus === types_1.CheckStatus.UNCHECKED;
    }
    get ransomwareIsNotDetected() {
        return this.ransomwareCheckStatus === types_1.CheckStatus.GOOD;
    }
    get summaryCheckStatus() {
        return [this.malwareCheckStatus, this.ransomwareCheckStatus].reduce((acc, curr) => {
            if (curr === types_1.CheckStatus.BAD) {
                return backup_1.SummaryCheckStatus.BAD;
            }
            if (curr === types_1.CheckStatus.GOOD &&
                (acc === backup_1.SummaryCheckStatus.GOOD ||
                    acc === backup_1.SummaryCheckStatus.UNCHECKED)) {
                return backup_1.SummaryCheckStatus.GOOD;
            }
            return acc;
        }, backup_1.SummaryCheckStatus.UNCHECKED);
    }
}
exports.OvaBackup = OvaBackup;
