import React from 'react'

function QuestionMarkWhiteIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 13.334C16.32 13.334 13.3334 16.3207 13.3334 20.0007C13.3334 23.6807 16.32 26.6673 20 26.6673C23.68 26.6673 26.6667 23.6807 26.6667 20.0007C26.6667 16.3207 23.68 13.334 20 13.334ZM20 25.334C17.06 25.334 14.6667 22.9407 14.6667 20.0007C14.6667 17.0607 17.06 14.6673 20 14.6673C22.94 14.6673 25.3334 17.0607 25.3334 20.0007C25.3334 22.9407 22.94 25.334 20 25.334ZM19.3334 22.6673H20.6667V24.0007H19.3334V22.6673ZM20.4067 16.0273C19.0334 15.8273 17.82 16.674 17.4534 17.8873C17.3334 18.274 17.6267 18.6673 18.0334 18.6673H18.1667C18.44 18.6673 18.66 18.474 18.7534 18.2207C18.9667 17.6273 19.6 17.2207 20.2867 17.3673C20.92 17.5007 21.3867 18.1207 21.3334 18.7673C21.2667 19.6607 20.2534 19.854 19.7 20.6873C19.7 20.694 19.6934 20.694 19.6934 20.7007C19.6867 20.714 19.68 20.7207 19.6734 20.734C19.6134 20.834 19.5534 20.9473 19.5067 21.0673C19.5 21.0873 19.4867 21.1007 19.48 21.1207C19.4734 21.134 19.4734 21.1473 19.4667 21.1673C19.3867 21.394 19.3334 21.6673 19.3334 22.0007H20.6667C20.6667 21.7207 20.74 21.4873 20.8534 21.2873C20.8667 21.2673 20.8734 21.2473 20.8867 21.2273C20.94 21.134 21.0067 21.0473 21.0734 20.9673C21.08 20.9607 21.0867 20.9473 21.0934 20.9407C21.16 20.8607 21.2334 20.7873 21.3134 20.714C21.9534 20.1073 22.82 19.614 22.64 18.3407C22.48 17.1807 21.5667 16.2007 20.4067 16.0273V16.0273Z"
        fill="white"
      />
    </svg>
  )
}

export default QuestionMarkWhiteIcon
