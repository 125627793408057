import SessionModel from '@lib/models/session.model'
import SessionFactory from '@lib/factories/session.factory'
import SystemHelper from '@lib/helpers/system.helper'

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
} as const

class RequestServiceInner {
  private token = ''

  private getHeaders(): HeadersInit {
    return {
      ...DEFAULT_HEADERS,
      Authorization: this.token,
    }
  }

  // error.code
  // error.name
  // error.message
  private static reThrowErrorHandler(error: any): void {
    SystemHelper.throwErrorInLocalEnv(decodeURI(error.message))
  }

  private async sendPost(
    url: string,
    body: object,
    headers?: HeadersInit
  ): Promise<any> {
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: headers ?? this.getHeaders(),
      })
      const result = await response.json()
      return result
    } catch (error: any) {
      RequestServiceInner.reThrowErrorHandler(error)
    }
  }

  public init(token: string) {
    this.token = token
  }

  public addTenant(tenantName: string): Promise<any> {
    return this.sendPost('/api/tenant/add', { tenantName })
  }

  // eslint-disable-next-line class-methods-use-this
  public verifyUser(link: string): Promise<any> {
    return this.sendPost('/api/user/verify', { link }, DEFAULT_HEADERS)
  }

  public async updatePassword(
    currentPassword: string,
    password: string
  ): Promise<any> {
    const result: any = await this.sendPost('/api/user/password', {
      currentPassword,
      password,
    })
    if (result.error) {
      throw new Error(result.error)
    }
    return result
  }

  public async sessions(): Promise<Array<SessionModel>> {
    const data: any = await this.sendPost('/api/user/sessions', {})
    return data.data.map(SessionFactory.build)
  }

  public async isMfaEnabled(): Promise<any> {
    const result = await this.sendPost('/api/mfa/is-enabled', {})
    if (result.error) {
      throw new Error(result.error)
    }
    return !!result.status
  }

  public async updateMfaEnabled(value: boolean): Promise<any> {
    const result = await this.sendPost('/api/mfa/update', { value })
    if (result.error) {
      throw new Error(result.error)
    }
    return !!result.status
  }

  public async resetMfa(): Promise<any> {
    const result = await this.sendPost('/api/mfa/reset', {})
    if (result.error) {
      throw new Error(result.error)
    }
    return !!result.status
  }
}

const RequestService = new RequestServiceInner()

export default RequestService
