import React, { ReactNode } from 'react'
import Image from 'next/image'

interface Props {
  children: ReactNode
}

function ImageLayout({ children }: Props) {
  return (
    <div className="wrap-1614166083206 jsImageLayout jsLayout">
      <div className="ilLogo" />

      <div className="ilMain">
        <div className="ilImage">
          <Image src="/images/login.svg" alt="" width={406} height={406} />
        </div>
        <main className="ilContent">{children}</main>
      </div>
    </div>
  )
}

export default ImageLayout
