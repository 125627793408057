"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ebsFactory = void 0;
const ebs_1 = require("../../models/assets/aws/ebs");
const factories_1 = require("../../use_cases/tests/factories");
const fishery_1 = require("fishery");
const common_1 = require("./common");
const types_1 = require("../../models/assets/aws/ebs/types");
class EBSFactory extends fishery_1.Factory {
    withInstanceIds(instanceIds) {
        return this.transient({
            attachedInstanceIds: instanceIds,
        });
    }
}
const encryptionDetails = () => ({
    encrypted: (0, factories_1.bool)(),
    encryptedWithCmk: (0, factories_1.bool)(),
});
const performanceCharacteristics = () => ({
    iops: Math.floor(Math.random() * 16000),
    throughput: Math.floor(Math.random() * 1000),
});
const attachedInstanceIds = () => Array.from({ length: 5 }, factories_1.awsInstanceId);
exports.ebsFactory = EBSFactory.define(({ transientParams }) => {
    return new ebs_1.EBS({
        id: transientParams.id ?? (0, factories_1.uuid)(),
        awsId: transientParams.awsId ?? (0, factories_1.awsVolumeId)(),
        awsAccountId: transientParams.awsAccountId ?? (0, factories_1.awsAccountId)(),
        awsRegion: transientParams.awsRegion ?? (0, factories_1.awsRegion)(),
        arn: transientParams.arn ?? (0, factories_1.uuid)(),
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
        state: transientParams.state ?? (0, common_1.randomValueFromEnum)(types_1.EBSState),
        type: transientParams.type ?? (0, common_1.randomValueFromEnum)(types_1.EBSType),
        encryptionDetails: transientParams.encryptionDetails ?? encryptionDetails(),
        performanceCharacteristics: transientParams.performanceCharacteristics ??
            performanceCharacteristics(),
        tags: transientParams.tags ?? [],
        attachedInstanceIds: transientParams.attachedInstanceIds ?? attachedInstanceIds(),
        hasReplicatedBackups: transientParams.hasReplicatedBackups ?? (0, factories_1.bool)(),
        backupPolicies: transientParams.backupPolicies ?? [],
        retentionPolicy: transientParams.retentionPolicy ?? [],
        ccAssetId: transientParams.ccAssetId ?? (0, factories_1.uuid)(),
        nonRecognizedVolumeType: transientParams.nonRecognizedVolumeType ?? '',
        awsAz: transientParams.awsAz ?? '',
        size: transientParams.size ?? Math.floor(Math.random() * 10000),
        awsCreatedAt: transientParams.awsCreatedAt ?? (0, common_1.date)(),
    });
});
