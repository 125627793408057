import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

interface UseQueryTab {
  tab: number
  setTab: (tab: number) => void
}

export function useQueryTab(defaultTab: number): UseQueryTab {
  const router = useRouter()
  const [tab, setTab] = useState<number>(defaultTab)

  useEffect(() => {
    const queryTab = parseInt(router.query.t as string)
    if (!isNaN(queryTab)) {
      setTab(queryTab)
    }
  }, [router.query.t])

  const changeTab = (newTab: number) => {
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          t: newTab.toString(),
        },
      },
      undefined,
      { shallow: true }
    )
    setTab(newTab)
  }

  return {
    tab,
    setTab: changeTab,
  }
}
