import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const useAnchorEl = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return {
    anchorEl,
    open,
    handleClick,
    handleClose,
  }
}

const ITEM_HEIGHT = 48

export default function ActionMenu({ options }: any) {
  const { anchorEl, open, handleClick, handleClose } = useAnchorEl()

  return (
    <Box sx={{ textAlign: 'right' }}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {options.map((option: any, index: number) => (
          <MenuItem
            key={index}
            onClick={() => {
              option.onClick()
              handleClose()
            }}
          >
            {option.icon && (
              <>
                <ListItemIcon>
                  {option.icon && <option.icon style={{ marginRight: 8 }} />}
                </ListItemIcon>{' '}
              </>
            )}
            <ListItemText> {option.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
