/* eslint-disable import/no-extraneous-dependencies */
import TimeHelper from '@lib/helpers/time.helper'
import { Stream as StreamProto } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/asset_items/stream_pb'
import { Stream } from 'blues-corejs/dist'

type StreamPt = StreamProto.AsObject

export class StreamTransformer {
  #stream: StreamPt

  constructor(stream: StreamPt) {
    this.#stream = stream
  }

  transform(): Stream {
    return this.#transformStream(this.#stream)
  }

  #transformStream(stream: StreamPt): Stream {
    return new Stream({
      id: stream.id,
      name: stream.name,
      assetId: stream.assetId,
      ccAssetId: stream.ccAssetId,
      createdAt: TimeHelper.secondsToDate(stream.createdAt?.seconds),
      updatedAt: TimeHelper.secondsToDate(stream.updatedAt?.seconds),
    })
  }
}
