import PreloaderConstants from '@lib/constants/preloader.constant'
import { Observable, of } from 'rxjs'
import StoreService from '@lib/services/store.service'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import ErrorService from '@lib/services/error.service'
import { ofType } from 'redux-observable'
import { errorHandledAction } from '@store/actions/default/error-handled.action'
import { EngineFunction } from '@lib/engine-types'
import ActionInterface from '@lib/interfaces/action.interface'
import ToastHelper from '@lib/helpers/toast.helper'
import SystemHelper from '@lib/helpers/system.helper'
import ServerErrorConstant from '@lib/constants/data/error/server-error.constant'

export function onPreloader(preloader: PreloaderConstants) {
  // eslint-disable-next-line max-len
  return (source: Observable<any>): Observable<any> =>
    new Observable<any>((observer) =>
      source.subscribe({
        next(data) {
          StoreService.enablePreloader(preloader)
          observer.next(data)
        },
        error(err) {
          observer.error(err)
        },
        complete() {
          observer.complete()
        },
      })
    )
}

export function offPreloader(preloader: PreloaderConstants) {
  // eslint-disable-next-line max-len
  return (source: Observable<any>): Observable<any> =>
    new Observable<any>((observer) =>
      source.subscribe({
        next(data) {
          StoreService.disablePreloader(preloader)
          observer.next(data)
        },
        error(err) {
          observer.error(err)
        },
        complete() {
          observer.complete()
        },
      })
    )
}

export function catchCustom(
  preloader: PreloaderConstants,
  errorGroup: ErrorGroupConstants
) {
  return (source: Observable<any>): Observable<any> =>
    source.pipe(
      catchError((error, caught) => {
        StoreService.disablePreloader(preloader)
        let errorMessage = error.message
        if (ServerErrorConstant.isTimeoutError(error.code)) {
          errorMessage =
            'The request has timed out, please refresh the page to proceed'
        }

        ErrorService.emitError({
          name: error.name,
          message: errorMessage,
          group: errorGroup,
        })

        // for test errors we close the stream in order to prevent
        // an infinite loop
        if (error.name === 'test-error') {
          // is important to return an action because it is an epic
          return of(errorHandledAction(error))
        }

        // "caught" - leads to retry()
        return caught
      })
    )
}

export function baseRequestScenario(
  actionType: Array<string>,
  preloader: PreloaderConstants,
  requestCallback: any,
  mapCallback: any,
  errorGroup: ErrorGroupConstants
) {
  return (source: Observable<any>): Observable<any> =>
    source.pipe(
      // it looks like - ofType('Some 1', 'Some 2')
      // @ts-ignore
      ofType(...actionType),
      onPreloader(preloader),
      switchMap(requestCallback),
      offPreloader(preloader),
      map(mapCallback),
      catchCustom(preloader, errorGroup)
    )
}

export function everyRequestScenario(
  actionType: Array<string>,
  preloader: PreloaderConstants,
  requestCallback: any,
  mapCallback: any,
  errorGroup: ErrorGroupConstants
) {
  return (source: Observable<any>): Observable<any> =>
    source.pipe(
      // it looks like - ofType('Some 1', 'Some 2')
      // @ts-ignore
      ofType(...actionType),
      onPreloader(preloader),
      mergeMap(requestCallback),
      offPreloader(preloader),
      map(mapCallback),
      catchCustom(preloader, errorGroup)
    )
}

// for these request scenarios we do not emit the error in the global
// scope, instead - we just show the toast
export function toastRequestScenario(
  actionType: Array<string>,
  preloader: PreloaderConstants,
  requestCallback: any,
  mapCallback: any,
  successMessageCallback: EngineFunction<ActionInterface, string>,
  errorMessageCallback: EngineFunction<ActionInterface, string>
) {
  return (source: Observable<any>): Observable<any> =>
    source.pipe(
      // @ts-ignore
      ofType(...actionType),

      mergeMap((action: ActionInterface) =>
        of(action).pipe(
          onPreloader(preloader),
          // for testing purposes
          map((newAction) => {
            if (newAction.error) {
              const error = new Error()
              error.name = 'test-error'
              throw error
            }
            return newAction
          }),
          switchMap(requestCallback),
          offPreloader(preloader),
          map(mapCallback),
          map((newAction) => {
            ToastHelper.success(successMessageCallback(action))
            return newAction
          }),
          catchError((error) => {
            StoreService.disablePreloader(preloader)
            ToastHelper.error(errorMessageCallback(action))
            if (error.name === 'test-error') {
              return of(errorHandledAction(error))
            }
            // eslint-disable-next-line no-console
            console.warn(error)
            return of(errorHandledAction(error))
          })
        )
      )
    )
}

// this function masks the server error and send
// this error with the original to the sentry
// anyway server side error for users useless
export const sentryReThrowCatchHandler = (message: string) => (err: Error) => {
  // anyway we are showing the original error
  // eslint-disable-next-line no-console
  console.error(err)

  SystemHelper.sendSentryIfProd(`${message} - [${err.message}]`)
  const overwrittenError = new Error(message)
  // some of our code relies on the "code" field
  // @ts-ignore
  overwrittenError.code = err.code
  // for debugging purposes
  // @ts-ignore
  overwrittenError.originalErrorMessage = message
  throw overwrittenError
}

//  this function doesn't change the error, just prints, rethrows
// and sends to the sentry
export const sentrySimpleReThrowHandler = (err: Error) => {
  // eslint-disable-next-line no-console
  console.error(err)

  SystemHelper.sendSentryIfProd(err.message)
  throw err
}
