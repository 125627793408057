import {
  ScheduledTenantJob,
  ScheduledTenantJobAttrs,
  ScheduledTenantJobKind,
} from '@features/scheduled-jobs-monitoring/models'
import Dexie from 'dexie'
import {
  AbstractScheduledJobsRepository,
  SCHEDULED_TENANT_JOBS,
} from './abstract-scheduled-jobs'

export type ModelId = string

export class ScheduledTenantJobsRepository extends AbstractScheduledJobsRepository<
  ScheduledTenantJobAttrs,
  ScheduledTenantJob,
  ModelId
> {
  async listByKinds(
    kinds: Array<ScheduledTenantJobKind>
  ): Promise<Array<ScheduledTenantJob>> {
    const result = await this.table.where('kind').anyOf(kinds).toArray()
    return result.map((ccJob) => this.instantiateModel(ccJob))
  }

  protected instantiateModel(
    attr: ScheduledTenantJobAttrs
  ): ScheduledTenantJob {
    return new ScheduledTenantJob(attr)
  }

  protected get table(): Dexie.Table<ScheduledTenantJobAttrs> {
    return this.db.table(this.tableName)
  }

  private get tableName(): string {
    return SCHEDULED_TENANT_JOBS
  }
}
