import { IconButton, styled } from '@mui/material'

export const StyledCopyIcon = styled(IconButton)<{ fontSize: number }>(
  ({ theme, fontSize }) => ({
    padding: 0,
    color: theme.palette.grey[700],
    fontSize: `${fontSize}px`,
    paddingLeft: '3px',
  })
)
