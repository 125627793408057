"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListGenericHostsInventory = void 0;
const models_1 = require("../../models");
const malware_1 = require("../../models/scans/malware");
const ransomware_1 = require("../../models/scans/ransomware");
const DEFAULT_STATE_OUTPUT = {
    genericHosts: [],
    summarizedData: { genericHostsCount: 0, threatsCount: 0 },
};
class ListGenericHostsInventory {
    #genericHosts;
    #threats;
    #lastBackups;
    #assetItemsListWithHostIds;
    #scans;
    #genericHostsWithScans;
    constructor({ genericHosts, threats, lastBackups, assetItemsListWithHostIds, scans, }) {
        this.#genericHosts = genericHosts;
        this.#threats = threats;
        this.#lastBackups = lastBackups;
        this.#assetItemsListWithHostIds = assetItemsListWithHostIds;
        this.#scans = scans;
        this.#genericHostsWithScans ||= this.#getGenericHostsWithScans();
    }
    execute(filters) {
        return this.#prepareOutput(this.#findGenericHostsMatchingFilters(filters));
    }
    #prepareOutput(genericHosts) {
        if (!genericHosts.length) {
            return DEFAULT_STATE_OUTPUT;
        }
        const genericHostsOutput = genericHosts.map((genericHost) => {
            const assetItemsCurrentGH = this.#assetItemsListWithHostIds?.get(genericHost.id) ?? [];
            if (!this.#assetItemsListWithHostIds) {
                return {
                    uuid: genericHost.id,
                    isInfected: false,
                    genericHostName: genericHost.hostname,
                    threats: 0,
                    lastScan: null,
                    lastBackup: null,
                };
            }
            return {
                uuid: genericHost.id,
                isInfected: this.#isInfected(assetItemsCurrentGH),
                genericHostName: genericHost.hostname,
                threats: this.#calculateThreatsCount(assetItemsCurrentGH),
                lastScan: this.#getLastScan(genericHost),
                lastBackup: this.#getLastBackup(genericHost.id),
            };
        });
        return {
            genericHosts: genericHostsOutput,
            summarizedData: this.#summarizeGenericHostData(this.#genericHosts, this.#getAllAssetItemsByGenericHosts()),
        };
    }
    #getAllAssetItemsByGenericHosts() {
        const allAssetItemsByGenericHosts = [];
        if (!this.#assetItemsListWithHostIds) {
            return [];
        }
        this.#assetItemsListWithHostIds.forEach((assetItems) => {
            allAssetItemsByGenericHosts.push(...assetItems);
        });
        return allAssetItemsByGenericHosts;
    }
    #isInfected(assetItems) {
        return this.#threats.some((threat) => assetItems.some((item) => threat.source.assetItem?.assetItemId === item.id));
    }
    #calculateThreatsCount(assetItems) {
        const threatsCount = this.#threats.reduce((acc, threat) => {
            assetItems?.forEach((item) => {
                if (threat.source.assetItem?.assetItemId === item.id) {
                    acc += 1;
                }
            });
            return acc;
        }, 0);
        return threatsCount;
    }
    #getLastBackup(genericHostId) {
        const lastBackup = this.#lastBackups.get(genericHostId);
        if (lastBackup) {
            return lastBackup.backup.timestamp;
        }
        return null;
    }
    #isRansomwareScanTarget(scanTarget, host) {
        if (!this.#assetItemsListWithHostIds) {
            return false;
        }
        const assetItems = this.#assetItemsListWithHostIds.get(host.id);
        if (!assetItems) {
            return false;
        }
        const assetItemsHaveScans = [];
        assetItems.forEach((assetItem) => {
            if (scanTarget instanceof ransomware_1.RansomwareScanTarget) {
                if ('assetItemId' in scanTarget.target &&
                    scanTarget.target.assetItemId === assetItem.id) {
                    assetItemsHaveScans.push(assetItem);
                }
                if (scanTarget.target instanceof ransomware_1.RansomwareScanBackup &&
                    scanTarget.target.source?.assetItem &&
                    scanTarget.target.source.assetItem?.backupAssetItemId === assetItem.id) {
                    assetItemsHaveScans.push(assetItem);
                }
            }
        });
        return assetItemsHaveScans.length > 0;
    }
    #isMalwareScanTarget(scanTarget, host) {
        if (!this.#assetItemsListWithHostIds) {
            return false;
        }
        const assetItems = this.#assetItemsListWithHostIds?.get(host.id);
        if (!assetItems) {
            return false;
        }
        const assetItemsHaveScans = [];
        assetItems?.forEach((assetItem) => {
            if (scanTarget instanceof malware_1.MalwareScanTarget) {
                if ('assetItemId' in scanTarget.target &&
                    scanTarget.target.assetItemId === assetItem.id) {
                    assetItemsHaveScans.push(assetItem);
                }
                if (scanTarget.target instanceof malware_1.MalwareScanBackup &&
                    scanTarget.target.source.assetItem &&
                    scanTarget.target.source.assetItem.backupAssetItemId === assetItem.id) {
                    assetItemsHaveScans.push(assetItem);
                }
            }
        });
        return assetItemsHaveScans.length > 0;
    }
    #isFilesystemScanTarget(scanTarget, host) {
        if (!this.#assetItemsListWithHostIds) {
            return false;
        }
        const assetItems = this.#assetItemsListWithHostIds.get(host.id);
        if (!assetItems) {
            return false;
        }
        const assetItemsHaveScans = [];
        assetItems.forEach((assetItem) => {
            if (scanTarget instanceof models_1.FilesystemScanTarget &&
                scanTarget.target instanceof models_1.FilesystemScanBackup &&
                scanTarget.target.source?.assetItem &&
                scanTarget.target.source.assetItem?.backupAssetItemId === assetItem.id) {
                return assetItemsHaveScans.push(assetItem);
            }
        });
        return assetItemsHaveScans.length > 0;
    }
    #filterScansForGenericHostsItems(host, scans) {
        return scans.filter((scan) => {
            const isRansomwareScanMatches = this.#isRansomwareScanTarget(scan.scanTarget, host);
            const isMalwareScanMatches = this.#isMalwareScanTarget(scan.scanTarget, host);
            const isFilesystemScanMatches = this.#isFilesystemScanTarget(scan.scanTarget, host);
            return (isRansomwareScanMatches ||
                isMalwareScanMatches ||
                isFilesystemScanMatches);
        });
    }
    #mapGenericHostItemsToScans(genericHost) {
        const scans = this.#filterScansForGenericHostsItems(genericHost, this.#scans);
        return { genericHost, scans };
    }
    #getGenericHostsWithScans() {
        return this.#genericHosts.map(this.#mapGenericHostItemsToScans.bind(this));
    }
    #getLastScan(host) {
        const scansForHost = this.#genericHostsWithScans.find((scan) => scan.genericHost.id === host.id);
        if (!scansForHost) {
            return null;
        }
        const scansTime = scansForHost.scans.map((scan) => scan.createdAt.getTime());
        if (!scansTime.length)
            return null;
        return new Date(Math.max(...scansTime));
    }
    #summarizeGenericHostData(genericHosts, assetItems) {
        const genericHostsCount = new Set(genericHosts.map(({ hostname }) => hostname)).size;
        const threatsCount = this.#calculateThreatsCount(assetItems);
        return {
            genericHostsCount,
            threatsCount,
        };
    }
    #findGenericHostsMatchingFilters(filters) {
        const { idSearch } = filters;
        return this.#genericHosts.filter((genericHost) => {
            if (idSearch &&
                !genericHost.hostname.toLowerCase().includes(filters.idSearch)) {
                return false;
            }
            return true;
        });
    }
}
exports.ListGenericHostsInventory = ListGenericHostsInventory;
