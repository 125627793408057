"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listS3BucketsWithActiveThreatsPossibleFilters = void 0;
const types_1 = require("../../models/threats/types");
class listS3BucketsWithActiveThreatsPossibleFilters {
    #s3BucketsList;
    #threats;
    constructor(parameters) {
        this.#s3BucketsList = parameters.s3BucketsList;
        this.#threats = parameters.threats;
    }
    execute() {
        const accountIds = new Set();
        const regions = new Set();
        const malware = new Set();
        this.#s3BucketsList.forEach((S3Bucket) => {
            const S3BucketThreats = this.#threats.filter(({ source: { asset, assetItem } }) => asset?.assetId === S3Bucket.id || assetItem?.assetId === S3Bucket.id);
            S3BucketThreats.forEach((threat) => {
                if (threat.kind === types_1.ThreatKind.MALWARE) {
                    malware.add(threat.name);
                }
            });
            accountIds.add(S3Bucket.awsAccountId);
            regions.add(S3Bucket.awsRegion);
        });
        return {
            possibleS3BucketsWithActiveThreatsFilters: {
                accountIds: [...accountIds],
                regions: [...regions],
                malwaresList: [...malware],
                idSearch: '',
            },
        };
    }
}
exports.listS3BucketsWithActiveThreatsPossibleFilters = listS3BucketsWithActiveThreatsPossibleFilters;
