import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'
import { TabViewInterface } from './types'

export interface TabsStoreActions {
  setTemporaryTab: (value: TabViewInterface) => void
  resetTab: () => void
}

export interface TabsStoreState {
  temporaryTab?: TabViewInterface
}

export const useTabsStore = create<
  TabsStoreState & { actions: TabsStoreActions }
>((set) => ({
  temporaryTab: undefined,
  actions: {
    setTemporaryTab: (value) =>
      set({
        temporaryTab: value,
      }),
    resetTab: () =>
      set({
        temporaryTab: undefined,
      }),
  },
}))

export const useTabsStoreActions = () =>
  useTabsStore(useShallow((state) => state.actions))

export const useGetTemporaryTab = () =>
  useTabsStore((state) => state.temporaryTab)
