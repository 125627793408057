import ValueInterface from '@lib/interfaces/value.interface'

export const REQUEST_DASHBOARD_DATA = 'REQUEST_DASHBOARD_DATA'
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA'

export const SET_ASSETS_EBS_VULNERABILITIES_SELECTED_FILTERS =
  'SET_ASSETS_EBS_VULNERABILITIES_SELECTED_FILTERS'

export const SET_SNAPSHOTS_VULNERABILITIES_SELECTED_FILTERS =
  'SET_SNAPSHOTS_VULNERABILITIES_SELECTED_FILTERS'

export const requestDashboardData = () => ({
  type: REQUEST_DASHBOARD_DATA,
})

export const setAssetsEbsVulnerabilitiesFilters = (
  payload: Array<ValueInterface>
) => ({
  type: SET_ASSETS_EBS_VULNERABILITIES_SELECTED_FILTERS,
  payload,
})

export const setSnapshotsVulnerabilitiesFilters = (
  payload: Array<ValueInterface>
) => ({
  type: SET_SNAPSHOTS_VULNERABILITIES_SELECTED_FILTERS,
  payload,
})
