export interface RecoveryPointAssetIdentifier {
  rpId: string
  assetId: string
}

export enum MENU {
  Restore = 'Restore',
  Mount = 'Mount',
  Scan = 'Scan',
}
