/* eslint-disable import/no-extraneous-dependencies */
import { decode, encode } from 'js-base64'
import {
  EngineObject,
  Nullable,
  StringConvertible,
  VIRow,
} from '@lib/engine-types'
import ArrHelper from '@lib/helpers/arr.helper'
import {
  FIFTY_MINUTE_MILLISECONDS,
  FIVE_HOURS_MILLISECONDS,
  FOUR_HOURS_MILLISECONDS,
  FORTY_MINUTE_MILLISECONDS,
  ONE_HOUR_MILLISECONDS,
  ONE_MINUTE_MILLISECONDS,
  SIX_HOURS_MILLISECONDS,
  TEN_MINUTE_MILLISECONDS,
  THIRTY_MINUTE_MILLISECONDS,
  THREE_HOURS_MILLISECONDS,
  TWENTY_MINUTE_MILLISECONDS,
  TWO_HOURS_MILLISECONDS,
} from '@lib/constants/time.constant'
import ValueInterface from '@lib/interfaces/value.interface'

import {
  Region,
  regionToHumanReadable,
} from '@lib/constants/aws-regions.constant'

import { EC2, EC2State, InstanceState, OsKind } from 'blues-corejs/dist'
import { EfsState } from 'blues-corejs/dist/models/assets/aws/efs/types'
import { S3BucketState } from 'blues-corejs/dist/models/assets/aws/s3-bucket/types'
import { AssetItemKind } from '@features/DashboardV4/common/types'
import { OsKindString } from '@features/DashboardV4/common'
import BaseDataConstant from '@lib/constants/data/base-data.constant'

export interface AssetKindParsedData extends ValueInterface {
  pathSlug: string
  pathLabel: string
}

class AssetKindParsedConstantInner extends BaseDataConstant<AssetKindParsedData> {
  get KIND_UNDEFINED(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: '',
      label: 'Undefined',
      value: -1,
    }
  }

  get KIND_AWS_EC2(): AssetKindParsedData {
    return {
      pathSlug: 'instances',
      pathLabel: 'Instances',
      name: 'ec2',
      label: 'EC2',
      value: 0,
    }
  }

  get KIND_AZURE_VM(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: 'azure_vm',
      label: '',
      value: 1,
    }
  }

  get KIND_VM_WARE_VM(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: 'vm_ware_vm',
      label: '',
      value: 2,
    }
  }

  get KIND_AWS_EBS(): AssetKindParsedData {
    return {
      pathSlug: 'volumes',
      pathLabel: 'Volumes',
      name: 'aws_ebs',
      label: 'EBS',
      value: 3,
    }
  }

  get KIND_AWS_EFS(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: 'aws_efs',
      label: 'EFS',
      value: 4,
    }
  }

  get KIND_GENERIC_HOST(): AssetKindParsedData {
    return {
      pathSlug: 'local-machine',
      pathLabel: 'Other Assets',
      name: 'generic_host',
      label: 'Generic Host',
      value: 5,
    }
  }

  get KIND_GENERIC_FS(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: 'generic_fs',
      label: 'Generic FS',
      value: 6,
    }
  }

  get KIND_S3_BUCKET(): AssetKindParsedData {
    return {
      pathSlug: 's3buckets',
      pathLabel: 'S3 Buckets',
      name: 's3_bucket',
      label: 'S3',
      value: 7,
    }
  }

  get KIND_AWS_S3_OBJECTS(): AssetKindParsedData {
    return {
      pathSlug: 's3objects',
      pathLabel: 'S3 Objects',
      name: 's3_objects',
      label: 'S3 Objects',
      value: 8,
    }
  }

  get KIND_ALL(): AssetKindParsedData {
    return {
      pathSlug: 'all',
      pathLabel: 'All',
      name: 'all',
      label: 'All',
      value: 999,
    }
  }

  public getByPathSlug(value: string): AssetKindParsedData {
    return this.getByField('pathSlug', value)
  }
}

const AssetKindParsedConstant = AssetKindParsedConstantInner.build(
  AssetKindParsedConstantInner,
  'KIND_'
)

abstract class StrHelper {
  public static toCamelCase(s: string): string {
    const result = s.replace(/([-_][a-z])/gi, ($1) =>
      $1.toUpperCase().replace('-', '').replace('_', '')
    )
    return result.charAt(0).toUpperCase() + result.slice(1)
  }

  public static camelToSnakeCase(s: string): string {
    return s
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase()
  }

  public static base64Encode(str: any): string {
    if (!str) {
      return ''
    }
    return encode(String(str))
  }

  public static base64Decode(str: any): string {
    if (!str) {
      return ''
    }
    return decode(String(str))
  }

  public static base64EncodeObj(obj: EngineObject): string {
    return StrHelper.base64Encode(JSON.stringify(obj))
  }

  public static base64DecodeObj(str: string): EngineObject {
    return JSON.parse(StrHelper.base64Decode(str))
  }

  public static isSubstring<T extends StringConvertible>(
    main: T,
    sub: string,
    subInLowerCase = false
  ): boolean {
    const subVal = subInLowerCase ? sub : sub.toLowerCase()
    return main.toString().toLowerCase().trim().includes(subVal.trim())
  }

  public static filterBySubstring<T extends StringConvertible>(
    arr: Array<T>,
    search: string
  ): Array<T> {
    const searchVal = search.toLowerCase()
    return arr.filter((row) => StrHelper.isSubstring(row, searchVal, true))
  }

  // used in checkboxes
  public static buildCompositeLabel(arr: Array<string>): string {
    const MAX = 14
    const SHORT_WORD_LENGTH = 4
    const result = arr.join('/')
    // out of limit
    if (result.length > MAX) {
      const index = result.indexOf('/')
      if (index !== -1 && index < MAX) {
        return `${result.slice(0, index + SHORT_WORD_LENGTH)}...`
      }
      return `${result.slice(0, MAX)}...`
    }
    return result
  }

  public static capitalize(value: string) {
    if (!value) {
      return ''
    }
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  public static buildHumanLabel(value: string): string {
    return value
      .toLowerCase()
      .split(/[\s\-_]+/)
      .map((v) => v.trim())
      .map(StrHelper.capitalize)
      .join(' ')
  }

  public static buildScopeLabels(scopeArr: Array<string>): Array<string> {
    const grouped: any = {}
    // grouping by labels
    scopeArr.forEach((scope: string) => {
      const [action, label] = scope.split(':')
      if (!action || !label) {
        return
      }
      if (!grouped[label]) {
        grouped[label] = []
      }
      ArrHelper.pushUnique(StrHelper.buildHumanLabel(action), grouped[label])
    })
    // creating results
    const result: Array<string> = []
    Object.keys(grouped).forEach((key) => {
      const scopeActions = grouped[key].join(', ')
      result.push(`${StrHelper.buildHumanLabel(key)} (${scopeActions})`)
    })
    return result
  }

  public static buildJsonLink(jsonStr: string): string {
    if (!jsonStr) {
      return jsonStr
    }
    let link
    try {
      link = encodeURI(JSON.stringify(JSON.parse(jsonStr), null, 2))
    } catch (e: any) {
      return ''
    }
    return `data:application/json;charset=utf-8,${link}`
  }

  // we suppose using this func in some code in DEV mode
  public static isValidJson(value: string) {
    try {
      JSON.parse(value)
      return true
    } catch (_e: any) {
      return false
    }
  }

  public static isNotValidJson(value: string) {
    return !StrHelper.isValidJson(value)
  }

  public static toScopeName(
    categoryName: string,
    permissionName: string
  ): string {
    return `${this.camelToSnakeCase(categoryName)}:${this.camelToSnakeCase(
      permissionName
    )}`
  }

  // it transform sentences into short abbreviations, like
  // Pouring rain -> PR
  public static createAbbreviation(sentence: string): string {
    return sentence
      .split(/[\s_-]/)
      .map((v) => v.trim())
      .filter((v) => !!v)
      .map((v) => v[0]?.toUpperCase() ?? '')
      .join('')
  }

  public static toSnakeCase(sentence: string): string {
    return sentence
      .split(/[\s_-]/)
      .map((v) => v.trim())
      .filter((v) => !!v)
      .map((v) => v.toLowerCase())
      .join('-')
  }

  public static humanFileSize(bytes: number, emptyResult = '0 bytes'): string {
    if (!bytes) {
      return emptyResult
    }
    const e = Math.floor(Math.log(bytes) / Math.log(1000))
    return (
      (bytes / Math.pow(1000, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B'
    )
  }

  public static humanFileSizeVi(
    bytes: number,
    emptyResult = {
      name: 'bytes',
      value: 0,
    }
  ): ValueInterface {
    if (!bytes) {
      return emptyResult
    }
    const e = Math.floor(Math.log(bytes) / Math.log(1000))
    return {
      name: ' KMGTP'.charAt(e) + 'b',
      value: (bytes / Math.pow(1000, e)).toFixed(2),
    }
  }

  public static realFileSize(bytes: number, emptyResult = '0 bytes'): string {
    if (!bytes) {
      return emptyResult
    }

    const e = Math.floor(Math.log(bytes) / Math.log(1024))
    return (
      (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'iB'
    )
  }

  public static realFileSizeVi(
    bytes: number,
    emptyResult = {
      name: 'bytes',
      value: 0,
    }
  ): ValueInterface {
    if (!bytes) {
      return emptyResult
    }
    const e = Math.floor(Math.log(bytes) / Math.log(1024))
    return {
      name: ' KMGTP'.charAt(e) + 'iB',
      value: (bytes / Math.pow(1024, e)).toFixed(2),
    }
  }

  public static toHumanBytes(
    bytes: number,
    emptyResult = '0 bytes',
    precision = 2
  ): string {
    if (bytes >= 1099511627776) {
      return `${(bytes / 1099511627776).toFixed(precision)} TiB`
    }
    if (bytes >= 1073741824) {
      return `${(bytes / 1073741824).toFixed(precision)} GiB`
    }
    if (bytes >= 1048576) {
      return `${(bytes / 1048576).toFixed(precision)} MiB`
    }
    if (bytes >= 1024) {
      return `${(bytes / 1024).toFixed(precision)} KiB`
    }
    if (bytes > 1) {
      return `${bytes} bytes`
    }
    if (bytes === 1) {
      return `${bytes} byte`
    }
    return emptyResult
  }

  /**
   * Converts bytes to gibibytes (GiB).
   * @param bytes The number of bytes to convert.
   * @param decimalPlaces Optional number of decimal places to round to.
   * @returns The equivalent number of gibibytes (GiB).
   */
  public static bytesToGiB(bytes: number, decimalPlaces = 0): number {
    const bytesInGiB = 1024 ** 3 // 1 GiB is 1024^3 bytes
    const gib = bytes / bytesInGiB
    const factor = 10 ** decimalPlaces
    return Math.round(gib * factor) / factor
  }

  // TODO: replace NumHelper.numberHash with this alternative
  public static makeHashNum(str: string): number {
    let hash = 0,
      i,
      chr,
      len

    if (str.length === 0) {
      return hash
    }

    for (i = 0, len = str.length; i < len; i++) {
      chr = str.charCodeAt(i)
      hash = (hash << 5) - hash + chr
      hash |= 0 // Convert to 32bit integer
    }

    return hash
  }

  public static buildWithOrdinalSuffix(n: number): string {
    const s: [string, string, string, string] = ['th', 'st', 'nd', 'rd']
    const v = n % 100
    return n + (s[(v - 20) % 10] || s[v] || s[0])
  }

  public static buildClassName(value: string, prefix = ''): string {
    const resultName = StrHelper.toCamelCase(value.replace(/\W/gi, ''))
    return `${prefix}${resultName}`
  }

  public static buildRestoreCLICommandRestore(
    kindLabel: string,
    instance: Nullable<EC2>,
    routerRecoveryPointId: string,
    tags: VIRow,
    selectedSubnet: string,
    selectedAvailabilityZone: string,
    selectedSecurityGroupIds: Array<string>
  ): string {
    let protectedObject = ''
    if (AssetKindParsedConstant.KIND_AWS_EC2.value === kindLabel && instance) {
      protectedObject = 'volume.getParsedCloudProviderVolumeId()'
      if (protectedObject) {
        protectedObject = ` ${protectedObject}`
      }
    }

    let tagsFormatted = ''
    if (tags.length > 0) {
      tagsFormatted = `${tags
        .map((t) =>
          t.value ? ` --tag '${t.name}=${t.value}'` : ` --tag '${t.name}'`
        )
        .join('')}`
    }

    let selectedSub = ''
    if (selectedSubnet) {
      selectedSub = ` --subnet-id ${selectedSubnet}`
    }

    let selectedSecurityGroups = ''
    if (selectedSecurityGroupIds.length) {
      selectedSecurityGroups = ` --sg-ids ${selectedSecurityGroupIds.join(' ')}`
    }

    let selectedAZone = ''
    if (selectedAvailabilityZone) {
      selectedAZone = ` --az ${selectedAvailabilityZone}`
    }

    return `elastio ${kindLabel} restore --rp ${routerRecoveryPointId}${protectedObject}${tagsFormatted}${selectedSub}${selectedSecurityGroups}${selectedAZone} --monitor`
  }

  public static isContainText(text: string, searchText: string): boolean {
    return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1
  }

  public static buildLastUpdatedTimeText(lastUpdatedTime: number): string {
    switch (true) {
      case lastUpdatedTime >= ONE_MINUTE_MILLISECONDS &&
        lastUpdatedTime < TEN_MINUTE_MILLISECONDS:
        return 'a few minutes ago'
      case lastUpdatedTime >= TEN_MINUTE_MILLISECONDS &&
        lastUpdatedTime < TWENTY_MINUTE_MILLISECONDS:
        return '10 minutes ago'
      case lastUpdatedTime >= TWENTY_MINUTE_MILLISECONDS &&
        lastUpdatedTime < THIRTY_MINUTE_MILLISECONDS:
        return '20 minutes ago'
      case lastUpdatedTime >= THIRTY_MINUTE_MILLISECONDS &&
        lastUpdatedTime < FORTY_MINUTE_MILLISECONDS:
        return '30 minutes ago'
      case lastUpdatedTime >= FORTY_MINUTE_MILLISECONDS &&
        lastUpdatedTime < FIFTY_MINUTE_MILLISECONDS:
        return '40 minutes ago'
      case lastUpdatedTime >= FIFTY_MINUTE_MILLISECONDS &&
        lastUpdatedTime < ONE_HOUR_MILLISECONDS:
        return '50 minutes ago'
      case lastUpdatedTime >= ONE_HOUR_MILLISECONDS &&
        lastUpdatedTime < TWO_HOURS_MILLISECONDS:
        return '1 hour ago'
      case lastUpdatedTime >= TWO_HOURS_MILLISECONDS &&
        lastUpdatedTime < THREE_HOURS_MILLISECONDS:
        return '2 hours ago'
      case lastUpdatedTime >= THREE_HOURS_MILLISECONDS &&
        lastUpdatedTime < FOUR_HOURS_MILLISECONDS:
        return '3 hours ago'
      case lastUpdatedTime >= FOUR_HOURS_MILLISECONDS &&
        lastUpdatedTime < FIVE_HOURS_MILLISECONDS:
        return '4 hours ago'
      case lastUpdatedTime >= FIVE_HOURS_MILLISECONDS &&
        lastUpdatedTime < SIX_HOURS_MILLISECONDS:
        return '5 hours ago'
      case lastUpdatedTime >= SIX_HOURS_MILLISECONDS:
        return 'more then 6 hours ago'
      default:
        return 'a few seconds ago'
    }
  }

  public static getOsNameByType(osKind: OsKind): string {
    switch (osKind) {
      case OsKind.LINUX:
        return 'Linux'
      case OsKind.WINDOWS:
        return 'Windows'
      default:
        return 'Other'
    }
  }

  public static getAssetItemNameByType(assetItemKind: AssetItemKind): string {
    switch (assetItemKind) {
      case AssetItemKind.KIND_DISK:
        return 'Disk'
      case AssetItemKind.KIND_FILE:
        return 'File'
      case AssetItemKind.KIND_STREAM:
        return 'Stream'
      case AssetItemKind.KIND_VOLUME:
        return 'Local volume'
      default:
        return ''
    }
  }

  public static getOsName(osKind: string): string {
    switch (osKind) {
      case OsKindString.LINUX:
        return 'Linux/UNIX'
      case OsKindString.WINDOWS:
        return 'Windows'
      default:
        return 'Other'
    }
  }

  public static getInstanceStateName(state: InstanceState): string {
    switch (state) {
      case InstanceState.INSTANCE_STATE_STOPPED:
        return 'Stopped'
      case InstanceState.INSTANCE_STATE_RUNNING:
        return 'Running'
      default:
        return 'Unknown'
    }
  }

  public static getEc2StateName(state: EC2State): string {
    switch (state) {
      case EC2State.TERMINATED:
        return 'Terminated'
      case EC2State.STOPPED:
        return 'Stopped'
      case EC2State.RUNNING:
        return 'Running'
      case EC2State.UNKNOWN:
        return 'Unknown'
      default:
        return 'Unrecognized'
    }
  }

  public static getEfsStateName(state: EfsState): string {
    switch (state) {
      case EfsState.IN_USE:
        return 'In use'
      case EfsState.DELETED:
        return 'Deleted'
      case EfsState.UNKNOWN:
        return 'Unknown'
      default:
        return 'Unrecognized'
    }
  }

  public static getS3BucketStateName(state: S3BucketState): string {
    switch (state) {
      case S3BucketState.IN_USE:
        return 'In use'
      case S3BucketState.DELETED:
        return 'Deleted'
      case S3BucketState.UNKNOWN:
        return 'Unknown'
      default:
        return 'Unrecognized'
    }
  }

  public static getHumanReadableRegion(region: Region) {
    return regionToHumanReadable[region] || ''
  }

  public static getOsTypeByName(osKind: string): OsKind {
    switch (osKind) {
      case 'Linux':
        return OsKind.LINUX
      case 'Windows':
        return OsKind.WINDOWS
      default:
        return OsKind.OTHER
    }
  }

  public static getAlphabetArray(): Array<string> {
    const alpha = Array.from(Array(26)).map((_e, i) => i + 65)
    const alphabet = alpha.map((x) => String.fromCharCode(x))

    return alphabet
  }

  public static generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0
        const v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      }
    )
  }
}

export default StrHelper
