import React from 'react'

function PauseResumeIcon() {
  return (
    <svg
      width="13"
      height="17"
      viewBox="0 0 13 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.875 2.125V17H0.125V2.125H4.375C4.375 1.83171 4.43034 1.55778 4.54102 1.30322C4.65169 1.04867 4.80387 0.821777 4.99756 0.622559C5.19124 0.42334 5.41536 0.271159 5.66992 0.166016C5.92448 0.0608724 6.20117 0.00553385 6.5 0C6.79329 0 7.06722 0.0553385 7.32178 0.166016C7.57633 0.276693 7.80322 0.428874 8.00244 0.622559C8.20166 0.816243 8.35384 1.04036 8.45898 1.29492C8.56413 1.54948 8.61947 1.82617 8.625 2.125H12.875ZM3.3125 4.25H9.6875V3.1875H7.5625V2.125C7.5625 1.97559 7.53483 1.83724 7.47949 1.70996C7.42415 1.58268 7.34945 1.47201 7.25537 1.37793C7.1613 1.28385 7.04785 1.20638 6.91504 1.14551C6.78223 1.08464 6.64388 1.05697 6.5 1.0625C6.35059 1.0625 6.21224 1.09017 6.08496 1.14551C5.95768 1.20085 5.84701 1.27555 5.75293 1.36963C5.65885 1.4637 5.58138 1.57715 5.52051 1.70996C5.45964 1.84277 5.43197 1.98112 5.4375 2.125V3.1875H3.3125V4.25ZM11.8125 3.1875H10.75V5.3125H2.25V3.1875H1.1875V15.9375H11.8125V3.1875ZM2.25 7.4375H6.5V8.5H2.25V7.4375ZM2.25 10.625H6.5V11.6875H2.25V10.625ZM2.25 13.8125H6.5V14.875H2.25V13.8125ZM9.84521 6.00146L10.5923 6.74854L8.625 8.71582L7.18896 7.27979L7.93604 6.53271L8.625 7.22168L9.84521 6.00146ZM9.84521 9.18896L10.5923 9.93604L8.625 11.9033L7.18896 10.4673L7.93604 9.72021L8.625 10.4092L9.84521 9.18896ZM9.84521 12.3765L10.5923 13.1235L8.625 15.0908L7.18896 13.6548L7.93604 12.9077L8.625 13.5967L9.84521 12.3765Z"
        fill="#32B6F3"
      />
    </svg>
  )
}

export default PauseResumeIcon
