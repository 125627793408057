import React, { createContext, ReactNode, useContext } from 'react'
import { AuthStrategy } from './types'
import { useCustomFlags } from '@components-context/feature-flags/use-custom-flags'
import { createAuthStrategy } from './auth-strategy'
import { LoadingScreen } from '@components-simple/loading-screen'
import { useFetchAuth0SpaKeys } from '@features/authentication/use-fetch-auth0-spa-keys'
import { useRouter } from 'next/router'

const AuthContext = createContext<AuthStrategy | undefined>(undefined)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { auth0Spa } = useCustomFlags()
  const { isLoading, spaAuthKeys } = useFetchAuth0SpaKeys()
  const router = useRouter()
  if (isLoading) {
    return <LoadingScreen />
  }

  const strategy = createAuthStrategy(auth0Spa, spaAuthKeys, router)

  return (
    <AuthContext.Provider value={strategy}>
      {strategy.wrapApp(children)}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthStrategy => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export const useAccessToken = () => {
  const auth = useAuth()
  return auth.getAccessToken()
}

export const useUser = () => {
  const auth = useAuth()
  return auth.getUser()
}
