import React, { CSSProperties, PropsWithChildren } from 'react'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import TableBody from '@mui/material/TableBody'
import { flexRender, Row } from '@tanstack/react-table'
import { TableCellStyled } from '@components/table/core/cell/styles'
import { WordBreak } from '@components/table'

const PROPS_TO_NOT_FORWARD = ['wordBreak']

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: var(--grey50);
  }
`
const EnhancedTableCellStyled = styled(TableCellStyled, {
  shouldForwardProp: (prop) => !PROPS_TO_NOT_FORWARD.includes(prop as string),
})<{
  wordBreak?: WordBreak
}>`
  white-space: nowrap;
  word-break: ${({ wordBreak }) => wordBreak ?? 'normal'};
`

EnhancedTableCellStyled.defaultProps = {
  size: 'small',
  height: '50px',
}

const warningStyle: CSSProperties = {
  backgroundColor: 'var(--yellow50)',
}

type Props<T> = {
  rows: Array<Row<T>>
  renderSubComponent?: (props: { row: Row<T> }) => React.ReactElement
  warningState?: (props: { row: Row<T> }) => boolean
}

function BodyTable<T>({
  rows = [],
  renderSubComponent,
  children,
  warningState,
}: PropsWithChildren<Props<T>>) {
  const shouldRenderSubComponent = (row: Row<T>) =>
    row.getIsExpanded() && renderSubComponent
  const getAdditionalRowStyle = (row: Row<T>): CSSProperties =>
    warningState && warningState({ row }) ? warningStyle : {}

  return (
    <TableBody>
      {rows.map((row, idxRow) => {
        const isRenderSubComponent = shouldRenderSubComponent(row)
        const additionalRowStyle: CSSProperties = getAdditionalRowStyle(row)

        return (
          <React.Fragment key={`${row.id}-${idxRow}`}>
            <StyledTableRow
              style={additionalRowStyle}
              className={`table-row-depth-${row.depth}`}
            >
              {row.getVisibleCells().map((cell, idxCell) => {
                return (
                  <EnhancedTableCellStyled
                    align={cell.column.columnDef.meta?.textAlign}
                    wordBreak={cell.column.columnDef.meta?.workBreak}
                    height={
                      cell.column.columnDef.meta?.height
                        ? `${cell.column.columnDef.meta?.height}px`
                        : ''
                    }
                    width={
                      cell.column.columnDef.meta?.width
                        ? `${cell.column.columnDef.meta?.width}px`
                        : ''
                    }
                    key={`${cell.id}-${idxCell}`}
                    hidden={cell.column.columnDef.meta?.hidden}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </EnhancedTableCellStyled>
                )
              })}
            </StyledTableRow>
            {isRenderSubComponent && renderSubComponent && (
              <StyledTableRow>
                <td colSpan={row.getVisibleCells().length}>
                  {renderSubComponent({ row })}
                </td>
              </StyledTableRow>
            )}
          </React.Fragment>
        )
      })}
      {/* Children prop where the observable element can be included */}
      {children}
    </TableBody>
  )
}

export default BodyTable
