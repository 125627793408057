import GrpcBaseService from '@lib/services/grpc/base/grpc-base.service'
import { RbacPromiseClient } from 'blue-stack-libs/users-grpc-libs/js/users/users_grpc_web_pb'
import UserRoleModel from '@lib/models/user-role.model'
import {
  CreateRoleRequest,
  DeleteRoleRequest,
  ListRolesRequest,
  UpdateRoleRequest,
} from 'blue-stack-libs/users-grpc-libs/js/users/messages/rbac_pb'
import UserFactory from '@lib/factories/user.factory'
import StrHelper from '@lib/helpers/str.helper'

class GrpcRbacService extends GrpcBaseService {
  protected static client = new RbacPromiseClient('', null, null)
  protected static clientName = 'Rbac'

  public static async listRoles(): Promise<Array<UserRoleModel>> {
    const request = new ListRolesRequest()
    const result = await this.handleQueryRetry(this.client.listRoles, request)
    return result
      .toObject()
      .rolesList.map(UserFactory.buildUserRole)
      .sort(
        (a: UserRoleModel, b: UserRoleModel) =>
          a.scopesList.length - b.scopesList.length
      )
  }

  public static async createRole(role: UserRoleModel): Promise<UserRoleModel> {
    const request = new CreateRoleRequest()
    request.setName(role.name)
    request.setScopesList(role.scopesList)
    request.setShortName(
      `${StrHelper.createAbbreviation(role.name)}-${Date.now()}`
    )
    const result = await this.handleQuery(this.client.createRole, request)
    return UserFactory.buildUserRole(result.toObject())
  }

  public static async updateRole(role: UserRoleModel): Promise<UserRoleModel> {
    const request = new UpdateRoleRequest()
    request.setRoleId(role.innerId)
    request.setShortName(
      `${StrHelper.createAbbreviation(role.name)}-${Date.now()}`
    )
    request.setName(role.name)
    request.setScopesList(role.scopesList)
    const result = await this.handleQuery(this.client.updateRole, request)
    return UserFactory.buildUserRole(result.toObject())
  }

  public static async deleteRole(id: string): Promise<any> {
    const request = new DeleteRoleRequest()
    request.setRoleId(id)
    const result = await this.handleQuery(this.client.deleteRole, request)
    return result.toObject()
  }
}

export default GrpcRbacService
