"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.s3BucketsFactory = void 0;
const fishery_1 = require("fishery");
const common_1 = require("./common");
const s3bucket_model_1 = require("../../../models/inventory.model/s3bucket.model");
class S3BucketsFactory extends fishery_1.Factory {
    withNoBackups() {
        return this.transient({
            threatStatus: null,
        });
    }
}
const s3BucketsFactory = S3BucketsFactory.define(({ sequence, transientParams }) => {
    const region = transientParams.region ?? (0, common_1.awsRegion)();
    const account = transientParams.accountId ?? (0, common_1.awsAccountId)();
    const s3BucketName = transientParams.name ?? (0, common_1.name)('S3Bucket', sequence);
    return new s3bucket_model_1.S3BucketModel({
        id: transientParams.id ?? (0, common_1.uuid)(),
        accountId: account,
        region,
        cloudConnectorId: transientParams.cloudConnectorId ?? (0, common_1.uuid)(),
        cloudConnectorAccountId: transientParams.cloudConnectorAccountId ?? (0, common_1.awsAccountId)(),
        cloudConnectorRegion: region,
        cloudConnectorAssetId: transientParams.cloudConnectorAssetId ??
            (0, common_1.awsCCAssetId)(account, region, s3BucketName, 'aws-s3'),
        name: s3BucketName,
        tags: transientParams.tags ?? new Map(),
        objectLockEnabled: transientParams.objectLockEnabled ?? false,
        publicReadAccess: transientParams.publicReadAccess ?? false,
        publicWriteAccess: transientParams.publicWriteAccess ?? false,
        objectVersioningEnabled: transientParams.objectVersioningEnabled ?? false,
        bucketPolicy: transientParams.bucketPolicy ?? (0, common_1.name)('BucketPolicy', sequence),
        loggingEnabled: transientParams.loggingEnabled ?? false,
        createdInAwsAt: transientParams.createdAt ?? Date.now(),
        createdAt: transientParams.createdAt ?? Date.now(),
        updatedAt: transientParams.createdAt ?? Date.now(),
        threatStatus: transientParams.threatStatus ?? null,
        coveredByPolicies: transientParams.coveredByPolicies ?? [],
        associatedRetentionPolicies: transientParams.associatedRetentionPolicies ?? [],
        coveredByPausedPolicies: transientParams.coveredByPausedPolicies ?? [],
        state: transientParams.state ?? 0,
        objectCount: transientParams.objectCount ?? 0,
        objectSize: transientParams.objectSize ?? 0,
    });
});
exports.s3BucketsFactory = s3BucketsFactory;
