"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobTenant = void 0;
const job_1 = require("../../job");
const types_1 = require("./types");
class JobTenant extends job_1.Job {
    #parentId;
    #name;
    #ccId;
    #statusMessage;
    #status;
    #kind;
    #startedAt;
    #finishedAt;
    #childrenList;
    constructor(parameters) {
        const { parentId, name, ccId, statusMessage, status, kind, startedAt, finishedAt, childrenList, ...jobParameters } = parameters;
        super(jobParameters);
        this.#parentId = parentId;
        this.#name = name;
        this.#ccId = ccId;
        this.#statusMessage = statusMessage;
        this.#status = status;
        this.#kind = kind;
        this.#startedAt = startedAt;
        this.#finishedAt = finishedAt;
        this.#childrenList = childrenList;
    }
    get parentId() {
        return this.#parentId;
    }
    get name() {
        return this.#name;
    }
    get ccId() {
        return this.#ccId;
    }
    get statusMessage() {
        return this.#statusMessage;
    }
    get status() {
        return this.#status;
    }
    get kind() {
        return this.#kind;
    }
    get startedAt() {
        return this.#startedAt;
    }
    get finishedAt() {
        return this.#finishedAt;
    }
    get childrenList() {
        return this.#childrenList;
    }
    get isJobInProgress() {
        return [
            types_1.TenantJobStatus.SCHEDULED,
            types_1.TenantJobStatus.STARTING,
            types_1.TenantJobStatus.INPROGRESS,
        ].includes(this.status);
    }
    get isFailed() {
        return this.status === types_1.TenantJobStatus.FAILED;
    }
    get isAborted() {
        return this.status === types_1.TenantJobStatus.ABORTED;
    }
}
exports.JobTenant = JobTenant;
