"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListDetailsS3RpForAssetItem = void 0;
class ListDetailsS3RpForAssetItem {
    execute({ backups, threats, }) {
        return this.#prepareOutput({ backups, threats });
    }
    #prepareOutput({ backups, threats, }) {
        const allBackups = [...backups.awsbRpsList, ...backups.elastioRpsList];
        const backupsOutput = allBackups.map((backup) => {
            const malwareThreats = this.#getThreatsNameByBackupId([...backup.threatIds], threats);
            return {
                isHealthy: true,
                malwareStatus: backup.malwareCheckStatus,
                completeTime: backup.createdAt,
                malwareNames: malwareThreats,
                backupModel: backup,
                id: backup.id,
            };
        });
        return {
            backups: backupsOutput,
        };
    }
    #getThreatsNameByBackupId(threatIds, threats) {
        return threats
            .filter((threat) => threatIds.includes(threat.id))
            .map((threat) => threat.name);
    }
}
exports.ListDetailsS3RpForAssetItem = ListDetailsS3RpForAssetItem;
