"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetItem = void 0;
class AssetItem {
    #ccAssetId;
    #assetId;
    #id;
    #createdAt;
    #updatedAt;
    constructor({ ccAssetId, assetId, id, createdAt, updatedAt, }) {
        this.#ccAssetId = ccAssetId;
        this.#assetId = assetId;
        this.#id = id;
        this.#createdAt = createdAt;
        this.#updatedAt = updatedAt;
    }
    get ccAssetId() {
        return this.#ccAssetId;
    }
    get assetId() {
        return this.#assetId;
    }
    get id() {
        return this.#id;
    }
    get createdAt() {
        return this.#createdAt;
    }
    get updatedAt() {
        return this.#updatedAt;
    }
}
exports.AssetItem = AssetItem;
