import React from 'react'

function LinkIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.24509 0.964988L4.72709 2.44599C4.58702 2.58524 4.50753 2.77413 4.50589 2.97165C4.50424 3.16916 4.58058 3.35934 4.71833 3.50091C4.85607 3.64247 5.0441 3.72398 5.24158 3.72774C5.43907 3.7315 5.63006 3.65721 5.77309 3.52099L7.30009 2.03299C7.47321 1.85304 7.68052 1.70948 7.90986 1.61074C8.13921 1.512 8.38597 1.46006 8.63566 1.45798C8.88535 1.45589 9.13294 1.5037 9.3639 1.59859C9.59487 1.69349 9.80455 1.83356 9.98064 2.01059C10.1567 2.18762 10.2957 2.39804 10.3894 2.6295C10.4831 2.86096 10.5296 3.1088 10.5261 3.35848C10.5227 3.60815 10.4695 3.85463 10.3696 4.08345C10.2696 4.31228 10.125 4.51883 9.94409 4.69099L8.46309 6.20999C8.39298 6.28022 8.33751 6.36367 8.29989 6.4555C8.26226 6.54733 8.24324 6.64572 8.24392 6.74495C8.24459 6.84419 8.26495 6.9423 8.30383 7.03361C8.3427 7.12492 8.3993 7.20761 8.47036 7.27688C8.54142 7.34615 8.62552 7.40064 8.71779 7.43717C8.81006 7.4737 8.90866 7.49156 9.00788 7.48971C9.1071 7.48786 9.20497 7.46634 9.29581 7.42639C9.38665 7.38644 9.46866 7.32886 9.53709 7.25699L11.0121 5.74499C11.6436 5.11127 11.9981 4.25312 11.9981 3.35849C11.9981 2.46386 11.6436 1.6057 11.0121 0.971988C10.3697 0.360358 9.517 0.0186402 8.62999 0.0173377C7.74298 0.0160352 6.8893 0.355247 6.24509 0.964988Z"
        fill="#32B6F3"
      />
      <path
        d="M6.22709 8.44601L4.70009 9.93501C4.52614 10.1116 4.31892 10.2519 4.0904 10.3478C3.86188 10.4438 3.6166 10.4935 3.36876 10.4941C3.12091 10.4947 2.87541 10.4461 2.64645 10.3512C2.41749 10.2563 2.20962 10.1169 2.03486 9.94116C1.8601 9.76541 1.72192 9.55676 1.62831 9.32726C1.5347 9.09777 1.48752 8.852 1.48949 8.60416C1.49147 8.35632 1.54256 8.11133 1.63981 7.88336C1.73706 7.65539 1.87855 7.44896 2.05609 7.27601L3.53709 5.75701C3.67318 5.61406 3.74744 5.42323 3.74376 5.22589C3.74008 5.02854 3.65876 4.84061 3.51743 4.70283C3.3761 4.56505 3.18615 4.48854 2.98878 4.48989C2.79141 4.49123 2.60253 4.57032 2.46309 4.71001L0.988086 6.22201C0.369044 6.85717 0.0249017 7.71047 0.0300871 8.59738C0.0352724 9.48429 0.389369 10.3335 1.0158 10.9614C1.64222 11.5893 2.49062 11.9453 3.37752 11.9525C4.26441 11.9598 5.11851 11.6176 5.75509 11L7.27309 9.52101C7.41316 9.38176 7.49265 9.19287 7.49429 8.99536C7.49593 8.79784 7.41959 8.60766 7.28185 8.4661C7.14411 8.32453 6.95608 8.24302 6.7586 8.23926C6.56111 8.2355 6.37012 8.3098 6.22709 8.44601Z"
        fill="#32B6F3"
      />
      <path
        d="M3.47008 8.51401C3.53958 8.5838 3.62218 8.63917 3.71313 8.67695C3.80408 8.71473 3.9016 8.73418 4.00008 8.73418C4.09857 8.73418 4.19609 8.71473 4.28704 8.67695C4.37799 8.63917 4.46059 8.5838 4.53008 8.51401L8.53008 4.51401C8.60175 4.44486 8.65892 4.36213 8.69827 4.27065C8.73762 4.17916 8.75836 4.08076 8.75927 3.98117C8.76018 3.88159 8.74125 3.78282 8.70358 3.69063C8.66592 3.59844 8.61027 3.51468 8.53988 3.44422C8.46949 3.37377 8.38578 3.31804 8.29363 3.28029C8.20147 3.24253 8.10272 3.22351 8.00314 3.22433C7.90355 3.22515 7.80513 3.24579 7.71361 3.28505C7.62209 3.32432 7.5393 3.38141 7.47008 3.45301L3.47008 7.45301C3.40039 7.52266 3.3451 7.60536 3.30738 7.69639C3.26966 7.78741 3.25024 7.88498 3.25024 7.98351C3.25024 8.08204 3.26966 8.17961 3.30738 8.27064C3.3451 8.36166 3.40039 8.44436 3.47008 8.51401Z"
        fill="#32B6F3"
      />
    </svg>
  )
}

export default LinkIcon
