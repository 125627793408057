import React from 'react'

function AssetItemFolderIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13725_163573)">
        <path
          d="M17.6738 5.14406C17.3197 4.79 16.849 4.59503 16.3483 4.59503H16.348L10.2948 4.59491C10.2944 4.59491 10.294 4.59491 10.2936 4.59491C10.1245 4.59491 9.96933 4.5135 9.8783 4.377L8.91862 2.9375H4.09802C3.06415 2.9375 2.22302 3.77862 2.22302 4.8125V14.8773C2.22302 15.9112 3.06415 16.7523 4.09802 16.7523H16.348C17.3819 16.7523 18.223 15.9112 18.223 14.8773V6.46991C18.223 5.96906 18.028 5.49819 17.6738 5.14406ZM3.47302 4.8125C3.47302 4.46787 3.7534 4.1875 4.09802 4.1875H8.24968L8.83827 5.07041C8.87408 5.12413 8.91262 5.17544 8.95355 5.22422C8.91346 5.27188 8.87577 5.32194 8.8408 5.37403L8.23796 6.25119L4.09837 6.25138H4.09799C3.88177 6.25138 3.67109 6.28775 3.47302 6.35756V4.8125ZM16.973 14.8773C16.973 15.2219 16.6926 15.5023 16.348 15.5023H4.09802C3.7534 15.5023 3.47302 15.2219 3.47302 14.8773V8.25116V8.12638C3.47302 7.95941 3.53805 7.80247 3.65612 7.68438C3.77415 7.56638 3.93108 7.50138 4.09815 7.50138H4.09827L8.89562 7.50112L9.87327 6.07866L9.87821 6.07137C9.96712 5.93803 10.1346 5.84491 10.2855 5.84491C10.2891 5.84491 10.2927 5.84488 10.2963 5.84491L16.3481 5.84503C16.515 5.845 16.6719 5.90997 16.79 6.028C16.908 6.14603 16.973 6.30297 16.973 6.46991V14.8773Z"
          fill="#4E5665"
        />
      </g>
      <defs>
        <clipPath id="clip0_13725_163573">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(2.22302 1.84375)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AssetItemFolderIcon
