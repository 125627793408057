import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import React, { useMemo } from 'react'
// eslint-disable-next-line css-modules/no-unused-class
import styles from './NameWithIdCopy.module.scss'
import SystemHelper from '@lib/helpers/system.helper'
import { Icon, IconButton, styled } from '@mui/material'
import clsx from 'clsx'

const StyledIcon = styled(Icon)({
  fontSize: '16px',
  color: '#1A1F28',
})

const StyledIconButton = styled(IconButton)({
  padding: '0',
})

interface Props {
  name: string
  id: string
  variant?: 'title' | 'tableCell'
  alternateId?: string
  maxLength?: number
}

const variants = {
  title: <Icon>content_copy</Icon>,
  tableCell: <StyledIcon>content_copy</StyledIcon>,
}

export function NameWithIdCopy({
  name,
  id,
  variant = 'title',
  alternateId,
  maxLength = 30,
}: Props) {
  const idForCopy = alternateId && alternateId.length ? alternateId : id
  const copy = () => {
    SystemHelper.copyToClipboard(idForCopy)
  }
  const icon = variants[variant]

  const displayedId = useMemo(() => (name && id ? `(${id})` : id), [name])

  return (
    <div className={styles.wrap}>
      <div className={clsx(styles[variant], styles.wrap)}>
        {name && <LongTextTooltip text={name} maxLength={maxLength} />}
        {displayedId && <div aria-label="Copy Text">{displayedId}</div>}
        <StyledIconButton onClick={copy}>{icon}</StyledIconButton>
      </div>
    </div>
  )
}
