"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ovaAccountFactory = void 0;
const fishery_1 = require("fishery");
const ova_account_1 = require("../../models/ova-account");
const factories_1 = require("../../use_cases/tests/factories");
const common_1 = require("./common");
const ova_backup_provider_1 = require("../../models/ova-backup-provider");
class OvaAccountFactory extends fishery_1.Factory {
}
const DEFAULT_BACKUP_PROVIDERS_COUNT = 10;
exports.ovaAccountFactory = OvaAccountFactory.define(({ transientParams, sequence }) => {
    return new ova_account_1.OvaAccount({
        id: transientParams.id ?? (0, factories_1.uuid)(),
        name: transientParams.name ?? `ova-account-${sequence}`,
        backupProvidersList: transientParams.backupProvidersList ??
            new Array(DEFAULT_BACKUP_PROVIDERS_COUNT).fill((0, common_1.randomValueFromEnum)(ova_backup_provider_1.OvaBackupProvider)),
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
    });
});
