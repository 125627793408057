import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

function LoginLayout({ children }: Props) {
  return (
    <div className="wrap-1602571821791 jsLoginLayout jsLayout">
      <main className="paper paper-big">{children}</main>
    </div>
  )
}

export default LoginLayout
