import PaginatedInterface from '@lib/interfaces/paginated.interface'
import { RetentionPolicyPriority } from '@lib/interfaces/retention-policies.interface'
import RetentionPolicyModel from '@lib/models/retention-policy.model'

export const SET_POLICIES_RETENTION = 'SET_POLICIES_RETENTION'
export const REQUEST_POLICIES_RETENTION = 'REQUEST_POLICIES_RETENTION'
export const SET_POLICIES_RETENTION_PAGINATION =
  'SET_POLICIES_RETENTION_PAGINATION'
export const DELETE_POLICY_RETENTION = 'DELETE_POLICY_RETENTION'
export const UPDATE_STATUS_POLICY_RETENTION = 'UPDATE_STATUS_POLICY_RETENTION'
export const SET_CUSTOM_RETENTION_POLICY_PRIORITIES =
  'SET_CUSTOM_RETENTION_POLICY_PRIORITIES'

export const requestPoliciesRetention = () => ({
  type: REQUEST_POLICIES_RETENTION,
  payload: null,
})

export const setPoliciesRetention = (
  policies: PaginatedInterface<RetentionPolicyModel>
) => ({
  type: SET_POLICIES_RETENTION,
  payload: policies,
})

export const deletePolicyRetention = (policyId: string) => ({
  type: DELETE_POLICY_RETENTION,
  payload: policyId,
})

export const updateStatusPolicyRetention = (policy: RetentionPolicyModel) => ({
  type: UPDATE_STATUS_POLICY_RETENTION,
  payload: policy,
})

export const setCustomRetentionPolicyPriorities = (
  policies: Array<RetentionPolicyPriority>
) => ({
  type: SET_CUSTOM_RETENTION_POLICY_PRIORITIES,
  payload: policies,
})
