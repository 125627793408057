/* eslint-disable import/no-extraneous-dependencies */
import TimeHelper from '@lib/helpers/time.helper'
import { EfsItem as EfItemProto } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/asset_items/efs_item_pb'
import { EfsItem } from 'blues-corejs/dist'
import {
  Selector,
  SelectorKind,
} from 'blues-corejs/dist/models/asset-items/efs-item/types'

type EfsItemPt = EfItemProto.AsObject

export class EfsItemTransformer {
  #efsItem: EfsItemPt

  constructor(efsItem: EfsItemPt) {
    this.#efsItem = efsItem
  }

  transform(): EfsItem {
    return this.#transformEfsItem(this.#efsItem)
  }

  #transformEfsItem(efsItem: EfsItemPt): EfsItem {
    return new EfsItem({
      assetId: efsItem.efsId,
      id: efsItem.id,
      name: efsItem.name,
      efsId: efsItem.efsId,
      ccAssetId: efsItem.ccAssetId,
      selectors: this.#convertSelectors(efsItem.selectorsList),
      createdAt: TimeHelper.secondsToDate(efsItem.createdAt?.seconds),
      updatedAt: TimeHelper.secondsToDate(efsItem.updatedAt?.seconds),
    })
  }

  #convertSelectors(
    selectors: Array<EfItemProto.Selector.AsObject>
  ): Array<Selector> {
    return selectors.map((selector) => ({
      kind: this.#convertSelectorKind(selector.kind),
      value: selector.value,
    }))
  }

  #convertSelectorKind(kind: EfItemProto.Selector.Kind): SelectorKind {
    const mapping = {
      [EfItemProto.Selector.Kind.KIND_GLOB]: SelectorKind.GLOB,
      [EfItemProto.Selector.Kind.KIND_PREFIX]: SelectorKind.PREFIX,
      [EfItemProto.Selector.Kind.KIND_PATH]: SelectorKind.PATH,
    }

    return mapping[kind]
  }
}
