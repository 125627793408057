import { getViewsFromConfig } from '@features/DashboardV4'
import {
  DEFAULT_VIEWS,
  TabViewInterface,
} from '@features/DashboardV4/management-view'
import { useRouter } from 'next/router'
import { useGetTemporaryTab } from '@features/DashboardV4/management-view/tabs-store'

function useCurrentView(): TabViewInterface {
  const router = useRouter()

  const temporaryTab = useGetTemporaryTab()

  const defaultViews = [...DEFAULT_VIEWS, ...getViewsFromConfig()]

  const viewsToDisplay = temporaryTab
    ? [...defaultViews, temporaryTab]
    : defaultViews

  const view = viewsToDisplay[Number(router.query.t)]
  const isViewDefaultDashboard = viewsToDisplay.find(
    (value) => value.isDefaultDashboard
  )

  return (
    view || isViewDefaultDashboard || (DEFAULT_VIEWS[0] as TabViewInterface)
  )
}

export default useCurrentView
