"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwsBackup = void 0;
const backup_1 = require("../backup");
class AwsBackup extends backup_1.Backup {
    #tags;
    constructor({ tags, ...restAttrs }) {
        super(restAttrs);
        this.#tags = tags;
    }
    get tags() {
        return [...this.#tags];
    }
}
exports.AwsBackup = AwsBackup;
