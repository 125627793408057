"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scanMalwareFactory = void 0;
const fishery_1 = require("fishery");
const malware_1 = require("../../models/scans/malware");
const factories_1 = require("../../use_cases/tests/factories");
const common_1 = require("./common");
const types_1 = require("../../models/scans/malware/types");
class ScanMalwareFactory extends fishery_1.Factory {
    withScanTarget(scanTarget) {
        return this.transient({
            scanTarget,
        });
    }
}
const malwareStatus = (0, common_1.randomValueFromEnum)(types_1.MalwareScanStatus);
const summary = () => ({
    errors: Math.floor(Math.random() * 100 + 1),
    totalFiles: Math.floor(Math.random() * 100 + 1),
    cleanFiles: Math.floor(Math.random() * 100 + 1),
    infectedFiles: Math.floor(Math.random() * 100 + 1),
    suspiciousFiles: Math.floor(Math.random() * 100 + 1),
    encryptedFiles: Math.floor(Math.random() * 100 + 1),
    incompleteFiles: Math.floor(Math.random() * 100 + 1),
    corruptedFiles: Math.floor(Math.random() * 100 + 1),
    serverErrors: Math.floor(Math.random() * 100 + 1),
});
function scanTarget() {
    return new malware_1.MalwareScanTarget({ assetId: (0, factories_1.uuid)() });
}
exports.scanMalwareFactory = ScanMalwareFactory.define(({ transientParams }) => {
    return new malware_1.MalwareScan({
        id: transientParams.id ?? (0, factories_1.uuid)(),
        scanTarget: transientParams.scanTarget ?? scanTarget(),
        status: transientParams.status ?? malwareStatus,
        jobId: transientParams.jobId ?? (0, factories_1.uuid)(),
        finishedAt: transientParams.finishedAt ?? (0, common_1.date)(),
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
        summary: transientParams.summary ?? summary(),
        threatIds: transientParams.threatIds ?? [],
    });
});
