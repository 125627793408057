// took from ./node_modules/blue-stack-libs/rex-grpc-libs/js/rex/messages/list_redstacks_pb.d.ts
// and extended

enum RedStackStatusConstant {
  UNDEFINED = -1,
  ACTIVE = 0,
  INACTIVE = 1,
  DELETED = 2,
  UPGRADE_REQUIRED = 3,
}

export default RedStackStatusConstant
