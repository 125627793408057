/* eslint-disable import/no-extraneous-dependencies */
import TimeHelper from '@lib/helpers/time.helper'
import { Scan as FilesystemScanProto } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/scans/filesystem_check/scan_pb'
import { FilesystemScanCheck } from 'blues-corejs/dist'
import {
  FilesystemScanBackup,
  FilesystemScanTarget,
} from 'blues-corejs/dist/models/scans/filesystem-check'
import {
  BackupSource,
  CheckStatus,
  Partition,
  Provider,
  StatusScanFilesystemCheck,
} from 'blues-corejs/dist/models/scans/filesystem-check/types'

type FilesystemScanPt = FilesystemScanProto.AsObject
const PartitionStatusPt = FilesystemScanProto.Partition.CheckStatus
const ProvidersPt = FilesystemScanProto.ScanTarget.Backup.Provider

export class FilesystemScanTransformer {
  #scan: FilesystemScanPt

  constructor(scan: FilesystemScanPt) {
    this.#scan = scan
  }

  transform(): FilesystemScanCheck {
    return this.#transformFilesystemScan(this.#scan)
  }

  #transformFilesystemScan(scan: FilesystemScanPt): FilesystemScanCheck {
    return new FilesystemScanCheck({
      id: scan.id,
      jobId: scan.jobId,
      threatIds: scan.threatIdsList,
      status: this.#convertStatus(scan.status),
      scanTarget: this.#convertScanTarget(
        scan.scanTarget as FilesystemScanProto.ScanTarget.AsObject
      ),
      createdAt: TimeHelper.secondsToDate(scan.createdAt?.seconds),
      updatedAt: TimeHelper.secondsToDate(scan.updatedAt?.seconds),
      finishedAt: TimeHelper.secondsToDate(scan.finishedAt?.seconds),
      partitions: this.#convertPartitions(scan.paratitionsList),
    })
  }

  // Scan target can not be undefined
  #convertScanTarget(
    scanTarget: FilesystemScanProto.ScanTarget.AsObject
  ): FilesystemScanTarget {
    const { asset, backup } = scanTarget

    const assetId = asset?.id

    if (assetId) {
      return new FilesystemScanTarget({
        assetId: assetId,
      })
    }

    if (backup) {
      const sourceAssetId = backup.source?.asset?.id

      const sourceAssetItemId = backup.source?.assetItem?.id
      const sourceAssetItemAssetId = backup.source?.assetItem?.assetId

      const backupSource: Partial<BackupSource> = {}

      if (sourceAssetId) {
        backupSource.asset = {
          backupAssetId: sourceAssetId,
        }
      }

      if (sourceAssetItemId && sourceAssetItemAssetId) {
        backupSource.assetItem = {
          backupAssetItemId: sourceAssetItemId,
          backupAssetId: sourceAssetItemAssetId,
        }
      }

      return new FilesystemScanTarget(
        new FilesystemScanBackup({
          backupId: backup.id,
          provider: this.#convertBackupProvider(backup.provider),
          source: backupSource as BackupSource,
          timestamp: TimeHelper.secondsToDate(backup.timestamp?.seconds),
        })
      )
    }

    throw new Error('Invalid scan target')
  }

  #convertBackupProvider(
    provider: FilesystemScanProto.ScanTarget.Backup.Provider
  ): Provider {
    const mapping: Record<
      FilesystemScanProto.ScanTarget.Backup.Provider,
      Provider
    > = {
      [ProvidersPt.PROVIDER_AWS]: Provider.AWS,
      [ProvidersPt.PROVIDER_ELASTIO]: Provider.ELASTIO,
      [ProvidersPt.OVA_VEEAM]: Provider.OVA_VEEAM,
      [ProvidersPt.OVA_COMMVAULT]: Provider.OVA_COMMVAULT,
      [ProvidersPt.OVA_RUBRIK]: Provider.OVA_RUBRIK,
      [ProvidersPt.OVA_COHESITY]: Provider.OVA_COHESITY,
      [ProvidersPt.OVA_VERITAS_NET_BACKUP]: Provider.OVA_VERITAS_NET_BACKUP,
      [ProvidersPt.OVA_AWS_BACKUP_VMWARE]: Provider.OVA_AWS_BACKUP_VMWARE,
    }

    return mapping[provider]
  }

  #convertStatus(
    status: FilesystemScanProto.Status
  ): StatusScanFilesystemCheck {
    const mapping = {
      [FilesystemScanProto.Status.STATUS_BAD]: StatusScanFilesystemCheck.BAD,
      [FilesystemScanProto.Status.STATUS_GOOD]: StatusScanFilesystemCheck.GOOD,
    }

    return mapping[status]
  }

  #convertPartitions(
    partitions: Array<FilesystemScanProto.Partition.AsObject>
  ): Array<Partition> {
    return partitions.map((partition) => ({
      checkStatus: this.#convertCheckStatus(partition.checkStatus),
      errorMessage: partition.errorMessage,
      filesystem: partition.filesystem,
      name: partition.name,
      filesNumber: partition.filesNumber,
      dirsNumber: partition.dirsNumber,
      bytesFree: partition.bytesFree,
      bytesUsed: partition.bytesUsed,
      fsSize: partition.fsSize,
    }))
  }

  #convertCheckStatus(
    status: FilesystemScanProto.Partition.CheckStatus
  ): CheckStatus {
    const mapping = {
      [PartitionStatusPt.CHECK_STATUS_NO_FILESYSTEM]: CheckStatus.NO_FILESYSTEM,
      [PartitionStatusPt.CHECK_STATUS_FILESYSTEM_IS_NOT_SUPPORTED]:
        CheckStatus.FILESYSTEM_IS_NOT_SUPPORTED,
      [PartitionStatusPt.CHECK_STATUS_ABORTED]: CheckStatus.ABORTED,
      [PartitionStatusPt.CHECK_STATUS_FAILED]: CheckStatus.FAILED,
      [PartitionStatusPt.CHECK_STATUS_NO_ERRORS]: CheckStatus.NO_ERRORS,
      [PartitionStatusPt.CHECK_STATUS_ERRORS_FIXED]: CheckStatus.ERRORS_FIXED,
      [PartitionStatusPt.CHECK_STATUS_ERRORS_NOT_FIXED]:
        CheckStatus.ERRORS_NOT_FIXED,
    }

    return mapping[status]
  }
}
