import React from 'react'

function UserIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.75 27C17.8802 27 16.1224 26.2719 14.8002 24.9498C13.4781 23.6276 12.75 21.8698 12.75 20C12.75 18.1302 13.4781 16.3724 14.8002 15.0502C16.1224 13.7281 17.8802 13 19.75 13C21.6198 13 23.3776 13.7281 24.6998 15.0502C26.0219 16.3724 26.75 18.1302 26.75 20C26.75 21.8698 26.0219 23.6276 24.6998 24.9498C23.3776 26.2719 21.6198 27 19.75 27ZM19.75 14.0938C16.4933 14.0938 13.8438 16.7433 13.8438 20C13.8438 23.2567 16.4933 25.9062 19.75 25.9062C23.0067 25.9062 25.6562 23.2567 25.6562 20C25.6562 16.7433 23.0067 14.0938 19.75 14.0938ZM19.7227 20.5195C18.2752 20.5195 17.0977 19.342 17.0977 17.8945C17.0977 16.4471 18.2752 15.2695 19.7227 15.2695C21.1701 15.2695 22.3477 16.4471 22.3477 17.8945C22.3477 19.342 21.1701 20.5195 19.7227 20.5195ZM19.7227 16.3633C18.8783 16.3633 18.1914 17.0502 18.1914 17.8945C18.1914 18.7389 18.8783 19.4258 19.7227 19.4258C20.567 19.4258 21.2539 18.7389 21.2539 17.8945C21.2539 17.0502 20.567 16.3633 19.7227 16.3633ZM23.1874 23.7043C23.4211 23.5129 23.4554 23.1684 23.264 22.9347C22.4907 21.9907 21.3481 21.4492 20.1291 21.4492H19.4803C18.2613 21.4492 17.1187 21.9907 16.3454 22.9347C16.154 23.1684 16.1883 23.5129 16.422 23.7043C16.6556 23.8957 17.0002 23.8614 17.1916 23.6278C17.7562 22.9384 18.5904 22.543 19.4803 22.543H20.1291C21.0189 22.543 21.8531 22.9384 22.4178 23.6278C22.526 23.7598 22.6829 23.8281 22.8412 23.8281C22.9631 23.8281 23.0858 23.7876 23.1874 23.7043Z"
        fill="#EAEFF3"
      />
    </svg>
  )
}

export default UserIcon
