import { PageHelper, StrHelper, SystemHelper } from '@lib/helpers'
import {
  ASSET_ID_QUERY_KEY,
  CLOUD_PROVIDER_ASSET_ID_QUERY_KEY,
  PagePathConstant,
} from '@lib/constants'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Asset, EBS, EC2 } from 'blues-corejs/dist'
import pagePathConstant from '@lib/constants/page-path.constant'

function composeRecoveryPointUrl({
  rpId,
  assetId,
  path,
}: {
  rpId: string
  assetId: string
  path: string
}) {
  const encodedAssetId = StrHelper.base64Encode(assetId)

  return `${path}?${CLOUD_PROVIDER_ASSET_ID_QUERY_KEY}=${encodedAssetId}&uuid=${rpId}`
}

export function getRecoveryPointMountUrl(asset: Asset, rpId: string) {
  if (asset instanceof EC2) {
    return composeRecoveryPointUrl({
      assetId: asset.id,
      rpId,
      path: PagePathConstant.MOUNT_EC2_RECOVERY_POINT,
    })
  }

  if (asset instanceof EBS) {
    return composeRecoveryPointUrl({
      assetId: asset.id,
      rpId,
      path: PagePathConstant.MOUNT_EBS_RECOVERY_POINT,
    })
  }

  SystemHelper.sendSentryIfProd(
    `Asset type not supported for restore: ${asset.id}`
  )

  throw new Error(`Asset type not supported for mount: ${asset.id}`)
}

export function getRecoveryPointRestoreUrl(asset: Asset, rpId: string) {
  if (asset instanceof EC2) {
    return `${PageHelper.buildUrl(
      pagePathConstant.RESTORE_INSTANCE,
      rpId
    )}?${ASSET_ID_QUERY_KEY}=${StrHelper.base64Encode(asset.id)}`
  }

  if (asset instanceof EBS) {
    return `${PageHelper.buildUrl(
      pagePathConstant.RESTORE_VOLUME,
      rpId
    )}?${ASSET_ID_QUERY_KEY}=${StrHelper.base64Encode(asset.id)}`
  }

  SystemHelper.sendSentryIfProd(
    `Asset type not supported for restore: ${asset.id}`
  )
  throw new Error(`Asset type not supported for restore: ${asset.id}`)
}
