/* eslint-disable import/no-extraneous-dependencies */
import { BackupsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import {
  Request as ListBackupsForAssetItemsRequest,
  Response,
} from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/backups/list_backups_for_asset_item_pb'
import { GrpcClient } from '@lib/clients/grpc-client'
import {
  AWSBRecoveryPointTransformer,
  EbsSnapshotTransformer,
  ElastioRecoveryPointTransformer,
} from '@lib/clients/assets/transformers'
import { ListBackupsForAssetItem } from '@lib/clients/backups/list-backups-for-asset-items/types'
import { AbstractListRequest } from '@lib/clients/list-request'
import { Pagination } from '@lib/clients/types'

export class ListBackupsForAssetItemsClient extends GrpcClient<BackupsPromiseClient> {
  #assetsClient: BackupsPromiseClient

  constructor(hostName = '') {
    super()
    this.#assetsClient = this.getClient(hostName)
  }

  protected initClient(hostName: string): BackupsPromiseClient {
    return new BackupsPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'ListBackupsForAssetItemsClient'
  }

  async list(
    request: AbstractListRequest<ListBackupsForAssetItemsRequest>
  ): Promise<ListBackupsForAssetItem & Pagination> {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#assetsClient.listBackupsForAssetItem(
            request.createGrpcRequest(),
            this.metadata()
          ),
        {
          requestName: 'BackupsPromiseClient/listBackupsForAssetItem',
        }
      )
    ).toObject()

    return {
      ...this.#transformResponse(response),
      pageToken: response.pagination?.nextPageToken,
    }
  }

  #transformResponse(response: Response.AsObject): ListBackupsForAssetItem {
    return {
      awsbRpsList: response.awsbRpsList.map((rp) =>
        new AWSBRecoveryPointTransformer(rp).transform()
      ),
      ebsSnapshotsList: response.ebsSnapshotsList.map((snapshot) =>
        new EbsSnapshotTransformer(snapshot).transform()
      ),
      elastioRpsList: response.elastioRpsList.map((rp) =>
        new ElastioRecoveryPointTransformer(rp).transform()
      ),
    }
  }
}
