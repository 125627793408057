import {
  DISCONNECT_SLACK_FROM_TENANT,
  requestTenantNotificationsSettings,
  REQUEST_TENANT_NOTIFICATIONS_SETTINGS,
  REQUEST_TENANT_NOTIFICATIONS_SETTINGS_SHADOW,
  setTenantNotificationsSettings,
  UPDATE_TENANT_NOTIFICATIONS_SETTINGS,
} from './../actions/tenant-notifications.action'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import PreloaderConstants from '@lib/constants/preloader.constant'
import { baseRequestScenario, sentryReThrowCatchHandler } from './epic-func'
import ActionInterface from '@lib/interfaces/action.interface'
import GrpcTenantNotificationService from '@lib/services/grpc/notifications/grpc-tenant-notifications.service'
import { emptyAction } from '@store/actions/default/empty.action'
import TenantNotificationsState from '@store/states/tenant-notifications.state'
import ToastHelper from '@lib/helpers/toast.helper'

export const requestTenantNotificationsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_TENANT_NOTIFICATIONS_SETTINGS],
      PreloaderConstants.REQUEST_TENANT_NOTIFICATIONS_SETTINGS,
      () =>
        GrpcTenantNotificationService.getTenantNotificationSettings().catch(
          sentryReThrowCatchHandler('Cannot get a tenant notification settings')
        ),
      (result: TenantNotificationsState) =>
        setTenantNotificationsSettings(result),
      ErrorGroupConstants.TENANT_NOTIFICATIONS
    )
  )

export const updateTenantNotificationsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [UPDATE_TENANT_NOTIFICATIONS_SETTINGS],
      PreloaderConstants.UPDATE_TENANT_NOTIFICATIONS_SETTINGS,
      (action: ActionInterface) =>
        GrpcTenantNotificationService.updateTenantNotificationSettings(
          action.payload
        ).catch(
          sentryReThrowCatchHandler(
            'Cannot update a tenant notification settings'
          )
        ),
      () => {
        ToastHelper.success('The new settings have been saved')
        return requestTenantNotificationsSettings()
      },
      ErrorGroupConstants.TENANT_NOTIFICATIONS
    )
  )

export const requestTenantNotificationsShadowEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_TENANT_NOTIFICATIONS_SETTINGS_SHADOW],
      PreloaderConstants.EMPTY,
      () => {
        return GrpcTenantNotificationService.getTenantNotificationSettings()
      },
      (result: TenantNotificationsState) => {
        if (result.slackNotificationSettings) {
          return setTenantNotificationsSettings(result)
        }
        return emptyAction()
      },
      ErrorGroupConstants.TENANT_NOTIFICATIONS
    )
  )

export const disconnectSlackFromTenantEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [DISCONNECT_SLACK_FROM_TENANT],
      PreloaderConstants.DISCONNECT_SLACK_FROM_TENANT,
      () => {
        return GrpcTenantNotificationService.disconnectSlackFromTenant()
      },
      () => {
        ToastHelper.success('Slack has been disconnected')
        return requestTenantNotificationsSettings()
      },
      ErrorGroupConstants.TENANT_NOTIFICATIONS
    )
  )
