import { SortDirection as SortDirectionType } from '@tanstack/react-table'
import { RotatingIcon } from '@components/table/core/rotating-icon'
import { ArrowDropIcon } from '@components/table/icons'
import { SortOrder } from '@lib/interfaces/tables/sort.interface'

type SortDirectionOwnProps = {
  isSortable?: boolean
  nextSortingOrder: SortDirectionType | false
}

function SortDirection({
  isSortable,
  nextSortingOrder,
}: SortDirectionOwnProps) {
  if (!isSortable) {
    return null
  }

  const isAscOrderSorting =
    nextSortingOrder === SortOrder.ASC || nextSortingOrder === false

  // Ensure RotatingIcon can handle the Icon prop or adjust this line accordingly
  return <RotatingIcon isRotated={isAscOrderSorting} Icon={ArrowDropIcon} />
}

export default SortDirection
