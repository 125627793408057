enum Modal {
  manageUsersAddUser,
  manageUsersEditUser,
  manageGroupsAddGroup,
  manageGroupsEditGroup,
  manageUsersRemoveUser,
  manageUsersDisableUser,

  notificationsRemoveEndPoint,
  manageStorageIDRemove,
  manageStorageRemove,
  assetFilter,
  revokeToken,
  revokeMultipleTokens,
  manageGroupsRemove,
  configureView,
  addVault,
  destroyVault,
  setVaultAsDefault,
  connectSources,
  removeSources,
  installAndRemoveSources,
  showErrorDetailsCloudDeployment,
  showErrorDetailsManualDeployment,
  accessDenied,
  deleteRole,
  installerNotification,
  cliCommand,
  resetMfa,
  deletePolicy,
  pinVault,
  unpinVault,
  manageUsersEnableUser,
  editVault,
  abortJob,
  repairCloudConnector,
  repairVault,
  defaultPolicy,
  deletePolicyRetention,
  fixCC,
  vpcInformation,
  safetyLockEdit,
  addWebhook,
  deleteWebhook,
  deleteWebhookEvent,
  firstProtectionPolicy,
  createProtectionPolicy,
  volumeVulnerabilitiesReport,
  instanceVulnerabilitiesReport,
  snapshotVulnerabilitiesReport,
  addSpecificPaths,
  addExistingPolicy,
  removeCloudConnectorFromPolicy,
  addNewValueFromSelect,
  deleteTabView,
  addSpecificPathsEfs,
}

export default Modal
