import { useState } from 'react'
import { ExpandedState } from '@tanstack/react-table'
import { RowsExpanded } from '@components/table/types'

interface Props {
  rowsExpanded?: RowsExpanded
}

export function useRowExpanded({ rowsExpanded }: Props) {
  const [uncontrolledRowExpanded, setUncontrolledRowExpanded] =
    useState<ExpandedState>({})

  const setExpandedRows = rowsExpanded?.setExpandedRows
    ? rowsExpanded.setExpandedRows
    : setUncontrolledRowExpanded

  const expandedRows = rowsExpanded?.expandedRows
    ? rowsExpanded.expandedRows
    : uncontrolledRowExpanded

  return {
    expandedRows,
    setExpandedRows,
  }
}
