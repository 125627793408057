import PreloaderState from '@store/states/preloader.state'
import ActionInterface from '@lib/interfaces/action.interface'
import {
  DISABLE_PRELOADER,
  ENABLE_PRELOADER,
} from '@store/actions/preloader.action'
import ArrHelper from '@lib/helpers/arr.helper'

const initialState: PreloaderState = {
  preloaders: [],
}

function preloaderReducer(
  prevState: PreloaderState = initialState,
  action: ActionInterface
): PreloaderState {
  let preloaders

  switch (action.type) {
    case ENABLE_PRELOADER:
      preloaders = [...prevState.preloaders]
      ArrHelper.pushUnique(action.payload, preloaders)
      return {
        ...prevState,
        preloaders,
      }

    case DISABLE_PRELOADER:
      preloaders = [...prevState.preloaders].filter((v) => v !== action.payload)
      return {
        ...prevState,
        preloaders,
      }

    default:
      return prevState
  }
}

export default preloaderReducer
