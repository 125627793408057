import { Metadata } from 'grpc-web'
import { GrpcCodes } from '@lib/constants/data/error/api-errors.constant'

interface GrpcRequestErrorAttrs {
  requestName: string
  message: string
  code: GrpcCodes
  metadata: Metadata
}

interface GrpcRequestMetadata {
  requestId: string
}

class GrpcRequestError extends Error {
  readonly #code: number

  readonly #requestName: string

  readonly #metadata: GrpcRequestMetadata

  constructor({ code, message, metadata, requestName }: GrpcRequestErrorAttrs) {
    super(`${requestName}: ${message} (code - ${code.toString()})`)

    this.name = 'GrpcRequestError'
    this.#requestName = requestName
    this.#code = code
    this.#metadata = this.#transformMetadata(metadata)
  }

  get metadata() {
    return this.#metadata
  }

  get requestId(): string {
    return this.metadata.requestId
  }

  get code(): GrpcCodes {
    return this.#code
  }

  get requestName(): string {
    return this.#requestName
  }

  #transformMetadata(metadata: Metadata): GrpcRequestMetadata {
    return {
      requestId: metadata['request-id'] as string,
    }
  }
}

export default GrpcRequestError
