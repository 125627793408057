import { BackupsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import { Request as ListBackupsForAssetRequest } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/backups/list_backups_for_asset_pb'
import { Request as LastCleanBackupRequest } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/backups/get_last_clean_backup_for_asset_pb'
import { GrpcClient } from '@lib/clients/grpc-client'

import {
  LastCleanBackupForAsset,
  LastCleanBackupForAssetResponseAsObject,
  ListBackupsForAsset,
  ListBackupsForAssetAsObject,
} from '@lib/clients/backups/list-backups-for-asset/types'
import {
  AWSBRecoveryPointTransformer,
  EbsSnapshotTransformer,
  ElastioRecoveryPointTransformer,
  OvaBackupTransformer,
} from '@lib/clients/assets/transformers'
import { Pagination } from '@lib/clients/types'
import { AbstractListRequest } from '@lib/clients/list-request'

export class ListBackupsForAssetClient extends GrpcClient<BackupsPromiseClient> {
  #backupClient: BackupsPromiseClient

  constructor(hostName = '') {
    super()
    this.#backupClient = this.getClient(hostName)
  }

  protected initClient(hostName: string): BackupsPromiseClient {
    return new BackupsPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'ListBackupsForAssetClient'
  }

  async listBackupsForAsset(
    request: AbstractListRequest<ListBackupsForAssetRequest>
  ): Promise<ListBackupsForAsset & Pagination> {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#backupClient.listBackupsForAsset(
            request.createGrpcRequest(),
            this.metadata()
          ),
        {
          requestName: 'BackupsPromiseClient/listBackupsForAsset',
        }
      )
    ).toObject()

    return {
      ...this.#transformResponse(response),
      pageToken: response.pagination?.nextPageToken,
    }
  }

  async lastCleanBackupForAsset(
    id: string
  ): Promise<Partial<LastCleanBackupForAsset>> {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#backupClient.getLastCleanBackupForAsset(
            new LastCleanBackupRequest().setAssetId(id),
            this.metadata()
          ),
        {
          requestName: 'BackupsPromiseClient/getLastCleanBackupForAsset',
        }
      )
    ).toObject()

    return this.#transformLastCleanBackupForAssetResponse(response)
  }

  #transformLastCleanBackupForAssetResponse(
    response: LastCleanBackupForAssetResponseAsObject
  ): Partial<LastCleanBackupForAsset> {
    return {
      awsbRps:
        response.awsbRps &&
        new AWSBRecoveryPointTransformer(response.awsbRps).transform(),
      ebsSnapshots:
        response.ebsSnapshots &&
        new EbsSnapshotTransformer(response.ebsSnapshots).transform(),
      elastioRps:
        response.elastioRps &&
        new ElastioRecoveryPointTransformer(response.elastioRps).transform(),
      ovaBackup:
        response.ovaBackups &&
        new OvaBackupTransformer(response.ovaBackups).transform(),
    }
  }

  #transformResponse(
    response: ListBackupsForAssetAsObject
  ): ListBackupsForAsset & Pagination {
    return {
      awsbRpsList: response.awsbRpsList.map((rp) =>
        new AWSBRecoveryPointTransformer(rp).transform()
      ),
      ebsSnapshotsList: response.ebsSnapshotsList.map((snapshot) =>
        new EbsSnapshotTransformer(snapshot).transform()
      ),
      elastioRpsList: response.elastioRpsList.map((rp) =>
        new ElastioRecoveryPointTransformer(rp).transform()
      ),
      ovaBackupList: response.ovaBackupsList.map((backup) =>
        new OvaBackupTransformer(backup).transform()
      ),
      pageToken: response.pagination?.nextPageToken,
    }
  }
}
