/* eslint-disable import/no-extraneous-dependencies */
import { UserSettings } from '@lib/constants'
import SettingsService from '@lib/services/high/settings.service'
import { TabViewInterface } from './management-view/types'
import { DEFAULT_VIEWS } from './management-view/consts'
import { RecoveryPointKind } from 'blues-corejs/dist/models/backups/aws/awsb/types'
import { ElastioRecoveryPointKind } from 'blues-corejs/dist/models/backups/aws/elastio/types'
import { CURRENT_VIEW_VERSION } from './management-view/consts'
import { viewsConfigToLatestVersion } from './views-config-to-latest-version'

export function getViewsFromConfig() {
  let views = (SettingsService.getSetting(UserSettings.TabViews) ??
    []) as Array<TabViewInterface>

  if (views.some(({ version }) => version !== CURRENT_VIEW_VERSION)) {
    views = viewsConfigToLatestVersion(views)

    SettingsService.updateSetting(UserSettings.TabViews, views)
  }

  return views.filter((view) => !DEFAULT_VIEWS.some(({ id }) => id === view.id))
}

export function getRpKindName(rpKind?: number) {
  if (rpKind === undefined) {
    return ''
  }

  if (rpKind !== undefined && !RecoveryPointKind[rpKind]) {
    return ''
  }

  return RecoveryPointKind[rpKind] ?? ''
}

export function getElastioRpKindName(rpKind?: number) {
  if (rpKind === undefined) {
    return ''
  }

  if (rpKind !== undefined && !ElastioRecoveryPointKind[rpKind]) {
    return ''
  }

  return ElastioRecoveryPointKind[rpKind]?.toString().replace('_', ' ') ?? ''
}
