import { StoreReturnType } from '@store/store'
import ScheduleInterface from '@lib/interfaces/schedule.interface'
import IntegrityScanInterface from '@lib/interfaces/integrity-scan.interface'
import { VIRow } from '@lib/engine-types'
import { SelectedVariant } from '@lib/constants/app.constant'
import {
  ProtectNewImmediately,
  SelectedCloudConnectorVariant,
  SnapshotImport,
} from '@lib/constants/policies.constant'
import { IntegrityCheckOptions } from '@lib/constants/integrity-check-options.constant'
import { VaultForPolicy } from '@lib/interfaces/vault/vaults-policy'
import { IntegrityScanOptions } from '@lib/constants/integrity-scan-options.constant'
import { KeepDataCopyInterface } from '@lib/interfaces/keep-data-copy.interface'
import ScanWithTimestampsInterface from '@lib/interfaces/scan-with-timestamps.interface'

export const getAddPolicyName = (state: any): string =>
  state.addPolicyState.policyName

export const getAddPolicySchedule = (state: any): ScheduleInterface =>
  state.addPolicyState.schedule

export const getAddIntegrityScan = (state: any): IntegrityScanInterface =>
  state.addPolicyState.integrityScan

export const getIntegrityScanOption = (state: any): IntegrityScanOptions =>
  state.addPolicyState.integrityScanOption

export const getKeepDataCopy = (state: any): KeepDataCopyInterface =>
  state.addPolicyState.keepDataCopy

export const getAddTags = (state: any): VIRow => state.addPolicyState.policyTags

export const getIntegrityCheck = (state: any): IntegrityCheckOptions =>
  state.addPolicyState.integrityCheck

export const getSelectedVariant = (state: any): SelectedVariant =>
  state.addPolicyState.selectedVariant

export const getSelectedCloudConnectorVariant = (
  state: any
): SelectedCloudConnectorVariant =>
  state.addPolicyState.selectedCloudConnectorVariant

export const getSelectedCloudConnectors = (state: any): VIRow =>
  state.addPolicyState.selectedCloudConnectors

export const getSnapshotImportVariant = (state: any): SnapshotImport =>
  state.addPolicyState.snapshotImportVariant

export const getProtectNewImmediately = (state: any): ProtectNewImmediately =>
  state.addPolicyState.protectNewImmediately

export const getSelectedVaultsList = (state: any): Array<VaultForPolicy> =>
  state.addPolicyState.selectedVaultsList

export const getSkipEbsBackup = (state: any): boolean =>
  state.addPolicyState.skipEbsBackup

export const getScanWithTimestamps = (
  state: any
): ScanWithTimestampsInterface => state.addPolicyState.scanWithTimestamps

export const getIsEntropyDetectionEnabled = (state: StoreReturnType): boolean =>
  state.addPolicyState.isEntropyDetectionEnabled
