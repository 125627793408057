import {
  AWSBRecoveryPointTransformer,
  EbsSnapshotTransformer,
  ElastioRecoveryPointTransformer,
} from './transformers'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  AWSBRecoveryPoint,
  EBSSnapshot,
  ElastioRecoveryPoint,
} from 'blues-corejs/dist'
import { Response as LiveAssetsResponse } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/assets/list_live_assets_pb'
import { Map as ProtobufMap } from 'google-protobuf'

type BackupType = EBSSnapshot | ElastioRecoveryPoint | AWSBRecoveryPoint

export type BackupsMap = Map<string, BackupType>

type ProtobufBackup =
  | LiveAssetsResponse.LastBackup
  | LiveAssetsResponse.FirstBackup

export class BackupProcessor {
  #backups: BackupsMap

  constructor(backups: ProtobufMap<string, LiveAssetsResponse.LastBackup>) {
    this.#backups = new Map()
    for (const [key, backup] of backups.entries()) {
      this.#addBackup(key, backup)
    }
  }

  #addBackup(key: string, backup: ProtobufBackup) {
    const awsbRecoveryPoint = backup.getAwsbRecoveryPoint()

    const ebsSnapshot = backup.getEbsSnapshot()

    const elastioRecoveryPoint = backup.getElastioRecoveryPoint()

    if (awsbRecoveryPoint) {
      this.#backups.set(
        key,
        new AWSBRecoveryPointTransformer(
          awsbRecoveryPoint.toObject()
        ).transform()
      )
      return
    }

    if (ebsSnapshot) {
      this.#backups.set(
        key,
        new EbsSnapshotTransformer(ebsSnapshot.toObject()).transform()
      )
      return
    }

    if (elastioRecoveryPoint) {
      this.#backups.set(
        key,
        new ElastioRecoveryPointTransformer(
          elastioRecoveryPoint.toObject()
        ).transform()
      )
      return
    }
  }

  get backups() {
    return this.#backups
  }
}
