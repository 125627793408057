import { StrHelper, TimeHelper } from '@lib/helpers'
import { NextRouter, useRouter } from 'next/router'
import { UsersClient } from '@lib/clients'
import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { PagePathConstant } from '@lib/constants'
import { initializeClients } from '@lib/clients/initialize-clients'
import StoreService from '@lib/services/store.service'
import LocalStorageHelper from '@lib/helpers/local-storage.helper'
import store from '@store/store'

type Dependencies = {
  usersClient: UsersClient
  parseTimeZone?: (timeZone?: string) => string | undefined
}

function defaultParseTimeZone(timeZone?: string): undefined | string {
  if (!timeZone) {
    return
  }
  return StrHelper.base64DecodeObj(timeZone)?.value as string
}

/**
 * This function checks if the current route or query parameters indicate
 * that we should skip user configuration initialization. Specifically,
 * it looks for the 'code' query parameter (typically from an authentication flow)
 * and certain excluded keywords in the route (like 'global' or 'login').
 */

export const loginElastioDomainAndPath = {
  domain: /^login\.elastio\.(us|com)$/,
  path: /^(\/|)/,
}

export const createTenantDomainAndPath = {
  domain: /^create-tenant\.elastio\.(us|com)$/,
  path: /^(\/|)/,
}

export const excludedDomainsAndPaths = [
  loginElastioDomainAndPath,
  createTenantDomainAndPath,
  {
    domain: /^localhost$/,
    path: /^\/global$/,
  },
  {
    domain: /^localhost$/,
    path: /^\/tenant-creation$/,
  },
]

function shouldInitializeUserConfig(router: NextRouter): boolean {
  if ('code' in router.query) {
    return false
  }

  if (typeof window === 'undefined') {
    return false
  }

  const domain = window.location.hostname.toLowerCase()
  const path = router.asPath.toLowerCase()

  for (const rule of excludedDomainsAndPaths) {
    if (rule.domain.test(domain) && rule.path.test(path)) {
      return false
    }
  }

  return true
}

async function initializeUserConfig(
  dependencies: Dependencies,
  parseTimeZone: (timeZone?: string) => string | undefined
): Promise<void> {
  const userConfig = await dependencies.usersClient.getUserConfig()
  const parsedTimeZone = parseTimeZone(userConfig.TimeZone?.value)
  TimeHelper.initTimezone(parsedTimeZone)
}

function determineInitializationState(router: NextRouter): boolean {
  const currentDate = TimeHelper.getDateWithTimezone(Date.now())

  const isEndOfDayValid = Boolean(currentDate?.endOf('day'))

  const shouldSkipInitConfig = !shouldInitializeUserConfig(router)

  return isEndOfDayValid || shouldSkipInitConfig
}

export function useAppInitialization(
  isAuthenticated: boolean,
  dependencies: Dependencies
) {
  const [isInitialized, setIsInitialized] = useState(false)
  const { getIdTokenClaims } = useAuth0()
  const router = useRouter()

  const parseTimeZone = dependencies.parseTimeZone || defaultParseTimeZone

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    const initialize = async () => {
      try {
        const claims = await getIdTokenClaims()

        if (!claims) {
          router.replace(PagePathConstant.RELOGIN)
          return
        }

        initializeClients(claims.__raw as string)
        StoreService.init(store.dispatch)

        LocalStorageHelper.saveGlobalCellData({
          expiredAt: claims.exp!,
        })

        if (shouldInitializeUserConfig(router)) {
          await initializeUserConfig(dependencies, parseTimeZone)
        }

        setIsInitialized(determineInitializationState(router))
      } catch (error) {
        console.error('Initialization failed:', error)
      }
    }

    initialize()
  }, [isAuthenticated])

  return { isInitialized }
}
