/* eslint-disable import/no-extraneous-dependencies */
import { RiskLevel } from 'blues-corejs/dist'

export function getRiskTypeName(status: RiskLevel): string {
  switch (status) {
    case RiskLevel.High:
      return 'High'

    case RiskLevel.Medium:
      return 'Medium'

    case RiskLevel.Low:
      return 'Low'
  }
}
