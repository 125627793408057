import {
  AbstractScheduledJobsRepository,
  SYNC,
} from './abstract-scheduled-jobs'
import { Sync, SyncAttrs } from '@features/scheduled-jobs-monitoring/models'
import Dexie from 'dexie'

type ModelId = string

export class SyncsRepository extends AbstractScheduledJobsRepository<
  SyncAttrs,
  Sync,
  ModelId
> {
  protected instantiateModel(attr: SyncAttrs): Sync {
    return new Sync(attr)
  }

  protected get table(): Dexie.Table<SyncAttrs> {
    return this.db.table(this.tableName)
  }

  private get tableName(): string {
    return SYNC
  }
}
