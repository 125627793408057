/* eslint-disable import/no-extraneous-dependencies */
import { Job as ProtobufJob } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/jobs/tenant/job_pb'
import { TenantJobKind } from 'blues-corejs/dist/models/jobs/tenant/job/types'

export class TenantJobKindTransformer {
  #jobKind: ProtobufJob.Kind

  constructor(jobKind: ProtobufJob.Kind) {
    this.#jobKind = jobKind
  }

  transform(): TenantJobKind {
    // @ts-ignore
    const mapping: Record<ProtobufJob.Kind, TenantJobKind> = {
      [ProtobufJob.Kind.KIND_INSTALL_CLOUD_CONNECTOR]:
        TenantJobKind.INSTALL_CLOUD_CONNECTOR,
      [ProtobufJob.Kind.KIND_CREATE_VAULT]: TenantJobKind.CREATE_VAULT,
      [ProtobufJob.Kind.KIND_DESTROY_VAULT]: TenantJobKind.DESTROY_VAULT,
      [ProtobufJob.Kind.KIND_REFRESH_PLANS]: TenantJobKind.REFRESH_PLANS,
      [ProtobufJob.Kind.KIND_SET_PLAN_STATUS]: TenantJobKind.SET_PLAN_STATUS,
      [ProtobufJob.Kind.KIND_RESYNC]: TenantJobKind.RESYNC,
      [ProtobufJob.Kind.KIND_ACTIVATE_CLOUD_CONNECTOR]:
        TenantJobKind.ACTIVATE_CLOUD_CONNECTOR,
      [ProtobufJob.Kind.KIND_ANALYZE_CLOUD_CONNECTOR_TARGET_REGION]:
        TenantJobKind.ANALYZE_CLOUD_CONNECTOR_TARGET_REGION,
      [ProtobufJob.Kind.KIND_EVALUATE_PLANS]: TenantJobKind.EVALUATE_PLANS,
      [ProtobufJob.Kind.KIND_SCAN_INVENTORY]: TenantJobKind.SCAN_INVENTORY,
      [ProtobufJob.Kind.KIND_ENABLE_ELASTIO_REPORTS_GATHERING]:
        TenantJobKind.ENABLE_ELASTIO_REPORTS_GATHERING,
      [ProtobufJob.Kind.KIND_DISABLE_ELASTIO_REPORTS_GATHERING]:
        TenantJobKind.DISABLE_ELASTIO_REPORTS_GATHERING,
      [ProtobufJob.Kind.KIND_UPGRADE_CLOUD_CONNECTOR]:
        TenantJobKind.UPGRADE_CLOUD_CONNECTOR,
      [ProtobufJob.Kind.KIND_UPGRADE_VAULT]: TenantJobKind.UPGRADE_VAULT,
      [ProtobufJob.Kind.KIND_REPAIR_REPORT_GATHERING_INFRA]:
        TenantJobKind.REPAIR_REPORT_GATHERING_INFRA,
      [ProtobufJob.Kind.KIND_CLEANUP_RED_STACK_DATA]:
        TenantJobKind.CLEANUP_RED_STACK_DATA,
      [ProtobufJob.Kind.KIND_REPAIR_CLOUD_CONNECTOR]:
        TenantJobKind.REPAIR_CLOUD_CONNECTOR,
      [ProtobufJob.Kind.KIND_REPAIR_VAULT]: TenantJobKind.REPAIR_VAULT,
      [ProtobufJob.Kind.KIND_UPDATE_VAULT_SUBNETS]:
        TenantJobKind.UPDATE_VAULT_SUBNETS,
      [ProtobufJob.Kind.KIND_CLOUD_CONNECTOR_INTEGRITY_CHECK]:
        TenantJobKind.CLOUD_CONNECTOR_INTEGRITY_CHECK,
      [ProtobufJob.Kind.KIND_CLOUD_CONNECTOR_INFRASTRUCTURE_CHECK]:
        TenantJobKind.CLOUD_CONNECTOR_INFRASTRUCTURE_CHECK,
      [ProtobufJob.Kind.KIND_VAULT_INFRASTRUCTURE_CHECK]:
        TenantJobKind.VAULT_INFRASTRUCTURE_CHECK,
      [ProtobufJob.Kind.KIND_VAULT_NETWORKING_CHECK]:
        TenantJobKind.VAULT_NETWORKING_CHECK,
      [ProtobufJob.Kind.KIND_EXECUTE_BACKUP_POLICY]:
        TenantJobKind.EXECUTE_BACKUP_POLICY,

      [ProtobufJob.Kind.KIND_CLEANUP_BACKUP_POLICY_COVERAGE]:
        TenantJobKind.CLEANUP_BACKUP_POLICY_COVERAGE,
      [ProtobufJob.Kind.KIND_REFRESH_CONNECTOR_RETENTION_POLICIES]:
        TenantJobKind.REFRESH_CONNECTOR_RETENTION_POLICIES,
      [ProtobufJob.Kind.KIND_EVALUATE_RETENTION_POLICIES]:
        TenantJobKind.EVALUATE_RETENTION_POLICIES,
      [ProtobufJob.Kind.KIND_UPDATE_VAULT]: TenantJobKind.UPDATE_VAULT,
      [ProtobufJob.Kind.KIND_EVALUATE_ASSETS_INTEGRITY_STATUS]:
        TenantJobKind.EVALUATE_ASSETS_INTEGRITY_STATUS,

      [ProtobufJob.Kind.KIND_REFRESH_ASSET_THREAT_STATUSES]:
        TenantJobKind.REFRESH_ASSET_THREAT_STATUSES,
      [ProtobufJob.Kind.KIND_GENERATE_SCHEDULED_REPORT]:
        TenantJobKind.GENERATE_SCHEDULED_REPORT,
      [ProtobufJob.Kind.KIND_DELETE_CLOUD_CONNECTOR]:
        TenantJobKind.DELETE_CLOUD_CONNECTOR,
    }

    return mapping[this.#jobKind]
  }
}
