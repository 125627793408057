/* eslint-disable import/no-extraneous-dependencies */
import { Asset } from 'blues-corejs/dist'
import { useEffect, useState } from 'react'
import { CloudConnector } from 'blues-corejs/dist/models'
import { RexClient } from '@lib/clients'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'

const rexClient = new RexClient()

function findCloudConnectorByAwsAccountId(
  cloudConnectors: Array<CloudConnector>,
  awsAccountId: string
): CloudConnector | undefined {
  const cloudConnector = cloudConnectors.find(
    (cc) => cc.awsAccountId === awsAccountId
  )

  return cloudConnector
}

const cloudConnectorsCache = new Map<string, CloudConnector | undefined>()

interface UseGetCloudConnectorByAwsAccountId {
  cloudConnector: CloudConnector | undefined
  isCloudConnectorLoading: boolean
}

export function useGetCloudConnectorByAsset(
  asset: Asset
): UseGetCloudConnectorByAwsAccountId {
  const [cloudConnector, setCloudConnector] = useState<
    CloudConnector | undefined
  >()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchCloudConnector() {
      if (!(asset instanceof AWSAsset)) {
        return
      }

      try {
        setIsLoading(true)
        const cloudConnectorList = await rexClient.listGetAllRedstacks()

        const fetchedCloudConnector = findCloudConnectorByAwsAccountId(
          cloudConnectorList,
          asset.awsAccountId
        )

        setCloudConnector(fetchedCloudConnector)
        cloudConnectorsCache.set(asset.awsAccountId, fetchedCloudConnector)
      } catch (error) {
        console.error('Error while fetching cloud connector:', error)
      } finally {
        setIsLoading(false)
      }
    }

    if (!(asset instanceof AWSAsset)) {
      return
    }
    if (cloudConnectorsCache.has(asset.awsAccountId)) {
      setCloudConnector(cloudConnectorsCache.get(asset.awsAccountId))
      return
    }

    fetchCloudConnector()

    return () => {
      setIsLoading(false)
      setCloudConnector(undefined)
    }
  }, [asset.id])

  return {
    cloudConnector,
    isCloudConnectorLoading: isLoading,
  }
}
