export enum ColumnWidth {
  EXTRA_SMALL = 20,
  SMALL = 100,
  MEDIUM = 180,
  LARGE = 480,
}

export enum RowHeight {
  SMALL = 36,
  LARGE = 50,
}

export type TextAlign = 'left' | 'center' | 'right'

export type WhiteSpace = 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line'

export type WordBreak = 'normal' | 'break-all' | 'keep-all' | 'break-word'
