"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listS3BucketInventoryPossibleFilters = void 0;
class listS3BucketInventoryPossibleFilters {
    #filesystems;
    constructor(parameters) {
        this.#filesystems = parameters.s3Buckets;
    }
    execute() {
        const accountIds = new Set();
        const regions = new Set();
        this.#filesystems.forEach((S3BucketItem) => {
            accountIds.add(S3BucketItem.awsAccountId);
            regions.add(S3BucketItem.awsRegion);
        });
        return {
            possibleS3BucketFilters: {
                accountIds: [...accountIds],
                regions: [...regions],
                covered: [true, false],
                state: [true, false],
            },
        };
    }
}
exports.listS3BucketInventoryPossibleFilters = listS3BucketInventoryPossibleFilters;
