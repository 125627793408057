import React, { memo } from 'react'
import { EmptyFunc } from '@lib/constants/app.constant'
import styles from './WidgetHeader.module.css'
import clsx from 'clsx'
import QuestionHint from '@components-simple/question-hint/QuestionHint'
import { Box, Typography } from '@mui/material'

interface Props {
  title: string
  className?: string
  onClick?: () => void
  hintMessage?: string
}

function WidgetHeader({
  title,
  hintMessage,
  onClick = EmptyFunc,
  className = '',
}: Props) {
  const isHeaderClickable = onClick !== EmptyFunc

  return (
    <Box
      className={clsx(`${styles.root} ${className}`, {
        [String(styles.rootLink)]: isHeaderClickable,
      })}
      onClick={onClick}
    >
      <Typography variant="inherit">{title}</Typography>
      {hintMessage && (
        <QuestionHint className={styles.hint} text={hintMessage} />
      )}
    </Box>
  )
}

export default memo(WidgetHeader)
