/* eslint-disable import/no-extraneous-dependencies */
import {
  AccountStatusConstant,
  CheckboxConstant,
  PagePathConstant,
  RedStackStatusConstant,
  Severity,
} from '@lib/constants'
import { AssetKind } from '@lib/constants/grpc/asset-kind.constant'
import {
  RowSimpleUnit,
  StringTupleArray,
  VIMatrix,
  VIRow,
} from '@lib/engine-types'
import VpcFactory from '@lib/factories/vpc.factory'
import ArrHelper from '@lib/helpers/arr.helper'
import DataHelper from '@lib/helpers/data.helper'
import LangHelper from '@lib/helpers/lang.helper'
import PageHelper from '@lib/helpers/page.helper'
import ScopeHelper from '@lib/helpers/scope.helper'
import { WebhookValueInterface } from '@lib/interfaces/notifications.interface'
import { AssetsEntityType } from '@lib/interfaces/tables/assets-table.interface'
import ValueInterface from '@lib/interfaces/value.interface'
import AccessTokenModel from '@lib/models/access-token.model'
import PolicyModel from '@lib/models/policy.model'
import RedStackModel from '@lib/models/red-stack.model'
import RetentionPolicyModel from '@lib/models/retention-policy.model'
import UserRoleModel from '@lib/models/user-role.model'
import UserModel from '@lib/models/user.model'
import VaultModel from '@lib/models/vault.model'
import VpcModel from '@lib/models/vpc.model'
import WebhookModel from '@lib/models/webhook.model'
// eslint-disable-next-line import/no-extraneous-dependencies
import { InstanceModel, VolumeModel, TagsArray } from 'blues-corejs/dist'
import { ReportScheduleRequestInterface } from '@lib/interfaces/schedule-report.interface'

// eslint-disable-next-line import/no-extraneous-dependencies
import { ElastioRecoveryPoint } from 'blues-corejs/dist/models'
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import TypeHelper from '@lib/helpers/type.helper'
import { EBS, EC2 } from '@lib/models/assets'
import { SelectSpecificPathsInterface } from '@lib/interfaces/assets-s3-select.interface'
import BaseDataConstant from '@lib/constants/data/base-data.constant'

export interface AssetKindParsedData extends ValueInterface {
  pathSlug: string
  pathLabel: string
}

class AssetKindParsedConstantInner extends BaseDataConstant<AssetKindParsedData> {
  get KIND_UNDEFINED(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: '',
      label: 'Undefined',
      value: -1,
    }
  }

  get KIND_AWS_EC2(): AssetKindParsedData {
    return {
      pathSlug: 'instances',
      pathLabel: 'Instances',
      name: 'ec2',
      label: 'EC2',
      value: 0,
    }
  }

  get KIND_AZURE_VM(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: 'azure_vm',
      label: '',
      value: 1,
    }
  }

  get KIND_VM_WARE_VM(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: 'vm_ware_vm',
      label: '',
      value: 2,
    }
  }

  get KIND_AWS_EBS(): AssetKindParsedData {
    return {
      pathSlug: 'volumes',
      pathLabel: 'Volumes',
      name: 'aws_ebs',
      label: 'EBS',
      value: 3,
    }
  }

  get KIND_AWS_EFS(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: 'aws_efs',
      label: 'EFS',
      value: 4,
    }
  }

  get KIND_GENERIC_HOST(): AssetKindParsedData {
    return {
      pathSlug: 'local-machine',
      pathLabel: 'Other Assets',
      name: 'generic_host',
      label: 'Generic Host',
      value: 5,
    }
  }

  get KIND_GENERIC_FS(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: 'generic_fs',
      label: 'Generic FS',
      value: 6,
    }
  }

  get KIND_S3_BUCKET(): AssetKindParsedData {
    return {
      pathSlug: 's3buckets',
      pathLabel: 'S3 Buckets',
      name: 's3_bucket',
      label: 'S3',
      value: 7,
    }
  }

  get KIND_AWS_S3_OBJECTS(): AssetKindParsedData {
    return {
      pathSlug: 's3objects',
      pathLabel: 'S3 Objects',
      name: 's3_objects',
      label: 'S3 Objects',
      value: 8,
    }
  }

  get KIND_ALL(): AssetKindParsedData {
    return {
      pathSlug: 'all',
      pathLabel: 'All',
      name: 'all',
      label: 'All',
      value: 999,
    }
  }

  public getByPathSlug(value: string): AssetKindParsedData {
    return this.getByField('pathSlug', value)
  }
}

const AssetKindParsedConstant = AssetKindParsedConstantInner.build(
  AssetKindParsedConstantInner,
  'KIND_'
)

// TODO: for future - move it in the model with a new field
function getApiAccessTableLabel(value: AccessTokenModel): string {
  if (value.updatedAt) {
    return value.updatedAt.toISOString()
  }
  return 'Never used'
}

// By not implementing any additional methods in the `TableFactory` class, we keep the class lightweight and rely on the inherited behavior.
abstract class VolumeIDTable {
  private static exceptionVolumeId = 'vol-ffffffff'

  public static getVolumeID(volumeID: string): string {
    const shouldContainLink = !DataHelper.protectedObjectIdParsed(
      volumeID
    ).includes(this.exceptionVolumeId)
    return shouldContainLink ? PageHelper.buildItemUrl(volumeID, 'volume') : ''
  }
}

abstract class TableFactory extends VolumeIDTable {
  public static managementVIUsers(data: Array<UserModel>): VIMatrix {
    return data.map((user) => [
      // name and email
      {
        name: user.name,
        label: user.email,
      },

      /*TODO: hidden 09.02.2024 by ticket #9484*/
      // groups
      // {
      //     name: 'None',
      // },
      // roles
      // {
      //   name: '',
      //   options: [
      //     {
      //       name: user.role.name,
      //       label: user.role.name,
      //       options: DataHelper.groupScopes(user.role.scopesList),
      //     },
      //   ],
      // },
      // last logged in
      {
        name: user.lastSeen?.toISOString() ?? '',
      },
      // status
      {
        name: '',
        value: user.statusParsed.value,
      },
    ])
  }

  public static EBSRelatedForEC2ForInventory(data: Array<EBS | EC2>): VIMatrix {
    return data?.map((asset) => {
      let assetTypeNumber = AssetKind.AWS_EC2

      if (asset instanceof EBS) {
        assetTypeNumber = AssetKind.AWS_EBS
      }

      const result: VIRow = [
        {
          id: asset?.id,
          name: '',
          type: AssetsEntityType.Checkbox,
          value: CheckboxConstant.Empty,
          selectedOption: {
            name: String(asset?.id),
            // disabled: !volume.canBeProtected,
            // value: volume.accountAliasName,
            extraValue: asset?.awsAccountId,
            type: assetTypeNumber,
            selectedOption: {
              name: LangHelper.getAwsRegionSingleTranslation(asset?.awsRegion),
              label: String(asset?.awsRegion),
            },
          },
        },
      ]
      return result
    })
  }

  public static EC2RelatedForEBSForInventory(data: Array<EBS | EC2>): VIMatrix {
    return data?.map((asset) => {
      let assetTypeNumber = AssetKind.AWS_EC2

      if (asset instanceof EBS) {
        assetTypeNumber = AssetKind.AWS_EBS
      }
      const result: VIRow = [
        {
          id: asset?.id,
          name: '',
          type: AssetsEntityType.Checkbox,
          value: CheckboxConstant.Empty,
          selectedOption: {
            name: String(asset?.id),
            label: asset?.id,
            // disabled: !asset.canBeProtected,
            // value: asset.accountAliasName,
            extraValue: asset?.awsAccountId,
            type: assetTypeNumber,
            selectedOption: {
              name: LangHelper.getAwsRegionSingleTranslation(asset?.awsRegion),
              label: String(asset?.awsRegion),
            },
          },
        },
      ]
      return result
    })
  }

  public static assetsInventoryPoliciesVI(
    data: Array<AssetWithRelatedAssets<Asset>>,
    assetsMissingInLiveAssets: Array<string>,
    lastBackups: Map<string, ElastioRecoveryPoint> | null
  ): VIMatrix {
    return data?.map((item) => {
      const assetTypeString = LangHelper.getAssetKindSingleTranslation(
        item.type
      )

      const assetTags: StringTupleArray = (item?.asset?.tags as TagsArray).map(
        ({ key, value }) => [key, value]
      )

      const isMissingInLiveAssets = assetsMissingInLiveAssets.some(
        (assetId) => assetId === item.asset.id
      )

      const lastBackup = lastBackups?.get(item.asset.id)

      // TODO remove this check after GenericHost Inventory page will be created
      const isHideLinks =
        item.type === AssetKind.GENERIC_HOST || isMissingInLiveAssets

      const result: VIRow = [
        {
          id: item.asset?.id,
          name: '',
          type: AssetsEntityType.Checkbox,
          value: CheckboxConstant.Empty,
          selectedOption: {
            name: String(item.asset?.id),
            label: item.asset?.id,
            // disabled: !asset.isProtected(),
            // value: asset.accountAliasName,
            extraValue: item.asset?.awsAccountId,
            type: item.type,
            selectedOption: {
              name: LangHelper.getAwsRegionSingleTranslation(
                item.asset?.awsRegion
              ),
              label: String(item.asset?.awsRegion),
            },
          },
          matrix:
            item.type === AssetKind.AWS_EBS
              ? TableFactory.EC2RelatedForEBSForInventory(item?.relatedAssets)
              : item.type === AssetKind.AWS_EC2
              ? TableFactory.EBSRelatedForEC2ForInventory(item?.relatedAssets)
              : [],
        },
        {
          // name: asset.state?.label ?? '',
          name: '',
          type: AssetsEntityType.Icon,
        },
        {
          type: AssetsEntityType.Link,
          name:
            item?.asset?.id && !isHideLinks
              ? PageHelper.buildItemUrl(
                  item.asset?.awsId,
                  TypeHelper.getAssetKindForInventory(item.type)
                )
              : '',

          label: item?.asset?.name || item?.asset?.awsId,
          value: item?.asset?.name ? String(item?.asset?.awsId) : '',
        },
        {
          name: '',
          type: AssetsEntityType.Tags,
          tags: assetTags,
        },
        {
          name: assetTypeString,
        },
        { name: item?.asset?.awsAccountId },
        {
          name: LangHelper.getAwsRegionSingleTranslation(
            item?.asset?.awsRegion
          ),
        },
        {
          type: AssetsEntityType.Date,
          name: lastBackup ? String(lastBackup.timestamp) : '',
        },
        {
          label:
            item?.asset.backupPolicies.length > 0
              ? item?.asset?.backupPolicies?.join()
              : 'Not assigned',
          type:
            item?.asset.backupPolicies.length > 0
              ? AssetsEntityType.Link
              : AssetsEntityType.Text,
          name: PagePathConstant.POLICIES,
        },
      ]
      return result
    })
  }

  public static apiAccessTable(data: Array<AccessTokenModel>): VIMatrix {
    return data.map(
      (v: AccessTokenModel): VIRow => [
        { name: String(v.innerId) },
        { name: String(v.name) },
        {
          name: '',
          options: ScopeHelper.getScopeValues(v.scopesList),
        },
        { name: 'Active' },
        { name: getApiAccessTableLabel(v) },
      ]
    )
  }

  public static revokedTokensHistory(data: Array<AccessTokenModel>): VIMatrix {
    return data.map(
      (v: AccessTokenModel): VIRow => [
        { name: String(v.name) },
        {
          name: '',
          options: ScopeHelper.getScopeValues(v.scopesList),
        },
        { name: 'Revoked' },
        { name: getApiAccessTableLabel(v) },
      ]
    )
  }

  public static enabledSourcesVI(
    rsArr: Array<RedStackModel>,
    vaults: Array<VaultModel>
  ): VIMatrix {
    // grouping by status "0", "1", "3"
    const connectAndDisconnectSources = rsArr.filter(
      (row: RedStackModel) =>
        row.isActive ||
        row.isInactive ||
        row.status === RedStackStatusConstant.UPGRADE_REQUIRED
    )
    const groupedByAwsAccount: Record<string, any> =
      DataHelper.groupedByAwsAccount(connectAndDisconnectSources)
    // add count of assetsCount
    vaults.forEach((rowVault: VaultModel) => {
      if (groupedByAwsAccount[rowVault.accountId]) {
        groupedByAwsAccount[rowVault.accountId].assetsCount +=
          rowVault.assetsCount
      }
    })
    const uniqueEnabledSources = [...Object.values(groupedByAwsAccount)]
    return uniqueEnabledSources.map((row: any) => {
      // general account status and label
      let activeRSCount = 0
      let inactiveRSCount = 0
      row.statuses.forEach((value: number) => {
        if (value === RedStackStatusConstant.ACTIVE) {
          activeRSCount += 1
        }
        if (value === RedStackStatusConstant.INACTIVE) {
          inactiveRSCount += 1
        }
      })
      let accountStatusValue = AccountStatusConstant.MIXED

      if (activeRSCount === 0) {
        accountStatusValue = AccountStatusConstant.DISCONNECTED
      }
      if (inactiveRSCount === 0) {
        accountStatusValue = AccountStatusConstant.CONNECTED
      }
      if (row.cfnUpgradeRequired) {
        accountStatusValue = AccountStatusConstant.UPGRADE_REQUIRED
      }

      // fill message
      const messages: Array<string> = []
      if (
        accountStatusValue === AccountStatusConstant.DISCONNECTED ||
        accountStatusValue === AccountStatusConstant.MIXED
      ) {
        row.awsRegions.forEach((region: string, index: number) => {
          let messageValue = ''
          if (row.statuses[index] === RedStackStatusConstant.ACTIVE) {
            messageValue = 'Success'
          }
          if (row.statuses[index] === RedStackStatusConstant.INACTIVE) {
            messageValue = 'Failed'
          }
          messageValue = `${messageValue} - ${region}`
          if (row.lastErrorMessages[index]) {
            messageValue += `: "${row.lastErrorMessages[index]}"`
          }
          messages.push(messageValue)
        })
      }

      return [
        // unprotected
        {
          name: '',
          type: accountStatusValue,
          messages,
          label: row.lastWarningMessage,
        },
        // id
        {
          name: row.awsAccount,
        },
        // name
        {
          name: row.accountAlias,
        },
        // description
        {
          name: row.accountDescription,
        },
        // region
        {
          name: row.awsRegions
            .map((regionName: string) =>
              LangHelper.getAwsRegionSingleTranslation(regionName)
            )
            .join(', '),
        },
        {
          name: '',
          type: accountStatusValue,
        },
      ]
    })
  }

  public static vaultReplication(): VIMatrix {
    return [
      [
        {
          name: 'US-East-1',
        },
        {
          name: 'Region',
        },
        {
          name: '-',
        },
        {
          name: '-',
        },
        {
          name: '',
        },
        {
          name: '',
        },
      ],
      [
        {
          name: 'Test-Dev (Default)',
        },
        {
          name: 'Account',
        },
        {
          name: '-',
        },
        {
          name: '-',
        },
        {
          name: '',
        },
        {
          name: '',
        },
      ],
    ]
  }

  public static rolesTableAllScopesGrouped(
    possibleRoles: Array<UserRoleModel>
  ): VIRow {
    const allScopes = possibleRoles.reduce(
      (generalArr: Array<string>, currentRole) =>
        generalArr.concat(currentRole.scopesList),
      []
    )
    return DataHelper.groupScopes(allScopes)
  }

  public static rolesTableScopesCategories(
    possibleRoles: Array<UserRoleModel>
  ): Array<string> {
    return TableFactory.rolesTableAllScopesGrouped(possibleRoles).map(
      (elem) => elem.name
    )
  }

  public static possibleRolesTable(
    possibleRoles: Array<UserRoleModel>,
    allScopesGrouped: VIRow
  ): Array<RowSimpleUnit> {
    const tableData: Array<RowSimpleUnit> = []
    allScopesGrouped.forEach((elem: ValueInterface, index: number) => {
      const tableElem: RowSimpleUnit = [
        index,
        elem.options?.map((v) => v.name).join(', ') ?? '',
      ]
      possibleRoles.forEach((role) => {
        let mask = ''
        elem.options?.forEach((viScope: ValueInterface) => {
          mask +=
            (role?.scopesList.includes(viScope.label ?? '') ? '1' : '0') ?? '0'
        })
        tableElem.push(mask)
      })

      tableData.push(tableElem)
    })
    return tableData
  }

  public static sourceIdTable(
    redStacks: Array<RedStackModel>,
    vpcList: Array<VpcModel>,
    regions: Array<ValueInterface>
  ): VIMatrix {
    const connectAndDisconnectIds = redStacks.filter(
      (row: RedStackModel) =>
        row.isActive ||
        row.isInactive ||
        row.status === RedStackStatusConstant.UPGRADE_REQUIRED
    )

    const groupedPossibleVPC = DataHelper.groupByField(vpcList, 'regionName')

    return regions.map((region) => {
      const currentRSForRegions = connectAndDisconnectIds.find(
        (rs: RedStackModel) => region.name === rs.awsRegion
      )

      const vpcLabelList: VIRow = ArrHelper.flatDeep(
        [...groupedPossibleVPC.entries()].map(([regionName, vpcArr]) =>
          regionName === region.name ? vpcArr.map(VpcFactory.fromModelToVi) : []
        )
      )

      let subnetsLabelList: VIRow = []

      vpcLabelList.forEach((currentVpc: ValueInterface) => {
        const subnetsLabelListForVpc: VIRow = ArrHelper.flatDeep(
          [...groupedPossibleVPC.entries()].map(([regionName, vpcArr]) =>
            regionName === region.name
              ? vpcArr
                  .filter((m) => m.innerId === currentVpc.name)[0]
                  ?.subnetsList?.map((el) =>
                    VpcFactory.fromSubnetModelAndVpcNameToVi(
                      el,
                      currentVpc.name,
                      regionName
                    )
                  )
              : []
          )
        )

        subnetsLabelList = [...subnetsLabelList, ...subnetsLabelListForVpc]
      })

      let subnetsLabelSelectedList: VIRow = []

      vpcLabelList.forEach((currentVpc: ValueInterface) => {
        const subnetsSelectedListForVpc: VIRow = ArrHelper.flatDeep(
          [...groupedPossibleVPC.entries()].map(([regionName, vpcArr]) =>
            regionName === region.name
              ? vpcArr
                  .filter((m) => m.innerId === currentVpc.name)[0]
                  ?.subnetsList?.filter((s) => s.isPublic)
                  .map((el) =>
                    VpcFactory.fromSubnetModelAndVpcNameToVi(
                      el,
                      currentVpc.name,
                      regionName
                    )
                  )
              : []
          )
        )

        subnetsLabelSelectedList = [
          ...subnetsLabelSelectedList,
          ...subnetsSelectedListForVpc,
        ]
      })

      const label =
        currentRSForRegions?.status ===
          RedStackStatusConstant.UPGRADE_REQUIRED &&
        currentRSForRegions?.redStackId
          ? 'Connected (Upgrade Required)'
          : currentRSForRegions?.isActive
          ? 'Connected'
          : 'Disconnected'

      return [
        {
          name: region.name,
          label: region.label,
          options: vpcLabelList,
        },
        {
          name: currentRSForRegions?.redStackId ?? '',
          value: currentRSForRegions?.isActive ?? false,
          defaultValue: currentRSForRegions?.isActive ?? false,
          label,
          extraValue: region.name,
          options: vpcLabelList,
          selectedOption: vpcLabelList[0],
          matrix: [subnetsLabelList, subnetsLabelSelectedList ?? []],
        },
      ]
    })
  }

  public static policiesTable(policies: Array<PolicyModel>): VIMatrix {
    return policies.map(
      (p): VIRow => [
        // name
        {
          name: p.policyName,
          value: p.innerId,
          extraValue: p.started,
        },
        // status
        {
          name:
            p.status === 'Paused'
              ? p.schedule.pausedUntil
                ? 'Paused'
                : 'Disabled'
              : 'Active',
        },
        // Last Run
        {
          name: String(p.previousRun || ''),
        },
        // Next Run
        {
          name: p.status,
        },
        // pause/resume
        {
          name: p.policyName,
          value: p.innerId,
          extraValue: p.started,
        },
      ]
    )
  }

  public static policiesRetentionTable(
    policies: Array<RetentionPolicyModel>
  ): VIMatrix {
    const tableMatrix: VIMatrix = []

    policies.map((p) => {
      const tableRow: VIRow = []
      tableRow.push(
        // name
        {
          name: p.policyName,
          value: p.innerId,
        },
        // status
        {
          name: p.status,
        }
      )
      tableRow.push(
        // retention
        {
          name: p.retention,
        },
        // pause/resume
        {
          name: p.policyName,
          value: p.innerId,
          extraValue: p.started,
        }
      )

      tableMatrix.push(tableRow)
    })

    return tableMatrix
  }

  public static webhookEventsTable(
    events: Array<WebhookValueInterface>
  ): VIMatrix {
    const severitiesToStrings = (severities?: Array<Severity>): string => {
      if (!severities) {
        return ''
      }

      const strings = severities.map((severity) => {
        switch (severity) {
          case Severity.ERROR:
            return 'Error'
          case Severity.WARNING:
            return 'Warning'
          case Severity.INFO:
            return 'Info'
          case Severity.TRACE:
            return 'Trace'
          default:
            throw new Error(`Unrecognized severity value: ${severity}`)
        }
      })
      return strings.join(', ')
    }
    return events.map(
      (p): VIRow => [
        // type
        {
          name: DataHelper.getWebhooksEventType(p.eventType),
        },
        // version
        {
          name: String(p.version).toUpperCase(),
        },
        // severity
        {
          name: severitiesToStrings(p.severities),
        },
        // delete
        {
          name: p.eventType,
          id: p.id,
        },
      ]
    )
  }

  public static webhooksTable(webhooks: Array<WebhookModel>): VIMatrix {
    return webhooks.map(
      (p): VIRow => [
        // name
        {
          name: p.getName(),
          value: p.getId(),
        },
        // Endpoint URL
        {
          name: p.getEndpoint(),
        },
        // Description
        {
          name: p.getDescription(),
        },
        // test run
        {
          name: p.getName(),
          value: p.getId(),
          extraValue: p.getId(),
        },
        {
          name: p.getName(),
          value: p.getId(),
        },
        {
          name: p.getName(),
          value: p.getId(),
        },
      ]
    )
  }

  public static assetsPolicyRetentionTable(
    assets: Array<InstanceModel | VolumeModel>
  ): VIMatrix {
    const assetsList = assets.map((asset): VIRow => {
      const assetType = DataHelper.getParsedInventoryAssetType(
        asset.getCloudProviderAssetId()
      )
      return [
        // ResourceID
        {
          name: asset.getParsedCloudProviderAssetId(),
          value: asset.getParsedCloudProviderAssetId()
            ? PageHelper.buildItemUrl(
                `${asset.getParsedCloudProviderAssetId()}`,
                assetType === AssetKind.AWS_EC2 ? 'instance' : 'volume'
              )
            : '',
          label: asset.getName(),
        },
        //Type
        { name: LangHelper.getAssetKindSingleTranslation(assetType) },
        //Account ID
        { name: asset.getAccountId() },
        //Region
        {
          name: LangHelper.getAwsRegionSingleTranslation(asset.getRegion()),
        },
      ]
    })

    return assetsList
  }

  public static missingAssetsTable(data: VIRow): VIMatrix {
    return data.map(
      (v: ValueInterface): VIRow => [
        {
          name: String(v.name),
          value: true,
        },
        { name: String(v.name) },
        {
          name: DataHelper.getAssetSourceName(v.type),
          value: v.type,
        },
        { name: String(v.value) },
        { name: LangHelper.getAwsRegionSingleTranslation(String(v.label)) },
      ]
    )
  }

  public static shceduledReportsTable(
    data: Array<ReportScheduleRequestInterface>
  ): VIMatrix {
    return data.map(
      (v: ReportScheduleRequestInterface): VIRow => [
        {
          name: String(v.id),
          value: v.reportKind,
        },
        { name: String(v.id) },
      ]
    )
  }

  public static webhooksEvents(data: Array<WebhookValueInterface>): VIMatrix {
    const severitiesToStrings = (severities?: Array<Severity>): string => {
      if (!severities) {
        return ''
      }

      const strings = severities.map((severity) => {
        switch (severity) {
          case Severity.ERROR:
            return 'Error'
          case Severity.WARNING:
            return 'Warning'
          case Severity.INFO:
            return 'Info'
          case Severity.TRACE:
            return 'Trace'
          default:
            throw new Error(`Unrecognized severity value: ${severity}`)
        }
      })
      return strings.join(', ')
    }
    return data.map(
      (v: WebhookValueInterface): VIRow => [
        {
          // Event type
          name: DataHelper.getWebhooksEventType(v.eventType),
        },
        // Version
        { name: String(v.version).toUpperCase() },
        // Severity
        {
          name: severitiesToStrings(v.severities as Array<Severity>),
        },

        // Delete icon
        {
          name: '',
          value: v.id,
        },
      ]
    )
  }

  public static assetsVIWithSortingByTypeAndSelected(
    assets: Array<AssetWithRelatedAssets<Asset>>,
    selectedAssets: Array<AssetWithRelatedAssets<Asset>>,
    assetsMissingInLiveAssets: Array<string>,
    lastBackups: Map<string, ElastioRecoveryPoint> | null
  ): Record<string, VIMatrix> {
    const data = TableFactory.assetsInventoryPoliciesVI(
      assets,
      assetsMissingInLiveAssets,
      lastBackups
    )

    // set up checkboxes
    data?.forEach((row: VIRow) => {
      const isSelected = selectedAssets.find((v) => v.asset.id === row[0]?.id)

      if (row[0]?.value !== undefined) {
        // main assets checked
        row[0].value =
          isSelected && !row[0]?.selectedOption?.disabled
            ? CheckboxConstant.Checked
            : CheckboxConstant.Empty
      }

      if (row[0]?.matrix?.length !== 0) {
        row[0]?.matrix?.forEach((subRowId) => {
          const isSubSelected = selectedAssets.find(
            (vv) => vv.asset?.id === subRowId[0]?.id
          )

          if (row[0]?.value !== undefined) {
            // main asset checked
            row[0].value = isSelected
              ? CheckboxConstant.Checked
              : CheckboxConstant.Empty

            if (isSubSelected) {
              // sub asset disabled and Indeterminated
              row[0].value = CheckboxConstant.Indeterminate

              if (row[0]?.selectedOption) {
                row[0].selectedOption.disabled = true
              }
            }
          }
        })
      }
      return row
    })

    return {
      EBS: data.filter(
        (row) =>
          row[0]?.selectedOption?.type ===
          AssetKindParsedConstant.KIND_AWS_EBS.value
      ),
      EC2: data.filter(
        (row) =>
          row[0]?.selectedOption?.type ===
          AssetKindParsedConstant.KIND_AWS_EC2.value
      ),
      S3: data.filter(
        (row) =>
          row[0]?.selectedOption?.type ===
          AssetKindParsedConstant.KIND_S3_BUCKET.value
      ),
      EFS: data.filter(
        (row) =>
          row[0]?.selectedOption?.type ===
          AssetKindParsedConstant.KIND_AWS_EFS.value
      ),
      othersAssetKind: data.filter(
        (row) =>
          row[0]?.selectedOption?.type !==
            AssetKindParsedConstant.KIND_AWS_EBS.value &&
          row[0]?.selectedOption?.type !==
            AssetKindParsedConstant.KIND_AWS_EC2.value &&
          row[0]?.selectedOption?.type !==
            AssetKindParsedConstant.KIND_S3_BUCKET.value &&
          row[0]?.selectedOption?.type !==
            AssetKindParsedConstant.KIND_AWS_EFS.value
      ),
    }
  }

  public static assetsS3PoliciesVI(
    data: Array<AssetWithRelatedAssets<Asset>>
  ): VIMatrix {
    return data?.map((item) => {
      const assetTypeString = LangHelper.getAssetKindSingleTranslation(
        item.type
      )

      const result: VIRow = [
        {
          id: item.asset?.id,
          name: '',
          type: AssetsEntityType.Checkbox,
          value: CheckboxConstant.Empty,
          selectedOption: {
            name: String(item.asset?.id),
            label: item.asset?.id,
            //disabled: item.asset?.isProtected(),
            // value: asset.accountAliasName,
            extraValue: item.asset?.awsAccountId,
            type: item.type,
            selectedOption: {
              name: LangHelper.getAwsRegionSingleTranslation(
                item.asset?.awsRegion
              ),
              label: String(item.asset?.awsRegion),
            },
          },
          matrix: [],
        },
        {
          name: '',
          type: AssetsEntityType.Icon,
        },
        {
          type: AssetsEntityType.Link,
          name: '',
          // TODO: uncommented link, when s3 page will be ready)
          // item?.asset?.getCloudConnectorId() &&
          // item?.asset?.getCloudProviderAssetId()
          //   ? PageHelper.buildItemUrl(
          //       item.asset?.getParsedCloudProviderAssetId(),
          //       TypeHelper.getAssetKindForInventory(item.type)
          //     )
          //   : '',
          label: item?.asset?.awsId,
          value: String(item?.asset?.name),
        },
        { name: assetTypeString },
        { name: item?.asset?.awsAccountId },
        {
          name: LangHelper.getAwsRegionSingleTranslation(
            item?.asset?.awsRegion
          ),
        },
        {
          label:
            item?.asset.backupPolicies.length > 0
              ? item?.asset?.backupPolicies?.join()
              : 'Not assigned',
          type:
            item?.asset.backupPolicies.length > 0
              ? AssetsEntityType.Link
              : AssetsEntityType.Text,
          name: PagePathConstant.POLICIES,
        },
        {
          id: item.asset?.id,
          name: '',
          label: 'All paths',
          //disabled: item.asset?.isProtected(),
          value: CheckboxConstant.Empty,
        },
        {
          id: item.asset?.id,
          name: '',
          label: 'Select paths...',
          //disabled: item.asset?.isProtected(),
        },
      ]
      return result
    })
  }

  public static assetsVIS3WithSelected(
    assets: Array<AssetWithRelatedAssets<Asset>>,
    selectedAssets: Array<AssetWithRelatedAssets<Asset>>,
    selectedAllPaths: Array<string>,
    selectedSpecificPaths: Array<SelectSpecificPathsInterface>
  ): VIMatrix {
    const data = TableFactory.assetsS3PoliciesVI(assets)
    // set up checkboxes
    data?.forEach((row: VIRow) => {
      const isSelected = selectedAssets?.find((v) => v.asset?.id === row[0]?.id)
      const isAllPathsSelected = selectedAllPaths?.find((v) => v === row[7]?.id)
      const specificPaths = selectedSpecificPaths?.find(
        (v) => v.asset.asset?.id === row[0]?.id
      )
      if (row[0]?.value !== undefined) {
        // main assets checked
        row[0].value =
          isSelected && !row[0]?.selectedOption?.disabled
            ? CheckboxConstant.Checked
            : CheckboxConstant.Empty
      }

      if (row[0]?.matrix !== undefined) {
        if (
          specificPaths !== undefined &&
          specificPaths.selectedPaths !== undefined
        ) {
          Object.entries(specificPaths.selectedPaths).forEach(
            ([key, value]: [string, Array<string>]) => {
              row[0]?.matrix?.push([
                {
                  name: key,
                  messages: value,
                },
              ])
            }
          )
        } else {
          row[0].matrix = []
        }
      }

      if (row[7]?.value !== undefined) {
        // all paths checked
        row[7].value =
          isAllPathsSelected && !row[7]?.disabled
            ? CheckboxConstant.Checked
            : CheckboxConstant.Empty

        if (row[8]?.disabled !== undefined) {
          row[8].disabled =
            row[7]?.value === CheckboxConstant.Checked ? true : false
        }
      }

      return row
    })

    return data
  }
}

export default TableFactory
