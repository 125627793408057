"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloudConnectorStatus = void 0;
var CloudConnectorStatus;
(function (CloudConnectorStatus) {
    CloudConnectorStatus[CloudConnectorStatus["ACTIVE"] = 0] = "ACTIVE";
    CloudConnectorStatus[CloudConnectorStatus["INACTIVE"] = 1] = "INACTIVE";
    CloudConnectorStatus[CloudConnectorStatus["DELETED"] = 2] = "DELETED";
    CloudConnectorStatus[CloudConnectorStatus["UPGRADE_REQUIRED"] = 3] = "UPGRADE_REQUIRED";
})(CloudConnectorStatus = exports.CloudConnectorStatus || (exports.CloudConnectorStatus = {}));
var IntegrityCheckErrorKind;
(function (IntegrityCheckErrorKind) {
    IntegrityCheckErrorKind[IntegrityCheckErrorKind["INFRASTRUCTURE"] = 0] = "INFRASTRUCTURE";
})(IntegrityCheckErrorKind || (IntegrityCheckErrorKind = {}));
