import { useEffect, useState } from 'react'

const LD_API_ROUTE = '/api/launchdarkly/fetch-api-key'

/**
 * Fetches the LaunchDarkly API key from the Next.JS server.
 * Main idea is to use this hook to fetch the API key and then use it in the `createFeatureFlagProvider` function.
 * Because of server-side rendering, we can not get .env variables on the client side directly, because they are undefined.
 */
export function useFetchLaunchDarklyApiKey() {
  const [ldApiKey, setLdApiKey] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchLaunchDarklyApiKey() {
      setIsLoading(true)
      const apiKey = await fetch(LD_API_ROUTE)
      const response = await apiKey.json()
      setLdApiKey(response.launchdarklyApi)
      setIsLoading(false)
    }

    fetchLaunchDarklyApiKey()
  }, [])

  return {
    ldApiKey,
    isLoading,
  }
}
