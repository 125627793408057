"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PercentageProgress = void 0;
const FRACTION_TO_PERCENTAGE = 100;
class PercentageProgress {
    #fraction;
    #rate;
    constructor(parameters) {
        const { fraction, rate } = parameters;
        this.#fraction = fraction;
        this.#rate = rate;
    }
    get rate() {
        return this.#rate;
    }
    get progress() {
        return this.#fraction * FRACTION_TO_PERCENTAGE;
    }
}
exports.PercentageProgress = PercentageProgress;
