import React from 'react'

function ArrowDropUpIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_12268_33652"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_12268_33652)">
        <path d="M12 10L17 15L7 15L12 10Z" fill="#B2B5C1" />
      </g>
    </svg>
  )
}

export default ArrowDropUpIcon
