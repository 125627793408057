import SessionModel from '@lib/models/session.model'
import NumHelper from '@lib/helpers/num.helper'
import TypeHelper from '@lib/helpers/type.helper'

abstract class SessionFactory {
  public static build(params: any): SessionModel {
    return new SessionModel({
      id: NumHelper.numberHash(params.id),
      innerId: TypeHelper.stringValDef(params.id, ''),
      location: TypeHelper.stringValDef(params.location, ''),
      agent: TypeHelper.stringValDef(params.agent, ''),
      date: TypeHelper.momentVal(params.date),
      ip: TypeHelper.stringValDef(params.ip, ''),
      device: TypeHelper.stringValDef(params.device, ''),
    })
  }
}

export default SessionFactory
