import StoreService from '@lib/services/store.service'

const AUTH0_LOGOUT_ERROR = 'Auth0 logout function is not available.'
const STORE_SERVICE_LOGOUT_ERROR =
  'StoreService logout function is not available.'

const performAuth0Logout = async (auth0Logout: () => Promise<void>) => {
  if (!auth0Logout) {
    throw new Error(AUTH0_LOGOUT_ERROR)
  }
  await auth0Logout()
}

const performStoreServiceLogout = async () => {
  if (!StoreService?.logout) {
    throw new Error(STORE_SERVICE_LOGOUT_ERROR)
  }
  await StoreService.logout()
}

export const performLogout = async (
  auth0Spa: boolean,
  auth0Logout: () => Promise<void>
) => {
  if (auth0Spa) {
    await performAuth0Logout(auth0Logout)
  } else {
    await performStoreServiceLogout()
  }
}
