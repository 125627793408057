import {
  CLOUD_PROVIDER_ASSET_ID_QUERY_KEY,
  PagePathConstant,
} from '@lib/constants'
import { StrHelper } from '@lib/helpers'
import { RecoveryPointAssetIdentifier } from './types'

interface MountUrlParams {
  rpId: string
  assetId: string
  path: PagePathConstant
}

function composeMountUrl({ rpId, assetId, path }: MountUrlParams) {
  const encodedAssetId = StrHelper.base64Encode(assetId)

  return `${path}?${CLOUD_PROVIDER_ASSET_ID_QUERY_KEY}=${encodedAssetId}&uuid=${rpId}`
}

export function composeMountInstanceUrl({
  rpId,
  assetId,
}: RecoveryPointAssetIdentifier) {
  const path = PagePathConstant.MOUNT_EC2_RECOVERY_POINT

  return composeMountUrl({
    rpId,
    assetId,
    path,
  })
}

export function composeMountVolumeUrl({
  rpId,
  assetId,
}: RecoveryPointAssetIdentifier) {
  const path = PagePathConstant.MOUNT_EBS_RECOVERY_POINT

  return composeMountUrl({
    rpId,
    assetId,
    path,
  })
}
