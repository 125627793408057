"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listVolumesWithActiveThreatsPossibleFilters = void 0;
const types_1 = require("../../models/threats/types");
class listVolumesWithActiveThreatsPossibleFilters {
    #volumes;
    #threats;
    constructor(parameters) {
        this.#volumes = parameters.volumes;
        this.#threats = parameters.threats;
    }
    execute() {
        const accountIds = new Set();
        const regions = new Set();
        const malware = new Set();
        const ransomware = new Set();
        const fsCheck = new Set();
        this.#volumes.forEach((volume) => {
            const volumeThreats = this.#threats.filter(({ source: { asset, assetItem } }) => asset?.assetId === volume.id || assetItem?.assetId === volume.id);
            volumeThreats.forEach((threat) => {
                if (threat.kind === types_1.ThreatKind.RANSOMWARE) {
                    ransomware.add(threat.name);
                }
                if (threat.kind === types_1.ThreatKind.MALWARE) {
                    malware.add(threat.name);
                }
                if (threat.kind === types_1.ThreatKind.FILESYSTEM_ERROR) {
                    fsCheck.add(threat.name);
                }
            });
            accountIds.add(volume.awsAccountId);
            regions.add(volume.awsRegion);
        });
        return {
            possibleVolumesWithActiveThreatsFilters: {
                accountIds: [...accountIds],
                regions: [...regions],
                malwaresList: [...malware],
                ransomwaresList: [...ransomware],
                fsCheck: [...fsCheck],
                idSearch: '',
            },
        };
    }
}
exports.listVolumesWithActiveThreatsPossibleFilters = listVolumesWithActiveThreatsPossibleFilters;
