"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListS3BucketsWithThreats = void 0;
const malware_1 = require("../../models/scans/malware");
const types_1 = require("../../models/threats/types");
class ListS3BucketsWithThreats {
    #s3buckets;
    #threats;
    #lastBackups;
    #lastScans;
    #bucketsWithScans;
    constructor(parameters) {
        this.#s3buckets = parameters.s3buckets;
        this.#threats = parameters.threats;
        this.#lastBackups = parameters.lastBackups;
        this.#lastScans = parameters.lastScans;
        this.#bucketsWithScans ||= this.#getBucketsWithScans();
    }
    execute(filters) {
        const s3buckets = this.#findS3BucketsMatchingFilters(filters);
        return this.#prepareOutput(s3buckets);
    }
    #prepareOutput(buckets) {
        return buckets.map((bucket) => {
            return {
                isInfected: this.#isInfected(bucket),
                name: bucket.name,
                threats: this.#getThreatsForBucket(bucket),
                accountId: bucket.awsAccountId,
                region: bucket.awsRegion,
                status: bucket.state,
                lastScan: this.#getLastScanForBucket(bucket),
                lastBackup: this.#getLastBackup(bucket),
                s3Id: bucket.id,
                s3AwsId: bucket.awsId,
            };
        });
    }
    #isInfected(bucket) {
        return this.#getThreatsForBucket(bucket).length > 0;
    }
    #getBucketsWithThreats() {
        return this.#s3buckets.filter((bucket) => this.#isInfected(bucket));
    }
    #getThreatsForBucket(bucket) {
        return this.#threats.filter(({ source: { asset, assetItem } }) => asset?.assetId === bucket.id || assetItem?.assetId === bucket.id);
    }
    #getLastBackup(bucket) {
        return this.#lastBackups.get(bucket.id);
    }
    #getLastScanForBucket(bucket) {
        const scan = this.#bucketsWithScans.find((scan) => scan.bucket.id === bucket.id);
        if (!scan) {
            return null;
        }
        const scansTime = scan.scans.map(({ createdAt }) => createdAt.getTime());
        if (!scansTime.length) {
            return null;
        }
        return new Date(Math.max(...scansTime));
    }
    #mapBucketToScans(bucket) {
        const scans = this.#filterScansForS3Bucket(bucket, this.#lastScans);
        return { bucket: bucket, scans };
    }
    #getBucketsWithScans() {
        return this.#s3buckets.map(this.#mapBucketToScans.bind(this));
    }
    #findS3BucketsMatchingFilters(filters) {
        return this.#getBucketsWithThreats().filter((bucket) => {
            if (!this.#matchesIncludeFilter(filters.accountIds, bucket.awsAccountId)) {
                return false;
            }
            if (!this.#matchesIncludeFilter(filters.regions, bucket.awsRegion)) {
                return false;
            }
            if (filters.malwaresList &&
                !this.#getBucketThreatByKind(bucket, types_1.ThreatKind.MALWARE).some((malware) => filters.malwaresList?.includes(malware))) {
                return false;
            }
            if (filters.ransomwaresList &&
                !this.#getBucketThreatByKind(bucket, types_1.ThreatKind.RANSOMWARE).some((ransomware) => filters.ransomwaresList?.includes(ransomware))) {
                return false;
            }
            if (filters.fsCheck &&
                !this.#getBucketThreatByKind(bucket, types_1.ThreatKind.FILESYSTEM_ERROR).some((fs) => filters.fsCheck?.includes(fs))) {
                return false;
            }
            if (filters.idSearch &&
                !this.#getS3SearchMatches(filters.idSearch, bucket)) {
                return false;
            }
            return true;
        });
    }
    #getS3SearchMatches(searchValue, s3) {
        const idMatches = s3.awsId.includes(searchValue);
        const nameMatches = s3.name.includes(searchValue);
        const tagsMatches = s3.tags.some(({ key, value }) => key.includes(searchValue) || value.includes(searchValue));
        return idMatches || nameMatches || tagsMatches;
    }
    #getBucketThreatByKind(bucket, kind) {
        return this.#threats
            .filter((threat) => {
            const isBucketThreat = threat.source.asset?.assetId === bucket.id ||
                threat.source.assetItem?.assetId === bucket.id;
            return isBucketThreat && threat.kind === kind;
        })
            .map((threat) => threat.name);
    }
    #matchesIncludeFilter(filters, value) {
        if (!filters) {
            return true;
        }
        if (!value) {
            return false;
        }
        return filters.includes(value);
    }
    #isMalwareScanTarget(target, bucket) {
        if (target instanceof malware_1.MalwareScanBackup && target.source.asset) {
            return target.source.asset.backupAssetId === bucket.id;
        }
        if ('assetId' in target) {
            return target.assetId === bucket.id;
        }
        return false;
    }
    #filterScansForS3Bucket(bucket, scans) {
        return scans.filter((scan) => {
            const malwareScanMatches = this.#isMalwareScanTarget(scan.scanTarget.target, bucket);
            return malwareScanMatches;
        });
    }
}
exports.ListS3BucketsWithThreats = ListS3BucketsWithThreats;
