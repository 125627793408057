import React from 'react'
import Button from '@mui/material/Button'
import { CSVLink } from 'react-csv'
import type { CommonPropTypes } from 'react-csv/components/CommonPropTypes'
import { Stack } from '@mui/material'

interface Props extends CommonPropTypes {
  buttonText?: string
  shouldShow?: boolean
  marginBottom?: number
  disabled?: boolean
}

const DEFAULT_SEPARATOR = ','
const DEFAULT_BUTTON_TEXT = 'Export as CSV'
const DEFAULT_MARGIN_BOTTOM = 1

export function ExportAsCsv(props: Props) {
  const {
    buttonText,
    shouldShow = true,
    marginBottom = DEFAULT_MARGIN_BOTTOM,
    disabled = false,
    ...csvLinkProps
  } = props

  if (!shouldShow) {
    return null
  }

  return (
    <Stack
      width="min-content"
      marginLeft="auto"
      marginRight={1}
      marginBottom={marginBottom}
    >
      <CSVLink {...csvLinkProps} separator={DEFAULT_SEPARATOR}>
        <Button variant="contained" color="primary" disabled={disabled}>
          {buttonText || DEFAULT_BUTTON_TEXT}
        </Button>
      </CSVLink>
    </Stack>
  )
}
