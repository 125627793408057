import { useState } from 'react'
import { DataFetchStatus } from '@lib/hooks/api-hooks/types'

type FetchStatus = {
  status: DataFetchStatus
  isLoading: () => boolean
  reset: () => void
  isIdle: () => boolean
  setSuccess: () => void
  setFailure: () => void
  setFetching: () => void
}

export const useFetchStatus = (): FetchStatus => {
  const [status, setStatus] = useState<DataFetchStatus>(DataFetchStatus.IDLE)

  const isLoading = () => status === DataFetchStatus.IS_LOADING

  const isIdle = () => status === DataFetchStatus.IDLE
  const reset = () => setStatus(DataFetchStatus.IDLE)
  const setSuccess = () => setStatus(DataFetchStatus.SUCCESS)
  const setFailure = () => setStatus(DataFetchStatus.FAILURE)
  const setFetching = () => setStatus(DataFetchStatus.IS_LOADING)

  return {
    status,
    isLoading,
    isIdle,
    reset,
    setSuccess,
    setFailure,
    setFetching,
  }
}
