import React from 'react'

function ToastWarningIcon() {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.43551 14.5001H14.9855C16.2688 14.5001 17.0688 13.1084 16.4272 12.0001L10.1522 1.1584C9.51051 0.0500651 7.91051 0.0500651 7.26884 1.1584L0.993843 12.0001C0.352176 13.1084 1.15218 14.5001 2.43551 14.5001ZM8.71051 8.66673C8.25217 8.66673 7.87717 8.29173 7.87717 7.8334V6.16673C7.87717 5.7084 8.25217 5.3334 8.71051 5.3334C9.16884 5.3334 9.54384 5.7084 9.54384 6.16673V7.8334C9.54384 8.29173 9.16884 8.66673 8.71051 8.66673ZM9.54384 12.0001H7.87717V10.3334H9.54384V12.0001Z"
        fill="#FFAB2D"
      />
    </svg>
  )
}

export default ToastWarningIcon
