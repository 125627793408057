"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListInstanceScans = exports.ListInstanceScansTypes = void 0;
const ransomware_1 = require("../../models/scans/ransomware");
const malware_1 = require("../../models/scans/malware");
const types_1 = require("../../models/scans/filesystem-check/types");
const types_2 = require("../../models/scans/malware/types");
const types_3 = require("../../models/scans/ransomware/types");
const models_1 = require("../../models");
exports.ListInstanceScansTypes = __importStar(require("./types"));
class ListInstanceScans {
    execute(scans) {
        return this.#prepareOutput(scans);
    }
    #prepareOutput(scans) {
        const scansOutput = scans.map((scan) => {
            const aggregatedRansomwareScan = this.#aggregateRansomwareScans(scan.ransomwareScansList);
            const aggregatedMalwareScan = this.#aggregateMalwareScans(scan.malwareScansList);
            const aggregatedFsCheckScan = this.#aggregateFileSystemScans(scan.filesystemChecksList);
            const ransomwareStatus = aggregatedRansomwareScan.aggregatedScanResult;
            const malwareStatus = aggregatedMalwareScan.aggregatedScanResult;
            const fsCheckStatus = aggregatedFsCheckScan.aggregatedScanResult;
            const lastRansomwareScannedAt = aggregatedRansomwareScan.aggregatedCompletedAt;
            const lastMalwareScannedAt = aggregatedMalwareScan.aggregatedCompletedAt;
            const lastFsCheckScannedAt = aggregatedFsCheckScan.aggregatedCompletedAt;
            const lastRansomwareScan = aggregatedRansomwareScan.lastScan;
            const lastMalwareScan = aggregatedMalwareScan.lastScan;
            const lastFsCheckScan = aggregatedFsCheckScan.lastScan;
            return {
                isHealthy: this.#getIsScansClean({
                    ransomwareScanResult: ransomwareStatus,
                    malwareScanResult: malwareStatus,
                    fsCheckScanResult: fsCheckStatus,
                }),
                ransomwareStatus: aggregatedRansomwareScan.aggregatedScanResult,
                malwareStatus: aggregatedMalwareScan.aggregatedScanResult,
                fsCheckStatus: aggregatedFsCheckScan.aggregatedScanResult,
                completeTime: this.#getLastCompleteTime([
                    lastRansomwareScannedAt,
                    lastMalwareScannedAt,
                    lastFsCheckScannedAt,
                ]),
                dataSource: this.#getDataSource([
                    lastRansomwareScan,
                    lastMalwareScan,
                    lastFsCheckScan,
                ]),
            };
        });
        return {
            scans: scansOutput,
        };
    }
    #getIsScansClean({ malwareScanResult, ransomwareScanResult, fsCheckScanResult, }) {
        const scanWithoutFailed = malwareScanResult !== types_2.MalwareScanStatus.INFECTED &&
            ransomwareScanResult !== types_3.RansomwareScanStatus.INFECTED &&
            fsCheckScanResult !== types_1.StatusScanFilesystemCheck.BAD;
        return scanWithoutFailed;
    }
    #getLastCompleteTime(scansTime) {
        return scansTime.reduce((lastCompleteTime, scan) => {
            if (scan && scan > lastCompleteTime) {
                return scan;
            }
            return lastCompleteTime;
        }, new Date(0));
    }
    #getDataSource(scans) {
        const source = {
            backupId: null,
            text: 'Direct scan',
            date: undefined,
            assetItemId: null,
        };
        scans.forEach((scan) => {
            if (!scan) {
                return null;
            }
            if ('assetItemId' in scan.scanTarget.target) {
                source.assetItemId = scan.scanTarget.target.assetItemId;
            }
            if (scan.scanTarget.target instanceof malware_1.MalwareScanBackup ||
                scan.scanTarget.target instanceof ransomware_1.RansomwareScanBackup ||
                scan.scanTarget.target instanceof models_1.FilesystemScanBackup) {
                if (scan.scanTarget.target.provider === types_2.Provider.ELASTIO) {
                    const sourceText = scan.scanTarget.target.source.assetItem
                        ? 'Asset Item Elastio Backup'
                        : 'Elastio Backup';
                    source.text = sourceText;
                }
                if (scan.scanTarget.target.provider === types_2.Provider.AWS) {
                    source.text = 'AWS Backup';
                }
                source.date = scan.scanTarget.target.timestamp;
                source.backupId = scan.scanTarget.target.backupId;
                source.assetItemId = scan.scanTarget.target.source.assetItem
                    ? scan.scanTarget.target.source.assetItem.backupAssetItemId
                    : null;
            }
        });
        return source;
    }
    #aggregateMalwareScans(scans) {
        const aggregatedScanResult = scans.length > 0
            ? scans.reduce((acc, scan) => {
                if (scan.status === types_2.MalwareScanStatus.CLEAN) {
                    return acc;
                }
                return scan.status;
            }, types_2.MalwareScanStatus.CLEAN)
            : null;
        const aggregatedScanTime = Math.max(...scans.map((scan) => scan.completedAt.getTime()));
        const lastScan = scans.find((scan) => scan.completedAt.getTime() === aggregatedScanTime);
        const aggregatedCompletedAt = () => {
            if (!lastScan) {
                return new Date(0);
            }
            return lastScan.completedAt;
        };
        const aggregatedScanTypeSource = () => {
            const directVolumeScansCount = scans.filter((scan) => 'assetId' in scan.scanTarget.target ||
                'assetItemId' in scan.scanTarget.target).length;
            if (directVolumeScansCount > 0) {
                return {
                    text: 'Direct scan',
                    backupId: null,
                    date: lastScan.completedAt,
                    assetItemId: null,
                };
            }
            const backupsVolumeScansCount = scans.filter((scan) => scan.scanTarget.target instanceof malware_1.MalwareScanBackup).length;
            if (backupsVolumeScansCount > 0) {
                const scan = lastScan.scanTarget.target;
                if (scan.provider === types_2.Provider.ELASTIO) {
                    return {
                        text: 'Elastio Backup',
                        backupId: scan.backupId,
                        date: lastScan.completedAt,
                        assetItemId: null,
                    };
                }
                if (scan.provider === types_2.Provider.AWS) {
                    return {
                        text: 'AWS Backup',
                        backupId: scan.backupId,
                        date: scan.timestamp,
                        assetItemId: null,
                    };
                }
            }
            return {
                backupId: null,
                text: 'Direct scan',
                date: undefined,
                assetItemId: null,
            };
        };
        return {
            aggregatedScanResult,
            aggregatedScanTime,
            aggregatedSource: aggregatedScanTypeSource(),
            aggregatedCompletedAt: aggregatedCompletedAt(),
            lastScan,
        };
    }
    #aggregateRansomwareScans(scans) {
        const aggregatedScanResult = scans.length > 0
            ? scans.reduce((acc, scan) => {
                if (scan.status === types_3.RansomwareScanStatus.CLEAN) {
                    return acc;
                }
                return scan.status;
            }, types_3.RansomwareScanStatus.CLEAN)
            : null;
        const directVolumeScansCount = scans.filter((scan) => 'assetId' in scan.scanTarget.target ||
            'assetItemId' in scan.scanTarget.target).length;
        const backupsVolumeScansCount = scans.filter((scan) => scan.scanTarget.target instanceof ransomware_1.RansomwareScanBackup).length;
        const aggregatedScanTime = Math.max(...scans.map((scan) => scan.completedAt.getTime()));
        const lastScan = scans.find((scan) => scan.completedAt.getTime() === aggregatedScanTime);
        const aggregatedCompletedAt = () => {
            if (!lastScan) {
                return new Date(0);
            }
            return lastScan.completedAt;
        };
        const aggregatedScanTypeSource = () => {
            if (directVolumeScansCount > 0) {
                return {
                    text: 'Direct scan',
                    backupId: null,
                    date: lastScan.completedAt,
                    assetItemId: null,
                };
            }
            if (backupsVolumeScansCount > 0) {
                const scan = lastScan.scanTarget.target;
                if (scan.provider === types_2.Provider.ELASTIO) {
                    return {
                        text: 'Elastio Backup',
                        backupId: scan.backupId,
                        date: scan.timestamp,
                        assetItemId: null,
                    };
                }
                if (scan.provider === types_2.Provider.AWS) {
                    return {
                        text: 'AWS Backup',
                        backupId: scan.backupId,
                        date: scan.timestamp,
                        assetItemId: null,
                    };
                }
            }
            return {
                backupId: null,
                text: 'Direct scan',
                date: undefined,
                assetItemId: null,
            };
        };
        return {
            aggregatedScanResult,
            aggregatedScanTime,
            aggregatedSource: aggregatedScanTypeSource(),
            aggregatedCompletedAt: aggregatedCompletedAt(),
            lastScan,
        };
    }
    #aggregateFileSystemScans(scans) {
        const aggregatedScanResult = scans.length > 0
            ? scans.reduce((acc, scan) => {
                if (scan.status === types_1.StatusScanFilesystemCheck.GOOD) {
                    return acc;
                }
                return scan.status;
            }, types_1.StatusScanFilesystemCheck.GOOD)
            : null;
        const directVolumeScansCount = scans.filter((scan) => 'assetId' in scan.scanTarget.target ||
            'assetItemId' in scan.scanTarget.target).length;
        const backupsVolumeScansCount = scans.filter((scan) => scan.scanTarget.target instanceof models_1.FilesystemScanBackup).length;
        const aggregatedScanTime = Math.max(...scans.map((scan) => scan.completedAt.getTime()));
        const lastScan = scans.find((scan) => scan.completedAt.getTime() === aggregatedScanTime);
        const aggregatedCompletedAt = () => {
            if (!lastScan) {
                return new Date(0);
            }
            return lastScan.completedAt;
        };
        const aggregatedScanTypeSource = () => {
            if (directVolumeScansCount > 0) {
                return {
                    text: 'Direct scan',
                    backupId: null,
                    date: lastScan.completedAt,
                    assetItemId: null,
                };
            }
            if (backupsVolumeScansCount > 0) {
                const scan = lastScan.scanTarget.target;
                if (scan.provider === types_2.Provider.ELASTIO) {
                    return {
                        text: 'Elastio Backup',
                        backupId: scan.backupId,
                        date: scan.timestamp,
                        assetItemId: null,
                    };
                }
                if (scan.provider === types_2.Provider.AWS) {
                    return {
                        text: 'AWS Backup',
                        backupId: scan.backupId,
                        date: scan.timestamp,
                        assetItemId: null,
                    };
                }
            }
            return {
                backupId: null,
                text: 'Direct scan',
                date: undefined,
                assetItemId: null,
            };
        };
        return {
            aggregatedScanResult,
            aggregatedSource: aggregatedScanTypeSource(),
            aggregatedScanTime,
            aggregatedCompletedAt: aggregatedCompletedAt(),
            lastScan,
        };
    }
}
exports.ListInstanceScans = ListInstanceScans;
