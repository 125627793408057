import { sentrySimpleReThrowHandler } from '@store/epics/epic-func'
import {
  TenantJobsClient,
  PossibleTenantJobFilters,
} from '@lib/clients/jobs/tenant-jobs-client'
import { useEffect, useMemo, useRef, useState } from 'react'
import { TimeRange } from '@lib/clients/types'
import { ObjHelper } from '@lib/helpers'

const client = new TenantJobsClient()

export function useFetchPossibleTenantJobFilters(timeRange: TimeRange) {
  const [possibleFilters, setPossibleFilters] =
    useState<PossibleTenantJobFilters>({
      kindsList: [],
      statusesList: [],
    })

  const previousTimeRange = useRef<TimeRange | undefined>(undefined)

  const isTimeRangeChanged = useMemo(() => {
    if (!timeRange || !timeRange?.start || !timeRange?.end) {
      return false
    }

    return ObjHelper.isNotEqual(previousTimeRange.current, timeRange)
  }, [
    timeRange?.end,
    timeRange?.start,
    previousTimeRange.current?.start,
    previousTimeRange.current?.end,
  ])

  useEffect(() => {
    let ignore = false
    async function getTenantJobFilters() {
      try {
        const response = await client.getTenantJobFilters(timeRange)

        previousTimeRange.current = timeRange

        if (!ignore) {
          setPossibleFilters(response)
        }
      } catch (error) {
        if (error instanceof Error) {
          sentrySimpleReThrowHandler(error)
        }

        console.error('Error fetching tenant job filters', error)
      }
    }

    if (!isTimeRangeChanged) {
      return
    }

    getTenantJobFilters()

    return () => {
      ignore = true
    }
  }, [isTimeRangeChanged])

  return possibleFilters
}
