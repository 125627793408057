import PoliciesState from '@store/states/policies.state'
import ActionInterface from '@lib/interfaces/action.interface'
import { SET_POLICIES } from '@store/actions/policies.action'

const initialState: PoliciesState = {
  policies: [],
  policiesCount: 0,
}

function policiesReducer(
  prevState: PoliciesState = initialState,
  action: ActionInterface
): PoliciesState {
  switch (action.type) {
    case SET_POLICIES:
      return {
        ...prevState,
        policies: action.payload,
        policiesCount: action.payload.length,
      }

    default:
      return prevState
  }
}

export default policiesReducer
