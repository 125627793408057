import {
  Notification,
  NotificationAttrs,
} from '@features/scheduled-jobs-monitoring/models'
import Dexie from 'dexie'
import { Repository } from './repository'
import { Logger } from '@features/scheduled-jobs-monitoring/use-cases'

export type ModelId = string

const NOTIFICATIONS = 'notifications'
const NOTIFICATIONS_TABLE = 'notifications'

// TODO We need to update the TypeScript to fix these issues.
// @ts-ignore
class NotificationsDB extends Dexie {
  constructor(name: string) {
    super(name)
    this.version(1).stores({
      [NOTIFICATIONS]: '++id, text, status, kind, createdAt',
    })
  }
}

export class NotificationsRepository extends Repository<
  NotificationAttrs,
  Notification,
  ModelId
> {
  #db: NotificationsDB

  constructor(protected logger: Logger) {
    super(logger)
    this.#db = new NotificationsDB(NOTIFICATIONS)
  }

  protected instantiateModel(attr: NotificationAttrs): Notification {
    return new Notification(attr)
  }

  protected get table(): Dexie.Table<NotificationAttrs> {
    return this.db.table(this.tableName)
  }

  private get tableName(): string {
    return NOTIFICATIONS_TABLE
  }

  protected get db(): Dexie {
    return this.#db
  }
}
