import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getUser } from '@store/selectors/user.selector'
import * as FullStoryImpl from '@fullstory/browser'
import { FULL_STORY_ORG_ID } from '@lib/constants/app.constant'
import SystemHelper from '@lib/helpers/system.helper'

function FullStory() {
  const user = useSelector(getUser)

  useEffect(() => {
    try {
      if (SystemHelper.isProductionEnv()) {
        FullStoryImpl.init({
          orgId: FULL_STORY_ORG_ID,
        })
      }
    } catch (_e) {
      SystemHelper.throwErrorInLocalEnv('FullStory was not loaded')
    }
  }, [])

  useEffect(() => {
    try {
      if (SystemHelper.isProductionEnv() && user.isNotEmpty) {
        FullStoryImpl.identify(user.innerId, {
          email: user.email,
          name: user.name,
          tenant: SystemHelper.getCurrentTenantName(),
        })
      }
    } catch (_e) {
      SystemHelper.throwErrorInLocalEnv('FullStory - identifying error')
    }
  }, [user.isNotEmpty])

  return null
}

export default FullStory
