import WebhookModel, {
  ApiKey,
  BasicAuth,
  BearerToken,
  WebhookModelAttributes,
} from '@lib/models/webhook.model'
import { ListEventsRequest } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/notifications_pb'
import { WebhooksPromiseClient } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/webhooks_grpc_web_pb'
import {
  ApiKey as ApiKeyGrpc,
  BasicAuth as BasicAuthGrpc,
  BearerToken as BearerTokenGrpc,
  CreateWebhookRequest,
  DeleteWebhookRequest,
  GetWebhookRequest,
  ListWebhookInvocationsRequest,
  ListWebhooksRequest,
  SecretString,
  UpdateWebhookRequest,
  Webhook,
  WebhookAuthentication,
  WebhookEventSubscription,
  WebhookInvocationPeriod,
  TestWebhookRequest,
  TestNewWebhook,
} from 'blue-stack-libs/notifications-grpc-libs/js/notifications/webhooks_pb'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import GrpcBaseService from '../base/grpc-base.service'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { DefinedDateRangeTuple } from '@lib/engine-types'

class GrpcWebhookService extends GrpcBaseService {
  protected static client = new WebhooksPromiseClient('', null, null)
  protected static clientName = 'Webhook'

  public static buildWebhookFromModel(webhook: WebhookModel): Webhook {
    const newWebhook = new Webhook()
    const subscriptions: Array<WebhookEventSubscription> = []
    const authRequest = new WebhookAuthentication()

    const getAuthType = () => {
      const auth = webhook.getAuthentication().intoInner()
      if (auth instanceof BearerToken) {
        const token = new BearerTokenGrpc()
        const secret = new SecretString()
        return authRequest.setBearerToken(
          token.setToken(
            auth.isTokenEncrypted()
              ? secret.setOldValue(new Empty())
              : secret.setValue(auth.getToken())
          )
        )
      }
      if (auth instanceof BasicAuth) {
        const basic = new BasicAuthGrpc()
        const secretPassword = new SecretString()
        const secretLogin = new SecretString()
        basic.setPassword(
          auth.isLoginEncrypted()
            ? secretPassword.setOldValue(new Empty())
            : secretPassword.setValue(auth.getPassword())
        )
        basic.setLogin(
          auth.isPasswordEncrypted()
            ? secretLogin.setOldValue(new Empty())
            : secretLogin.setValue(auth.getLogin())
        )
        return authRequest.setBasicAuth(basic)
      }

      if (auth instanceof ApiKey) {
        const api = new ApiKeyGrpc()
        const secretKey = new SecretString()
        const secretValue = new SecretString()

        api.setKey(
          auth.isKeyEncrypted()
            ? secretKey.setOldValue(new Empty())
            : secretKey.setValue(auth.getKey())
        )
        api.setValue(
          auth.isValueEncrypted()
            ? secretValue.setOldValue(new Empty())
            : secretValue.setValue(auth.getValue())
        )
        return authRequest.setApiKey(api)
      }
    }

    webhook.getEventSubscriptions().forEach((event) => {
      const subscription = new WebhookEventSubscription()
      subscription.setEventType(event.getEventType().toLowerCase())
      subscription.setVersion(event.getVersion())
      subscription.setSeveritiesList(event.getSeverities())

      subscriptions.push(subscription)
    })

    newWebhook.setId(webhook.getId())
    newWebhook.setName(webhook.getName())
    newWebhook.setDescription(webhook.getDescription())
    newWebhook.setEndpoint(webhook.getEndpoint())
    newWebhook.setAuthentication(getAuthType())
    newWebhook.setEventSubscriptionsList(subscriptions)

    return newWebhook
  }

  public static async getWebhooksList() {
    const request = new ListWebhooksRequest()

    const result = await this.handleQuery(this.client.listWebhooks, request)
    return result.getWebhooksList()
  }

  public static async createWebhook(webhook: WebhookModel) {
    const request = new CreateWebhookRequest()

    const newWebhook = this.buildWebhookFromModel(webhook)

    request.setName(newWebhook.getName())

    request.setEndpoint(`https://${newWebhook.getEndpoint()}`)

    request.setDescription(newWebhook.getDescription())

    request.setAuthentication(newWebhook.getAuthentication())

    request.setEventSubscriptionsList(newWebhook.getEventSubscriptionsList())

    const result = await this.handleQuery(this.client.createWebhook, request)
    return result
  }

  public static async updateWebhook(webhook: WebhookModel) {
    const request = new UpdateWebhookRequest()
    const updatedWebhook = this.buildWebhookFromModel(webhook)
    request.setName(updatedWebhook.getName())
    request.setId(updatedWebhook.getId())
    request.setEndpoint(updatedWebhook.getEndpoint())
    request.setDescription(updatedWebhook.getDescription())

    request.setAuthentication(updatedWebhook.getAuthentication())

    request.setEventSubscriptionsList(
      updatedWebhook.getEventSubscriptionsList()
    )

    const result = await this.handleQuery(this.client.updateWebhook, request)

    return result.getWebhook()
  }

  public static async getWebhook(id: string) {
    const request = new GetWebhookRequest().setId(id)

    const result = await this.handleQuery(this.client.getWebhook, request)

    return result.getWebhook()
  }

  public static async deleteWebhook(id: string) {
    const request = new DeleteWebhookRequest().setId(id)

    const result = await this.handleQuery(this.client.deleteWebhook, request)

    return result
  }

  public static async testWebhook(
    webhook: Pick<
      WebhookModelAttributes,
      'name' | 'endpoint' | 'authentication'
    >
  ) {
    const webhookFromModel = this.buildWebhookFromModel(
      new WebhookModel({
        ...webhook,
        id: 'Testing',
        description: '',
        event_subscriptions: [],
      })
    )

    const request = new TestWebhookRequest()

    const testWebhook = new TestNewWebhook()

    testWebhook.setName(webhookFromModel.getName())

    testWebhook.setEndpoint(`https://${webhookFromModel.getEndpoint()}`)

    testWebhook.setAuthentication(webhookFromModel.getAuthentication())

    request.setWebhook(testWebhook)

    const result = await this.handleQuery(this.client.testWebhook, request)

    return result.getSuccess()
  }

  public static async testExistingWebhook(webhooId: string) {
    const request = new TestWebhookRequest()

    request.setId(webhooId)

    const result = await this.handleQuery(this.client.testWebhook, request)

    return result.getSuccess()
  }

  public static async getEventTypes() {
    const request = new ListEventsRequest()

    const result = await this.handleQuery(this.client.listEventTypes, request)

    return result.toObject().eventTypesList
  }

  public static async getWebhookInvocations(
    webhooks: Array<string> = [],
    periods: Array<DefinedDateRangeTuple> = []
  ) {
    const request = new ListWebhookInvocationsRequest()
    const periodsList = periods.map(([startAt, endAt]) => {
      const period = new WebhookInvocationPeriod()
      period.setStartAt(Timestamp.fromDate(new Date(startAt)))
      period.setEndAt(Timestamp.fromDate(new Date(endAt)))
      return period
    })

    request.setWebhookIdsList(webhooks)
    request.setPeriodsList(periodsList)

    const result = await this.handleQuery(
      this.client.listWebhookInvocations,
      request
    )

    return result.toObject().webhookInvocationsList
  }
}

export default GrpcWebhookService
