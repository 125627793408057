import { ListBackupsForAssetFiltersParams } from '@lib/clients/backups/list-backups-for-asset/types'
import { Request as ListBackupsRequest } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/backups/list_backups_for_asset_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { AbstractListRequest } from '@lib/clients/list-request'

export class ListBackupsForAssetFilter extends AbstractListRequest<ListBackupsRequest> {
  #requestParams: Partial<ListBackupsForAssetFiltersParams>

  constructor(requestParam: Partial<ListBackupsForAssetFiltersParams>) {
    super()
    this.#requestParams = requestParam
  }

  createGrpcRequest(): ListBackupsRequest {
    const grpcRequest = new ListBackupsRequest()

    const filter = new ListBackupsRequest.Initial.Filter()

    const { timeRange, assetIdList, replicatedList } = this.#requestParams

    if (assetIdList && assetIdList.length) {
      filter.setAssetIdList(assetIdList)
    }
    if (timeRange) {
      const timeRangeProto = this.#setupTimeRange({
        start: timeRange.start as number,
        end: timeRange.end as number,
      })
      filter.setTimeRange(timeRangeProto)
    }

    if (replicatedList && replicatedList.length) {
      filter.setReplicatedList(replicatedList)
    }

    return grpcRequest.setInitial(
      new ListBackupsRequest.Initial()
        .setFilter(filter)
        .setPageSize(
          this.#requestParams?.pageSize || AbstractListRequest.DEFAULT_PAGE_SIZE
        )
    )
  }

  #createTimestamp(seconds: number) {
    const timestamp = new Timestamp()
    timestamp.setSeconds(seconds)
    return timestamp
  }

  #setupTimeRange({ start, end }: { start: number; end: number }) {
    const timeRange = new ListBackupsRequest.Initial.Filter.TimeRange()
    if (start) {
      timeRange.setStart(this.#createTimestamp(start))
    }

    if (end) {
      timeRange.setEnd(this.#createTimestamp(end))
    }
    return timeRange
  }
}
