export enum Severity {
  ERROR,
  WARNING,
  INFO,
  TRACE,
}

export enum AuthorizationOptions {
  NONE = 'none',
  BASIC = 'basic_authorization',
  BEARER = 'bearer',
  API_KEY = 'api_key',
}
