import { ScheduleRestoreRequest } from 'blue-stack-libs/pechkin-grpc-libs/js/pechkin/pechkin_pb'
import { VIRow } from '@lib/engine-types'
import ValueInterface from '@lib/interfaces/value.interface'

abstract class RestoreRequestAbstarct {
  abstract createRequestEC2(): ScheduleRestoreRequest
  abstract createRequestEBS(ccAssetId: string): ScheduleRestoreRequest
}
interface EC2RestoreRequestAttrs {
  ccId: string
  ccRpId: string
  ccVaultId: string
  subnetId?: string
  availabilityZone?: string
  securityGroupIdsList?: Array<string>
  tags: VIRow
}

export class RestoreRequest extends RestoreRequestAbstarct {
  #ccId: string

  #ccRpId: string

  #ccVaultId: string

  #subnetId: string

  #tags: VIRow

  #availabilityZone: string

  #securityGroupIdsList: Array<string>

  constructor(parameters: EC2RestoreRequestAttrs) {
    super()
    this.#ccId = parameters.ccId
    this.#ccRpId = parameters.ccRpId
    this.#ccVaultId = parameters.ccVaultId
    this.#subnetId = parameters.subnetId ?? ''
    this.#tags = parameters.tags
    this.#securityGroupIdsList = parameters.securityGroupIdsList ?? []
    this.#availabilityZone = parameters.availabilityZone ?? ''
  }

  createRequestEC2(): ScheduleRestoreRequest {
    const request = new ScheduleRestoreRequest()
    const elastioAwsRp = this.#createElastioAwsRp()
    const awsEc2Restore = this.#createElastioAwsRpInstance()
    const elastioEc2Rp = new ScheduleRestoreRequest.ElastioAwsEc2Rp()
    elastioEc2Rp.setEc2(awsEc2Restore)

    request.setElastioAwsRp(elastioAwsRp)

    this.#modifyTags(request, this.#tags)

    elastioAwsRp.setEc2(elastioEc2Rp)
    return request
  }

  createRequestEBS(ccAssetId: string): ScheduleRestoreRequest {
    const request = new ScheduleRestoreRequest()
    const elastioAwsRp = this.#createElastioAwsRp()
    const awsEc2Restore = this.#awsEBSRestoreInstance(ccAssetId)
    const elastioEc2Rp = new ScheduleRestoreRequest.ElastioAwsEbsRp()

    elastioEc2Rp.setEbs(awsEc2Restore)

    request.setElastioAwsRp(elastioAwsRp)

    this.#modifyTags(request, this.#tags)

    elastioAwsRp.setEbs(elastioEc2Rp)
    return request
  }

  #createElastioAwsRp() {
    const elastioAwsRp = new ScheduleRestoreRequest.ElastioAwsRp()
    elastioAwsRp.setCcId(this.#ccId)
    elastioAwsRp.setCcRpId(this.#ccRpId)
    elastioAwsRp.setCcVaultId(this.#ccVaultId)
    return elastioAwsRp
  }

  #createElastioAwsRpInstance() {
    const awsEc2Restore = new ScheduleRestoreRequest.AwsEc2Restore()
    awsEc2Restore.setSubnetId(this.#subnetId)
    awsEc2Restore.setSecurityGroupIdsList(this.#securityGroupIdsList)
    return awsEc2Restore
  }

  #awsEBSRestoreInstance(ccAssetId: string) {
    const awsEBS2Restore = new ScheduleRestoreRequest.AwsEbsRestore()
    awsEBS2Restore.setAvailabilityZone(this.#availabilityZone)
    awsEBS2Restore.setCcAssetId(ccAssetId)
    return awsEBS2Restore
  }

  #modifyTags(request: ScheduleRestoreRequest, tags: VIRow) {
    const tagsMap = request.getTagsMap()
    tagsMap.clear()
    tags?.forEach(({ name, value }: ValueInterface) =>
      tagsMap.set(name, String(value))
    )
  }
}
