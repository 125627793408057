import {
  ScheduledTenantJob,
  ScheduledTenantJobStatus,
  NotificationStatus,
  NotificationKind,
  Notification,
  Sync,
} from '@features/scheduled-jobs-monitoring/models'
import { JobMessages } from '@features/scheduled-jobs-monitoring/use-cases/constants'

import { Logger } from './logger'

export interface ScheduledTenantJobsListParams {
  jobIds: Array<string>
  start: Date
  end: Date
}

interface ScheduledTenantJobsClient {
  list(
    params: ScheduledTenantJobsListParams
  ): Promise<Array<ScheduledTenantJob>>
}

interface JobsRepository {
  list(): Promise<Array<ScheduledTenantJob>>
  delete(id: string): Promise<void>
  update(job: ScheduledTenantJob): Promise<boolean>
}

interface SyncsRepository {
  get(id: string): Promise<Sync | undefined>
  update(sync: Sync): Promise<boolean>
  insert(sync: Sync): Promise<void>
}

interface NotificationRepository {
  insert(notification: Notification): Promise<void>
}

interface SyncScheduledTenantJobsAndNotifyUserAttrs {
  jobsRepository: JobsRepository
  syncsRepository: SyncsRepository
  notificationsRepository: NotificationRepository
  logger: Logger
  client: ScheduledTenantJobsClient
}

const ONE_SECOND_IN_MS = 1000

export class SyncScheduledTenantJobsAndNotifyUserUseCase {
  readonly #LOCK_ID = 'scheduled-tenant-jobs-last-update'

  readonly #LOCK_OPTIONS = { ifAvailable: true }

  #jobsRepository: JobsRepository

  #syncsRepository: SyncsRepository

  #notificationsRepository: NotificationRepository

  #logger: Logger

  #client: ScheduledTenantJobsClient

  constructor(parameters: SyncScheduledTenantJobsAndNotifyUserAttrs) {
    this.#jobsRepository = parameters.jobsRepository
    this.#syncsRepository = parameters.syncsRepository
    this.#notificationsRepository = parameters.notificationsRepository
    this.#logger = parameters.logger
    this.#client = parameters.client
  }

  async execute(): Promise<void> {
    // The TypeScript DOM types package has not been appended with the `locks` property on the Navigator type.
    // @ts-ignore
    await navigator.locks.request(
      this.#LOCK_ID,
      this.#LOCK_OPTIONS,
      async (lock: any) => {
        if (!lock) {
          return
        }

        const sync = await this.#syncsRepository.get(this.#LOCK_ID)

        if (sync?.isForbidden) {
          this.#logger.info('Sync is forbidden')
          return
        }

        const storedJobs = await this.#jobsRepository.list()

        if (!storedJobs.length) {
          this.#logger.info('No jobs for monitoring')
          await this.#scheduleNextSync(sync)
          return
        }

        await this.#processJobs(storedJobs)
        await this.#scheduleNextSync(sync)
      }
    )
  }

  async #processJobs(storedJobs: Array<ScheduledTenantJob>): Promise<void> {
    const jobIds = storedJobs.map((job) => job.id)
    const createdAts = storedJobs.map((job) => job.createdAt.getTime())

    const backendJobs = await this.#client.list({
      jobIds: jobIds,
      start: new Date(Math.min(...createdAts)),
      end: new Date(Math.max(...createdAts) + ONE_SECOND_IN_MS),
    })

    this.#logger.info('Jobs retrieved from client.')

    for (const job of backendJobs) {
      await this.#handleJob(job)
    }
  }

  async #handleJob(job: ScheduledTenantJob): Promise<void> {
    switch (job.status) {
      case ScheduledTenantJobStatus.SUCCEEDED:
        await this.#handleJobSucceeded(job)
        break
      case ScheduledTenantJobStatus.FAILED:
        await this.#handleJobFailed(job)
        break
      case ScheduledTenantJobStatus.ABORTED:
        await this.#handleJobAborted(job)
        break
      default:
        await this.#handleJobInProgress(job)
    }
  }

  async #handleJobInProgress(job: ScheduledTenantJob): Promise<void> {
    await this.#jobsRepository.update(job)
    this.#logger.info('Job updated.', job)
  }

  async #handleJobSucceeded(job: ScheduledTenantJob): Promise<void> {
    this.#logger.info('Job completed.', job)

    await this.#notificationsRepository.insert(
      new Notification({
        createdAt: new Date(),
        id: job.id,
        kind: NotificationKind.SUCCESS,
        status: NotificationStatus.PENDING,
        text: JobMessages[job.kind][ScheduledTenantJobStatus.SUCCEEDED],
      })
    )

    await this.#jobsRepository.delete(job.id)
  }

  async #handleJobFailed(job: ScheduledTenantJob): Promise<void> {
    this.#logger.error(`Job ${job.status}.`, job)

    await this.#notificationsRepository.insert(
      new Notification({
        createdAt: new Date(),
        id: job.id,
        kind: NotificationKind.ERROR,
        status: NotificationStatus.PENDING,
        text: JobMessages[job.kind][ScheduledTenantJobStatus.FAILED],
      })
    )

    await this.#jobsRepository.delete(job.id)
  }

  async #handleJobAborted(job: ScheduledTenantJob): Promise<void> {
    this.#logger.error(`Job ${job.status}.`, job)

    await this.#notificationsRepository.insert(
      new Notification({
        createdAt: new Date(),
        id: job.id,
        kind: NotificationKind.WARNING,
        status: NotificationStatus.PENDING,
        text: JobMessages[job.kind][ScheduledTenantJobStatus.ABORTED],
      })
    )

    await this.#jobsRepository.delete(job.id)
  }

  async #scheduleNextSync(sync: Sync | undefined) {
    const nextAllowedAt = new Date(Date.now() + ONE_SECOND_IN_MS)
    if (sync) {
      sync.allowedAt = nextAllowedAt
      await this.#syncsRepository.update(new Sync(sync))
      return
    }

    await this.#syncsRepository.insert(
      new Sync({
        id: this.#LOCK_ID,
        createdAt: new Date(),
        allowedAt: nextAllowedAt,
      })
    )
  }
}
