import React, { forwardRef } from 'react'
import { TableProps as VirtuosoTableProps } from 'react-virtuoso'
import { Box, TableProps as MUITableProps } from '@mui/material'
import TableUI from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableBodyUI from '@mui/material/TableBody'
import Paper from '@mui/material/Paper'
import TableRowUI from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { flexRender, HeaderGroup } from '@tanstack/react-table'
import { SortDirection } from '@components/table'

export const Table = ({
  virtuosoProps,
  muiProps,
}: {
  virtuosoProps: VirtuosoTableProps
  muiProps?: MUITableProps
}) => {
  return (
    <TableUI
      {...muiProps}
      {...virtuosoProps}
      sx={{
        borderCollapse: 'separate',
        tableLayout: 'fixed',
        ...muiProps?.sx,
      }}
    />
  )
}

export const Scroller = forwardRef<HTMLDivElement>((props, ref) => (
  <TableContainer component={Paper} {...props} ref={ref} />
))

export const TableBody = forwardRef<HTMLTableSectionElement>((props, ref) => (
  <TableBodyUI {...props} ref={ref} />
))

type HeaderGroupsProps<T> = {
  headerGroups: Array<HeaderGroup<T>>
}

export const FixedHeaderContent = <T,>({
  headerGroups,
}: HeaderGroupsProps<T>) => (
  <>
    {headerGroups.map((headerGroup) => (
      <TableRowUI key={headerGroup.id}>
        {headerGroup.headers.map((header) => {
          const {
            getIsSorted,
            columnDef,
            getToggleSortingHandler,
            getCanSort,
          } = header.column
          const nextSortingOrder = getIsSorted()
          return (
            <TableCell
              align={columnDef.meta?.textAlign}
              key={header.id}
              colSpan={header.colSpan}
              sx={{
                width: header.getSize(),
              }}
              onClick={getToggleSortingHandler()}
              className={getCanSort() ? 'cursor-pointer select-none' : ''}
            >
              <Box display="inline-flex" gap={1}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
                <SortDirection
                  nextSortingOrder={nextSortingOrder}
                  isSortable={columnDef.enableSorting}
                />
              </Box>
            </TableCell>
          )
        })}
      </TableRowUI>
    ))}
  </>
)
