import { Request as Pagination } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/pagination_pb'
import { Request as ListEbsRequestPb } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/assets/list_ebs_pb'
import { ListEbsRequest } from '@lib/clients/assets/list-ebs'

export class ListEbsPagination implements ListEbsRequest {
  #pageToken: string

  constructor(pageToken: string) {
    this.#pageToken = pageToken
  }

  toGrpc(): ListEbsRequestPb {
    const grpcRequest = new ListEbsRequestPb()
    grpcRequest.setPagination(new Pagination().setPageToken(this.#pageToken))

    return grpcRequest
  }
}
