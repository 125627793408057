"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.volumesFactory = void 0;
const fishery_1 = require("fishery");
const models_1 = require("../../../models");
const common_1 = require("./common");
const index_1 = require("./index");
const volume_model_1 = require("../../../models/inventory.model/volume.model");
class VolumesFactory extends fishery_1.Factory {
    withNoBackups() {
        return this.transient({
            threatStatus: null,
        });
    }
    withNotScannedBackup() {
        return this.transient({
            firstGenericBackupAt: Date.now() - 1000 * 60 * 60 * 24 * 30,
            lastGenericBackupAt: Date.now(),
            threatStatus: index_1.assetThreatStatusesFactory.notScannedBackup().build(),
        });
    }
    healthy() {
        return this.transient({
            firstGenericBackupAt: Date.now() - 1000 * 60 * 60 * 24 * 30,
            lastGenericBackupAt: Date.now(),
            threatStatus: index_1.assetThreatStatusesFactory.healthy().build(),
        });
    }
    unhealthy() {
        return this.transient({
            firstGenericBackupAt: Date.now() - 1000 * 60 * 60 * 24 * 30,
            lastGenericBackupAt: Date.now(),
            threatStatus: index_1.assetThreatStatusesFactory.unhealthy().build(),
        });
    }
    withMalware() {
        return this.transient({
            firstGenericBackupAt: Date.now() - 1000 * 60 * 60 * 24 * 30,
            lastGenericBackupAt: Date.now(),
            threatStatus: index_1.assetThreatStatusesFactory
                .unhealthy()
                .volumeWithMalware()
                .build(),
        });
    }
    withRansomware() {
        return this.transient({
            firstGenericBackupAt: Date.now() - 1000 * 60 * 60 * 24 * 30,
            lastGenericBackupAt: Date.now(),
            threatStatus: index_1.assetThreatStatusesFactory
                .unhealthy()
                .volumeWithRansomware()
                .build(),
        });
    }
    withCorruptedFileSystem() {
        return this.transient({
            firstGenericBackupAt: Date.now() - 1000 * 60 * 60 * 24 * 30,
            lastGenericBackupAt: Date.now(),
            threatStatus: index_1.assetThreatStatusesFactory
                .unhealthy()
                .volumeWithCorruptedFileSystem()
                .build(),
        });
    }
    encryptedWithoutCMK() {
        return this.transient({ encrypted: true, usesCustomerManagedKey: false });
    }
    encryptedWithCMK() {
        return this.transient({ encrypted: true, usesCustomerManagedKey: true });
    }
    unencrypted() {
        return this.transient({ encrypted: false, usesCustomerManagedKey: false });
    }
    withLastBackupAt(lastGenericBackupAt) {
        return this.healthy().transient({ lastGenericBackupAt });
    }
    withFirstBackupAt(firstGenericBackupAt) {
        return this.healthy().transient({ firstGenericBackupAt });
    }
    withSla(duration) {
        return this.transient({
            coveringPoliciesSLAs: new Map([['policyId', duration]]),
        });
    }
}
const volumesFactory = VolumesFactory.define(({ sequence, transientParams }) => {
    const region = transientParams.region ?? (0, common_1.awsRegion)();
    return new models_1.VolumeModel({
        coveringPoliciesSLAs: transientParams.coveringPoliciesSLAs ?? new Map(),
        firstGenericBackupAt: transientParams.firstGenericBackupAt ?? null,
        lastGenericBackupAt: transientParams.lastGenericBackupAt ?? null,
        accountId: transientParams.accountId ?? (0, common_1.awsAccountId)(),
        availabilityZone: transientParams.availabilityZone ?? (0, common_1.awsAvailabilityZone)(region),
        cloudConnectorId: transientParams.cloudConnectorId ?? (0, common_1.uuid)(),
        cloudProviderVolumeId: transientParams.cloudProviderVolumeId ?? (0, common_1.awsVolumeId)(),
        cost: transientParams.cost ?? (0, common_1.awsCost)(),
        coveredByPolicies: transientParams.coveredByPolicies ?? [],
        coveredByPausedPolicies: transientParams.coveredByPausedPolicies ?? [],
        createdAt: transientParams.createdAt ?? Date.now(),
        encrypted: transientParams.encrypted ?? (0, common_1.bool)(),
        id: transientParams.id ?? (0, common_1.uuid)(),
        inventorySize: transientParams.inventorySize ?? Math.random(),
        iops: transientParams.iops ?? Math.random(),
        multiAttachEnabled: transientParams.multiAttachEnabled ?? (0, common_1.bool)(),
        name: transientParams.name ?? (0, common_1.name)('Volume', sequence),
        region,
        status: transientParams.status ?? (0, common_1.volumeStatus)(),
        statusCheck: transientParams.statusCheck ?? (0, common_1.volumeStatusCheck)(),
        tags: transientParams.tags ?? new Map(),
        threatStatus: transientParams.threatStatus ?? null,
        throughput: transientParams.throughput ?? Math.random(),
        usesCustomerManagedKey: transientParams.usesCustomerManagedKey ?? (0, common_1.bool)(),
        volumeType: transientParams.volumeType ?? 'gp2',
        lastGenericBackupKind: volume_model_1.VolumeGenericBackupKind.ELASTIO,
        associatedRetentionPolicies: transientParams.associatedRetentionPolicies ?? [],
    });
});
exports.volumesFactory = volumesFactory;
