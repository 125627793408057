import {
  ListTenantsRequest,
  ManagementPromiseClient,
  ListTenantsResponse,
  GetCustomerProfileResponse,
  GetCustomerProfileRequest,
  CreateTenantRequest,
  ListElastioRegionsResponse,
  ListElastioRegionsRequest,
} from '@lib/clients/grpc-imports'
import { GrpcClient } from '@lib/clients/grpc-client'

export type TenantName = {
  name: string
}

export type CustomerProfile = {
  id: string
  email: string
  locale: string
  displayName: string
  nickname: string
  avatarUrl: string
  phoneNumber: string
}

export type CustomerElastioRegion = {
  regionName: string
  customerFacingRegionName: string
}

export type CreateTenant = {
  elastioRegionName: string
  tenantName: string
}

export class ManagementClient extends GrpcClient<ManagementPromiseClient> {
  #managementClient: ManagementPromiseClient

  #CLIENT_TYPE_ID = 'ManagementClient'

  constructor(hostName = '') {
    super()
    this.#managementClient = this.getClient(hostName)
  }

  async listTenants(): Promise<Array<TenantName>> {
    const request = new ListTenantsRequest()
    const response: ListTenantsResponse = await this.retryGrpcCall(
      () => this.#managementClient.listTenants(request, this.metadata()),
      {
        requestName: 'ManagementPromiseClient/listTenants',
      }
    )

    return response.toObject().tenantsList
  }

  async getCustomerProfile(): Promise<CustomerProfile> {
    const request = new GetCustomerProfileRequest()
    const response: GetCustomerProfileResponse = await this.retryGrpcCall(
      () => this.#managementClient.getCustomerProfile(request, this.metadata()),
      {
        requestName: 'ManagementPromiseClient/getCustomerProfile',
      }
    )

    return response.toObject()
  }

  async createTenant({
    tenantName,
    elastioRegionName,
  }: CreateTenant): Promise<void> {
    const request = new CreateTenantRequest()
    request.setTenantName(tenantName).setElastioRegionName(elastioRegionName)
    await this.retryGrpcCall(
      () => this.#managementClient.createTenant(request, this.metadata()),
      {
        requestName: 'ManagementPromiseClient/createTenant',
      }
    )
  }

  async listElastioRegions(): Promise<Array<CustomerElastioRegion>> {
    const request = new ListElastioRegionsRequest()
    const response: ListElastioRegionsResponse = await this.retryGrpcCall(
      () => this.#managementClient.listElastioRegions(request, this.metadata()),
      {
        requestName: 'ManagementPromiseClient/listElastioRegions',
      }
    )

    return response.toObject().elastioRegionsList
  }

  protected innerClientTypeId(): string {
    return this.#CLIENT_TYPE_ID
  }

  protected initClient(hostName = ''): ManagementPromiseClient {
    return new ManagementPromiseClient(hostName, null, null)
  }
}
