import React from 'react'

function DashboardIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00004 4.35963C5.00004 3.79318 4.62694 3.33398 4.16671 3.33398C3.70647 3.33398 3.33337 3.79318 3.33337 4.35963V15.6417C3.33337 16.2081 3.70647 16.6673 4.16671 16.6673C4.62694 16.6673 5.00004 16.2081 5.00004 15.6417V4.35963Z"
        fill="#999CAA"
      />
      <path
        d="M8.80961 9.48853C8.80961 8.92209 8.43652 8.46289 7.97628 8.46289C7.51604 8.46289 7.14294 8.92209 7.14294 9.48853V15.6424C7.14294 16.2088 7.51604 16.668 7.97628 16.668C8.43652 16.668 8.80961 16.2088 8.80961 15.6424V9.48853Z"
        fill="#999CAA"
      />
      <path
        d="M12.6191 7.4358C12.6191 6.86935 12.246 6.41016 11.7857 6.41016C11.3255 6.41016 10.9524 6.86935 10.9524 7.4358V15.6409C10.9524 16.2074 11.3255 16.6666 11.7857 16.6666C12.246 16.6666 12.6191 16.2074 12.6191 15.6409V7.4358Z"
        fill="#999CAA"
      />
      <path
        d="M16.4286 12.5647C16.4286 11.9983 16.0555 11.5391 15.5953 11.5391C15.1351 11.5391 14.762 11.9983 14.762 12.5647V15.6416C14.762 16.2081 15.1351 16.6673 15.5953 16.6673C16.0555 16.6673 16.4286 16.2081 16.4286 15.6416V12.5647Z"
        fill="#999CAA"
      />
    </svg>
  )
}

export default DashboardIcon
