"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListDetailsRecoveryPointsS3 = void 0;
const models_1 = require("../../models");
const types_1 = require("../../models/scans/malware/types");
class ListDetailsRecoveryPointsS3 {
    #threats;
    #assetItems;
    #bucketName;
    #assetItemsMap = {};
    #lastRP;
    constructor(parameters) {
        this.#threats = parameters.threats;
        this.#assetItems = parameters.assetItems;
        this.#bucketName = parameters.bucketName;
        this.#lastRP = parameters.lastRP;
    }
    execute(filters) {
        return this.#prepareOutput({
            filters,
        });
    }
    #prepareOutput({ filters, }) {
        for (const assetItem of this.#assetItems) {
            this.#assetItemsMap[assetItem.id] = {
                scanTime: new Date(0),
                threatCount: 0,
                malwareStatus: types_1.MalwareScanStatus.CLEAN,
                assetItemId: assetItem.id,
                assetItemType: assetItem.selectors[0].kind,
                assetItemPath: assetItem.selectors[0].value,
            };
        }
        const { bucketLastRecoveryPointDate, bucketMalwareStatus, threatIdsForAsset, } = this.#iterateThreats();
        const output = Object.values(this.#assetItemsMap);
        if (bucketMalwareStatus !== null) {
            output.push({
                scanTime: bucketLastRecoveryPointDate,
                threatCount: threatIdsForAsset.size,
                malwareStatus: bucketMalwareStatus,
                assetItemId: '',
                assetItemType: 0,
                assetItemPath: this.#bucketName,
            });
        }
        else if (this.#lastRP && this.#lastRP instanceof models_1.ElastioRecoveryPoint) {
            const entity = this.#lastRP.backupEntities[0]?.entity;
            if ('assetId' in entity) {
                output.push({
                    scanTime: this.#lastRP.createdAt,
                    threatCount: this.#lastRP.threatIds.length,
                    malwareStatus: this.#lastRP.threatIds.length > 0
                        ? types_1.MalwareScanStatus.INFECTED
                        : types_1.MalwareScanStatus.CLEAN,
                    assetItemId: '',
                    assetItemType: 0,
                    assetItemPath: this.#bucketName,
                });
            }
        }
        const filteredRecoveryPointsOutput = this.#findScanMatchingFilters(output, filters);
        return {
            recoveryPoints: filteredRecoveryPointsOutput,
        };
    }
    #iterateThreats() {
        const assetItemsMap = this.#assetItemsMap;
        const threatIdsForAsset = new Set();
        let bucketMalwareStatus = null;
        let bucketLastRecoveryPointDate = new Date(0);
        for (const threat of this.#threats) {
            const threadSource = threat.source;
            const assetItemId = threadSource?.assetItem?.assetItemId;
            if (assetItemId) {
                const assetItem = this.#assetItems.find((item) => item.id === assetItemId);
                if (!assetItem) {
                    continue;
                }
                if (threat.createdAt < assetItemsMap[assetItem.id].scanTime) {
                    continue;
                }
                assetItemsMap[assetItem.id].malwareStatus = types_1.MalwareScanStatus.INFECTED;
                assetItemsMap[assetItem.id].threatCount += 1;
            }
            else {
                if (threat.createdAt < bucketLastRecoveryPointDate) {
                    continue;
                }
                bucketLastRecoveryPointDate = threat.createdAt;
                bucketMalwareStatus = types_1.MalwareScanStatus.INFECTED;
                threatIdsForAsset.add(threat.id);
            }
        }
        return {
            bucketLastRecoveryPointDate,
            bucketMalwareStatus,
            threatIdsForAsset,
        };
    }
    #findScanMatchingFilters(recoveryPointsOutput, filters) {
        return recoveryPointsOutput.filter((recoveryPoint) => {
            if (!filters.search) {
                return true;
            }
            return recoveryPoint.assetItemPath
                .toLowerCase()
                .includes(filters.search.toLowerCase());
        });
    }
}
exports.ListDetailsRecoveryPointsS3 = ListDetailsRecoveryPointsS3;
