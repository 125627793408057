/* eslint-disable import/no-extraneous-dependencies */
import TimeHelper from '@lib/helpers/time.helper'
import { Host as HostProto } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/assets/generic/host_pb'
import { GenericHost } from 'blues-corejs/dist'

type HostPt = HostProto.AsObject

export class GenericHostTransformer {
  #genericHost: HostPt

  constructor(genericHost: HostPt) {
    this.#genericHost = genericHost
  }

  transform(): GenericHost {
    return this.#transformGenericHost(this.#genericHost)
  }

  #transformGenericHost(genericHost: HostPt): GenericHost {
    return new GenericHost({
      id: genericHost.id,
      hostname: genericHost.hostname,
      osName: genericHost.osName,
      cpuCoresCount: genericHost.cpuCoresCount,
      retentionPolicy: genericHost.retentionPoliciesList,
      createdAt: TimeHelper.secondsToDate(genericHost.createdAt?.seconds),
      updatedAt: TimeHelper.secondsToDate(genericHost.updatedAt?.seconds),
    })
  }
}
