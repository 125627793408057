import BaseModel from '@lib/models/base-model/base.model'
import RoleTypeConstant from '@lib/constants/role-type.constant'

class UserRoleModel extends BaseModel {
  public readonly shortName: string

  public readonly name: string

  public readonly type: RoleTypeConstant

  public readonly scopesList: Array<string>

  constructor(readonly params: any = {}) {
    super(params)

    // base
    this.shortName = params.shortName
    this.name = params.name
    this.type = params.type
    this.scopesList = params.scopesList

    // extra
  }
}

export default UserRoleModel
