import ReportPeriodInterface from '@lib/interfaces/report-period.interface'
import ValueInterface from '@lib/interfaces/value.interface'
import { ListScansByFiltersReqFilterParams } from '@lib/clients/scans/types'

const actionPrefix = 'fsCheckReportsActionTypes/'

export const SET_FS_CHECK_REPORTS_PERIOD = `${actionPrefix}SET_FS_CHECK_REPORTS_PERIOD`
export const SET_FS_CHECK_REPORTS_SELECTED_FILTERS = `${actionPrefix}SET_FS_CHECK_REPORTS_SELECTED_FILTERS`
export const SET_FS_CHECK_REPORTS_SELECTED_RP_FILTERS = `${actionPrefix}SET_FS_CHECK_REPORTS_SELECTED_RP_FILTERS`
export const SET_FS_CHECK_REPORTS_SELECTED_FILTERS_FOR_API = `${actionPrefix}SET_FS_CHECK_REPORTS_SELECTED_FILTERS_FOR_API`

export interface SetFsCheckReportsPeriodAction {
  type: typeof SET_FS_CHECK_REPORTS_PERIOD
  payload: ReportPeriodInterface
}

export interface SetFsCheckReportsSelectedFiltersAction {
  type: typeof SET_FS_CHECK_REPORTS_SELECTED_FILTERS
  payload: Array<ValueInterface>
}

export interface SetFsCheckReportsSelectedRpFiltersAction {
  type: typeof SET_FS_CHECK_REPORTS_SELECTED_RP_FILTERS
  payload: string
}

export interface SetFsCheckReportsSelectedFiltersForApiAction {
  type: typeof SET_FS_CHECK_REPORTS_SELECTED_FILTERS_FOR_API
  payload: ListScansByFiltersReqFilterParams
}

export type FsCheckReportsAction =
  | SetFsCheckReportsPeriodAction
  | SetFsCheckReportsSelectedFiltersAction
  | SetFsCheckReportsSelectedRpFiltersAction
  | SetFsCheckReportsSelectedFiltersForApiAction
