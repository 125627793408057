/* eslint-disable import/no-extraneous-dependencies */
import { Schedule as ProtobufSchedule } from 'blue-stack-libs/pechkin-grpc-libs/js/pechkin/messages/plan_pb'
import {
  Daily,
  DayOfWeek,
  Frequency,
  HourlyOrLess,
  Monthly,
  Schedule,
  ScheduleStatus,
  Weekly,
} from 'blues-corejs/dist/models/policy/types'

export class ScheduleTransformer {
  #schedule: ProtobufSchedule.AsObject

  constructor(schedule: ProtobufSchedule.AsObject) {
    this.#schedule = schedule
  }

  transform(): Schedule {
    return {
      status: this.#getScheduleStatus(),
      frequency: this.#getScheduleFrequency(),
    }
  }

  #getScheduleFrequency(): Frequency {
    return {
      daily: this.#getScheduleDaily(),
      every12Hours: this.#getSchedule12Hours(),
      weekly: this.#getWeeklySchedule(),
      monthly: this.#getMonthlySchedule(),
      everyHour: this.#getEveryHourSchedule(),
      every15Minutes: this.#get15MinutesSchedule(),
      every30Minutes: this.#get30MinutesSchedule(),
    }
  }

  #getScheduleStatus(): ScheduleStatus {
    if (this.#schedule.pausedUntil) {
      return ScheduleStatus.PAUSED
    }

    if (this.#schedule.disabled) {
      return ScheduleStatus.DISABLED
    }

    return ScheduleStatus.ENABLED
  }

  #getScheduleDaily(): Daily | undefined {
    if (!this.#schedule.daily) {
      return
    }

    return this.#schedule.daily
  }

  #getWeeklySchedule(): Weekly | undefined {
    if (!this.#schedule.weekly) {
      return
    }

    const day = this.#mapDayOfWeek(this.#schedule.weekly.day)

    return {
      day,
    }
  }

  #mapDayOfWeek(day: ProtobufSchedule.DayOfWeek): DayOfWeek {
    const mapping = {
      [ProtobufSchedule.DayOfWeek.MONDAY]: DayOfWeek.MONDAY,
      [ProtobufSchedule.DayOfWeek.TUESDAY]: DayOfWeek.TUESDAY,
      [ProtobufSchedule.DayOfWeek.WEDNESDAY]: DayOfWeek.WEDNESDAY,
      [ProtobufSchedule.DayOfWeek.THURSDAY]: DayOfWeek.THURSDAY,
      [ProtobufSchedule.DayOfWeek.FRIDAY]: DayOfWeek.FRIDAY,
      [ProtobufSchedule.DayOfWeek.SATURDAY]: DayOfWeek.SATURDAY,
      [ProtobufSchedule.DayOfWeek.SUNDAY]: DayOfWeek.SUNDAY,
    }

    return mapping[day]
  }

  #getMonthlySchedule(): Monthly | undefined {
    if (!this.#schedule.monthly) {
      return
    }

    return {
      dayOfMonth: this.#schedule.monthly.dayOfMonth,
    }
  }

  #getSchedule12Hours() {
    if (!this.#schedule.every12Hours) {
      return
    }

    return this.#schedule.every12Hours
  }

  #getEveryHourSchedule(): HourlyOrLess | undefined {
    return this.#getScheduleWindow(this.#schedule.everyHour?.window)
  }

  #get30MinutesSchedule(): HourlyOrLess | undefined {
    return this.#getScheduleWindow(this.#schedule.every30Minutes?.window)
  }

  #get15MinutesSchedule(): HourlyOrLess | undefined {
    return this.#getScheduleWindow(this.#schedule.every15Minutes?.window)
  }

  #getScheduleWindow(
    scheduleWindow?: ProtobufSchedule.TimeWindow.AsObject
  ): HourlyOrLess | undefined {
    if (!scheduleWindow) {
      return
    }

    return {
      from: scheduleWindow?.from,
      to: scheduleWindow?.to,
    }
  }
}
