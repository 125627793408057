/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import {
  AWSBRecoveryPoint,
  Backup,
  EBSSnapshot,
  ElastioRecoveryPoint,
} from 'blues-corejs/dist'
import { OvaBackup } from 'blues-corejs/dist/models'
import NumHelper from '@lib/helpers/num.helper'
import { Typography } from '@mui/material'

interface Props {
  backup: Backup
}

const DEFAULT_BACKUP_SIZE = 0

function computeBackupSize(backup: Backup): number {
  if (backup instanceof ElastioRecoveryPoint) {
    return backup.optimizedSize
  }

  if (backup instanceof AWSBRecoveryPoint) {
    return backup.size
  }

  if (backup instanceof EBSSnapshot) {
    return backup.size
  }

  if (backup instanceof OvaBackup) {
    return backup.totalSize
  }

  return DEFAULT_BACKUP_SIZE
}

export default function BackupSizeCell({ backup }: Props) {
  const formattedSize = NumHelper.getSizeFormattedInGib(
    computeBackupSize(backup)
  )

  return (
    <Typography fontSize="14px" variant="caption">
      {formattedSize}
    </Typography>
  )
}
