/* eslint-disable import/no-extraneous-dependencies */
import TimeHelper from '@lib/helpers/time.helper'
import { Scan as RansomwareScanProto } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/scans/ransomware/scan_pb'
import { RansomwareScan } from 'blues-corejs/dist'
import {
  RansomwareScanBackup,
  RansomwareScanTarget,
} from 'blues-corejs/dist/models/scans/ransomware'
import {
  RansomwareScanStatus,
  Summary,
  Provider,
  BackupStatus,
  BackupSource,
} from 'blues-corejs/dist/models/scans/ransomware/types'

type RansomwareScanPt = RansomwareScanProto.AsObject
const ProvidersPt = RansomwareScanProto.ScanTarget.Backup.Provider
const BackupsPt = RansomwareScanProto.ScanTarget.Backup.Status

export class RansomwareScanTransformer {
  #scan: RansomwareScanPt

  constructor(scan: RansomwareScanPt) {
    this.#scan = scan
  }

  transform(): RansomwareScan {
    return this.#transformRansomwareScan(this.#scan)
  }

  #transformRansomwareScan(scan: RansomwareScanPt): RansomwareScan {
    return new RansomwareScan({
      id: scan.id,
      jobId: scan.jobId,
      threatIds: scan.threatIdsList,
      status: this.#convertStatus(scan.status),
      summary: this.#convertSummary(scan.summary),
      updatedAt: TimeHelper.secondsToDate(scan.updatedAt?.seconds),
      scanTarget: this.#convertScanTarget(
        scan.scanTarget as RansomwareScanProto.ScanTarget.AsObject
      ),
      createdAt: TimeHelper.secondsToDate(scan.createdAt?.seconds),
      finishedAt: TimeHelper.secondsToDate(scan.finishedAt?.seconds),
    })
  }

  // Scan target can not be undefined
  #convertScanTarget(
    scanTarget: RansomwareScanProto.ScanTarget.AsObject
  ): RansomwareScanTarget {
    const { asset, assetItem, backup } = scanTarget

    if (asset) {
      return new RansomwareScanTarget({
        assetId: asset.id,
      })
    }

    if (assetItem) {
      return new RansomwareScanTarget({
        assetItemId: assetItem.id,
        assetId: assetItem.assetId,
      })
    }

    if (backup) {
      const sourceAssetId = backup.source?.asset?.id

      const sourceAssetItemId = backup.source?.assetItem?.id

      const backupSource: Partial<BackupSource> = {}

      if (sourceAssetId) {
        backupSource.asset = {
          backupAssetId: sourceAssetId,
        }
      }

      if (sourceAssetItemId) {
        backupSource.assetItem = {
          backupAssetItemId: sourceAssetItemId,
          backupAssetId: backup.source?.assetItem?.assetId as string,
        }
      }

      return new RansomwareScanTarget(
        new RansomwareScanBackup({
          backupId: backup.id,
          provider: this.#convertBackupProvider(backup.provider),
          backupStatus: this.#convertBackupStatus(backup.status),
          source: backupSource as BackupSource,
          timestamp: TimeHelper.secondsToDate(backup.timestamp?.seconds),
        })
      )
    }

    throw new Error('Invalid scan target')
  }

  #convertBackupProvider(
    provider: RansomwareScanProto.ScanTarget.Backup.Provider
  ): Provider {
    const mapping: Record<
      RansomwareScanProto.ScanTarget.Backup.Provider,
      Provider
    > = {
      [ProvidersPt.PROVIDER_AWS]: Provider.AWS,
      [ProvidersPt.PROVIDER_ELASTIO]: Provider.ELASTIO,
      [ProvidersPt.OVA_VEEAM]: Provider.OVA_VEEAM,
      [ProvidersPt.OVA_COMMVAULT]: Provider.OVA_COMMVAULT,
      [ProvidersPt.OVA_RUBRIK]: Provider.OVA_RUBRIK,
      [ProvidersPt.OVA_COHESITY]: Provider.OVA_COHESITY,
      [ProvidersPt.OVA_VERITAS_NET_BACKUP]: Provider.OVA_VERITAS_NET_BACKUP,
      [ProvidersPt.OVA_AWS_BACKUP_VMWARE]: Provider.OVA_AWS_BACKUP_VMWARE,
    }

    return mapping[provider]
  }

  #convertBackupStatus(
    status: RansomwareScanProto.ScanTarget.Backup.Status
  ): BackupStatus {
    const mapping = {
      [BackupsPt.STATUS_BAD]: BackupStatus.BAD,
      [BackupsPt.STATUS_GOOD]: BackupStatus.GOOD,
    }

    return mapping[status]
  }

  #convertSummary(
    summary?: RansomwareScanProto.Summary.AsObject
  ): Summary | undefined {
    if (!summary) {
      return
    }

    return {
      errors: summary.errors?.value ?? 0,
      totalFiles: summary.totalFiles?.value ?? 0,
      totalRansomware: summary.totalRansomware?.value ?? 0,
    }
  }

  #convertStatus(status: RansomwareScanProto.Status): RansomwareScanStatus {
    const mapping = {
      [RansomwareScanProto.Status.STATUS_CLEAN]: RansomwareScanStatus.CLEAN,
      [RansomwareScanProto.Status.STATUS_INFECTED]:
        RansomwareScanStatus.INFECTED,
    }

    return mapping[status]
  }
}
