import React from 'react'

function ContactUsIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12150_49818)">
        <path
          d="M18 11.125C18 9.84609 17.2277 8.74438 16.125 8.26119V6.375C16.125 3.96263 14.1624 2 11.75 2H8.25C5.83763 2 3.875 3.96263 3.875 6.375V8.26119C2.77234 8.74438 2 9.84609 2 11.125C2 12.8481 3.40184 14.25 5.125 14.25H6.375C6.72019 14.25 7 13.9702 7 13.625V8.625C7 8.27981 6.72019 8 6.375 8H5.125V6.375C5.125 4.65188 6.52684 3.25 8.25 3.25H11.75C13.4732 3.25 14.875 4.65188 14.875 6.375V8H13.625C13.2798 8 13 8.27981 13 8.625V13.625C13 13.9702 13.2798 14.25 13.625 14.25H14.875V14.875C14.875 15.2196 14.5946 15.5 14.25 15.5H11.7675C11.5095 14.7726 10.8148 14.25 10 14.25C8.96613 14.25 8.125 15.0911 8.125 16.125C8.125 17.1589 8.96613 18 10 18C10.8148 18 11.5095 17.4774 11.7675 16.75H14.25C15.2839 16.75 16.125 15.9089 16.125 14.875V13.9888C17.2277 13.5056 18 12.4039 18 11.125ZM5.75 9.25V13H5.125C4.09113 13 3.25 12.1589 3.25 11.125C3.25 10.0911 4.09113 9.25 5.125 9.25H5.75ZM10 16.75C9.65538 16.75 9.375 16.4696 9.375 16.125C9.375 15.7804 9.65538 15.5 10 15.5C10.3446 15.5 10.625 15.7804 10.625 16.125C10.625 16.4696 10.3446 16.75 10 16.75ZM14.875 13H14.25V9.25H14.875C15.9089 9.25 16.75 10.0911 16.75 11.125C16.75 12.1589 15.9089 13 14.875 13Z"
          fill="#999CAA"
        />
      </g>
      <defs>
        <clipPath id="clip0_12150_49818">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ContactUsIcon
