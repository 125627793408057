import GrpcBaseService from '@lib/services/grpc/base/grpc-base.service'
import { VaultPromiseClient } from 'blue-stack-libs/vault-grpc-libs/js/vault/vault_grpc_web_pb'
import {
  GetVault,
  UpdateVaultRequest,
  AwsVaultCreate,
  CreateVaultRequest,
  SetDefaultRequest,
  RepairVaultRequest,
  ListVaults,
} from 'blue-stack-libs/vault-grpc-libs/js/vault/vault_pb'
import VaultFactory from '@lib/factories/vault.factory'
import VaultModel from '@lib/models/vault.model'
import CreateVaultInterface from '@lib/interfaces/vault/create-vault.interface'

class GrpcVaultService extends GrpcBaseService {
  protected static client = new VaultPromiseClient('', null, null)
  protected static clientName = 'Vaults'

  public static async getVault(id: string): Promise<VaultModel> {
    const request = new GetVault.Request()
    request.setVaultId(id)
    const result = await this.handleQueryRetry(this.client.getVault, request)
    return VaultFactory.buildFromGrpc(result.toObject())
  }

  public static async updateVault(
    vaultId: string,
    description: string,
    vpcId: string,
    subnetIdsList: Array<string>,
    safetyLock: boolean
  ): Promise<any> {
    const request = new UpdateVaultRequest()
    request.setId(vaultId)
    request.setDescription(description)
    request.setVpcId(vpcId)
    request.setSubnetIdsList(subnetIdsList)
    request.setSafetyLock(safetyLock)
    const result = await this.handleQueryLong(this.client.updateVault, request)
    return result.toObject()
  }

  public static async createVault(data: CreateVaultInterface): Promise<string> {
    const request = new CreateVaultRequest()
    const params = new AwsVaultCreate()
    params.setRedStackId(data.redStackId)
    params.setVaultName(data.vaultName)
    params.setVpcId(data.vpcId)
    params.setSubnetIdsList(data.subnetIdsList)
    params.setSafetyLock(data.safetyLock)
    request.setAwsVaultCreate(params)
    const result = await this.handleQuery(this.client.createVault, request)
    return result.toObject().jobId
  }

  public static async setDefault(
    data: Pick<VaultModel, 'innerId' | 'name'>
  ): Promise<any> {
    const request = new SetDefaultRequest()
    request.setVaultId(data.innerId)
    const result = await this.handleQuery(this.client.setDefault, request)
    return result.toObject()
  }

  public static async repairVault(
    vaultName: string,
    cloudConnectorId: string
  ): Promise<string> {
    const request = new RepairVaultRequest()
    request.setVaultName(vaultName)
    request.setRedStackId(cloudConnectorId)
    const result = await this.handleQuery(this.client.repairVault, request)
    return result.toObject().jobId
  }

  public static async listVaults(
    accountId?: string | number
  ): Promise<Array<VaultModel>> {
    const vaultFilters = new ListVaults.Request.Filters()
    if (accountId) {
      vaultFilters.setAccountIdsList([String(accountId)])
    }
    const request = new ListVaults.Request()
    request.setFilters(vaultFilters)
    const result = await this.handleQueryRetry(this.client.listVaults, request)
    return result
      .toObject()
      .vaultsList.filter((v) => !!v.redStackId)
      .map(VaultFactory.buildFromGrpc)
  }
}

export default GrpcVaultService
