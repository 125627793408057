import { Typography } from '@mui/material'
import Link from 'next/link'
import styles from './index.module.scss'

interface Props {
  text: string
  href: string
  last: boolean
}

function Crumb({ text, href, last = false }: Props) {
  if (last) {
    return <Typography>{text}</Typography>
  }

  return (
    <Link href={href}>
      <a className={styles.crumbLink}>{text}</a>
    </Link>
  )
}

export default Crumb
