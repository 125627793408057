import React from 'react'
import Box from '@mui/material/Box'
import { flexRender, Header } from '@tanstack/react-table'
import TableCell from '@mui/material/TableCell'
import { SortDirection } from '@components/table'
import { styled } from '@mui/material/styles'

const EnhancedTableCellStyled = styled(TableCell)`
  line-height: inherit;
`
EnhancedTableCellStyled.defaultProps = {
  size: 'small',
}

const DEFAULT_WHITE_SPACE = 'nowrap'

function HeaderCell<T>({ header }: { header: Header<T, unknown> }) {
  const { getIsSorted, columnDef, getToggleSortingHandler, getCanSort } =
    header.column
  const nextSortingOrder = getIsSorted()

  return (
    <EnhancedTableCellStyled
      align={columnDef.meta?.textAlign}
      sx={{
        whiteSpace: columnDef.meta?.whiteSpace ?? DEFAULT_WHITE_SPACE,
        minWidth: `${columnDef.meta?.size}px`,
      }}
      onClick={getToggleSortingHandler()}
      className={getCanSort() ? 'cursor-pointer select-none' : ''}
    >
      <Box display="inline-flex" gap={1}>
        {flexRender(columnDef.header, header.getContext())}
        <SortDirection
          nextSortingOrder={nextSortingOrder}
          isSortable={columnDef.enableSorting}
        />
      </Box>
    </EnhancedTableCellStyled>
  )
}

export default HeaderCell
