import VaultModel from '@lib/models/vault.model'
import CreateVaultInterface from '@lib/interfaces/vault/create-vault.interface'
import RedStackModel from '@lib/models/red-stack.model'
import DestroyVaultInterface from '@lib/interfaces/vault/destroy-vault.interface'
import VaultMetricsModel from '@lib/models/vault-metrics.model'

export const REQUEST_VAULT_LIST = 'REQUEST_VAULT_LIST'
export const SET_VAULT_LIST = 'SET_VAULT_LIST'
export const REQUEST_ACCOUNT_VAULT_LIST = 'REQUEST_ACCOUNT_VAULT_LIST'
export const SET_ACCOUNT_VAULT_LIST = 'SET_ACCOUNT_VAULT_LIST'
export const CREATE_VAULT = 'CREATE_VAULT'
export const DESTROY_VAULT = 'DESTROY_VAULT'
export const REQUEST_ACCOUNT_RED_STACKS = 'REQUEST_ACCOUNT_RED_STACKS'
export const SET_ACCOUNT_RED_STACKS = 'SET_ACCOUNT_RED_STACKS'
export const REQUEST_ALL_ACTIVE_RED_STACKS = 'REQUEST_ALL_ACTIVE_RED_STACKS'
export const SET_ALL_ACTIVE_RED_STACKS = 'SET_ALL_ACTIVE_RED_STACKS'
export const SET_VAULT_AS_DEFAULT = 'SET_VAULT_AS_DEFAULT'
export const SET_NEW_DEFAULT_VAULT = 'SET_NEW_DEFAULT_VAULT'
export const REQUEST_SOURCES_VAULT_LIST = 'REQUEST_SOURCES_VAULT_LIST'
export const SET_SOURCES_VAULT_LIST = 'SET_SOURCES_VAULT_LIST'
export const REQUEST_VAULT = 'REQUEST_VAULT'
export const SET_VAULT = 'SET_VAULT'
export const REQUEST_VAULT_METRICS = 'REQUEST_VAULT_METRICS'
export const SET_VAULT_METRICS = 'SET_VAULT_METRICS'
export const UPDATE_VAULT = 'UPDATE_VAULT'
export const UPDATE_VAULT_DATA = 'UPDATE_VAULT_DATA'

export const requestVaultList = () => ({
  type: REQUEST_VAULT_LIST,
  payload: null,
})

export const setVaultList = (payload: Array<VaultModel>) => ({
  type: SET_VAULT_LIST,
  payload,
})

export const requestAccountVaultList = (accountId: string) => ({
  type: REQUEST_ACCOUNT_VAULT_LIST,
  payload: accountId,
})

export const setAccountVaultList = (payload: Array<VaultModel>) => ({
  type: SET_ACCOUNT_VAULT_LIST,
  payload,
})

export const requestSourcesVaultList = () => ({
  type: REQUEST_SOURCES_VAULT_LIST,
  payload: null,
})

export const setSourcesVaultList = (payload: Array<VaultModel>) => ({
  type: SET_SOURCES_VAULT_LIST,
  payload,
})

export const createVault = (payload: CreateVaultInterface) => ({
  type: CREATE_VAULT,
  payload,
})

export const destroyVault = (payload: DestroyVaultInterface) => ({
  type: DESTROY_VAULT,
  payload,
})

export const requestAccountRedStacks = (accountId: string) => ({
  type: REQUEST_ACCOUNT_RED_STACKS,
  payload: accountId,
})

export const setAccountRedStacks = (payload: Array<RedStackModel>) => ({
  type: SET_ACCOUNT_RED_STACKS,
  payload,
})

export const requestAllActiveRedStacks = () => ({
  type: REQUEST_ALL_ACTIVE_RED_STACKS,
  payload: null,
})

export const setAllActiveRedStacks = (redStacks: Array<RedStackModel>) => ({
  type: SET_ALL_ACTIVE_RED_STACKS,
  payload: redStacks,
})

export const setVaultAsDefault = (
  payload: Pick<VaultModel, 'innerId' | 'region' | 'name'>
) => ({
  type: SET_VAULT_AS_DEFAULT,
  payload,
})

export const setNewDefaultVault = (
  payload: Pick<VaultModel, 'innerId' | 'region'>
) => ({
  type: SET_NEW_DEFAULT_VAULT,
  payload,
})

export const requestVault = (vaultId: string) => ({
  type: REQUEST_VAULT,
  payload: vaultId,
})

export const setVault = (vaultModel: VaultModel) => ({
  type: SET_VAULT,
  payload: vaultModel,
})

export const requestVaultMetrics = (vaultId: string) => ({
  type: REQUEST_VAULT_METRICS,
  payload: vaultId,
})

export const setVaultMetrics = (vaultMetricsModel: VaultMetricsModel) => ({
  type: SET_VAULT_METRICS,
  payload: vaultMetricsModel,
})

export const updateVaultData = (
  description: string,
  vpcId: string,
  subnetIdsList: Array<string>
) => ({
  type: UPDATE_VAULT_DATA,
  payload: {
    description: description,
    vpcId: vpcId,
    subnetIdsList: subnetIdsList,
  },
})

export const updateVault = (
  vaultId: string,
  description: string,
  vpcId: string,
  subnetIdsList: Array<string>,
  safetyLock: boolean
) => ({
  type: UPDATE_VAULT,
  payload: {
    vaultId: vaultId,
    description: description,
    vpcId: vpcId,
    subnetIdsList: subnetIdsList,
    safetyLock: safetyLock,
  },
})
