import BaseDataConstant from '@lib/constants/data/base-data.constant'
import ValueInterface from '@lib/interfaces/value.interface'

class VolumeStatusCheckConstantInner extends BaseDataConstant<ValueInterface> {
  get VOLUME_STATUS_CHECK_UNKNOWN(): ValueInterface {
    return {
      name: 'Undefined',
      value: 0,
    }
  }

  get VOLUME_STATUS_IMPAIRED(): ValueInterface {
    return {
      name: 'Impaired',
      value: 1,
    }
  }

  get VOLUME_STATUS_INSUFFICIENT_DATA(): ValueInterface {
    return {
      name: 'Insufficient data',
      value: 2,
    }
  }

  get VOLUME_STATUS_OK(): ValueInterface {
    return {
      name: 'Ok',
      value: 3,
    }
  }
}

const VolumeStatusConstant = VolumeStatusCheckConstantInner.build(
  VolumeStatusCheckConstantInner,
  'VOLUME_STATUS_CHECK_'
)

export default VolumeStatusConstant
