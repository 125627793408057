"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listEfsWithActiveThreatsPossibleFilters = void 0;
const types_1 = require("../../models/threats/types");
class listEfsWithActiveThreatsPossibleFilters {
    #efsList;
    #threats;
    constructor(parameters) {
        this.#efsList = parameters.efsList;
        this.#threats = parameters.threats;
    }
    execute() {
        const accountIds = new Set();
        const regions = new Set();
        const malware = new Set();
        const ransomware = new Set();
        this.#efsList.forEach((efs) => {
            const efsThreats = this.#threats.filter(({ source: { asset, assetItem } }) => asset?.assetId === efs.id || assetItem?.assetId === efs.id);
            efsThreats.forEach((threat) => {
                if (threat.kind === types_1.ThreatKind.RANSOMWARE) {
                    ransomware.add(threat.name);
                }
                if (threat.kind === types_1.ThreatKind.MALWARE) {
                    malware.add(threat.name);
                }
            });
            accountIds.add(efs.awsAccountId);
            regions.add(efs.awsRegion);
        });
        return {
            possibleEfsWithActiveThreatsFilters: {
                accountIds: [...accountIds],
                regions: [...regions],
                malwaresList: [...malware],
                ransomwaresList: [...ransomware],
                idSearch: '',
            },
        };
    }
}
exports.listEfsWithActiveThreatsPossibleFilters = listEfsWithActiveThreatsPossibleFilters;
