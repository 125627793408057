import React from 'react'

function AssetItemFileIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14121_144803)">
        <path
          d="M13.7793 4.16462L10.7661 1.31394C10.4456 1.01072 10.0261 0.84375 9.58493 0.84375H3.84814C2.90043 0.84375 2.12939 1.61478 2.12939 2.5625V15.125C2.12939 16.0727 2.90043 16.8438 3.84814 16.8438H12.5981C13.5459 16.8438 14.3169 16.0727 14.3169 15.125V5.41319C14.3169 4.94291 14.121 4.48781 13.7793 4.16462ZM12.8688 4.59375H10.5356C10.4495 4.59375 10.3794 4.52366 10.3794 4.4375V2.23863L12.8688 4.59375ZM12.5981 15.9062H3.84814C3.41736 15.9062 3.06689 15.5558 3.06689 15.125V2.5625C3.06689 2.13172 3.41736 1.78125 3.84814 1.78125H9.44189V4.4375C9.44189 5.04059 9.93255 5.53125 10.5356 5.53125H13.3794V15.125C13.3794 15.5558 13.0289 15.9062 12.5981 15.9062Z"
          fill="black"
          stroke="black"
          strokeWidth="0.2"
        />
        <path
          d="M11.5669 7.09375H4.69189C4.43302 7.09375 4.22314 7.30362 4.22314 7.5625C4.22314 7.82138 4.43302 8.03125 4.69189 8.03125H11.5669C11.8258 8.03125 12.0356 7.82138 12.0356 7.5625C12.0356 7.30362 11.8258 7.09375 11.5669 7.09375Z"
          fill="black"
          stroke="black"
          strokeWidth="0.2"
        />
        <path
          d="M11.5669 9.59375H4.69189C4.43302 9.59375 4.22314 9.80362 4.22314 10.0625C4.22314 10.3214 4.43302 10.5312 4.69189 10.5312H11.5669C11.8258 10.5312 12.0356 10.3214 12.0356 10.0625C12.0356 9.80362 11.8258 9.59375 11.5669 9.59375Z"
          fill="black"
          stroke="black"
          strokeWidth="0.2"
        />
        <path
          d="M6.96439 12.0938H4.69189C4.43302 12.0938 4.22314 12.3036 4.22314 12.5625C4.22314 12.8214 4.43302 13.0312 4.69189 13.0312H6.96439C7.22327 13.0312 7.43314 12.8214 7.43314 12.5625C7.43314 12.3036 7.22327 12.0938 6.96439 12.0938Z"
          fill="black"
          stroke="black"
          strokeWidth="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_14121_144803">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.223145 0.84375)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AssetItemFileIcon
