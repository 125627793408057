import { GrpcClient } from '@lib/clients/grpc-client'
import { AssetsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import { EFSTransformer } from '@lib/clients/assets/transformers'
// eslint-disable-next-line import/no-extraneous-dependencies
import { EFS } from 'blues-corejs/dist'
import { AbstractListRequest } from '@lib/clients/list-request'
import { ListEfs } from '@lib/clients/grpc-imports'

export class ListEfsClient extends GrpcClient<AssetsPromiseClient> {
  #assetsClient: AssetsPromiseClient

  constructor(hostName = '') {
    super()
    this.#assetsClient = this.getClient(hostName)
  }

  protected innerClientTypeId(): string {
    return 'ListEfsClient'
  }

  protected initClient(hostName = ''): AssetsPromiseClient {
    return new AssetsPromiseClient(hostName, null, null)
  }

  async list(request: AbstractListRequest<ListEfs>): Promise<Array<EFS>> {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#assetsClient.listEFS(
            request.createGrpcRequest(),
            this.metadata()
          ),
        {
          requestName: 'AssetsPromiseClient/listEFS',
        }
      )
    ).toObject()

    return response.filesystemsList.map((item) =>
      new EFSTransformer(item).transform()
    )
  }
}
