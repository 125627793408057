"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ebsSnapshotFactory = void 0;
const ebs_snapshot_1 = require("../../models/backups/aws/ebs-snapshot");
const factories_1 = require("../../use_cases/tests/factories");
const fishery_1 = require("fishery");
const common_1 = require("./common");
const types_1 = require("../../models/backups/aws/ebs-snapshot/types");
class EBSSnapshotFactory extends fishery_1.Factory {
}
const correspondingBackup = () => ({
    awsBackup: {
        awsbRpId: (0, factories_1.uuid)(),
    },
    elastioRecoveryPoint: {
        erpId: (0, factories_1.uuid)(),
    },
});
const threatsIds = () => Array.from({ length: 10 }, factories_1.uuid);
const scanIds = () => Array.from({ length: 10 }, factories_1.uuid);
exports.ebsSnapshotFactory = EBSSnapshotFactory.define(({ transientParams }) => {
    return new ebs_snapshot_1.EBSSnapshot({
        malwareCheckStatus: transientParams.malwareCheckStatus ?? (0, common_1.randomValueFromEnum)(types_1.CheckStatus),
        ransomwareCheckStatus: transientParams.ransomwareCheckStatus ??
            (0, common_1.randomValueFromEnum)(types_1.CheckStatus),
        fsCheckStatus: transientParams.fsCheckStatus ?? (0, common_1.randomValueFromEnum)(types_1.CheckStatus),
        id: transientParams.id ?? (0, factories_1.uuid)(),
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
        awsId: transientParams.awsId ?? (0, factories_1.awsSnapshotId)(),
        arn: transientParams.arn ?? (0, factories_1.uuid)(),
        awsAccountId: transientParams.awsAccountId ?? (0, factories_1.awsAccountId)(),
        awsRegion: transientParams.awsRegion ?? (0, factories_1.awsRegion)(),
        tags: transientParams.tags ?? [],
        description: transientParams.description ?? '',
        status: transientParams.status ?? (0, common_1.randomValueFromEnum)(types_1.EBSSnapshotStatus),
        encrypted: transientParams.encrypted ?? (0, factories_1.bool)(),
        publiclyShared: transientParams.publiclyShared ?? (0, factories_1.bool)(),
        storageTier: transientParams.storageTier ?? (0, common_1.randomValueFromEnum)(types_1.StorageTier),
        size: transientParams.size ?? Math.floor(Math.random() * 1000000000),
        volumeId: transientParams.volumeId ?? (0, factories_1.awsVolumeId)(),
        correspondingBackup: transientParams.correspondingBackup ?? correspondingBackup(),
        jobId: transientParams.jobId ?? (0, factories_1.uuid)(),
        backupPolicyName: transientParams.backupPolicyName ?? [],
        deletedAt: transientParams.deletedAt ?? (0, common_1.date)(),
        threatIds: transientParams.threatIds ?? threatsIds(),
        scanIds: transientParams.scanIds ?? scanIds(),
        timestamp: transientParams.timestamp ?? (0, common_1.date)(),
    });
});
