"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.US_GOV_WEST_1_HUMANREADABLE = exports.US_GOV_EAST_1_HUMANREADABLE = exports.SA_EAST_1_HUMANREADABLE = exports.ME_SOUTH_1_HUMANREADABLE = exports.EU_NORTH_1_HUMANREADABLE = exports.EU_WEST_3_HUMANREADABLE = exports.EU_SOUTH_1_HUMANREADABLE = exports.EU_WEST_2_HUMANREADABLE = exports.EU_WEST_1_HUMANREADABLE = exports.EU_CENTRAL_1_HUMANREADABLE = exports.CN_NORTHWEST_1_HUMANREADABLE = exports.CN_NORTH_1_HUMANREADABLE = exports.CA_CENTRAL_1_HUMANREADABLE = exports.AP_NORTHEAST_1_HUMANREADABLE = exports.AP_SOUTHEAST_2_HUMANREADABLE = exports.AP_SOUTHEAST_1_HUMANREADABLE = exports.AP_NORTHEAST_2_HUMANREADABLE = exports.AP_NORTHEAST_3_HUMANREADABLE = exports.AP_SOUTH_1_HUMANREADABLE = exports.AP_EAST_1_HUMANREADABLE = exports.AF_SOUTH_1_HUMANREADABLE = exports.US_WEST_2_HUMANREADABLE = exports.US_WEST_1_HUMANREADABLE = exports.US_EAST_1_HUMANREADABLE = exports.US_EAST_2_HUMANREADABLE = exports.US_GOV_WEST_1 = exports.US_GOV_EAST_1 = exports.SA_EAST_1 = exports.ME_SOUTH_1 = exports.EU_NORTH_1 = exports.EU_WEST_3 = exports.EU_SOUTH_1 = exports.EU_WEST_2 = exports.EU_WEST_1 = exports.EU_CENTRAL_1 = exports.CN_NORTHWEST_1 = exports.CN_NORTH_1 = exports.CA_CENTRAL_1 = exports.AP_NORTHEAST_1 = exports.AP_SOUTHEAST_2 = exports.AP_SOUTHEAST_1 = exports.AP_NORTHEAST_2 = exports.AP_NORTHEAST_3 = exports.AP_SOUTH_1 = exports.AP_EAST_1 = exports.AF_SOUTH_1 = exports.US_WEST_2 = exports.US_WEST_1 = exports.US_EAST_1 = exports.US_EAST_2 = void 0;
exports.regionToHumanReadable = void 0;
exports.US_EAST_2 = 'us-east-2';
exports.US_EAST_1 = 'us-east-1';
exports.US_WEST_1 = 'us-west-1';
exports.US_WEST_2 = 'us-west-2';
exports.AF_SOUTH_1 = 'af-south-1';
exports.AP_EAST_1 = 'ap-east-1';
exports.AP_SOUTH_1 = 'ap-south-1';
exports.AP_NORTHEAST_3 = 'ap-northeast-3';
exports.AP_NORTHEAST_2 = 'ap-northeast-2';
exports.AP_SOUTHEAST_1 = 'ap-southeast-1';
exports.AP_SOUTHEAST_2 = 'ap-southeast-2';
exports.AP_NORTHEAST_1 = 'ap-northeast-1';
exports.CA_CENTRAL_1 = 'ca-central-1';
exports.CN_NORTH_1 = 'cn-north-1';
exports.CN_NORTHWEST_1 = 'cn-northwest-1';
exports.EU_CENTRAL_1 = 'eu-central-1';
exports.EU_WEST_1 = 'eu-west-1';
exports.EU_WEST_2 = 'eu-west-2';
exports.EU_SOUTH_1 = 'eu-south-1';
exports.EU_WEST_3 = 'eu-west-3';
exports.EU_NORTH_1 = 'eu-north-1';
exports.ME_SOUTH_1 = 'me-south-1';
exports.SA_EAST_1 = 'sa-east-1';
exports.US_GOV_EAST_1 = 'us-gov-east-1';
exports.US_GOV_WEST_1 = 'us-gov-west-1';
exports.US_EAST_2_HUMANREADABLE = 'Ohio';
exports.US_EAST_1_HUMANREADABLE = 'N. Virginia';
exports.US_WEST_1_HUMANREADABLE = 'N. California';
exports.US_WEST_2_HUMANREADABLE = 'Oregon';
exports.AF_SOUTH_1_HUMANREADABLE = 'Cape Town';
exports.AP_EAST_1_HUMANREADABLE = 'Hong Kong';
exports.AP_SOUTH_1_HUMANREADABLE = 'Mumbai';
exports.AP_NORTHEAST_3_HUMANREADABLE = 'Osaka-Local';
exports.AP_NORTHEAST_2_HUMANREADABLE = 'Seoul';
exports.AP_SOUTHEAST_1_HUMANREADABLE = 'Singapore';
exports.AP_SOUTHEAST_2_HUMANREADABLE = 'Sydney';
exports.AP_NORTHEAST_1_HUMANREADABLE = 'Tokyo';
exports.CA_CENTRAL_1_HUMANREADABLE = 'Canada (Central)';
exports.CN_NORTH_1_HUMANREADABLE = 'China (Beijing)';
exports.CN_NORTHWEST_1_HUMANREADABLE = 'China (Ningxia)';
exports.EU_CENTRAL_1_HUMANREADABLE = 'Frankfurt';
exports.EU_WEST_1_HUMANREADABLE = 'Ireland';
exports.EU_WEST_2_HUMANREADABLE = 'London';
exports.EU_SOUTH_1_HUMANREADABLE = 'Milan';
exports.EU_WEST_3_HUMANREADABLE = 'Paris';
exports.EU_NORTH_1_HUMANREADABLE = 'Stockholm';
exports.ME_SOUTH_1_HUMANREADABLE = 'Bahrain';
exports.SA_EAST_1_HUMANREADABLE = 'São Paulo';
exports.US_GOV_EAST_1_HUMANREADABLE = 'AWS GovCloud (US-East)';
exports.US_GOV_WEST_1_HUMANREADABLE = 'AWS GovCloud (US)';
exports.regionToHumanReadable = {
    [exports.US_EAST_2]: exports.US_EAST_2_HUMANREADABLE,
    [exports.US_EAST_1]: exports.US_EAST_1_HUMANREADABLE,
    [exports.US_WEST_1]: exports.US_WEST_1_HUMANREADABLE,
    [exports.US_WEST_2]: exports.US_WEST_2_HUMANREADABLE,
    [exports.AF_SOUTH_1]: exports.AF_SOUTH_1_HUMANREADABLE,
    [exports.AP_EAST_1]: exports.AP_EAST_1_HUMANREADABLE,
    [exports.AP_SOUTH_1]: exports.AP_SOUTH_1_HUMANREADABLE,
    [exports.AP_NORTHEAST_3]: exports.AP_NORTHEAST_3_HUMANREADABLE,
    [exports.AP_NORTHEAST_2]: exports.AP_NORTHEAST_2_HUMANREADABLE,
    [exports.AP_SOUTHEAST_1]: exports.AP_SOUTHEAST_1_HUMANREADABLE,
    [exports.AP_SOUTHEAST_2]: exports.AP_SOUTHEAST_2_HUMANREADABLE,
    [exports.AP_NORTHEAST_1]: exports.AP_NORTHEAST_1_HUMANREADABLE,
    [exports.CA_CENTRAL_1]: exports.CA_CENTRAL_1_HUMANREADABLE,
    [exports.CN_NORTH_1]: exports.CN_NORTH_1_HUMANREADABLE,
    [exports.CN_NORTHWEST_1]: exports.CN_NORTHWEST_1_HUMANREADABLE,
    [exports.EU_CENTRAL_1]: exports.EU_CENTRAL_1_HUMANREADABLE,
    [exports.EU_WEST_1]: exports.EU_WEST_1_HUMANREADABLE,
    [exports.EU_WEST_2]: exports.EU_WEST_2_HUMANREADABLE,
    [exports.EU_SOUTH_1]: exports.EU_SOUTH_1_HUMANREADABLE,
    [exports.EU_WEST_3]: exports.EU_WEST_3_HUMANREADABLE,
    [exports.EU_NORTH_1]: exports.EU_NORTH_1_HUMANREADABLE,
    [exports.ME_SOUTH_1]: exports.ME_SOUTH_1_HUMANREADABLE,
    [exports.SA_EAST_1]: exports.SA_EAST_1_HUMANREADABLE,
    [exports.US_GOV_EAST_1]: exports.US_GOV_EAST_1_HUMANREADABLE,
    [exports.US_GOV_WEST_1]: exports.US_GOV_WEST_1_HUMANREADABLE,
};
