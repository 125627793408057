import { Moment } from 'moment'
import { Nullable } from '@lib/engine-types'
import BaseModel from '@lib/models/base-model/base.model'

class AccessTokenModel extends BaseModel {
  public readonly name: string

  public readonly userId: string

  public readonly tenantId: string

  public readonly createdAt: Nullable<Moment>

  public readonly updatedAt: Nullable<Moment>

  public readonly scopesList: Array<string>

  // extra

  public readonly scopesListRow: string

  constructor(readonly params: any = {}) {
    super(params)

    // base
    this.createdAt = params.createdAt
    this.updatedAt = params.updatedAt
    this.name = params.name
    this.tenantId = params.tenantId
    this.userId = params.userId
    this.scopesList = params.scopesList

    // extra
    this.scopesListRow = this.scopesList.join(', ')
  }
}

export default AccessTokenModel
