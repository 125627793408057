"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Threat = void 0;
class Threat {
    #source;
    #kind;
    #status;
    #name;
    #location;
    #scanIds;
    #firstDetectedAt;
    #resolvedAt;
    #ignoredAt;
    #id;
    #createdAt;
    #updatedAt;
    constructor(parameters) {
        const { source, kind, status, name, location, scanIds, firstDetectedAt, resolvedAt, ignoredAt, id, createdAt, updatedAt, } = parameters;
        this.#id = id;
        this.#createdAt = createdAt;
        this.#updatedAt = updatedAt;
        this.#source = source;
        this.#kind = kind;
        this.#status = status;
        this.#name = name;
        this.#location = location;
        this.#scanIds = Object.freeze([...scanIds]);
        this.#firstDetectedAt = firstDetectedAt;
        this.#resolvedAt = resolvedAt;
        this.#ignoredAt = ignoredAt;
    }
    get source() {
        return this.#source;
    }
    get kind() {
        return this.#kind;
    }
    get status() {
        return this.#status;
    }
    get name() {
        return this.#name;
    }
    get location() {
        return this.#location;
    }
    get scanIds() {
        return [...this.#scanIds];
    }
    get firstDetectedAt() {
        return this.#firstDetectedAt;
    }
    get resolvedAt() {
        return this.#resolvedAt;
    }
    get ignoredAt() {
        return this.#ignoredAt;
    }
    get id() {
        return this.#id;
    }
    get createdAt() {
        return this.#createdAt;
    }
    get updatedAt() {
        return this.#updatedAt;
    }
    get assetId() {
        return this.source.asset?.assetId || this.source.assetItem?.assetId;
    }
}
exports.Threat = Threat;
