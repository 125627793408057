"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListScansForGenericAssetItems = void 0;
const types_1 = require("../../models/scans/malware/types");
const types_2 = require("../../models/scans/ransomware/types");
const types_3 = require("../../models/scans/filesystem-check/types");
__exportStar(require("./types"), exports);
class ListScansForGenericAssetItems {
    #scans;
    #threats;
    #assetItemKind;
    constructor({ scans, threats, assetItemKind, }) {
        this.#scans = scans;
        this.#threats = threats;
        this.#assetItemKind = assetItemKind;
    }
    execute(filters) {
        return this.#prepareOutput(this.#findScanMatchingFilters(filters));
    }
    #prepareOutput({ scans, }) {
        const scansOutput = scans.map((scan) => {
            const malware = scan.malwares;
            const ransomware = scan.ransomwares;
            const filesystem = scan.filesystemChecks;
            const unionScans = [
                malware,
                ransomware,
                filesystem,
            ];
            const malwareThreatsNames = this.#getThreatsNameByScanId(malware?.id);
            const ransomwareThreatsNames = this.#getThreatsNameByScanId(ransomware?.id);
            const filesystemThreatsNames = this.#getThreatsNameByScanId(filesystem?.id);
            const threatsNames = [
                ...malwareThreatsNames,
                ...ransomwareThreatsNames,
                ...filesystemThreatsNames,
            ];
            const scanId = malware?.id || ransomware?.id || filesystem?.id || '';
            const lastCompleteTime = this.#getLastCompleteTime(unionScans);
            return {
                isHealthy: this.#isHealthy([malware, ransomware, filesystem]),
                risks: {
                    malwareStatus: malware?.status,
                    ransomwareStatus: ransomware?.status,
                    fsCheckStatus: filesystem?.status,
                },
                completeTime: lastCompleteTime,
                threatsNames: threatsNames,
                malwareNames: malwareThreatsNames,
                ransomwareNames: ransomwareThreatsNames,
                scanId: scanId,
                scanData: {
                    malwareScan: malware,
                    ransomwareScan: ransomware,
                    filesystemScan: filesystem,
                },
                assetItemKind: this.#assetItemKind,
            };
        });
        return {
            scans: scansOutput,
        };
    }
    #isHealthy(scans) {
        return scans.every((scan) => !(scan?.status === types_1.MalwareScanStatus.INFECTED ||
            scan?.status === types_2.RansomwareScanStatus.INFECTED ||
            scan?.status === types_3.StatusScanFilesystemCheck.BAD));
    }
    #getLastCompleteTime(scans) {
        return scans.reduce((lastCompleteTime, scan) => {
            if (scan && scan.createdAt > lastCompleteTime) {
                return scan.createdAt;
            }
            return lastCompleteTime;
        }, new Date(0));
    }
    #getThreatsNameByScanId(scanId) {
        if (!scanId) {
            return [];
        }
        return Array.from(this.#threats.reduce((uniqueNames, threat) => {
            if (threat.scanIds.includes(scanId)) {
                if (threat.name) {
                    uniqueNames.add(threat.name);
                }
            }
            return uniqueNames;
        }, new Set()));
    }
    #findScanMatchingFilters(filters) {
        const startDate = filters.dateRange.startDate;
        const endDate = filters.dateRange.endDate;
        if (startDate && endDate) {
            const scans = this.#scans.filter((scan) => {
                const unionScans = [
                    scan.malwares,
                    scan.ransomwares,
                ];
                const lastTime = this.#getLastCompleteTime(unionScans);
                return startDate <= lastTime && lastTime <= endDate;
            });
            return {
                scans,
            };
        }
        return {
            scans: this.#scans,
        };
    }
}
exports.ListScansForGenericAssetItems = ListScansForGenericAssetItems;
