"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.efsItemFactory = void 0;
const efs_item_1 = require("../../models/asset-items/efs-item");
const factories_1 = require("../../use_cases/tests/factories");
const fishery_1 = require("fishery");
const common_1 = require("./common");
const types_1 = require("../../models/asset-items/efs-item/types");
class EfsItemFactory extends fishery_1.Factory {
}
const selector = () => ({
    kind: (0, common_1.randomValueFromEnum)(types_1.SelectorKind),
    value: (0, factories_1.uuid)(),
});
const selectors = new Array(10).fill(null).map(selector);
exports.efsItemFactory = EfsItemFactory.define(({ transientParams, sequence }) => {
    return new efs_item_1.EfsItem({
        assetId: transientParams.assetId ?? (0, factories_1.uuid)(),
        id: transientParams.id ?? (0, factories_1.uuid)(),
        ccAssetId: transientParams.ccAssetId ?? (0, factories_1.uuid)(),
        efsId: transientParams.efsId ?? (0, factories_1.uuid)(),
        name: transientParams.name ?? (0, factories_1.name)('Name', sequence),
        selectors: transientParams.selectors ?? selectors,
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
    });
});
