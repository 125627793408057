import { ModalState } from '@store/states/modal.state'
import ActionInterface from '@lib/interfaces/action.interface'
import {
  HIDE_MODAL,
  SHOW_ACCESS_DENIED_MODAL,
  SHOW_MODAL,
} from '@store/actions/modal.action'
import Modal from '@lib/constants/modal.constant'

const initialState: ModalState = {
  openedModals: [],
  accessDeniedModalMessage: '',
}

function modalReducer(
  prevState = initialState,
  action: ActionInterface
): ModalState {
  const modals: Array<Modal> = prevState.openedModals
  let newOpenedModals: Array<Modal>
  switch (action.type) {
    case SHOW_MODAL:
      if (modals.includes(action.payload)) {
        return prevState
      }
      return {
        ...prevState,
        openedModals: [...modals, action.payload],
      }

    case HIDE_MODAL:
      if (!modals.includes(action.payload)) {
        return prevState
      }
      return {
        ...prevState,
        openedModals: modals.filter((v) => v !== action.payload),
      }

    case SHOW_ACCESS_DENIED_MODAL:
      newOpenedModals = modals.filter((v) => v !== Modal.accessDenied)
      newOpenedModals.push(Modal.accessDenied)
      return {
        ...prevState,
        accessDeniedModalMessage: action.payload,
        openedModals: newOpenedModals,
      }

    default:
      return prevState
  }
}

export default modalReducer
