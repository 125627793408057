"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.frequenciesConstants = exports.MONTHLY = exports.WEEKLY = exports.EVERY_12_HOURS = exports.DAILY = exports.EVERY_HOUR = exports.EVERY_30_MINUTES = exports.EVERY_15_MINUTES = void 0;
exports.EVERY_15_MINUTES = 'every15Minutes';
exports.EVERY_30_MINUTES = 'every30Minutes';
exports.EVERY_HOUR = 'everyHour';
exports.DAILY = 'daily';
exports.EVERY_12_HOURS = 'every12Hours';
exports.WEEKLY = 'weekly';
exports.MONTHLY = 'monthly';
exports.frequenciesConstants = {
    [exports.EVERY_15_MINUTES]: 15 * 60,
    [exports.EVERY_30_MINUTES]: 30 * 60,
    [exports.EVERY_HOUR]: 60 * 60,
    [exports.DAILY]: 24 * 60 * 60,
    [exports.EVERY_12_HOURS]: 12 * 60 * 60,
    [exports.WEEKLY]: 7 * 24 * 60 * 60,
    [exports.MONTHLY]: 30 * 24 * 60 * 60,
};
