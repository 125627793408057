import { Request as ListBackupsRequest } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/backups/list_backups_for_asset_item_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { AbstractListRequest } from '@lib/clients/list-request'
import { ListBackupsForAssetItemsFiltersParams } from './types'

export class ListBackupsForAssetItemsFilter extends AbstractListRequest<ListBackupsRequest> {
  #requestParams: Partial<ListBackupsForAssetItemsFiltersParams>

  constructor(requestParam: Partial<ListBackupsForAssetItemsFiltersParams>) {
    super()
    this.#requestParams = requestParam
  }

  createGrpcRequest(): ListBackupsRequest {
    const grpcRequest = ListBackupsRequest

    const filter = this.#buildFilter()

    return new grpcRequest().setInitial(
      new ListBackupsRequest.Initial()
        .setFilter(filter)
        .setPageSize(
          this.#requestParams?.pageSize || AbstractListRequest.DEFAULT_PAGE_SIZE
        )
    )
  }

  #createTimestamp(seconds: number) {
    const timestamp = new Timestamp()
    timestamp.setSeconds(seconds)
    return timestamp
  }

  #setupTimeRange({ start, end }: { start: number; end: number }) {
    const timeRange = new ListBackupsRequest.Initial.Filter.TimeRange()
    if (start) {
      timeRange.setStart(this.#createTimestamp(start))
    }

    if (end) {
      timeRange.setEnd(this.#createTimestamp(end))
    }
    return timeRange
  }

  #buildFilter() {
    const filter = new ListBackupsRequest.Initial.Filter()
    const { timeRange, assetItems } = this.#requestParams

    if (assetItems) {
      const assetItemsList = assetItems.map((item) => {
        const assetItemRequest =
          new ListBackupsRequest.Initial.Filter.AssetItem()
        assetItemRequest.setKind(item.kind)
        assetItemRequest.setId(item.id)
        return assetItemRequest
      })
      filter.setAssetItemsList(assetItemsList)
    }

    if (timeRange) {
      const timeRangeProto = this.#setupTimeRange({
        start: timeRange.start as number,
        end: timeRange.end as number,
      })
      filter.setTimeRange(timeRangeProto)
    }

    return filter
  }
}
