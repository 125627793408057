"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobCloudConnector = void 0;
const job_1 = require("../../job");
const types_1 = require("./types");
class JobCloudConnector extends job_1.Job {
    #status;
    #kind;
    #progress;
    #startedAt;
    #finishedAt;
    #abortRequested;
    #finalized;
    #targetAssetId;
    #scanIds;
    #ccJobId;
    #ccId;
    #parentId;
    #name;
    #statusMessage;
    #childrenList;
    constructor(parameters) {
        const { status, kind, progress, finishedAt, startedAt, abortRequested, finalized, targetAssetId, scanIds, statusMessage, ccJobId, ccId, parentId, name, ...jobParameters } = parameters;
        super(jobParameters);
        this.#status = status;
        this.#kind = kind;
        this.#progress = progress;
        this.#startedAt = startedAt;
        this.#finishedAt = finishedAt;
        this.#abortRequested = abortRequested;
        this.#finalized = finalized;
        this.#targetAssetId = targetAssetId;
        this.#scanIds = scanIds;
        this.#statusMessage = statusMessage;
        this.#ccJobId = ccJobId;
        this.#ccId = ccId;
        this.#parentId = parentId;
        this.#name = name;
        this.#childrenList = parameters.childrenList;
    }
    get status() {
        return this.#status;
    }
    set status(status) {
        this.#status = status;
    }
    get kind() {
        return this.#kind;
    }
    get progress() {
        if (!this.#progress) {
            return;
        }
        return this.#progress.progress;
    }
    get startedAt() {
        return this.#startedAt;
    }
    get finishedAt() {
        return this.#finishedAt;
    }
    get abortRequested() {
        return this.#abortRequested;
    }
    get finalized() {
        return this.#finalized;
    }
    get targetAssetId() {
        return this.#targetAssetId;
    }
    get scanIds() {
        return this.#scanIds;
    }
    get statusMessage() {
        return this.#statusMessage;
    }
    get name() {
        return this.#name;
    }
    get parentId() {
        return this.#parentId;
    }
    get ccId() {
        return this.#ccId;
    }
    get ccJobId() {
        return this.#ccJobId;
    }
    get childrenList() {
        return this.#childrenList;
    }
    get isScheduled() {
        return this.#status === types_1.CloudConnectorJobStatus.SCHEDULED;
    }
    get isInProgress() {
        return this.#status === types_1.CloudConnectorJobStatus.IN_PROGRESS;
    }
    get isFinished() {
        return [
            types_1.CloudConnectorJobStatus.ABORTED,
            types_1.CloudConnectorJobStatus.SUCCEEDED,
            types_1.CloudConnectorJobStatus.FAILED,
        ].includes(this.#status);
    }
    get isFailed() {
        return this.#status === types_1.CloudConnectorJobStatus.FAILED;
    }
    get isAborted() {
        return this.#status === types_1.CloudConnectorJobStatus.ABORTED;
    }
}
exports.JobCloudConnector = JobCloudConnector;
