"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DayOfWeek = exports.AfterOccurrences = exports.OnDate = exports.Never = exports.EveryMonth = exports.EveryWeek = exports.EveryDay = exports.Custom = exports.Monthly = exports.Weekly = exports.Daily = exports.Schedule = void 0;
class Schedule {
    #startsOn;
    #mode;
    constructor(startsOn, mode) {
        this.#startsOn = startsOn;
        this.#mode = mode;
    }
    get startsOn() {
        return this.#startsOn;
    }
    set startsOn(newStartsOn) {
        this.#startsOn = newStartsOn;
    }
    get mode() {
        return this.#mode;
    }
    set mode(newMode) {
        this.#mode = newMode;
    }
}
exports.Schedule = Schedule;
class Daily {
}
exports.Daily = Daily;
class Weekly {
    #day;
    constructor(day) {
        this.#day = day;
    }
    get day() {
        return this.#day;
    }
}
exports.Weekly = Weekly;
class Monthly {
    #dayOfMonth;
    constructor(dayOfMonth) {
        this.#dayOfMonth = dayOfMonth;
    }
    get dayOfMonth() {
        return this.#dayOfMonth;
    }
}
exports.Monthly = Monthly;
class Custom {
    #repeatMode;
    #expirationMode;
    constructor({ repeatMode, expirationMode, }) {
        this.#repeatMode = repeatMode;
        this.#expirationMode = expirationMode;
    }
    get expirationMode() {
        return this.#expirationMode;
    }
    get repeatMode() {
        return this.#repeatMode;
    }
}
exports.Custom = Custom;
class EveryDay {
    #stride;
    constructor(stride) {
        this.#stride = stride;
    }
    get stride() {
        return this.#stride;
    }
}
exports.EveryDay = EveryDay;
class EveryWeek {
    #stride;
    #day;
    constructor(stride, day) {
        this.#stride = stride;
        this.#day = day;
    }
    get day() {
        return this.#day;
    }
    get stride() {
        return this.#stride;
    }
}
exports.EveryWeek = EveryWeek;
class EveryMonth {
    #stride;
    #dayOfMonth;
    constructor(stride, dayOfMonth) {
        this.#stride = stride;
        this.#dayOfMonth = dayOfMonth;
    }
    get stride() {
        return this.#stride;
    }
    get dayOfMonth() {
        return this.#dayOfMonth;
    }
}
exports.EveryMonth = EveryMonth;
class Never {
}
exports.Never = Never;
class OnDate {
    #date;
    constructor(date) {
        this.#date = date;
    }
    get date() {
        return this.#date;
    }
}
exports.OnDate = OnDate;
class AfterOccurrences {
    #afterOccurrences;
    constructor(afterOccurrences) {
        this.#afterOccurrences = afterOccurrences;
    }
    get afterOccurrences() {
        return this.#afterOccurrences;
    }
}
exports.AfterOccurrences = AfterOccurrences;
var DayOfWeek;
(function (DayOfWeek) {
    DayOfWeek[DayOfWeek["SUNDAY"] = 0] = "SUNDAY";
    DayOfWeek[DayOfWeek["MONDAY"] = 1] = "MONDAY";
    DayOfWeek[DayOfWeek["TUESDAY"] = 2] = "TUESDAY";
    DayOfWeek[DayOfWeek["WEDNESDAY"] = 3] = "WEDNESDAY";
    DayOfWeek[DayOfWeek["THURSDAY"] = 4] = "THURSDAY";
    DayOfWeek[DayOfWeek["FRIDAY"] = 5] = "FRIDAY";
    DayOfWeek[DayOfWeek["SATURDAY"] = 6] = "SATURDAY";
})(DayOfWeek = exports.DayOfWeek || (exports.DayOfWeek = {}));
