export default function OverviewIcon({ selected }: { selected?: boolean }) {
  const color = selected ? 'white' : 'black'

  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16195_49651)">
        <path
          d="M13.6986 12.1856C14.6618 10.9013 14.5598 9.06597 13.3924 7.89856C13.1192 7.62534 12.8093 7.41066 12.4785 7.25413V4.36834L8.45395 0.34375H1.22852C0.676232 0.34375 0.228516 0.791465 0.228516 1.34375V15.3438C0.228516 15.896 0.676231 16.3438 1.22852 16.3438H11.4785C12.0308 16.3438 12.4785 15.896 12.4785 15.3438V13.1834C12.6724 13.0917 12.859 12.9799 13.0353 12.8481L15.2219 15.0589C15.4039 15.2429 15.7007 15.2445 15.8848 15.0625C16.0688 14.8805 16.0705 14.5837 15.8884 14.3996L13.6986 12.1856ZM8.72852 1.94416L10.8781 4.09375H8.72852V1.94416ZM11.541 15.4062H1.16602V1.28125H7.79102V4.03125C7.79102 4.58353 8.23873 5.03125 8.79102 5.03125H11.541V6.97244C10.5524 6.83091 9.51105 7.13959 8.75208 7.89856C7.47273 9.17791 7.47273 11.2596 8.75208 12.5389C9.50223 13.2891 10.5395 13.6084 11.541 13.4651V15.4062ZM12.7296 11.876C11.8157 12.7898 10.3288 12.7898 9.41502 11.876C8.5012 10.9622 8.5012 9.47528 9.41502 8.56147C10.3288 7.64766 11.8157 7.64759 12.7296 8.56147C13.6434 9.47528 13.6434 10.9622 12.7296 11.876Z"
          fill={color}
          stroke={color}
          strokeWidth="0.2"
        />
        <path
          d="M8.25977 6H2.57227C2.31338 6 2.10352 6.20987 2.10352 6.46875C2.10352 6.72763 2.31338 6.9375 2.57227 6.9375H8.25977C8.51865 6.9375 8.72852 6.72763 8.72852 6.46875C8.72852 6.20987 8.51865 6 8.25977 6Z"
          fill={color}
        />
        <path
          d="M6.38477 7.875H2.57227C2.31338 7.875 2.10352 8.08487 2.10352 8.34375C2.10352 8.60263 2.31338 8.8125 2.57227 8.8125H6.38477C6.64365 8.8125 6.85352 8.60263 6.85352 8.34375C6.85352 8.08487 6.64365 7.875 6.38477 7.875Z"
          fill={color}
        />
        <path
          d="M6.38477 11.625H2.57227C2.31338 11.625 2.10352 11.8349 2.10352 12.0938C2.10352 12.3526 2.31338 12.5625 2.57227 12.5625H6.38477C6.64365 12.5625 6.85352 12.3526 6.85352 12.0938C6.85352 11.8349 6.64365 11.625 6.38477 11.625Z"
          fill={color}
        />
        <path
          d="M6.38477 9.75H2.57227C2.31338 9.75 2.10352 9.95987 2.10352 10.2188C2.10352 10.4776 2.31338 10.6875 2.57227 10.6875H6.38477C6.64365 10.6875 6.85352 10.4776 6.85352 10.2188C6.85352 9.95987 6.64365 9.75 6.38477 9.75Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_16195_49651">
          <rect
            width="16"
            height="16"
            fill={color}
            transform="translate(0.223145 0.34375)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
