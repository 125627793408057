import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { UsersClient } from '@lib/clients'
import {
  requestUser as fetchUserRequest,
  setUser,
} from '@store/actions/user.action'

export const useFetchUserProfile = (usersClient: UsersClient): boolean => {
  const dispatch = useDispatch()
  const [isProfileFetched, setProfileFetched] = useState(false)

  const fetchUserProfile = useCallback(async () => {
    dispatch(fetchUserRequest())
    const response = await usersClient.getUserProfile()

    if (response) {
      dispatch(setUser(response))
      setProfileFetched(true)
    }
  }, [])

  useEffect(() => {
    fetchUserProfile()
  }, [fetchUserProfile])

  return isProfileFetched
}
