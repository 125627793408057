import React from 'react'

function MyAccountIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_1">
        <path
          id="Vector"
          d="M10 17C8.13023 17 6.37239 16.2719 5.05023 14.9498C3.72814 13.6276 3 11.8698 3 10C3 8.13023 3.72814 6.37239 5.05023 5.05023C6.37239 3.72814 8.13023 3 10 3C11.8698 3 13.6276 3.72814 14.9498 5.05023C16.2719 6.37239 17 8.13023 17 10C17 11.8698 16.2719 13.6276 14.9498 14.9498C13.6276 16.2719 11.8698 17 10 17ZM10 4.09375C6.74328 4.09375 4.09375 6.74328 4.09375 10C4.09375 13.2567 6.74328 15.9062 10 15.9062C13.2567 15.9062 15.9062 13.2567 15.9062 10C15.9062 6.74328 13.2567 4.09375 10 4.09375ZM9.97266 10.5195C8.52521 10.5195 7.34766 9.34197 7.34766 7.89453C7.34766 6.44709 8.52521 5.26953 9.97266 5.26953C11.4201 5.26953 12.5977 6.44709 12.5977 7.89453C12.5977 9.34197 11.4201 10.5195 9.97266 10.5195ZM9.97266 6.36328C9.12831 6.36328 8.44141 7.05021 8.44141 7.89453C8.44141 8.73885 9.12831 9.42578 9.97266 9.42578C10.817 9.42578 11.5039 8.73885 11.5039 7.89453C11.5039 7.05021 10.817 6.36328 9.97266 6.36328ZM13.4374 13.7043C13.6711 13.5129 13.7054 13.1684 13.514 12.9347C12.7407 11.9907 11.5981 11.4492 10.3791 11.4492H9.73025C8.5113 11.4492 7.36868 11.9907 6.5954 12.9347C6.40402 13.1684 6.43829 13.5129 6.67196 13.7043C6.90562 13.8957 7.25018 13.8614 7.44158 13.6278C8.00623 12.9384 8.84043 12.543 9.73025 12.543H10.3791C11.2689 12.543 12.1031 12.9384 12.6678 13.6278C12.776 13.7598 12.9329 13.8281 13.0912 13.8281C13.2131 13.8281 13.3358 13.7876 13.4374 13.7043Z"
          fill="#999CAA"
        />
      </g>
    </svg>
  )
}

export default MyAccountIcon
