"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.s3BucketFactory = void 0;
const s3_bucket_1 = require("../../models/assets/aws/s3-bucket");
const factories_1 = require("../../use_cases/tests/factories");
const fishery_1 = require("fishery");
const common_1 = require("./common");
const types_1 = require("../../models/assets/aws/s3-bucket/types");
class S3BucketFactory extends fishery_1.Factory {
}
exports.s3BucketFactory = S3BucketFactory.define(({ transientParams }) => {
    return new s3_bucket_1.S3Bucket({
        id: transientParams.id ?? (0, factories_1.uuid)(),
        awsId: transientParams.awsId ?? (0, factories_1.uuid)(),
        awsAccountId: transientParams.awsAccountId ?? (0, factories_1.awsAccountId)(),
        awsRegion: transientParams.awsRegion ?? (0, factories_1.awsRegion)(),
        arn: transientParams.arn ?? (0, factories_1.uuid)(),
        objectCount: transientParams.objectCount ?? Math.floor(Math.random() * 10),
        size: transientParams.size ?? Math.floor(Math.random() * 100000),
        state: transientParams.state ?? (0, common_1.randomValueFromEnum)(types_1.S3BucketState),
        tags: transientParams.tags ?? [],
        backupPolicies: transientParams.backupPolicies ?? [],
        retentionPolicy: transientParams.retentionPolicy ?? [],
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
    });
});
