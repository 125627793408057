"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InstanceModel = exports.InstanceGenericBackupKind = exports.InstanceState = exports.OsKind = void 0;
const threat_status_model_1 = require("./threat-status.model");
var OsKind;
(function (OsKind) {
    OsKind[OsKind["OTHER"] = 0] = "OTHER";
    OsKind[OsKind["LINUX"] = 1] = "LINUX";
    OsKind[OsKind["WINDOWS"] = 2] = "WINDOWS";
})(OsKind = exports.OsKind || (exports.OsKind = {}));
var InstanceState;
(function (InstanceState) {
    InstanceState[InstanceState["INSTANCE_STATE_UNKNOWN"] = 0] = "INSTANCE_STATE_UNKNOWN";
    InstanceState[InstanceState["INSTANCE_STATE_RUNNING"] = 1] = "INSTANCE_STATE_RUNNING";
    InstanceState[InstanceState["INSTANCE_STATE_STOPPED"] = 2] = "INSTANCE_STATE_STOPPED";
})(InstanceState = exports.InstanceState || (exports.InstanceState = {}));
var InstanceGenericBackupKind;
(function (InstanceGenericBackupKind) {
    InstanceGenericBackupKind[InstanceGenericBackupKind["UNKNOWN"] = 0] = "UNKNOWN";
    InstanceGenericBackupKind[InstanceGenericBackupKind["ELASTIO"] = 1] = "ELASTIO";
    InstanceGenericBackupKind[InstanceGenericBackupKind["IMPORTED_AWS_BACKUP"] = 2] = "IMPORTED_AWS_BACKUP";
})(InstanceGenericBackupKind = exports.InstanceGenericBackupKind || (exports.InstanceGenericBackupKind = {}));
class InstanceModel extends threat_status_model_1.AssetWithThreat {
    id;
    name;
    accountId;
    region;
    osKind;
    cost;
    volumeIds;
    cloudConnectorId;
    cloudProviderInstanceId;
    createdAt;
    imageName;
    state;
    coveredByPolicies;
    coveredByPausedPolicies;
    size;
    imageId;
    imageLocation;
    vpcId;
    tags;
    lastGenericBackupAt;
    lastGenericBackupKind;
    firstGenericBackupAt;
    coveringPoliciesSLAs;
    associatedRetentionPolicies;
    constructor(attrs) {
        super(attrs);
        this.id = attrs.id;
        this.name = attrs.name;
        this.accountId = attrs.accountId;
        this.region = attrs.region;
        this.osKind = attrs.osKind;
        this.cost = attrs.cost;
        this.volumeIds = attrs.volumeIds;
        this.cloudConnectorId = attrs.cloudConnectorId;
        this.cloudProviderInstanceId = attrs.cloudProviderInstanceId;
        this.createdAt = attrs.createdAt;
        this.imageName = attrs.imageName;
        this.state = attrs.state;
        this.coveredByPolicies = attrs.coveredByPolicies;
        this.coveredByPausedPolicies = attrs.coveredByPausedPolicies;
        this.size = attrs.size;
        this.imageId = attrs.imageId;
        this.imageLocation = attrs.imageLocation;
        this.vpcId = attrs.vpcId;
        this.tags = attrs.tags;
        this.threatStatus = attrs.threatStatus;
        this.lastGenericBackupAt = attrs.lastGenericBackupAt;
        this.firstGenericBackupAt = attrs.firstGenericBackupAt;
        this.coveringPoliciesSLAs = attrs.coveringPoliciesSLAs;
        this.lastGenericBackupKind = attrs.lastGenericBackupKind;
        this.associatedRetentionPolicies = attrs.associatedRetentionPolicies;
    }
    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getAccountId() {
        return this.accountId;
    }
    getRegion() {
        return this.region;
    }
    getOsKind() {
        return this.osKind;
    }
    getCost() {
        return this.cost;
    }
    getVolumeIds() {
        return this.volumeIds;
    }
    getCloudConnectorId() {
        return this.cloudConnectorId;
    }
    getCloudProviderAssetId() {
        return this.cloudProviderInstanceId;
    }
    getCloudProviderInstanceId() {
        return this.cloudProviderInstanceId;
    }
    getCreatedAt() {
        return this.createdAt;
    }
    getRecoveryPointCreatedAt() {
        return this.getLastRpCreatedAt();
    }
    getImageName() {
        return this.imageName;
    }
    getState() {
        return this.state;
    }
    getCoveredByPolicies() {
        return this.coveredByPolicies;
    }
    isCoveredByPausedPolicies() {
        return this.coveredByPausedPolicies.length > 0;
    }
    getAssociatedRetentionPolicies() {
        return this.associatedRetentionPolicies;
    }
    getElastioPoliciesOrAwsBackupCoverage() {
        if (this.isCoveredByPolicy()) {
            return this.getCoveredByPolicies();
        }
        return this.lastGenericBackupKind;
    }
    getSize() {
        return this.size;
    }
    getImageId() {
        return this.imageId;
    }
    getImageLocation() {
        return this.imageLocation;
    }
    getVpcId() {
        return this.vpcId;
    }
    getTags() {
        return Array.from(this.tags);
    }
    getTagsMap() {
        return this.tags;
    }
    getMaxBackupRetention() {
        if (!this.firstGenericBackupAt) {
            return null;
        }
        return Date.now() - (this.firstGenericBackupAt ?? 0);
    }
    getRpo() {
        if (!this.lastGenericBackupAt) {
            return null;
        }
        return Date.now() - this.lastGenericBackupAt;
    }
    hasAnyBackup() {
        return this.lastGenericBackupAt !== null;
    }
    // TODO: it must be array
    getSla() {
        const slaDurations = Array.from(this.coveringPoliciesSLAs.values());
        if (slaDurations.length === 0) {
            return null;
        }
        return Math.min(...slaDurations);
    }
    isOutOfSchedule() {
        const slaDurations = Array.from(this.coveringPoliciesSLAs.values());
        const rpo = this.getRpo();
        if (rpo === null || rpo === undefined) {
            return false;
        }
        for (const slaDuration of slaDurations) {
            if (slaDuration === null || slaDuration === undefined) {
                continue;
            }
            if (rpo > slaDuration) {
                return true;
            }
        }
        return false;
    }
    getAgeInDays() {
        return Math.round(Math.abs((Date.now() - this.createdAt) / (1000 * 3600 * 24)));
    }
    getParsedCloudProviderInstanceId() {
        return (this.cloudProviderInstanceId?.split(':')?.pop() ??
            this.cloudProviderInstanceId ??
            '');
    }
    getParsedCloudProviderAssetId() {
        return this.getParsedCloudProviderInstanceId();
    }
    getCloudConnectorAssetId() {
        return `elastio:asset:aws-ec2:s:${this.getAccountId()}:${this.getRegion()}:${this.getCloudProviderInstanceId()}`;
    }
    isProtected() {
        return this.isLastRecoveryPointId() && this.isCoveredByPolicy();
    }
    isCoveredByPolicy() {
        return this.getCoveredByPolicies().length > 0;
    }
    isCoveredByElastioPolicyOrAwsBackup() {
        return (this.isCoveredByPolicy() ||
            this.lastGenericBackupKind ===
                InstanceGenericBackupKind.IMPORTED_AWS_BACKUP);
    }
    getVolumeType() {
        return '';
    }
    isAssociatedWithAnyRetentionPolicy() {
        return this.getAssociatedRetentionPolicies().length > 0;
    }
}
exports.InstanceModel = InstanceModel;
