import React from 'react'

function EditIcon() {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.60222 4.66667L9.33333 5.39778L2.27111 12.4444H1.55556V11.7289L8.60222 4.66667ZM11.4022 0C11.2078 0 11.0056 0.0777778 10.8578 0.225556L9.43444 1.64889L12.3511 4.56556L13.7744 3.14222C14.0778 2.83889 14.0778 2.33333 13.7744 2.04556L11.9544 0.225556C11.7989 0.07 11.6044 0 11.4022 0ZM8.60222 2.48111L0 11.0833V14H2.91667L11.5189 5.39778L8.60222 2.48111Z"
        fill="#32B6F3"
      />
    </svg>
  )
}

export default EditIcon
