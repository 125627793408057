import { Severity } from '@lib/constants/notifications.constant'
import WebhookModel, {
  ApiKey,
  BasicAuth,
  BearerToken,
  Secret,
  WebhookAuthentication,
  WebhookEventSubscription,
  WebhookModelAttributes,
} from '@lib/models/webhook.model'
import { Webhook } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/webhooks_pb'

class WebhookFactory {
  auth = (authOption?: number, webhook?: Webhook) => {
    const authValues = webhook?.getAuthentication()
    if (!authValues) {
      return
    }

    switch (authOption) {
      case 1:
        const basicAuth = authValues.getBasicAuth()
        const loginValue = basicAuth?.getLogin()?.getValue()
        const passwordValue = basicAuth?.getPassword()?.getValue()
        if (!loginValue || !passwordValue) {
          return
        }

        return new BasicAuth(
          new Secret({
            secret: loginValue,
            encrypted: true,
          }),
          new Secret({
            secret: passwordValue,
            encrypted: true,
          })
        )

      case 2:
        const bearerAuth = authValues.getBearerToken()
        const token = String(bearerAuth?.getToken()?.getValue()) || ''

        return new BearerToken(
          new Secret({
            secret: token,
            encrypted: true,
          })
        )

      case 3:
        const apiAuth = authValues.getApiKey()
        const key = apiAuth?.getKey()?.getValue()
        const value = apiAuth?.getValue()?.getValue()

        if (!key || !value) {
          return
        }

        return new ApiKey(
          new Secret({
            secret: key,
            encrypted: true,
          }),
          new Secret({
            secret: value,
            encrypted: true,
          })
        )

      default:
        return
    }
  }

  buildFake(): WebhookModel {
    const events = [
      new WebhookEventSubscription('vault', 'V0', [
        Severity.ERROR,
        Severity.TRACE,
      ]),
      new WebhookEventSubscription('recovery-point', 'V0', [Severity.ERROR]),
    ]

    const attributes: WebhookModelAttributes = {
      id: 'ab663b96-76e8-4bce-92ab-d2a94807679',
      name: 'test',
      endpoint: 'https://example.com',
      authentication: new WebhookAuthentication(
        this.auth(Math.round(Math.random() * 2) + 1)
      ),
      description: 'description',
      event_subscriptions: events,
    }

    return new WebhookModel(attributes)
  }

  buildFromGrpc(webhook: Webhook): WebhookModel {
    return new WebhookModel({
      id: webhook.getId(),
      name: webhook.getName(),
      endpoint: webhook.getEndpoint(),
      authentication: new WebhookAuthentication(
        this.auth(webhook.getAuthentication()?.getKindCase(), webhook)
      ),
      description: webhook.getDescription(),
      event_subscriptions: webhook.getEventSubscriptionsList().map((event) => {
        return new WebhookEventSubscription(
          event.getEventType(),
          event.getVersion(),
          event.getSeveritiesList()
        )
      }),
    })
  }
}
export default WebhookFactory
