"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listEc2InventoryPossibleFilters = void 0;
class listEc2InventoryPossibleFilters {
    #instances;
    constructor(parameters) {
        this.#instances = parameters.Ec2s;
    }
    execute() {
        const accountIds = new Set();
        const regions = new Set();
        this.#instances.forEach((Ec2Item) => {
            accountIds.add(Ec2Item.awsAccountId);
            regions.add(Ec2Item.awsRegion);
        });
        return {
            possibleEc2Filters: {
                accountIds: [...accountIds],
                regions: [...regions],
                covered: [true, false],
                backupsOnSchedule: [true, false],
                idSearch: '',
            },
        };
    }
}
exports.listEc2InventoryPossibleFilters = listEc2InventoryPossibleFilters;
