enum ReportDateRangeConstant {
  CustomDate = 0,
  Today = 1,
  Yesterday = 2,
  ThisWeek = 3,
  ThisMonth = 4,
  ThreeMonths = 5,
  ThisYear = 6,
  LastWeek = 7,
  LastMonth = 8,
  LastYear = 9,
}

export function getReportDateRangeName(value: ReportDateRangeConstant): string {
  const processedValue = ReportDateRangeConstant[value]?.match(/[A-Z][a-z]+/g)
  return processedValue ? processedValue.join(' ') : ''
}

export function getReportPeriods(list: Array<ReportDateRangeConstant>) {
  return list.map((period) => ({
    name: getReportDateRangeName(period),
    value: period,
  }))
}

export const REPORT_DEFAULT_POSSIBLE_PERIODS = getReportPeriods([
  ReportDateRangeConstant.CustomDate,
  ReportDateRangeConstant.Today,
  ReportDateRangeConstant.Yesterday,
  ReportDateRangeConstant.ThisWeek,
  ReportDateRangeConstant.ThisMonth,
  ReportDateRangeConstant.ThisYear,
  ReportDateRangeConstant.LastWeek,
  ReportDateRangeConstant.LastMonth,
  ReportDateRangeConstant.LastYear,
])

export const COMPLIANCE_REPORT_POSSIBLE_PERIODS = getReportPeriods([
  ReportDateRangeConstant.CustomDate,
  ReportDateRangeConstant.Today,
  ReportDateRangeConstant.Yesterday,
  ReportDateRangeConstant.ThisWeek,
  ReportDateRangeConstant.ThisMonth,
  ReportDateRangeConstant.ThreeMonths,
  ReportDateRangeConstant.LastWeek,
  ReportDateRangeConstant.LastMonth,
])

export const COMPLIANCE_SCHEDULED_REPORT_POSSIBLE_PERIODS = getReportPeriods([
  ReportDateRangeConstant.Today,
  ReportDateRangeConstant.Yesterday,
  ReportDateRangeConstant.ThisWeek,
  ReportDateRangeConstant.ThisMonth,
  ReportDateRangeConstant.ThreeMonths,
  ReportDateRangeConstant.LastWeek,
  ReportDateRangeConstant.LastMonth,
])

export default ReportDateRangeConstant
