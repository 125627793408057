enum LayoutConstant {
  Empty,
  Login,
  Main,
  Image,
  Error,
  Deployment,
  DashboardV4,
  MainTopBar,
}

export default LayoutConstant
