// index = 0 - simple block
// index = 1 - buttons block

export enum SnapshotVulnerabilitiesTypeName {
  UNENCRYPTED = 'Unencrypted',
  PUBLIC_PERMISSIONS = 'w. Public Permissions',
  OLDER_THAN_30_DAYS = 'Older than 30 days',
}

export enum DashboardColors {
  BLUE = 'blue500',
  RED = 'red600',
}

export enum EbsInventoryStatus {
  ATTACHED = 'Attached',
  UNATTACHED = 'Unattached',
}
