import { EngineCallback, EngineCallbackPure } from '@lib/engine-types'
import { cloneDeep, isEqual, union, merge } from 'lodash/fp'

abstract class ObjHelper {
  public static iterate(obj: any, callback: EngineCallback<any>) {
    if (ObjHelper.isNotObject(obj)) {
      return
    }
    Object.entries(obj).forEach(([key, value]) => callback(key, value))
  }

  public static assignIfEmpty(baseObj: any, newObj: any) {
    if (!ObjHelper.isObject(baseObj)) {
      return null
    }
    const result = { ...baseObj }
    if (!ObjHelper.isObject(newObj)) {
      return result
    }
    ObjHelper.iterate(newObj, (key: any, value: any) => {
      if (ObjHelper.isEmpty(result[key])) {
        result[key] = value
      }
    })
    return result
  }

  public static IsJsonString(str: any) {
    if (!str || typeof str !== 'string') {
      return false
    }
    try {
      const json: unknown = JSON.parse(str)
      if (json && typeof json === 'object') {
        return json
      }
    } catch (e) {
      return false
    }
  }

  public static isObject(obj: any) {
    if (obj === null) {
      return false
    }
    if (Array.isArray(obj)) {
      return false
    }
    return typeof obj === 'object'
  }

  public static isNotObject(obj: any) {
    return !ObjHelper.isObject(obj)
  }

  public static hasValues(obj: any) {
    if (ObjHelper.isNotObject(obj)) {
      return false
    }
    if (obj === undefined || obj === null) {
      return false
    }
    return Object.keys(obj).length > 0
  }

  public static hasNoValues(obj: any) {
    return !ObjHelper.hasValues(obj)
  }

  public static isEmpty(obj: any) {
    return obj === undefined || obj === null
  }

  public static isNotEmpty(obj: any) {
    return !ObjHelper.isEmpty(obj)
  }

  public static fromArrayToFilledObject<T>(
    arr: Array<string>,
    value: T
  ): Record<string, T> {
    const result: Record<string, T> = {}
    arr.forEach((v) => {
      result[v] = value
    })
    return result
  }

  public static cloneDeep<T>(obj: T): T {
    return cloneDeep(obj)
  }

  public static isEqual<T>(a: T, b: T): boolean {
    return isEqual(a, b)
  }

  public static isNotEqual<T>(a: T, b: T): boolean {
    return !ObjHelper.isEqual(a, b)
  }

  public static getNotEmptyKeyName(obj: any): string {
    if (!obj) {
      return ''
    }
    for (const [key, value] of Object.entries(obj)) {
      if (value) {
        return key
      }
    }
    return ''
  }

  public static isEmptyFunc(
    callback: EngineCallback<any> | EngineCallbackPure
  ): boolean {
    return (
      typeof callback === 'function' &&
      /^function[^{]*[{]\s*[}]\s*$/.test(
        Function.prototype.toString.call(callback)
      )
    )
  }

  public static isNotEmptyFunc(
    callback: EngineCallback<any> | EngineCallbackPure
  ): boolean {
    return !ObjHelper.isEmptyFunc(callback)
  }

  public static unionArrays<T>(arr1: Array<T>, arr2: Array<T>): Array<T> {
    return union(arr1, arr2)
  }

  public static mergeObjects<T>(obj1: T, obj2: T): T {
    return merge(obj1, obj2)
  }
}

export default ObjHelper
