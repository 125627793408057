"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reportFactory = exports.generateNumber = void 0;
const fishery_1 = require("fishery");
const reports_1 = require("../../models/reports");
const factories_1 = require("../../use_cases/tests/factories");
const common_1 = require("./common");
class ReportFactory extends fishery_1.Factory {
}
function generateNumber() {
    return Math.floor(Math.random() * 100); // Generates a random number between 0 and 99
}
exports.generateNumber = generateNumber;
const accountFactory = fishery_1.Factory.define(() => {
    const id = (0, factories_1.uuid)();
    return {
        id,
        accountName: (0, factories_1.name)('Account', generateNumber()),
    };
});
function generateEmail(sequence) {
    const emailNames = [
        'larry',
        'osrin',
        'mcast',
        'satishr',
        'magusnet',
        'mcsporran',
        'rmcfarla',
        'lvorbigun',
        'gusnet',
    ];
    const domain = 'acme.com';
    const name = emailNames[sequence % emailNames.length];
    return `${name}@${domain}`;
}
function randomFormat() {
    return Math.random() > 0.5 ? 'CSV' : 'PDF';
}
function randomKind() {
    return Math.random() > 0.5 ? 'ReportType1' : 'ReportType2';
}
// ReportType1
const reportFormatFactory = fishery_1.Factory.define(({ sequence }) => ({
    id: `format-${sequence}`,
    format: randomFormat(),
}));
const formats = Array.from({ length: 5 }, () => reportFormatFactory.build());
const emailList = Array.from({ length: 10 }, (_, index) => generateEmail(index));
exports.reportFactory = ReportFactory.define(({ transientParams, sequence }) => {
    return new reports_1.Report({
        id: transientParams.id ?? (0, factories_1.uuid)(),
        name: transientParams.name ?? (0, factories_1.name)('Report', sequence),
        description: transientParams.description ?? (0, factories_1.name)('Description', sequence),
        reportType: transientParams.reportType ?? randomKind(),
        frequency: transientParams.description ?? (0, factories_1.name)('Frequency', sequence),
        lastGenerated: transientParams.lastGenerated ?? (0, common_1.date)(),
        nextReport: transientParams.nextReport ?? (0, common_1.date)(),
        accounts: Array.from({ length: 5 }, () => accountFactory.build()),
        emailList: emailList,
        formats: formats,
    });
});
