import { ManagementClient, TenantName, CustomerProfile } from '@lib/clients'
import { useEffect, useState } from 'react'
import { setupGrpcClientTokenIfNeeded } from '@lib/helpers/setup-grpc-client-token'
import { GrpcClient } from '@lib/clients/grpc-client'

const managementClient = new ManagementClient()

export function useFetchTenantsAndCustomerProfile(): {
  tenants: Array<TenantName>
  customerProfile: CustomerProfile | undefined
  isLoading: boolean
  error: null | string
} {
  const [tenants, setTenants] = useState<Array<TenantName>>([])
  const [customerProfile, setCustomerProfile] = useState<
    CustomerProfile | undefined
  >()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<null | string>(null)

  useEffect(() => {
    let isMounted = true

    const fetchTenantsAndCustomerProfile = async () => {
      setError(null)

      await setupGrpcClientTokenIfNeeded(GrpcClient)

      if (!isMounted) {
        return
      }

      const [tenantsList, customer] = await Promise.all([
        managementClient.listTenants(),
        managementClient.getCustomerProfile(),
      ])

      if (isMounted) {
        setTenants(tenantsList)
        setCustomerProfile(customer)
        setIsLoading(false)
      }
    }

    fetchTenantsAndCustomerProfile()

    return () => {
      isMounted = false
      setIsLoading(true)
    }
  }, [])

  return {
    tenants,
    customerProfile,
    isLoading,
    error,
  }
}
