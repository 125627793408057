export class NullLogger {
    // eslint-disable-next-line no-unused-vars
    error(..._data: Array<any>): void {}

    // eslint-disable-next-line no-unused-vars
    warn(..._data: Array<any>): void {}

    // eslint-disable-next-line no-unused-vars
    info(..._data: Array<any>): void {}

    // eslint-disable-next-line no-unused-vars
    log(..._data: Array<any>): void {}
}
