import QuestionHint from '@components-simple/question-hint/QuestionHint'
import CalendarIcon from '@inline-img/inputs/calendar-icon'
import SelectArrowIcon from '@inline-img/inputs/select-arrow-icon'
import TimeFormatConstants from '@lib/constants/time-format.constant'
import { DateRangeTuple, EngineCallback, Nullable } from '@lib/engine-types'
import Adapter from '@mui/lab/AdapterMoment'
import DateRangePicker, {
  DateRange,
  DateRangePickerProps,
} from '@mui/lab/DateRangePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import clsx from 'clsx'
import moment from 'moment-timezone'
import React, {
  CSSProperties,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react'
import SettingsService from '@lib/services/high/settings.service'
import { DEFAULT_TIME_ZONE, UserSettings } from '@lib/constants'
import { TimeHelper } from '@lib/helpers'
import Box from '@mui/material/Box'

interface TimeAdapter<T extends moment.Moment = moment.Moment> {
  (value: Nullable<number> | moment.Moment): TimeAdapter<T>

  valueOf(): number
}

type TPickerProps = DateRangePickerProps<TimeAdapter>

export interface DateRangeSelectProps
  extends Omit<TPickerProps, 'value' | 'onChange' | 'renderInput' | 'date'> {
  value: DateRangeTuple
  label?: string
  endLabel?: string
  onChange: EngineCallback<DateRangeTuple>
  inputFormat?: TimeFormatConstants
  minMaxRange?: DateRangeTuple
  startText?: string
  endText?: string
  hint?: string
  disabled?: boolean
  labelStyle?: CSSProperties
}

const minMaxDate = [0, moment().valueOf()] as DateRangeTuple
export const BASE_DATE_FORMAT = 'DD / MM / YYYY'
const adapter = moment as unknown as TimeAdapter

function DateRangeSelect({
  value,
  label = '',
  endLabel = '',
  onChange,
  minMaxRange = minMaxDate,
  className = '',
  inputFormat = TimeFormatConstants.SHORT_DATE_FORMAT,
  startText = 'From',
  endText = 'To',
  hint = '',
  disabled,
  labelStyle = {},
  ...nativeProps
}: PropsWithChildren<DateRangeSelectProps>): JSX.Element {
  const timezone =
    SettingsService.getSetting(UserSettings.TimeZone)?.value ??
    DEFAULT_TIME_ZONE().value
  const adaptedValue = useMemo(() => {
    return [
      adapter(moment(value[0]).tz(timezone)),
      adapter(moment(value[1]).tz(timezone)),
    ] as TPickerProps['value']
  }, [value, timezone])

  const minMaxRangeWithOffset = useMemo(() => {
    const endDate = moment(minMaxRange[1])
      .tz(timezone, false)
      .format(BASE_DATE_FORMAT)

    return [
      adapter(moment(minMaxRange[0]).tz(timezone, false)),
      adapter(moment(endDate, BASE_DATE_FORMAT)),
    ]
  }, [minMaxRange])

  const [showError, setShowError] = useState<boolean>(false)
  const [dateOpen, setDateOpen] = useState(false)

  const renderInput: TPickerProps['renderInput'] = useCallback(
    (startProps, endProps) => (
      <Box className={clsx(className, 'wrap-1637118383413', 'pickerWrap')}>
        <Box>
          {label ? (
            <Box className="dataRangeSelectLabel" style={labelStyle}>
              {label}
              {hint && <QuestionHint text={hint} variant="inText" />}
            </Box>
          ) : null}
          <TextField
            {...startProps}
            onClick={() => (disabled ? setDateOpen(false) : setDateOpen(true))}
            className={clsx(startProps.className, 'pickerFrom')}
            InputLabelProps={{ shrink: false }}
            label={
              disabled
                ? // @ts-ignore
                  ((startProps?.inputProps?.disabled = disabled), '')
                : startProps.inputProps
                ? startProps.inputProps.value.toString().length > 0
                  ? ''
                  : startText
                : startText
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <SelectArrowIcon />
                </InputAdornment>
              ),
            }}
            helperText="Invalid date range"
            disabled={disabled}
          />
        </Box>

        <Box className="endLabelWrap">
          {endLabel ? (
            <Box className="dataRangeSelectLabel">{endLabel}</Box>
          ) : null}
          <TextField
            {...endProps}
            className={clsx(endProps.className, 'pickerTo')}
            InputLabelProps={{ shrink: false }}
            onClick={() => (disabled ? setDateOpen(false) : setDateOpen(true))}
            label={
              disabled
                ? // @ts-ignore
                  ((endProps?.inputProps?.disabled = disabled), '')
                : endProps.inputProps
                ? endProps.inputProps.value.toString().length > 0
                  ? ''
                  : endText
                : endText
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <SelectArrowIcon />
                </InputAdornment>
              ),
            }}
            disabled={disabled}
          />
        </Box>
      </Box>
    ),
    [className, disabled]
  )

  const onChangeInner = (newValue: DateRange<any>) => {
    // if we chose the only one - component sets up a current date but
    // sends "undefined"

    if (newValue[0] && !newValue[1]) {
      const startDate = newValue[0]

      const endDateTz = moment().tz(timezone, false).format(BASE_DATE_FORMAT)
      const endDate = moment(endDateTz, BASE_DATE_FORMAT)

      onChange([startDate, endDate])
      return
    }

    if (newValue[0] && newValue[1]) {
      const startDate = moment(newValue[0]).tz(timezone).startOf('day')

      const endDate = TimeHelper.convertToEndOfDayInTimezone(
        newValue[1],
        timezone
      )

      onChange([startDate, endDate])
      return
    }
  }

  // timeout to prevent opening of the second DateRangePicker
  return (
    <ClickAwayListener
      onClickAway={() => setTimeout(() => setDateOpen(false), 50)}
    >
      <Box
        className={clsx('pickerValidationWrap', {
          statusErrorClass: showError,
        })}
      >
        <LocalizationProvider dateAdapter={Adapter}>
          <DateRangePicker
            className={clsx(className, 'wrap-1637118383413', 'popperWrap')}
            calendars={1}
            open={dateOpen}
            onClose={() => setDateOpen(false)}
            onOpen={() => setDateOpen(true)}
            value={adaptedValue}
            onChange={onChangeInner}
            minDate={minMaxRangeWithOffset[0]}
            maxDate={minMaxRangeWithOffset[1]}
            renderInput={renderInput}
            inputFormat={inputFormat}
            disableMaskedInput
            startText={startText}
            endText={endText}
            onError={([startReason, endReason], [start, end]) => {
              if (!startReason && !endReason) {
                setShowError(false)
                return
              }
              if (start && end) {
                if (start > end) {
                  setShowError(true)
                  return
                }
              }
            }}
            disableHighlightToday
            {...nativeProps}
          />
        </LocalizationProvider>
      </Box>
    </ClickAwayListener>
  )
}

export default DateRangeSelect
