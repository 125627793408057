"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reportFactory = exports.ovaBackupFactory = exports.serverFactory = exports.ovaAccountFactory = exports.threatFactory = exports.genericHostFactory = exports.jobCloudConnectorFactory = exports.s3BucketFactory = exports.s3ItemFactory = exports.scanFilesystemCheckFactory = exports.streamFactory = exports.scanMalwareFactory = exports.scanRansomwareFactory = exports.jobTenantFactory = exports.fileFactory = exports.localVolumeFactory = exports.elastioRecoveryPointFactory = exports.efsItemFactory = exports.efsFactory = exports.ec2Factory = exports.ebsSnapshotFactory = exports.diskFactory = exports.awsbRecoveryPointFactory = exports.ebsFactory = void 0;
var ebs_1 = require("./ebs");
Object.defineProperty(exports, "ebsFactory", { enumerable: true, get: function () { return ebs_1.ebsFactory; } });
var awsb_recovery_point_1 = require("./awsb-recovery-point");
Object.defineProperty(exports, "awsbRecoveryPointFactory", { enumerable: true, get: function () { return awsb_recovery_point_1.awsbRecoveryPointFactory; } });
var disk_1 = require("./disk");
Object.defineProperty(exports, "diskFactory", { enumerable: true, get: function () { return disk_1.diskFactory; } });
var ebs_snapshot_1 = require("./ebs-snapshot");
Object.defineProperty(exports, "ebsSnapshotFactory", { enumerable: true, get: function () { return ebs_snapshot_1.ebsSnapshotFactory; } });
var ec2_1 = require("./ec2");
Object.defineProperty(exports, "ec2Factory", { enumerable: true, get: function () { return ec2_1.ec2Factory; } });
var efs_1 = require("./efs");
Object.defineProperty(exports, "efsFactory", { enumerable: true, get: function () { return efs_1.efsFactory; } });
var efs_item_1 = require("./efs-item");
Object.defineProperty(exports, "efsItemFactory", { enumerable: true, get: function () { return efs_item_1.efsItemFactory; } });
var elastio_recovery_point_1 = require("./elastio-recovery-point");
Object.defineProperty(exports, "elastioRecoveryPointFactory", { enumerable: true, get: function () { return elastio_recovery_point_1.elastioRecoveryPointFactory; } });
var local_volume_1 = require("./local-volume");
Object.defineProperty(exports, "localVolumeFactory", { enumerable: true, get: function () { return local_volume_1.localVolumeFactory; } });
var file_1 = require("./file");
Object.defineProperty(exports, "fileFactory", { enumerable: true, get: function () { return file_1.fileFactory; } });
var job_tenant_1 = require("./job-tenant");
Object.defineProperty(exports, "jobTenantFactory", { enumerable: true, get: function () { return job_tenant_1.jobTenantFactory; } });
var scan_ransomware_1 = require("./scan-ransomware");
Object.defineProperty(exports, "scanRansomwareFactory", { enumerable: true, get: function () { return scan_ransomware_1.scanRansomwareFactory; } });
var scan_malware_1 = require("./scan-malware");
Object.defineProperty(exports, "scanMalwareFactory", { enumerable: true, get: function () { return scan_malware_1.scanMalwareFactory; } });
var stream_1 = require("./stream");
Object.defineProperty(exports, "streamFactory", { enumerable: true, get: function () { return stream_1.streamFactory; } });
var scan_filesystem_check_1 = require("./scan-filesystem-check");
Object.defineProperty(exports, "scanFilesystemCheckFactory", { enumerable: true, get: function () { return scan_filesystem_check_1.scanFilesystemCheckFactory; } });
var s3_item_1 = require("./s3-item");
Object.defineProperty(exports, "s3ItemFactory", { enumerable: true, get: function () { return s3_item_1.s3ItemFactory; } });
var s3_bucket_1 = require("./s3-bucket");
Object.defineProperty(exports, "s3BucketFactory", { enumerable: true, get: function () { return s3_bucket_1.s3BucketFactory; } });
var job_cloud_connector_1 = require("./job-cloud-connector");
Object.defineProperty(exports, "jobCloudConnectorFactory", { enumerable: true, get: function () { return job_cloud_connector_1.jobCloudConnectorFactory; } });
var generic_host_1 = require("./generic-host");
Object.defineProperty(exports, "genericHostFactory", { enumerable: true, get: function () { return generic_host_1.genericHostFactory; } });
var threat_1 = require("./threat");
Object.defineProperty(exports, "threatFactory", { enumerable: true, get: function () { return threat_1.threatFactory; } });
var ova_account_1 = require("./ova-account");
Object.defineProperty(exports, "ovaAccountFactory", { enumerable: true, get: function () { return ova_account_1.ovaAccountFactory; } });
var ova_server_1 = require("./ova-server");
Object.defineProperty(exports, "serverFactory", { enumerable: true, get: function () { return ova_server_1.serverFactory; } });
var ova_backup_1 = require("./ova-backup");
Object.defineProperty(exports, "ovaBackupFactory", { enumerable: true, get: function () { return ova_backup_1.ovaBackupFactory; } });
var report_1 = require("./report");
Object.defineProperty(exports, "reportFactory", { enumerable: true, get: function () { return report_1.reportFactory; } });
