import { Nullable } from '@lib/engine-types'
import { TenantSettings } from '@lib/models'
import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'

interface TenantSettingsState {
  tenantSettingsModel: Nullable<TenantSettings>
  isTenantSettingsLoading: boolean
}

interface TenantSettingsActions {
  setTenantSettingsModel: (
    tenantSettingsModel: Nullable<TenantSettings>
  ) => void
  setIsTenantSettingsLoading: (isLoading: boolean) => void
}

type TenantSettingsStore = TenantSettingsState & {
  actions: TenantSettingsActions
}

const useTenantSettingsStore = create<TenantSettingsStore>((set) => ({
  isTenantSettingsLoading: false,
  tenantSettingsModel: null,
  actions: {
    setTenantSettingsModel: (tenantSettingsModel) => {
      set({
        tenantSettingsModel,
      })
    },
    setIsTenantSettingsLoading: (isLoading) => {
      set({
        isTenantSettingsLoading: isLoading,
      })
    },
  },
}))

const useGetTenantSettings = () =>
  useTenantSettingsStore(useShallow((state) => state.tenantSettingsModel))

const useIsTenantSettingsLoading = () =>
  useTenantSettingsStore((state) => state.isTenantSettingsLoading)

const useGetTenantSettingsStoreActions = () =>
  useTenantSettingsStore(useShallow((state) => state.actions))

export {
  useGetTenantSettings,
  useIsTenantSettingsLoading,
  useGetTenantSettingsStoreActions,
}
