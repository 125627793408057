/* eslint-disable import/no-extraneous-dependencies */
import TimeHelper from '@lib/helpers/time.helper'
import { Volume as VolumeProto } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/asset_items/volume_pb'
import { LocalVolume } from 'blues-corejs/dist'

type VolumePt = VolumeProto.AsObject

export class VolumeTransformer {
  #volume: VolumePt

  constructor(volume: VolumePt) {
    this.#volume = volume
  }

  transform(): LocalVolume {
    return this.#transformVolume(this.#volume)
  }

  #transformVolume(volume: VolumePt): LocalVolume {
    return new LocalVolume({
      id: volume.id,
      deviceName: volume.deviceName,
      assetId: volume.assetId,
      ccAssetId: volume.ccAssetId,
      createdAt: TimeHelper.secondsToDate(volume.createdAt?.seconds),
      updatedAt: TimeHelper.secondsToDate(volume.updatedAt?.seconds),
    })
  }
}
