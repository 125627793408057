import { GrpcClient } from '@lib/clients/grpc-client'
import { JobsPromiseClient, JobTenant } from '@lib/clients/grpc-imports'
import {
  ScheduledTenantJob,
  ScheduledTenantJobAttrs,
  ScheduledTenantJobStatus,
  ScheduledTenantJobKind,
} from '@features/scheduled-jobs-monitoring/models'
import { ScheduledCcJobsListParams } from '@features/scheduled-jobs-monitoring/use-cases'
import {
  ScheduledTenantJobsFilter,
  ScheduledTenantJobFilter,
} from './scheduled-tenant-jobs-filter'
import TimeHelper from '@lib/helpers/time.helper'
import { GrpcCodes } from '@lib/constants/data/error/api-errors.constant'

// DANGER: check errorShouldNotBeRetried method override

export class ScheduledTenantJobsClient extends GrpcClient<JobsPromiseClient> {
  #jobsPromiseClient: JobsPromiseClient

  constructor(hostName = '') {
    super()
    this.#jobsPromiseClient = this.getClient(hostName)
  }

  async get(jobId: string): Promise<ScheduledTenantJob> {
    const request = new ScheduledTenantJobFilter(jobId)

    const response = await this.retryGrpcCall(
      () =>
        this.#jobsPromiseClient.getTenantJob(
          request.createGrpcRequest(),
          this.metadata()
        ),
      {
        requestName: 'JobsPromiseClient/getTenantJob',
      }
    )

    const job = response.getJob()
    if (!job) {
      throw new Error('Scheduled tenant job not found')
    }

    return this.#transform(job)
  }

  async list(
    params: ScheduledCcJobsListParams
  ): Promise<Array<ScheduledTenantJob>> {
    const request = new ScheduledTenantJobsFilter({
      jobIdsList: params.jobIds,
      timeRange: {
        start: Number(params.start),
        end: Number(params.end),
      },
    })

    const response = await this.retryGrpcCall(
      () =>
        this.#jobsPromiseClient.listTenantJobs(
          request.createGrpcRequest(),
          this.metadata()
        ),
      {
        requestName: 'JobsPromiseClient/listTenantJobs',
      }
    )

    const jobsList = response.getJobsList()
    return jobsList.map((job) => this.#transform(job))
  }

  protected innerClientTypeId(): string {
    return 'scheduleTenantJobsClient'
  }

  protected initClient(hostName: string): JobsPromiseClient {
    return new JobsPromiseClient(hostName, null, null)
  }

  protected errorShouldNotBeRetried(grpcErrorCode: GrpcCodes): boolean {
    //
    // DANGER: please keep this in mind if you are going to implement other methods.
    //
    // This is required because bluestack jobs could have some lag between creation
    // and registration in bluestack service

    if (grpcErrorCode === GrpcCodes.NOT_FOUND) {
      return false
    }

    return super.errorShouldNotBeRetried(grpcErrorCode)
  }

  #transform(job: JobTenant): ScheduledTenantJob {
    const attrs: ScheduledTenantJobAttrs = {
      id: job.getId(),
      createdAt: TimeHelper.secondsToDate(job.getCreatedAt()?.getSeconds()),
      kind: this.#convertKind(job.getKind()),
      status: this.#convertStatus(job.getStatus()),
      ccId: job.getCcId(),
    }

    return new ScheduledTenantJob(attrs)
  }

  #convertStatus(status: JobTenant.Status): ScheduledTenantJobStatus {
    const mapping: Record<JobTenant.Status, ScheduledTenantJobStatus> = {
      [JobTenant.Status.STATUS_SCHEDULED]: ScheduledTenantJobStatus.SCHEDULED,
      [JobTenant.Status.STATUS_STARTING]: ScheduledTenantJobStatus.STARTING,
      [JobTenant.Status.STATUS_INPROGRESS]: ScheduledTenantJobStatus.INPROGRESS,
      [JobTenant.Status.STATUS_FAILED]: ScheduledTenantJobStatus.FAILED,
      [JobTenant.Status.STATUS_SUCCEEDED]: ScheduledTenantJobStatus.SUCCEEDED,
      [JobTenant.Status.STATUS_ABORTED]: ScheduledTenantJobStatus.ABORTED,
    }

    return mapping[status]
  }

  #convertKind(kind: JobTenant.Kind): ScheduledTenantJobKind {
    const mapping: Partial<Record<JobTenant.Kind, ScheduledTenantJobKind>> = {
      [JobTenant.Kind.KIND_REPAIR_VAULT]: ScheduledTenantJobKind.REPAIR_VAULT,
      [JobTenant.Kind.KIND_CREATE_VAULT]: ScheduledTenantJobKind.CREATE_VAULT,
      [JobTenant.Kind.KIND_INSTALL_CLOUD_CONNECTOR]:
        ScheduledTenantJobKind.INSTALL_CLOUD_CONNECTOR,
      [JobTenant.Kind.KIND_REPAIR_CLOUD_CONNECTOR]:
        ScheduledTenantJobKind.REPAIR_CLOUD_CONNECTOR,
      [JobTenant.Kind.KIND_ACTIVATE_CLOUD_CONNECTOR]:
        ScheduledTenantJobKind.ACTIVATE_CLOUD_CONNECTOR,
      [JobTenant.Kind.KIND_ENABLE_ELASTIO_REPORTS_GATHERING]:
        ScheduledTenantJobKind.ENABLE_ELASTIO_REPORTS_GATHERING,
      [JobTenant.Kind.KIND_UPDATE_VAULT_SUBNETS]:
        ScheduledTenantJobKind.UPDATE_VAULT_SUBNETS,
    }

    return mapping[kind] ?? ScheduledTenantJobKind.UNKNOWN
  }
}
