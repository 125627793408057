import React, { ForwardedRef, useMemo } from 'react'
import { useReactTable } from '@tanstack/react-table'

import {
  BodyTable,
  DefaultNoDataComponent,
  HeaderGroupsTable,
  LoadingObservableTableRow,
} from './core'
import { TableOwnProps } from './types'
import { useTableConfig } from './config'
import {
  RootTableWithData,
  StyledTableContainer,
  MUITable,
} from './shared/styles'
import { NO_DATA_MESSAGE } from './shared/consts'
import { SkeletonPreloader } from './core/skeleton-preloader'

function Table<T>(props: TableOwnProps<T>) {
  const { generalProps, advancedProps, columns, data, className, handlers } =
    props
  const {
    isLoading,
    isShowHeader = true,
    noDataMessage = NO_DATA_MESSAGE,
    isDisabled = false,
  } = generalProps

  const initialTableState = advancedProps?.initialTableState || {}
  const renderSubComponent = advancedProps?.renderSubComponent

  const warningState = advancedProps?.warningState

  const observableState = advancedProps?.observableState

  const tableConfig = useMemo(
    () => ({
      data,
      columns,
      initialTableState,
      expandableRowKey: advancedProps?.expandableRowKey,
      ...handlers,
    }),
    [
      data,
      columns,
      initialTableState,
      advancedProps?.expandableRowKey,
      handlers,
    ]
  )

  const tableInstance = useReactTable(useTableConfig(tableConfig))

  const SkeletonLoader = useMemo(() => {
    return <SkeletonPreloader />
  }, [])

  const isShowTable = useMemo(() => {
    return (
      data.length > 0 ||
      (!observableState?.isInitialFetched &&
        observableState?.isInitialFetched !== undefined)
    )
  }, [data.length, observableState])

  // (isLoading && !observableState?.isInitialFetched) || (isLoading && !data.length)
  //TODO create callback
  if (
    (isLoading && !observableState?.isInitialFetched) ||
    (isLoading && !data.length)
  ) {
    return <StyledTableContainer>{SkeletonLoader}</StyledTableContainer>
  }

  const ActualNoDataComponent = <DefaultNoDataComponent text={noDataMessage} />

  return isShowTable ? (
    <RootTableWithData className={className} isDisabled={isDisabled}>
      <StyledTableContainer>
        <MUITable>
          {isShowHeader && (
            <HeaderGroupsTable headerGroups={tableInstance.getHeaderGroups()} />
          )}
          <BodyTable
            key={tableInstance.getRowModel().rows.length}
            rows={tableInstance.getRowModel().rows}
            renderSubComponent={renderSubComponent}
            warningState={warningState}
          >
            {observableState && (
              <LoadingObservableTableRow
                colSpanForCell={columns.length}
                ref={
                  observableState.observerElement as ForwardedRef<HTMLTableRowElement>
                }
                isLoading={isLoading}
              />
            )}
          </BodyTable>
        </MUITable>
      </StyledTableContainer>
    </RootTableWithData>
  ) : (
    ActualNoDataComponent
  )
}

export default Table
