import Modal from '@lib/constants/modal.constant'

export const SHOW_MODAL = 'ENABLE_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const SHOW_ACCESS_DENIED_MODAL = 'SHOW_ACCESS_DENIED_MODAL'

export const showModal = (payload: Modal) => ({
  type: SHOW_MODAL,
  payload,
})

export const hideModal = (payload: Modal) => ({
  type: HIDE_MODAL,
  payload,
})

export const showAccessDeniedModal = (message: string) => ({
  type: SHOW_ACCESS_DENIED_MODAL,
  payload: message,
})
