"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListAssetItemsGenericWithThreats = void 0;
const types_1 = require("./types");
const types_2 = require("../../models/scans/malware/types");
const types_3 = require("../../models/threats/types");
const types_4 = require("../../models/scans/ransomware/types");
const types_5 = require("../../models/scans/filesystem-check/types");
class ListAssetItemsGenericWithThreats {
    #assetItems;
    #threats;
    #assetItemsMap = {};
    constructor({ assetItems, threats, }) {
        this.#assetItems = assetItems;
        this.#threats = threats;
    }
    execute(filters) {
        return this.#prepareOutput(filters);
    }
    #prepareOutput(filters) {
        this.#processAssetItemsList();
        const prepareTableData = Object.values(this.#assetItemsMap);
        return this.#findSearchMatchingFilters(prepareTableData, filters);
    }
    #processAssetItemsList() {
        for (const assetItemFile of this.#assetItems.filesList) {
            this.#assetItemsMap[assetItemFile.id] = {
                threatsCount: 0,
                risks: {
                    malwareStatus: types_2.MalwareScanStatus.CLEAN,
                    ransomwareStatus: types_4.RansomwareScanStatus.CLEAN,
                    fsCheckStatus: types_5.StatusScanFilesystemCheck.GOOD,
                },
                name: assetItemFile.path,
                id: assetItemFile.id,
                kind: types_1.AssetItemType.FILE,
                assetItem: assetItemFile,
            };
        }
        for (const assetItemDisk of this.#assetItems.disksList) {
            this.#assetItemsMap[assetItemDisk.id] = {
                threatsCount: 0,
                risks: {
                    malwareStatus: types_2.MalwareScanStatus.CLEAN,
                    ransomwareStatus: types_4.RansomwareScanStatus.CLEAN,
                    fsCheckStatus: types_5.StatusScanFilesystemCheck.GOOD,
                },
                name: assetItemDisk.deviceName,
                id: assetItemDisk.id,
                kind: types_1.AssetItemType.DISK,
                assetItem: assetItemDisk,
            };
        }
        for (const assetItemStream of this.#assetItems.streamsList) {
            this.#assetItemsMap[assetItemStream.id] = {
                threatsCount: 0,
                risks: {
                    malwareStatus: types_2.MalwareScanStatus.CLEAN,
                    ransomwareStatus: types_4.RansomwareScanStatus.CLEAN,
                    fsCheckStatus: types_5.StatusScanFilesystemCheck.GOOD,
                },
                name: assetItemStream.name,
                id: assetItemStream.id,
                kind: types_1.AssetItemType.STREAM,
                assetItem: assetItemStream,
            };
        }
        for (const assetItemLocalVolume of this.#assetItems.volumesList) {
            this.#assetItemsMap[assetItemLocalVolume.id] = {
                threatsCount: 0,
                risks: {
                    malwareStatus: types_2.MalwareScanStatus.CLEAN,
                    ransomwareStatus: types_4.RansomwareScanStatus.CLEAN,
                    fsCheckStatus: types_5.StatusScanFilesystemCheck.GOOD,
                },
                name: assetItemLocalVolume.deviceName,
                id: assetItemLocalVolume.id,
                kind: types_1.AssetItemType.LOCAL_VOLUME,
                assetItem: assetItemLocalVolume,
            };
        }
        this.#updateAssetItemsWithThreats(this.#assetItems.filesList);
        this.#updateAssetItemsWithThreats(this.#assetItems.disksList);
        this.#updateAssetItemsWithThreats(this.#assetItems.streamsList);
        this.#updateAssetItemsWithThreats(this.#assetItems.volumesList);
    }
    #updateAssetItemsWithThreats(assetItemsList) {
        const assetItemsMap = this.#assetItemsMap;
        for (const threat of this.#threats) {
            const threatsSource = threat.source;
            const assetItemId = threatsSource.assetItem?.assetItemId;
            if (assetItemId) {
                const assetItem = assetItemsList.find((item) => item.id === assetItemId);
                if (!assetItem) {
                    continue;
                }
                assetItemsMap[assetItem.id].threatsCount += 1;
                if (threat.kind === types_3.ThreatKind.MALWARE) {
                    assetItemsMap[assetItem.id].risks.malwareStatus =
                        types_2.MalwareScanStatus.INFECTED;
                }
                if (threat.kind === types_3.ThreatKind.RANSOMWARE) {
                    assetItemsMap[assetItem.id].risks.ransomwareStatus =
                        types_4.RansomwareScanStatus.INFECTED;
                }
                if (threat.kind === types_3.ThreatKind.FILESYSTEM_ERROR) {
                    assetItemsMap[assetItem.id].risks.fsCheckStatus =
                        types_5.StatusScanFilesystemCheck.BAD;
                }
            }
        }
        return assetItemsMap;
    }
    #findSearchMatchingFilters(assetItemsOutput, filters) {
        return assetItemsOutput.filter((item) => {
            if (!filters.search) {
                return true;
            }
            return item.name.toLowerCase().includes(filters.search.toLowerCase());
        });
    }
}
exports.ListAssetItemsGenericWithThreats = ListAssetItemsGenericWithThreats;
