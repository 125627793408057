"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assetThreatStatusesFactory = void 0;
const fishery_1 = require("fishery");
const models_1 = require("../../../models");
const common_1 = require("./common");
class AssetThreatStatusFactory extends fishery_1.Factory {
    notScannedBackup() {
        return this.transient({
            recoveryPointBytesUploaded: Math.floor(Math.random() * 1000000),
            recoveryPointBytesIncluded: Math.floor(Math.random() * 1000000),
            recoveryPointTotalBytesIngested: Math.floor(Math.random() * 1000000),
            recoveryPointCreatedAt: Date.now(),
            recoveryPointId: (0, common_1.uuid)(),
            recoveryPointVault: (0, common_1.name)('vault', 1),
            tenantRecoveryPointId: (0, common_1.uuid)(),
        });
    }
    healthy() {
        // healthy assets have the same rp as last healthy and scanned
        const rpId = (0, common_1.uuid)();
        const rpVault = (0, common_1.name)('vault', 1);
        const rpCreatedAt = Date.now();
        const rpBytesUploaded = Math.floor(Math.random() * 1000000);
        const rpBytesIncluded = Math.floor(Math.random() * 1000000);
        const rpTotalBytesIngested = Math.floor(Math.random() * 1000000);
        const rpTenantId = (0, common_1.uuid)();
        return this.transient({
            malwareList: [],
            ransomwareList: [],
            recoveryPointBytesUploaded: rpBytesUploaded,
            recoveryPointBytesIncluded: rpBytesIncluded,
            recoveryPointTotalBytesIngested: rpTotalBytesIngested,
            scannedRecoveryPointBytesUploaded: rpBytesUploaded,
            scannedRecoveryPointBytesIncluded: rpBytesIncluded,
            scannedRecoveryPointTotalBytesIngested: rpTotalBytesIngested,
            scannedRecoveryPointCreatedAt: rpCreatedAt,
            scannedRecoveryPointId: rpId,
            scannedRecoveryPointVault: rpVault,
            scannedTenantRecoveryPointId: rpId,
            recoveryPointCreatedAt: rpCreatedAt,
            recoveryPointId: rpId,
            recoveryPointVault: rpVault,
            tenantRecoveryPointId: rpTenantId,
            fsCheckStatus: models_1.FsCheckStatus.SUCCEEDED,
            volumeFsCheckReport: {
                id: (0, common_1.uuid)(),
                partitionName: 'C:',
                status: models_1.FsCheckStatus.SUCCEEDED,
                volumeName: '',
                partitionsCount: 1,
                healthyPartitionsCount: 1,
            },
            fsCheckedRecoveryPointBytesIncluded: rpBytesIncluded,
            fsCheckedRecoveryPointBytesUploaded: rpBytesUploaded,
            fsCheckedRecoveryPointTotalBytesIngested: rpTotalBytesIngested,
            fsCheckedRecoveryPointCreatedAt: rpCreatedAt,
            fsCheckedRecoveryPointId: rpId,
            fsCheckedRecoveryPointVault: rpVault,
            healthyRecoveryPointBytesIncluded: rpBytesIncluded,
            healthyRecoveryPointBytesUploaded: rpBytesUploaded,
            healthyRecoveryPointTotalBytesIngested: rpTotalBytesIngested,
            healthyRecoveryPointCreatedAt: rpCreatedAt,
            healthyRecoveryPointId: rpId,
            healthyRecoveryPointVault: rpVault,
            healthyTenantRecoveryPointId: rpTenantId,
            healthyFsCheckTimestamp: rpCreatedAt,
            healthyMalwareScanTimestamp: rpCreatedAt,
            healthyRansomwareScanTimestamp: rpCreatedAt,
        });
    }
    unhealthy() {
        // unhealthy assets have different rp for last healthy and scanned
        const healthyRpId = (0, common_1.uuid)();
        const healthyRpVault = (0, common_1.name)('vault', 1);
        const healthyRpCreatedAt = Date.now() - 3600 * 1000;
        const healthyRpBytesUploaded = Math.floor(Math.random() * 1000000);
        const healthyRpBytesIncluded = Math.floor(Math.random() * 1000000);
        const healthyRpTotalBytesIngested = Math.floor(Math.random() * 1000000);
        const healthyRpNewBytesIngested = Math.floor(Math.random() * 1000000);
        const healthyRpTenantId = (0, common_1.uuid)();
        const scannedRpId = (0, common_1.uuid)();
        const scannedRpVault = (0, common_1.name)('vault', 1);
        const scannedRpCreatedAt = Date.now();
        const scannedRpBytesUploaded = Math.floor(Math.random() * 1000000);
        const scannedRpBytesIncluded = Math.floor(Math.random() * 1000000);
        const scannedRpTotalBytesIngested = Math.floor(Math.random() * 1000000);
        const scannedRpNewBytesIngested = Math.floor(Math.random() * 1000000);
        const scannedRpTenantId = (0, common_1.uuid)();
        return this.transient({
            recoveryPointBytesUploaded: scannedRpBytesUploaded,
            recoveryPointBytesIncluded: scannedRpBytesIncluded,
            recoveryPointTotalBytesIngested: scannedRpTotalBytesIngested,
            scannedRecoveryPointBytesUploaded: scannedRpBytesUploaded,
            scannedRecoveryPointBytesIncluded: scannedRpBytesIncluded,
            scannedRecoveryPointTotalBytesIngested: scannedRpTotalBytesIngested,
            scannedRecoveryPointCreatedAt: scannedRpCreatedAt,
            scannedRecoveryPointId: scannedRpId,
            scannedRecoveryPointVault: scannedRpVault,
            scannedTenantRecoveryPointId: scannedRpId,
            recoveryPointCreatedAt: scannedRpCreatedAt,
            recoveryPointId: scannedRpId,
            recoveryPointVault: scannedRpVault,
            tenantRecoveryPointId: scannedRpTenantId,
            fsCheckedRecoveryPointBytesIncluded: scannedRpBytesIncluded,
            fsCheckedRecoveryPointBytesUploaded: scannedRpBytesUploaded,
            fsCheckedRecoveryPointTotalBytesIngested: scannedRpTotalBytesIngested,
            fsCheckedRecoveryPointCreatedAt: scannedRpCreatedAt,
            fsCheckedRecoveryPointId: scannedRpId,
            fsCheckedRecoveryPointVault: scannedRpVault,
            healthyRecoveryPointBytesIncluded: healthyRpBytesIncluded,
            healthyRecoveryPointBytesUploaded: healthyRpBytesUploaded,
            healthyRecoveryPointTotalBytesIngested: healthyRpTotalBytesIngested,
            healthyRecoveryPointCreatedAt: healthyRpCreatedAt,
            healthyRecoveryPointId: healthyRpId,
            healthyRecoveryPointVault: healthyRpVault,
            healthyTenantRecoveryPointId: healthyRpTenantId,
            healthyFsCheckTimestamp: healthyRpCreatedAt,
            healthyMalwareScanTimestamp: healthyRpCreatedAt,
            healthyRansomwareScanTimestamp: healthyRpCreatedAt,
            healthyRecoveryPointNewBytesIngested: healthyRpNewBytesIngested,
            recoveryPointNewBytesIngested: scannedRpNewBytesIngested,
            scannedRecoveryPointNewBytesIngested: scannedRpNewBytesIngested,
            fsCheckedRecoveryPointNewBytesIngested: scannedRpNewBytesIngested,
            volumeFsCheckReport: {
                id: (0, common_1.uuid)(),
                partitionName: 'C:',
                status: models_1.FsCheckStatus.SUCCEEDED,
                volumeName: '',
                partitionsCount: 1,
                healthyPartitionsCount: 1,
            },
        });
    }
    volumeWithMalware() {
        return this.transient({
            malwareList: ['malware'],
            lastMalwareDetectedAt: Date.now(),
            firstMalwareDetectedAt: Date.now(),
            threatScanReportId: (0, common_1.uuid)(),
        });
    }
    instanceWithMalware() {
        const threatScanReportsByVolumeIdMap = new Map();
        threatScanReportsByVolumeIdMap.set((0, common_1.uuid)(), {
            id: (0, common_1.uuid)(),
            malwareList: ['malware-1'],
            ransomwareList: [],
            malwareScannedFilesCount: 1000000,
            malwareInfectedFilesCount: 1000000,
            ransomwareScannedFilesCount: 1000000,
            ransomwareInfectedFilesCount: 1000000,
        });
        threatScanReportsByVolumeIdMap.set((0, common_1.uuid)(), {
            id: (0, common_1.uuid)(),
            malwareList: ['malware-2'],
            ransomwareList: [],
            malwareScannedFilesCount: 1000000,
            malwareInfectedFilesCount: 1000000,
            ransomwareScannedFilesCount: 1000000,
            ransomwareInfectedFilesCount: 1000000,
        });
        return this.transient({
            malwareList: ['malware-1', 'malware-2'],
            lastMalwareDetectedAt: Date.now(),
            firstMalwareDetectedAt: Date.now(),
            threatScanReportsByVolumeIdMap,
        });
    }
    volumeWithRansomware() {
        return this.transient({
            ransomwareList: ['ransomware'],
            lastRansomwareDetectedAt: Date.now(),
            firstRansomwareDetectedAt: Date.now(),
            threatScanReportId: (0, common_1.uuid)(),
        });
    }
    instanceWithRansomware() {
        const threatScanReportsByVolumeIdMap = new Map();
        threatScanReportsByVolumeIdMap.set((0, common_1.awsVolumeId)(), {
            id: (0, common_1.uuid)(),
            ransomwareList: ['ransomware-1'],
            malwareList: [],
            malwareScannedFilesCount: 1000000,
            malwareInfectedFilesCount: 1000000,
            ransomwareScannedFilesCount: 1000000,
            ransomwareInfectedFilesCount: 1000000,
        });
        threatScanReportsByVolumeIdMap.set((0, common_1.awsVolumeId)(), {
            id: (0, common_1.uuid)(),
            ransomwareList: ['ransomware-2'],
            malwareList: [],
            malwareScannedFilesCount: 1000000,
            malwareInfectedFilesCount: 1000000,
            ransomwareScannedFilesCount: 1000000,
            ransomwareInfectedFilesCount: 1000000,
        });
        return this.transient({
            ransomwareList: ['ransomware-1', 'ransomware-2'],
            lastRansomwareDetectedAt: Date.now(),
            firstRansomwareDetectedAt: Date.now(),
            threatScanReportsByVolumeIdMap,
        });
    }
    volumeWithCorruptedFileSystem() {
        return this.transient({
            fsCheckStatus: models_1.FsCheckStatus.FAILED,
            volumeFsCheckReport: {
                id: (0, common_1.uuid)(),
                partitionName: 'C:',
                status: models_1.FsCheckStatus.FAILED,
                volumeName: '',
                partitionsCount: 1,
                healthyPartitionsCount: 0,
            },
        });
    }
    instanceWithCorruptedFileSystem() {
        this.sequence();
        const fsCheckReportsByVolumeIdMap = new Map();
        const vol1Id1 = (0, common_1.awsVolumeId)();
        const vol1Id2 = (0, common_1.awsVolumeId)();
        fsCheckReportsByVolumeIdMap.set(vol1Id1, {
            id: (0, common_1.uuid)(),
            partitionName: 'C:',
            status: models_1.FsCheckStatus.FAILED,
            volumeName: vol1Id1,
            partitionsCount: 1,
            healthyPartitionsCount: 1,
        });
        fsCheckReportsByVolumeIdMap.set(vol1Id2, {
            id: (0, common_1.uuid)(),
            partitionName: 'D:',
            status: models_1.FsCheckStatus.FAILED,
            volumeName: vol1Id2,
            partitionsCount: 1,
            healthyPartitionsCount: 1,
        });
        return this.transient({
            fsCheckStatus: models_1.FsCheckStatus.FAILED,
            instanceFsCheckReports: { fsCheckReportsByVolumeIdMap },
        });
    }
}
const assetThreatStatusesFactory = AssetThreatStatusFactory.define(({ transientParams }) => {
    const status = {
        threatScanReportCreatedAt: transientParams?.threatScanReportCreatedAt ?? null,
        firstMalwareDetectedAt: transientParams.firstMalwareDetectedAt ?? null,
        firstRansomwareDetectedAt: transientParams.firstRansomwareDetectedAt ?? null,
        fsCheckStatus: transientParams.fsCheckStatus ?? models_1.FsCheckStatus.NONE,
        fsCheckedRecoveryPointBytesIncluded: transientParams.fsCheckedRecoveryPointBytesIncluded ?? 0,
        fsCheckedRecoveryPointBytesUploaded: transientParams.fsCheckedRecoveryPointBytesUploaded ?? 0,
        fsCheckedRecoveryPointCreatedAt: transientParams.fsCheckedRecoveryPointCreatedAt ?? null,
        fsCheckedRecoveryPointId: transientParams.fsCheckedRecoveryPointId ?? null,
        fsCheckedRecoveryPointInstanceId: transientParams.fsCheckedRecoveryPointInstanceId ?? '',
        fsCheckedRecoveryPointTotalBytesIngested: transientParams.fsCheckedRecoveryPointTotalBytesIngested ?? 0,
        fsCheckedRecoveryPointVault: transientParams.fsCheckedRecoveryPointVault ?? '',
        fsCheckedTenantRecoveryPointId: transientParams.fsCheckedTenantRecoveryPointId ?? '',
        healthyFsCheckTimestamp: transientParams.healthyFsCheckTimestamp ?? null,
        healthyMalwareScanTimestamp: transientParams.healthyMalwareScanTimestamp ?? null,
        healthyRansomwareScanTimestamp: transientParams.healthyRansomwareScanTimestamp ?? null,
        healthyRecoveryPointBytesIncluded: transientParams.healthyRecoveryPointBytesIncluded ?? 0,
        healthyRecoveryPointBytesUploaded: transientParams.healthyRecoveryPointBytesUploaded ?? 0,
        healthyRecoveryPointCreatedAt: transientParams.healthyRecoveryPointCreatedAt ?? null,
        healthyRecoveryPointId: transientParams.healthyRecoveryPointId ?? '',
        healthyRecoveryPointInstanceId: transientParams.healthyRecoveryPointInstanceId ?? '',
        healthyRecoveryPointTotalBytesIngested: transientParams.healthyRecoveryPointTotalBytesIngested ?? 0,
        healthyRecoveryPointVault: transientParams.healthyRecoveryPointVault ?? '',
        healthyTenantRecoveryPointId: transientParams.healthyTenantRecoveryPointId ?? '',
        instanceFsCheckReports: transientParams.instanceFsCheckReports ?? null,
        lastMalwareDetectedAt: transientParams.lastMalwareDetectedAt ?? null,
        lastRansomwareDetectedAt: transientParams.lastRansomwareDetectedAt ?? null,
        malwareList: transientParams.malwareList ?? [],
        ransomwareList: transientParams.ransomwareList ?? [],
        recoveryPointBytesIncluded: transientParams.recoveryPointBytesIncluded ?? 0,
        recoveryPointBytesUploaded: transientParams.recoveryPointBytesUploaded ?? 0,
        recoveryPointCreatedAt: transientParams.recoveryPointCreatedAt ?? null,
        recoveryPointId: transientParams.recoveryPointId ?? '',
        recoveryPointInstanceId: transientParams.recoveryPointInstanceId ?? '',
        recoveryPointTotalBytesIngested: transientParams.recoveryPointTotalBytesIngested ?? 0,
        recoveryPointVault: transientParams.recoveryPointVault ?? '',
        scannedRecoveryPointBytesIncluded: transientParams.scannedRecoveryPointBytesIncluded ?? 0,
        scannedRecoveryPointBytesUploaded: transientParams.scannedRecoveryPointBytesUploaded ?? 0,
        scannedRecoveryPointCreatedAt: transientParams.scannedRecoveryPointCreatedAt ?? null,
        scannedRecoveryPointId: transientParams.scannedRecoveryPointId ?? '',
        scannedRecoveryPointInstanceId: transientParams.scannedRecoveryPointInstanceId ?? '',
        scannedRecoveryPointTotalBytesIngested: transientParams.scannedRecoveryPointTotalBytesIngested ?? 0,
        scannedRecoveryPointVault: transientParams.scannedRecoveryPointVault ?? '',
        scannedTenantRecoveryPointId: transientParams.scannedTenantRecoveryPointId ?? '',
        tenantRecoveryPointId: transientParams.tenantRecoveryPointId ?? '',
        threatScanReportId: transientParams.threatScanReportId ?? '',
        threatScanReportsByVolumeIdMap: transientParams.threatScanReportsByVolumeIdMap ?? null,
        volumeFsCheckReport: transientParams.volumeFsCheckReport ?? null,
        recoveryPointNewBytesIngested: transientParams.recoveryPointNewBytesIngested ?? 0,
        scannedRecoveryPointNewBytesIngested: transientParams.scannedRecoveryPointNewBytesIngested ?? 0,
        fsCheckedRecoveryPointNewBytesIngested: transientParams.fsCheckedRecoveryPointNewBytesIngested ?? 0,
        healthyRecoveryPointNewBytesIngested: transientParams.healthyRecoveryPointNewBytesIngested ?? 0,
    };
    return status;
});
exports.assetThreatStatusesFactory = assetThreatStatusesFactory;
