// the main idea - to do pure redirects, avoiding
// all code after this function if the error is happened
import ServerErrorConstant from '@lib/constants/data/error/server-error.constant'
import { PagePathConstant } from '@lib/constants'
import { SystemHelper } from '@lib/helpers'
import { getEngineInitData } from './getEngineInitData'

export async function handleError(code: number) {
  switch (code) {
    case ServerErrorConstant.ERROR_LOGIN.code:
    case ServerErrorConstant.ERROR_AUTH0_SESSION.code:
      SystemHelper.pureNavigate(PagePathConstant.RELOGIN)
      return
    // wrong tenant
    case ServerErrorConstant.ERROR_TENANT_DENIED.code:
      SystemHelper.pureNavigate(PagePathConstant.TENANT_ACCESS_DENIED)
      return
    // need verification
    case ServerErrorConstant.ERROR_NEED_VERIFICATION.code:
      SystemHelper.pureNavigate(PagePathConstant.VERIFICATION)
      return
    // if the backend hangs requests
    case ServerErrorConstant.ERROR_DURATION.code:
    case ServerErrorConstant.ERROR_TIMEOUT.code:
    case ServerErrorConstant.ERROR_ENDLESS_REQUESTS.code:
    case ServerErrorConstant.ERROR_INTERNAL_HTTP.code:
    case ServerErrorConstant.ERROR_INTERNAL_UNKNOWN.code:
      await getEngineInitData()
      SystemHelper.pureNavigate(PagePathConstant.INIT_ERROR)

      return

    default:
      // we do not proceed with loading or redirecting for local env
      // in order to see the error
      if (SystemHelper.isLocalEnv()) {
        SystemHelper.throwErrorInLocalEnv(`Engine unhandled error - ${code}`)
        return
      }
      SystemHelper.pureNavigate(PagePathConstant.AUTH0_LOGIN)
      return
  }
}
