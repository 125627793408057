import ScheduleInterface from '@lib/interfaces/schedule.interface'
import FrequencyConstant from '@lib/constants/data/time/frequency.constant'
import DayOfWeekConstant from '@lib/constants/data/time/day-of-week.constant'
import { DateRangeTuple } from '@lib/engine-types'
import ValueInterface from '@lib/interfaces/value.interface'
import { DefinedDateRangeTuple } from '@lib/engine-types'
import TimeHelper from '@lib/helpers/time.helper'
import ScanWithTimestampsInterface from '@lib/interfaces/scan-with-timestamps.interface'
import moment from 'moment-timezone'
import TimeFormatConstant from '@lib/constants/time-format.constant'

// for server jobs and tasks we have a limitation - 1 hour
// most tasks less than 1 hour long, for others - users just
// don't see toasts - otherwise we get a lot of data if there
// are lots of jobs
export const ONE_HOUR_MILLISECONDS = 60 * 60 * 1000

// used for Dashboard time counter
export const ONE_MINUTE_MILLISECONDS = 60 * 1000
export const TEN_MINUTE_MILLISECONDS = 10 * 60 * 1000
export const TWENTY_MINUTE_MILLISECONDS = 20 * 60 * 1000
export const THIRTY_MINUTE_MILLISECONDS = 30 * 60 * 1000
export const FORTY_MINUTE_MILLISECONDS = 40 * 60 * 1000
export const FIFTY_MINUTE_MILLISECONDS = 50 * 60 * 1000
export const TWO_HOURS_MILLISECONDS = 2 * 60 * 60 * 1000
export const THREE_HOURS_MILLISECONDS = 3 * 60 * 60 * 1000
export const FOUR_HOURS_MILLISECONDS = 4 * 60 * 60 * 1000
export const FIVE_HOURS_MILLISECONDS = 5 * 60 * 60 * 1000
export const SIX_HOURS_MILLISECONDS = 6 * 60 * 60 * 1000
export const SEVEN_HOURS_MILLISECONDS = 7 * 60 * 60 * 1000

// Formats

// Output: "12 Dec 2022 12:32 pm"
export const DATE_FORMAT_1 = 'DD MMM YYYY hh:mm A'

export const EMPTY_SCHEDULE = (): ScheduleInterface => {
  const dayTodayPlusHour = TimeHelper.currentPlusHours(1)

  return {
    frequency: FrequencyConstant.FREQUENCY_DAILY,
    dayOfMonth: -1,
    dayOfWeek: DayOfWeekConstant.DAY_SUNDAY,
    windowStart: {
      timezone: TimeFormatConstant.UTC_TIMEZONE_VALUE,
      hour: TimeHelper.momentNow().startOf('day').hour(),
      minute: 0,
    },
    windowEnd: {
      timezone: TimeFormatConstant.UTC_TIMEZONE_VALUE,
      hour: TimeHelper.momentNow().endOf('day').hour(),
      minute: 55,
    },
    daysList: {
      monday: true,
      thursday: true,
      wednesday: true,
      sunday: true,
      friday: true,
      saturday: true,
      tuesday: true,
    },
    firstRun: {
      runNow: true,
      runOn: {
        day: TimeHelper.momentToTimestamp(dayTodayPlusHour),
        time: {
          timezone: TimeFormatConstant.UTC_TIMEZONE_VALUE,
          hour: dayTodayPlusHour.hours(),
          minute: dayTodayPlusHour.minutes(),
        },
      },
    },
  }
}

export const EMPTY_SCAN_WITH_TIMESTAMPS = (): ScanWithTimestampsInterface => {
  const dayTodayPlusHour = TimeHelper.currentPlusHours(1)

  return {
    isScanObjects: false,
    dateTimeData: {
      day: TimeHelper.momentToTimestamp(dayTodayPlusHour),
      time: {
        timezone: TimeFormatConstant.UTC_TIMEZONE_VALUE,
        hour: dayTodayPlusHour.hours(),
        minute: dayTodayPlusHour.minutes(),
      },
    },
  }
}

export const EMPTY_DATE_RANGE = (): DateRangeTuple => [null, null]
export const EMPTY_REPORT_PERIOD = (): ValueInterface => ({ name: '' })

export const DEFAULT_DATE_RANGE = (): DefinedDateRangeTuple => {
  const to = TimeHelper.momentNow()
  let from = to.clone().startOf('month')
  if (from.date() === to.date()) {
    from = TimeHelper.currentMinusMonths(1)
  }
  return [
    TimeHelper.momentToTimestamp(from),
    TimeHelper.timestampToEndOfTheDay(TimeHelper.momentToTimestamp(to)),
  ]
}

export const DEFAULT_TIME_ZONE = () => {
  //https://momentjs.com/timezone/docs/#/using-timezones/guessing-user-timezone/
  const value = moment.tz.guess()
  return {
    value,
  }
}

export const UTC_TIME_ZONE = () => {
  return {
    value: 'UTC',
  }
}
