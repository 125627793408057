enum ErrorGroupConstants {
  EMPTY,
  ACCESS,
  ACCOUNT,
  USER,
  TENANT,
  REX,
  ASSETS,
  ASSETS_SELECT,
  RECOVERY_POINT,
  VAULT,
  SOURCE_ID,
  MANAGEMENT_ROLES,
  JOBS,
  TASK,
  RESTORE,
  DASHBOARD,
  POLICIES,
  EDIT_POLICY,
  UPDATE_DEFAULT_POLICY,
  DATA_ITEMS,
  ANTI_MALWARE,
  POLICIES_RETENTION,
  EDIT_POLICY_RETENTION,
  EDIT_POLICY_RETENTION_DEFAULT,
  ASSETS_POLICY_RETENTION,
  USER_NOTIFICATIONS,
  TENANT_NOTIFICATIONS,
  COMPLIANCE_SUMMARY,
  BACKUP_AUDIT,
  WEBHOOK,
  SCHEDULE_REPORT,
  VOLUME_VULNERABILITIES_REPORT,
  S3_PATHS,
  BACKUP,
  ASSET_LIST_RECOVERY_POINTS,
  ASSETS_SELECT_INVENTORY,
  // [g.js - put new here]
}

export default ErrorGroupConstants
