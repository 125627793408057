"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListInventoryInstances = void 0;
const models_1 = require("../../models");
const share_1 = require("../share");
class ListInventoryInstances {
    #instances;
    #volumes;
    #threats;
    #firstBackups;
    #lastBackups;
    #policies;
    #lastScans;
    #lastEbsSnapshots;
    #volumesWithScans;
    constructor(parameters) {
        this.#instances = parameters.instances;
        this.#threats = parameters.threats;
        this.#firstBackups = parameters.firstBackups;
        this.#lastBackups = parameters.lastBackups;
        this.#policies = parameters.policies;
        this.#lastEbsSnapshots = parameters.lastEbsSnapshots;
        this.#lastScans = parameters.lastScans;
        this.#volumesWithScans ||= this.#getVolumeWithScans();
        this.#volumes = parameters.volumes.reduce((map, volume) => {
            map.set(volume.id, volume);
            return map;
        }, new Map());
    }
    execute(filters) {
        return this.#prepareOutput(this.#findInstanceMatchingFilters(filters));
    }
    #prepareOutput(instances) {
        const instancesOutput = instances.map((instance) => ({
            uuid: instance.id,
            isInfected: this.#isUnhealthyInstance(instance),
            isRunning: this.#getIsRunning(instance),
            isProtected: this.#isProtected(instance),
            accountId: instance.awsAccountId,
            instanceId: instance.awsId,
            attachedVolumes: this.#getAttachedVolumes(instance),
            coveredByPolicesList: instance.backupPolicies,
            createdAt: instance.createdAt,
            osKind: instance.osKind,
            region: instance.awsRegion,
            sla: this.#getSla(instance),
            rpo: this.#getRpo(instance),
            instanceSize: 0,
            instanceAge: this.#getInstanceAge(instance),
            instanceState: instance.state,
            lastScan: this.#getLastScanForInstance(instance),
            lastBackup: this.#getLastBackup(instance),
            maxBackupRetention: this.#getMaxBackupRetention(instance),
            imageName: instance.arn,
            instanceName: instance.name,
            awsId: instance.awsId,
        }));
        return {
            instances: instancesOutput,
            summarizedData: this.#summarizeInstanceData(instances),
        };
    }
    #getSla(instance) {
        const relatedPolicies = this.#policies.filter((policy) => instance.backupPolicies.includes(policy.id));
        if (!relatedPolicies.length) {
            return null;
        }
        const frequencies = relatedPolicies.map(({ sla }) => sla);
        const minFrequency = Math.min(...frequencies);
        return minFrequency;
    }
    #getLastScanForInstance(instance) {
        const attachedVolumes = this.#getAttachedVolumes(instance);
        const scans = attachedVolumes.map((volume) => this.#getLastScanForVolume(volume));
        const scansTimeList = [];
        for (const scan of scans) {
            if (!scan) {
                continue;
            }
            scansTimeList.push(scan.getTime());
        }
        if (!scansTimeList.length) {
            return null;
        }
        return new Date(Math.max(...scansTimeList));
    }
    #getLastScanForVolume(volume) {
        const scansList = this.#volumesWithScans.get(volume.id);
        if (!scansList || scansList.length === 0) {
            return null;
        }
        const scansTime = scansList.map(({ createdAt }) => createdAt.getTime());
        return new Date(Math.max(...scansTime));
    }
    #getVolumeWithScans() {
        const map = new Map();
        for (const scan of this.#lastScans) {
            const volumesScans = map.get(scan.scanTarget.target.assetId);
            if (volumesScans) {
                volumesScans.push(scan);
            }
            else {
                map.set(scan.scanTarget.target.assetId, [scan]);
            }
        }
        return map;
    }
    #isUnhealthyInstance(instance) {
        const attachedVolumes = this.#getAttachedVolumes(instance);
        if (instance.state === models_1.EC2State.TERMINATED) {
            return false;
        }
        return attachedVolumes.some((volume) => this.#threats.some((threat) => threat.source.asset?.assetId === volume.id ||
            threat.source.assetItem?.assetId === volume.id));
    }
    #getAttachedVolumes(instance) {
        return instance.ebsIds
            .map((id) => this.#volumes.get(id))
            .filter(Boolean);
    }
    #getIsRunning(instance) {
        return instance.state === models_1.EC2State.RUNNING;
    }
    #isProtected(instance) {
        return instance.backupPolicies.length > 0 && this.#lastBackups.size > 0;
    }
    #getLastBackup(instance) {
        return this.#lastBackups.get(instance.id);
    }
    #getFirstBackup(instance) {
        return this.#firstBackups.get(instance.id);
    }
    #getRpo(instance) {
        const lastBackup = this.#getLastBackup(instance);
        if (!lastBackup) {
            return null;
        }
        return Date.now() - lastBackup.timestamp.getTime();
    }
    #getInstanceAge(instance) {
        return Math.round(Math.abs((Date.now() - instance.createdAt.getTime()) / (1000 * 3600 * 24)));
    }
    #getMaxBackupRetention(instance) {
        const firstBackup = this.#getFirstBackup(instance);
        if (!firstBackup) {
            return null;
        }
        return Date.now() - firstBackup.timestamp.getTime();
    }
    #getInstanceCoveredByPolicies(instance) {
        return instance.backupPolicies.length > 0;
    }
    #getVolumeSnapshots(volume) {
        return this.#lastEbsSnapshots.filter((snapshot) => snapshot.volumeId === volume.id);
    }
    #summarizeInstanceData(instances) {
        const instancesCount = new Set([...instances.map(({ awsId }) => awsId)])
            .size;
        const accountsCount = new Set([
            ...instances.map(({ awsAccountId }) => awsAccountId),
        ]).size;
        const regionsCount = new Set([
            ...instances.map(({ awsRegion }) => awsRegion),
        ]).size;
        const osTypesCount = new Set([...instances.map(({ osKind }) => osKind)])
            .size;
        const statusesCount = new Set([...instances.map(({ state }) => state)]).size;
        const attachedVolumeIds = [
            ...new Set(instances.flatMap(({ ebsIds }) => ebsIds)),
        ];
        const attachedVolumesList = attachedVolumeIds
            .map((id) => this.#volumes.get(id))
            .filter(Boolean);
        const inventorySize = attachedVolumesList.reduce((accum, curr) => accum + curr.size, 0);
        return {
            instancesCount,
            accountsCount,
            regionsCount,
            osTypesCount,
            statusesCount,
            inventorySize,
        };
    }
    #findInstanceMatchingFilters(filters) {
        const { accountIds, regions, covered, idSearch, backupsOnSchedule } = filters;
        return this.#instances.filter((instance) => {
            if (accountIds &&
                !(0, share_1.matchesIncludeFilter)(accountIds, instance.awsAccountId)) {
                return false;
            }
            if (regions && !(0, share_1.matchesIncludeFilter)(regions, instance.awsRegion)) {
                return false;
            }
            if (covered &&
                covered?.includes(true) &&
                !this.#getInstanceCoveredByPolicies(instance)) {
                return false;
            }
            if (covered &&
                covered?.includes(false) &&
                this.#getInstanceCoveredByPolicies(instance)) {
                return false;
            }
            if (idSearch &&
                idSearch &&
                !this.#isInstanceSearchMatches(idSearch, instance)) {
                return false;
            }
            if (backupsOnSchedule &&
                backupsOnSchedule?.includes(true) &&
                !this.#isInstanceOutOfSchedule(instance)) {
                return false;
            }
            if (backupsOnSchedule &&
                backupsOnSchedule?.includes(false) &&
                this.#isInstanceOutOfSchedule(instance)) {
                return false;
            }
            return true;
        });
    }
    #isInstanceSearchMatches(searchValue, instance) {
        const idMatches = instance.awsId.includes(searchValue);
        const nameMatches = instance.name.includes(searchValue);
        const tagsMatches = instance.tags.some(({ key, value }) => key.includes(searchValue) || value.includes(searchValue));
        const volumes = this.#getAttachedVolumes(instance);
        const volumesMatches = volumes.some((volume) => this.#isVolumeSearchMatches(searchValue, volume));
        return idMatches || nameMatches || tagsMatches || volumesMatches;
    }
    #isVolumeSearchMatches(searchValue, volume) {
        const idMatches = volume.awsId.includes(searchValue);
        const nameMatches = volume.name.includes(searchValue);
        const tagsMatches = volume.tags.some(({ key, value }) => key.includes(searchValue) || value.includes(searchValue));
        const snapshots = this.#getVolumeSnapshots(volume);
        const snapshotsMatches = snapshots.some((snapshot) => this.#isVolumeSnapshotMatchesBySearch(searchValue, snapshot));
        return idMatches || nameMatches || tagsMatches || snapshotsMatches;
    }
    #isVolumeSnapshotMatchesBySearch(searchValue, snapshot) {
        const idMatches = snapshot.awsId.includes(searchValue);
        const nameMatches = snapshot.name.includes(searchValue);
        const tagsMatches = snapshot.tags.some(({ key, value }) => key.includes(searchValue) || value.includes(searchValue));
        return idMatches || nameMatches || tagsMatches;
    }
    #isInstanceOutOfSchedule(instance) {
        const lastBackup = this.#getLastBackup(instance);
        const instanceSla = this.#getSla(instance);
        if (!lastBackup) {
            return false;
        }
        if (!instanceSla) {
            return false;
        }
        return Date.now() - lastBackup.createdAt.getTime() > instanceSla;
    }
}
exports.ListInventoryInstances = ListInventoryInstances;
