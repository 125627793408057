import NumHelper from '@lib/helpers/num.helper'
import TypeHelper from '@lib/helpers/type.helper'
import ValueInterface from '@lib/interfaces/value.interface'
import AwsSecurityGroupsModel, {
  AwsSecurityGroup,
} from '@lib/models/aws-security-groups.model'

abstract class AwsSecurityGroupsFactory {
  public static buildFromGrpc(params: any): AwsSecurityGroupsModel {
    return new AwsSecurityGroupsModel({
      id: NumHelper.numberHash(params.vpcId),
      innerId: TypeHelper.stringValDef(params.vpcId, ''),
      vpcId: TypeHelper.stringValDef(params.vpcId, ''),
      securityGroupsList: params.securityGroupsList.map((group: any) => {
        return AwsSecurityGroupsFactory.buildGroupFromGrpc(group)
      }),
    })
  }

  public static buildGroupFromGrpc(params: any): AwsSecurityGroup {
    return new AwsSecurityGroup({
      id: NumHelper.numberHash(params.id),
      innerId: TypeHelper.stringValDef(params.id, ''),
      groupId: TypeHelper.stringValDef(params.id, ''),
      groupName: TypeHelper.stringValDef(params.name, ''),
    })
  }

  public static buildGroupEmpty() {
    return new AwsSecurityGroup({
      id: '',
      innerId: '',
      groupId: '',
      groupName: '',
    })
  }

  public static fromGroupModelToVi(model: AwsSecurityGroup): ValueInterface {
    return {
      name: model.groupId,
      label: model.groupName,
    }
  }
}

export default AwsSecurityGroupsFactory
