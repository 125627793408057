import {
  EBSTransformer,
  EC2Transformer,
  EFSTransformer,
  GenericHostTransformer,
  OvaServerTransformer,
  S3BucketTransformer,
} from './../assets/transformers'
/* eslint-disable import/no-extraneous-dependencies */
import {
  Request,
  Response,
} from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/assets/list_assets_by_ids_pb'
import { GrpcClient } from '@lib/clients/grpc-client'
import { AssetsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import {
  EBS,
  EC2,
  EFS,
  GenericHost,
  OvaServer,
  S3Bucket,
} from 'blues-corejs/dist'

export type Asset = {
  ec2Instance?: EC2
  ebsVolume?: EBS
  s3Bucket?: S3Bucket
  efsFilesystem?: EFS
  genericHost?: GenericHost
  ovaServer?: OvaServer
}

export type ListAssetsByIds = {
  assetsList: Array<Asset>
}

export class ListAssetsByIdsClient extends GrpcClient<AssetsPromiseClient> {
  #assetsClient: AssetsPromiseClient

  constructor(hostName = '') {
    super()
    this.#assetsClient = this.getClient(hostName)
  }

  protected initClient(hostName: string): AssetsPromiseClient {
    return new AssetsPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'ListAssetsByIdsClient'
  }

  async listAssetsByIds(assetIds: Array<string>): Promise<ListAssetsByIds> {
    const request = new Request().setAssetIdsList(assetIds)

    const response = (
      await this.retryGrpcCall(
        () => this.#assetsClient.listAssetsByIds(request, this.metadata()),
        {
          requestName: 'AssetsPromiseClient/listAssetsByIds',
        }
      )
    ).toObject()

    return this.#transformResponse(response)
  }

  #transformResponse(response: Response.AsObject): ListAssetsByIds {
    return {
      assetsList: response.assetsList.map(
        ({
          ec2Instance,
          ebsVolume,
          s3Bucket,
          efsFilesystem,
          genericHost,
          ovaServer,
        }) => ({
          ec2Instance:
            ec2Instance && new EC2Transformer(ec2Instance).transform(),
          ebsVolume: ebsVolume && new EBSTransformer(ebsVolume).transform(),
          s3Bucket: s3Bucket && new S3BucketTransformer(s3Bucket).transform,
          efsFilesystem:
            efsFilesystem && new EFSTransformer(efsFilesystem).transform(),
          genericHost:
            genericHost && new GenericHostTransformer(genericHost).transform(),
          ovaServer:
            ovaServer && new OvaServerTransformer(ovaServer).transform(),
        })
      ),
    }
  }
}

const listAssetsByIdsClient = new ListAssetsByIdsClient()

export { listAssetsByIdsClient }
