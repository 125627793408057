import React from 'react'

function BellWithGreenDotIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9847 23.4468L24.1026 22.5648V19.146C24.1026 17.0468 22.9813 15.2895 21.0257 14.8246V14.3596C21.0257 13.7921 20.5676 13.334 20.0001 13.334C19.4326 13.334 18.9744 13.7921 18.9744 14.3596V14.8246C17.012 15.2895 15.8975 17.04 15.8975 19.146V22.5648L15.0155 23.4468C14.5847 23.8776 14.8855 24.616 15.4941 24.616H24.4992C25.1146 24.616 25.4155 23.8776 24.9847 23.4468ZM22.7351 23.2485H17.265V19.146C17.265 17.4502 18.2975 16.069 20.0001 16.069C21.7026 16.069 22.7351 17.4502 22.7351 19.146V23.2485ZM20.0001 26.6673C20.7522 26.6673 21.3676 26.0519 21.3676 25.2998H18.6326C18.6326 26.0519 19.2411 26.6673 20.0001 26.6673Z"
        fill="white"
      />
      <rect x="25" y="10" width="5" height="5" rx="2.5" fill="#70D180" />
    </svg>
  )
}

export default BellWithGreenDotIcon
