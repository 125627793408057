abstract class NumHelper {
  // TODO: replace with StrHelper.makeHashNum
  public static numberHash(value: any): number {
    if (value === undefined || value === null) {
      return -1
    }
    return value
      .toString()
      .split('')
      .reduce((a: any, b: any) => {
        // eslint-disable-next-line no-bitwise
        const temp = (a << 5) - a + b.charCodeAt(0)
        // eslint-disable-next-line no-bitwise
        return Math.abs(temp & temp)
      }, 0)
  }

  public static roundTo(value: number, maxSigns = 0): number {
    const factor = Math.pow(10, maxSigns)
    return Math.round(value * factor) / factor
  }

  public static moneyFormat(value: any, precision = 2): string {
    return value?.toFixed(precision) ?? ''
  }

  public static roundedPercent(
    value: number,
    all: number,
    maxSigns = 2
  ): number {
    return NumHelper.roundTo((value * 100) / all, maxSigns)
  }

  public static getNumberUSFormatted(value: number, maxSigns = 2): string {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: maxSigns,
      maximumFractionDigits: maxSigns,
    }).format(value)
  }

  public static getSizeFormattedInGib(sizeInByte: number): string {
    return NumHelper.getNumberUSFormatted(
      Number(Math.ceil(sizeInByte / 1024 / 1024 / 1024)),
      0
    )
  }

  public static formatLargeNumber(number: number): string {
    if (number >= 1000000000) {
      return (
        String(Math.floor(number / 100000000) / 10).replace(/\.0$/, '') + ' B'
      )
    } else if (number >= 1000000) {
      return String(Math.floor(number / 100000) / 10).replace(/\.0$/, '') + ' M'
    } else if (number >= 10000) {
      return String(Math.floor(number / 100) / 10).replace(/\.0$/, '') + ' K'
    }
    return number.toLocaleString()
  }
}

export default NumHelper
