/* eslint-disable import/no-extraneous-dependencies */
import { useTheme } from '@mui/material'
import { Backup } from 'blues-corejs/dist'

const RANSOMWARE_TEXT = {
  GOOD: 'No Ransomware found',
  BAD: 'Ransomware found',
  UNCHECKED: 'Not scanned for Ransomware',
}

const MALWARE_TEXT = {
  GOOD: 'No Malware found',
  BAD: 'Malware found',
  UNCHECKED: 'Not scanned for Malware',
}

const FILESYSTEM_TEXT = {
  GOOD: 'No File Corruption found',
  BAD: 'File Corruption found',
  UNCHECKED: 'Not scanned for File Corruption',
}

export function useComputeRansomwareScanStatusByBackup(backup: Backup) {
  const { palette } = useTheme()

  const { red, green, grey } = palette

  if (backup.ransomwareIsNotDetected) {
    return {
      color: green[50],
      fill: green[500],
      tooltipText: RANSOMWARE_TEXT.GOOD,
    }
  }
  if (backup.ransomwareIsDetected) {
    return {
      color: red[50],
      fill: red[500],
      tooltipText: RANSOMWARE_TEXT.BAD,
    }
  }

  return {
    color: grey[200],
    fill: grey[500],
    tooltipText: RANSOMWARE_TEXT.UNCHECKED,
  }
}

export function useComputeMalwareScanStatusByBackup(backup: Backup) {
  const { palette } = useTheme()

  const { red, green, grey } = palette

  if (backup.malwareIsNotDetected) {
    return {
      color: green[50],
      fill: green[500],
      tooltipText: MALWARE_TEXT.GOOD,
    }
  }
  if (backup.malwareIsDetected) {
    return {
      color: red[50],
      fill: red[500],
      tooltipText: MALWARE_TEXT.BAD,
    }
  }

  return {
    color: grey[200],
    fill: grey[500],
    tooltipText: MALWARE_TEXT.UNCHECKED,
  }
}

export function useComputeFilesystemScanStatusByBackup(backup: Backup) {
  const { palette } = useTheme()

  const { red, green, grey } = palette

  if (backup.filesystemIsNotCorrupted) {
    return {
      color: green[50],
      fill: green[500],
      tooltipText: FILESYSTEM_TEXT.GOOD,
    }
  }
  if (backup.filesystemIsCorrupted) {
    return {
      color: red[50],
      fill: red[500],
      tooltipText: FILESYSTEM_TEXT.BAD,
    }
  }

  return {
    color: grey[200],
    fill: grey[500],
    tooltipText: FILESYSTEM_TEXT.UNCHECKED,
  }
}
