import BaseDataConstant from '@lib/constants/data/base-data.constant'
import ValueInterface from '@lib/interfaces/value.interface'

class FrequencyConstantInner extends BaseDataConstant<ValueInterface> {
  get FREQUENCY_UNDEFINED(): ValueInterface {
    return {
      name: 'Undefined',
      value: -1,
      extraValue: 1,
    }
  }

  get FREQUENCY_EVERY_15_MIN(): ValueInterface {
    return {
      name: 'Every 15 minutes',
      value: 0,
      extraValue: 15,
    }
  }

  get FREQUENCY_EVERY_30_MIN(): ValueInterface {
    return {
      name: 'Every 30 minutes',
      value: 1,
      extraValue: 30,
    }
  }

  get FREQUENCY_EVERY_HOUR(): ValueInterface {
    return {
      name: 'Every Hour',
      value: 2,
      extraValue: 60,
    }
  }

  get FREQUENCY_EVERY_12_HOURS(): ValueInterface {
    return {
      name: 'Every 12 Hours',
      value: 3,
    }
  }

  get FREQUENCY_DAILY(): ValueInterface {
    return {
      name: 'Daily',
      value: 4,
    }
  }

  get FREQUENCY_WEEKLY(): ValueInterface {
    return {
      name: 'Weekly',
      value: 5,
    }
  }

  get FREQUENCY_MONTHLY(): ValueInterface {
    return {
      name: 'Monthly',
      value: 6,
    }
  }
}

const FrequencyConstant = FrequencyConstantInner.build(
  FrequencyConstantInner,
  'FREQUENCY_'
)

export default FrequencyConstant
