/* eslint-disable import/no-extraneous-dependencies */
import TimeHelper from '@lib/helpers/time.helper'
import { Efs as EfsProto } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/assets/aws/efs_pb'
import { EFS } from 'blues-corejs/dist'
import { EfsState } from 'blues-corejs/dist/models/assets/aws/efs/types'

type EfsPt = EfsProto.AsObject

export class EFSTransformer {
  #filesystem: EfsPt

  constructor(filesystem: EfsPt) {
    this.#filesystem = filesystem
  }

  transform(): EFS {
    return this.#transformFilesystem(this.#filesystem)
  }

  #transformFilesystem(filesystem: EfsPt): EFS {
    return new EFS({
      arn: filesystem.arn,
      awsAccountId: filesystem.awsAccountId,
      awsId: filesystem.awsId,
      awsRegion: filesystem.awsRegion,
      backupPolicies: filesystem.backupPoliciesList,
      ccAssetId: filesystem.ccAssetId,
      createdAt: TimeHelper.secondsToDate(filesystem.createdAt?.seconds),
      id: filesystem.id,
      state: this.#convertEFSState(filesystem.state),
      tags: filesystem.awsTagsList,
      updatedAt: TimeHelper.secondsToDate(filesystem.updatedAt?.seconds),
    })
  }

  #convertEFSState(state: EfsProto.State): EfsState {
    const mapping = {
      [EfsProto.State.STATE_DELETED]: EfsState.DELETED,
      [EfsProto.State.STATE_IN_USE]: EfsState.IN_USE,
      [EfsProto.State.STATE_UNKNOWN]: EfsState.UNKNOWN,
    }

    return mapping[state]
  }
}
