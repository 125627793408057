/* eslint-disable import/no-extraneous-dependencies */
import TimeHelper from '@lib/helpers/time.helper'
import { File as FileProto } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/asset_items/file_pb'
import { File } from 'blues-corejs/dist'

type FilePt = FileProto.AsObject

export class FileTransformer {
  #file: FilePt

  constructor(file: FilePt) {
    this.#file = file
  }

  transform(): File {
    return this.#transformFile(this.#file)
  }

  #transformFile(file: FilePt): File {
    return new File({
      id: file.id,
      assetId: file.assetId,
      ccAssetId: file.ccAssetId,
      path: file.path,
      createdAt: TimeHelper.secondsToDate(file.createdAt?.seconds),
      updatedAt: TimeHelper.secondsToDate(file.updatedAt?.seconds),
    })
  }
}
