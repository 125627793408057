import BaseModel from '@lib/models/base-model/base.model'
import { CfnStatusConstant } from '@lib/constants/cfn-status.constant'

class CfnModel extends BaseModel {
  public readonly arn: string

  public readonly region: string

  public readonly status: CfnStatusConstant

  public readonly isEmpty: boolean

  constructor(readonly params: any = {}) {
    super(params)

    this.arn = params.arn
    this.region = params.region
    this.status = params.status
    this.isEmpty = params.isEmpty
  }
}

export default CfnModel
