import { useCustomFlags } from '@components-context/feature-flags/use-custom-flags'
import { useAuth0 } from '@auth0/auth0-react'

import { performLogout } from './perform-logout'
import { useFetchEnvKeys } from '@lib/hooks/api-hooks'

const LOCALHOST_URL = 'http://localhost:3201'

export function useLogout() {
  const { envKeys } = useFetchEnvKeys()
  const { auth0Spa } = useCustomFlags()
  const { logout: auth0Logout } = useAuth0()

  const getReturnToLink = () => {
    return envKeys.publicLoginDomain || LOCALHOST_URL
  }

  const handleLogout = async () => {
    try {
      await performLogout(auth0Spa, () =>
        auth0Logout({
          logoutParams: {
            returnTo: getReturnToLink(),
          },
        })
      )
    } catch (error) {
      console.error('Logout failed:', error)
    }
  }

  return { logout: handleLogout }
}
