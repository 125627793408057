import { PagePathConstant } from '@lib/constants'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export function useAuthentication(shouldHandleAuth: boolean): {
  isAuthenticated: boolean
  isLoading: boolean
} {
  const { isAuthenticated, isLoading, error, loginWithRedirect } = useAuth0()
  const router = useRouter()

  useEffect(() => {
    if (!shouldHandleAuth || isLoading) {
      return
    }

    if (error) {
      router.push(PagePathConstant.INIT_ERROR)
      return
    }

    if (!isAuthenticated) {
      const { pathname, search } = window.location

      loginWithRedirect({
        appState: {
          returnTo: `${pathname}${search}`,
        },
      })
    }
  }, [shouldHandleAuth, isAuthenticated, isLoading])

  return {
    isAuthenticated,
    isLoading,
  }
}
