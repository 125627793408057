import NumHelper from '@lib/helpers/num.helper'
import TypeHelper from '@lib/helpers/type.helper'
import AccessTokenModel from '@lib/models/access-token.model'

abstract class AccessFactory {
  public static buildFromGRPC(params: any): AccessTokenModel {
    return new AccessTokenModel({
      id: NumHelper.numberHash(params.id),
      innerId: TypeHelper.stringValDef(params.id, ''),
      createdAt: TypeHelper.momentVal(params.createdAt.seconds * 1000),
      updatedAt: TypeHelper.momentVal(params.updatedAt.seconds * 1000),
      name: TypeHelper.stringValDef(params.name, ''),
      scopesList: TypeHelper.arrayStringValDef(params.scopesList, []),
      tenantId: TypeHelper.stringValDef(params.tenantId, ''),
      userId: TypeHelper.stringValDef(params.userId, ''),
    })
  }

  public static buildEmpty(): AccessTokenModel {
    return new AccessTokenModel({
      name: '',
      scopesList: [],
    })
  }

  public static build(params: any): AccessTokenModel {
    return new AccessTokenModel({
      ...params,
      createdAt: TypeHelper.momentVal(params.createdAt),
      updatedAt: TypeHelper.momentVal(params.updatedAt),
      scopesList: TypeHelper.arrayStringValDef(params.scopesList, []),
    })
  }
}

export default AccessFactory
