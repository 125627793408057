import React from 'react'

function ExitIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12048_48526)">
        <path
          d="M13.3125 14.25V15.5C13.3125 16.8785 12.191 18 10.8125 18H4.53125C3.15271 18 2.03125 16.8785 2.03125 15.5V4.5C2.03125 3.12146 3.15271 2 4.53125 2H10.8125C12.191 2 13.3125 3.12146 13.3125 4.5V5.75C13.3125 6.09521 13.0327 6.375 12.6875 6.375C12.3423 6.375 12.0625 6.09521 12.0625 5.75V4.5C12.0625 3.81079 11.5017 3.25 10.8125 3.25H4.53125C3.84204 3.25 3.28125 3.81079 3.28125 4.5V15.5C3.28125 16.1892 3.84204 16.75 4.53125 16.75H10.8125C11.5017 16.75 12.0625 16.1892 12.0625 15.5V14.25C12.0625 13.9048 12.3423 13.625 12.6875 13.625C13.0327 13.625 13.3125 13.9048 13.3125 14.25ZM17.5736 8.92639L16.1741 7.52686C15.9299 7.28271 15.5342 7.28271 15.2902 7.52686C15.046 7.77087 15.046 8.16663 15.2902 8.41064L16.2856 9.40625H8.78125C8.43604 9.40625 8.15625 9.68604 8.15625 10.0312C8.15625 10.3765 8.43604 10.6562 8.78125 10.6562H16.2856L15.2902 11.6519C15.046 11.8959 15.046 12.2916 15.2902 12.5356C15.4122 12.6577 15.5721 12.7188 15.7321 12.7188C15.8921 12.7188 16.052 12.6577 16.1741 12.5356L17.5736 11.1361C18.1829 10.5269 18.1829 9.53564 17.5736 8.92639Z"
          fill="#999CAA"
        />
      </g>
      <defs>
        <clipPath id="clip0_12048_48526">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ExitIcon
