import React from 'react'

function AuditLogIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.05 2.50096C6.80832 2.38429 3.33332 5.79262 3.33332 10.001H1.84165C1.46665 10.001 1.28332 10.451 1.54998 10.7093L3.87498 13.0426C4.04165 13.2093 4.29998 13.2093 4.46665 13.0426L6.79165 10.7093C7.04998 10.451 6.86665 10.001 6.49165 10.001H4.99998C4.99998 6.75096 7.64998 4.12596 10.9167 4.16762C14.0167 4.20929 16.625 6.81762 16.6666 9.91762C16.7083 13.176 14.0833 15.8343 10.8333 15.8343C9.49165 15.8343 8.24998 15.376 7.26665 14.601C6.93332 14.3426 6.46665 14.3676 6.16665 14.6676C5.81665 15.0176 5.84165 15.6093 6.23332 15.9093C7.49998 16.9093 9.09165 17.501 10.8333 17.501C15.0417 17.501 18.45 14.026 18.3333 9.78429C18.225 5.87596 14.9583 2.60929 11.05 2.50096ZM10.625 6.66762C10.2833 6.66762 9.99998 6.95096 9.99998 7.29262V10.3593C9.99998 10.651 10.1583 10.926 10.4083 11.076L13.0083 12.6176C13.3083 12.7926 13.6916 12.6926 13.8666 12.401C14.0416 12.101 13.9416 11.7176 13.65 11.5426L11.25 10.1176V7.28429C11.25 6.95095 10.9667 6.66762 10.625 6.66762Z"
        fill="#999CAA"
      />
    </svg>
  )
}

export default AuditLogIcon
