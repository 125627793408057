"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.diskFactory = void 0;
const disk_1 = require("../../models/asset-items/disk");
const factories_1 = require("../../use_cases/tests/factories");
const fishery_1 = require("fishery");
const common_1 = require("./common");
class DiskFactory extends fishery_1.Factory {
}
exports.diskFactory = DiskFactory.define(({ transientParams, sequence }) => {
    return new disk_1.Disk({
        id: transientParams.id ?? (0, factories_1.uuid)(),
        assetId: transientParams.assetId ?? (0, factories_1.uuid)(),
        ccAssetId: transientParams.ccAssetId ?? (0, factories_1.uuid)(),
        deviceName: transientParams.deviceName ?? (0, factories_1.name)('Device Name', sequence),
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
    });
});
