import BaseModel from '@lib/models/base-model/base.model'
import StrHelper from '@lib/helpers/str.helper'
import NumHelper from '@lib/helpers/num.helper'

class VaultMetricsModel extends BaseModel {
  public readonly totalRecoveryPointSize: number

  public readonly reductionFactor: number

  public readonly assetsCountProtect: number

  public readonly assetsCountAcc: number

  // extra
  public readonly totalRecoveryPointSizeFormatted: string

  public readonly reductionFactorFormatted: string

  constructor(readonly params: any = {}) {
    super(params)

    // base
    this.totalRecoveryPointSize = params.totalRecoveryPointSize
    this.reductionFactor = params.reductionFactor
    this.assetsCountProtect = params.assetsCountProtect
    this.assetsCountAcc = params.assetsCountAcc

    // extra
    this.totalRecoveryPointSizeFormatted = StrHelper.realFileSize(
      this.totalRecoveryPointSize,
      '0'
    )
    const reductionFactorFormatted = String(
      NumHelper.roundTo(this.reductionFactor, 2)
    )

    this.reductionFactorFormatted = reductionFactorFormatted
      ? `${reductionFactorFormatted}x`
      : '- - x'
  }
}

export default VaultMetricsModel
