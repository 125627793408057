import {
  EntropyDetectionNotificationsStatus,
  TenantSettingsAttrs,
} from './types'

export class TenantSettings {
  #entropyDetectionStatus: EntropyDetectionNotificationsStatus

  constructor(attrs: TenantSettingsAttrs) {
    this.#entropyDetectionStatus = attrs.entropyDetectionStatus
  }

  get isEntropyDetectionEnabled(): boolean {
    return (
      this.#entropyDetectionStatus ===
      EntropyDetectionNotificationsStatus.ENABLED
    )
  }

  get isEntropyDetectionDisabled(): boolean {
    return (
      this.#entropyDetectionStatus ===
      EntropyDetectionNotificationsStatus.DISABLED
    )
  }
}
