import { AssetsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import { Request as ListAssetsByIdsRequest } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/assets/list_assets_by_ids_pb'
import { GrpcClient } from '@lib/clients/grpc-client'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { LiveAssets } from './types'
import { ListLiveAssetsTransformer } from './list-live-assets-transformer'
import {
  EBSTransformer,
  EC2Transformer,
  EFSTransformer,
  GenericHostTransformer,
  OvaServerTransformer,
  S3BucketTransformer,
} from './transformers'

export class ListLiveAssetsClient extends GrpcClient<AssetsPromiseClient> {
  #assetsClient: AssetsPromiseClient

  #transformer: ListLiveAssetsTransformer

  constructor(hostName = '', transformer = new ListLiveAssetsTransformer()) {
    super()
    this.#assetsClient = this.getClient(hostName)
    this.#transformer = transformer
  }

  protected innerClientTypeId(): string {
    return 'ListLiveAssetsClient'
  }

  protected initClient(hostName = ''): AssetsPromiseClient {
    return new AssetsPromiseClient(hostName, null, null)
  }

  async list(): Promise<LiveAssets> {
    const response = await this.retryGrpcCall(
      () => this.#assetsClient.listLiveAssets(new Empty(), this.metadata()),
      {
        requestName: 'AssetsPromiseClient/listLiveAssets',
      }
    )

    return this.#transformer.transformResponse(response)
  }

  async listAssetsByIds(assetIds: Array<string>) {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#assetsClient.listAssetsByIds(
            new ListAssetsByIdsRequest().setAssetIdsList(assetIds),
            this.metadata()
          ),
        {
          requestName: 'AssetsPromiseClient/listAssetsByIds',
        }
      )
    ).toObject()

    return response.assetsList.map(
      ({
        ec2Instance,
        ebsVolume,
        s3Bucket,
        genericHost,
        efsFilesystem,
        ovaServer,
      }) => {
        if (ec2Instance) {
          return new EC2Transformer(ec2Instance).transform()
        }

        if (ebsVolume) {
          return new EBSTransformer(ebsVolume).transform()
        }

        if (s3Bucket) {
          return new S3BucketTransformer(s3Bucket).transform
        }

        if (genericHost) {
          return new GenericHostTransformer(genericHost).transform()
        }

        if (efsFilesystem) {
          return new EFSTransformer(efsFilesystem).transform()
        }

        if (ovaServer) {
          return new OvaServerTransformer(ovaServer).transform()
        }

        throw new Error('No assets found by given ids')
      }
    )
  }
}
