"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThreatStatus = exports.ThreatKind = void 0;
var ThreatKind;
(function (ThreatKind) {
    ThreatKind[ThreatKind["RANSOMWARE"] = 0] = "RANSOMWARE";
    ThreatKind[ThreatKind["MALWARE"] = 1] = "MALWARE";
    ThreatKind[ThreatKind["FILESYSTEM_ERROR"] = 2] = "FILESYSTEM_ERROR";
})(ThreatKind = exports.ThreatKind || (exports.ThreatKind = {}));
var ThreatStatus;
(function (ThreatStatus) {
    ThreatStatus[ThreatStatus["DETECTED"] = 0] = "DETECTED";
    ThreatStatus[ThreatStatus["RESOLVED"] = 1] = "RESOLVED";
    ThreatStatus[ThreatStatus["IGNORED"] = 2] = "IGNORED";
})(ThreatStatus = exports.ThreatStatus || (exports.ThreatStatus = {}));
