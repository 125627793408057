import { Nullable } from '@lib/engine-types'

export enum ScanStatus {
  UNSCANNED = 1,
  SUCCEEDED = 2,
  FAILED = 3,
}

export interface Risks {
  ransomwareStatus?: Nullable<ScanStatus>
  malwareStatus?: Nullable<ScanStatus>
  fsCheckStatus?: Nullable<ScanStatus>
}

export enum AssetItemType {
  FILE,
  STREAM,
  LOCAL_VOLUME,
  DISK,
  S3_ITEM,
  FS_ITEM,
  FOLDER,
}

export interface AssetDetailsHeaderInfo {
  name: string
  label: string
  id?: string
  icon?: Nullable<JSX.Element>
  isCopy?: boolean
  alternateId?: string
  maxLength?: number
}

export enum Provider {
  PROVIDER_AWS_BRP = 0,
  PROVIDER_EBS_SNAPSHOT = 1,
  PROVIDER_ELASTIO_RP = 2,
}

export enum AssetItemKind {
  KIND_FILE = 0,
  KIND_STREAM = 1,
  KIND_VOLUME = 2,
  KIND_DISK = 3,
}

export enum OsKindString {
  OTHER = 'other',
  LINUX = 'linux',
  WINDOWS = 'windows',
}
