import React from 'react'

function UnlinkIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.10009 8.57198L4.70009 9.93498C4.52614 10.1115 4.31892 10.2519 4.0904 10.3478C3.86188 10.4438 3.6166 10.4935 3.36876 10.4941C3.12091 10.4946 2.87541 10.4461 2.64645 10.3511C2.41749 10.2562 2.20962 10.1169 2.03486 9.94113C1.8601 9.76538 1.72192 9.55672 1.62831 9.32723C1.5347 9.09774 1.48752 8.85197 1.48949 8.60413C1.49147 8.35629 1.54256 8.1113 1.63981 7.88333C1.73706 7.65535 1.87855 7.44893 2.05609 7.27598L3.41109 5.88598L2.35009 4.82498L0.988086 6.22198C0.369044 6.85714 0.0249017 7.71044 0.0300871 8.59735C0.0352724 9.48426 0.389369 10.3335 1.0158 10.9613C1.64222 11.5892 2.49062 11.9453 3.37752 11.9525C4.26441 11.9597 5.11851 11.6176 5.75509 11L7.15509 9.63098L6.10009 8.57198Z"
        fill="#32B6F3"
      />
      <path
        d="M4.22415 6.69998L3.47015 7.45398C3.39647 7.52264 3.33736 7.60544 3.29637 7.69744C3.25538 7.78944 3.23334 7.88876 3.23156 7.98946C3.22979 8.09016 3.24831 8.19019 3.28603 8.28358C3.32375 8.37697 3.3799 8.4618 3.45112 8.53302C3.52233 8.60424 3.60717 8.66038 3.70056 8.6981C3.79394 8.73583 3.89397 8.75435 3.99468 8.75257C4.09538 8.7508 4.19469 8.72876 4.28669 8.68776C4.37869 8.64677 4.46149 8.58767 4.53015 8.51398L5.28515 7.75898L4.22415 6.69998Z"
        fill="#32B6F3"
      />
      <path
        d="M8.5722 6.09999L9.9352 4.69999C10.1118 4.52605 10.2521 4.31882 10.348 4.09031C10.444 3.86179 10.4937 3.61651 10.4943 3.36866C10.4948 3.12082 10.4463 2.87531 10.3514 2.64636C10.2565 2.4174 10.1171 2.20953 9.94134 2.03477C9.76559 1.86001 9.55694 1.72183 9.32745 1.62822C9.09796 1.53461 8.85218 1.48743 8.60434 1.4894C8.3565 1.49137 8.11151 1.54247 7.88354 1.63972C7.65557 1.73697 7.44914 1.87846 7.2762 2.05599L5.8862 3.41099L4.8252 2.34999L6.2222 0.987995C6.85735 0.368953 7.71065 0.0248101 8.59756 0.0299955C9.48447 0.0351809 10.3337 0.389278 10.9616 1.0157C11.5894 1.64213 11.9455 2.49053 11.9527 3.37743C11.9599 4.26432 11.6178 5.11842 11.0002 5.75499L9.6312 7.15499L8.5722 6.09999Z"
        fill="#32B6F3"
      />
      <path
        d="M6.7002 4.224L7.4542 3.47C7.52286 3.39631 7.60566 3.33721 7.69766 3.29622C7.78966 3.25523 7.88897 3.23319 7.98967 3.23141C8.09038 3.22963 8.19041 3.24816 8.28379 3.28588C8.37718 3.3236 8.46201 3.37974 8.53323 3.45096C8.60445 3.52218 8.6606 3.60702 8.69832 3.7004C8.73604 3.79379 8.75456 3.89382 8.75279 3.99452C8.75101 4.09523 8.72897 4.19454 8.68798 4.28654C8.64698 4.37854 8.58788 4.46134 8.5142 4.53L7.7592 5.285L6.7002 4.224Z"
        fill="#32B6F3"
      />
      <path
        d="M11.0001 11.75C10.9016 11.7502 10.804 11.7309 10.713 11.6932C10.622 11.6554 10.5395 11.5999 10.4701 11.53L0.470091 1.53C0.337611 1.38782 0.265487 1.19978 0.268916 1.00548C0.272344 0.811175 0.351056 0.62579 0.488469 0.488377C0.625882 0.350964 0.811267 0.272252 1.00557 0.268824C1.19987 0.265396 1.38792 0.337519 1.53009 0.469999L11.5301 10.47C11.6348 10.5749 11.7062 10.7085 11.7351 10.8539C11.7639 10.9993 11.7491 11.15 11.6924 11.2869C11.6357 11.4239 11.5396 11.541 11.4164 11.6234C11.2932 11.7058 11.1483 11.7499 11.0001 11.75Z"
        fill="#32B6F3"
      />
    </svg>
  )
}

export default UnlinkIcon
