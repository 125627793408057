import EngineState from '@store/states/engine.state'
import { EMPTY_PAGE_META } from '@lib/constants/app.constant'
import ActionInterface from '@lib/interfaces/action.interface'
import { SET_ENGINE_PAGE_META } from '@store/actions/engine.action'

const initialState: EngineState = {
  pageMeta: EMPTY_PAGE_META(),
}

function engineReducer(
  prevState: EngineState = initialState,
  action: ActionInterface
) {
  switch (action.type) {
    case SET_ENGINE_PAGE_META:
      return {
        ...prevState,
        pageMeta: action.payload,
      }
    default:
      return prevState
  }
}

export default engineReducer
