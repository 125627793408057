import DateRangeSelect, {
  BASE_DATE_FORMAT,
  DateRangeSelectProps,
} from '@components-composite/date-range-select/DateRangeSelect'
import React, { useState } from 'react'
import { DateRangeTuple } from '@lib/engine-types'

import { transformDateRangeForApi } from './transform-date-range-for-api'
import {
  setTimeRangeAction,
  useTimeRange,
} from '@components/context/time-range'
import { TimeRange } from '@lib/clients/types'

interface Props extends Omit<DateRangeSelectProps, 'onChange' | 'value'> {
  onDateRangeChange: (newDateRange: TimeRange) => void
  defaultDateRange?: DateRangeTuple
  labels?: {
    startText?: string
    endText?: string
  }
}
function DateRangePickerWithCallAPI({
  onDateRangeChange,
  defaultDateRange = [null, null],
  labels = {
    startText: 'From date',
    endText: 'To date',
  },
}: Props) {
  const [dateRange, setDateRange] = useState<DateRangeTuple>(defaultDateRange)

  const { dispatch } = useTimeRange()

  const handleDateChange = (newDateRange: DateRangeTuple) => {
    setDateRange(newDateRange)
    dispatch(setTimeRangeAction(transformDateRangeForApi(newDateRange)))
    onDateRangeChange(transformDateRangeForApi(newDateRange))
    onDateRangeChange(transformDateRangeForApi(newDateRange))
  }
  return (
    <DateRangeSelect
      value={dateRange}
      onChange={handleDateChange}
      startText={BASE_DATE_FORMAT}
      endText={BASE_DATE_FORMAT}
      label={labels.startText}
      endLabel={labels.endText}
    />
  )
}

export default DateRangePickerWithCallAPI
