import React from 'react'

function AssetsIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4.44531C8.72726 4.44531 7.53382 5.07748 6.80994 6.11415C4.88049 6.18526 3.33337 7.77698 3.33337 9.72309C3.33337 11.7142 4.95337 13.3342 6.94448 13.3342H11.1112V12.7786C11.1112 12.5803 11.151 12.3964 11.2088 12.2231H6.94448C5.56615 12.2231 4.44448 11.1014 4.44448 9.72309C4.44448 8.34476 5.56615 7.22309 6.94448 7.22309L7.42192 7.24913L7.59336 6.95291C8.08891 6.09124 9.01115 5.55642 10 5.55642C11.3734 5.55642 12.5248 6.54134 12.7398 7.898L12.8234 8.43186L13.4343 8.35156C13.4926 8.34378 13.5506 8.3342 13.6112 8.3342C14.1689 8.3342 14.6689 8.5733 15.0239 8.95052C15.6811 9.08497 16.2414 9.44815 16.6342 9.95204C16.4725 8.45926 15.2467 7.28137 13.7251 7.22526C13.244 5.58748 11.7495 4.44531 10 4.44531ZM14.4445 10.0009C13.5 10.0009 12.7778 10.7231 12.7778 11.6675V12.2231C12.4445 12.2231 12.2223 12.4453 12.2223 12.7786V15.0009C12.2223 15.3342 12.4445 15.5564 12.7778 15.5564H16.1111C16.4445 15.5564 16.6667 15.3342 16.6667 15.0009V12.7786C16.6667 12.4453 16.4445 12.2231 16.1111 12.2231V11.6675C16.1111 10.7231 15.3889 10.0009 14.4445 10.0009ZM14.4445 10.8342C14.8889 10.8342 15.2778 11.2231 15.2778 11.6675V12.2231H13.6112V11.6675C13.6112 11.2231 14 10.8342 14.4445 10.8342Z"
        fill="#999CAA"
      />
    </svg>
  )
}

export default AssetsIcon
