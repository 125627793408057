"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListSnapshotsVulnerabilities = void 0;
const vulnerability_1 = require("../../models/vulnerability");
const share_1 = require("../share");
__exportStar(require("./types"), exports);
class ListSnapshotsVulnerabilities {
    #snapshots;
    #volumes;
    constructor(parameters) {
        this.#snapshots = parameters.snapshots;
        this.#volumes = parameters.volumes;
    }
    execute(filters, riskIdentificationStrategy) {
        return this.prepareOutput(this.findSnapshotsMatchingFilters(filters, riskIdentificationStrategy), riskIdentificationStrategy);
    }
    prepareOutput(snapshots, riskIdentificationStrategy) {
        return snapshots.map((snapshot) => {
            const snapshotVulnerabilities = this.#getSnapshotVulnerabilities(snapshot);
            return {
                snapshotId: snapshot.awsId,
                accountId: snapshot.awsAccountId,
                volumeId: this.#getSnapshotVolumeId(this.#volumes, snapshot),
                volumeAwsId: this.#getAwsVolumeId(this.#volumes, snapshot),
                region: snapshot.awsRegion,
                vulnerabilitiesList: snapshotVulnerabilities,
                riskVulnerabilities: this.getHighestRiskLevel(snapshotVulnerabilities, riskIdentificationStrategy),
            };
        });
    }
    #getAwsVolumeId(volumes, snapshot) {
        const volume = volumes.find((volume) => volume.id === snapshot.volumeId);
        if (!volume) {
            return '';
        }
        return volume.awsId;
    }
    #getSnapshotVolumeId(volumes, snapshot) {
        const volume = volumes.find((volume) => volume.id === snapshot.volumeId);
        if (!volume) {
            return '';
        }
        return volume.id;
    }
    #getSnapshotVulnerabilities(snapshot) {
        const snapshotVulnerabilities = [];
        if (snapshot.isPubliclyShared) {
            snapshotVulnerabilities.push(vulnerability_1.SnapshotVulnerabilityKind.PUBLIC_PERMISSIONS);
        }
        if (snapshot.isUnencrypted) {
            snapshotVulnerabilities.push(vulnerability_1.SnapshotVulnerabilityKind.UNENCRYPTED);
        }
        return snapshotVulnerabilities;
    }
    findSnapshotsMatchingFilters(filters, riskIdentificationStrategy) {
        return this.#snapshots
            .filter((snapshot) => this.#getSnapshotVulnerabilities(snapshot).length)
            .filter((snapshot) => {
            const vulnerabilitiesTypes = this.#getSnapshotVulnerabilities(snapshot);
            const risks = [
                ...new Set(vulnerabilitiesTypes.map(riskIdentificationStrategy.identifyRiskLevel)),
            ];
            if (!(0, share_1.matchesIncludeFilter)(filters.accountIds, snapshot.awsAccountId)) {
                return false;
            }
            if (!(0, share_1.matchesIncludeFilter)(filters.regions, snapshot.awsRegion)) {
                return false;
            }
            if (filters.snapshotVulnerabilityTypes) {
                if (!filters.snapshotVulnerabilityTypes.some((vulnerability) => vulnerabilitiesTypes.includes(vulnerability))) {
                    return false;
                }
            }
            if (filters.riskType?.length &&
                !filters.riskType?.some((el) => risks.includes(el))) {
                return false;
            }
            if (filters.idSearch &&
                !this.#matchesBySearchValue(filters.idSearch, snapshot)) {
                return false;
            }
            return true;
        });
    }
    getHighestRiskLevel(vulnerabilities, riskIdentificationStrategy) {
        const risks = vulnerabilities.map((vulnerability) => ({
            vulnerability,
            risk: riskIdentificationStrategy.identifyRiskLevel(vulnerability),
        }));
        return risks.reduce((accum, curr) => Math.max(accum, curr.risk), 0);
    }
    #matchesBySearchValue(searchValue, snapshot) {
        const volumes = this.#volumes.filter((volume) => volume.id === snapshot.volumeId);
        const snapshotNameMatches = snapshot.name
            .toLowerCase()
            .includes(searchValue);
        const volumeAwsIdMatches = volumes.some((volume) => volume.awsId.toLowerCase().includes(searchValue));
        const matchesAwsId = snapshot.awsId.includes(searchValue);
        const matchesAccountId = snapshot.awsAccountId.includes(searchValue);
        const volumeVulnerabilities = this.#getSnapshotVulnerabilities(snapshot);
        const matchesVulnerabilities = volumeVulnerabilities.some((vulnerability) => vulnerability.toLowerCase().includes(searchValue));
        return (snapshotNameMatches ||
            matchesAccountId ||
            volumeAwsIdMatches ||
            matchesAwsId ||
            matchesVulnerabilities);
    }
}
exports.ListSnapshotsVulnerabilities = ListSnapshotsVulnerabilities;
