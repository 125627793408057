import BaseDataConstant from '@lib/constants/data/base-data.constant'
import ValueInterface from '@lib/interfaces/value.interface'

export interface ReportOriginData extends ValueInterface {}

class ReportOriginConstantInner extends BaseDataConstant<ReportOriginData> {
  get ORIGIN_UNDEFINED(): ReportOriginData {
    return {
      name: '',
      value: -1,
      label: '',
    }
  }

  get ORIGIN_RECOVERY_POINT(): ReportOriginData {
    return {
      name: 'recoveryPoint',
      value: 0,
      label: 'Recovery Point',
    }
  }

  get ORIGIN_LOCAL(): ReportOriginData {
    return {
      name: 'local',
      value: 1,
      label: 'Local',
    }
  }
}

const ReportOriginConstant = ReportOriginConstantInner.build(
  ReportOriginConstantInner,
  'ORIGIN_'
)

export default ReportOriginConstant
