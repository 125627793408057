import { GrpcClient } from '@lib/clients/grpc-client'
import { TenantSettingsClient } from '@lib/clients/tenant-settings'
import { SystemHelper } from '@lib/helpers'
import { useEffect } from 'react'
import { useGetTenantSettingsStoreActions } from './tenant-settings.store'

const client = new TenantSettingsClient()

export const useFetchTenantSettings = () => {
  const { setIsTenantSettingsLoading, setTenantSettingsModel } =
    useGetTenantSettingsStoreActions()

  const isTokenAvailable = GrpcClient.isTokenAvailable()

  useEffect(() => {
    const globalDomains = [
      process.env.NEXT_PUBLIC_LOGIN_DOMAIN,
      process.env.NEXT_PUBLIC_TENANT_CREATION_DOMAIN,
    ]
    const isGlobalHost = globalDomains.includes(window.location.hostname)

    async function fetchTenantSettings() {
      if (!isTokenAvailable) {
        return
      }
      try {
        setIsTenantSettingsLoading(true)
        const result = await client.getTenantSettings()
        setTenantSettingsModel(result)
      } catch (error: any) {
        if ('message' in error) {
          SystemHelper.sendSentryIfProd(
            `useFetchTenantSettings: ${error.message} (${error.code})`
          )
        }
      } finally {
        setIsTenantSettingsLoading(false)
      }
    }

    if (isGlobalHost) {
      return
    }

    fetchTenantSettings()
  }, [isTokenAvailable])
}
