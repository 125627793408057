import PreloaderConstants from '@lib/constants/preloader.constant'

export const ENABLE_PRELOADER = 'ENABLE_PRELOADER'
export const DISABLE_PRELOADER = 'DISABLE_PRELOADER'

export const enablePreloaderAction = (key: PreloaderConstants) => ({
  type: ENABLE_PRELOADER,
  payload: key,
})

export const disablePreloaderAction = (key: PreloaderConstants) => ({
  type: DISABLE_PRELOADER,
  payload: key,
})
