import CfnModel from '@lib/models/cfn.model'
import NumHelper from '@lib/helpers/num.helper'
import TypeHelper from '@lib/helpers/type.helper'
import { CfnStatusConstant } from '@lib/constants/cfn-status.constant'

abstract class CfnFactory {
  public static buildFromGrpc(params: any): CfnModel {
    return new CfnModel({
      id: NumHelper.numberHash(params.status),
      innerId: TypeHelper.stringValDef(params.status, ''),
      arn: TypeHelper.stringValDef(params.description?.arn, ''),
      region: TypeHelper.stringValDef(params.description?.region, ''),
      status: TypeHelper.enumValDef(
        params.status,
        CfnStatusConstant.NOTINSTALLED,
        CfnStatusConstant
      ),
      isEmpty: false,
    })
  }

  public static buildEmpty() {
    return new CfnModel({
      id: '',
      innerId: '',
      arn: '',
      region: '',
      status: CfnStatusConstant.NOTINSTALLED,
      isEmpty: true,
    })
  }
}

export default CfnFactory
