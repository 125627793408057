import React from 'react'

function BlueVerticalDotsIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.375 3.75C9.375 4.09518 9.65482 4.375 10 4.375C10.3452 4.375 10.625 4.09518 10.625 3.75C10.625 3.40482 10.3452 3.125 10 3.125C9.65482 3.125 9.375 3.40482 9.375 3.75Z"
        fill="#32B6F3"
      />
      <path
        d="M9.375 3.75C9.375 4.09518 9.65482 4.375 10 4.375C10.3452 4.375 10.625 4.09518 10.625 3.75C10.625 3.40482 10.3452 3.125 10 3.125C9.65482 3.125 9.375 3.40482 9.375 3.75Z"
        stroke="#32B6F3"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M9.375 16.25C9.375 16.5952 9.65482 16.875 10 16.875C10.3452 16.875 10.625 16.5952 10.625 16.25C10.625 15.9048 10.3452 15.625 10 15.625C9.65482 15.625 9.375 15.9048 9.375 16.25Z"
        fill="#32B6F3"
      />
      <path
        d="M9.375 16.25C9.375 16.5952 9.65482 16.875 10 16.875C10.3452 16.875 10.625 16.5952 10.625 16.25C10.625 15.9048 10.3452 15.625 10 15.625C9.65482 15.625 9.375 15.9048 9.375 16.25Z"
        stroke="#32B6F3"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M9.375 10C9.375 10.3452 9.65482 10.625 10 10.625C10.3452 10.625 10.625 10.3452 10.625 10C10.625 9.65482 10.3452 9.375 10 9.375C9.65482 9.375 9.375 9.65482 9.375 10Z"
        fill="#32B6F3"
      />
      <path
        d="M9.375 10C9.375 10.3452 9.65482 10.625 10 10.625C10.3452 10.625 10.625 10.3452 10.625 10C10.625 9.65482 10.3452 9.375 10 9.375C9.65482 9.375 9.375 9.65482 9.375 10Z"
        stroke="#32B6F3"
        strokeWidth="2"
        strokeLinecap="square"
      />{' '}
    </svg>
  )
}

export default BlueVerticalDotsIcon
