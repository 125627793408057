import RedStackModel from '@lib/models/red-stack.model'
import VpcModel from '@lib/models/vpc.model'
import ArrHelper from '@lib/helpers/arr.helper'
import ValueInterface from '@lib/interfaces/value.interface'
import CfnModel from '@lib/models/cfn.model'
import AwsAccountInfoModel from '@lib/models/aws-account-info.model'
import AvailabilityZoneModel from '@lib/models/availability-zone.model'
import AwsSecurityGroupsModel from '@lib/models/aws-security-groups.model'
import { StoreReturnType } from '@store/store'

export const getCfTemplate = (state: any): string =>
  state.rexState.deploymentFiles.cfTemplate
export const getTfArchiveUrl = (state: any): string =>
  state.rexState.deploymentFiles.tfArchiveUrl
export const getAllRedStacksList = (state: any): Array<RedStackModel> =>
  state.rexState.allRedStacks
export const getPossibleVPCList = (state: any): Array<VpcModel> =>
  state.rexState.possibleVPCList
export const getPossibleVPCListByAccount = (state: any): Array<VpcModel> =>
  state.rexState.possibleVPCList
export const getCFLink = (state: any): string => state.rexState.cfLink
export const getIamRole = (state: any): string =>
  state.rexState.allRedStacks[0]?.iamRole ?? ''
export const getCfn = (state: any): CfnModel => state.rexState.cfn

export const getAllActiveRedStacksList = (state: any): Array<RedStackModel> =>
  state.rexState.allRedStacks.filter((r: RedStackModel) => r.isActive)

export const getAllInactiveRedStacksList = (state: any): Array<RedStackModel> =>
  state.rexState.allRedStacks.filter((r: RedStackModel) => r.isInactive)

export const getAllNotDeletedRedStacksList = (
  state: StoreReturnType
): Array<RedStackModel> =>
  state.rexState.allRedStacks.filter(
    (rs: RedStackModel) => rs.isActive || rs.isInactive
  )

export const getAllActiveRedStackRegions = (state: any): Array<string> => {
  const allActiveRedStackRegions = getAllActiveRedStacksList(state).map(
    (r: RedStackModel) => r.awsRegion
  )
  return ArrHelper.unique(allActiveRedStackRegions)
}
// eslint-disable-next-line max-len
export const getAllActiveRedStackRegionsForAccount =
  (accountId: string) =>
  (state: any): Array<string> => {
    const allRedStacks = getAllActiveRedStacksList(state)
    return allRedStacks
      .filter((r: RedStackModel) => r.awsAccount === accountId)
      .map((r: RedStackModel) => r.awsRegion)
  }

export const getPossibleRegionsList = (state: any): Array<ValueInterface> =>
  state.rexState.possibleRegionsList

export const getAccountInfoList = (state: any): Array<AwsAccountInfoModel> =>
  state.rexState.awsAccountInfoList

export const getListAwsAccountsList = (
  state: any
): Array<AwsAccountInfoModel> => state.rexState.listAwsAccountsList

export const getListAvailabilityZonesList = (
  state: any
): Array<AvailabilityZoneModel> => state.rexState.listAvailabilityZonesList

export const getListAwsSecurityGroupsList = (
  state: any
): Array<AwsSecurityGroupsModel> => state.rexState.listAwsSecurityGroupsList

export const getIsRedStackExist = (state: any): boolean =>
  state.rexState.isRedStackExist
