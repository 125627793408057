"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhasedProgress = void 0;
class PhasedProgress {
    #phases;
    #currentPhase;
    constructor(parameters) {
        const { phases, currentPhase } = parameters;
        this.#phases = phases;
        this.#currentPhase = currentPhase;
    }
    get progress() {
        return (this.#currentPhase / this.#phases.length) * 100;
    }
}
exports.PhasedProgress = PhasedProgress;
