"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListDetailsScansS3 = void 0;
const types_1 = require("../../models/scans/malware/types");
__exportStar(require("./types"), exports);
class ListDetailsScansS3 {
    threatsForAsset;
    assetItems;
    bucketName;
    assetItemsMap = {};
    lastScan;
    constructor({ threatsForAsset, assetItems, bucketName, lastScan, }) {
        this.threatsForAsset = threatsForAsset;
        this.assetItems = assetItems;
        this.bucketName = bucketName;
        this.lastScan = lastScan;
    }
    execute(filters) {
        return this.prepareOutput({
            filters,
        });
    }
    prepareOutput({ filters, }) {
        for (const assetItem of this.assetItems) {
            this.assetItemsMap[assetItem.id] = {
                scanTime: new Date(0),
                threatCount: 0,
                malwareStatus: types_1.MalwareScanStatus.CLEAN,
                assetItemId: assetItem.id,
                assetItemType: assetItem.selectors[0].kind,
                assetItemPath: assetItem.selectors[0].value,
            };
        }
        const { bucketMalwareStatus, bucketLastThreat, threatIdsForAsset } = this.iterateThreats();
        const output = Object.values(this.assetItemsMap);
        if (bucketMalwareStatus !== null) {
            output.push({
                scanTime: bucketLastThreat,
                threatCount: threatIdsForAsset.size,
                malwareStatus: bucketMalwareStatus,
                assetItemId: '',
                assetItemType: 0,
                assetItemPath: this.bucketName,
            });
        }
        else if (this.lastScan && 'assetId' in this.lastScan.scanTarget.target) {
            output.push({
                scanTime: this.lastScan.createdAt,
                threatCount: this.lastScan.threatIds.length,
                malwareStatus: this.lastScan.status,
                assetItemId: '',
                assetItemType: 0,
                assetItemPath: this.bucketName,
            });
        }
        const filteredScansOutput = this.findScanMatchingFilters(output, filters);
        return {
            scans: filteredScansOutput,
        };
    }
    iterateThreats() {
        const assetItemsMap = this.assetItemsMap;
        const threatIdsForAsset = new Set();
        let bucketMalwareStatus = null;
        let bucketLastThreat = new Date(0);
        for (const threat of this.threatsForAsset) {
            const threadSource = threat.source;
            const assetItemId = threadSource?.assetItem?.assetItemId;
            if (assetItemId) {
                const assetItem = this.assetItems.find((item) => item.id === assetItemId);
                if (!assetItem) {
                    continue;
                }
                if (threat.createdAt < assetItemsMap[assetItem.id].scanTime) {
                    continue;
                }
                assetItemsMap[assetItem.id].malwareStatus = types_1.MalwareScanStatus.INFECTED;
                assetItemsMap[assetItem.id].threatCount += 1;
            }
            else {
                if (threat.createdAt < bucketLastThreat) {
                    continue;
                }
                bucketLastThreat = threat.createdAt;
                bucketMalwareStatus = types_1.MalwareScanStatus.INFECTED;
                threatIdsForAsset.add(threat.id);
            }
        }
        return {
            threatIdsForAsset,
            bucketLastThreat,
            bucketMalwareStatus,
        };
    }
    findScanMatchingFilters(scansOutput, filters) {
        return scansOutput.filter((scan) => {
            if (!filters.search) {
                return true;
            }
            return scan.assetItemPath
                .toLowerCase()
                .includes(filters.search.toLowerCase());
        });
    }
}
exports.ListDetailsScansS3 = ListDetailsScansS3;
