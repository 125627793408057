import NumHelper from '@lib/helpers/num.helper'
import TypeHelper from '@lib/helpers/type.helper'
import AvailabilityZoneModel from '@lib/models/availability-zone.model'

abstract class AvailabilityZoneFactory {
  public static buildFromGrpc(params: any): AvailabilityZoneModel {
    return new AvailabilityZoneModel({
      id: NumHelper.numberHash(params.id),
      innerId: TypeHelper.stringValDef(params.id, ''),
      azId: TypeHelper.stringValDef(params.id, ''),
      azName: TypeHelper.stringValDef(params.name, ''),
    })
  }

  public static buildEmpty() {
    return new AvailabilityZoneModel({
      id: '',
      innerId: '',
      azId: '',
      azName: '',
    })
  }
}

export default AvailabilityZoneFactory
