"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElastioRecoveryPoint = void 0;
const aws_backup_1 = require("../aws-backup");
const types_1 = require("./types");
const backup_1 = require("../../backup");
class ElastioRecoveryPoint extends aws_backup_1.AwsBackup {
    #ccRpId;
    #fsCheckStatus;
    #malwareCheckStatus;
    #ransomwareCheckStatus;
    #ccId;
    #vault;
    #jobId;
    #kind;
    #status;
    #deletionStatus;
    #sourceAssetId;
    #backupEntities;
    #correspondingBackup;
    #backupPolicyName;
    #retentionPolicyName;
    #threatIds;
    #scanIds;
    #sizeInfo;
    constructor(parameters) {
        const { ccRpId, ccId, vault, jobId, kind, status, deletionStatus, ransomwareCheckStatus, sourceAssetId, backupEntities, correspondingBackup, backupPolicyName, retentionPolicyName, threatIds, scanIds, sizeInfo, fsCheckStatus, malwareCheckStatus, ...backupParameters } = parameters;
        super(backupParameters);
        this.#ccRpId = ccRpId;
        this.#ccId = ccId;
        this.#vault = vault;
        this.#jobId = jobId;
        this.#fsCheckStatus = fsCheckStatus;
        this.#malwareCheckStatus = malwareCheckStatus;
        this.#ransomwareCheckStatus = ransomwareCheckStatus;
        this.#kind = kind;
        this.#status = status;
        this.#deletionStatus = deletionStatus;
        this.#sourceAssetId = sourceAssetId;
        this.#backupEntities = Object.freeze([...backupEntities]);
        this.#correspondingBackup = Object.freeze({ ...correspondingBackup });
        this.#backupPolicyName = backupPolicyName;
        this.#retentionPolicyName = retentionPolicyName;
        this.#threatIds = Object.freeze([...threatIds]);
        this.#scanIds = Object.freeze([...scanIds]);
        this.#sizeInfo = sizeInfo;
    }
    get ccRpId() {
        return this.#ccRpId;
    }
    get ccId() {
        return this.#ccId;
    }
    get vault() {
        return this.#vault;
    }
    get jobId() {
        return this.#jobId;
    }
    get kind() {
        return this.#kind;
    }
    get status() {
        return this.#status;
    }
    get deletionStatus() {
        return this.#deletionStatus;
    }
    get sourceAssetId() {
        return this.#sourceAssetId;
    }
    get backupEntities() {
        return [...this.#backupEntities];
    }
    get correspondingBackup() {
        return { ...this.#correspondingBackup };
    }
    get backupPolicyName() {
        return this.#backupPolicyName;
    }
    get retentionPolicyName() {
        return this.#retentionPolicyName;
    }
    get threatIds() {
        return [...this.#threatIds];
    }
    get scanIds() {
        return [...this.#scanIds];
    }
    set scanIds(newScanIds) {
        this.#scanIds = Object.freeze([...newScanIds]);
    }
    get sizeInfo() {
        return this.#sizeInfo;
    }
    get optimizedSize() {
        return this.sizeInfo.optimizedSize;
    }
    get fsCheckStatus() {
        return this.#fsCheckStatus;
    }
    get malwareCheckStatus() {
        return this.#malwareCheckStatus;
    }
    get ransomwareCheckStatus() {
        return this.#ransomwareCheckStatus;
    }
    get vaultName() {
        return this.#vault.name;
    }
    get summaryCheckStatus() {
        return [
            this.fsCheckStatus,
            this.malwareCheckStatus,
            this.ransomwareCheckStatus,
        ].reduce((acc, curr) => {
            if (curr === types_1.CheckStatus.BAD) {
                return backup_1.SummaryCheckStatus.BAD;
            }
            if (curr === types_1.CheckStatus.GOOD &&
                (acc === backup_1.SummaryCheckStatus.GOOD ||
                    acc === backup_1.SummaryCheckStatus.UNCHECKED)) {
                return backup_1.SummaryCheckStatus.GOOD;
            }
            return acc;
        }, backup_1.SummaryCheckStatus.UNCHECKED);
    }
    get ransomwareIsNotDetected() {
        return this.ransomwareCheckStatus === types_1.CheckStatus.GOOD;
    }
    get malwareIsNotDetected() {
        return this.malwareCheckStatus === types_1.CheckStatus.GOOD;
    }
    get filesystemIsNotCorrupted() {
        return this.fsCheckStatus === types_1.CheckStatus.GOOD;
    }
    get ransomwareIsDetected() {
        return this.ransomwareCheckStatus === types_1.CheckStatus.BAD;
    }
    get malwareIsDetected() {
        return this.malwareCheckStatus === types_1.CheckStatus.BAD;
    }
    get filesystemIsCorrupted() {
        return this.fsCheckStatus === types_1.CheckStatus.BAD;
    }
    get ransomwareIsNotChecked() {
        return this.ransomwareCheckStatus === types_1.CheckStatus.UNCHECKED;
    }
    get malwareIsNotChecked() {
        return this.malwareCheckStatus === types_1.CheckStatus.UNCHECKED;
    }
    get filesystemIsNotChecked() {
        return this.fsCheckStatus === types_1.CheckStatus.UNCHECKED;
    }
    get isMountable() {
        return [
            types_1.ElastioRecoveryPointKind.EBS,
            types_1.ElastioRecoveryPointKind.EC2,
        ].includes(this.kind);
    }
    get isRestorable() {
        return [
            types_1.ElastioRecoveryPointKind.FILE,
            types_1.ElastioRecoveryPointKind.STREAM,
            types_1.ElastioRecoveryPointKind.EBS,
            types_1.ElastioRecoveryPointKind.EC2,
        ].includes(this.kind);
    }
    get isScannable() {
        return [
            types_1.ElastioRecoveryPointKind.FILE,
            types_1.ElastioRecoveryPointKind.EBS,
            types_1.ElastioRecoveryPointKind.EC2,
        ].includes(this.kind);
    }
    get isDeleted() {
        return (this.deletionStatus === types_1.StatusDeletion.HARDDELETE ||
            this.deletionStatus === types_1.StatusDeletion.SOFTDELETE);
    }
}
exports.ElastioRecoveryPoint = ElastioRecoveryPoint;
