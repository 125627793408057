"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalculateBackupCoverageStats = void 0;
class CalculateBackupCoverageStats {
    #vaultStatistics;
    constructor(parameters) {
        this.#vaultStatistics = parameters.vaultStatistics;
    }
    execute() {
        return {
            storageReduction: this.#storageReduction(),
            storageSize: this.#storageSize(),
        };
    }
    #getTotalBytesUploaded() {
        return this.#vaultStatistics.reduce((acc, { bytesUploaded }) => acc + bytesUploaded, 0);
    }
    #getBytesIncluded() {
        return this.#vaultStatistics.reduce((acc, { bytesIncluded }) => acc + bytesIncluded, 0);
    }
    #storageReduction() {
        const included = this.#getBytesIncluded();
        const uploaded = this.#getTotalBytesUploaded();
        return included === 0 ? 0 : ((included - uploaded) / included) * 100;
    }
    #storageSize() {
        return this.#getTotalBytesUploaded();
    }
}
exports.CalculateBackupCoverageStats = CalculateBackupCoverageStats;
