"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ovaBackupFactory = void 0;
const backup_1 = require("../../models/backups/ova/backup");
const types_1 = require("../../models/backups/ova/backup/types");
const ova_backup_provider_1 = require("../../models/ova-backup-provider");
const fishery_1 = require("fishery");
const common_1 = require("./common");
const factories_1 = require("../../use_cases/tests/factories");
const ova_backup_snapshot_1 = require("../../models/backups/ova/backup/ova-backup-snapshot");
const DEFAULT_COLLECTION_SIZE = 10;
class OvaBackupFactory extends fishery_1.Factory {
    withId(id) {
        return this.transient({ id });
    }
}
const snapshot = () => new ova_backup_snapshot_1.OvaBackupSnapshot({
    id: (0, factories_1.uuid)(),
    ccVolumeId: (0, factories_1.uuid)(),
    ovaVolumeId: (0, factories_1.uuid)(),
    backupProviderVolumeId: (0, factories_1.uuid)(),
    allocatedSize: Math.floor(Math.random() * 100000),
    totalSize: Math.floor(Math.random() * 10000),
    malwareCheckStatus: (0, common_1.randomValueFromEnum)(types_1.CheckStatus),
    ransomwareCheckStatus: (0, common_1.randomValueFromEnum)(types_1.CheckStatus),
    createdAt: (0, common_1.date)(),
    updatedAt: (0, common_1.date)(),
});
exports.ovaBackupFactory = OvaBackupFactory.define(({ transientParams }) => {
    return new backup_1.OvaBackup({
        id: transientParams.id ?? (0, factories_1.uuid)(),
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
        ccBackupId: transientParams.ccBackupId ?? (0, factories_1.uuid)(),
        backupProviderBackupId: transientParams.backupProviderBackupId ?? (0, factories_1.uuid)(),
        sourceAssetId: transientParams.sourceAssetId ?? (0, factories_1.uuid)(),
        timestamp: transientParams.timestamp ?? (0, common_1.date)(),
        provider: transientParams.provider ?? (0, common_1.randomValueFromEnum)(ova_backup_provider_1.OvaBackupProvider),
        totalSize: transientParams.totalSize ?? Math.floor(Math.random() * 10000),
        snapshots: transientParams.snapshots ??
            Array.from({ length: DEFAULT_COLLECTION_SIZE }, snapshot),
        scanIds: transientParams.scanIds ??
            Array.from({ length: DEFAULT_COLLECTION_SIZE }, factories_1.uuid),
        threatIds: transientParams.threatIds ??
            Array.from({ length: DEFAULT_COLLECTION_SIZE }, factories_1.uuid),
        malwareCheckStatus: transientParams.malwareCheckStatus ?? (0, common_1.randomValueFromEnum)(types_1.CheckStatus),
        ransomwareCheckStatus: transientParams.ransomwareCheckStatus ??
            (0, common_1.randomValueFromEnum)(types_1.CheckStatus),
    });
});
