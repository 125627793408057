// took from
// blue-stack-libs/catalog-grpc-libs/js/catalog/catalog_pb.d.ts

export enum ReportFilterId {
  ACCOUNT_IDS = 0,
  REGION_NAMES = 1,
  REPORT_ORIGINS = 2,
  HUMAN_READABLE_RECOVERY_POINT_ID = 3,
  PROTECTED_OBJECT = 4,
  STATUSES = 5,
}

export enum FilterIdForReports {
  ACCOUNT_IDS_LIST = 0,
  REGIONS_LIST = 1,
  SCAN_TARGET_KINDS_LIST = 2,
  ASSET_ID_LIST = 3,
  STATUSES_LIST = 4,
  HUMAN_READABLE_RECOVERY_POINT_IDS_LIST = 5,
}
