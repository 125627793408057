import { SelectedVariant } from '@lib/constants/app.constant'
import {
  ProtectNewImmediately,
  SelectedCloudConnectorVariant,
  SnapshotImport,
} from '@lib/constants/policies.constant'
import { IntegrityCheckOptions } from '@lib/constants/integrity-check-options.constant'
import {
  EMPTY_SCAN_WITH_TIMESTAMPS,
  EMPTY_SCHEDULE,
} from '@lib/constants/time.constant'
import ActionInterface from '@lib/interfaces/action.interface'
import {
  RESET_EDIT_SCAN_WITH_TIMESTAMPS,
  RESET_INTEGRITY_SCAN,
  SET_EDIT_INTEGRITY_CHECK,
  SET_EDIT_INTEGRITY_SCAN,
  SET_EDIT_POLICY_NAME,
  SET_EDIT_POLICY_SCHEDULE,
  SET_EDIT_POLICY_TAGS,
  SET_EDIT_POLICY_VAULT_LIST,
  SET_EDIT_PROTECT_IMMEDIATELY,
  SET_EDIT_SCAN_WITH_TIMESTAMPS,
  SET_EDIT_SELECTED_CLOUD_CONNECTORS,
  SET_EDIT_SELECTED_CLOUD_CONNECTORS_VARIANT,
  SET_EDIT_SELECTED_VARIANT,
  SET_EDIT_SKIP_EBS_BACKUP,
  SET_EDIT_SNAPSHOT_IMPORT_VARIANT,
  SET_ENTROPY_DETECTION_CONFIGURATION,
  SET_INTEGRITY_SCAN_OPTION,
  SET_KEEP_DATA_COPY,
} from '@store/actions/edit-policy.action'
import EditPolicyState from '@store/states/edit-policy.state'
import { IntegrityScanOptions } from '@lib/constants/integrity-scan-options.constant'

const initialState: EditPolicyState = {
  policyName: '',
  schedule: EMPTY_SCHEDULE(),
  integrityScan: {
    scanForMalware: true,
    scanForRansomware: true,
  },
  policyTags: [],
  selectedVariant: SelectedVariant.AllEC2Variant,
  snapshotImportVariant: SnapshotImport.SKIP,
  integrityCheck: IntegrityCheckOptions.INTEGRITY_CHECK_FILE_SYSTEM,
  protectNewImmediately: ProtectNewImmediately.DISABLED,
  selectedVaultsListEditPolicy: [],
  skipEbsBackup: false,
  selectedCloudConnectorVariant: SelectedCloudConnectorVariant.ALL,
  selectedCloudConnectors: [],
  integrityScanOption: IntegrityScanOptions.SCAN_WITH_ELASTIO,
  keepDataCopy: {
    keepLastClean: false,
    keepLatestInfected: false,
  },
  scanWithTimestamps: EMPTY_SCAN_WITH_TIMESTAMPS(),
  isEntropyDetectionEnabled: false,
}

function editPolicyReducer(
  prevState = initialState,
  action: ActionInterface
): EditPolicyState {
  switch (action.type) {
    case SET_EDIT_POLICY_NAME:
      return {
        ...prevState,
        policyName: action.payload,
      }

    case SET_EDIT_POLICY_SCHEDULE:
      return {
        ...prevState,
        schedule: action.payload,
      }

    case SET_EDIT_INTEGRITY_SCAN:
      return {
        ...prevState,
        integrityScan: action.payload,
      }

    case SET_INTEGRITY_SCAN_OPTION:
      return {
        ...prevState,
        integrityScanOption: action.payload,
      }

    case SET_KEEP_DATA_COPY:
      return {
        ...prevState,
        keepDataCopy: action.payload,
      }

    case SET_EDIT_SELECTED_VARIANT:
      return {
        ...prevState,
        selectedVariant: action.payload,
      }

    case SET_EDIT_SELECTED_CLOUD_CONNECTORS_VARIANT:
      return {
        ...prevState,
        selectedCloudConnectorVariant: action.payload,
      }

    case SET_EDIT_SELECTED_CLOUD_CONNECTORS:
      return {
        ...prevState,
        selectedCloudConnectors: action.payload,
      }

    case SET_EDIT_POLICY_TAGS:
      return {
        ...prevState,
        policyTags: action.payload,
      }
    case SET_EDIT_INTEGRITY_CHECK:
      return {
        ...prevState,
        integrityCheck: action.payload,
      }

    case SET_EDIT_SNAPSHOT_IMPORT_VARIANT:
      return {
        ...prevState,
        snapshotImportVariant: action.payload,
      }

    case SET_EDIT_PROTECT_IMMEDIATELY:
      return {
        ...prevState,
        protectNewImmediately: action.payload,
      }

    case SET_EDIT_SKIP_EBS_BACKUP:
      return {
        ...prevState,
        skipEbsBackup: action.payload,
      }

    case SET_EDIT_POLICY_VAULT_LIST:
      return {
        ...prevState,
        selectedVaultsListEditPolicy: action.payload,
      }

    case SET_EDIT_SCAN_WITH_TIMESTAMPS:
      return {
        ...prevState,
        scanWithTimestamps: action.payload,
      }

    case RESET_EDIT_SCAN_WITH_TIMESTAMPS:
      return {
        ...prevState,
        scanWithTimestamps: EMPTY_SCAN_WITH_TIMESTAMPS(),
      }

    case SET_ENTROPY_DETECTION_CONFIGURATION:
      return {
        ...prevState,
        isEntropyDetectionEnabled: action.payload,
      }

    case RESET_INTEGRITY_SCAN:
      return {
        ...prevState,
        integrityScan: {
          scanForMalware: true,
          scanForRansomware: true,
        },
        integrityCheck: IntegrityCheckOptions.INTEGRITY_CHECK_FILE_SYSTEM,
        integrityScanOption: IntegrityScanOptions.SCAN_WITH_ELASTIO,
        keepDataCopy: {
          keepLastClean: false,
          keepLatestInfected: false,
        },
      }

    default:
      return prevState
  }
}

export default editPolicyReducer
