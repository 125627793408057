"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RansomwareScanBackup = exports.RansomwareScanTarget = exports.RansomwareScan = void 0;
const scan_1 = require("../scan");
const types_1 = require("./types");
class RansomwareScan extends scan_1.Scan {
    #scanTarget;
    #status;
    #jobId;
    #finishedAt;
    #summary;
    #threatIds;
    constructor(parameters) {
        const { scanTarget, status, jobId, finishedAt, summary, threatIds, ...assetParameters } = parameters;
        super(assetParameters);
        this.#scanTarget = scanTarget;
        this.#status = status;
        this.#jobId = jobId;
        this.#finishedAt = finishedAt;
        this.#summary = summary;
        this.#threatIds = Object.freeze([...threatIds]);
    }
    get scanTarget() {
        return this.#scanTarget;
    }
    get status() {
        return this.#status;
    }
    get jobId() {
        return this.#jobId;
    }
    get finishedAt() {
        return this.#finishedAt;
    }
    get summary() {
        return this.#summary;
    }
    get threatIds() {
        return [...this.#threatIds];
    }
    set threatIds(newThreatIds) {
        this.#threatIds = Object.freeze([...newThreatIds]);
    }
    get infectedFilesCount() {
        if (!this.#summary) {
            return 0;
        }
        return this.#summary.totalRansomware;
    }
    get scannedFilesCount() {
        if (!this.#summary) {
            return 0;
        }
        return this.#summary.totalFiles;
    }
    get targetIsBackup() {
        return this.scanTarget.target instanceof RansomwareScanBackup;
    }
    get isInfected() {
        return this.status === types_1.RansomwareScanStatus.INFECTED;
    }
    get isClean() {
        return !this.isInfected;
    }
}
exports.RansomwareScan = RansomwareScan;
class RansomwareScanTarget {
    #target;
    constructor(target) {
        this.#target = target;
    }
    get target() {
        return this.#target;
    }
    get assetId() {
        return this.#target.assetId;
    }
}
exports.RansomwareScanTarget = RansomwareScanTarget;
class RansomwareScanBackup {
    #backupId;
    #source;
    #provider;
    #timestamp;
    #backupStatus;
    constructor(parameters) {
        this.#backupId = parameters.backupId;
        this.#source = parameters.source;
        this.#provider = parameters.provider;
        this.#timestamp = parameters.timestamp;
        this.#backupStatus = parameters.backupStatus;
    }
    get backupId() {
        return this.#backupId;
    }
    get source() {
        return this.#source;
    }
    get provider() {
        return this.#provider;
    }
    get timestamp() {
        return this.#timestamp;
    }
    get backupStatus() {
        return this.#backupStatus;
    }
    get assetId() {
        if (this.#source.asset) {
            return this.#source.asset.backupAssetId;
        }
        else {
            return this.#source.assetItem?.backupAssetId;
        }
    }
}
exports.RansomwareScanBackup = RansomwareScanBackup;
