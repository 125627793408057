/* eslint-disable import/no-extraneous-dependencies */
import { RexPromiseClient } from 'blue-stack-libs/rex-grpc-libs/js/rex/rex_grpc_web_pb'
import { ListRequest } from 'blue-stack-libs/users-grpc-libs/js/users/messages/pat_pb'
import { CloudConnector } from 'blues-corejs/dist/models'
import { GrpcClient } from '../grpc-client/grpc-client'
import { CloudConnectorTransformer } from './cloud-connector-transformer'

export class RexClient extends GrpcClient<RexPromiseClient> {
  #rexClient: RexPromiseClient

  constructor(hostName = '') {
    super()
    this.#rexClient = this.getClient(hostName)
  }

  protected innerClientTypeId(): string {
    return 'RexClient'
  }

  protected initClient(hostName: string): RexPromiseClient {
    return new RexPromiseClient(hostName, null, null)
  }

  async listGetAllRedstacks(): Promise<Array<CloudConnector>> {
    const request = new ListRequest()

    const response = (
      await this.retryGrpcCall(
        () => this.#rexClient.getAllRedstacks(request, this.metadata()),
        {
          requestName: 'RexPromiseClient/getAllRedstacks',
        }
      )
    ).toObject()

    return response.redStacksList.map((cloudConnector) =>
      new CloudConnectorTransformer(cloudConnector).transform()
    )
  }
}
