import React, { memo, ReactNode } from 'react'
import ConfigureView from '@components-composite/configure-view/ConfigureView'
import FullStory from '@components-complex/layouts/sections/full-story/FullStory'
import Image from 'next/image'
import ZeWebMessenger from '@components-complex/layouts/sections/ze-web-messenger/ze-web-messenger'

interface Props {
  children: ReactNode
}

function DeploymentLayout({ children }: Props) {
  return (
    <div className="wrap-1615376449040 jsDeploymentLayout jsLayout">
      <div className="deploymentContent">
        <div className="topLogoLabel">
          <div className="topLogoWrap">
            <Image
              className="topLogoImg"
              width={36}
              height={36}
              src="/images/logo.svg"
            />
            <div className="topLogoText">elastio</div>
          </div>
        </div>

        <div className="obContentWrap">{children}</div>

        <ZeWebMessenger />
        <FullStory />
        <ConfigureView />
      </div>
    </div>
  )
}

export default memo(DeploymentLayout)
