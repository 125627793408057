/* eslint-disable import/no-extraneous-dependencies */
import TimeHelper from '@lib/helpers/time.helper'
import { Scan as MalwareScanProto } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/scans/malware/scan_pb'
import { MalwareScan } from 'blues-corejs/dist'
import {
  Summary,
  Provider,
  MalwareScanStatus,
  BackupSource,
} from 'blues-corejs/dist/models/scans/malware/types'
import {
  MalwareScanBackup,
  MalwareScanTarget,
} from 'blues-corejs/dist/models/scans/malware'

type MalwareScanPt = MalwareScanProto.AsObject
const ProvidersPt = MalwareScanProto.ScanTarget.Backup.Provider

export class MalwareScanTransformer {
  #scan: MalwareScanPt

  constructor(scan: MalwareScanPt) {
    this.#scan = scan
  }

  transform(): MalwareScan {
    return this.#transformMalwareScan(this.#scan)
  }

  #transformMalwareScan(scan: MalwareScanPt): MalwareScan {
    return new MalwareScan({
      id: scan.id,
      jobId: scan.jobId,
      threatIds: scan.threatIdsList,
      status: this.#convertStatus(scan.status),
      summary: this.#convertSummary(scan.summary),
      updatedAt: TimeHelper.secondsToDate(scan.updatedAt?.seconds),
      scanTarget: this.#convertScanTarget(
        scan.scanTarget as MalwareScanProto.ScanTarget.AsObject
      ),
      createdAt: TimeHelper.secondsToDate(scan.createdAt?.seconds),
      finishedAt: TimeHelper.secondsToDate(scan.finishedAt?.seconds),
    })
  }

  // Scan target can not be undefined
  #convertScanTarget(
    scanTarget: MalwareScanProto.ScanTarget.AsObject
  ): MalwareScanTarget {
    const { asset, assetItem, backup } = scanTarget

    if (asset) {
      return new MalwareScanTarget({
        assetId: asset.id,
      })
    }

    if (assetItem) {
      return new MalwareScanTarget({
        assetItemId: assetItem.id,
        assetId: assetItem.assetId,
      })
    }

    if (backup) {
      const sourceAssetId = backup.source?.assetId?.id

      const sourceAssetItemId = backup.source?.assetItemId?.id

      const backupSource: Partial<BackupSource> = {}

      if (sourceAssetId) {
        backupSource.asset = {
          backupAssetId: sourceAssetId,
        }
      }

      if (sourceAssetItemId) {
        backupSource.assetItem = {
          backupAssetItemId: sourceAssetItemId,
          backupAssetId: backup.source?.assetItemId?.assetId as string,
        }
      }

      return new MalwareScanTarget(
        new MalwareScanBackup({
          backupId: backup.id,
          provider: this.#convertBackupProvider(backup.provider),
          source: backupSource as BackupSource,
          timestamp: TimeHelper.secondsToDate(backup.timestamp?.seconds),
        })
      )
    }

    throw new Error('Invalid scan target')
  }

  #convertBackupProvider(
    provider: MalwareScanProto.ScanTarget.Backup.Provider
  ): Provider {
    const mapping: Record<
      MalwareScanProto.ScanTarget.Backup.Provider,
      Provider
    > = {
      [ProvidersPt.PROVIDER_AWS]: Provider.AWS,
      [ProvidersPt.PROVIDER_ELASTIO]: Provider.ELASTIO,
      [ProvidersPt.OVA_VEEAM]: Provider.OVA_VEEAM,
      [ProvidersPt.OVA_COMMVAULT]: Provider.OVA_COMMVAULT,
      [ProvidersPt.OVA_RUBRIK]: Provider.OVA_RUBRIK,
      [ProvidersPt.OVA_COHESITY]: Provider.OVA_COHESITY,
      [ProvidersPt.OVA_VERITAS_NET_BACKUP]: Provider.OVA_VERITAS_NET_BACKUP,
      [ProvidersPt.OVA_AWS_BACKUP_VMWARE]: Provider.OVA_AWS_BACKUP_VMWARE,
    }

    return mapping[provider]
  }

  #convertSummary(summary?: MalwareScanProto.Summary.AsObject): Summary {
    if (!summary) {
      return {
        errors: 0,
        totalFiles: 0,
        cleanFiles: 0,
        corruptedFiles: 0,
        encryptedFiles: 0,
        incompleteFiles: 0,
        infectedFiles: 0,
        serverErrors: 0,
        suspiciousFiles: 0,
      }
    }

    return {
      errors: summary.errors?.value ?? 0,
      totalFiles: summary.totalFiles?.value ?? 0,
      cleanFiles: summary.cleanFiles?.value ?? 0,
      corruptedFiles: summary.corruptedFiles?.value ?? 0,
      encryptedFiles: summary.encryptedFiles?.value ?? 0,
      incompleteFiles: summary.incompleteFiles?.value ?? 0,
      infectedFiles: summary.infectedFiles?.value ?? 0,
      serverErrors: summary.serverErrors?.value ?? 0,
      suspiciousFiles: summary.suspiciousFiles?.value ?? 0,
    }
  }

  #convertStatus(status: MalwareScanProto.Status): MalwareScanStatus {
    const mapping = {
      [MalwareScanProto.Status.STATUS_CLEAN]: MalwareScanStatus.CLEAN,
      [MalwareScanProto.Status.STATUS_INFECTED]: MalwareScanStatus.INFECTED,
    }

    return mapping[status]
  }
}
