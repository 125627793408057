import { ScheduledCcJob } from '@features/scheduled-jobs-monitoring/models'
import {
  ClientSideJobStatus,
  JobMessages,
} from '@features/scheduled-jobs-monitoring/use-cases/constants'

interface ScheduledCcJobsClient {
  get(jobId: string): Promise<ScheduledCcJob>
}

interface ScheduledCcJobRepository {
  insert(backup: ScheduledCcJob): Promise<void>
}

interface Notifier {
  info(text: string): void
}

export class ScheduleCcJobUseCase {
  constructor(
    private repository: ScheduledCcJobRepository,
    private client: ScheduledCcJobsClient,
    private notifier: Notifier
  ) {}

  async execute(jobId: string): Promise<void> {
    const job = await this.client.get(jobId)
    try {
      await this.repository.insert(job)
      this.notifier.info(
        JobMessages[job.kind][ClientSideJobStatus.INITIALIZING]
      )
    } catch (error) {
      const err = error as Error
      throw new Error(`Failed to execute job with ID ${''}: ${err.message}`)
    }
  }
}
