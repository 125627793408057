import ActionInterface from '@lib/interfaces/action.interface'
import ReportPeriodInterface from '@lib/interfaces/report-period.interface'
import ReportDateRangeConstant, {
  getReportDateRangeName,
} from '@lib/constants/report-date-range.constant'
import TimeHelper from '@lib/helpers/time.helper'
import IscanReportsState from '@store/states/iscan-reports.state'
import {
  Kind,
  ListScansByFiltersReqFilterParams,
} from '@lib/clients/scans/types'
import {
  SET_ISCAN_REPORTS_PERIOD,
  SET_ISCAN_REPORTS_POSSIBLE_FILTERS,
  SET_ISCAN_REPORTS_POSSIBLE_RP_FILTERS,
  SET_ISCAN_REPORTS_SELECTED_FILTERS,
  SET_ISCAN_REPORTS_SELECTED_RP_FILTERS,
  SET_ISCAN_REPORTS_SELECTED_RP_FILTERS_FOR_API,
} from '@store/actions'

export const DEFAULT_ISCAN_REPORT_PERIOD: ReportPeriodInterface = {
  period: {
    name: getReportDateRangeName(ReportDateRangeConstant.ThisWeek),
    value: ReportDateRangeConstant.ThisWeek,
  },
  dateRange: TimeHelper.currentWeekDateRange(),
}

export const DEFAULT_SELECTED_FILTERS_FOR_API: ListScansByFiltersReqFilterParams =
  {
    accountIdsList: [],
    regionsList: [],
    scanTargetKindsList: [],
    humanReadableRecoveryPointIdsList: [],
    assetIdList: [],
    statusesList: [],
    kindsList: [Kind.MALWARE, Kind.RANSOMWARE],
    timeRange: TimeHelper.transformDateRangeForApi(
      DEFAULT_ISCAN_REPORT_PERIOD.dateRange
    ),
  }

const INITIAL_STATE: IscanReportsState = {
  reportPeriod: DEFAULT_ISCAN_REPORT_PERIOD,
  possibleFilters: [],
  selectedFilters: [],
  possibleRPFilters: [],
  selectedRPFilters: '',
  selectedFiltersForApi: DEFAULT_SELECTED_FILTERS_FOR_API,
}

function iscanReportsReducer(
  prevState = INITIAL_STATE,
  action: ActionInterface
) {
  switch (action.type) {
    case SET_ISCAN_REPORTS_PERIOD:
      return {
        ...prevState,
        reportPeriod: action.payload,
      }

    case SET_ISCAN_REPORTS_POSSIBLE_FILTERS:
      return {
        ...prevState,
        possibleFilters: action.payload,
      }

    case SET_ISCAN_REPORTS_SELECTED_FILTERS:
      return {
        ...prevState,
        selectedFilters: action.payload,
      }

    case SET_ISCAN_REPORTS_POSSIBLE_RP_FILTERS:
      return {
        ...prevState,
        possibleRPFilters: action.payload,
      }

    case SET_ISCAN_REPORTS_SELECTED_RP_FILTERS:
      return {
        ...prevState,
        selectedRPFilters: action.payload,
      }

    case SET_ISCAN_REPORTS_SELECTED_RP_FILTERS_FOR_API:
      return {
        ...prevState,
        selectedFiltersForApi: action.payload,
      }

    default:
      return prevState
  }
}

export default iscanReportsReducer
