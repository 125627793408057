import BaseModel from '@lib/models/base-model/base.model'
import TimeHelper from '@lib/helpers/time.helper'
import TimeFormatConstants from '@lib/constants/time-format.constant'
import { Moment } from 'moment'

class SessionModel extends BaseModel {
  public location: string

  public agent: string

  public date: Moment

  public dateFormatted: string

  public ip: string

  public device: string

  constructor(readonly params: any = {}) {
    super(params)

    // base
    this.location = params.location
    this.agent = params.agent
    this.date = params.date
    this.ip = params.ip
    this.device = params.device

    // extra
    this.dateFormatted = TimeHelper.momentFormat(
      this.date,
      TimeFormatConstants.SHORT_DATETIME_FORMAT
    )
  }
}

export default SessionModel
