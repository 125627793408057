import React from 'react'
import ToastMessage from '@components-simple/toast-message/ToastMessage'
import { toast, ToastOptions } from 'react-toastify'

export const TOAST_CONFIGS: ToastOptions = {
  position: 'bottom-right',
  className: 'customToast',
}

abstract class ToastHelper {
  public static success(message: string, header?: string) {
    toast.success(
      <ToastMessage
        variant="success"
        messageText={message}
        headerText={header}
      />,
      TOAST_CONFIGS
    )
  }

  public static info(message: string, header?: string) {
    toast.info(
      <ToastMessage variant="info" messageText={message} headerText={header} />,
      TOAST_CONFIGS
    )
  }

  public static warning(message: string, header?: string) {
    toast.warning(
      <ToastMessage
        variant="warning"
        messageText={message}
        headerText={header}
      />,
      TOAST_CONFIGS
    )
  }

  public static error(message: string, header?: string) {
    toast.error(
      <ToastMessage
        variant="error"
        messageText={message}
        headerText={header}
      />,
      TOAST_CONFIGS
    )
  }
}

export default ToastHelper
