import ServerErrorConstant from '@lib/constants/data/error/server-error.constant'
import { Metadata } from 'grpc-web'
import {
  AuthTokenManager,
  InvalidTokenError,
} from '@lib/services/grpc/base/helpers/token-manager'
import { GrpcDevToolsIntegration } from './grpc-dev-tools-integration'
import { GrpcCodes } from '@lib/constants/data/error/api-errors.constant'
import SystemHelper from '@lib/helpers/system.helper'
import PagePathConstant from '../../constants/page-path.constant'
import GrpcRequestError from '@lib/errors/grpc-error'

type RequestOptions = {
  requestName: string
  maxRetries?: number
  currentRetry?: number
  initialInterval?: number
}

/**
 * To extend `GrpcClient` for a new service, you must:
 *
 * 1. Implement the `initClient(hostName: string): C` method to initialize the specific gRPC service client.
 *    This method should create and return an instance of the gRPC service client using the provided `hostName`.
 *
 * 2. Implement the `innerClientTypeId(): string` method to return a unique identifier for the client type.
 *    This identifier is used for client registration and retrieval from the internal `clients` map.
 *
 * 3. Implement additional methods that utilize `this.getClient(hostName)` to obtain the gRPC service client
 *    and perform service-specific operations.
 *
 * Example usage:
 * ```ts
 * class ExampleClient extends GrpcClient<C> {
 *   constructor(hostName) {
 *     super();
 *     // Initialize and register the client in the constructor
 *     this.exampleServiceClient = this.getClient(hostName);
 *   }
 *
 *   // Implement the required abstract methods
 *   protected getClient(hostName: string): C {
 *     // Initialize your specific gRPC client here
 *   }
 *
 *   // After minification, the class name Client might be changed to something like a to reduce the file size,
 *   // and therefore Client.name and Client.constructor.name would return "a" instead of "Client"
 *   protected innerClientTypeId(): string {
 *     return 'ExampleClient';
 *   }
 * }
 * ```
 */

export abstract class GrpcClient<C> {
  #devToolsIntegration: GrpcDevToolsIntegration

  #authTokenManager: AuthTokenManager

  constructor() {
    this.#authTokenManager = new AuthTokenManager()
    this.#devToolsIntegration = new GrpcDevToolsIntegration()
  }

  static #token: string

  readonly #MAX_REQUEST_TIME = 60000 * 5 // 5 minutes

  readonly #INITIAL_INTERVAL = 100

  readonly #INITIAL_RETRY = 0

  readonly #DEFAULT_PAGE_SIZE = 25

  readonly #DEFAULT_MAX_RETRIES = 3

  readonly #MAX_INTERVAL = 10000

  public static setToken(token: string) {
    this.#token = token

    return this
  }

  protected static clients: Map<string, unknown> = new Map()

  #delay(interval: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, interval))
  }

  #calculateBackoff({
    retry,
    initialInterval,
  }: {
    retry: number
    initialInterval: number
  }): number {
    const backoff =
      initialInterval * Math.exp(retry) * (1.1 - Math.random() * 0.2)
    return Math.min(backoff, this.#MAX_INTERVAL)
  }

  #isGrpcError(error: unknown): error is {
    code: number
    message: string
    metadata: Metadata
  } {
    return (
      'code' in (error as object) &&
      'message' in (error as object) &&
      'metadata' in (error as object)
    )
  }

  #getGrpcErrorCode(error: unknown): number {
    if (this.#isGrpcError(error)) {
      return error.code ?? ServerErrorConstant.DEFAULT_GRPC_ERROR.code
    }
    return ServerErrorConstant.DEFAULT_GRPC_ERROR.code
  }

  protected metadata(): Metadata {
    return {
      Authorization: GrpcClient.token,
      deadline: String(Date.now() + this.#MAX_REQUEST_TIME),
    }
  }

  protected errorShouldNotBeRetried(grpcErrorCode: GrpcCodes): boolean {
    return ServerErrorConstant.isNotRetryebleError(grpcErrorCode)
  }

  protected async retryGrpcCall<T>(
    call: () => Promise<T>,
    options: RequestOptions
  ): Promise<T> {
    const {
      currentRetry = this.#INITIAL_RETRY,
      maxRetries = this.#DEFAULT_MAX_RETRIES,
      initialInterval = this.#INITIAL_INTERVAL,
    } = options
    try {
      this.#authTokenManager.ensureTokenValidity(GrpcClient.token)
      return await call()
    } catch (error: unknown) {
      console.log({ error })
      if (error instanceof InvalidTokenError) {
        throw error
      }

      if (this.#isGrpcError(error)) {
        const grpcError = new GrpcRequestError({
          code: error.code as GrpcCodes,
          message: error.message,
          metadata: error.metadata,
          requestName: options.requestName,
        })

        if (!this.tryToHandleError(grpcError)) {
          SystemHelper.sendGrpcErrorToSentryIfProd(grpcError)
        }

        throw grpcError
      }

      const grpcErrorCode = this.#getGrpcErrorCode(error)

      if (grpcErrorCode === GrpcCodes.UNAUTHENTICATED) {
        SystemHelper.pureNavigate(PagePathConstant.RELOGIN)
        throw error
      }

      if (grpcErrorCode === GrpcCodes.PERMISSION_DENIED) {
        SystemHelper.pureNavigate(PagePathConstant.TENANT_ACCESS_DENIED)
        throw error
      }

      if (
        !this.#getGrpcErrorCode(error) ||
        this.errorShouldNotBeRetried(grpcErrorCode)
      ) {
        throw error
      }

      if (currentRetry >= maxRetries) {
        throw error
      }

      await this.#delay(
        this.#calculateBackoff({
          retry: currentRetry,
          initialInterval,
        })
      )

      return this.retryGrpcCall(call, {
        ...options,
        currentRetry: currentRetry + 1,
      })
    }
  }

  protected tryToHandleError(error: GrpcRequestError): boolean {
    switch (error.code) {
      case ServerErrorConstant.ERROR_LOGIN.code:
      case ServerErrorConstant.ERROR_AUTH0_SESSION.code:
        SystemHelper.pureNavigate(PagePathConstant.RELOGIN)
        return true
      // wrong tenant
      case ServerErrorConstant.ERROR_TENANT_DENIED.code:
        SystemHelper.pureNavigate(PagePathConstant.TENANT_ACCESS_DENIED)
        return true
      // need verification
      case ServerErrorConstant.ERROR_NEED_VERIFICATION.code:
        SystemHelper.pureNavigate(PagePathConstant.VERIFICATION)
        return true
      default:
        return false
    }
  }

  // this method must return the inner GRPC client
  protected abstract initClient(hostName: string): C

  // this method must return a class name or another id of the inner client type
  protected abstract innerClientTypeId(): string

  #initAndRegisterInDevTools(hostName: string): C {
    const client = this.initClient(hostName)

    if (this.#devToolsIntegration) {
      this.#devToolsIntegration.register(client)
    }

    return client
  }

  static get token(): string {
    if (!this.#token) {
      SystemHelper.sendSentryIfProd(
        ServerErrorConstant.TOKEN_SETUP_ERROR.extraInfo
      )
    }

    return this.#token
  }

  static isTokenAvailable(): boolean {
    return !!this.#token
  }

  get pageSize(): number {
    return this.#DEFAULT_PAGE_SIZE
  }

  protected getClient(hostName: string): C {
    const hostNamePart = hostName
      ? `hostname:${hostName.trim()}`
      : 'hostname:undefined'
    const key = `typeId:${this.innerClientTypeId()}-${hostNamePart}}`

    if (!GrpcClient.clients.has(key)) {
      const newClient = this.#initAndRegisterInDevTools(hostName)
      GrpcClient.clients.set(key, newClient)
    }

    return GrpcClient.clients.get(key) as C
  }
}
