import React from 'react'
import { CellWithDateTimeTooltipOwnProps } from './types'
import {
  DateTimeTooltip,
  DateOrTimestamp,
} from '@components-simple/date-time-tooltip'

function CellWithDateTimeTooltip({ date }: CellWithDateTimeTooltipOwnProps) {
  if (!date) {
    return null
  }

  return (
    <DateTimeTooltip
      placement="top-start"
      direction="row"
      showTimeFirst
      date={date as DateOrTimestamp}
    />
  )
}

export default CellWithDateTimeTooltip
