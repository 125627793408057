import { GrpcClient } from '@lib/clients/grpc-client'
import { getEngineInitData } from '@lib/hooks/engine/getEngineInitData'
import { SystemHelper } from '@lib/helpers/index'
import { PagePathConstant } from '@lib/constants'
import ServerErrorConstant from '@lib/constants/data/error/server-error.constant'

async function fetchAccessToken(): Promise<string | undefined> {
  try {
    const { accessToken, code } = await getEngineInitData()

    if (code === ServerErrorConstant.ERROR_AUTH0_SESSION.code) {
      SystemHelper.pureNavigate(PagePathConstant.AUTH0_GLOBAL_LOGIN)
      return
    }

    return accessToken
  } catch (err) {
    SystemHelper.sendSentryIfProd(
      `Global login: unhandled error: ${
        err instanceof Error ? err.message : 'An unknown error occurred.'
      }`
    )
  }
}

export async function setupGrpcClientTokenIfNeeded(
  grpcClient: typeof GrpcClient
): Promise<boolean> {
  let accessToken = grpcClient.isTokenAvailable() ? grpcClient.token : undefined

  if (!accessToken) {
    accessToken = await fetchAccessToken()
    if (!accessToken) {
      console.warn('Access token is empty or null, skipping gRPC client setup.')
      return false
    }
    GrpcClient.setToken(accessToken)
  }

  return true
}
