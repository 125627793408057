import TypeHelper from '@lib/helpers/type.helper'
import AwsAccountInfoModel from '@lib/models/aws-account-info.model'

abstract class AwsAccountInfoFactory {
  public static buildFromGrpc(params: any): AwsAccountInfoModel {
    return new AwsAccountInfoModel({
      awsAccountId: TypeHelper.stringValDef(params.awsAccountId, ''),
      reportGatheringEnabled: TypeHelper.booleanValDef(
        params.reportGatheringEnabled,
        false
      ),
    })
  }
}

export default AwsAccountInfoFactory
