import { ModelAttrs, Model } from './model'

export enum NotificationStatus {
  PENDING = 'pending',
  SHOWN = 'shown',
}

export enum NotificationKind {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface NotificationAttrs extends ModelAttrs {
  text: string
  status: NotificationStatus
  kind: NotificationKind
}

export class Notification extends Model<NotificationAttrs> {
  #status: NotificationStatus

  #kind: NotificationKind

  #text: string

  constructor(attrs: NotificationAttrs) {
    super(attrs)
    this.#status = attrs.status
    this.#text = attrs.text
    this.#kind = attrs.kind
  }

  get text(): string {
    return this.#text
  }

  get status(): NotificationStatus {
    return this.#status
  }

  set status(newStatus: NotificationStatus) {
    this.#status = newStatus
  }

  get kind(): NotificationKind {
    return this.#kind
  }

  set kind(kind: NotificationKind) {
    this.#kind = kind
  }

  toAttrs(): NotificationAttrs {
    return {
      id: this.id,
      text: this.text,
      status: this.status,
      createdAt: this.createdAt,
      kind: this.kind,
    }
  }
}
