import moment, { Moment } from 'moment'
import RegexConstants from '@lib/constants/regex.constant'
import { Nullable } from '@lib/engine-types'
import { OptionalBoolean } from '@lib/constants/inventory/optional-boolean.constant'
import { AssetKind } from '@lib/constants/grpc/asset-kind.constant'
import { AssetTypeNameConstant } from '@lib/constants/inventory/asset-type-name.constant'

abstract class TypeHelper {
  static applyHandler<T>(value: any, handler: any): Nullable<T> {
    if (value === null || value === undefined) {
      return null
    }
    return handler(value)
  }

  static applyHandlerDef<T>(value: any, defVal: T, handler: any): T {
    if (value === null || value === undefined) {
      return defVal
    }
    return handler(value)
  }

  //----------------------------------------------------------------------------------------

  private static numberHandler(value: any) {
    return +value
  }

  static numberValDef(value: any, defVal: number): number {
    const result = TypeHelper.applyHandlerDef<number>(
      value,
      defVal,
      TypeHelper.numberHandler
    )
    if (isNaN(result)) {
      return defVal
    }
    return result
  }

  static numberVal(value: any): Nullable<number> {
    return TypeHelper.applyHandler<number>(value, (value: any) => {
      if (isNaN(value)) {
        return null
      }
      return TypeHelper.numberHandler(value)
    })
  }

  //----------------------------------------------------------------------------------------

  private static stringHandler(value: any): string {
    if (value === null) {
      return ''
    }
    return `${value}`
  }

  static stringValDef(value: any, defVal: string): string {
    return TypeHelper.applyHandlerDef<string>(
      value,
      defVal,
      TypeHelper.stringHandler
    )
  }

  static stringVal(value: any): Nullable<string> {
    return TypeHelper.applyHandler<string>(value, TypeHelper.stringHandler)
  }

  //----------------------------------------------------------------------------------------

  private static arrayStringHandler(value: any): Array<string> {
    if (!Array.isArray(value) || value.length === 0) {
      return []
    }
    return value.map(TypeHelper.stringHandler)
  }

  static arrayStringValDef(value: any, defVal: Array<string>): Array<string> {
    return TypeHelper.applyHandlerDef<Array<string>>(
      value,
      defVal,
      TypeHelper.arrayStringHandler
    )
  }

  static arrayStringVal(value: any): Nullable<Array<string>> {
    return TypeHelper.applyHandler<Array<string>>(
      value,
      TypeHelper.arrayStringHandler
    )
  }

  //----------------------------------------------------------------------------------------

  private static emailHandler(value: any): Nullable<string> {
    const finalValue = TypeHelper.stringHandler(value)
    if (RegexConstants.EMAIL.test(finalValue)) {
      return finalValue
    }
    return null
  }

  static emailValDef(value: any, defVal: string): string {
    return TypeHelper.applyHandlerDef<string>(
      value,
      defVal,
      TypeHelper.emailHandler
    )
  }

  static emailVal(value: any): Nullable<string> {
    return TypeHelper.applyHandler<string>(value, TypeHelper.emailHandler)
  }

  //----------------------------------------------------------------------------------------

  private static booleanHandler(value: any): boolean {
    if (value === 'false') {
      return false
    }
    return !!value
  }

  static booleanValDef(value: any, defVal = false): boolean {
    return TypeHelper.applyHandlerDef<boolean>(
      value,
      defVal,
      TypeHelper.booleanHandler
    )
  }

  static booleanVal(value: any): Nullable<boolean> {
    return TypeHelper.applyHandler(value, TypeHelper.booleanHandler)
  }

  //----------------------------------------------------------------------------------------

  private static phoneHandler(value: any): string {
    return value.toString().replace(/[\D]/g, '')
  }

  static phoneValDef(value: any, defVal: string): string {
    return TypeHelper.applyHandlerDef<string>(
      value,
      defVal,
      TypeHelper.phoneHandler
    )
  }

  static phoneVal(value: any): Nullable<string> {
    return TypeHelper.applyHandler(value, TypeHelper.phoneHandler)
  }

  //----------------------------------------------------------------------------------------

  private static momentHandler(value: any): Moment {
    return moment.utc(value)
  }

  static momentValDef(value: any, defVal: Moment) {
    return TypeHelper.applyHandlerDef<Moment>(
      value,
      defVal,
      TypeHelper.momentHandler
    )
  }

  static momentVal(value: any): Nullable<Moment> {
    return TypeHelper.applyHandler(value, TypeHelper.momentHandler)
  }

  //----------------------------------------------------------------------------------------

  private static enumHandler(value: any, enumArr: any) {
    for (const key in enumArr) {
      const currentVal = enumArr[key]
      if (currentVal == value) {
        return currentVal
      }
    }
    return null
  }

  static enumValDef<T>(value: any, defVal: T, enumArr: any): T {
    const result = TypeHelper.applyHandlerDef(value, defVal, (v: any) =>
      TypeHelper.enumHandler(v, enumArr)
    )
    if (result === null) {
      return defVal
    }
    return result
  }

  static enumVal<T>(value: any, enumArr: any): Nullable<T> {
    return TypeHelper.applyHandler(value, (v: any) =>
      TypeHelper.enumHandler(v, enumArr)
    )
  }

  //----------------------------------------------------------------------------------------

  // data: Array<[string, string]>
  static mapFromTuple(data: any): Map<string, string> {
    const result = new Map<string, string>()
    if (!data) {
      return result
    }
    Object.values(data)
      // @ts-ignore
      .map(([key, value]) => {
        result.set(key, value)
      })
    return result
  }

  //----------------------------------------------------------------------------------------

  static booleanFromOptionalBoolean(value: any): Nullable<boolean> {
    switch (value) {
      case OptionalBoolean.UNKNOWN:
        return null
      case OptionalBoolean.FALSE:
        return false
      case OptionalBoolean.TRUE:
        return true
      default:
        return null
    }
  }

  static getAssetKindForInventory(type: number): string {
    switch (type) {
      case AssetKind.AWS_EBS:
        return AssetTypeNameConstant.VOLUME
      case AssetKind.AWS_EC2:
        return AssetTypeNameConstant.INSTANCE
      case AssetKind.AWS_S3:
        return AssetTypeNameConstant.S3BUCKET
      case AssetKind.GENERIC_HOST:
        return AssetTypeNameConstant.GENERIC_HOST
      case AssetKind.AWS_EFS:
        return AssetTypeNameConstant.EFS
      default:
        return ''
    }
  }
}

export default TypeHelper
