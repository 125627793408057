import { Request as Pagination } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/pagination_pb'
import { Request as BackupsForAssetItemsRequest } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/backups/list_backups_for_asset_item_pb'
import { AbstractListRequest } from '@lib/clients/list-request'

export class ListBackupsForAssetItemsPagination extends AbstractListRequest<BackupsForAssetItemsRequest> {
  #pageToken: string

  constructor(pageToken: string) {
    super()
    this.#pageToken = pageToken
  }

  createGrpcRequest(): BackupsForAssetItemsRequest {
    const grpcRequest = new BackupsForAssetItemsRequest()
    grpcRequest.setPagination(new Pagination().setPageToken(this.#pageToken))

    return grpcRequest
  }
}
