import { ScansPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import { GrpcClient } from '@lib/clients/grpc-client'
import {
  Request,
  Response,
} from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/scans/download_scan_pb'

export class DownloadScan extends GrpcClient<ScansPromiseClient> {
  #downloadScansClient: ScansPromiseClient

  constructor(hostName = '') {
    super()
    this.#downloadScansClient = this.getClient(hostName)
  }

  protected initClient(hostName: string): ScansPromiseClient {
    return new ScansPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'DownloadScan'
  }

  async downloadScan(id: string): Promise<string> {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#downloadScansClient.downloadScan(
            new Request().setScanId(id),
            this.metadata()
          ),
        {
          requestName: 'ScansPromiseClient/downloadScan',
        }
      )
    ).toObject()

    return this.#transformResponse(response)
  }

  #transformResponse(response: Response.AsObject): string {
    return response && response.reportDownloadUrl
  }
}
