import {
  useRef,
  useLayoutEffect,
  useMemo,
  useState,
  MutableRefObject,
} from 'react'

interface UseVisibilityObserverOutput<T> {
  isIntersecting: boolean
  observedElement: MutableRefObject<T | null>
}

// TODO Support for Multiple Elements, Option to Unobserve, Return More than the Ref(the intersection state), Error Handling and Feedback
function useVisibilityObserver<T extends Element>(
  callback: () => void,
  options: IntersectionObserverInit = {
    root: null,
    rootMargin: '5px',
    threshold: 0.01,
  }
): UseVisibilityObserverOutput<T> {
  const [isIntersecting, setIsIntersecting] = useState(false) // State to track intersection status
  const observedElementRef = useRef<T | null>(null)
  const memoizedOptions = useMemo(() => options, [options])

  // TODO Add a useCallback
  const callbackObserver = (entries: Array<IntersectionObserverEntry>) => {
    const entry = entries[0]
    if (!entry) {
      return
    }

    setIsIntersecting(entry.isIntersecting) // Update state based on intersection
    if (entry.isIntersecting) {
      callback()
    }
  }
  const observerMemoized = useMemo(
    () => new IntersectionObserver(callbackObserver, memoizedOptions),
    [callbackObserver, memoizedOptions]
  )

  useLayoutEffect(() => {
    const observedElement = observedElementRef.current

    if (observedElement) {
      observerMemoized.observe(observedElement)
    } else {
      // TODO fix the log
      // console.error('useObserver: No element to observe')
    }

    return () => {
      if (observedElement) {
        observerMemoized.unobserve(observedElement)
      }
      observerMemoized.disconnect()
    }
  }, [observerMemoized, observedElementRef.current])

  return {
    isIntersecting,
    observedElement: observedElementRef,
  }
}

export { useVisibilityObserver }
