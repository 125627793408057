import { Subject } from 'rxjs'
import { filter } from 'rxjs/operators'
import ErrorInterface from '@lib/interfaces/error.interface'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import ArrHelper from '@lib/helpers/arr.helper'

class ErrorServiceInner {
  private readonly errors$: Subject<ErrorInterface>

  constructor() {
    this.errors$ = new Subject()
  }

  public emitError(error: ErrorInterface) {
    // eslint-disable-next-line no-console
    const dataToShow: any = { ...error }
    if (error.group !== undefined) {
      dataToShow.group_name = ErrorGroupConstants[Number(error.group)]
    }
    // ".error" will stop unit tests
    // eslint-disable-next-line no-console
    console.error(dataToShow)
    this.errors$.next(error)
  }

  public filter(errorGroupArr: Array<ErrorGroupConstants> = []) {
    if (ArrHelper.arrEmpty(errorGroupArr)) {
      return this.errors$
    }
    return this.errors$.pipe(
      filter((error) => !!error.group && errorGroupArr.includes(error.group))
    )
  }
}

const ErrorService = new ErrorServiceInner()

export default ErrorService
