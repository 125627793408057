import {
  UserProfileRequest,
  UserConfig,
  UsersPromiseClient,
  UsersListRequest,
} from '@lib/clients/grpc-imports'
import { GrpcClient } from '@lib/clients/grpc-client'
import UserModel from '@lib/models/user.model'
import UserFactory from '@lib/factories/user.factory'
// eslint-disable-next-line import/no-extraneous-dependencies
import { UserProfile } from 'blues-corejs/dist/models'
import TimeHelper from '@lib/helpers/time.helper'

export class UsersClient extends GrpcClient<UsersPromiseClient> {
  #usersPromiseClient: UsersPromiseClient

  #CLIENT_TYPE_ID = 'UsersClient'

  constructor(hostName = '') {
    super()
    this.#usersPromiseClient = this.getClient(hostName)
  }

  async getUserProfile(): Promise<UserModel> {
    const request = new UserProfileRequest()

    const response: UserProfileRequest = await this.retryGrpcCall(
      () => this.#usersPromiseClient.getUserProfile(request, this.metadata()),
      {
        requestName: 'UsersPromiseClient/getUserProfile',
      }
    )

    return UserFactory.buildFromGRPC(response.toObject())
  }

  async getUserConfig(): Promise<any> {
    const response = await this.retryGrpcCall(
      () =>
        this.#usersPromiseClient.getUserConfig(
          new UserConfig(),
          this.metadata()
        ),
      {
        requestName: 'UsersPromiseClient/getUserConfig',
      }
    )

    return JSON.parse(response.getJson())
  }

  async getList(): Promise<Array<UserProfile>> {
    const response = await this.retryGrpcCall(
      () =>
        this.#usersPromiseClient.list(new UsersListRequest(), this.metadata()),
      {
        requestName: 'UsersPromiseClient/list',
      }
    )

    response.getUserProfilesList()

    return response.toObject().userProfilesList.map(
      (userProfile) =>
        new UserProfile({
          email: userProfile.email,
          id: userProfile.id,
          // @ts-ignore
          lastSeen: TimeHelper.secondsToDate(userProfile.lastSeen),
          locale: userProfile.locale,
          name: userProfile.name,
          nickname: userProfile.nickname,
          phoneNumber: userProfile.phoneNumber,
          // @ts-ignore
          picture: userProfile.picture,
          // @ts-ignore
          role: userProfile.role ?? {},
          // @ts-ignore
          status: userProfile.status,
        })
    )
  }

  protected innerClientTypeId(): string {
    return this.#CLIENT_TYPE_ID
  }

  protected initClient(hostName = ''): UsersPromiseClient {
    return new UsersPromiseClient(hostName, null, null)
  }
}
