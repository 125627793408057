import PreloaderConstants from '@lib/constants/preloader.constant'
import {
  REQUEST_TOKEN_LIST,
  setTokensList,
  SUBMIT_ADD_TOKEN,
  SUBMIT_EDIT_TOKEN,
  SUBMIT_REVOKE_TOKEN_LIST,
  REQUEST_TOKEN,
  setEditToken,
  setSecretToken,
  requestTokenList,
  setRevokedTokensList,
  REQUEST_REVOKED_TOKEN_LIST,
  requestRevokedTokenList,
} from '@store/actions/token.action'
import Router from 'next/router'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import AccessTokenModel from '@lib/models/access-token.model'
import ActionInterface from '@lib/interfaces/action.interface'
import {
  baseRequestScenario,
  sentryReThrowCatchHandler,
} from '@store/epics/epic-func'
import { emptyAction } from '@store/actions/default/empty.action'
import TokenIssueResultInterface from '@lib/interfaces/token-issue-result.interface'
import ToastHelper from '@lib/helpers/toast.helper'
import StoreService from '@lib/services/store.service'
import PageHelper from '@lib/helpers/page.helper'
import PagePathConstant from '@lib/constants/page-path.constant'
import GrpcPatService from '@lib/services/grpc/grpc-pat.service'

export const requestTokenListEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      // if we are changing tokens somehow, we should request the new list of tokens
      [REQUEST_TOKEN_LIST],
      PreloaderConstants.REQUEST_TOKEN_LIST,
      () =>
        GrpcPatService.list().catch(
          sentryReThrowCatchHandler('Cannot get a list of tokens')
        ),
      (data: Array<AccessTokenModel>) => setTokensList(data),
      ErrorGroupConstants.ACCESS
    )
  )

export const requestRevokedTokenListEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      // if we are changing tokens somehow, we should request the new list of tokens
      [REQUEST_REVOKED_TOKEN_LIST],
      PreloaderConstants.REQUEST_REVOKED_TOKEN_LIST,
      () =>
        GrpcPatService.listRevoked().catch(
          sentryReThrowCatchHandler('Cannot get a list of revoked tokens')
        ),
      (data: Array<AccessTokenModel>) => setRevokedTokensList(data),
      ErrorGroupConstants.ACCESS
    )
  )

export const requestSingleTokenEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_TOKEN],
      PreloaderConstants.REQUEST_TOKEN,
      (action: ActionInterface) =>
        GrpcPatService.getTokenDetailsById(action.payload).catch(
          sentryReThrowCatchHandler('Cannot get a single token information')
        ),
      (data: AccessTokenModel) => setEditToken(data),
      ErrorGroupConstants.ACCESS
    )
  )

export const issueTokenEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [SUBMIT_ADD_TOKEN],
      PreloaderConstants.ISSUE_TOKEN,
      (action: ActionInterface) =>
        GrpcPatService.issue(
          action.payload.tokenName,
          action.payload.scopes
        ).catch(sentryReThrowCatchHandler('Cannot issue the token')),
      ({ secretToken, generatedToken }: TokenIssueResultInterface) => {
        // navigate on the result page
        Router.push(
          PageHelper.buildUrl(
            PagePathConstant.GENERATED_ACCESS_TOKEN_ID,
            generatedToken.innerId
          )
        )
        // we save this secret key only in memory - we do not using it
        // for navigating in url in order to prevent saving it in the
        // browser history
        return setSecretToken(secretToken)
      },
      ErrorGroupConstants.ACCESS
    )
  )

export const revokeTokenEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [SUBMIT_REVOKE_TOKEN_LIST],
      PreloaderConstants.REVOKE_TOKEN,
      (action: ActionInterface) =>
        GrpcPatService.revoke(action.payload).catch(
          sentryReThrowCatchHandler('Cannot perform the tokens revoking')
        ),
      () => {
        ToastHelper.success('The access api token has been revoked')
        StoreService.dispatchAction(requestRevokedTokenList())
        return requestTokenList()
      },
      ErrorGroupConstants.ACCESS
    )
  )

export const updateTokenEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [SUBMIT_EDIT_TOKEN],
      PreloaderConstants.UPDATE_TOKEN,
      (action: ActionInterface) =>
        GrpcPatService.update(
          action.payload.tokenId,
          action.payload.tokenName,
          action.payload.scopes
        ).catch(
          sentryReThrowCatchHandler('Cannot perform the tokens updating')
        ),
      () => {
        Router.push(PagePathConstant.ACCOUNT_TOKENS)
        ToastHelper.success('The access api token has been updated')
        return emptyAction()
      },
      ErrorGroupConstants.ACCESS
    )
  )
