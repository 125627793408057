import { VIMatrix } from '@lib/engine-types'
import ValueInterface from '@lib/interfaces/value.interface'

export const getAccountAliasName = (state: any): string =>
  state.sourceIdState.accountAliasName

export const getAccountDescription = (state: any): string =>
  state.sourceIdState.accountDescription

export const getDataForInstallation = (state: any): Array<ValueInterface> =>
  state.sourceIdState.dataForInstallation

export const getRsIdForRemoved = (state: any): Array<string> =>
  state.sourceIdState.rsIdForRemoved

export const getSourceIdTableData = (state: any): VIMatrix =>
  state.sourceIdState.sourceIdTableData

export const sourceIdFormTouched = (state: any): boolean =>
  state.sourceIdState.isFormTouched

export const getAccountRedStackId = (state: any): string =>
  state.sourceIdState.accountRedStackId

export const getAccountId = (state: any): string =>
  state.sourceIdState.accountId

export const sourceIdHasSelectedSubnets = (state: any): boolean => {
  return state.sourceIdState.dataForInstallation.every(
    (el: ValueInterface) => el.options && el.options.length > 0
  )
}

export const sourceIdHasSelectedPrivateSubnets = (state: any): boolean =>
  state.sourceIdState.dataForInstallation.some((el: ValueInterface) =>
    el.options?.find((o) => o.extraValue === false)
  )

export const sourceIdCanSubmit = (state: any): boolean =>
  state.sourceIdState.isFormTouched ||
  (state.sourceIdState.dataForInstallation.length > 0 &&
    sourceIdHasSelectedSubnets(state)) ||
  state.sourceIdState.rsIdForRemoved.length > 0
