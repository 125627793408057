import LangConstant from '@lib/constants/lang/lang.constant'
import { AssetKind } from '@lib/constants/grpc/asset-kind.constant'

const LangAssetKindConstant = new Map<AssetKind, LangConstant>()

LangAssetKindConstant.set(AssetKind.AWS_EC2, {
  en: {
    single: 'EC2',
  },
})

LangAssetKindConstant.set(AssetKind.AZURE_VM, {
  en: {
    single: 'AzureVM',
  },
})

LangAssetKindConstant.set(AssetKind.VM_WARE_VM, {
  en: {
    single: 'VmWareVM',
  },
})

LangAssetKindConstant.set(AssetKind.AWS_EBS, {
  en: {
    single: 'EBS',
  },
})

LangAssetKindConstant.set(AssetKind.AWS_EFS, {
  en: {
    single: 'EFS',
  },
})

LangAssetKindConstant.set(AssetKind.GENERIC_HOST, {
  en: {
    single: 'Generic Host',
  },
})

LangAssetKindConstant.set(AssetKind.GENERIC_FS, {
  en: {
    single: 'GenericFS',
  },
})

LangAssetKindConstant.set(AssetKind.AWS_S3, {
  en: {
    single: 'S3 Bucket',
  },
})

export default LangAssetKindConstant
