import {
  Kind,
  KIND_TENANT,
  FEATURE_FLAG_S3_PREVIEW,
} from '@lib/feature-flags/consts'

abstract class BaseContext {
  private readonly kind: Kind

  protected constructor(kind: Kind) {
    this.kind = kind
  }

  getKind(): Kind {
    return this.kind
  }

  abstract getKey(): string
}

export class TenantContext extends BaseContext {
  private readonly tenantName: string

  constructor(tenantName: string) {
    super(KIND_TENANT)
    this.tenantName = tenantName
  }

  getKey(): string {
    return this.tenantName
  }
}

// For adding new features related to LaunchDarkly or other feature flagging tools, extend the BaseFeature class.
// For example, the S3Feature class demonstrates this by extending BaseFeature with specific types and implementing the abstract methods.
export abstract class BaseFeature<C extends BaseContext, V> {
  abstract intoContext(): C
  abstract getDefaultValue(): V
  abstract getFlagName(): string
}

export class S3Feature extends BaseFeature<TenantContext, boolean> {
  private readonly tenantName: string

  constructor(tenantName: string) {
    super()
    this.tenantName = tenantName
  }

  intoContext(): TenantContext {
    return new TenantContext(this.tenantName)
  }

  getDefaultValue(): boolean {
    return false
  }

  getFlagName(): string {
    return FEATURE_FLAG_S3_PREVIEW
  }
}
