import { EngineFunction, Nullable } from '@lib/engine-types'
import { intersection, uniq, sum, sample } from 'lodash/fp'
import { SortOrder } from '@lib/interfaces/tables/sort.interface'
import orderBy from 'lodash/fp/orderBy'
import uniqBy from 'lodash/fp/uniqBy'
import unionBy from 'lodash/unionBy'
import ObjHelper from '@lib/helpers/obj.helper'

abstract class ArrHelper {
  public static flatDeep<T>(arr: Array<any>, d = 1): Array<T> {
    return d > 0
      ? arr.reduce(
          (acc, val) =>
            acc.concat(
              Array.isArray(val) ? ArrHelper.flatDeep(val, d - 1) : val
            ),
          []
        )
      : arr.slice()
  }

  public static remove<T>(elem: T, arr: Array<T>) {
    if (!Array.isArray(arr)) {
      return []
    }
    return arr.filter((v) => v !== elem)
  }

  public static arrEmpty<T>(arr: Array<T>): boolean {
    if (!Array.isArray(arr)) {
      return true
    }
    return arr.length === 0
  }

  public static arrNotEmpty<T>(arr: Array<T>): boolean {
    return !ArrHelper.arrEmpty(arr)
  }

  public static last<T>(arr: Array<T>): Nullable<T> {
    if (ArrHelper.arrEmpty(arr)) {
      return null
    }
    return arr[arr.length - 1]
  }

  public static first<T>(arr: Array<T>): Nullable<T> {
    if (ArrHelper.arrEmpty(arr)) {
      return null
    }
    return arr[0]
  }

  public static safeLength(arr: Array<any>): number {
    if (ArrHelper.arrEmpty(arr)) {
      return 0
    }
    return arr.length
  }

  public static isArray<T>(arr: Array<T>): boolean {
    return Array.isArray(arr)
  }

  public static isNotArray<T>(arr: Array<T>): boolean {
    return !ArrHelper.isArray(arr)
  }

  public static map<T, P>(
    arr: Array<T>,
    callback: EngineFunction<T, P>
  ): Array<P> {
    if (ArrHelper.arrEmpty(arr)) {
      return []
    }
    return arr.map((v) => callback(v))
  }

  public static intersection<T>(arr1: Array<T>, arr2: Array<T>): Array<T> {
    return intersection(arr1, arr2)
  }

  public static pushUnique<T>(value: T, arr: Array<T>) {
    if (arr.includes(value)) {
      return
    }
    arr.push(value)
  }

  public static updateArrayValue<T>(
    arr: Array<T>,
    initialValue: T,
    fieldNameForSearch: string,
    fieldNameForReplace: string
  ): void {
    const valueToReplace = arr
      // @ts-ignore
      .find((v) => v[fieldNameForSearch] === initialValue[fieldNameForSearch])
    if (!valueToReplace) {
      return
    }
    // @ts-ignore
    valueToReplace[fieldNameForReplace] = initialValue[fieldNameForReplace]
  }

  public static generateArr(
    placeholder: number,
    length: number
  ): Array<number> {
    const result = []
    for (let i = 0; i < length; ++i) {
      result.push(placeholder)
    }
    return result
  }

  public static generateIncSequence(from: number, to: number): Array<number> {
    const result = []
    for (let i = from; i < to; ++i) {
      result.push(i)
    }
    return result
  }

  public static sortInc<T>(arr: Array<T>): Array<T> {
    const result = [...arr]
    result.sort((a, b) => (a > b ? 1 : -1))
    return result
  }

  public static sortArrByMap<T>(
    baseArr: Array<T>,
    baseMap: Array<number>
  ): Array<T> {
    const result: Array<T> = []
    const sortedBaseMap = ArrHelper.sortInc(baseMap)
    // we slice it in case of Mask is larger then Array
    ;[...baseMap].slice(0, baseArr.length).forEach((baseValue) => {
      const finalIndex = sortedBaseMap.indexOf(baseValue)
      // the way how we handle repeats
      sortedBaseMap[finalIndex] = NaN
      const elem = baseArr[finalIndex]
      if (elem !== undefined) {
        result.push(elem)
      }
    })
    // add unsorted values if Mask length is less then Array length
    if (result.length < baseArr.length) {
      baseArr.forEach((v) => {
        ArrHelper.pushUnique(v, result)
      })
    }
    return result
  }

  public static sum<T>(
    arr: ReadonlyArray<T>,
    selector?: EngineFunction<T, number> | keyof T
  ): number {
    return arr.reduce((acc: number, elem: any) => {
      let valueToAdd
      switch (typeof selector) {
        case 'string':
          valueToAdd = Number(elem[selector])
          break
        case 'function':
          valueToAdd = Number(selector(elem))
          break

        default:
          valueToAdd = elem
      }

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(valueToAdd)) {
        valueToAdd = 0
      }
      return acc + valueToAdd
    }, 0)
  }

  public static unique<T>(arr: Array<T>): Array<T> {
    return uniq(arr)
  }

  public static uniqueBy<T>(arr: Array<T>, key: keyof T): Array<T> {
    return uniqBy(key, arr)
  }

  public static unionArray<T>(
    arr1: Array<T>,
    arr2: Array<T>,
    key: keyof T
  ): Array<T> {
    return unionBy(arr1, arr2, key)
  }

  public static orderBy<T>(
    collection: ReadonlyArray<T>,
    selector: string,
    direction: SortOrder
  ): Array<T> {
    return orderBy([selector], [direction], collection)
  }

  public static arrayItemMove<T>(
    arr: Array<T>,
    fromIndex: number,
    toIndex: number
  ): Array<T> {
    const element = arr[fromIndex]
    if (element !== undefined) {
      arr.splice(fromIndex, 1)
      arr.splice(toIndex, 0, element)
    }

    return arr
  }

  public static arrSumValues<T>(arr: Array<T>): number {
    if (ArrHelper.arrEmpty(arr)) {
      return 0
    }
    return sum(arr)
  }

  public static swapValues<T>(
    arr: Array<T>,
    from: number,
    to: number
  ): Array<T> {
    return arr.map((current, idx) => {
      if (idx === from) {
        return arr[to] as T
      }
      if (idx === to) {
        return arr[from] as T
      }
      return current
    })
  }

  public static arrRemoveValues<T>(
    arr: Array<T>,
    arrRemove: Array<T>
  ): Array<T> {
    if (ArrHelper.arrEmpty(arrRemove)) {
      return arr
    }
    const result = ObjHelper.cloneDeep(arr)
    arrRemove.forEach((el) => {
      const ind = result.indexOf(el)
      if (ind !== -1) {
        result.splice(ind, 1)
      }
    })
    return result
  }

  public static getRandomItemFromArray<T>(arr: Array<T>) {
    return sample(arr)
  }

  public static generateArrayWithCallback = <T>(
    length: number,
    callback: (index: number) => T
  ): Array<T> => Array.from({ length }, (_, index) => callback(index))
}

export default ArrHelper
