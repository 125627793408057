/* eslint-disable import/no-extraneous-dependencies */
import PreloaderConstants from '@lib/constants/preloader.constant'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import {
  baseRequestScenario,
  sentryReThrowCatchHandler,
} from '@store/epics/epic-func'
import VaultModel from '@lib/models/vault.model'
import {
  CREATE_VAULT,
  REQUEST_ACCOUNT_RED_STACKS,
  REQUEST_ACCOUNT_VAULT_LIST,
  REQUEST_ALL_ACTIVE_RED_STACKS,
  REQUEST_SOURCES_VAULT_LIST,
  REQUEST_VAULT,
  REQUEST_VAULT_LIST,
  REQUEST_VAULT_METRICS,
  requestVaultList,
  SET_VAULT_AS_DEFAULT,
  setAccountRedStacks,
  setAccountVaultList,
  setAllActiveRedStacks,
  setNewDefaultVault,
  setSourcesVaultList,
  setVault,
  setVaultList,
  setVaultMetrics,
  UPDATE_VAULT,
  updateVaultData,
} from '@store/actions/vault.action'
import ActionInterface from '@lib/interfaces/action.interface'
import ToastHelper from '@lib/helpers/toast.helper'
import StoreService from '@lib/services/store.service'
import { hideModal } from '@store/actions/modal.action'
import Modal from '@lib/constants/modal.constant'
import RedStackModel from '@lib/models/red-stack.model'
import VaultMetricsModel from '@lib/models/vault-metrics.model'
import GrpcRexService from '@lib/services/grpc/grpc-rex.service'
import GrpcVaultService from '@lib/services/grpc/grpc-vault.service'
import GrpcMixedService from '@lib/services/grpc/high/grpc-mixed.service'
import { getAllRedStacks, setPossibleVpcList } from '@store/actions/rex.action'
import { ScheduleTenantJobUseCase } from '@features/scheduled-jobs-monitoring/use-cases'
import { ScheduledTenantJobsRepository } from '@features/scheduled-jobs-monitoring/repositories'
import { createLogger } from '@features/scheduled-jobs-monitoring/infrastructure/logging'
import { ScheduledTenantJobsClient } from '@features/scheduled-jobs-monitoring/clients'

const scheduleTenantJobUseCase = new ScheduleTenantJobUseCase(
  new ScheduledTenantJobsRepository(createLogger()),
  new ScheduledTenantJobsClient(),
  ToastHelper
)

export const requestVaultListEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_VAULT_LIST],
      PreloaderConstants.REQUEST_VAULT_LIST,
      () =>
        GrpcMixedService.listVaultsForActiveRedStacks().catch(
          sentryReThrowCatchHandler('Cannot get a list of vaults')
        ),
      (data: Array<VaultModel>) => setVaultList(data),
      ErrorGroupConstants.VAULT
    )
  )

export const requestSourcesVaultListEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_SOURCES_VAULT_LIST],
      PreloaderConstants.REQUEST_SOURCES_VAULT_LIST,
      () =>
        GrpcMixedService.listVaultsForAllRedStacks().catch(
          sentryReThrowCatchHandler('Cannot get a list of vaults')
        ),
      (data: Array<VaultModel>) => setSourcesVaultList(data),
      ErrorGroupConstants.VAULT
    )
  )

export const requestAccountVaultListEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_ACCOUNT_VAULT_LIST],
      PreloaderConstants.REQUEST_ACCOUNT_VAULT_LIST,
      (action: ActionInterface) =>
        GrpcMixedService.listVaultsForAllRedStacks(action.payload).catch(
          sentryReThrowCatchHandler(
            'Cannot get a list of vault for the account'
          )
        ),
      (data: Array<VaultModel>) => setAccountVaultList(data),
      ErrorGroupConstants.VAULT
    )
  )

export const setVaultAsDefaultEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [SET_VAULT_AS_DEFAULT],
      PreloaderConstants.SET_VAULT_AS_DEFAULT,
      async (action: ActionInterface) => {
        await GrpcVaultService.setDefault(action.payload).catch((e) => {
          ToastHelper.error('Something went wrong: ' + (e as Error)?.message)
          sentryReThrowCatchHandler(
            'Cannot set vault as default for this region'
          )(e)
        })

        ToastHelper.success(
          `Vault "${action.payload.name}" successfully set as default`
        )
        return action.payload
      },
      (data: Pick<VaultModel, 'innerId' | 'region'>) => {
        StoreService.dispatchAction(getAllRedStacks())
        StoreService.dispatchAction(requestVaultList())
        StoreService.dispatchAction(setPossibleVpcList([]))
        return setNewDefaultVault(data)
      },
      ErrorGroupConstants.VAULT
    )
  )

export const createVaultEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [CREATE_VAULT],
      PreloaderConstants.CREATE_VAULT,
      (action: ActionInterface) =>
        GrpcVaultService.createVault(action.payload).catch(
          sentryReThrowCatchHandler('Cannot perform the vault creation action')
        ),
      async (jobId: string) => {
        await scheduleTenantJobUseCase.execute(jobId)
        return hideModal(Modal.addVault)
      },
      ErrorGroupConstants.VAULT
    )
  )

export const requestAccountRedStacksEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_ACCOUNT_RED_STACKS],
      PreloaderConstants.REQUEST_ACCOUNT_RED_STACKS,
      (action: ActionInterface) =>
        GrpcRexService.getAllRedStacksByAccountId(action.payload).catch(
          sentryReThrowCatchHandler(
            'Cannot get a list of cloud connectors for the account'
          )
        ),
      (data: Array<RedStackModel>) => setAccountRedStacks(data),
      ErrorGroupConstants.VAULT
    )
  )

export const requestAllActiveRedStacksEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_ALL_ACTIVE_RED_STACKS],
      PreloaderConstants.REQUEST_ALL_ACTIVE_RED_STACKS,
      () =>
        GrpcRexService.getAllActiveRedStacks().catch(
          sentryReThrowCatchHandler('Cannot get a list of cloud connectors')
        ),
      (data: Array<RedStackModel>) => setAllActiveRedStacks(data),
      ErrorGroupConstants.VAULT
    )
  )

export const requestVaultEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_VAULT],
      PreloaderConstants.REQUEST_VAULT,
      (action: ActionInterface) =>
        GrpcVaultService.getVault(action.payload).catch(
          sentryReThrowCatchHandler('Cannot get a vault')
        ),
      (data: VaultModel) => setVault(data),
      ErrorGroupConstants.VAULT
    )
  )

export const requestVaultMetricsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_VAULT_METRICS],
      PreloaderConstants.REQUEST_VAULT_METRICS,
      (action: ActionInterface) =>
        GrpcMixedService.getVaultMetrics(action.payload).catch(
          sentryReThrowCatchHandler('Cannot get a vault`s metrics')
        ),
      (data: VaultMetricsModel) => setVaultMetrics(data),
      ErrorGroupConstants.VAULT
    )
  )

export const updateVaultEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [UPDATE_VAULT],
      PreloaderConstants.UPDATE_VAULT,
      (action: ActionInterface) =>
        GrpcVaultService.updateVault(
          action.payload.vaultId,
          action.payload.description,
          action.payload.vpcId,
          action.payload.subnetIdsList,
          action.payload.safetyLock
        )
          .then((jobId) => ({
            jobId,
            action,
          }))
          .catch(sentryReThrowCatchHandler('Cannot update vault')),
      async ({ jobId, action }: { jobId: string; action: ActionInterface }) => {
        await scheduleTenantJobUseCase.execute(jobId)

        return updateVaultData(
          action.payload.description,
          action.payload.vpcId,
          action.payload.subnetIdsList
        )
      },
      ErrorGroupConstants.VAULT
    )
  )
