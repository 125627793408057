import Dexie from 'dexie'
import { Repository } from './repository'
import {
  Model,
  ModelAttrs,
} from '@features/scheduled-jobs-monitoring/models/model'
import { Logger } from '@features/scheduled-jobs-monitoring/use-cases'

// IMPORTANT: DANGER ZONE
//
// DO NOT CHANGE THESE CONSTANTS VALUES
//
// These constants are used to specify IndexedDB table names.
// To change table names, use migration.
export const SCHEDULED_TENANT_JOBS = 'scheduled_tenant_jobs'
export const SCHEDULED_CC_JOBS = 'scheduled_cc_jobs'
export const SYNC = 'sync'

// TODO We need to update the TypeScript to fix these issues.
// @ts-ignore
export class ScheduledJobsDB extends Dexie {
  constructor(name: string) {
    super(name)
    this.version(1).stores({
      [SCHEDULED_TENANT_JOBS]: '++id, kind, status, createdAt',
      [SYNC]: '++id, allowedAt, createdAt',
    })

    this.version(2)
      .stores({
        [SCHEDULED_TENANT_JOBS]: '++id, ccId, kind, status, createdAt',
        [SCHEDULED_CC_JOBS]: '++id, ccId, kind, status, createdAt',
        [SYNC]: '++id, allowedAt, createdAt',
      })
      .upgrade(async (tx) => {
        await tx
          .table(SCHEDULED_TENANT_JOBS)
          .toCollection()
          .modify((job) => {
            job.ccId = ''
          })
      })

    // TODO append migration in such way
    // this.version(3).stores({
    //     jobs: '++id, kind, status, createdAt, description'
    // }).upgrade(async tx => {
    //     await tx.table('jobs').toCollection().modify(job => {
    //         job.description = "";
    //     });
    // });
  }
}

export abstract class AbstractScheduledJobsRepository<
  A extends ModelAttrs,
  T extends Model<A>,
  K
> extends Repository<A, T, K> {
  #db: ScheduledJobsDB

  constructor(protected logger: Logger) {
    super(logger)
    this.#db = new ScheduledJobsDB('scheduled_jobs')
  }

  protected get db(): Dexie {
    return this.#db
  }
}
