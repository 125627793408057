"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.awsbRecoveryPointFactory = void 0;
const awsb_1 = require("../../models/backups/aws/awsb");
const types_1 = require("../../models/backups/aws/awsb/types");
const factories_1 = require("../../use_cases/tests/factories");
const fishery_1 = require("fishery");
const common_1 = require("./common");
class AWSBRecoveryPointFactory extends fishery_1.Factory {
}
const vault = (sequence) => ({
    arn: (0, factories_1.name)('arn', sequence),
    createdAt: (0, common_1.date)(),
    name: (0, factories_1.name)('vault-name', sequence),
    size: Math.floor(Math.random() * 1000000000),
    sizeLastCalculatedAt: (0, common_1.date)(),
});
const calculatedLifecycle = () => ({
    deleteAt: (0, common_1.date)(),
    moveToColdStorageAt: (0, common_1.date)(),
});
const createdBy = (sequence) => ({
    backupPlanArn: (0, factories_1.name)('backupPlanArn', sequence),
    backupPlanId: (0, factories_1.uuid)(),
    backupPlanVersion: (0, factories_1.uuid)(),
    backupRuleId: (0, factories_1.uuid)(),
});
const correspondingBackup = () => ({
    ebsSnapshot: {
        snapId: (0, factories_1.uuid)(),
    },
    elastioRecoveryPoint: {
        erpId: (0, factories_1.uuid)(),
    },
});
const threatIds = () => Array.from({ length: 10 }, factories_1.uuid);
const scanIds = () => Array.from({ length: 10 }, factories_1.uuid);
const lifecycle = () => ({
    deleteAfter: (0, common_1.date)(),
    moveToColdStorageAfter: (0, common_1.date)(),
});
exports.awsbRecoveryPointFactory = AWSBRecoveryPointFactory.define(({ transientParams, sequence }) => {
    return new awsb_1.AWSBRecoveryPoint({
        ransomwareCheckStatus: transientParams.ransomwareCheckStatus ??
            (0, common_1.randomValueFromEnum)(types_1.CheckStatus),
        malwareCheckStatus: transientParams.malwareCheckStatus ?? (0, common_1.randomValueFromEnum)(types_1.CheckStatus),
        fsCheckStatus: transientParams.fsCheckStatus ?? (0, common_1.randomValueFromEnum)(types_1.CheckStatus),
        id: transientParams.id ?? (0, factories_1.uuid)(),
        lifecycle: transientParams.lifecycle ?? lifecycle(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        arn: transientParams.arn ?? (0, factories_1.name)('arn', sequence),
        accountId: transientParams.accountId ?? (0, factories_1.awsAccountId)(),
        region: transientParams.region ?? (0, factories_1.awsRegion)(),
        tags: transientParams.tags ?? [],
        kind: transientParams.kind ?? (0, common_1.randomValueFromEnum)(types_1.RecoveryPointKind),
        size: transientParams.size ?? Math.floor(Math.random() * 1000000000),
        encrypted: transientParams.encrypted ?? (0, factories_1.bool)(),
        encryptionKeyArn: transientParams.encryptionKeyArn ?? (0, factories_1.name)('encryptionKeyArn', sequence),
        parentRecoveryPointArn: transientParams.parentRecoveryPointArn ??
            (0, factories_1.name)('parentRecoveryPointArn', sequence),
        sourceBackupVaultArn: transientParams.sourceBackupVaultArn ??
            (0, factories_1.name)('sourceBackupVaultArn', sequence),
        vault: transientParams.vault ?? vault(sequence),
        calculatedLifecycle: transientParams.calculatedLifecycle ?? calculatedLifecycle(),
        createdBy: transientParams.createdBy ?? createdBy(sequence),
        storageClass: transientParams.storageClass ?? (0, common_1.randomValueFromEnum)(types_1.StorageClass),
        status: transientParams.status ?? (0, common_1.randomValueFromEnum)(types_1.RecoveryPointStatus),
        sourceAssetId: transientParams.sourceAssetId ?? (0, factories_1.uuid)(),
        correspondingBackup: transientParams.correspondingBackup ?? correspondingBackup(),
        importJobId: transientParams.importJobId ?? (0, factories_1.uuid)(),
        threatIds: transientParams.threatIds ?? threatIds(),
        scanIds: transientParams.scanIds ?? scanIds(),
        timestamp: transientParams.timestamp ?? (0, common_1.date)(),
    });
});
