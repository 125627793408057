"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListInstancesWithThreats = void 0;
const types_1 = require("../../models/assets/aws/ec2/types");
const filesystem_check_1 = require("../../models/scans/filesystem-check");
const malware_1 = require("../../models/scans/malware");
const ransomware_1 = require("../../models/scans/ransomware");
const types_2 = require("../../models/threats/types");
class ListInstancesWithThreats {
    #instances;
    #volumes;
    #threats;
    #lastScans;
    #lastBackups;
    #volumesWithScans;
    constructor(parameters) {
        this.#instances = parameters.instances;
        this.#volumes = parameters.volumes;
        this.#threats = parameters.threats;
        this.#lastScans = parameters.lastScans;
        this.#lastBackups = parameters.lastBackups;
        this.#volumesWithScans ||= this.#getVolumeWithScans();
    }
    /**
     * @description Use `ListVolumesWithThreats` instead
     * @returns {Array<InstancesOutput>}
     */
    execute(filters) {
        return this.#prepareOutput(this.#findInstancesMatchingFilters(filters));
    }
    #prepareOutput(instances) {
        return instances.map((instance) => {
            return {
                instanceId: instance.id,
                instanceAwsId: instance.awsId,
                instanceName: instance.name,
                threats: this.#getThreatsForInstance(instance),
                accountId: instance.awsAccountId,
                region: instance.awsRegion,
                osKind: instance.osKind,
                attachedVolumes: instance.ebsIds.length,
                status: instance.state,
                lastScan: this.#getLastScanForInstance(instance),
                lastBackup: this.#getLastBackup(instance),
            };
        });
    }
    #getThreatsForInstance(instance) {
        return this.#threats.filter(({ source: { asset, assetItem } }) => asset.assetId === instance.id || assetItem?.assetItemId === instance.id);
    }
    #getActiveInstancesWithThreats() {
        return this.#instances.filter((instance) => this.#getThreatsForInstance(instance).length > 0 &&
            instance.state !== types_1.EC2State.STOPPED);
    }
    #getLastBackup(instance) {
        return this.#lastBackups.get(instance.id);
    }
    #getLastScanForInstance(instance) {
        const attachedVolumes = this.#volumes.filter((volume) => instance.ebsIds.includes(volume.id));
        const scans = attachedVolumes.map((volume) => this.#getLastScanForVolume(volume));
        const scansTime = scans.map((scan) => scan?.getTime() ?? 0);
        return new Date(Math.max(...scansTime));
    }
    #mapVolumeToScans(volume) {
        const scans = this.#filterScansForVolume(volume, this.#lastScans);
        return { volume, scans };
    }
    #getVolumeWithScans() {
        return this.#volumes.map(this.#mapVolumeToScans.bind(this));
    }
    #getLastScanForVolume(volume) {
        const scan = this.#volumesWithScans.find((scan) => scan.volume.id === volume.id);
        if (!scan) {
            return null;
        }
        const scansTime = scan.scans.map(({ createdAt }) => createdAt.getTime());
        return new Date(Math.max(...scansTime));
    }
    #findInstancesMatchingFilters(filters) {
        return this.#getActiveInstancesWithThreats().filter((instance) => {
            if (!this.#matchesIncludeFilter(filters.accountIds, instance.awsAccountId)) {
                return false;
            }
            if (!this.#matchesIncludeFilter(filters.regions, instance.awsRegion)) {
                return false;
            }
            if (filters.malwaresList &&
                !this.#getInstanceThreatByKind(instance, types_2.ThreatKind.MALWARE).some((malware) => filters.malwaresList?.includes(malware))) {
                return false;
            }
            if (filters.ransomwaresList &&
                !this.#getInstanceThreatByKind(instance, types_2.ThreatKind.RANSOMWARE).some((ransomware) => filters.ransomwaresList?.includes(ransomware))) {
                return false;
            }
            if (filters.fsCheck &&
                !this.#getInstanceThreatByKind(instance, types_2.ThreatKind.FILESYSTEM_ERROR).some((fs) => filters.fsCheck?.includes(fs))) {
                return false;
            }
            return true;
        });
    }
    #matchesIncludeFilter(filters, value) {
        if (!filters) {
            return true;
        }
        if (!value) {
            return false;
        }
        return filters.includes(value);
    }
    #getInstanceThreatByKind(instance, kind) {
        return this.#threats
            .filter((threat) => {
            const isInstanceThreat = threat.source.asset?.assetId === instance.id ||
                threat.source.assetItem?.assetItemId === instance.id;
            return isInstanceThreat && threat.kind === kind;
        })
            .map((threat) => threat.name);
    }
    #isRansomwareScanTarget(scanTarget, volume) {
        if (scanTarget instanceof ransomware_1.RansomwareScanTarget) {
            if ('assetId' in scanTarget.target) {
                return scanTarget.target.assetId === volume.id;
            }
            if (scanTarget instanceof ransomware_1.RansomwareScanBackup) {
                return scanTarget.source.asset?.backupAssetId === volume.id;
            }
        }
    }
    #isMalwareScanTarget(scanTarget, volume) {
        if (scanTarget instanceof malware_1.MalwareScanTarget) {
            if ('assetId' in scanTarget.target) {
                return scanTarget.target.assetId === volume.id;
            }
            if (scanTarget instanceof malware_1.MalwareScanBackup) {
                return scanTarget.source.asset?.backupAssetId === volume.id;
            }
        }
    }
    #isFilesystemScanTarget(scanTarget, volume) {
        if (scanTarget instanceof filesystem_check_1.FilesystemScanTarget) {
            if ('assetId' in scanTarget.target) {
                return scanTarget.target.assetId === volume.id;
            }
            if (scanTarget instanceof filesystem_check_1.FilesystemScanBackup) {
                return scanTarget.source.asset?.backupAssetId === volume.id;
            }
        }
    }
    #filterScansForVolume(volume, scans) {
        return scans.filter((scan) => {
            const ransomwareScanMatches = this.#isRansomwareScanTarget(scan.scanTarget, volume);
            const malwareScanMatches = this.#isMalwareScanTarget(scan.scanTarget, volume);
            const filesystemScanMatches = this.#isFilesystemScanTarget(scan.scanTarget, volume);
            return (ransomwareScanMatches || malwareScanMatches || filesystemScanMatches);
        });
    }
}
exports.ListInstancesWithThreats = ListInstancesWithThreats;
