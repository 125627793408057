import ActionInterface from '@lib/interfaces/action.interface'
import SourceVaultsState from '@store/states/source-vaults.state'
import { SET_BACKUP_TIMELINE_STATS } from '@store/actions/source-vaults.action'

const initialState: SourceVaultsState = {
  backupTimelineStats: {},
}

function SourceVaultsReducer(
  prevState: SourceVaultsState = initialState,
  action: ActionInterface
): SourceVaultsState {
  switch (action.type) {
    case SET_BACKUP_TIMELINE_STATS:
      return {
        ...prevState,
        backupTimelineStats: {
          ...prevState.backupTimelineStats,
          ...action.payload,
        },
      }

    default:
      return prevState
  }
}

export default SourceVaultsReducer
