import { FormPeriodInterface } from '@lib/interfaces/form/form-period.interface'

export const getAddRetentionPolicyName = (state: any): string =>
  state.addRetentionPolicyState.retentionPolicyName
export const getRecoveryPeriod = (state: any): FormPeriodInterface =>
  state.addRetentionPolicyState.recoveryPeriod
export const getPolicyRetentionAccountIds = (state: any): any =>
  state.addRetentionPolicyState.policyRetentionAccountIds
export const getPolicyRetentionRegions = (state: any): any =>
  state.addRetentionPolicyState.policyRetentionRegions
export const getPolicyRetentionVaults = (state: any): any =>
  state.addRetentionPolicyState.policyRetentionVaults
export const getPolicyRetentionBackupTypes = (state: any): any =>
  state.addRetentionPolicyState.policyRetentionBackupTypes
export const getPolicyRetentionAssetSources = (state: any): any =>
  state.addRetentionPolicyState.policyRetentionAssetSources
export const getPolicyRetentionTags = (state: any): any =>
  state.addRetentionPolicyState.policyRetentionTags
export const getPolicyRetentionAssetsSelectedFilters = (state: any) =>
  state.addRetentionPolicyState.assetsSelectedFilters
