import React from 'react'

function CalendarIcon() {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 0.708496H3.66667V3.54183H2.25V0.708496ZM9.33333 0.708496H10.75V3.54183H9.33333V0.708496Z"
        fill="#32B6F3"
      />
      <path
        d="M11.4583 2.125H1.54167C0.761394 2.125 0.125 2.76139 0.125 3.54167V13.4583C0.125 14.2386 0.761394 14.875 1.54167 14.875H11.4583C12.2386 14.875 12.875 14.2386 12.875 13.4583V3.54167C12.875 2.76139 12.2386 2.125 11.4583 2.125ZM11.4583 13.4583H1.54167V5.66667H11.4583V13.4583Z"
        fill="#32B6F3"
      />
    </svg>
  )
}

export default CalendarIcon
