"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OvaServer = void 0;
const asset_1 = require("../../asset");
class OvaServer extends asset_1.Asset {
    #ovaAccountId;
    #ccAssetId;
    #backupProvider;
    #backupProviderAssetId;
    #name;
    #osName;
    constructor(parameters) {
        const { ovaAccountId, ccAssetId, backupProvider, backupProviderAssetId, name, osName, ...assetParameters } = parameters;
        super(assetParameters);
        this.#ovaAccountId = ovaAccountId;
        this.#ccAssetId = ccAssetId;
        this.#backupProvider = backupProvider;
        this.#backupProviderAssetId = backupProviderAssetId;
        this.#name = name;
        this.#osName = osName;
    }
    get ovaAccountId() {
        return this.#ovaAccountId;
    }
    get ccAssetId() {
        return this.#ccAssetId;
    }
    get backupProvider() {
        return this.#backupProvider;
    }
    get backupProviderAssetId() {
        return this.#backupProviderAssetId;
    }
    get name() {
        return this.#name;
    }
    get osName() {
        return this.#osName;
    }
    get canBeDirectlyScanned() {
        return false;
    }
    getHumanReadableType() {
        return 'Server';
    }
}
exports.OvaServer = OvaServer;
