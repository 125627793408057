import { VIMatrix, VIRow } from '@lib/engine-types'
import { SelectSpecificPathsInterface } from '@lib/interfaces/assets-s3-select.interface'
import PaginationModel from '@lib/models/pagination.model'
import { StoreReturnType } from '@store/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { EFS, S3Bucket } from '@lib/models/assets'

export const getAssetsSelectAllFilters = (state: StoreReturnType): VIRow =>
  state.assetsSelectState.allFilters
export const getAssetsSelectFilters = (state: StoreReturnType): VIRow =>
  state.assetsSelectState.filters
export const getAssetsSelectPagination = (
  state: StoreReturnType
): PaginationModel => state.assetsSelectState.pagination
export const getAssetsSelectSearch = (state: StoreReturnType): string =>
  state.assetsSelectState.search
export const getAssetsSelect = (
  state: any
): Array<AssetWithRelatedAssets<Asset>> => state.assetsSelectState.assets
export const getSelectedAssetsSelect = (
  state: any
): Array<AssetWithRelatedAssets<Asset>> =>
  state.assetsSelectState.selectedAssets
export const getAssetsTableTabsDataInit = (
  state: any
): Record<string, VIMatrix> => state.assetsSelectState.tableTabsDataInit
export const getAssetsTableTabsData = (
  state: StoreReturnType
): Record<string, VIMatrix> => state.assetsSelectState.tableTabsData
export const getMissingSelectedAssets = (state: StoreReturnType): VIMatrix =>
  state.assetsSelectState.missingSelectedAssets
export const getAssetsSelectEnabledTabs = (
  state: StoreReturnType
): Array<string> => state.assetsSelectState.tabsEnabled
export const getAssetsSelectTabSelectedNum = (state: StoreReturnType): number =>
  state.assetsSelectState.tabSelectedNum
export const getAssetsMarker = (state: StoreReturnType): number =>
  state.assetsSelectState.assetsMarker
export const getAssetTypeTabSelectedNum = (state: StoreReturnType): number =>
  state.assetsSelectState.tabAssetTypeSelectedNum

export const getS3AssetsSelectAllFilters = (state: StoreReturnType): VIRow =>
  state.assetsSelectState.s3AllFilters
export const getS3AssetsSelectFilters = (state: StoreReturnType): VIRow =>
  state.assetsSelectState.s3Filters
export const getS3AssetsSelectPagination = (
  state: StoreReturnType
): PaginationModel => state.assetsSelectState.s3Pagination
export const getS3AssetsSelectSearch = (state: StoreReturnType): string =>
  state.assetsSelectState.s3Search
export const getS3AssetsSelect = (
  state: any
): Array<AssetWithRelatedAssets<S3Bucket>> => state.assetsSelectState.s3Assets
export const getS3SelectedAssetsSelect = (
  state: any
): Array<AssetWithRelatedAssets<Asset>> =>
  state.assetsSelectState.s3SelectedAssets
export const getS3AssetsTableDataInit = (state: StoreReturnType): VIMatrix =>
  state.assetsSelectState.tableS3DataInit
export const getS3AssetsTableData = (state: StoreReturnType): VIMatrix =>
  state.assetsSelectState.tableS3Data
export const getS3SelectedAllPaths = (state: StoreReturnType): Array<string> =>
  state.assetsSelectState.s3SelectedAllPaths
export const getS3SelectedSpecificPaths = (
  state: any
): Array<SelectSpecificPathsInterface> =>
  state.assetsSelectState.s3SelectedSpecificPaths
export const getIsUnprotectedAssets = (state: StoreReturnType): boolean =>
  state.assetsSelectState.isUnprotectedAssets

//efs assets
export const getEfsAssetsSelectAllFilters = (state: StoreReturnType): VIRow =>
  state.assetsSelectState.efsAllFilters
export const getEfsAssetsSelectFilters = (state: StoreReturnType): VIRow =>
  state.assetsSelectState.efsFilters
export const getEfsAssetsSelectPagination = (
  state: StoreReturnType
): PaginationModel => state.assetsSelectState.efsPagination
export const getEfsAssetsSelectSearch = (state: StoreReturnType): string =>
  state.assetsSelectState.efsSearch
export const getEfsAssetsSelect = (
  state: any
): Array<AssetWithRelatedAssets<EFS>> => state.assetsSelectState.efsAssets
export const getEfsSelectedAssetsSelect = (
  state: any
): Array<AssetWithRelatedAssets<Asset>> =>
  state.assetsSelectState.efsSelectedAssets
export const getEfsAssetsTableDataInit = (state: StoreReturnType): VIMatrix =>
  state.assetsSelectState.tableEfsDataInit
export const getEfsAssetsTableData = (state: StoreReturnType): VIMatrix =>
  state.assetsSelectState.tableEfsData
export const getEfsSelectedAllPaths = (state: StoreReturnType): Array<string> =>
  state.assetsSelectState.efsSelectedAllPaths
export const getEfsSelectedSpecificPaths = (
  state: any
): Array<SelectSpecificPathsInterface> =>
  state.assetsSelectState.efsSelectedSpecificPaths
export const getAssetIdsMissingInLiveAssets = (
  state: StoreReturnType
): Array<string> => state.assetsSelectState.assetIdsMissingInLiveAssets

export const getAssetsLoadingMarker = (state: StoreReturnType): number =>
  state.assetsSelectState.assetsLoadingMarker
