import ValueInterface from '@lib/interfaces/value.interface'
import {
  ActiveEfsThreatsFilterData,
  ActiveInstancesThreatsFilterData,
  ActiveS3ThreatsFilterData,
  ActiveVolumesThreatsFilterData,
  AssetItemsFilterData,
  AssetsEfsSelectedFilterData,
  AssetsFilterData,
  AssetsS3SelectedFilterData,
  AssetsSelectedFilterData,
  AssetsVulnerabilityFilterData,
  EbsVulnerabilitiesFilterData,
  Ec2InventoryFilterData,
  EfsInventoryFilterData,
  FilterData,
  FilterId,
  FilterIdDashboard,
  FsCheckScanReportFilterData,
  IscanReportFilterData,
  JobFilterId,
  JobStatusFilterData,
  QuarantinedVolumesFilterData,
  RecoveryExposureFilterData,
  ReportFilterData,
  S3BucketInventoryFilterData,
  SnapshotsInventoryFilterData,
  SnapshotsVulnerabilitiesFilterData,
  SnapshotVulnerabilitiesFilterData,
  VolumeInventoryFilterData,
  VolumeSnapshotCoverageFilterData,
} from '@lib/constants/grpc/filter.constant'
import { VIRow } from '@lib/engine-types'
import JobKindConstant, {
  getJobKindName,
} from '@lib/constants/jobs/job-kind.constant'
import DataItemKindConstant from '@lib/constants/data/data-item/data-item-kind.constant'
import {
  FilterIdForReports,
  ReportFilterId,
} from '@lib/constants/grpc/report-filter.constant'
import SystemHelper from '@lib/helpers/system.helper'

abstract class FilterFactory {
  public static buildAssetsFromGrpc(params: any): ValueInterface {
    const mainFilter = FilterData.get(params.id as FilterId)
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }
    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)
    return result
  }

  public static buildAssetsSelectedFromGrpc(params: any): ValueInterface {
    const mainFilter = AssetsSelectedFilterData.get(params.id as FilterId)
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }
    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)
    return result
  }

  public static buildS3AssetsSelectedFromGrpc(params: any): ValueInterface {
    const mainFilter = AssetsS3SelectedFilterData.get(params.id as FilterId)
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }
    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)
    return result
  }

  public static buildEfsAssetsSelectedFromGrpc(params: any): ValueInterface {
    const mainFilter = AssetsEfsSelectedFilterData.get(params.id as FilterId)
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }
    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)
    return result
  }

  public static buildJobFiltersFromGrpc(params: any): ValueInterface {
    const filter = JobStatusFilterData.get(params.id as JobFilterId)
    if (!filter) {
      SystemHelper.throwErrorInLocalEnv(
        'Cannot parse the job kind in the factory'
      )

      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }
    const result = filter.value
    result.options = filter.getFieldFunc(params)
    return result
  }

  public static buildFullDataItemsFromGrpc(params: any): VIRow {
    const result: VIRow = []

    // build kinds list filter data
    const kindsList = params.kindFiltersList.map((kind: number) => {
      const kindParsed = DataItemKindConstant.getByValue(kind)
      return {
        type: 1,
        name: kind,
        label: kindParsed.label,
        value: false,
      }
    })

    // adding to the final filters set
    result.push({
      name: 'kinds',
      value: 'kinds',
      label: 'Data Item Kind',
      type: 1,
      options: kindsList,
    })

    return result
  }

  public static buildAntiMalwareFromGrpc(params: any): ValueInterface {
    const mainFilter = ReportFilterData.get(params.id as ReportFilterId)
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }
    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)
    return result
  }

  public static buildIscanReportFiltersFromGrpc(params: any): ValueInterface {
    const mainFilter = IscanReportFilterData.get(
      params.id as FilterIdForReports
    )
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }
    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)
    return result
  }

  public static getBackupTimelineJobFilters = () => ({
    name: 'kinds',
    options: [
      {
        label: 'All assets',
        name: 'All assets',
        value: -1,
      },
      {
        label: getJobKindName(JobKindConstant.EBS_BACKUP),
        name: String(JobKindConstant.EBS_BACKUP),
        value: JobKindConstant.EBS_BACKUP,
      },
      {
        label: getJobKindName(JobKindConstant.STREAM_BACKUP),
        name: String(JobKindConstant.STREAM_BACKUP),
        value: JobKindConstant.STREAM_BACKUP,
      },
      {
        label: getJobKindName(JobKindConstant.EC2_BACKUP),
        name: String(JobKindConstant.EC2_BACKUP),
        value: JobKindConstant.EC2_BACKUP,
      },
      {
        label: getJobKindName(JobKindConstant.FILE_BACKUP),
        name: String(JobKindConstant.FILE_BACKUP),
        value: JobKindConstant.FILE_BACKUP,
      },
      {
        label: getJobKindName(JobKindConstant.BLOCK_BACKUP),
        name: String(JobKindConstant.BLOCK_BACKUP),
        value: JobKindConstant.BLOCK_BACKUP,
      },
      {
        label: getJobKindName(JobKindConstant.POSTGRES_BACKUP),
        name: String(JobKindConstant.POSTGRES_BACKUP),
        value: JobKindConstant.POSTGRES_BACKUP,
      },
      {
        label: getJobKindName(JobKindConstant.S3_BACKUP),
        name: String(JobKindConstant.S3_BACKUP),
        value: JobKindConstant.S3_BACKUP,
      },
    ],
    value: -1,
  })

  public static getBackupTimelineDateRangeFilters = () => ({
    name: 'daterange',
    options: [
      {
        name: 'Past 3 hours',
        label: 'Past 3 hours',
        value: 0,
        extraValue: 3,
        supplementalValue: 15,
      },
      {
        name: 'Past 6 hours',
        label: 'Past 6 hours',
        value: 1,
        extraValue: 6,
        supplementalValue: 30,
      },
      {
        name: 'Past day',
        label: 'Past day',
        value: 2,
        extraValue: 24,
        supplementalValue: 4 * 30,
      },
      {
        name: 'Past week',
        label: 'Past week',
        value: 3,
        extraValue: 7 * 24,
        supplementalValue: 7 * 4 * 30,
      },
      {
        name: 'Past 2 weeks',
        label: 'Past 2 weeks',
        value: 4,
        extraValue: 14 * 24,
        supplementalValue: 14 * 4 * 30,
      },
      {
        name: 'Past month',
        label: 'Past month',
        value: 5,
        extraValue: 30 * 24,
        supplementalValue: 30 * 4 * 30,
      },
    ],
    value: 0,
  })

  public static getWebhooksDateRangeFilters = () => ({
    name: 'daterange',
    options: [
      {
        name: 'Past 3 hours',
        label: 'Past 3 hours',
        value: 0,
        extraValue: 3,
        supplementalValue: 6,
      },
      {
        name: 'Past 6 hours',
        label: 'Past 6 hours',
        value: 1,
        extraValue: 6,
        supplementalValue: 6,
      },
      {
        name: 'Past day',
        label: 'Past day',
        value: 2,
        extraValue: 24,
        supplementalValue: 6,
      },
      {
        name: 'Past week',
        label: 'Past week',
        value: 3,
        extraValue: 7 * 24,
        supplementalValue: 7,
      },
      {
        name: 'Past 2 weeks',
        label: 'Past 2 weeks',
        value: 4,
        extraValue: 14 * 24,
        supplementalValue: 7,
      },
      {
        name: 'Past month',
        label: 'Past month',
        value: 5,
        extraValue: 30 * 24,
        supplementalValue: 6,
      },
    ],
    value: 0,
  })

  public static buildRegionFilter(region: string): ValueInterface {
    return {
      name: 'regions',
      options: [
        {
          name: region,
        },
      ],
    }
  }

  public static buildKindFilter(kind: string): ValueInterface {
    return {
      name: 'kinds',
      options: [
        {
          name: kind,
        },
      ],
    }
  }

  public static buildVolumeSnapshotCoverageFilter(params: any): ValueInterface {
    const mainFilter = VolumeSnapshotCoverageFilterData.get(
      params.id as FilterIdDashboard
    )
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }

    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)

    return result
  }

  public static buildVolumeInventoryFilter(params: any): ValueInterface {
    const mainFilter = VolumeInventoryFilterData.get(
      params.id as FilterIdDashboard
    )
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }

    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)

    return result
  }

  public static buildSnapshotsInventoryFilter(params: any): ValueInterface {
    const mainFilter = SnapshotsInventoryFilterData.get(
      params.id as FilterIdDashboard
    )
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }
    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)

    return result
  }

  public static buildSnapshotVulnerabilitiesFilter(
    params: any
  ): ValueInterface {
    const mainFilter = SnapshotVulnerabilitiesFilterData.get(
      params.id as FilterIdDashboard
    )
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }
    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)

    return result
  }

  public static buildEbsVulnerabilitiesFilter(params: any): ValueInterface {
    const mainFilter = EbsVulnerabilitiesFilterData.get(
      params.id as FilterIdDashboard
    )
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }
    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)

    return result
  }

  public static buildQuarantinedVolumesFilter(params: any): ValueInterface {
    const mainFilter = QuarantinedVolumesFilterData.get(
      params.id as FilterIdDashboard
    )

    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }

    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)

    return result
  }

  public static buildEc2InventoryFilter(params: any): ValueInterface {
    const mainFilter = Ec2InventoryFilterData.get(
      params.id as FilterIdDashboard
    )
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }

    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)

    return result
  }

  public static buildS3BucketInventoryFilter(params: any): ValueInterface {
    const mainFilter = S3BucketInventoryFilterData.get(
      params.id as FilterIdDashboard
    )
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }

    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)

    return result
  }

  public static buildEfsInventoryFilter(params: any): ValueInterface {
    const mainFilter = EfsInventoryFilterData.get(
      params.id as FilterIdDashboard
    )
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }

    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)

    return result
  }

  public static buildActiveInstancesThreatsFilter(params: any): ValueInterface {
    const mainFilter = ActiveInstancesThreatsFilterData.get(
      params.id as FilterIdDashboard
    )
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }

    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)
    return result
  }

  public static buildActiveVolumesThreatsFilter(params: any): ValueInterface {
    const mainFilter = ActiveVolumesThreatsFilterData.get(
      params.id as FilterIdDashboard
    )
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }

    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)
    return result
  }

  public static buildActiveEfsThreatsFilter(params: any): ValueInterface {
    const mainFilter = ActiveEfsThreatsFilterData.get(
      params.id as FilterIdDashboard
    )
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }

    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)
    return result
  }

  public static buildActiveS3ThreatsFilter(params: any): ValueInterface {
    const mainFilter = ActiveS3ThreatsFilterData.get(
      params.id as FilterIdDashboard
    )
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }

    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)
    return result
  }

  public static buildActiveThreatsFilter(params: any): ValueInterface {
    const mainFilter = ActiveVolumesThreatsFilterData.get(
      params.id as FilterIdDashboard
    )
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }

    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)
    return result
  }

  public static buildAssetsVulnerabilityFilter(params: any): ValueInterface {
    const mainFilter = AssetsVulnerabilityFilterData.get(
      params.id as FilterIdDashboard
    )
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }

    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)

    return result
  }

  public static buildSnapshotsVulnerabilityFilter(params: any): ValueInterface {
    const mainFilter = SnapshotsVulnerabilitiesFilterData.get(
      params.id as FilterIdDashboard
    )

    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }

    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)

    return result
  }

  public static buildRecoveryExposureFilter(params: any): ValueInterface {
    const mainFilter = RecoveryExposureFilterData.get(
      params.id as FilterIdDashboard
    )

    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }

    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)

    return result
  }

  public static buildAssetsFilter(params: any): ValueInterface {
    const mainFilter = AssetsFilterData.get(params.id as FilterId)
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }

    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)

    return result
  }

  public static buildAssetItemsFilter(params: any): ValueInterface {
    const mainFilter = AssetItemsFilterData.get(params.id as FilterIdDashboard)
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }

    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)

    return result
  }

  public static buildFsCheckScanReportFiltersFromGrpc(
    params: any
  ): ValueInterface {
    const mainFilter = FsCheckScanReportFilterData.get(
      params.id as FilterIdForReports
    )
    if (!mainFilter) {
      return {
        name: '',
        value: '',
        label: '',
        options: [],
      }
    }
    const result = mainFilter.value
    result.options = mainFilter.getFieldFunc(params)
    return result
  }
}

export default FilterFactory
