import ReportDateRangeConstant, {
  getReportDateRangeName,
} from '@lib/constants/report-date-range.constant'
import TimeHelper from '@lib/helpers/time.helper'
import ActionInterface from '@lib/interfaces/action.interface'
import {
  SET_REQUEST_BACKUP_AUDIT_DATA,
  SET_REQUEST_BACKUP_AUDIT_PERIOD,
} from '@store/actions/reports.action'
import BackupAuditReportState from '@store/states/backup-audit-reports.state'

const initialState: BackupAuditReportState = {
  reportPeriod: {
    period: {
      name: getReportDateRangeName(ReportDateRangeConstant.ThisMonth),
      value: ReportDateRangeConstant.ThisMonth,
    },
    dateRange: TimeHelper.currentMonthDateRange(),
  },
  reportData: undefined,
}

function backupAuditReportReducer(
  prevState = initialState,
  action: ActionInterface
) {
  switch (action.type) {
    case SET_REQUEST_BACKUP_AUDIT_PERIOD:
      return {
        ...prevState,
        reportPeriod: action.payload,
      }

    case SET_REQUEST_BACKUP_AUDIT_DATA:
      return {
        ...prevState,
        reportData: action.payload,
      }
    default:
      return prevState
  }
}

export default backupAuditReportReducer
