import { EngineCallback, VINumberMap, VIRow } from '@lib/engine-types'

export enum AssetsEntityType {
  Expand = 1,
  Checkbox = 2,
  Switch = 3,
  Link = 4,
  Tags = 5,
  Icon = 6,
  Text = 7,
  Date = 8,
}

export interface OnChangeData {
  index: number
  value: string | number | boolean
  row: VIRow
  type: AssetsEntityType
}

export interface SettingsClickData {
  index: number
  value: string | number | boolean
  row: VIRow
}

export interface HeadComponentData {
  data: VIRow
  onChange: EngineCallback<OnChangeData>
  onSettingsClick: EngineCallback<SettingsClickData>
  columnState: Array<number>
  forceTemplate?: Array<number>
  skipTemplate?: Array<number>
}

export interface RowComponentData {
  data: VIRow
  columnState: Array<number>
  forceTemplate?: Array<number>
  menu?: Array<string>
  onMenuClick?: EngineCallback<OnMenuClickData>
  onChange: EngineCallback<OnChangeData>
  showMenu: boolean
  columnDrawMapper?: VINumberMap
  className?: string
}

export interface OnMenuClickData {
  chosenMenu: string
  data: VIRow
}
