"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.instancesFactory = void 0;
const fishery_1 = require("fishery");
const models_1 = require("../../../models");
const common_1 = require("./common");
const asset_threat_statuses_1 = require("./asset_threat_statuses");
const instance_model_1 = require("../../../models/inventory.model/instance.model");
class InstancesFactory extends fishery_1.Factory {
    withNoBackups() {
        return this.transient({
            threatStatus: null,
        });
    }
    healthy() {
        return this.transient({
            firstGenericBackupAt: Date.now() - 1000 * 60 * 60 * 24 * 30,
            lastGenericBackupAt: Date.now(),
            threatStatus: asset_threat_statuses_1.assetThreatStatusesFactory.healthy().build(),
        });
    }
    withMalware() {
        return this.transient({
            firstGenericBackupAt: Date.now() - 1000 * 60 * 60 * 24 * 30,
            lastGenericBackupAt: Date.now(),
            threatStatus: asset_threat_statuses_1.assetThreatStatusesFactory
                .unhealthy()
                .instanceWithMalware()
                .build(),
        });
    }
    withRansomware() {
        return this.transient({
            firstGenericBackupAt: Date.now() - 1000 * 60 * 60 * 24 * 30,
            lastGenericBackupAt: Date.now(),
            threatStatus: asset_threat_statuses_1.assetThreatStatusesFactory
                .unhealthy()
                .instanceWithRansomware()
                .build(),
        });
    }
    withCorruptedFileSystems() {
        return this.transient({
            firstGenericBackupAt: Date.now() - 1000 * 60 * 60 * 24 * 30,
            lastGenericBackupAt: Date.now(),
            threatStatus: asset_threat_statuses_1.assetThreatStatusesFactory
                .unhealthy()
                .instanceWithCorruptedFileSystem()
                .build(),
        });
    }
    withLastBackupAt(lastGenericBackupAt) {
        return this.healthy().transient({ lastGenericBackupAt });
    }
    withFirstBackupAt(firstGenericBackupAt) {
        return this.healthy().transient({ firstGenericBackupAt });
    }
    withSla(duration) {
        return this.transient({
            coveringPoliciesSLAs: new Map([['policyId', duration]]),
        });
    }
    running() {
        return this.transient({
            state: models_1.InstanceState.INSTANCE_STATE_RUNNING,
        });
    }
}
const instancesFactory = InstancesFactory.define(({ sequence, transientParams }) => {
    const region = transientParams.region ?? (0, common_1.awsRegion)();
    const imageName = transientParams.imageName ?? (0, common_1.awsAmiName)();
    return new models_1.InstanceModel({
        firstGenericBackupAt: transientParams.firstGenericBackupAt ?? null,
        lastGenericBackupAt: transientParams.lastGenericBackupAt ?? null,
        accountId: transientParams.accountId ?? (0, common_1.awsAccountId)(),
        cloudConnectorId: transientParams.cloudConnectorId ?? (0, common_1.uuid)(),
        cloudProviderInstanceId: transientParams.cloudProviderInstanceId ?? (0, common_1.awsInstanceId)(),
        cost: transientParams.cost ?? (0, common_1.awsCost)(),
        coveredByPolicies: transientParams.coveredByPolicies ?? [],
        createdAt: transientParams.createdAt ?? Date.now(),
        id: transientParams.id ?? (0, common_1.uuid)(),
        imageId: transientParams.imageId ?? (0, common_1.awsAmiId)(),
        imageLocation: transientParams.imageLocation ?? (0, common_1.awsAmiLocation)(imageName),
        imageName,
        name: transientParams.name ?? (0, common_1.name)('Instance', sequence),
        osKind: transientParams.osKind ?? (0, common_1.osKind)(),
        region,
        coveringPoliciesSLAs: transientParams.coveringPoliciesSLAs ?? new Map(),
        coveredByPausedPolicies: transientParams.coveredByPausedPolicies ?? [],
        size: transientParams.size ?? '',
        state: transientParams.state ?? (0, common_1.instanceState)(),
        tags: transientParams.tags ?? new Map(),
        threatStatus: transientParams.threatStatus ?? null,
        volumeIds: transientParams.volumeIds ?? [],
        vpcId: transientParams.vpcId ?? '',
        lastGenericBackupKind: instance_model_1.InstanceGenericBackupKind.ELASTIO,
        associatedRetentionPolicies: transientParams.associatedRetentionPolicies ?? [],
    });
});
exports.instancesFactory = instancesFactory;
