import ScheduleInterface from '@lib/interfaces/schedule.interface'
import IntegrityScanInterface from '@lib/interfaces/integrity-scan.interface'
import { VIRow } from '@lib/engine-types'
import { SelectedVariant } from '@lib/constants/app.constant'
import {
  ProtectNewImmediately,
  SelectedCloudConnectorVariant,
  SnapshotImport,
} from '@lib/constants/policies.constant'
import { IntegrityCheckOptions } from '@lib/constants/integrity-check-options.constant'
import { VaultForPolicy } from '@lib/interfaces/vault/vaults-policy'
import { IntegrityScanOptions } from '@lib/constants/integrity-scan-options.constant'
import { KeepDataCopyInterface } from '@lib/interfaces/keep-data-copy.interface'
import ScanWithTimestampsInterface from '@lib/interfaces/scan-with-timestamps.interface'

export const SET_ADD_POLICY_NAME = 'SET_ADD_PLAN_NAME'
export const SET_ADD_POLICY_SCHEDULE = 'SET_ADD_PLAN_SCHEDULE'
export const SET_ADD_INTEGRITY_SCAN = 'SET_ADD_INTEGRITY_SCAN'
export const SET_INTEGRITY_CHECK = 'SET_INTEGRITY_CHECK'
export const SET_INTEGRITY_SCAN_OPTION = 'SET_INTEGRITY_SCAN_OPTION'
export const RESET_INTEGRITY_SCAN = 'RESET_INTEGRITY_SCAN'
export const SET_KEEP_DATA_COPY = 'SET_KEEP_DATA_COPY'
export const ADD_POLICY = 'ADD_PLAN'
export const SET_ADD_POLICY_TAGS = 'SET_ADD_PLAN_TAGS'
export const SET_ADD_SELECTED_VARIANT = 'SET_ADD_SELECTED_VARIANT'
export const SET_SNAPSHOT_IMPORT_VARIANT = 'SET_SNAPSHOT_IMPORT_VARIANT'
export const SET_PROTECT_IMMEDIATELY = 'SET_PROTECT_IMMEDIATELY'
export const SET_VAULTS_LIST = 'SET_VAULTS_LIST'
export const SET_SKIP_EBS_BACKUP = 'SET_SKIP_EBS_BACKUP'
export const SET_ENTROPY_DETECTION_CONFIGURATION =
  'SET_ENTROPY_DETECTION_CONFIGURATION'

export const SET_ADD_SELECTED_CLOUD_CONNECTORS_VARIANT =
  'SET_ADD_SELECTED_CLOUD_CONNECTORS_VARIANT'
export const SET_ADD_SELECTED_CLOUD_CONNECTORS =
  'SET_ADD_SELECTED_CLOUD_CONNECTORS'
export const SET_SCAN_WITH_TIMESTAMPS = 'SET_SCAN_WITH_TIMESTAMPS'
export const RESET_SCAN_WITH_TIMESTAMPS = 'RESET_SCAN_WITH_TIMESTAMPS'

export const setAddPolicyName = (policyName: string) => ({
  type: SET_ADD_POLICY_NAME,
  payload: policyName,
})

export const setAddPolicySchedule = (schedule: ScheduleInterface) => ({
  type: SET_ADD_POLICY_SCHEDULE,
  payload: schedule,
})

export const setAddIntegrityScan = (payload: IntegrityScanInterface) => ({
  type: SET_ADD_INTEGRITY_SCAN,
  payload,
})

export const setIntegrityScanOption = (payload: IntegrityScanOptions) => ({
  type: SET_INTEGRITY_SCAN_OPTION,
  payload,
})

export const setKeepDataCopy = (payload: KeepDataCopyInterface) => ({
  type: SET_KEEP_DATA_COPY,
  payload,
})

export const resetIntegrityScan = () => ({
  type: RESET_INTEGRITY_SCAN,
  payload: null,
})

export const setAddTags = (payload: VIRow) => ({
  type: SET_ADD_POLICY_TAGS,
  payload,
})

export const setAddSelectedVariant = (payload: SelectedVariant) => ({
  type: SET_ADD_SELECTED_VARIANT,
  payload,
})

export const setAddSelectedCloudConnectorsVariant = (
  payload: SelectedCloudConnectorVariant
) => ({
  type: SET_ADD_SELECTED_CLOUD_CONNECTORS_VARIANT,
  payload,
})

export const setAddSelectedCloudConnectors = (payload: VIRow) => ({
  type: SET_ADD_SELECTED_CLOUD_CONNECTORS,
  payload,
})

export const setSnapshotImportVariant = (payload: SnapshotImport) => ({
  type: SET_SNAPSHOT_IMPORT_VARIANT,
  payload,
})

export const setIntegrityCheck = (payload: IntegrityCheckOptions) => ({
  type: SET_INTEGRITY_CHECK,
  payload,
})

export const setProtectNewImmediately = (payload: ProtectNewImmediately) => ({
  type: SET_PROTECT_IMMEDIATELY,
  payload,
})

export const setSkipEbsBackup = (payload: boolean) => ({
  type: SET_SKIP_EBS_BACKUP,
  payload,
})

export const setAddPolicyVaults = (payload: Array<VaultForPolicy>) => ({
  type: SET_VAULTS_LIST,
  payload,
})

export const setScanWithTimestamps = (
  payload: ScanWithTimestampsInterface
) => ({
  type: SET_SCAN_WITH_TIMESTAMPS,
  payload,
})

export const resetScanWithTimestamps = () => ({
  type: RESET_SCAN_WITH_TIMESTAMPS,
  payload: null,
})

export const addPolicy = (executeImmediately: boolean) => ({
  type: ADD_POLICY,
  payload: executeImmediately,
})

export const setEntropyDetectionConfiguration = (payload: boolean) => ({
  type: SET_ENTROPY_DETECTION_CONFIGURATION,
  payload,
})
