function ArrowDropIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <g clipPath="url(#clip0_13292_70068)">
        <path
          d="M8.05834 9.75703L10.2167 11.9154C10.5417 12.2404 11.0667 12.2404 11.3917 11.9154L13.55 9.75703C14.075 9.23203 13.7 8.33203 12.9583 8.33203H8.64168C7.90001 8.33203 7.53334 9.23203 8.05834 9.75703Z"
          fill="#1A1F28"
        />
      </g>
      <defs>
        <clipPath id="clip0_13292_70068">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.800049)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArrowDropIcon
