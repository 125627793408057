import BaseDataConstant from '@lib/constants/data/base-data.constant'
import ValueInterface from '@lib/interfaces/value.interface'

class VolumeStatusConstantInner extends BaseDataConstant<ValueInterface> {
  get VOLUME_STATUS_UNKNOWN(): ValueInterface {
    return {
      name: 'Undefined',
      value: 0,
    }
  }

  get VOLUME_STATUS_AVAILABLE(): ValueInterface {
    return {
      name: 'Available',
      value: 1,
    }
  }

  get VOLUME_STATUS_CREATING(): ValueInterface {
    return {
      name: 'Creating',
      value: 2,
    }
  }

  get VOLUME_STATUS_DELETED(): ValueInterface {
    return {
      name: 'Deleted',
      value: 3,
    }
  }

  get VOLUME_STATUS_DELETING(): ValueInterface {
    return {
      name: 'Deleting',
      value: 4,
    }
  }

  get VOLUME_STATUS_ERROR(): ValueInterface {
    return {
      name: 'Error',
      value: 5,
    }
  }

  get VOLUME_STATUS_IN_USE(): ValueInterface {
    return {
      name: 'In use',
      value: 6,
    }
  }
}

const VolumeStatusConstant = VolumeStatusConstantInner.build(
  VolumeStatusConstantInner,
  'VOLUME_STATUS_'
)

export default VolumeStatusConstant
