import { NotificationsPromiseClient } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/notifications_grpc_web_pb'
import GrpcBaseService from '@lib/services/grpc/base/grpc-base.service'
import {
  DisconnectSlackFromTenantRequest,
  GetTenantNotificationSettingsRequest,
  UpdateTenantNotificationSettingsRequest,
} from 'blue-stack-libs/notifications-grpc-libs/js/notifications/notifications_pb'
import {
  SlackNotificationSettings,
  EmailNotificationSettings,
} from 'blue-stack-libs/notifications-grpc-libs/js/notifications/messages/tenant_settings_pb'
import { NotificationsConfig } from '@lib/interfaces/notifications.interface'

class GrpcTenantNotificationService extends GrpcBaseService {
  protected static client = new NotificationsPromiseClient('', null, null)
  protected static clientName = 'Notifications'

  public static async getTenantNotificationSettings() {
    const request = new GetTenantNotificationSettingsRequest()

    const result = await this.handleQuery(
      this.client.getTenantNotificationSettings,
      request
    )
    return result.toObject()
  }

  public static async updateTenantNotificationSettings(
    config: NotificationsConfig
  ) {
    const { slack, email } = config

    const request = new UpdateTenantNotificationSettingsRequest()

    if (!slack.isDisconnected) {
      const slackSettings = new SlackNotificationSettings()

      slackSettings.setSendErrors(slack.sendErrors)
      slackSettings.setSendInfo(slack.sendInfo)
      slackSettings.setSendWarnings(slack.sendWarnings)

      request.setSlackNotificationSettings(slackSettings)
    }

    const emailSettings = new EmailNotificationSettings()

    emailSettings.setSendErrors(email.sendErrors)
    emailSettings.setSendInfo(email.sendInfo)
    emailSettings.setSendWarnings(email.sendWarnings)

    if (email.emailsList?.length) {
      emailSettings.setEmailsList(email.emailsList)
    }

    request.setEmailNotificationSettings(emailSettings)

    const result = await this.handleQuery(
      this.client.updateTenantNotificationSettings,
      request
    )
    return result.toObject()
  }

  public static async disconnectSlackFromTenant() {
    const request = new DisconnectSlackFromTenantRequest()

    const result = await this.handleQuery(
      this.client.disconnectSlackFromTenant,
      request
    )
    return result.toObject()
  }
}

export default GrpcTenantNotificationService
