"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jobTenantFactory = void 0;
const job_1 = require("../../models/jobs/tenant/job");
const factories_1 = require("../../use_cases/tests/factories");
const fishery_1 = require("fishery");
const common_1 = require("./common");
const types_1 = require("../../models/jobs/tenant/job/types");
class JobTenantFactory extends fishery_1.Factory {
    setJobId(id) {
        return this.transient({ id });
    }
    setJobKind(kind) {
        return this.transient({ kind });
    }
    setJobStatus(status) {
        return this.transient({ status });
    }
}
exports.jobTenantFactory = JobTenantFactory.define(({ transientParams, sequence }) => {
    return new job_1.JobTenant({
        id: transientParams.id ?? (0, factories_1.uuid)(),
        kind: transientParams.kind ?? (0, common_1.randomValueFromEnum)(types_1.TenantJobKind),
        status: transientParams.status ?? (0, common_1.randomValueFromEnum)(types_1.TenantJobStatus),
        parentId: transientParams.parentId ?? (0, factories_1.uuid)(),
        name: transientParams.name ?? (0, factories_1.name)('Job', sequence),
        ccId: transientParams.ccId ?? (0, factories_1.uuid)(),
        startedAt: transientParams.startedAt ?? (0, common_1.date)(),
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        finishedAt: transientParams.finishedAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
        statusMessage: transientParams.statusMessage ?? (0, factories_1.name)('Status Message', sequence),
        childrenList: transientParams.childrenList ?? [],
    });
});
