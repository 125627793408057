"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.File = void 0;
const asset_item_1 = require("../../asset-items/asset-item");
class File extends asset_item_1.AssetItem {
    #path;
    constructor(parameters) {
        const { path, ...assetItemParameters } = parameters;
        super(assetItemParameters);
        this.#path = path;
    }
    get path() {
        return this.#path;
    }
}
exports.File = File;
