"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilesystemScanBackup = exports.FilesystemScanTarget = exports.FilesystemScanCheck = void 0;
const scan_1 = require("../scan");
const types_1 = require("./types");
class FilesystemScanCheck extends scan_1.Scan {
    #scanTarget;
    #status;
    #jobId;
    #finishedAt;
    #partitions;
    #threatIds;
    constructor(parameters) {
        const { scanTarget, status, jobId, finishedAt, partitions, threatIds, ...assetParameters } = parameters;
        super(assetParameters);
        this.#scanTarget = scanTarget;
        this.#status = status;
        this.#jobId = jobId;
        this.#finishedAt = finishedAt;
        this.#partitions = Object.freeze([...partitions]);
        this.#threatIds = Object.freeze([...threatIds]);
    }
    get scanTarget() {
        return this.#scanTarget;
    }
    get status() {
        return this.#status;
    }
    get jobId() {
        return this.#jobId;
    }
    get finishedAt() {
        return this.#finishedAt;
    }
    get partitions() {
        return [...this.#partitions];
    }
    get threatIds() {
        return [...this.#threatIds];
    }
    set threatIds(newThreatIds) {
        this.#threatIds = Object.freeze([...newThreatIds]);
    }
    get corruptedPartitionsCount() {
        return this.#partitions.filter((p) => p.checkStatus === types_1.CheckStatus.ERRORS_NOT_FIXED ||
            p.checkStatus === types_1.CheckStatus.FAILED).length;
    }
    get healthyPartitionsCount() {
        return this.#partitions.filter((p) => p.checkStatus === types_1.CheckStatus.NO_ERRORS ||
            p.checkStatus === types_1.CheckStatus.ERRORS_FIXED).length;
    }
    get targetIsBackup() {
        return this.scanTarget.target instanceof FilesystemScanBackup;
    }
    get isInfected() {
        return this.status === types_1.StatusScanFilesystemCheck.BAD;
    }
    get isClean() {
        return !this.isInfected;
    }
}
exports.FilesystemScanCheck = FilesystemScanCheck;
class FilesystemScanTarget {
    #target;
    constructor(target) {
        this.#target = target;
    }
    get target() {
        return this.#target;
    }
    get assetId() {
        return this.#target.assetId;
    }
}
exports.FilesystemScanTarget = FilesystemScanTarget;
class FilesystemScanBackup {
    #backupId;
    #source;
    #provider;
    #timestamp;
    constructor(parameters) {
        const { backupId, source, provider, timestamp } = parameters;
        this.#backupId = backupId;
        this.#source = source;
        this.#provider = provider;
        this.#timestamp = timestamp;
    }
    get backupId() {
        return this.#backupId;
    }
    get source() {
        return this.#source;
    }
    get provider() {
        return this.#provider;
    }
    get timestamp() {
        return this.#timestamp;
    }
    get assetId() {
        if (this.#source.asset) {
            return this.#source.asset.backupAssetId;
        }
        else {
            return this.#source.assetItem?.backupAssetId;
        }
    }
}
exports.FilesystemScanBackup = FilesystemScanBackup;
