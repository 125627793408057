import { Tenant } from 'blue-stack-libs/catalog-grpc-libs/js/catalog/messages/tenants_pb'
// eslint-disable-next-line import/no-extraneous-dependencies
import { TenantModel } from 'blues-corejs/dist'

abstract class TenantsServiceFactory {
  public static build(attrs: Tenant.AsObject) {
    return new TenantModel({
      name: attrs.name,
      vulnerabilities_enabled: attrs.vulnerabilitiesEnabled,
      created_at: attrs.createdAt?.seconds,
      updated_at: attrs.updatedAt?.seconds,
    })
  }

  public static buildFake(fakeAttrs: TenantModel) {
    return new TenantModel({
      name: fakeAttrs.getName(),
      vulnerabilities_enabled: fakeAttrs.getVulnerabilitiesEnabled(),
      created_at: fakeAttrs.getCreatedAt(),
      updated_at: fakeAttrs.getUpdatedAt(),
    })
  }
}

export default TenantsServiceFactory
