"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AWSAsset = void 0;
const asset_1 = require("../asset");
// Suggesting that there might be shared properties or behaviors among different AWS assets,
// and these can be placed in this abstract base class(EC2 instances, S3 buckets)
class AWSAsset extends asset_1.Asset {
    #awsId;
    #arn;
    #awsAccountId;
    #backupPolicies;
    #awsRegion;
    #tags;
    constructor(parameters) {
        const { awsId, arn, awsAccountId, awsRegion, backupPolicies, tags, ...assetParameters } = parameters;
        super(assetParameters);
        this.#awsId = awsId;
        this.#arn = arn;
        this.#awsAccountId = awsAccountId;
        this.#awsRegion = awsRegion;
        this.#backupPolicies = backupPolicies;
        this.#tags = tags;
    }
    get awsId() {
        return this.#awsId;
    }
    get arn() {
        return this.#arn;
    }
    get awsAccountId() {
        return this.#awsAccountId;
    }
    get awsRegion() {
        return this.#awsRegion;
    }
    get backupPolicies() {
        return [...this.#backupPolicies];
    }
    get tags() {
        return [...this.#tags];
    }
    get name() {
        const TAG_KEY = 'name';
        const tag = this.#tags.find((tag) => tag?.key?.toLowerCase() === TAG_KEY);
        if (!tag) {
            return '';
        }
        return tag.value;
    }
}
exports.AWSAsset = AWSAsset;
