import PreloaderConstants from '@lib/constants/preloader.constant'
import Router from 'next/router'
import GrpcPechkinService from '@lib/services/grpc/grpc-pechkin.service'
import { ADD_POLICY } from '@store/actions/add-policy.action'
import { emptyAction } from '@store/actions/default/empty.action'
import {
  sentryReThrowCatchHandler,
  toastRequestScenario,
} from '@store/epics/epic-func'
import {
  getAddIntegrityScan,
  getAddPolicyName,
  getAddPolicySchedule,
  getAddTags,
  getIntegrityCheck,
  getIntegrityScanOption,
  getIsEntropyDetectionEnabled,
  getKeepDataCopy,
  getProtectNewImmediately,
  getScanWithTimestamps,
  getSelectedCloudConnectors,
  getSelectedVariant,
  getSelectedVaultsList,
  getSkipEbsBackup,
  getSnapshotImportVariant,
} from '@store/selectors/add-policy.selector'
import {
  getEfsSelectedAllPaths,
  getEfsSelectedAssetsSelect,
  getEfsSelectedSpecificPaths,
  getS3SelectedAllPaths,
  getS3SelectedAssetsSelect,
  getS3SelectedSpecificPaths,
  getSelectedAssetsSelect,
} from '@store/selectors/assets-select.selector'
import ActionInterface from '@lib/interfaces/action.interface'

export const addPolicyEpic = (action$: any, state$: any) =>
  action$.pipe(
    toastRequestScenario(
      [ADD_POLICY],
      PreloaderConstants.ADD_POLICIES,
      (action: ActionInterface) => {
        const selectedVariant = getSelectedVariant(state$.value)
        const policyName = getAddPolicyName(state$.value)
        const schedule = getAddPolicySchedule(state$.value)
        const iscan = getAddIntegrityScan(state$.value)
        const selectedAssets = getSelectedAssetsSelect(state$.value)
        const policyTags = getAddTags(state$.value)
        const snapshotImportVariant = getSnapshotImportVariant(state$.value)
        const integrityCheck = getIntegrityCheck(state$.value)
        const protectNewImmediately = getProtectNewImmediately(state$.value)
        const vaultLists = getSelectedVaultsList(state$.value)
        const executeImmediately = action.payload
        const skipEbsBackup = getSkipEbsBackup(state$.value)
        const selectedS3Assets = getS3SelectedAssetsSelect(state$.value)
        const selectedS3AllPath = getS3SelectedAllPaths(state$.value)
        const selectedS3SpecificPath = getS3SelectedSpecificPaths(state$.value)
        const selectedEfsAssets = getEfsSelectedAssetsSelect(state$.value)
        const selectedEfsAllPath = getEfsSelectedAllPaths(state$.value)
        const selectedEfsSpecificPath = getEfsSelectedSpecificPaths(
          state$.value
        )
        const selectedCloudConnectors = getSelectedCloudConnectors(state$.value)
        const integrityScanOption = getIntegrityScanOption(state$.value)
        const keepDataCopy = getKeepDataCopy(state$.value)
        const scanWithTimestamps = getScanWithTimestamps(state$.value)
        const isEntropyDetectionEnabled = getIsEntropyDetectionEnabled(
          state$.value
        )

        return GrpcPechkinService.createPlan({
          policyName,
          selectedVariant,
          schedule,
          iscan,
          snapshotImport: snapshotImportVariant,
          executeImmediately,
          skipEbsBackup,
          selectedAssets,
          selectedS3Assets,
          selectedS3AllPath,
          selectedS3SpecificPath,
          policyTags,
          integrityCheck,
          protectNewImmediately,
          vaultsList: vaultLists,
          selectedCloudConnectors,
          selectedEfsAssets,
          selectedEfsAllPath,
          selectedEfsSpecificPath,
          integrityScanOption,
          keepDataCopy,
          scanWithTimestamps,
          isEntropyDetectionEnabled,
        }).catch(sentryReThrowCatchHandler('Cannot create a policy'))
      },
      () => {
        // possible return to the OnBoarding page
        // or to the Policies list page
        Router.back()
        return emptyAction()
      },
      () => 'The Policy has been created',
      () => 'Error during the policy creation'
    )
  )
