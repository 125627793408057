import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

function ErrorLayout({ children }: Props) {
  return (
    <div className="wrap-1614166083208">
      <div className="ilMain wrapper">
        <main className="content">{children}</main>
      </div>
    </div>
  )
}

export default ErrorLayout
