import React from 'react'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'

import { Header } from '@components/table/core/header'
import { HeaderWithLabelOwnProps } from '@components/table/core/header/types'
import { styled } from '@mui/material'

const StyledChip = styled(Chip)(() => ({
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '150%',
}))

function HeaderWithLabel({ title, label }: HeaderWithLabelOwnProps) {
  return (
    <Box gap={1} display="flex" alignItems="center">
      <Header title={title} />
      <StyledChip label={label} size="small" />
    </Box>
  )
}
export default HeaderWithLabel
