import GrpcRequestError from '@lib/errors/grpc-error'
import * as Sentry from '@sentry/nextjs'

abstract class SystemHelper {
  public static isProductionEnv(): boolean {
    return !SystemHelper.isLocalEnv()
  }

  public static isGlobalCell(): boolean {
    return process.env.NEXT_IS_GLOBAL_CELL === '1'
  }

  public static isLocalEnv(): boolean {
    return process.env.NEXT_PUBLIC_LOCAL === 'local'
  }

  public static isE2E(): boolean {
    return process.env.NEXT_E2E === '1'
  }

  // This method is used to avoid some logic in the code same as `isE2E`
  // But in the initial start of our app, we can not get access to  `NEXT_E2E`
  public static isPublicE2E(): boolean {
    return process.env.NEXT_PUBLIC_E2E === '1'
  }

  public static shouldAddDebugCode(overrideDomain?: string): boolean {
    // disabled for unit tests
    if ('test' === process.env.NODE_ENV) {
      return false
    }
    const devDomains = [
      'localhost',
      'frontend-e2e.development.elastio.us',
      'frontend.development.elastio.us',
      'backend.development.elastio.us',
      'dev.staging.elastio.us',
    ]
    return (
      devDomains.includes(overrideDomain ?? globalThis?.location?.hostname) ||
      !!globalThis?.localStorage?.getItem('DEBUG')
    )
  }

  public static copyToClipboard(str: string): Promise<void> {
    return navigator.clipboard.writeText(str)
  }

  public static pureNavigate(url: string) {
    if (!globalThis || !globalThis.location) {
      return
    }

    // normalize url
    const urlValue = url.replace(/^\//, '')
    let origin = ''

    if (!url.match(/^http/)) {
      origin = `${globalThis.location.origin}/`
    }

    if (globalThis.location.pathname === url) {
      globalThis.history.pushState({}, '', urlValue)
      return
    }

    globalThis.location.href = `${origin}${urlValue}`
  }

  public static openInNewTab(link: string) {
    if (!globalThis) {
      return
    }
    const a = document.createElement('a')
    a.setAttribute('href', link)
    a.setAttribute('target', '_blank')
    a.click()
  }

  public static printErrorInLocalEnv(...args: any) {
    if (SystemHelper.isLocalEnv()) {
      // eslint-disable-next-line no-console
      console.error(...args)
    }
  }

  public static sendSentryIfProd(message: string) {
    if (SystemHelper.isProductionEnv()) {
      Sentry.captureException(new Error(message))
    }
  }

  public static sendGrpcErrorToSentryIfProd(error: GrpcRequestError) {
    if (SystemHelper.isProductionEnv()) {
      Sentry.captureException(error, (scope) => {
        scope.setTag('grpc-request-id', error.requestId)
        return scope
      })
    } else {
      SystemHelper.printErrorInLocalEnv(error)
    }
  }

  public static sendObjectToSentryIfProd(object: any) {
    if (SystemHelper.isProductionEnv()) {
      Sentry.captureException(object)
    }
  }

  public static throwErrorInLocalEnv(message: string): void {
    if (SystemHelper.isLocalEnv()) {
      throw new Error(message)
    }
    SystemHelper.sendSentryIfProd(message)
  }

  public static getCurrentTenantName(): string {
    return globalThis?.location?.host ?? ''
  }

  public static getCurrentPageName(pageName: string): string {
    const tenantName = globalThis?.location?.host.split('.')[0]
    const upperCaseTenantName = tenantName
      ? tenantName?.charAt(0)?.toUpperCase() + tenantName?.slice(1)
      : ''
    return upperCaseTenantName + ' - ' + pageName ?? ''
  }
}

export default SystemHelper
