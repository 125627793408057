import { CloudConnectorJobsClient } from '@lib/clients'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import PreloaderConstants from '@lib/constants/preloader.constant'
import { Nullable } from '@lib/engine-types'
import ActionInterface from '@lib/interfaces/action.interface'
import {
  BackupTimelineStatsRequest,
  CountsList,
} from '@lib/interfaces/backup-timeline.interface'
import {
  REQUEST_BACKUP_TIMELINE_STATS,
  setBackupTimelineStats,
} from '@store/actions/source-vaults.action'

import { everyRequestScenario, sentryReThrowCatchHandler } from './epic-func'

const cloudConnectorJobsClient = new CloudConnectorJobsClient()

export const requestBackupTimelineStatsEpic = (action$: any) =>
  action$.pipe(
    everyRequestScenario(
      [REQUEST_BACKUP_TIMELINE_STATS],
      PreloaderConstants.REQUEST_BACKUP_TIMELINE_STATS,
      (action: ActionInterface) => {
        const params: BackupTimelineStatsRequest = action.payload
        const id = params.redStackIdsList[0]
        if (id) {
          return cloudConnectorJobsClient
            .getBackupTimelineStats({
              intervalMinutes: params.intervalMinutes,
              kindsList: params.kindsList,
              redStackIdsList: params.redStackIdsList,
              endTime: params.end,
              startTime: params.start,
            })
            .catch(
              sentryReThrowCatchHandler(
                `Cannot get Backup Timeline Stats for cloud connectors ${params.redStackIdsList.join(
                  ', '
                )}`
              )
            )
            .then((data: Nullable<CountsList>) => ({
              [id]: data,
            }))
        }

        return Promise.resolve(null)
      },
      (res: Record<string, Nullable<CountsList>>) =>
        setBackupTimelineStats(res),
      ErrorGroupConstants.JOBS
    )
  )
