import ActionInterface from '@lib/interfaces/action.interface'
import JobState from '@store/states/job.state'
import {
  SET_CC_SERVER_JOBS,
  SET_CURRENT_JOBS_PAGE,
  SET_JOBS_BACKUP_FAILED_FILTERS,
  SET_JOBS_BACKUP_SUCCESS_FILTERS,
  SET_JOBS_DATE_RANGE,
  SET_JOBS_LIST,
  SET_JOBS_LIST_PAGINATION,
  SET_POSSIBLE_JOBS_FILTERS,
  SET_SELECTED_JOBS_FILTERS,
  SET_TENANT_SERVER_JOBS,
  SET_UPDATED_CC_SERVER_JOBS,
  SET_UPDATED_TENANT_SERVER_JOBS,
} from '@store/actions/job.action'
import PaginationFactory from '@lib/factories/pagination.factory'
import BaseModel from '@lib/models/base-model/base.model'
import JobPageConstant from '@lib/constants/jobs/job-page.constant'
import { $enum } from 'ts-enum-util'
import { DEFAULT_DATE_RANGE } from '@lib/constants/time.constant'
import JobKindConstant, {
  getJobKindName,
} from '@lib/constants/jobs/job-kind.constant'
import JobStatusConstant, {
  getJobStatusName,
} from '@lib/constants/jobs/job-status.constant'

export const getBackupFilters = () => [
  {
    label: 'Job kind',
    name: 'kinds',
    options: [
      {
        label: getJobKindName(JobKindConstant.EBS_BACKUP),
        name: String(JobKindConstant.EBS_BACKUP),
        value: JobKindConstant.EBS_BACKUP,
        type: 1,
      },
      {
        label: getJobKindName(JobKindConstant.STREAM_BACKUP),
        name: String(JobKindConstant.STREAM_BACKUP),
        value: JobKindConstant.STREAM_BACKUP,
        type: 1,
      },
      {
        label: getJobKindName(JobKindConstant.EC2_BACKUP),
        name: String(JobKindConstant.EC2_BACKUP),
        value: JobKindConstant.EC2_BACKUP,
        type: 1,
      },
      {
        label: getJobKindName(JobKindConstant.FILE_BACKUP),
        name: String(JobKindConstant.FILE_BACKUP),
        value: JobKindConstant.FILE_BACKUP,
        type: 1,
      },
      {
        label: getJobKindName(JobKindConstant.BLOCK_BACKUP),
        name: String(JobKindConstant.BLOCK_BACKUP),
        value: JobKindConstant.BLOCK_BACKUP,
        type: 1,
      },
      {
        label: getJobKindName(JobKindConstant.POSTGRES_BACKUP),
        name: String(JobKindConstant.POSTGRES_BACKUP),
        value: JobKindConstant.POSTGRES_BACKUP,
        type: 1,
      },
    ],
    type: 1,
    value: 'kinds',
  },
]

export const getDeploymentFilters = () => [
  {
    label: 'Job kind',
    name: 'kinds',
    options: [
      {
        label: getJobKindName(JobKindConstant.INSTALL_CLOUD_CONNECTOR),
        name: String(JobKindConstant.INSTALL_CLOUD_CONNECTOR),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.ACTIVATE_CLOUD_CONNECTOR),
        name: String(JobKindConstant.ACTIVATE_CLOUD_CONNECTOR),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.UPGRADE_CLOUD_CONNECTOR),
        name: String(JobKindConstant.UPGRADE_CLOUD_CONNECTOR),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.REPAIR_CLOUD_CONNECTOR),
        name: String(JobKindConstant.REPAIR_CLOUD_CONNECTOR),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.CREATE_VAULT),
        name: String(JobKindConstant.CREATE_VAULT),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.REPAIR_VAULT),
        name: String(JobKindConstant.REPAIR_VAULT),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.DESTROY_VAULT),
        name: String(JobKindConstant.DESTROY_VAULT),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.UPDATE_VAULT_SUBNETS),
        name: String(JobKindConstant.UPDATE_VAULT_SUBNETS),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.DELETE_CLOUD_CONNECTOR),
        name: String(JobKindConstant.DELETE_CLOUD_CONNECTOR),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.ENABLE_ELASTIO_REPORTS_GATHERING),
        name: String(JobKindConstant.ENABLE_ELASTIO_REPORTS_GATHERING),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(
          JobKindConstant.DISABLE_ELASTIO_REPORTS_GATHERING
        ),
        name: String(JobKindConstant.DISABLE_ELASTIO_REPORTS_GATHERING),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.REPAIR_REPORT_GATHERING_INFRA),
        name: String(JobKindConstant.REPAIR_REPORT_GATHERING_INFRA),
        type: 1,
        value: true,
        disabled: true,
      },
    ],
    type: 1,
    value: 'kinds',
  },
]

export const getCloudConnectorFilters = () => [
  {
    label: 'Job kind',
    name: 'kinds',
    options: [
      {
        label: getJobKindName(JobKindConstant.EBS_BACKUP),
        name: String(JobKindConstant.EBS_BACKUP),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.EBS_RESTORE),
        name: String(JobKindConstant.EBS_RESTORE),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.EC2_BACKUP),
        name: String(JobKindConstant.EC2_BACKUP),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.EC2_RESTORE),
        name: String(JobKindConstant.EC2_RESTORE),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.FILE_BACKUP),
        name: String(JobKindConstant.FILE_BACKUP),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.FILE_RESTORE),
        name: String(JobKindConstant.FILE_RESTORE),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.BLOCK_BACKUP),
        name: String(JobKindConstant.BLOCK_BACKUP),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.BLOCK_RESTORE),
        name: String(JobKindConstant.BLOCK_RESTORE),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.STREAM_BACKUP),
        name: String(JobKindConstant.STREAM_BACKUP),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.STREAM_RESTORE),
        name: String(JobKindConstant.STREAM_RESTORE),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.POSTGRES_BACKUP),
        name: String(JobKindConstant.POSTGRES_BACKUP),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.POSTGRES_RESTORE),
        name: String(JobKindConstant.POSTGRES_RESTORE),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.ISCAN),
        name: String(JobKindConstant.ISCAN),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.AWS_BACKUP_IMPORT),
        name: String(JobKindConstant.AWS_BACKUP_IMPORT),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.VAULT_CATALOG_RESTORE),
        name: String(JobKindConstant.VAULT_CATALOG_RESTORE),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.DATABASE_MAINTENANCE),
        name: String(JobKindConstant.DATABASE_MAINTENANCE),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.FS_CHECK),
        name: String(JobKindConstant.FS_CHECK),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.S3_BACKUP),
        name: String(JobKindConstant.S3_BACKUP),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.S3_RESTORE),
        name: String(JobKindConstant.S3_RESTORE),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.AWS_BACKUP_RP_ISCAN),
        name: String(JobKindConstant.AWS_BACKUP_RP_ISCAN),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.BACKUP_INTEGRITY_CHECK),
        name: String(JobKindConstant.BACKUP_INTEGRITY_CHECK),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.FS_CHECK),
        name: String(JobKindConstant.FS_CHECK),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.APPLY_RETENTION_POLICY),
        name: String(JobKindConstant.APPLY_RETENTION_POLICY),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.POSTGRES_RECOVERY_TEST),
        name: String(JobKindConstant.POSTGRES_RECOVERY_TEST),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.UNKNOWN),
        name: String(JobKindConstant.UNKNOWN),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.IMPORT_SNAPSHOTS),
        name: String(JobKindConstant.IMPORT_SNAPSHOTS),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.AWS_BACKUP_RP_EC2_SCAN),
        name: String(JobKindConstant.AWS_BACKUP_RP_EC2_SCAN),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.AWS_BACKUP_RP_EBS_SCAN),
        name: String(JobKindConstant.AWS_BACKUP_RP_EBS_SCAN),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.AWS_BACKUP_RP_EFS_SCAN),
        name: String(JobKindConstant.AWS_BACKUP_RP_EFS_SCAN),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.AWS_EFS_SCAN),
        name: String(JobKindConstant.AWS_EFS_SCAN),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.AWS_BACKUP_RP_EC2_IMPORT),
        name: String(JobKindConstant.AWS_BACKUP_RP_EC2_IMPORT),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.AWS_BACKUP_RP_EBS_IMPORT),
        name: String(JobKindConstant.AWS_BACKUP_RP_EBS_IMPORT),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.VAULT_REPLICATION),
        name: String(JobKindConstant.VAULT_REPLICATION),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.AWS_EC2_AMI_SCAN),
        name: String(JobKindConstant.AWS_EC2_AMI_SCAN),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.AWS_FSX_ONTAP_SCAN),
        name: String(JobKindConstant.AWS_FSX_ONTAP_SCAN),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.AWS_EBS_SCAN),
        name: String(JobKindConstant.AWS_EBS_SCAN),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.AWS_EC2_SCAN),
        name: String(JobKindConstant.AWS_EC2_SCAN),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.AWS_BACKUP_RP_FSX_ONTAP_SCAN),
        name: String(JobKindConstant.AWS_BACKUP_RP_FSX_ONTAP_SCAN),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.AWS_EC2_AMI_IMPORT),
        name: String(JobKindConstant.AWS_EC2_AMI_IMPORT),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.AWS_EBS_SNAPSHOT_SCAN),
        name: String(JobKindConstant.AWS_EBS_SNAPSHOT_SCAN),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.AWS_S3_SCAN),
        name: String(JobKindConstant.AWS_S3_SCAN),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.IMPORT_SNAPSHOTS),
        name: String(JobKindConstant.IMPORT_SNAPSHOTS),
        type: 1,
        value: true,
        disabled: true,
      },
    ],
    type: 1,
    value: 'kinds',
  },
]

export const getSystemFilters = () => [
  {
    label: 'Job kind',
    name: 'kinds',
    options: [
      {
        label: getJobKindName(JobKindConstant.REFRESH_PLANS),
        name: String(JobKindConstant.REFRESH_PLANS),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.SET_PLAN_STATUS),
        name: String(JobKindConstant.SET_PLAN_STATUS),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.EVALUATE_PLANS),
        name: String(JobKindConstant.EVALUATE_PLANS),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.CLOUD_CONNECTOR_INTEGRITY_CHECK),
        name: String(JobKindConstant.CLOUD_CONNECTOR_INTEGRITY_CHECK),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.EXECUTE_BACKUP_POLICY),
        name: String(JobKindConstant.EXECUTE_BACKUP_POLICY),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.EVALUATE_RETENTION_POLICIES),
        name: String(JobKindConstant.EVALUATE_RETENTION_POLICIES),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.CLEANUP_BACKUP_POLICY_COVERAGE),
        name: String(JobKindConstant.CLEANUP_BACKUP_POLICY_COVERAGE),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(
          JobKindConstant.REFRESH_CONNECTOR_RETENTION_POLICIES
        ),
        name: String(JobKindConstant.REFRESH_CONNECTOR_RETENTION_POLICIES),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.EVALUATE_ASSETS_INTEGRITY_STATUS),
        name: String(JobKindConstant.EVALUATE_ASSETS_INTEGRITY_STATUS),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.REFRESH_ASSET_THREAT_STATUSES),
        name: String(JobKindConstant.REFRESH_ASSET_THREAT_STATUSES),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.GENERATE_SCHEDULED_REPORT),
        name: String(JobKindConstant.GENERATE_SCHEDULED_REPORT),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.CLEANUP_RED_STACK_DATA),
        name: String(JobKindConstant.CLEANUP_RED_STACK_DATA),
        type: 1,
        value: true,
        disabled: true,
      },
    ],
    type: 1,
    value: 'kinds',
  },
]

export const getBackupSuccessFilters = () => [
  {
    label: 'Job kind',
    name: 'kinds',
    options: [
      {
        label: getJobKindName(JobKindConstant.EBS_BACKUP),
        name: String(JobKindConstant.EBS_BACKUP),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.EC2_BACKUP),
        name: String(JobKindConstant.EC2_BACKUP),
        type: 1,
        value: true,
        disabled: true,
      },
    ],
    type: 1,
    value: 'kinds',
  },
  {
    label: 'Job Statuses',
    name: 'jobStatuses',
    options: [
      {
        label: getJobStatusName(JobStatusConstant.SUCCEEDED),
        name: String(JobStatusConstant.SUCCEEDED),
        type: 1,
        value: true,
        disabled: true,
      },
    ],
    type: 1,
    value: 'jobStatuses',
  },
]

export const getBackupFailedFilters = () => [
  {
    label: 'Job kind',
    name: 'kinds',
    options: [
      {
        label: getJobKindName(JobKindConstant.EBS_BACKUP),
        name: String(JobKindConstant.EBS_BACKUP),
        type: 1,
        value: true,
        disabled: true,
      },
      {
        label: getJobKindName(JobKindConstant.EC2_BACKUP),
        name: String(JobKindConstant.EC2_BACKUP),
        type: 1,
        value: true,
        disabled: true,
      },
    ],
  },
  {
    label: 'Job Statuses',
    name: 'jobStatuses',
    options: [
      {
        label: getJobStatusName(JobStatusConstant.FAILED),
        name: String(JobStatusConstant.FAILED),
        type: 1,
        value: true,
        disabled: true,
      },
    ],
  },
]

export function getInitialJobsValues<T>(value: T): Record<JobPageConstant, T> {
  const result: Record<JobPageConstant, T> = {} as Record<JobPageConstant, T>
  $enum(JobPageConstant)
    .getValues()
    .map((v) => {
      result[v] = value
    })
  return result
}

const initialState: JobState = {
  // Server jobs
  cloudConnectorServerJobs: [],
  updatedCloudConnectorServerJobs: [],
  cloudConnectorRuns: 0,
  tenantServerJobs: [],
  updatedTenantServerJobs: [],
  tenantRuns: 0,
  //
  currentJobsPage: JobPageConstant.ALL,
  jobsList: getInitialJobsValues([]),
  jobsListPagination: getInitialJobsValues(PaginationFactory.buildEmpty()),
  possibleFilters: getInitialJobsValues([]),
  selectedFilters: {
    ...getInitialJobsValues([]),
    [JobPageConstant.CLOUD_CONNECTOR]: getCloudConnectorFilters(),
    [JobPageConstant.DEPLOYMENT]: getDeploymentFilters(),
    [JobPageConstant.SYSTEM]: getSystemFilters(),
    [JobPageConstant.FILTERS]: getBackupSuccessFilters(),
  },
  jobsSearch: getInitialJobsValues(''),
  dateRange: getInitialJobsValues(DEFAULT_DATE_RANGE()),
}

function jobReducer(
  prevState = initialState,
  action: ActionInterface
): JobState {
  let jobsListPagination, jobsList, possibleFilters, selectedFilters, dateRange

  switch (action.type) {
    case SET_CC_SERVER_JOBS:
      return {
        ...prevState,
        cloudConnectorRuns: prevState.cloudConnectorRuns + 1,
        cloudConnectorServerJobs: action.payload,
      }

    case SET_TENANT_SERVER_JOBS:
      return {
        ...prevState,
        tenantRuns: prevState.tenantRuns + 1,
        tenantServerJobs: action.payload,
      }

    case SET_UPDATED_CC_SERVER_JOBS:
      return {
        ...prevState,
        updatedCloudConnectorServerJobs: action.payload,
      }

    case SET_UPDATED_TENANT_SERVER_JOBS:
      return {
        ...prevState,
        updatedTenantServerJobs: action.payload,
      }
    case SET_CURRENT_JOBS_PAGE:
      return {
        ...prevState,
        currentJobsPage: action.payload,
      }
    case SET_JOBS_LIST:
      jobsListPagination = prevState.jobsListPagination
      jobsListPagination[prevState.currentJobsPage] = BaseModel.clone(
        prevState.jobsListPagination[prevState.currentJobsPage],
        {
          total: action.payload.total,
        }
      )
      jobsList = prevState.jobsList
      jobsList[prevState.currentJobsPage] = action.payload.data
      return {
        ...prevState,
        jobsListPagination,
        jobsList,
      }

    case SET_JOBS_LIST_PAGINATION:
      jobsListPagination = prevState.jobsListPagination
      jobsListPagination[prevState.currentJobsPage] = action.payload
      return {
        ...prevState,
        jobsListPagination,
      }

    case SET_POSSIBLE_JOBS_FILTERS:
      possibleFilters = prevState.possibleFilters
      possibleFilters[prevState.currentJobsPage] = action.payload
      return {
        ...prevState,
        possibleFilters,
      }

    case SET_SELECTED_JOBS_FILTERS:
      selectedFilters = prevState.selectedFilters
      selectedFilters[prevState.currentJobsPage] = action.payload
      jobsListPagination = prevState.jobsListPagination
      jobsListPagination[prevState.currentJobsPage] =
        PaginationFactory.cloneReset(
          prevState.jobsListPagination[prevState.currentJobsPage]
        )
      return {
        ...prevState,
        selectedFilters,
        jobsListPagination,
      }

    case SET_JOBS_DATE_RANGE:
      dateRange = prevState.dateRange
      dateRange[prevState.currentJobsPage] = action.payload
      jobsListPagination = prevState.jobsListPagination
      jobsListPagination[prevState.currentJobsPage] =
        PaginationFactory.cloneReset(
          prevState.jobsListPagination[prevState.currentJobsPage]
        )
      return {
        ...prevState,
        jobsListPagination,
        dateRange,
      }

    case SET_JOBS_BACKUP_SUCCESS_FILTERS:
      selectedFilters = prevState.selectedFilters
      selectedFilters[prevState.currentJobsPage] = getBackupSuccessFilters()
      jobsListPagination = prevState.jobsListPagination
      jobsListPagination[prevState.currentJobsPage] =
        PaginationFactory.cloneReset(
          prevState.jobsListPagination[prevState.currentJobsPage]
        )
      return {
        ...prevState,
        selectedFilters,
        jobsListPagination,
      }
    case SET_JOBS_BACKUP_FAILED_FILTERS:
      selectedFilters = prevState.selectedFilters
      selectedFilters[prevState.currentJobsPage] = getBackupFailedFilters()
      jobsListPagination = prevState.jobsListPagination
      jobsListPagination[prevState.currentJobsPage] =
        PaginationFactory.cloneReset(
          prevState.jobsListPagination[prevState.currentJobsPage]
        )
      return {
        ...prevState,
        selectedFilters,
        jobsListPagination,
      }

    default:
      return prevState
  }
}

export default jobReducer
