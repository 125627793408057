import SystemHelper from '@lib/helpers/system.helper'

enum JobStatusConstant {
  UNDEFINED = -1,
  SCHEDULED = 0,
  STARTING = 1,
  IN_PROGRESS = 2,
  FAILED = 3,
  SUCCEEDED = 4,
  ABORTED = 5,
  CUSTOM = 6,
}

export const JOB_STATUS_FINISHED = [
  JobStatusConstant.FAILED,
  JobStatusConstant.SUCCEEDED,
  JobStatusConstant.ABORTED,
]

export const JOB_STATUS_PROGRESS = [
  JobStatusConstant.SCHEDULED,
  JobStatusConstant.STARTING,
  JobStatusConstant.IN_PROGRESS,
  // 15 oct 2021 - "vnikonov" - is the job is in progress
  // it is done for extensibility
  JobStatusConstant.CUSTOM,
]

export function getJobStatusName(status: JobStatusConstant): string {
  switch (status) {
    case JobStatusConstant.SCHEDULED:
      return 'Scheduled'

    case JobStatusConstant.STARTING:
      return 'Starting'

    case JobStatusConstant.IN_PROGRESS:
      return 'In Progress'

    case JobStatusConstant.FAILED:
      return 'Failed'

    case JobStatusConstant.SUCCEEDED:
      return 'Completed successfully'

    case JobStatusConstant.ABORTED:
      return 'Aborted'

    case JobStatusConstant.CUSTOM:
      return 'Custom'

    default:
      SystemHelper.throwErrorInLocalEnv('Wrong job status')
      return ''
  }
}

export default JobStatusConstant
