import { ASSET_ID_QUERY_KEY, PagePathConstant } from '@lib/constants'
import { PageHelper, StrHelper } from '@lib/helpers'
import { RecoveryPointAssetIdentifier } from './types'

interface RestoreUrlParams {
  rpId: string
  assetId: string
  path: PagePathConstant
}

function composeRestoreUrl({ rpId, assetId, path }: RestoreUrlParams) {
  const encodedAssetId = StrHelper.base64Encode(assetId)

  return `${PageHelper.buildUrl(
    path as string,
    rpId
  )}?${ASSET_ID_QUERY_KEY}=${encodedAssetId}`
}

export function composeRestoreVolumeUrl({
  rpId,
  assetId,
}: RecoveryPointAssetIdentifier) {
  const path = PagePathConstant.RESTORE_VOLUME

  return composeRestoreUrl({
    rpId,
    assetId,
    path,
  })
}

export function composeRestoreInstanceUrl({
  rpId,
  assetId,
}: RecoveryPointAssetIdentifier) {
  const path = PagePathConstant.RESTORE_INSTANCE

  return composeRestoreUrl({
    rpId,
    assetId,
    path,
  })
}
