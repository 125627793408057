import RexState from '@store/states/rex.state'
import ActionInterface from '@lib/interfaces/action.interface'
import {
  SET_ALL_RED_STACKS,
  SET_AWS_ACCOUNT_INFO_LIST,
  SET_CF_LINK,
  SET_CFN,
  SET_DEPLOYMENT_FILES,
  SET_POSSIBLE_REGIONS_LIST,
  SET_POSSIBLE_VPC_LIST,
  SET_RED_STACKS_LIST,
  SET_LIST_AWS_ACCOUNTS,
  SET_LIST_AVAILABILITY_ZONES,
  SET_LIST_AWS_SECURITY_GROUPS,
  SET_IF_RED_STACK_EXIST,
} from '@store/actions/rex.action'
import CfnFactory from '@lib/factories/cfn.factory'

const initialState: RexState = {
  deploymentFiles: {
    cfTemplate: '',
    tfArchiveUrl: '',
  },
  redStacksList: [],
  allRedStacks: [],
  possibleVPCList: [],
  cfLink: '',
  possibleRegionsList: [],
  cfn: CfnFactory.buildEmpty(),
  awsAccountInfoList: [],
  listAwsAccountsList: [],
  listAvailabilityZonesList: [],
  listAwsSecurityGroupsList: [],
  isRedStackExist: null,
}

function rexReducer(
  prevState: RexState = initialState,
  action: ActionInterface
): RexState {
  switch (action.type) {
    case SET_DEPLOYMENT_FILES:
      return {
        ...prevState,
        deploymentFiles: action.payload,
      }

    case SET_RED_STACKS_LIST:
      return {
        ...prevState,
        redStacksList: action.payload,
      }

    case SET_ALL_RED_STACKS:
      return {
        ...prevState,
        allRedStacks: action.payload,
      }

    case SET_POSSIBLE_VPC_LIST:
      return {
        ...prevState,
        possibleVPCList: action.payload,
      }

    case SET_CFN:
      return {
        ...prevState,
        cfn: action.payload,
      }

    case SET_CF_LINK:
      return {
        ...prevState,
        cfLink: action.payload,
      }

    case SET_POSSIBLE_REGIONS_LIST:
      return {
        ...prevState,
        possibleRegionsList: action.payload,
      }

    case SET_AWS_ACCOUNT_INFO_LIST:
      return {
        ...prevState,
        awsAccountInfoList: action.payload,
      }
    case SET_LIST_AWS_ACCOUNTS:
      return {
        ...prevState,
        listAwsAccountsList: action.payload,
      }
    case SET_LIST_AVAILABILITY_ZONES:
      return {
        ...prevState,
        listAvailabilityZonesList: action.payload,
      }
    case SET_LIST_AWS_SECURITY_GROUPS:
      return {
        ...prevState,
        listAwsSecurityGroupsList: action.payload,
      }
    case SET_IF_RED_STACK_EXIST:
      return {
        ...prevState,
        isRedStackExist: action.payload,
      }
    default:
      return prevState
  }
}

export default rexReducer
