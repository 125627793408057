"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.threatFactory = void 0;
const threats_1 = require("../../models/threats");
const types_1 = require("../../models/threats/types");
const factories_1 = require("../../use_cases/tests/factories");
const fishery_1 = require("fishery");
const common_1 = require("./common");
class ThreatFactory extends fishery_1.Factory {
    withScanIdsAndSourceAndKind(scanIds, source, kind) {
        return this.transient({
            source,
            scanIds,
            kind,
        });
    }
}
function source() {
    const asset = () => ({ assetId: (0, factories_1.uuid)() });
    const assetItem = () => ({ assetId: (0, factories_1.uuid)(), assetItemId: (0, factories_1.uuid)() });
    return {
        asset: asset(),
        assetItem: assetItem(),
    };
}
exports.threatFactory = ThreatFactory.define(({ transientParams, sequence }) => {
    return new threats_1.Threat({
        id: transientParams.id ?? (0, factories_1.uuid)(),
        kind: transientParams.kind ?? (0, common_1.randomValueFromEnum)(types_1.ThreatKind),
        source: transientParams.source ?? source(),
        status: transientParams.status ?? (0, common_1.randomValueFromEnum)(types_1.ThreatStatus),
        name: transientParams.name ?? `Threat ${sequence}`,
        location: transientParams.location ?? (0, factories_1.uuid)(),
        scanIds: transientParams.scanIds ?? [],
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
        firstDetectedAt: transientParams.firstDetectedAt ?? (0, common_1.date)(),
        resolvedAt: transientParams.resolvedAt ?? (0, common_1.date)(),
        ignoredAt: transientParams.ignoredAt ?? (0, common_1.date)(),
    });
});
