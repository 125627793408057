import Dexie from 'dexie'
import {
  Model,
  ModelAttrs,
} from '@features/scheduled-jobs-monitoring/models/model'

import { Logger } from '@features/scheduled-jobs-monitoring/use-cases'

export abstract class Repository<A extends ModelAttrs, T extends Model<A>, K> {
  constructor(protected logger: Logger) {}

  async insert(item: T): Promise<void> {
    try {
      await this.table.add(item.toAttrs())
      this.logger.info(
        `Successfully inserted item into '${this.table.name}':`,
        item
      )
    } catch (error) {
      this.logger.error(
        `Failed to insert item into table '${this.table.name}'.`
      )
      throw new Error(`Failed to insert item into table '${this.table.name}'.`)
    }
  }

  async update(item: T): Promise<boolean> {
    try {
      const result =
        1 ===
        (await this.table
          .where('id')
          .equals(item.id)
          .modify((_, ref) => {
            ref.value = item.toAttrs()
          }))
      this.logger.info(
        `Item ${result ? 'successfully' : 'not'} updated in '${
          this.table.name
        }':`,
        item
      )
      return result
    } catch (error) {
      this.logger.error(`Failed to update item in table '${this.table.name}'.`)
      throw new Error(`Failed to update item in table '${this.table.name}'.`)
    }
  }

  async get(id: K): Promise<T | undefined> {
    try {
      const item = await this.table.get(id)

      if (typeof item === 'undefined') {
        this.logger.info(`No item found for '${id}'.`)
        return
      }

      this.logger.info(`Successfully retrieved item from '${this.table.name}'.`)

      return this.instantiateModel(item)
    } catch (error) {
      this.logger.error(
        `Failed to retrieve item from table '${this.table.name}'.`
      )
      throw new Error(`Failed to update item in table '${this.table.name}'.`)
    }
  }

  async delete(id: K): Promise<void> {
    try {
      await this.table.delete(id)
      this.logger.info(
        `Successfully deleted item from '${this.table.name}' with ID: ${id}.`
      )
    } catch (error) {
      this.logger.error(
        `Failed to delete item from table '${this.table.name}' with ID: ${id}.`
      )
      throw new Error(`Failed to update item in table '${this.table.name}'.`)
    }
  }

  async list(): Promise<Array<T>> {
    try {
      const items = await this.table.toArray()
      this.logger.info(
        `Successfully retrieved all items from '${this.table.name}'.`
      )
      return items.map((item) => this.instantiateModel(item))
    } catch (error) {
      this.logger.error(`Failed to list items from table '${this.table.name}'.`)
      throw new Error(`Failed to update item in table '${this.table.name}'.`)
    }
  }

  protected abstract get db(): Dexie

  protected abstract get table(): Dexie.Table<A>

  protected abstract instantiateModel(attr: A): T
}
