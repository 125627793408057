"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetVolumesVulnerabilitiesFilters = void 0;
const vulnerability_1 = require("../../models/vulnerability");
class GetVolumesVulnerabilitiesFilters {
    #volumes;
    #lastBackups;
    #riskFromQuery;
    #snapshotByVolumeIdMap;
    #attachedInstances;
    constructor(parameters) {
        this.#volumes = parameters.volumes;
        this.#lastBackups = parameters.lastBackups;
        this.#riskFromQuery = parameters.riskFromQuery;
        this.#snapshotByVolumeIdMap ||= parameters.snapshots.reduce((map, snapshot) => {
            map.set(snapshot.volumeId, snapshot);
            return map;
        }, new Map());
        this.#attachedInstances = parameters.instances.filter((instance) => parameters.volumes.some((volume) => volume.attachedInstanceIds.includes(instance.id)));
    }
    execute(riskIdentificationStrategy) {
        const accountIds = new Set();
        const regions = new Set();
        const ebsVulnerabilities = new Set();
        const riskLevel = new Set();
        if (this.#riskFromQuery !== undefined) {
            riskLevel.add(this.#riskFromQuery);
        }
        this.#getVolumesWithVulnerabilities().forEach((volume) => {
            accountIds.add(volume.awsAccountId);
            regions.add(volume.awsRegion);
            const vulnerabilities = this.#getVolumeVulnerabilities(volume);
            vulnerabilities.forEach((vulnerability) => ebsVulnerabilities.add(vulnerability));
            const uniqueRiskLevels = vulnerabilities.map(riskIdentificationStrategy.identifyRiskLevel);
            uniqueRiskLevels.forEach((risk) => riskLevel.add(risk));
            vulnerabilities.forEach((vulnerability) => {
                ebsVulnerabilities.add(vulnerability);
            });
        });
        return {
            accountIds: [...accountIds],
            regions: [...regions],
            ebsVulnerabilities: [...ebsVulnerabilities].sort(),
            riskType: [...riskLevel].sort(),
            volumeAwsIds: this.#volumes.map((volume) => volume.awsId),
            attachedInstancesAwsIds: this.#attachedInstances.map((instance) => instance.awsId),
        };
    }
    #getVolumesWithVulnerabilities() {
        return this.#volumes.filter((volume) => this.#getVolumeVulnerabilities(volume).length);
    }
    #getVolumeVulnerabilities(volume) {
        const volumeSnapshot = this.#snapshotByVolumeIdMap.get(volume.id);
        const result = [];
        if (volume.isNotAttached) {
            result.push(vulnerability_1.VolumeVulnerabilityKind.UNATTACHED);
        }
        if (volume.isUnencrypted) {
            result.push(vulnerability_1.VolumeVulnerabilityKind.UNENCRYPTED);
        }
        if (volume.encryptedWithoutCmk) {
            result.push(vulnerability_1.VolumeVulnerabilityKind.NOT_USING_CMK);
        }
        if (!this.#lastBackups.has(volume.id)) {
            result.push(vulnerability_1.VolumeVulnerabilityKind.NOT_CYBER_SCANNED);
        }
        if (!this.#lastBackups.has(volume.id)) {
            result.push(vulnerability_1.VolumeVulnerabilityKind.NO_BACKUPS);
        }
        if (volumeSnapshot && this.#isSnapshotOlderAWeek(volumeSnapshot)) {
            result.push(vulnerability_1.VolumeVulnerabilityKind.SNAPSHOTS_OLDER_7DAYS);
        }
        return result;
    }
    #isSnapshotOlderAWeek(snapshot) {
        const millisecondsInWeek = 7 * 24 * 60 * 60 * 1000;
        return snapshot.age > millisecondsInWeek;
    }
}
exports.GetVolumesVulnerabilitiesFilters = GetVolumesVulnerabilitiesFilters;
