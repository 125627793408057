import { SET_USER_NOTIFICATIONS_SETTINGS } from './../actions/user-notifications.action'
import ActionInterface from '@lib/interfaces/action.interface'
import UserNotificationsState from '@store/states/user-notifications.state'

const initialState: UserNotificationsState = {
  slackNotificationSettings: {
    sendErrors: false,
    sendWarnings: false,
    sendInfo: false,
  },
  emailNotificationSettings: {
    sendErrors: false,
    sendWarnings: false,
    sendInfo: false,
    emailsList: [],
  },
  slackSetupUrl: '',
}

function userNotificationsReducer(
  prevState = initialState,
  action: ActionInterface
) {
  switch (action.type) {
    case SET_USER_NOTIFICATIONS_SETTINGS:
      return {
        ...prevState,
        slackNotificationSettings: action.payload.slackNotificationSettings,
        emailNotificationSettings: action.payload.emailNotificationSettings,
        slackSetupUrl: action.payload.slackSetupUrl,
      }
    default:
      return prevState
  }
}

export default userNotificationsReducer
