import { styled, TableRow, TableRowProps } from '@mui/material'
import { memo, PropsWithChildren, useMemo } from 'react'

const MemoizedStyledTableRow = memo(
  styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[50],
    },
  }))
)

const MemoizedTableRow = memo(TableRow)

interface Props extends TableRowProps {
  depth: number
}

/**
 * Represents a default row component for a table.
 * In case the depth is 0, it will render a default row.
 * Otherwise, it will render a nested row.
 * @param depth - The depth of the row.
 */
const TableRowComponent = ({
  children,
  depth = 0,
  ...restProps
}: PropsWithChildren<Props>) => {
  const RowComponent = useMemo(
    () => (depth === 0 ? MemoizedStyledTableRow : MemoizedTableRow),
    [depth]
  )

  return <RowComponent {...restProps}>{children}</RowComponent>
}

export default TableRowComponent
