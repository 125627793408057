import React from 'react'

function ReportsIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.93754 3.33398C5.19664 3.33398 4.58337 3.93781 4.58337 4.66732V15.334C4.58337 16.0635 5.19664 16.6673 5.93754 16.6673H14.0625C14.8034 16.6673 15.4167 16.0635 15.4167 15.334V7.33398L11.3542 3.33398H5.93754ZM5.93754 4.66732H10.6771V8.00065H14.0625V15.334H5.93754V4.66732ZM7.29171 10.0007V14.0007H8.64587V10.0007H7.29171ZM9.32296 11.334V14.0007H10.6771V11.334H9.32296ZM11.3542 12.6673V14.0007H12.7084V12.6673H11.3542Z"
        fill="#999CAA"
      />
    </svg>
  )
}

export default ReportsIcon
