import { useEffect, useCallback, useMemo, useState, useRef } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import {
  SyncScheduledTenantJobsAndNotifyUserUseCase,
  ShowPendingNotificationsUseCase,
  SyncScheduledCcJobsAndNotifyUserUseCase,
} from '@features/scheduled-jobs-monitoring/use-cases'
import {
  ScheduledTenantJobsRepository,
  ScheduledCcJobsRepository,
  SyncsRepository,
  NotificationsRepository,
} from '@features/scheduled-jobs-monitoring/repositories'
import {
  ScheduledTenantJobsClient,
  ScheduledCcJobsClient,
} from '@features/scheduled-jobs-monitoring/clients'
import { useVisibility } from './use-visibility'
import ToastHelper from '@lib/helpers/toast.helper'
import { createLogger } from '@features/scheduled-jobs-monitoring/infrastructure/logging'

const DEFAULT_IDLE_TIMEOUT_MS = 1000 * 60 * 5 // 5 minutes
const DEFAULT_DEBOUNCE_INTERVAL_MS = 500
const DEFAULT_SYNC_INTERVAL_MS = 1000 * 30 // 30 seconds

const logger = createLogger()

const syncScheduledTenantJobsAndNotifyUserUseCase =
  new SyncScheduledTenantJobsAndNotifyUserUseCase({
    syncsRepository: new SyncsRepository(logger),
    jobsRepository: new ScheduledTenantJobsRepository(logger),
    notificationsRepository: new NotificationsRepository(logger),
    client: new ScheduledTenantJobsClient(),
    logger: logger,
  })

const syncScheduledCcJobsAndNotifyUserUseCase =
  new SyncScheduledCcJobsAndNotifyUserUseCase({
    syncsRepository: new SyncsRepository(logger),
    jobsRepository: new ScheduledCcJobsRepository(logger),
    notificationsRepository: new NotificationsRepository(logger),
    client: new ScheduledCcJobsClient(),
    logger: logger,
  })

const showPendingNotificationsUseCase = new ShowPendingNotificationsUseCase(
  new NotificationsRepository(logger),
  ToastHelper
)

interface UseGlobalSyncJobsOwnProps {
  idleTimeout?: number
  debounceInterval?: number
  syncInterval?: number
  onError?: (error: Error) => void
  onSync?: () => void
  isEnabled?: boolean
}

export function useGlobalSyncJobs({
  idleTimeout = DEFAULT_IDLE_TIMEOUT_MS,
  debounceInterval = DEFAULT_DEBOUNCE_INTERVAL_MS,
  syncInterval = DEFAULT_SYNC_INTERVAL_MS,
  onError,
  onSync,
  isEnabled = true,
}: UseGlobalSyncJobsOwnProps) {
  const isVisible = useVisibility()
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)
  const [isUserActive, setIsUserActive] = useState(true)
  const lastSyncTimeRef = useRef(0)

  const handleSyncError = useCallback((error) => {
    logger.error('Error syncing jobs:', error)
    if (onError) {
      onError(error)
    }
  }, [])

  const executeSync = useCallback(async () => {
    if (!isVisible || !isUserActive || !isEnabled) {
      return
    }

    const now = Date.now()
    if (now - lastSyncTimeRef.current < syncInterval) {
      return
    }

    try {
      await Promise.all([
        await syncScheduledTenantJobsAndNotifyUserUseCase.execute(),
        await syncScheduledCcJobsAndNotifyUserUseCase.execute(),
      ])

      await showPendingNotificationsUseCase.execute()
      lastSyncTimeRef.current = now
      if (onSync) {
        onSync()
      }
    } catch (error) {
      handleSyncError(error)
    }
  }, [isVisible, isUserActive, isEnabled, syncInterval])

  const stopInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }, [])

  const startInterval = useCallback(() => {
    stopInterval()
    intervalRef.current = setInterval(executeSync, syncInterval)
  }, [syncInterval])

  const handleIdle = useCallback(() => {
    setIsUserActive(false)
    stopInterval()
  }, [stopInterval])

  const handleActive = useCallback(() => {
    setIsUserActive(true)
    executeSync()
    startInterval()
  }, [startInterval])

  const { reset, pause, resume } = useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleIdle,
    onActive: handleActive,
    debounce: debounceInterval,
    crossTab: true,
  })

  useEffect(() => {
    if (isVisible && isUserActive && isEnabled) {
      startInterval()
    } else {
      stopInterval()
    }

    return stopInterval
  }, [isVisible, isUserActive, isEnabled, startInterval, stopInterval])

  useEffect(() => {
    if (!isEnabled) {
      pause()
    } else {
      resume()
    }
  }, [isEnabled, pause, resume])

  return useMemo(
    () => ({
      reset,
      pause,
      resume,
      forceSync: executeSync,
    }),
    [reset, pause, resume, executeSync]
  )
}
