import { StrHelper } from '@lib/helpers'
import {
  ViewsVersion,
  TabViewInterface,
  ViewKind,
} from './management-view/types'

/**
 * @description This function is used to convert the old views version to the latest one
 */
export function viewsConfigToLatestVersion(
  views: Array<any>
): Array<TabViewInterface> {
  return views
    .map((view) => {
      switch (view?.version) {
        case ViewsVersion.V2:
          return view
        case ViewsVersion.V1:
          return {
            ...view,
            kind: ViewKind.AWS,
            isDefaultDashboard: false,
            version: ViewsVersion.V2,
          }
        default:
          if (view?.variant) {
            return {
              ...view,
              version: ViewsVersion.V2,
              kind: ViewKind.AWS,
              isDefaultDashboard: false,
            }
          }
          // The old default view of the dashboard
          if (view?.index === 0) {
            return
          }

          const viewId = StrHelper.generateUUID()

          return {
            id: viewId,
            name: view.name,
            slice: view.slice,
            variant: 'overview',
            version: ViewsVersion.V1,
            isDefaultDashboard: false,
            kind: ViewKind.AWS,
          }
      }
    })
    .filter(Boolean)
}
