import { TenantSettingsClient } from '@lib/clients/tenant-settings'
import { UpdateTenantSettingsRequestAttrs } from '@lib/clients/tenant-settings/types'
import { SystemHelper } from '@lib/helpers'
import { useGetTenantSettingsStoreActions } from './tenant-settings.store'

const client = new TenantSettingsClient()

export const useUpdateTenantSettings = () => {
  const { setIsTenantSettingsLoading, setTenantSettingsModel } =
    useGetTenantSettingsStoreActions()

  async function updateTenantSettings(
    attributes: UpdateTenantSettingsRequestAttrs
  ) {
    try {
      setIsTenantSettingsLoading(true)
      const result = await client.updateTenantSettings(attributes)
      setTenantSettingsModel(result)
    } catch (error: any) {
      if ('message' in error) {
        SystemHelper.sendSentryIfProd(
          `useUpdateTenantSettings: ${error.message}`
        )
      }
    } finally {
      setIsTenantSettingsLoading(false)
    }
  }

  return { updateTenantSettings }
}
