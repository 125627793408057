import ManagementRolesState from '@store/states/management-roles.state'
import ActionInterface from '@lib/interfaces/action.interface'
import { Nullable, RowSimpleUnit, VIRow } from '@lib/engine-types'
import UserRoleModel from '@lib/models/user-role.model'
import UserFactory from '@lib/factories/user.factory'
import RoleTypeConstant from '@lib/constants/role-type.constant'
import TableFactory from '@lib/factories/table.factory'
import {
  CANCEL_ROLE_FOR_EDIT,
  SET_ROLE_FOR_CREATE,
  SET_ROLE_FOR_EDIT,
  SUBMIT_ROLE_FOR_EDIT,
  UPDATE_ROLE_FOR_EDIT_SCOPES,
} from '@store/actions/management-roles.action'
import { SET_POSSIBLE_ROLES } from '@store/actions/user.action'
import ArrHelper from '@lib/helpers/arr.helper'

const initialState: ManagementRolesState = {
  possibleScopes: [],
  defaultRoles: [],
  customRoles: [],
  roleForEdit: null,
  scopesCategories: [],
  defaultRolesTableData: [],
  customRolesTableData: [],
  allScopesGrouped: [],
  editColumnIndex: -1,
}

function managementRolesReducer(
  prevState = initialState,
  action: ActionInterface
): ManagementRolesState {
  let newRole: Nullable<UserRoleModel>
  let newRoles: Array<UserRoleModel>
  let newDefaultRoles: Array<UserRoleModel>
  let newCustomRoles: Array<UserRoleModel>
  let scopesToAdd: Array<string>
  let scopesToRemove: Array<string>
  let roleIndex: number
  let newDefaultRolesTableData: Array<RowSimpleUnit>
  let newCustomRolesTableData: Array<RowSimpleUnit>
  let newScopesCategories: Array<string>
  let newAllScopesGrouped: VIRow
  let newPossibleScopes: Array<string>

  switch (action.type) {
    case SET_POSSIBLE_ROLES:
      newRoles = action.payload
      newDefaultRoles = newRoles.filter(
        (r) => r.type === RoleTypeConstant.DEFAULT
      )
      newCustomRoles = newRoles.filter(
        (r) => r.type === RoleTypeConstant.CUSTOM
      )
      // new standard has ".flat" - but we choose a safe variant
      newPossibleScopes = ArrHelper.flatDeep(newRoles.map((r) => r.scopesList))
      newAllScopesGrouped = TableFactory.rolesTableAllScopesGrouped(newRoles)
      newScopesCategories = TableFactory.rolesTableScopesCategories(newRoles)
      newDefaultRolesTableData = TableFactory.possibleRolesTable(
        newDefaultRoles,
        newAllScopesGrouped
      )
      newCustomRolesTableData = TableFactory.possibleRolesTable(
        newCustomRoles,
        newAllScopesGrouped
      )

      return {
        ...prevState,
        defaultRoles: newDefaultRoles,
        customRoles: newCustomRoles,
        scopesCategories: newScopesCategories,
        defaultRolesTableData: newDefaultRolesTableData,
        customRolesTableData: newCustomRolesTableData,
        allScopesGrouped: newAllScopesGrouped,
        possibleScopes: newPossibleScopes,
        editColumnIndex: -1,
      }

    case SET_ROLE_FOR_CREATE:
      newRole = UserFactory.buildUserRole({
        id: '',
        type: RoleTypeConstant.CUSTOM,
      })
      newCustomRoles = [newRole, ...prevState.customRoles]

      return {
        ...prevState,
        roleForEdit: newRole,
        customRoles: newCustomRoles,
        customRolesTableData: TableFactory.possibleRolesTable(
          newCustomRoles,
          prevState.allScopesGrouped
        ),
        editColumnIndex: 0,
      }

    case SET_ROLE_FOR_EDIT:
      newRole = prevState.customRoles.find(
        (pr) => pr.name === action.payload.roleName
      )
      if (!newRole) {
        return prevState
      }
      return {
        ...prevState,
        roleForEdit: UserFactory.cloneRole(newRole, {}),
        editColumnIndex: action.payload.index,
      }

    case UPDATE_ROLE_FOR_EDIT_SCOPES:
      if (!prevState.roleForEdit) {
        return prevState
      }

      // update role for edit
      scopesToAdd = []
      scopesToRemove = []
      if (action.payload.value) {
        scopesToAdd.push(action.payload.scope)
      } else {
        scopesToRemove.push(action.payload.scope)
      }
      newRole = UserFactory.cloneRole(prevState.roleForEdit, {
        allScopesForNormalization: prevState.possibleScopes,
        scopesToAdd,
        scopesToRemove,
      })

      // update the table visualization
      roleIndex = prevState.customRoles.findIndex(
        (r) => r.id === prevState.roleForEdit?.id
      )
      newCustomRoles = [...prevState.customRoles]
      if (roleIndex in newCustomRoles) {
        newCustomRoles[roleIndex] = newRole
      }

      return {
        ...prevState,
        roleForEdit: newRole,
        customRolesTableData: TableFactory.possibleRolesTable(
          newCustomRoles,
          prevState.allScopesGrouped
        ),
      }

    case SUBMIT_ROLE_FOR_EDIT:
      if (!prevState.roleForEdit) {
        return prevState
      }
      newRole = UserFactory.cloneRole(prevState.roleForEdit, {
        name: action.payload,
      })
      return {
        ...prevState,
        roleForEdit: newRole,
      }

    case CANCEL_ROLE_FOR_EDIT:
      newCustomRoles = prevState.customRoles.filter((v) => !!v.innerId)
      return {
        ...prevState,
        roleForEdit: null,
        editColumnIndex: -1,
        customRoles: newCustomRoles,
        customRolesTableData: TableFactory.possibleRolesTable(
          newCustomRoles,
          prevState.allScopesGrouped
        ),
      }

    default:
      return prevState
  }
}

export default managementRolesReducer
