import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import Router from 'next/router'
import ActionInterface from '@lib/interfaces/action.interface'
import PreloaderConstants from '@lib/constants/preloader.constant'
import {
  ADD_TENANT,
  MARK_AS_TENANT_CREATOR,
  REQUEST_GET_TENANT,
  REQUEST_UPDATE_TENANT,
  SET_CREATED_TENANT_NAME,
  setTenant,
} from '@store/actions/tenant.action'
import RequestService from '@lib/services/request.service'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import LocalStorageHelper from '@lib/helpers/local-storage.helper'
import {
  baseRequestScenario,
  sentrySimpleReThrowHandler,
} from '@store/epics/epic-func'
import { emptyAction } from '@store/actions/default/empty.action'
import PagePathConstant from '@lib/constants/page-path.constant'
import GrpcTenantsService from '@lib/services/grpc/grpc-tenants.service'
import TypedActionInterface from '@lib/interfaces/typed-action.interface'
// eslint-disable-next-line import/no-extraneous-dependencies
import { TenantModel } from 'blues-corejs/dist'
import ToastHelper from '@lib/helpers/toast.helper'

export const addTenantEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [ADD_TENANT],
      PreloaderConstants.ADD_TENANT,
      (action: ActionInterface) => RequestService.addTenant(action.payload),
      () => {
        Router.push(PagePathConstant.CREATE_TENANT_SUCCESS)
        return emptyAction()
      },
      ErrorGroupConstants.TENANT
    )
  )

export const setCreatedTenantNameEpic = (action$: any) =>
  action$.pipe(
    ofType(SET_CREATED_TENANT_NAME),

    map((action: ActionInterface) => {
      LocalStorageHelper.saveCreatedTenantName(action.payload)
      return emptyAction()
    })
  )

export const markAsTenantCreatorEpic = (action$: any) =>
  action$.pipe(
    ofType(MARK_AS_TENANT_CREATOR),

    map(() => {
      Router.push(PagePathConstant.CREATE_TENANT)
      return emptyAction()
    })
  )

// Epics related to the new Tenant Service

export const requestGetTenantEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_GET_TENANT],
      PreloaderConstants.REQUEST_GET_TENANT,
      () =>
        GrpcTenantsService.getTenant().catch((error: Error) => {
          ToastHelper.error('Something went wrong: ' + error?.message)
          return sentrySimpleReThrowHandler(error)
        }),
      (tenant: TenantModel) => setTenant(tenant),
      ErrorGroupConstants.TENANT
    )
  )

export const requestUpdateTenantEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_UPDATE_TENANT],
      PreloaderConstants.REQUEST_UPDATE_TENANT,
      (action: TypedActionInterface<TenantModel>) =>
        GrpcTenantsService.updateTenant(action.payload).catch(
          (error: Error) => {
            ToastHelper.error('Something went wrong: ' + error?.message)
            return sentrySimpleReThrowHandler(error)
          }
        ),
      (tenant: TenantModel) => {
        ToastHelper.success('Tenant settings has been updated')

        return setTenant(tenant)
      },
      ErrorGroupConstants.TENANT
    )
  )
