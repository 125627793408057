"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VulnerabilitiesDetectionStrategy = void 0;
const models_1 = require("../models");
class VulnerabilitiesDetectionStrategy {
    identifyRiskLevel(id) {
        switch (id) {
            case models_1.SnapshotVulnerabilitiesTypeName.PUBLIC_PERMISSIONS:
                return models_1.RiskLevel.Medium;
            case models_1.SnapshotVulnerabilitiesTypeName.UNENCRYPTED:
                return models_1.RiskLevel.High;
            case models_1.EbsVulnerabilityKind.NOT_USING_CMK:
                return models_1.RiskLevel.Medium;
            case models_1.EbsVulnerabilityKind.UNENCRYPTED:
                return models_1.RiskLevel.High;
            case models_1.EbsVulnerabilityKind.UNATTACHED:
                return models_1.RiskLevel.Low;
            case models_1.EbsVulnerabilityKind.SNAPSHOTS_OLDER_7DAYS:
                return models_1.RiskLevel.High;
            case models_1.EbsVulnerabilityKind.NOT_CYBER_SCANNED:
                return models_1.RiskLevel.High;
            case models_1.EbsVulnerabilityKind.NO_BACKUPS:
                return models_1.RiskLevel.High;
            default:
                return models_1.RiskLevel.Low;
        }
    }
    isStale(snapshot) {
        const millisecondsInWeek = 7 * 24 * 60 * 60 * 1000;
        return snapshot.age > millisecondsInWeek;
    }
}
exports.VulnerabilitiesDetectionStrategy = VulnerabilitiesDetectionStrategy;
