import { useEffect, useState } from 'react'

export function useGlobalLoginCheck(): boolean {
  const [isGlobalLogin, setIsGlobalLogin] = useState(true)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const globalDomains = [
        process.env.NEXT_PUBLIC_LOGIN_DOMAIN,
        process.env.NEXT_PUBLIC_TENANT_CREATION_DOMAIN,
      ]

      const isGlobalHost = globalDomains.includes(window.location.hostname)

      if (!isGlobalHost) {
        setIsGlobalLogin(false)
      }
    }
  }, [])

  return isGlobalLogin
}
