import { ScanTargetKind, Status } from '@lib/clients/scans/types'

export enum FilterNamesConstant {
  ACCOUNT_ID = 'accountId',
  REGIONS = 'regions',
  INSTANCE_IDS = 'instanceIds',
  INSTANCE_TYPES = 'instanceTypes',
  VOLUME_IDS = 'volumeIds',
  VOLUME_TYPES = 'volumeTypes',
  VPC_IDS = 'vpcIds',
  TAGS = 'tags',
  VAULTS = 'vaults',
  CLOUD_CONNECTORS = 'cloudConnectors',
  CLOUD_CONNECTOR_STATUSES = 'cloudConnectorStatuses',
  IS_PROTECTED = 'isProtected',
  IS_PAUSED_INSTANCES = 'isPausedInstances',
  HUMAN_READABLE_RECOVERY_POINT_ID = 'humanReadableRecoveryPointId',
  ASSET_ITEM_KIND = 'assetItemKind',
  ACCOUNT_IDS_LIST = 'accountIdsList',
  REGIONS_LIST = 'regionsList',
  SCAN_TARGET_KINDS_LIST = 'scanTargetKindsList',
  HUMAN_READABLE_RECOVERY_POINT_IDS_LIST = 'humanReadableRecoveryPointIdsList',
  ASSET_ID_LIST = 'assetIdList',
  STATUSES_LIST = 'statusesList',
}

export function getScanTargetKindName(scanTargetKind: ScanTargetKind): string {
  switch (scanTargetKind) {
    case ScanTargetKind.BACKUP:
      return 'Recovery point'
    case ScanTargetKind.DIRECT_SCAN:
      return 'Direct scan'
    default:
      return ''
  }
}

export function getFsCheckScanStatusName(scanStatus: Status): string {
  switch (scanStatus) {
    case Status.GOOD:
      return 'Succeeded'
    case Status.BAD:
      return 'Failed'
    default:
      return ''
  }
}

export function getScanStatusName(scanStatus: Status): string {
  switch (scanStatus) {
    case Status.GOOD:
      return 'Clean'
    case Status.BAD:
      return 'Infected'
    default:
      return ''
  }
}
