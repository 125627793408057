import React from 'react'

export default function Ec2Icon({
  width = '47',
  height = '47',
}: {
  width?: string
  height?: string
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="EC2 1" clipPath="url(#clip0_13114_84113)">
        <circle
          id="Ellipse 531"
          cx="24"
          cy="24"
          r="23"
          fill="url(#paint0_linear_13114_84113)"
        />
        <path
          id="Amazon-EC2_Icon_64_Squid"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5705 29.9595H29.5214V19.0086H18.5705V29.9595ZM30.3975 19.0086H32.1496V19.8846H30.3975V21.6368H32.1496V22.5129H30.3975V23.827H32.1496V24.703H30.3975V26.4552H32.1496V27.3313H30.3975V29.0834H32.1496V29.9595H30.3975V30.019C30.3975 30.4693 30.0313 30.8355 29.581 30.8355H29.5214V32.5877H28.6454V30.8355H26.8932V32.5877H26.0171V30.8355H24.703V32.5877H23.827V30.8355H22.0748V32.5877H21.1988V30.8355H19.4466V32.5877H18.5705V30.8355H18.511C18.0607 30.8355 17.6945 30.4693 17.6945 30.019V29.9595H16.3804V29.0834H17.6945V27.3313H16.3804V26.4552H17.6945V24.703H16.3804V23.827H17.6945V22.5129H16.3804V21.6368H17.6945V19.8846H16.3804V19.0086H17.6945V18.949C17.6945 18.4987 18.0607 18.1325 18.511 18.1325H18.5705V16.3804H19.4466V18.1325H21.1988V16.3804H22.0748V18.1325H23.827V16.3804H24.703V18.1325H26.0171V16.3804H26.8932V18.1325H28.6454V16.3804H29.5214V18.1325H29.581C30.0313 18.1325 30.3975 18.4987 30.3975 18.949V19.0086ZM24.703 35.5996C24.703 35.6294 24.6785 35.6539 24.6487 35.6539H12.9304C12.9006 35.6539 12.8761 35.6294 12.8761 35.5996V23.8813C12.8761 23.8515 12.9006 23.827 12.9304 23.827H15.5043V22.9509H12.9304C12.4174 22.9509 12 23.3683 12 23.8813V35.5996C12 36.1126 12.4174 36.53 12.9304 36.53H24.6487C25.1617 36.53 25.5791 36.1126 25.5791 35.5996V33.4638H24.703V35.5996ZM36.53 12.9304V24.6487C36.53 25.1617 36.1126 25.5791 35.5996 25.5791H33.0257V24.703H35.5996C35.6294 24.703 35.6539 24.6785 35.6539 24.6487V12.9304C35.6539 12.9006 35.6294 12.8761 35.5996 12.8761H23.8813C23.8515 12.8761 23.827 12.9006 23.827 12.9304V15.5043H22.9509V12.9304C22.9509 12.4174 23.3683 12 23.8813 12H35.5996C36.1126 12 36.53 12.4174 36.53 12.9304Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_13114_84113"
          x1="1"
          y1="4601"
          x2="4601"
          y2="1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C8511B" />
          <stop offset="1" stopColor="#FF9900" />
        </linearGradient>
        <clipPath id="clip0_13114_84113">
          <rect
            width="46"
            height="45.9981"
            fill="white"
            transform="translate(0.900391 0.898438)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
