import { useEffect, useState } from 'react'

interface EnvKeys {
  publicLoginDomain: string
}

const API_ROUTE = '/api/fetch-envs'

const INITIAL_STATE_KEYS: EnvKeys = {
  publicLoginDomain: '',
}
/**
 * Fetches the env keys from the Next.JS server.
 * Because of server-side rendering, we can not get .env variables on the client side directly, because they are undefined.
 */
export function useFetchEnvKeys() {
  const [envKeys, setEnvKeys] = useState<EnvKeys>(INITIAL_STATE_KEYS)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function fetchEnvs() {
      const apiKey = await fetch(API_ROUTE)
      const response = await apiKey.json()

      setEnvKeys(response)

      setIsLoading(false)
    }

    fetchEnvs()
  }, [])

  return {
    envKeys,
    isLoading,
  }
}
