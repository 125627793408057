import { PolicyStatus } from '@lib/constants/policies.constant'
import RetentionPolicyFactory from '@lib/factories/retention-policy.factory'
import PaginatedInterface from '@lib/interfaces/paginated.interface'
import RetentionDurationInterface from '@lib/interfaces/retention-policy/retention-duration.interface'
import { RetentionPolicyPriority } from '@lib/interfaces/retention-policies.interface'
import RetentionPolicyModel from '@lib/models/retention-policy.model'
import GrpcBaseService from '@lib/services/grpc/base/grpc-base.service'
import { RetentionPoliciesPromiseClient } from 'blue-stack-libs/catalog-grpc-libs/js/catalog/catalog_grpc_web_pb'
import {
  CreateCustomRetentionPolicyRequest,
  DeleteRetentionPolicyRequest,
  GetRetentionPolicyRequest,
  ListRetentionPoliciesRequest,
  SetCustomRetentionPolicyPrioritiesRequest,
  UpdateCustomRetentionPolicyRequest,
  UpdateDefaultRetentionPolicyRequest,
} from 'blue-stack-libs/catalog-grpc-libs/js/catalog/catalog_pb'
import {
  CustomRetentionPolicy,
  RetentionPolicy,
  Selector,
} from 'blue-stack-libs/catalog-grpc-libs/js/catalog/messages/retention_pb'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { VIRow } from '@lib/engine-types'
import DurationConstant from '@lib/constants/data/time/duration.constant'
import PeriodConstant from '@lib/constants/data/time/period.constant'
import {
  FormPeriodInterface,
  RowPeriodInterface,
} from '@lib/interfaces/form/form-period.interface'
import { AssetKind } from '@lib/constants/grpc/asset-kind.constant'
import ValueInterface from '@lib/interfaces/value.interface'
import { getAssetSourceName } from '@lib/constants/retention-policy/asset-source.constant'
import { getBackupTypeName } from '@lib/constants/retention-policy/backup-type.constant'
import { SelectCategory } from '@lib/constants/retention-policy/select-category.constant'
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'

class GrpcRetentionPoliciesService extends GrpcBaseService {
  protected static client = new RetentionPoliciesPromiseClient('', null, null)
  protected static clientName = 'RetentionPolicies'

  public static async getRetentionPolicy(
    id: string
  ): Promise<RetentionPolicyModel> {
    const request = new GetRetentionPolicyRequest()
    request.setId(id)
    const result = await this.handleQueryRetry(
      this.client.getRetentionPolicy,
      request
    )

    const criteriaList = result
      .getRetentionPolicy()
      ?.getCustomPolicy()
      ?.getSelectorsList()[0]
      ?.getCriteriaList()

    const editPolicyAssetSources: VIRow = []
    const editPolicyBackupTypes: VIRow = []

    criteriaList?.map((item) => {
      // get AssetSource value
      if (item.getMatchOnCase() === 1) {
        const as = item.getAssetSource()
        editPolicyAssetSources.push({
          name: getAssetSourceName(as),
          value: as,
        })
      }

      if (item.getMatchOnCase() === 2) {
        // get BackupType value
        const bt = item.getBackupType()
        editPolicyBackupTypes.push({
          name: getBackupTypeName(bt),
          value: bt,
        })
      }
    })

    const resultObj = result.toObject()

    return RetentionPolicyFactory.buildRetentionPolicyFromGrpc(
      resultObj.retentionPolicy,
      editPolicyAssetSources,
      editPolicyBackupTypes
    )
  }

  public static async listRetentionPolicies(): Promise<
    PaginatedInterface<RetentionPolicyModel>
  > {
    const request = new ListRetentionPoliciesRequest()
    const result = await this.handleQueryRetry(
      this.client.listRetentionPolicies,
      request
    )

    const listPolicies = result.getRetentionPoliciesList()

    const policyObj = {
      list: listPolicies.map((policy) => {
        const criteriaList = policy
          ?.getCustomPolicy()
          ?.getSelectorsList()[0]
          ?.getCriteriaList()

        const editPolicyAssetSources: VIRow = []
        const editPolicyBackupTypes: VIRow = []

        criteriaList?.map((item) => {
          // get AssetSource value
          if (item.getMatchOnCase() === 1) {
            const as = item.getAssetSource()
            editPolicyAssetSources.push({
              name: getAssetSourceName(as),
              value: as,
            })
          }

          if (item.getMatchOnCase() === 2) {
            // get BackupType value
            const bt = item.getBackupType()
            editPolicyBackupTypes.push({
              name: getBackupTypeName(bt),
              value: bt,
            })
          }
        })

        const resultPolicy = policy.toObject()

        return RetentionPolicyFactory.buildRetentionPolicyFromGrpc(
          resultPolicy,
          editPolicyAssetSources,
          editPolicyBackupTypes
        )
      }),
      total: listPolicies.length,
    }

    return policyObj
  }

  private static fillRetentionDuration(
    periodRow: Record<string, any>
  ): RetentionPolicy.RetentionDuration {
    const updDuration = new RetentionPolicy.RetentionDuration()

    if (periodRow.duration === DurationConstant.DURATION_HOURS.name) {
      updDuration.setHours(Number(periodRow.countDuration))
    }
    if (periodRow.duration === DurationConstant.DURATION_DAYS.name) {
      updDuration.setDays(Number(periodRow.countDuration))
    }
    if (periodRow.duration === DurationConstant.DURATION_WEEKS.name) {
      updDuration.setWeeks(Number(periodRow.countDuration))
    }
    if (periodRow.duration === DurationConstant.DURATION_MONTHS.name) {
      updDuration.setMonths(Number(periodRow.countDuration))
    }
    if (periodRow.duration === DurationConstant.DURATION_YEARS.name) {
      updDuration.setYears(Number(periodRow.countDuration))
    }
    if (periodRow.duration === DurationConstant.DURATION_INFINITE.name) {
      updDuration.setInfinite(new Empty())
    }
    return updDuration
  }

  public static async createRetentionPolicy(
    retentionPolicyName: string,
    recoveryPeriod: FormPeriodInterface,
    selectedAssets: Array<AssetWithRelatedAssets<Asset>>,
    policyRetentionAccountIds: VIRow,
    policyRetentionRegions: VIRow,
    policyRetentionVaults: VIRow,
    policyRetentionBackupTypes: VIRow,
    policyRetentionAssetSources: VIRow,
    policyRetentionTags: VIRow
  ): Promise<any> {
    // Name
    const customRetentionPolicy = new CustomRetentionPolicy()
    customRetentionPolicy.setId(retentionPolicyName)

    // Period Block and Status
    const period = new RetentionPolicy()

    recoveryPeriod?.period
      ?.map((periodRow: RowPeriodInterface) => {
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_ALL.name) {
          period.setKeepAll(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_HOURLY.name) {
          period.setKeepHourly(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_DAILY.name) {
          period.setKeepDaily(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_WEEKLY.name) {
          period.setKeepWeekly(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_MONTHLY.name) {
          period.setKeepMonthly(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_YEARLY.name) {
          period.setKeepYearly(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
      })
      .join()

    period.setStatus(PolicyStatus.POLICY_STATUS_ENABLED)
    customRetentionPolicy.setPolicy(period)

    // Recovery Point Type
    const updSelectorsList = [] as Selector[]
    const updSelector = new Selector()
    const updCriteriaList = [] as Selector.Criterion[]
    if (policyRetentionAccountIds.length > 0) {
      policyRetentionAccountIds.map((accId) => {
        const updCriterion = new Selector.Criterion()
        updCriterion.setAwsAccountId(accId.name)
        updCriteriaList.push(updCriterion)
      })
    }
    if (policyRetentionRegions.length > 0) {
      policyRetentionRegions.map((reg) => {
        const updCriterion = new Selector.Criterion()
        updCriterion.setAwsRegion(reg.name)
        updCriteriaList.push(updCriterion)
      })
    }
    if (policyRetentionBackupTypes.length > 0) {
      policyRetentionBackupTypes.map((bt) => {
        const updCriterion = new Selector.Criterion()
        updCriterion.setBackupType(Number(bt.value))
        updCriteriaList.push(updCriterion)
      })
    }
    if (policyRetentionAssetSources.length > 0) {
      policyRetentionAssetSources.map((as) => {
        const updCriterion = new Selector.Criterion()
        updCriterion.setAssetSource(Number(as.value))
        updCriteriaList.push(updCriterion)
      })
    }
    if (policyRetentionVaults.length > 0) {
      policyRetentionVaults.map((v) => {
        const updCriterion = new Selector.Criterion()
        const updVault = new Selector.Criterion.Vault()
        updVault.setVault(String(v.value))
        updVault.setRegion(String(v.extraValue))
        updVault.setAwsAccountId(String(v.defaultValue))
        updCriterion.setVault(updVault)
        updCriteriaList.push(updCriterion)
      })
    }
    if (policyRetentionTags.length > 0) {
      const updCriterion = new Selector.Criterion()
      const updTags = new Selector.Criterion.Tags()
      policyRetentionTags.map((t) => {
        updTags.getTagsMap().set(t.name, String(t.value))
      })
      updCriterion.setTags(updTags)
      updCriteriaList.push(updCriterion)
    }

    // Specific Assets
    const updCriterion = new Selector.Criterion()
    const updAssets = new Selector.Criterion.Assets()
    const updAwsAssets = [] as Selector.Criterion.AwsAsset[]
    const updGenericAssets = [] as Selector.Criterion.GenericHostAsset[]
    const updNasAssets = [] as Selector.Criterion.NasAsset[]

    selectedAssets.map((item: AssetWithRelatedAssets<Asset>) => {
      const assetModel = item.asset
      if (item.type === AssetKind.AWS_EBS) {
        const updAws = new Selector.Criterion.AwsAsset()
        updAws.setAccountId(assetModel.awsAccountId)
        updAws.setRegion(assetModel.awsRegion)
        updAws.setEbs(assetModel.awsId)
        updAwsAssets.push(updAws)
      }
      if (item.type === AssetKind.AWS_EC2) {
        const updAws = new Selector.Criterion.AwsAsset()
        updAws.setAccountId(assetModel.awsAccountId)
        updAws.setRegion(assetModel.awsRegion)
        updAws.setEbs(assetModel.awsId)
        updAwsAssets.push(updAws)
      }
      if (item.type === AssetKind.AWS_S3) {
        const updAws = new Selector.Criterion.AwsAsset()
        updAws.setAccountId(assetModel.awsAccountId)
        updAws.setRegion(assetModel.awsRegion)
        updAws.setEbs(assetModel.awsId)
        updAwsAssets.push(updAws)
      }
      if (item.type === AssetKind.GENERIC_HOST) {
        const updGeneric = new Selector.Criterion.GenericHostAsset()
        updGeneric.setHostname(assetModel.awsId)
        updGenericAssets.push(updGeneric)
      }
      if (item.type === AssetKind.GENERIC_FS) {
        const updNas = new Selector.Criterion.NasAsset()
        updNas.setHostname(assetModel.awsId)
        updNasAssets.push(updNas)
      }
    })

    updAssets.setGenericList(updGenericAssets)
    updAssets.setAwsList(updAwsAssets)
    updAssets.setNasList(updNasAssets)
    updCriterion.setAssets(updAssets)
    updCriteriaList.push(updCriterion)
    updSelector.setCriteriaList(updCriteriaList)
    updSelectorsList.push(updSelector)
    customRetentionPolicy.setSelectorsList(updSelectorsList)

    const request = new CreateCustomRetentionPolicyRequest()
    request.setCustomRetentionPolicy(customRetentionPolicy)
    const result = await this.handleQueryLong(
      this.client.createCustomRetentionPolicy,
      request
    )
    return result.toObject()
  }

  public static async deleteRetentionPolicy(id: string): Promise<any> {
    const request = new DeleteRetentionPolicyRequest()
    request.setId(id)
    const result = await this.handleQueryLong(
      this.client.deleteRetentionPolicy,
      request
    )
    return result.toObject()
  }

  private static setRetentionDuration(
    period: RetentionDurationInterface
  ): RetentionPolicy.RetentionDuration {
    const updDuration = new RetentionPolicy.RetentionDuration()

    if (period.hours) {
      updDuration.setHours(period.hours)
    }
    if (period.days) {
      updDuration.setDays(period.days)
    }
    if (period.weeks) {
      updDuration.setWeeks(period.weeks)
    }
    if (period.months) {
      updDuration.setMonths(period.months)
    }
    if (period.years) {
      updDuration.setYears(period.years)
    }
    if (period.infinite) {
      updDuration.setInfinite(new Empty())
    }

    return updDuration
  }

  public static async editRetentionPolicy(
    policyName: string,
    recoveryPeriod: FormPeriodInterface,
    selectedAssets: VIRow,
    accountIdsForUpdate: VIRow,
    regionsForUpdate: VIRow,
    backupTypesForUpdate: VIRow,
    assetSourcesForUpdate: VIRow,
    vaultsForUpdate: VIRow,
    tagsForUpdate: VIRow,
    policyStatus?: PolicyStatus
  ): Promise<any> {
    // Set Retention policy retention duration
    const updPeriod = new RetentionPolicy()

    recoveryPeriod?.period
      ?.map((periodRow) => {
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_ALL.name) {
          updPeriod.setKeepAll(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_HOURLY.name) {
          updPeriod.setKeepHourly(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_DAILY.name) {
          updPeriod.setKeepDaily(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_WEEKLY.name) {
          updPeriod.setKeepWeekly(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_MONTHLY.name) {
          updPeriod.setKeepMonthly(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_YEARLY.name) {
          updPeriod.setKeepYearly(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
      })
      .join()

    // Set status
    const updStatus = policyStatus ?? PolicyStatus.POLICY_STATUS_ENABLED
    updPeriod.setStatus(updStatus)

    // Update Custom policy
    const updCustomPolicy = new CustomRetentionPolicy()

    // Set policy id (name)
    updCustomPolicy.setId(policyName)

    // Set policy period for update
    updCustomPolicy.setPolicy(updPeriod)

    // set policy Selectors
    const updSelectorsList = [] as Selector[]
    const updSelector = new Selector()
    const updCriteriaList = [] as Selector.Criterion[]

    if (accountIdsForUpdate.length > 0) {
      accountIdsForUpdate.map((accId) => {
        const updCriterion = new Selector.Criterion()
        updCriterion.setAwsAccountId(accId.name)
        updCriteriaList.push(updCriterion)
      })
    }
    if (regionsForUpdate.length > 0) {
      regionsForUpdate.map((reg) => {
        const updCriterion = new Selector.Criterion()
        updCriterion.setAwsRegion(reg.name)
        updCriteriaList.push(updCriterion)
      })
    }
    if (backupTypesForUpdate.length > 0) {
      backupTypesForUpdate.map((bt) => {
        const updCriterion = new Selector.Criterion()
        updCriterion.setBackupType(Number(bt.value))
        updCriteriaList.push(updCriterion)
      })
    }
    if (assetSourcesForUpdate.length > 0) {
      assetSourcesForUpdate.map((as) => {
        const updCriterion = new Selector.Criterion()
        updCriterion.setAssetSource(Number(as.value))
        updCriteriaList.push(updCriterion)
      })
    }
    if (vaultsForUpdate.length > 0) {
      vaultsForUpdate.map((v) => {
        const updCriterion = new Selector.Criterion()
        const updVault = new Selector.Criterion.Vault()
        // value: criteria.vault.vault,
        // defaultValue: criteria.vault.awsAccountId,
        // extraValue: criteria.vault.region,
        updVault.setVault(String(v.value))
        updVault.setRegion(String(v.extraValue))
        updVault.setAwsAccountId(String(v.defaultValue))

        updCriterion.setVault(updVault)
        updCriteriaList.push(updCriterion)
      })
    }
    if (tagsForUpdate.length > 0) {
      const updCriterion = new Selector.Criterion()
      const updTags = new Selector.Criterion.Tags()
      tagsForUpdate.map((t) => {
        updTags.getTagsMap().set(t.name, String(t.value))
      })
      updCriterion.setTags(updTags)
      updCriteriaList.push(updCriterion)
    }

    // Specific Assets
    const updAssetsCriterion = new Selector.Criterion()
    const updAssets = new Selector.Criterion.Assets()
    const updAwsAssets = [] as Selector.Criterion.AwsAsset[]
    const updGenericAssets = [] as Selector.Criterion.GenericHostAsset[]
    const updNasAssets = [] as Selector.Criterion.NasAsset[]

    selectedAssets.map((assetSelected: ValueInterface) => {
      if (assetSelected.type === AssetKind.AWS_EBS) {
        const updAws = new Selector.Criterion.AwsAsset()
        updAws.setAccountId(String(assetSelected.value))
        updAws.setRegion(String(assetSelected.label))
        updAws.setEbs(assetSelected.name)
        updAwsAssets.push(updAws)
      }
      if (assetSelected.type === AssetKind.AWS_EC2) {
        const updAws = new Selector.Criterion.AwsAsset()
        updAws.setAccountId(String(assetSelected.value))
        updAws.setRegion(String(assetSelected.label))
        updAws.setEc2(assetSelected.name)
        updAwsAssets.push(updAws)
      }
      if (assetSelected.type === AssetKind.AWS_S3) {
        const updAws = new Selector.Criterion.AwsAsset()
        updAws.setAccountId(String(assetSelected.value))
        updAws.setRegion(String(assetSelected.label))
        updAws.setS3(assetSelected.name)
        updAwsAssets.push(updAws)
      }
      if (assetSelected.type === AssetKind.GENERIC_HOST) {
        const updGeneric = new Selector.Criterion.GenericHostAsset()
        updGeneric.setHostname(assetSelected.name)
        updGenericAssets.push(updGeneric)
      }
      if (assetSelected.type === AssetKind.GENERIC_FS) {
        const updNas = new Selector.Criterion.NasAsset()
        updNas.setHostname(assetSelected.name)
        updNasAssets.push(updNas)
      }
    })

    updAssets.setGenericList(updGenericAssets)
    updAssets.setAwsList(updAwsAssets)
    updAssets.setNasList(updNasAssets)
    updAssetsCriterion.setAssets(updAssets)
    updCriteriaList.push(updAssetsCriterion)

    // Set Selector
    updSelector.setCriteriaList(updCriteriaList)
    updSelectorsList.push(updSelector)

    updCustomPolicy.setSelectorsList(updSelectorsList)

    const request = new UpdateCustomRetentionPolicyRequest()
    request.setCustomRetentionPolicy(updCustomPolicy)

    const result = await this.handleQueryLong(
      this.client.updateCustomRetentionPolicy,
      request
    )
    return result.toObject()
  }

  public static async editDefaultRetentionPolicy(
    recoveryPeriod: FormPeriodInterface,
    policyStatus?: PolicyStatus
  ): Promise<any> {
    // Set Retention policy retention duration
    const updPeriod = new RetentionPolicy()

    recoveryPeriod?.period
      ?.map((periodRow) => {
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_ALL.name) {
          updPeriod.setKeepAll(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_HOURLY.name) {
          updPeriod.setKeepHourly(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_DAILY.name) {
          updPeriod.setKeepDaily(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_WEEKLY.name) {
          updPeriod.setKeepWeekly(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_MONTHLY.name) {
          updPeriod.setKeepMonthly(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
        if (periodRow.periodValue === PeriodConstant.PERIOD_KEEP_YEARLY.name) {
          updPeriod.setKeepYearly(
            GrpcRetentionPoliciesService.fillRetentionDuration(periodRow)
          )
        }
      })
      .join()

    // Set status
    const updStatus = policyStatus ?? PolicyStatus.POLICY_STATUS_ENABLED
    updPeriod.setStatus(updStatus)

    // Update Default policy
    const request = new UpdateDefaultRetentionPolicyRequest()
    request.setRetentionPolicy(updPeriod)
    const result = await this.handleQueryLong(
      this.client.updateDefaultRetentionPolicy,
      request
    )
    return result.toObject()
  }

  public static async updateRetentionPolicy(
    policy: RetentionPolicyModel,
    newStatus?: PolicyStatus,
    policyName?: string
  ): Promise<any> {
    const isDefault = policy.isDefault

    const policyKeepAll = isDefault
      ? policy.defaultPolicy?.policy?.keepAll
      : policy.customPolicy?.policy?.keepAll
    const policyKeepHourly = isDefault
      ? policy.defaultPolicy?.policy?.keepHourly
      : policy.customPolicy?.policy?.keepHourly
    const policyKeepDaily = isDefault
      ? policy.defaultPolicy?.policy?.keepDaily
      : policy.customPolicy?.policy?.keepDaily
    const policyKeepWeekly = isDefault
      ? policy.defaultPolicy?.policy?.keepWeekly
      : policy.customPolicy?.policy?.keepWeekly
    const policyKeepMonthly = isDefault
      ? policy.defaultPolicy?.policy?.keepMonthly
      : policy.customPolicy?.policy?.keepMonthly
    const policyKeepYearly = isDefault
      ? policy.defaultPolicy?.policy?.keepYearly
      : policy.customPolicy?.policy?.keepYearly

    const updPolicy = new RetentionPolicy()

    // Set Retention policy retention duration
    if (policyKeepAll) {
      updPolicy.setKeepAll(
        GrpcRetentionPoliciesService.setRetentionDuration(policyKeepAll)
      )
    }
    if (policyKeepHourly) {
      updPolicy.setKeepHourly(
        GrpcRetentionPoliciesService.setRetentionDuration(policyKeepHourly)
      )
    }
    if (policyKeepDaily) {
      updPolicy.setKeepDaily(
        GrpcRetentionPoliciesService.setRetentionDuration(policyKeepDaily)
      )
    }
    if (policyKeepWeekly) {
      updPolicy.setKeepWeekly(
        GrpcRetentionPoliciesService.setRetentionDuration(policyKeepWeekly)
      )
    }
    if (policyKeepMonthly) {
      updPolicy.setKeepMonthly(
        GrpcRetentionPoliciesService.setRetentionDuration(policyKeepMonthly)
      )
    }
    if (policyKeepYearly) {
      updPolicy.setKeepYearly(
        GrpcRetentionPoliciesService.setRetentionDuration(policyKeepYearly)
      )
    }

    // Set new status
    const policyCurrentStatus = isDefault
      ? policy.defaultPolicy?.policy?.status ||
        PolicyStatus.POLICY_STATUS_ENABLED
      : policy.customPolicy?.policy?.status ||
        PolicyStatus.POLICY_STATUS_ENABLED

    const updStatus = newStatus ?? policyCurrentStatus
    updPolicy.setStatus(updStatus)

    if (isDefault) {
      // Update Default policy
      const request = new UpdateDefaultRetentionPolicyRequest()
      request.setRetentionPolicy(updPolicy)
      const result = await this.handleQueryLong(
        this.client.updateDefaultRetentionPolicy,
        request
      )
      return result.toObject()
    } else {
      // Update Custom policy
      const updCustomPolicy = new CustomRetentionPolicy()

      // Set policy id (name)
      if (policy.customPolicy?.id) {
        const policyId = policyName ?? policy.customPolicy?.id
        updCustomPolicy.setId(policyId)
      }

      // Set policy for update
      updCustomPolicy.setPolicy(updPolicy)

      const backupTypesForUpdate = policy.dataForRecoveryPointTypes?.find(
        (item) => item.name === SelectCategory.BACKUP_TYPES
      )?.options
      const assetSourcesForUpdate = policy.dataForRecoveryPointTypes?.find(
        (item) => item.name === SelectCategory.ASSET_SOURCES
      )?.options

      // set policy Selectors
      if (policy.customPolicy?.selectorsList) {
        const currentSelectors = policy.customPolicy?.selectorsList
        const updSelectorsList = [] as Selector[]
        const updCriteriaList = [] as Selector.Criterion[]

        currentSelectors.map((sel) => {
          // Selector for update
          const updSelector = new Selector()
          const selCriteriaList = sel.criteriaList

          if (selCriteriaList) {
            selCriteriaList.map((criteria) => {
              // Criterion for update
              const updCriterion = new Selector.Criterion()

              if (criteria.awsAccountId) {
                updCriterion.setAwsAccountId(criteria.awsAccountId)
              }
              if (criteria.awsRegion) {
                updCriterion.setAwsRegion(criteria.awsRegion)
              }
              // Vault
              if (criteria.vault) {
                const updVault = new Selector.Criterion.Vault()
                updVault.setVault(criteria.vault.vault)
                updVault.setRegion(criteria.vault.region)
                if (criteria.vault.awsAccountId) {
                  updVault.setAwsAccountId(criteria.vault.awsAccountId)
                }

                updCriterion.setVault(updVault)
              }

              // Assets
              if (criteria.assets) {
                const updAssets = new Selector.Criterion.Assets()

                // aws list
                if (criteria.assets.awsList) {
                  const crAwsList = criteria.assets.awsList
                  const updAwsAssets = [] as Selector.Criterion.AwsAsset[]
                  crAwsList.map((aws) => {
                    const updAws = new Selector.Criterion.AwsAsset()
                    if (aws.accountId) {
                      updAws.setAccountId(aws.accountId)
                    }
                    if (aws.ebs) {
                      updAws.setEbs(aws.ebs)
                    }
                    if (aws.ec2) {
                      updAws.setEc2(aws.ec2)
                    }
                    if (aws.s3) {
                      updAws.setS3(aws.s3)
                    }
                    if (aws.region) {
                      updAws.setRegion(aws.region)
                    }
                    updAwsAssets.push(updAws)
                  })
                  updAssets.setAwsList(updAwsAssets)
                }

                // generic list
                if (criteria.assets.genericList) {
                  const crGenericList = criteria.assets.genericList
                  const updGenericAssets =
                    [] as Selector.Criterion.GenericHostAsset[]

                  crGenericList.map((gh) => {
                    const updGeneric = new Selector.Criterion.GenericHostAsset()
                    if (gh.hostname) {
                      updGeneric.setHostname(gh.hostname)
                    }
                    updGenericAssets.push(updGeneric)
                  })

                  updAssets.setGenericList(updGenericAssets)
                }

                // nas list
                if (criteria.assets.nasList) {
                  const crNasList = criteria.assets.nasList
                  const updNasAssets = [] as Selector.Criterion.NasAsset[]

                  crNasList.map((n) => {
                    const updNas = new Selector.Criterion.NasAsset()
                    if (n.hostname) {
                      updNas.setHostname(n.hostname)
                    }
                    updNasAssets.push(updNas)
                  })

                  updAssets.setNasList(updNasAssets)
                }

                updCriterion.setAssets(updAssets)
              }

              //Tags
              if (criteria.tags) {
                const updTags = new Selector.Criterion.Tags()
                const crTags = criteria.tags
                crTags.tagsMap.map((tag) =>
                  updTags.getTagsMap().set(tag[0], tag[1])
                )

                updCriterion.setTags(updTags)
              }

              if (updCriterion.getMatchOnCase() !== 0) {
                updCriteriaList.push(updCriterion)
              }
            })

            if (backupTypesForUpdate && backupTypesForUpdate.length > 0) {
              backupTypesForUpdate.map((bt) => {
                const updCriterionBT = new Selector.Criterion()
                updCriterionBT.setBackupType(Number(bt.value))
                updCriteriaList.push(updCriterionBT)
              })
            }
            if (assetSourcesForUpdate && assetSourcesForUpdate.length > 0) {
              assetSourcesForUpdate.map((as) => {
                const updCriterionAS = new Selector.Criterion()
                updCriterionAS.setAssetSource(Number(as.value))
                updCriteriaList.push(updCriterionAS)
              })
            }

            updSelector.setCriteriaList(updCriteriaList)
          } //criteria list

          // Set Selector
          updSelectorsList.push(updSelector)
        }) //selectors

        updCustomPolicy.setSelectorsList(updSelectorsList)
      }

      const request = new UpdateCustomRetentionPolicyRequest()
      request.setCustomRetentionPolicy(updCustomPolicy)

      const result = await this.handleQueryLong(
        this.client.updateCustomRetentionPolicy,
        request
      )
      return result.toObject()
    }
  }

  public static async updateStatusRetentionPolicy(
    policy: RetentionPolicyModel
  ): Promise<any> {
    const policyCurrentStatus = policy.isDefault
      ? policy.defaultPolicy?.policy?.status
      : policy.customPolicy?.policy?.status

    const updStatus =
      policyCurrentStatus === PolicyStatus.POLICY_STATUS_DISABLED
        ? PolicyStatus.POLICY_STATUS_ENABLED
        : PolicyStatus.POLICY_STATUS_DISABLED

    GrpcRetentionPoliciesService.updateRetentionPolicy(policy, updStatus)
  }

  public static async setCustomRetentionPolicyPriorities(
    prioritiesList: Array<RetentionPolicyPriority>
  ) {
    const request = new SetCustomRetentionPolicyPrioritiesRequest()

    const requestsLists: SetCustomRetentionPolicyPrioritiesRequest.RetentionPolicyPriority[] =
      []

    prioritiesList.forEach(({ policyId, priority }) => {
      const request =
        new SetCustomRetentionPolicyPrioritiesRequest.RetentionPolicyPriority()

      request.setPolicyId(policyId)
      request.setPriority(priority)

      requestsLists.push(request)
    })

    request.setPrioritiesList(requestsLists)

    const result = await this.handleQuery(
      this.client.setCustomRetentionPolicyPriorities,
      request
    )

    return result.toObject()
  }
}

export default GrpcRetentionPoliciesService
