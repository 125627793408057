"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListInventoryScans = void 0;
const ransomware_1 = require("../../models/scans/ransomware");
const malware_1 = require("../../models/scans/malware");
const types_1 = require("../../models/scans/filesystem-check/types");
const types_2 = require("../../models/scans/malware/types");
const types_3 = require("../../models/scans/ransomware/types");
const models_1 = require("../../models");
__exportStar(require("./types"), exports);
class ListInventoryScans {
    execute(scans) {
        return this.prepareOutput({ scans });
    }
    prepareOutput({ scans, }) {
        const scansOutput = scans.map((scan) => {
            const malware = scan.malwares;
            const ransomware = scan.ransomwares;
            const fsCheck = scan.filesystemChecks;
            return {
                isHealthy: this.checkIfHealthy([malware, fsCheck, ransomware]),
                ransomwareStatus: ransomware?.status,
                malwareStatus: malware?.status,
                fsCheckStatus: fsCheck?.status,
                completeTime: this.getLastCompleteTime([malware, fsCheck, ransomware]),
                dataSource: this.getDataSource([malware, fsCheck, ransomware]),
                ransomwareScan: ransomware,
                malwareScan: malware,
                fsCheckScan: fsCheck,
            };
        });
        return {
            scans: scansOutput,
        };
    }
    checkIfHealthy(scans) {
        return scans.every((scan) => {
            if (scan?.status === types_2.MalwareScanStatus.INFECTED ||
                scan?.status === types_3.RansomwareScanStatus.INFECTED ||
                scan?.status === types_1.StatusScanFilesystemCheck.BAD) {
                return false;
            }
            return true;
        });
    }
    getLastCompleteTime(scans) {
        return scans.reduce((lastCompleteTime, scan) => {
            if (scan && scan.createdAt > lastCompleteTime) {
                return scan.createdAt;
            }
            return lastCompleteTime;
        }, new Date(0));
    }
    getDataSource(scans) {
        const source = {
            backupId: null,
            text: 'Direct scan',
            date: undefined,
        };
        scans.forEach((scan) => {
            if (!scan) {
                return null;
            }
            if (scan.scanTarget.target instanceof malware_1.MalwareScanBackup ||
                scan.scanTarget.target instanceof ransomware_1.RansomwareScanBackup ||
                scan.scanTarget.target instanceof models_1.FilesystemScanBackup) {
                if (scan.scanTarget.target.provider === types_2.Provider.ELASTIO) {
                    source.text = 'Elastio Backup';
                }
                if (scan.scanTarget.target.provider === types_2.Provider.AWS) {
                    source.text = 'AWS Backup';
                }
                source.date = scan.scanTarget.target.timestamp;
                source.backupId = scan.scanTarget.target.backupId;
            }
        });
        return source;
    }
}
exports.ListInventoryScans = ListInventoryScans;
