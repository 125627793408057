import GrpcBaseService from '@lib/services/grpc/base/grpc-base.service'
import { JobStatusPromiseClient } from 'blue-stack-libs/jobstatus-grpc-libs/js/jobstatus/jobstatus_grpc_web_pb'
import ValueInterface from '@lib/interfaces/value.interface'
import JobFactory from '@lib/factories/job.factory'
import { ArrayTotalResult, DateRangeTuple } from '@lib/engine-types'
import SystemHelper from '@lib/helpers/system.helper'
import JobModel from '@lib/models/job.model'
import {
  AbortJobRequest,
  ListJobsFilters,
  ListJobsRequest,
  StatusFilterVariant,
} from 'blue-stack-libs/jobstatus-grpc-libs/js/jobstatus/jobstatus_pb'
import * as jobstatus_messages_pagination_pb from 'blue-stack-libs/jobstatus-grpc-libs/js/jobstatus/messages/pagination_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import FilterFactory from '@lib/factories/filter.factory'
import JobKindConstant from '@lib/constants/jobs/job-kind.constant'
import { JobKind } from 'blue-stack-libs/jobstatus-grpc-libs/js/jobstatus/messages/job_kind_pb'

class GrpcJobStatusService extends GrpcBaseService {
  protected static client = new JobStatusPromiseClient('', null, null)
  protected static clientName = 'JobStatus'

  public static async listJobs(
    offset: number,
    limit: number,
    filters: Array<ValueInterface>,
    search: string,
    dateRange: DateRangeTuple
  ): Promise<ArrayTotalResult<JobModel>> {
    const request = new ListJobsRequest()

    // build pagination
    const pagination = new jobstatus_messages_pagination_pb.Pagination()
    pagination.setOffset(offset)
    pagination.setLimit(limit)
    request.setPagination(pagination)

    // build filters
    const requestFilters = new ListJobsFilters()
    if (filters.length > 0) {
      filters.forEach((f) => {
        if (!f.options) {
          return
        }

        switch (f.name) {
          case 'accountIds':
            requestFilters.setAccountIdsList(f.options.map((v) => v.name))
            break
          case 'assetsKind':
            requestFilters.setAssetKindsList(f.options.map((v) => +v.name))
            break
          case 'instanceIds':
            requestFilters.setInstanceIdsList(f.options.map((v) => v.name))
            break
          case 'regions':
            requestFilters.setRegionsList(f.options.map((v) => v.name))
            break
          case 'volumeIds':
            requestFilters.setVolumeIdsList(f.options.map((v) => v.name))
            break
          case 'kinds':
            requestFilters.setKindsList(f.options.map((v) => +v.name))
            break
          case 'jobStatuses':
            requestFilters.setStatusesList(
              f.options.map((v) => {
                const status = new StatusFilterVariant()
                status.setStatus(+v.name)
                return status
              })
            )
            break
          default:
            SystemHelper.throwErrorInLocalEnv(
              'Cannot parse the filter in the list jobs request'
            )
        }
      })
      request.setFilters(requestFilters)
    }

    // set start time
    if (dateRange[0]) {
      requestFilters.setStart(
        Timestamp.fromDate(new Date(dateRange[0].valueOf()))
      )
    }
    // set end time
    if (dateRange[1]) {
      requestFilters.setEnd(
        Timestamp.fromDate(new Date(dateRange[1].valueOf()))
      )
    }
    request.setFilters(requestFilters)
    // search
    if (search) {
      // TODO: we do not have API so far for this, but it must be done soon
      //  request.setSearchQuery(search);
    }

    const result = await this.handleQueryRetry(this.client.listJobs, request)
    const resultObject = result.toObject()
    // TODO: remove mock total
    return {
      total: 0,
      data: resultObject.jobsList.map(JobFactory.buildJobFromGRPC),
    }
  }

  public static async isRedStackJob(): Promise<boolean> {
    const request = new ListJobsRequest()

    const pagination = new jobstatus_messages_pagination_pb.Pagination()
    pagination.setOffset(0)
    pagination.setLimit(1)
    request.setPagination(pagination)

    // build filters
    const requestFilters = new ListJobsFilters()

    requestFilters.setKindsList([
      JobKindConstant.INSTALL_CLOUD_CONNECTOR as unknown as JobKind,
    ])

    request.setFilters(requestFilters)

    const result = await this.handleQueryRetry(this.client.listJobs, request)
    const resultObject = result.toObject()

    return resultObject.jobsList.length > 0
  }

  public static async getJobFilters(): Promise<Array<ValueInterface>> {
    const request = new ListJobsRequest()
    const result = await this.handleQueryRetry(
      this.client.getJobFilters,
      request
    )
    return result
      .toObject()
      .filtersList.map(FilterFactory.buildJobFiltersFromGrpc)
  }

  public static async abortJob(id: string): Promise<any> {
    const request = new AbortJobRequest()
    request.setId(id)
    const result = await this.handleQuery(this.client.abortJob, request)
    return result.toObject()
  }
}

export default GrpcJobStatusService
