import { Request as GrpcListTenantJobsRequest } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/jobs/list_tenant_jobs_pb'
import { Request as Pagination } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/pagination_pb'
import { ListTenantJobsRequest } from '@lib/clients/jobs/tenant-jobs'

export class ListTenantJobsPagination implements ListTenantJobsRequest {
  #pageToken: string

  constructor(pageToken: string) {
    this.#pageToken = pageToken
  }

  toGrpc(): GrpcListTenantJobsRequest {
    const grpcRequest = GrpcListTenantJobsRequest

    return new grpcRequest().setPagination(
      new Pagination().setPageToken(this.#pageToken)
    )
  }
}
