"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Vulnerability = exports.VolumeVulnerabilityKind = exports.SnapshotVulnerabilityKind = exports.RiskLevel = void 0;
var RiskLevel;
(function (RiskLevel) {
    RiskLevel[RiskLevel["Low"] = 0] = "Low";
    RiskLevel[RiskLevel["Medium"] = 1] = "Medium";
    RiskLevel[RiskLevel["High"] = 2] = "High";
})(RiskLevel = exports.RiskLevel || (exports.RiskLevel = {}));
var SnapshotVulnerabilityKind;
(function (SnapshotVulnerabilityKind) {
    SnapshotVulnerabilityKind["UNENCRYPTED"] = "Unencrypted Snapshots";
    SnapshotVulnerabilityKind["PUBLIC_PERMISSIONS"] = "Publicly accessible Snapshots";
})(SnapshotVulnerabilityKind = exports.SnapshotVulnerabilityKind || (exports.SnapshotVulnerabilityKind = {}));
var VolumeVulnerabilityKind;
(function (VolumeVulnerabilityKind) {
    VolumeVulnerabilityKind["UNATTACHED"] = "Unattached Volumes";
    VolumeVulnerabilityKind["UNENCRYPTED"] = "Unencrypted Volumes";
    VolumeVulnerabilityKind["NOT_USING_CMK"] = "Encrypted without Customer Managed Key";
    VolumeVulnerabilityKind["NO_BACKUPS"] = "Volumes with no backups";
    VolumeVulnerabilityKind["SNAPSHOTS_OLDER_7DAYS"] = "Volumes with stale Snapshots";
    VolumeVulnerabilityKind["NOT_CYBER_SCANNED"] = "Unscanned Volumes";
})(VolumeVulnerabilityKind = exports.VolumeVulnerabilityKind || (exports.VolumeVulnerabilityKind = {}));
/*
 * A Value Object that represents a vulnerability with its `RiskLevel`, number of affected assets
 * and percentage of affected assets.
 */
class Vulnerability {
    _id;
    _riskLevel;
    _numberOfAffectedAssets;
    _percentageOfAffectedAssets;
    constructor({ id, riskLevel, numberOfAffectedAssets, percentageOfAffectedAssets, }) {
        this._id = id;
        this._riskLevel = riskLevel;
        this._numberOfAffectedAssets = numberOfAffectedAssets;
        this._percentageOfAffectedAssets = percentageOfAffectedAssets;
    }
    get id() {
        return this._id;
    }
    get riskLevel() {
        return this._riskLevel;
    }
    get numberOfAffectedAssets() {
        return this._numberOfAffectedAssets;
    }
    get percentageOfAffectedAssets() {
        return this._percentageOfAffectedAssets;
    }
}
exports.Vulnerability = Vulnerability;
