import { GrpcClient } from '@lib/clients/grpc-client'
import { JobsPromiseClient, CloudConnector } from '@lib/clients/grpc-imports'
import {
  ScheduledCcJob,
  ScheduledCcJobAttrs,
  ScheduledCcJobStatus,
  ScheduledCcJobKind,
} from '@features/scheduled-jobs-monitoring/models'
import { ScheduledCcJobsListParams } from '@features/scheduled-jobs-monitoring/use-cases'
import {
  ScheduledCcJobsFilter,
  ScheduledTenantJobFilter,
} from './scheduled-cc-jobs-filter'
import TimeHelper from '@lib/helpers/time.helper'

export class ScheduledCcJobsClient extends GrpcClient<JobsPromiseClient> {
  #jobsPromiseClient: JobsPromiseClient

  constructor(hostName = '') {
    super()
    this.#jobsPromiseClient = this.getClient(hostName)
  }

  async get(jobId: string): Promise<ScheduledCcJob> {
    const request = new ScheduledTenantJobFilter(jobId)

    const response = await this.retryGrpcCall(
      () =>
        this.#jobsPromiseClient.getCloudConnectorJob(
          request.createGrpcRequest(),
          this.metadata()
        ),
      {
        requestName: 'JobsPromiseClient/getCloudConnectorJob',
      }
    )

    const job = response.getJob()
    if (!job) {
      throw new Error('Scheduled tenant job not found')
    }

    return this.#transform(job)
  }

  async list(
    params: ScheduledCcJobsListParams
  ): Promise<Array<ScheduledCcJob>> {
    const request = new ScheduledCcJobsFilter({
      jobIdsList: params.jobIds,
      timeRange: {
        start: Number(params.start),
        end: Number(params.end),
      },
    })

    const response = await this.retryGrpcCall(
      () =>
        this.#jobsPromiseClient.listCloudConnectorJobs(
          request.createGrpcRequest(),
          this.metadata()
        ),
      {
        requestName: 'JobsPromiseClient/listCloudConnectorJobs',
      }
    )

    const jobsList = response.getJobsList()
    return jobsList.map((job) => this.#transform(job))
  }

  protected innerClientTypeId(): string {
    return 'scheduleCcJobsClient'
  }

  protected initClient(hostName: string): JobsPromiseClient {
    return new JobsPromiseClient(hostName, null, null)
  }

  #transform(job: CloudConnector): ScheduledCcJob {
    const attrs: ScheduledCcJobAttrs = {
      id: job.getId(),
      createdAt: TimeHelper.secondsToDate(job.getCreatedAt()?.getSeconds()),
      kind: this.#convertKind(job.getKind()),
      status: this.#convertStatus(job.getStatus()),
      ccId: job.getCcId(),
    }

    return new ScheduledCcJob(attrs)
  }

  #convertStatus(status: CloudConnector.Status): ScheduledCcJobStatus {
    const mapping: Record<CloudConnector.Status, ScheduledCcJobStatus> = {
      [CloudConnector.Status.STATUS_SCHEDULED]: ScheduledCcJobStatus.SCHEDULED,
      [CloudConnector.Status.STATUS_STARTING]: ScheduledCcJobStatus.STARTING,
      [CloudConnector.Status.STATUS_INPROGRESS]:
        ScheduledCcJobStatus.INPROGRESS,
      [CloudConnector.Status.STATUS_FAILED]: ScheduledCcJobStatus.FAILED,
      [CloudConnector.Status.STATUS_SUCCEEDED]: ScheduledCcJobStatus.SUCCEEDED,
      [CloudConnector.Status.STATUS_ABORTED]: ScheduledCcJobStatus.ABORTED,
    }

    return mapping[status]
  }

  #convertKind(kind: CloudConnector.Kind): ScheduledCcJobKind {
    const mapping: Partial<Record<CloudConnector.Kind, ScheduledCcJobKind>> = {
      [CloudConnector.Kind.KIND_ISCAN]: ScheduledCcJobKind.ISCAN,
      [CloudConnector.Kind.KIND_APPLY_RETENTION_POLICY]:
        ScheduledCcJobKind.APPLY_RETENTION_POLICY,
      [CloudConnector.Kind.KIND_DATABASE_MAINTENANCE]:
        ScheduledCcJobKind.DATABASE_MAINTENANCE,
      [CloudConnector.Kind.KIND_AWS_EC2_SCAN]: ScheduledCcJobKind.AWS_EC2_SCAN,
      [CloudConnector.Kind.KIND_AWS_EBS_SCAN]: ScheduledCcJobKind.AWS_EC2_SCAN,
      [CloudConnector.Kind.KIND_AWS_EFS_SCAN]: ScheduledCcJobKind.AWS_EFS_SCAN,
      [CloudConnector.Kind.KIND_AWS_S3_SCAN]: ScheduledCcJobKind.AWS_S3_SCAN,
      [CloudConnector.Kind.KIND_AWS_BACKUP_RP_EC2_SCAN]:
        ScheduledCcJobKind.AWS_BACKUP_RP_EC2_SCAN,
      [CloudConnector.Kind.KIND_AWS_BACKUP_RP_EBS_SCAN]:
        ScheduledCcJobKind.AWS_BACKUP_RP_EBS_SCAN,
      [CloudConnector.Kind.KIND_AWS_BACKUP_RP_EFS_SCAN]:
        ScheduledCcJobKind.AWS_BACKUP_RP_EFS_SCAN,
    }

    return mapping[kind] ?? ScheduledCcJobKind.UNKNOWN
  }
}
