"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListAssetsCoveredWithRetentionPolicy = void 0;
const models_1 = require("../../models");
class ListAssetsCoveredWithRetentionPolicy {
    inventory;
    constructor(inventory) {
        this.inventory = inventory;
    }
    /**
     * execute use-case.
     *
     * @param {string} policyName Retention policy name for getting assets associated with this retention policy
     * @param {string} searchValue search string for filtering assets list
     */
    execute(policyName, searchValue) {
        const assets = this.assetsAssociatedWithRetentionPolicy(policyName);
        return this.getFilteredList(searchValue ?? '', assets);
    }
    assetsAssociatedWithRetentionPolicy(policyName) {
        return [
            ...this.inventory.getInstances(),
            ...this.inventory.getVolumes(),
        ].filter((asset) => asset.getAssociatedRetentionPolicies().includes(policyName));
    }
    matchesIncludeSearchString(values, searchString) {
        if (!searchString) {
            return true;
        }
        if (!values) {
            return false;
        }
        return values.some((value) => value.includes(searchString));
    }
    getFilteredList(searchValue, assetsList) {
        if (!searchValue) {
            return assetsList;
        }
        const searchValueToLower = searchValue.trim().toLocaleLowerCase();
        return assetsList.filter((asset) => {
            //asset ID, asset name, asset account
            const matches = this.matchesIncludeSearchString([
                asset.getParsedCloudProviderAssetId(),
                asset.getName().toLocaleLowerCase(),
                asset.getAccountId(),
            ], searchValueToLower);
            //asset region
            const matchesRegion = asset.getRegion().toLocaleLowerCase().includes(searchValueToLower) ||
                models_1.regionToHumanReadable[asset.getRegion()]
                    .toLocaleLowerCase()
                    .includes(searchValueToLower);
            return matches || matchesRegion;
        });
    }
}
exports.ListAssetsCoveredWithRetentionPolicy = ListAssetsCoveredWithRetentionPolicy;
