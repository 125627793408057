import React, { memo } from 'react'
import SystemHelper from '@lib/helpers/system.helper'
import { EngineCallbackPure } from '@lib/engine-types'
import Link from 'next/link'
import { Icon, IconButton } from '@mui/material'

interface Props {
  text: string
  href?: string
  onDelete?: EngineCallbackPure
  variant?: 'default' | 'success' | 'unstyled' | 'large' | 'title' | 'tableCell'
  showText?: boolean
  className?: string
}

function CopyRow({
  text,
  onDelete,
  href,
  variant = 'default',
  showText = true,
  className = '',
}: Props) {
  return (
    <div className="wrap-1604582489407">
      <div className={`crWrap ${variant}Variant ${className}`}>
        {showText && (
          <>
            {href ? (
              <Link href={href}>
                <a className="blueLink">{text}</a>
              </Link>
            ) : (
              <div aria-label="Copy Text" className="crContent">
                {text}
              </div>
            )}
          </>
        )}
        <IconButton
          onClick={() => {
            SystemHelper.copyToClipboard(text)
          }}
        >
          <Icon>content_copy</Icon>
        </IconButton>
      </div>
      {onDelete && (
        <IconButton
          aria-label="Delete"
          size="small"
          className="crRemoveBtn"
          onClick={onDelete}
        >
          <Icon>delete_outline</Icon>
        </IconButton>
      )}
    </div>
  )
}

export default memo(CopyRow)
