import React, { ReactNode } from 'react'
import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { UserProvider, useUser as useUserNext } from '@auth0/nextjs-auth0'
import { useSSRAccessToken } from './ssr/use-ssr-access-token'
import { useSPAAccessToken } from './spa/use-spa-access-token'
import { Auth0SpaKeys, AuthStrategy } from './types'
import { NextRouter } from 'next/router'
import PagePathConstant from '@lib/constants/page-path.constant'

class Auth0EnvError extends Error {
  constructor() {
    super(
      'Missing Auth0 environment variables: publicAuth0SpaDomain, publicAuth0SpaClientId'
    )
    this.name = 'Auth0EnvError'
  }
}

let authStrategyInstance: AuthStrategy | undefined

interface Auth0SPAStrategyAttrs {
  domain: string
  clientId: string
  cookieDomain: string
}

export class Auth0SPAStrategy implements AuthStrategy {
  #domain: string | undefined

  #clientId: string | undefined

  #cookieDomain: string | undefined

  #router: NextRouter

  constructor(attrs: Auth0SPAStrategyAttrs, router: NextRouter) {
    this.#domain = attrs.domain
    this.#clientId = attrs.clientId
    this.#cookieDomain = attrs.cookieDomain
    this.#router = router

    if (!attrs.domain || !attrs.clientId || !attrs.cookieDomain) {
      throw new Auth0EnvError()
    }
  }

  #redirectCallback = (appState?: AppState): void => {
    const targetUrl = appState?.returnTo || PagePathConstant.DASHBOARD

    this.#router.push(targetUrl)
  }

  wrapApp = (children: ReactNode): JSX.Element => {
    return (
      <Auth0Provider
        domain={this.#domain!}
        clientId={this.#clientId!}
        cookieDomain={this.#cookieDomain!}
        onRedirectCallback={this.#redirectCallback}
        authorizationParams={{
          redirect_uri:
            typeof window !== 'undefined' ? window.location.origin : undefined,
        }}
      >
        {children}
      </Auth0Provider>
    )
  }

  getAccessToken = () => {
    try {
      const { token } = useSPAAccessToken()
      return token
    } catch (error) {
      console.error('Error getting access token:', error)
    }
  }

  getUser = () => {
    try {
      const { user } = useAuth0()
      return user
    } catch (error) {
      console.error('Error getting user:', error)
    }
  }
}

export class NextAuth0Strategy implements AuthStrategy {
  wrapApp = (children: ReactNode): JSX.Element => {
    return <UserProvider>{children}</UserProvider>
  }

  getAccessToken = () => {
    const { token } = useSSRAccessToken()
    return token
  }

  getUser = () => {
    try {
      const { user } = useUserNext()
      return user
    } catch (error) {
      console.error('Error getting user:', error)
    }
  }
}

export const createAuthStrategy = (
  useSPAAuth: boolean,
  spaEnvKeys: Auth0SpaKeys,
  router: NextRouter
): AuthStrategy => {
  if (!authStrategyInstance) {
    authStrategyInstance = useSPAAuth
      ? new Auth0SPAStrategy(spaEnvKeys, router)
      : new NextAuth0Strategy()
  }
  return authStrategyInstance
}
