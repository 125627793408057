import ActionInterface from '@lib/interfaces/action.interface'
import {
  REMOVE_SELECTED_WEBHOOK,
  SET_EVENT_TYPES,
  SET_TEST_WEBHOOK_RESULT,
  SET_WEBHOOK,
  SET_WEBHOOKS_LIST,
  SET_WEBHOOK_INVOCATIONS,
} from '@store/actions/webhook.action'
import WebhookState from '@store/states/webhook.state'

const initialState: WebhookState = {
  webhooksList: [],
  selectedWebhook: undefined,
  eventTypes: [],
  webhooksInvocations: [],
}

function webhookReducer(
  prevState: WebhookState = initialState,
  action: ActionInterface
): WebhookState {
  switch (action.type) {
    case SET_WEBHOOKS_LIST:
      return {
        ...prevState,
        webhooksList: action.payload,
      }
    case SET_WEBHOOK:
      return {
        ...prevState,
        selectedWebhook: action.payload,
      }

    case REMOVE_SELECTED_WEBHOOK:
      return {
        ...prevState,
        selectedWebhook: undefined,
      }

    case SET_EVENT_TYPES:
      return {
        ...prevState,
        eventTypes: action.payload,
      }

    case SET_TEST_WEBHOOK_RESULT:
      return {
        ...prevState,
        webhookTestedResult: action.payload,
      }

    case SET_WEBHOOK_INVOCATIONS:
      return {
        ...prevState,
        webhooksInvocations: action.payload,
      }
    default:
      return prevState
  }
}

export default webhookReducer
