"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListDetailsS3ForAssetItemS3Scan = void 0;
const types_1 = require("../../models/scans/malware/types");
__exportStar(require("./types"), exports);
class ListDetailsS3ForAssetItemS3Scan {
    execute({ scans, threats, }) {
        return this.prepareOutput({ scans, threats });
    }
    prepareOutput({ scans, threats, }) {
        const currentMalwares = [];
        scans.forEach((scan) => {
            if (!scan.malwares)
                return;
            currentMalwares.push(scan.malwares);
        });
        const scansOutput = currentMalwares.map((malware) => {
            const malwareThreats = this.getThreatsNameByScanId(malware.id, threats);
            return {
                isHealthy: malware.status === types_1.MalwareScanStatus.CLEAN,
                malwareStatus: malware.status,
                completeTime: malware.createdAt,
                malwareNames: malwareThreats,
                scanId: malware.id,
                scanMalwareModel: malware,
            };
        });
        return {
            scans: scansOutput,
        };
    }
    getThreatsNameByScanId(scanId, threats) {
        const threatsNames = threats
            .filter((threat) => threat.scanIds.includes(scanId))
            .map((threat) => threat.name);
        return [...new Set(threatsNames)];
    }
}
exports.ListDetailsS3ForAssetItemS3Scan = ListDetailsS3ForAssetItemS3Scan;
