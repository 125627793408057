import React from 'react'

function JobsIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99337 3.33398C6.31337 3.33398 3.33337 6.32065 3.33337 10.0007C3.33337 13.6807 6.31337 16.6673 9.99337 16.6673C13.68 16.6673 16.6667 13.6807 16.6667 10.0007C16.6667 6.32065 13.68 3.33398 9.99337 3.33398ZM10 15.334C7.05337 15.334 4.66671 12.9473 4.66671 10.0007C4.66671 7.05398 7.05337 4.66732 10 4.66732C12.9467 4.66732 15.3334 7.05398 15.3334 10.0007C15.3334 12.9473 12.9467 15.334 10 15.334ZM9.85337 6.66732H9.81337C9.54671 6.66732 9.33337 6.88065 9.33337 7.14732V10.294C9.33337 10.5273 9.45337 10.7473 9.66004 10.8673L12.4267 12.5273C12.6534 12.6607 12.9467 12.594 13.08 12.3673C13.22 12.1407 13.1467 11.8407 12.9134 11.7073L10.3334 10.174V7.14732C10.3334 6.88065 10.12 6.66732 9.85337 6.66732V6.66732Z"
        fill="#999CAA"
      />
    </svg>
  )
}

export default JobsIcon
