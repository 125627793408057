import { Nullable } from '@lib/engine-types'
import ValueInterface from '@lib/interfaces/value.interface'
import ObjHelper from '@lib/helpers/obj.helper'
import StrHelper from '@lib/helpers/str.helper'

type FilterDataType = Array<Array<string | number>>

abstract class EngineHelper {
  public static getStatusClassByType(
    optionType: Nullable<number | string>
  ): string {
    if (!optionType) {
      return ''
    }
    switch (Number(optionType)) {
      case 0:
        return 'ehStatusAll'
      case 1:
        return 'ehStatusReady'
      case 2:
        return 'ehStatusWarning'
      case 3:
        return 'ehStatusError'

      default:
        throw new Error()
    }
  }

  public static filterData(
    data: FilterDataType,
    mask: Array<number>,
    filters: Array<ValueInterface>
  ): FilterDataType {
    const result: FilterDataType = []

    // eslint-disable-next-line no-restricted-syntax
    for (const elem of data) {
      let shouldAdd = true
      mask.forEach((maskValue, index) => {
        if (!maskValue) {
          return
        }
        const filterElem = filters.find((f) => f.index === index)
        if (!filterElem) {
          return
        }
        if (!shouldAdd) {
          return
        }
        switch (maskValue) {
          //-----------------------------------
          // string compare
          case 1:
            if (
              !StrHelper.isSubstring(
                String(elem[index]),
                String(filterElem.value)
              )
            ) {
              shouldAdd = false
            }
            break

          //-----------------------------------
          // time compare
          case 2:
            if (
              // for all
              filterElem.value !== 0 &&
              // for specific interval
              Date.now() - Number(elem[index]) > Number(filterElem.value)
            ) {
              shouldAdd = false
            }
            break

          //-----------------------------------
          // status compare
          case 3:
            if (
              // for all
              filterElem.value !== 0 &&
              // of for specific
              +(elem[index] ?? 0) !== filterElem.value
            ) {
              shouldAdd = false
            }
            break

          default:
            break
        }
      })
      if (shouldAdd) {
        result.push(ObjHelper.cloneDeep(elem))
      }
    }
    return result
  }

  public static calcCurrentPaginationPage(limit: number, offset: number) {
    if (limit > 0 && offset > 0) {
      return Math.ceil(offset / limit) + 1
    }
    return 1
  }

  // helping function in order to write code in easy way without
  // handling conditions in separate lines of code
  public static applyCallbackIf<T>(
    condition: boolean,
    data: T,
    callback: (input: T) => T
  ) {
    if (!condition) {
      return data
    }
    return callback(data)
  }
}

export default EngineHelper
