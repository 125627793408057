import ActionInterface from '@lib/interfaces/action.interface'
import ReportScheduleModel from '@lib/models/report-schedule.model'
import { SET_GENERATED_REPORTS } from '@store/actions/reports.action'
import { ReportScheduleState } from '@store/states/schedule-report.state'

const initialState: ReportScheduleState = {
  reportScheduleList: [],
}

function scheduleReportReducer(
  prevState = initialState,
  action: ActionInterface
): ReportScheduleState {
  switch (action.type) {
    case SET_GENERATED_REPORTS:
      return {
        ...prevState,
        reportScheduleList: action.payload as Array<ReportScheduleModel>,
      }
    default:
      return prevState
  }
}

export default scheduleReportReducer
