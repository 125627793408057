import React from 'react'

function ActivatedIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.28506 5.21592C5.20495 5.12995 5.10835 5.061 5.00102 5.01318C4.89369 4.96535 4.77782 4.93964 4.66033 4.93756C4.54285 4.93549 4.42615 4.9571 4.31719 5.00111C4.20824 5.04512 4.10927 5.11062 4.02618 5.19371C3.94309 5.2768 3.87759 5.37577 3.83358 5.48472C3.78957 5.59368 3.76796 5.71038 3.77003 5.82786C3.77211 5.94535 3.79782 6.06121 3.84565 6.16855C3.89347 6.27588 3.96242 6.37248 4.04839 6.45259L6.38172 8.78592C6.4628 8.86733 6.55917 8.93193 6.66528 8.97601C6.77139 9.02009 6.88516 9.04279 7.00006 9.04279C7.11496 9.04279 7.22873 9.02009 7.33484 8.97601C7.44095 8.93193 7.53731 8.86733 7.61839 8.78592L13.4517 2.95259C13.6063 2.78672 13.6904 2.56733 13.6864 2.34064C13.6824 2.11396 13.5906 1.89768 13.4303 1.73736C13.27 1.57705 13.0537 1.48522 12.827 1.48122C12.6003 1.47722 12.3809 1.56136 12.2151 1.71592L7.00006 6.92975L5.28506 5.21592Z"
        fill="#27AE60"
      />
      <path
        d="M12.9498 7.02431C12.7996 6.99364 12.6449 6.99285 12.4945 7.02198C12.344 7.05112 12.2008 7.10961 12.0729 7.19411C11.9451 7.27862 11.8352 7.38748 11.7494 7.51449C11.6636 7.6415 11.6037 7.78415 11.5731 7.93431C11.3884 8.83854 10.9397 9.66768 10.2838 10.3169C9.62788 10.9662 8.79418 11.4063 7.88811 11.5818C6.98204 11.7573 6.0443 11.6601 5.19343 11.3027C4.34256 10.9452 3.61679 10.3435 3.10787 9.5736C2.59896 8.8037 2.32975 7.90019 2.33429 6.9773C2.33882 6.05441 2.61691 5.15359 3.13337 4.38873C3.64984 3.62387 4.38149 3.02933 5.23583 2.68026C6.09017 2.33119 7.02883 2.24328 7.93313 2.42764C8.23651 2.48953 8.55206 2.42836 8.81034 2.25759C9.06863 2.08682 9.24849 1.82045 9.31038 1.51706C9.37226 1.21368 9.31109 0.898133 9.14032 0.639849C8.96956 0.381564 8.70318 0.201695 8.39979 0.139811C7.93889 0.0471711 7.46991 0.000663799 6.99979 0.000978145C5.67535 0.00124847 4.37816 0.377255 3.25889 1.08533C2.13961 1.7934 1.24418 2.80448 0.676593 4.00114C0.109005 5.1978 -0.107446 6.53093 0.0523786 7.84569C0.212203 9.16045 0.741744 10.4029 1.5795 11.4287C2.41725 12.4545 3.52884 13.2216 4.78517 13.6409C6.04149 14.0602 7.39098 14.1145 8.67691 13.7975C9.96285 13.4804 11.1324 12.805 12.0499 11.8498C12.9673 10.8946 13.5949 9.69866 13.8598 8.40098C13.8905 8.25083 13.8913 8.09611 13.8621 7.94566C13.833 7.79521 13.7745 7.65197 13.69 7.52412C13.6055 7.39628 13.4966 7.28634 13.3696 7.20058C13.2426 7.11482 13.1 7.05492 12.9498 7.02431Z"
        fill="#27AE60"
      />
    </svg>
  )
}

export default ActivatedIcon
