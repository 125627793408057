"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckStatus = exports.StorageClass = exports.RecoveryPointKind = exports.RecoveryPointStatus = void 0;
var RecoveryPointStatus;
(function (RecoveryPointStatus) {
    RecoveryPointStatus[RecoveryPointStatus["COMPLETED"] = 0] = "COMPLETED";
    RecoveryPointStatus[RecoveryPointStatus["PARTIAL"] = 1] = "PARTIAL";
    RecoveryPointStatus[RecoveryPointStatus["DELETING"] = 2] = "DELETING";
    RecoveryPointStatus[RecoveryPointStatus["EXPIRED"] = 3] = "EXPIRED";
    RecoveryPointStatus[RecoveryPointStatus["STOPPED"] = 4] = "STOPPED";
    RecoveryPointStatus[RecoveryPointStatus["DELETED"] = 5] = "DELETED";
})(RecoveryPointStatus = exports.RecoveryPointStatus || (exports.RecoveryPointStatus = {}));
var RecoveryPointKind;
(function (RecoveryPointKind) {
    RecoveryPointKind[RecoveryPointKind["EC2"] = 0] = "EC2";
    RecoveryPointKind[RecoveryPointKind["EBS"] = 1] = "EBS";
    RecoveryPointKind[RecoveryPointKind["S3"] = 2] = "S3";
    RecoveryPointKind[RecoveryPointKind["EFS"] = 3] = "EFS";
})(RecoveryPointKind = exports.RecoveryPointKind || (exports.RecoveryPointKind = {}));
var StorageClass;
(function (StorageClass) {
    StorageClass[StorageClass["CLASS_WARM"] = 0] = "CLASS_WARM";
    StorageClass[StorageClass["CLASS_COLD"] = 1] = "CLASS_COLD";
    StorageClass[StorageClass["CLASS_DELETED"] = 2] = "CLASS_DELETED";
})(StorageClass = exports.StorageClass || (exports.StorageClass = {}));
var CheckStatus;
(function (CheckStatus) {
    CheckStatus[CheckStatus["NOT_APPLICABLE"] = 0] = "NOT_APPLICABLE";
    CheckStatus[CheckStatus["UNCHECKED"] = 1] = "UNCHECKED";
    CheckStatus[CheckStatus["GOOD"] = 2] = "GOOD";
    CheckStatus[CheckStatus["BAD"] = 3] = "BAD";
})(CheckStatus = exports.CheckStatus || (exports.CheckStatus = {}));
