import React, { useMemo } from 'react'
import { AppProps } from 'next/app'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import store from '@store/store'
import theme from '@lib/theme/theme'
import { useFetchLaunchDarklyApiKey } from '@components/context/feature-flags'
import Layout from '@components-complex/layouts/layout'
import { PageMeta } from '@lib/engine-types'
import {
  AuthProvider,
  AuthenticationSPARequired,
  AuthenticationSSRRequired,
} from '@features/authentication'
import { HistoryProvider } from 'components/context/HistoryContext'
import { LoadingScreen } from '@components-simple/loading-screen'
import { useCustomFlags } from '@components-context/feature-flags/use-custom-flags'
import { useFetchTenantSettings } from '@lib/zustand/tenant-settings'
import { LDProvider } from 'launchdarkly-react-client-sdk'
import { getContext } from '@components-context/feature-flags/get-context'
import SystemHelper from '@lib/helpers/system.helper'

import '../styles/styles.scss'
import '../styles/root.css'
import { useRouter } from 'next/router'

interface ConditionalLayoutProps {
  children: React.ReactNode
  pageMeta: PageMeta
  excludedKeywords?: ReadonlyArray<string>
}

const DEFAULT_EXCLUDED_KEYWORDS: ReadonlyArray<string> = ['code']

/**
 * If the user is in the Auth0 authentication flow, specifically when the 'code' query parameter
 * exists, indicating that the user has just authenticated. This avoids redundant API calls.
 */
const useShouldRenderLayout = (
  excludedKeywords: ReadonlyArray<string> = []
): boolean => {
  const router = useRouter()

  return useMemo(() => {
    if (!router.isReady) {
      return true
    }

    const currentPath = router.asPath.toLowerCase()
    const shouldExclude = excludedKeywords.some((keyword) =>
      currentPath.includes(keyword.toLowerCase())
    )

    return !shouldExclude
  }, [router.isReady, router.asPath, excludedKeywords])
}

/**
 * This component is primarily used to skip rendering the layout when the 'code' query
 * parameter exists, which happens during the Auth0 callback after the user has authenticated.
 */
const ConditionalLayout: React.FC<ConditionalLayoutProps> = ({
  children,
  pageMeta,
  excludedKeywords = DEFAULT_EXCLUDED_KEYWORDS,
}) => {
  const shouldRenderLayout = useShouldRenderLayout(excludedKeywords)

  if (!shouldRenderLayout) {
    return null
  }

  return <Layout pageMeta={pageMeta}>{children}</Layout>
}

type AppPropsExtended = AppProps & { Component: { pageMeta: PageMeta } }

const LD_PROVIDER_OPTIONS = {
  bootstrap: 'localStorage',
} as const

function Main({ Component }: AppPropsExtended) {
  const { auth0Spa, isInitialized } = useCustomFlags()
  const pageMeta = Component.pageMeta

  const AuthenticationRequired = auth0Spa
    ? AuthenticationSPARequired
    : AuthenticationSSRRequired

  if (!isInitialized) {
    return <LoadingScreen />
  }

  return (
    <AuthProvider>
      <AuthenticationRequired pageMeta={pageMeta}>
        <HistoryProvider>
          <ThemeProvider theme={theme}>
            <ConditionalLayout pageMeta={pageMeta}>
              <Component />
            </ConditionalLayout>
          </ThemeProvider>
        </HistoryProvider>
      </AuthenticationRequired>
    </AuthProvider>
  )
}

function App(props: AppPropsExtended) {
  const { isLoading, ldApiKey } = useFetchLaunchDarklyApiKey()
  useFetchTenantSettings()

  if (isLoading || !ldApiKey) {
    return <LoadingScreen />
  }

  return (
    <Provider store={store}>
      <LDProvider
        clientSideID={ldApiKey}
        context={getContext(SystemHelper.getCurrentTenantName())}
        options={LD_PROVIDER_OPTIONS}
      >
        <Main {...props} />
      </LDProvider>
    </Provider>
  )
}

export default App
