export interface ModelAttrs {
  id: string
  createdAt: Date
}

export abstract class Model<A extends ModelAttrs> {
  #id: string

  #createdAt: Date

  constructor(attrs: ModelAttrs) {
    this.#id = attrs.id
    this.#createdAt = attrs.createdAt
  }

  get id(): string {
    return this.#id
  }

  get createdAt(): Date {
    return this.#createdAt
  }

  abstract toAttrs(): A
}
