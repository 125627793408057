import { ModelAttrs, Model } from './model'

export enum ScheduledCcJobStatus {
  SCHEDULED = 'scheduled',
  STARTING = 'starting',
  INPROGRESS = 'inprogress',
  FAILED = 'failed',
  SUCCEEDED = 'succeeded',
  ABORTED = 'aborted',
}

export enum ScheduledCcJobKind {
  ISCAN = 'iscan',
  APPLY_RETENTION_POLICY = 'apply_retention_policy',
  DATABASE_MAINTENANCE = 'database_maintenance',
  AWS_EC2_SCAN = 'aws_ec2_scan',
  AWS_EBS_SCAN = 'aws_ebs_scan',
  AWS_EFS_SCAN = 'aws_efs_scan',
  AWS_S3_SCAN = 'aws_s3_scan',
  AWS_BACKUP_RP_EC2_SCAN = 'aws_backup_rp_ec2_scan',
  AWS_BACKUP_RP_EBS_SCAN = 'aws_backup_rp_ebs_scan',
  AWS_BACKUP_RP_EFS_SCAN = 'aws_backup_rp_efs_scan',
  UNKNOWN = 'unknown',
}

export interface ScheduledCcJobAttrs extends ModelAttrs {
  status: ScheduledCcJobStatus
  kind: ScheduledCcJobKind
  ccId: string
}

export class ScheduledCcJob extends Model<ScheduledCcJobAttrs> {
  #status: ScheduledCcJobStatus

  #kind: ScheduledCcJobKind

  #ccId: string

  constructor(attrs: ScheduledCcJobAttrs) {
    super(attrs)
    this.#status = attrs.status
    this.#kind = attrs.kind
    this.#ccId = attrs.ccId
  }

  get kind(): ScheduledCcJobKind {
    return this.#kind
  }

  get status(): ScheduledCcJobStatus {
    return this.#status
  }

  get ccId(): string {
    return this.#ccId
  }

  toAttrs(): ScheduledCcJobAttrs {
    return {
      id: this.id,
      kind: this.kind,
      status: this.status,
      createdAt: this.createdAt,
      ccId: this.ccId,
    }
  }
}
