"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AWSBRecoveryPoint = void 0;
const aws_backup_1 = require("../aws-backup");
const types_1 = require("./types");
const backup_1 = require("../../backup");
class AWSBRecoveryPoint extends aws_backup_1.AwsBackup {
    #arn;
    #fsCheckStatus;
    #malwareCheckStatus;
    #ransomwareCheckStatus;
    #accountId;
    #region;
    #kind;
    #size;
    #encrypted;
    #encryptionKeyArn;
    #parentRecoveryPointArn;
    #sourceBackupVaultArn;
    #vault;
    #calculatedLifecycle;
    #lifecycle;
    #createdBy;
    #storageClass;
    #status;
    #sourceAssetId;
    #correspondingBackup;
    #importJobId;
    #threatIds;
    #scanIds;
    constructor(parameters) {
        const { arn, accountId, region, kind, size, encrypted, encryptionKeyArn, parentRecoveryPointArn, sourceBackupVaultArn, vault, calculatedLifecycle, lifecycle, createdBy, storageClass, status, sourceAssetId, correspondingBackup, importJobId, threatIds, scanIds, malwareCheckStatus, fsCheckStatus, ransomwareCheckStatus, ...backupParameters } = parameters;
        super(backupParameters);
        this.#arn = arn;
        this.#accountId = accountId;
        this.#fsCheckStatus = fsCheckStatus;
        this.#malwareCheckStatus = malwareCheckStatus;
        this.#ransomwareCheckStatus = ransomwareCheckStatus;
        this.#region = region;
        this.#kind = kind;
        this.#size = size;
        this.#encrypted = encrypted;
        this.#encryptionKeyArn = encryptionKeyArn;
        this.#parentRecoveryPointArn = parentRecoveryPointArn;
        this.#sourceBackupVaultArn = sourceBackupVaultArn;
        this.#vault = vault;
        this.#calculatedLifecycle = Object.freeze({ ...calculatedLifecycle });
        this.#lifecycle = lifecycle;
        this.#createdBy = createdBy;
        this.#storageClass = storageClass;
        this.#status = status;
        this.#sourceAssetId = sourceAssetId;
        this.#correspondingBackup = Object.freeze({ ...correspondingBackup });
        this.#importJobId = importJobId;
        this.#threatIds = Object.freeze([...threatIds]);
        this.#scanIds = Object.freeze([...scanIds]);
    }
    get arn() {
        return this.#arn;
    }
    get accountId() {
        return this.#accountId;
    }
    get region() {
        return this.#region;
    }
    get kind() {
        return this.#kind;
    }
    get size() {
        return this.#size;
    }
    get encrypted() {
        return this.#encrypted;
    }
    get encryptionKeyArn() {
        return this.#encryptionKeyArn;
    }
    get parentRecoveryPointArn() {
        return this.#parentRecoveryPointArn;
    }
    get sourceBackupVaultArn() {
        return this.#sourceBackupVaultArn;
    }
    get vault() {
        return this.#vault;
    }
    get calculatedLifecycle() {
        return { ...this.#calculatedLifecycle };
    }
    get lifecycle() {
        return this.#lifecycle;
    }
    get createdBy() {
        return this.#createdBy;
    }
    get storageClass() {
        return this.#storageClass;
    }
    get status() {
        return this.#status;
    }
    get sourceAssetId() {
        return this.#sourceAssetId;
    }
    get correspondingBackup() {
        return { ...this.#correspondingBackup };
    }
    get importJobId() {
        return this.#importJobId;
    }
    get threatIds() {
        return [...this.#threatIds];
    }
    get scanIds() {
        return [...this.#scanIds];
    }
    get fsCheckStatus() {
        return this.#fsCheckStatus;
    }
    get malwareCheckStatus() {
        return this.#malwareCheckStatus;
    }
    get ransomwareCheckStatus() {
        return this.#ransomwareCheckStatus;
    }
    get summaryCheckStatus() {
        return [
            this.fsCheckStatus,
            this.malwareCheckStatus,
            this.ransomwareCheckStatus,
        ].reduce((acc, curr) => {
            if (curr === types_1.CheckStatus.BAD) {
                return backup_1.SummaryCheckStatus.BAD;
            }
            if (curr === types_1.CheckStatus.GOOD &&
                (acc === backup_1.SummaryCheckStatus.GOOD ||
                    acc === backup_1.SummaryCheckStatus.UNCHECKED)) {
                return backup_1.SummaryCheckStatus.GOOD;
            }
            return acc;
        }, backup_1.SummaryCheckStatus.UNCHECKED);
    }
    get ransomwareIsNotDetected() {
        return this.ransomwareCheckStatus === types_1.CheckStatus.GOOD;
    }
    get malwareIsNotDetected() {
        return this.malwareCheckStatus === types_1.CheckStatus.GOOD;
    }
    get filesystemIsNotCorrupted() {
        return this.fsCheckStatus === types_1.CheckStatus.GOOD;
    }
    get ransomwareIsDetected() {
        return this.ransomwareCheckStatus === types_1.CheckStatus.BAD;
    }
    get malwareIsDetected() {
        return this.malwareCheckStatus === types_1.CheckStatus.BAD;
    }
    get filesystemIsCorrupted() {
        return this.fsCheckStatus === types_1.CheckStatus.BAD;
    }
    get ransomwareIsNotChecked() {
        return this.ransomwareCheckStatus === types_1.CheckStatus.UNCHECKED;
    }
    get malwareIsNotChecked() {
        return this.malwareCheckStatus === types_1.CheckStatus.UNCHECKED;
    }
    get filesystemIsNotChecked() {
        return this.fsCheckStatus === types_1.CheckStatus.UNCHECKED;
    }
    get isMountable() {
        return false;
    }
    get isRestorable() {
        return false;
    }
    get isScannable() {
        return true;
    }
    get isDeleted() {
        return this.status === types_1.RecoveryPointStatus.DELETED;
    }
}
exports.AWSBRecoveryPoint = AWSBRecoveryPoint;
