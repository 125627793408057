import DemoModeConstant from '@lib/constants/data/demo/demo-mode.constant'
import ValueInterface from '@lib/interfaces/value.interface'

enum KEYS {
  CREATED_TENANT_NAME = 'CREATED_TENANT_NAME',
  CONFIGURE_VIEW = 'DEMO_CONFIGS_v1',
  GLOBAL_CELL = 'GLOBAL_CELL',
}

abstract class LocalStorageHelper {
  private static saveData(key: KEYS, data: any) {
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, JSON.stringify(data))
    }
  }

  private static loadData(key: KEYS) {
    if (typeof window !== 'undefined') {
      try {
        const data = localStorage.getItem(key)
        if (!data) {
          return null
        }
        return JSON.parse(data)
      } catch (_e) {
        return null
      }
    }
    return null
  }

  public static clearStorage() {
    if (typeof window !== 'undefined') {
      localStorage.clear()
    }
  }

  public static saveCreatedTenantName(value: string) {
    LocalStorageHelper.saveData(KEYS.CREATED_TENANT_NAME, value)
  }

  public static loadCreatedTenantName(): string {
    const result = LocalStorageHelper.loadData(KEYS.CREATED_TENANT_NAME)
    return result ?? ''
  }

  public static getDemoConfigs(): Array<ValueInterface> {
    // get all possible configs
    const result = DemoModeConstant.getAll()

    // fill their values using local storage
    const storageData = LocalStorageHelper.loadData(KEYS.CONFIGURE_VIEW)
    if (storageData) {
      storageData.forEach((data: ValueInterface) => {
        const found = result.find((v) => v.name === data.name)
        if (found) {
          found.value = data.value
        }
      })
    }

    return result
  }

  public static saveDemoConfigs(value: Array<ValueInterface>) {
    LocalStorageHelper.saveData(KEYS.CONFIGURE_VIEW, value)
  }

  public static saveGlobalCellData(data: { expiredAt: number }) {
    LocalStorageHelper.saveData(KEYS.GLOBAL_CELL, data)
  }
}

export default LocalStorageHelper
