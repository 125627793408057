"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Report = void 0;
class Report {
    #id;
    #name;
    #description;
    #reportType;
    #frequency;
    #lastGenerated;
    #nextReport;
    #accounts;
    #emailList;
    #formats;
    constructor(args) {
        this.#id = args.id;
        this.#name = args.name;
        this.#description = args.description;
        this.#reportType = args.reportType;
        this.#frequency = args.frequency;
        this.#lastGenerated = args.lastGenerated;
        this.#nextReport = args.nextReport;
        this.#accounts = args.accounts;
        this.#emailList = args.emailList;
        this.#formats = args.formats;
    }
    get id() {
        return this.#id;
    }
    get name() {
        return this.#name;
    }
    get description() {
        return this.#description;
    }
    get reportType() {
        return this.#reportType;
    }
    get frequency() {
        return this.#frequency;
    }
    get lastGenerated() {
        return this.#lastGenerated;
    }
    get nextReport() {
        return this.#nextReport;
    }
    get accounts() {
        return this.#accounts;
    }
    get emailList() {
        return this.#emailList;
    }
    get formats() {
        return this.#formats;
    }
}
exports.Report = Report;
