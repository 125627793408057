import {
  GrpcCodes,
  notRetryableErrors,
  retryableErrors,
} from './api-errors.constant'
import BaseDataConstant from '@lib/constants/data/base-data.constant'

export interface ServerErrorData {
  code: number
  extraInfo: string
}

class ServerErrorConstantInner extends BaseDataConstant<ServerErrorData> {
  get ERROR_UNDEFINED(): ServerErrorData {
    return {
      code: -1,
      extraInfo: '',
    }
  }

  get DEFAULT_GRPC_ERROR(): ServerErrorData {
    return {
      code: 23,
      extraInfo: 'Unknown gRPC error',
    }
  }

  get ERROR_TIMEOUT(): ServerErrorData {
    return {
      code: 1,
      // if the request deadline is achieved
      extraInfo: 'Timeout expired',
    }
  }

  get ERROR_DURATION(): ServerErrorData {
    return {
      code: 4,
      // if the request deadline is achieved
      extraInfo: 'downstream duration timeout',
    }
  }

  get ERROR_TENANT_DENIED(): ServerErrorData {
    return {
      code: 7,
      extraInfo: 'User tried to enter into tenant, where he is not belong to',
    }
  }

  get ERROR_INTERNAL_HTTP(): ServerErrorData {
    return {
      code: 13,
      extraInfo: 'Failed to start HTTP/2 stream',
    }
  }

  get ERROR_INTERNAL_UNKNOWN(): ServerErrorData {
    return {
      code: 14,
      extraInfo: 'Some unknown error',
    }
  }

  get ERROR_LOGIN(): ServerErrorData {
    return {
      code: 16,
      extraInfo: 'Need re-login: token expired',
    }
  }

  get TOKEN_SETUP_ERROR(): ServerErrorData {
    return {
      code: 33,
      extraInfo: 'Token must be set up',
    }
  }

  get ERROR_PROCESSING_GRPC_CALL(): ServerErrorData {
    return {
      code: 66,
      extraInfo: ' An error occurred while processing the gRPC call',
    }
  }

  get ERROR_NEED_VERIFICATION(): ServerErrorData {
    return {
      code: 900,
      extraInfo: 'User must finish the verification from the email',
    }
  }

  get ERROR_AUTH0_SESSION(): ServerErrorData {
    return {
      code: 901,
      extraInfo: 'Error during getting the Auth0 users session',
    }
  }

  get ERROR_ENDLESS_REQUESTS(): ServerErrorData {
    return {
      code: 902,
      extraInfo:
        'Backend may ignore "deadline", we have a timer for finishing them',
    }
  }

  public isTimeoutError(code: number | string): boolean {
    switch (Number(code)) {
      case this.ERROR_DURATION.code:
      case this.ERROR_TIMEOUT.code:
        return true

      default:
        return false
    }
  }

  public isUnexpectedError(code: number | string): boolean {
    switch (Number(code)) {
      case this.ERROR_TENANT_DENIED.code:
      case this.ERROR_LOGIN.code:
      case this.ERROR_NEED_VERIFICATION.code:
      case this.ERROR_AUTH0_SESSION.code:
        //   case this.ERROR_DURATION.code:
        //   case this.ERROR_TIMEOUT.code:
        return false

      // ERROR_UNDEFINED
      // ERROR_INTERNAL_HTTP
      // ERROR_INTERNAL_UNKNOWN
      // ERROR_ENDLESS_REQUESTS
      default:
        return true
    }
  }

  public isNotRetryebleError(code: GrpcCodes): boolean {
    return notRetryableErrors.includes(code)
  }

  public isRetryebleError(code: GrpcCodes): boolean {
    return retryableErrors.includes(code)
  }
}

const ServerErrorConstant = ServerErrorConstantInner.build(
  ServerErrorConstantInner,
  'ERROR_'
)

export default ServerErrorConstant
