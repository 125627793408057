import { styled, TableCell } from '@mui/material'

export const TableCellStyled = styled(TableCell)`
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  font-weight: var(--font-weight-normal);
  &.MuiTableCell-root {
    border-bottom: none;
  }
`
