"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Asset = void 0;
/**
 * Abstract class representing an Asset.
 * This serves as a base for all asset types.
 */
class Asset {
    #id;
    #createdAt;
    #updatedAt;
    constructor({ id, createdAt, updatedAt }) {
        this.#id = id;
        this.#createdAt = createdAt;
        this.#updatedAt = updatedAt;
    }
    get id() {
        return this.#id;
    }
    get createdAt() {
        return this.#createdAt;
    }
    get updatedAt() {
        return this.#updatedAt;
    }
}
exports.Asset = Asset;
