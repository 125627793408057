import React from 'react'

function OnboardingIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0545 5.04176C15.0545 5.87177 14.3818 6.54446 13.5518 6.54446C12.7218 6.54446 12.0491 5.87177 12.0491 5.04176C12.0491 4.21175 12.7218 3.53906 13.5518 3.53906C14.3818 3.53906 15.0545 4.21175 15.0545 5.04176Z"
        fill="#999CAA"
      />
      <path
        d="M9.19038 12.4717L8.03987 11.5302C7.87199 11.3928 7.73346 11.232 7.62193 11.0547L5.40898 14.8971C5.28219 15.1167 5.27162 15.3761 5.36202 15.6015L4.16573 14.1657C4.0589 14.039 3.86988 14.0213 3.74192 14.127C3.61513 14.2338 3.59752 14.4228 3.70318 14.5508L4.79498 15.861H3.33337V16.4621H7.24039C7.4071 16.4621 7.54093 16.3282 7.54093 16.1615C7.54093 15.9948 7.4071 15.861 7.24039 15.861H6.69097C6.71562 15.8351 6.74262 15.8105 6.76493 15.78L9.19038 12.4717Z"
        fill="#999CAA"
      />
      <path
        d="M11.0406 10.4215L12.2967 8.32831C12.616 7.79532 12.5327 7.11675 12.0924 6.67651L11.4244 6.00734C11.0969 5.6798 10.686 5.44852 10.2376 5.33582L8.87808 4.99654C8.79473 4.97541 8.70903 4.96484 8.6245 4.96484C8.40966 4.96484 8.196 5.03176 8.01168 5.1609L5.79168 6.93948C5.5651 7.12145 5.46414 7.42199 5.53458 7.70375C5.59328 7.93972 5.76585 8.13342 5.9936 8.2203C6.0793 8.25317 6.1697 8.26843 6.25892 8.26843C6.40685 8.26843 6.55477 8.22499 6.68038 8.13812L8.76185 6.71408L9.98984 7.20598L8.2723 9.11487C8.02225 9.39193 7.90133 9.74882 7.92833 10.121C7.95768 10.4943 8.1326 10.8289 8.4214 11.066L10.6391 12.881L9.46155 14.4201C9.28076 14.6572 9.23614 14.9648 9.34063 15.2431C9.44629 15.5213 9.68226 15.7221 9.97458 15.7808C10.0298 15.7913 10.0849 15.7972 10.1401 15.7972C10.3796 15.7972 10.6132 15.6962 10.7764 15.5154L12.5468 13.5631C12.7921 13.2919 12.9142 12.9421 12.8919 12.577C12.8708 12.213 12.7076 11.8808 12.4329 11.6401L11.0406 10.4215Z"
        fill="#999CAA"
      />
      <path
        d="M16.3446 6.27183C16.0218 6.05699 15.5898 6.13448 15.3608 6.44676L14.1199 8.13847L13.0763 7.39299C13.1279 7.81445 13.0434 8.25234 12.8121 8.63741L12.5386 9.09174L13.6222 9.69399C13.8264 9.8067 14.0507 9.86422 14.2772 9.86422C14.404 9.86422 14.5308 9.84661 14.6553 9.81022C15.0039 9.70925 15.2916 9.47798 15.4641 9.16218L16.5712 7.2345C16.7602 6.90461 16.6604 6.48315 16.3446 6.27183Z"
        fill="#999CAA"
      />
    </svg>
  )
}

export default OnboardingIcon
