import React from 'react'

function MalwareIcon({ fill = '#45AA5B', width = '12', height = '12' }) {
  return (
    <svg
      data-testid="malware-icon"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.667 3.33315H9.46033C9.16033 2.81315 8.74699 2.36648 8.24699 2.02648L8.86699 1.40648C9.12699 1.14648 9.12699 0.726484 8.86699 0.466484C8.60699 0.206484 8.18699 0.206484 7.92699 0.466484L6.94699 1.44648C6.64033 1.37315 6.32699 1.33315 6.00033 1.33315C5.67366 1.33315 5.36033 1.37315 5.06033 1.44648L4.07366 0.466484C3.81366 0.206484 3.39366 0.206484 3.13366 0.466484C2.87366 0.726484 2.87366 1.14648 3.13366 1.40648L3.74699 2.02648C3.25366 2.36648 2.84033 2.81315 2.54033 3.33315H1.33366C0.966992 3.33315 0.666992 3.63315 0.666992 3.99982C0.666992 4.36648 0.966992 4.66648 1.33366 4.66648H2.06033C2.02699 4.88648 2.00033 5.10648 2.00033 5.33315V5.99982H1.33366C0.966992 5.99982 0.666992 6.29982 0.666992 6.66648C0.666992 7.03315 0.966992 7.33315 1.33366 7.33315H2.00033V7.99982C2.00033 8.22648 2.02699 8.44648 2.06033 8.66648H1.33366C0.966992 8.66648 0.666992 8.96648 0.666992 9.33315C0.666992 9.69982 0.966992 9.99982 1.33366 9.99982H2.54033C3.23366 11.1932 4.52033 11.9998 6.00033 11.9998C7.48033 11.9998 8.76699 11.1932 9.46033 9.99982H10.667C11.0337 9.99982 11.3337 9.69982 11.3337 9.33315C11.3337 8.96648 11.0337 8.66648 10.667 8.66648H9.94032C9.97366 8.44648 10.0003 8.22648 10.0003 7.99982V7.33315H10.667C11.0337 7.33315 11.3337 7.03315 11.3337 6.66648C11.3337 6.29982 11.0337 5.99982 10.667 5.99982H10.0003V5.33315C10.0003 5.10648 9.97366 4.88648 9.94032 4.66648H10.667C11.0337 4.66648 11.3337 4.36648 11.3337 3.99982C11.3337 3.63315 11.0337 3.33315 10.667 3.33315ZM6.66699 8.66648H5.33366C4.96699 8.66648 4.66699 8.36648 4.66699 7.99982C4.66699 7.63315 4.96699 7.33315 5.33366 7.33315H6.66699C7.03366 7.33315 7.33366 7.63315 7.33366 7.99982C7.33366 8.36648 7.03366 8.66648 6.66699 8.66648ZM6.66699 5.99982H5.33366C4.96699 5.99982 4.66699 5.69982 4.66699 5.33315C4.66699 4.96648 4.96699 4.66648 5.33366 4.66648H6.66699C7.03366 4.66648 7.33366 4.96648 7.33366 5.33315C7.33366 5.69982 7.03366 5.99982 6.66699 5.99982Z"
        fill={fill}
      />
    </svg>
  )
}

export default MalwareIcon
