import TypeHelper from '@lib/helpers/type.helper'

class GrpcRequestError extends Error {
  public name: string

  public code: number

  public constructor(message: string, name?: string, code?: string | number) {
    super(decodeURI(message))

    this.name = TypeHelper.stringValDef(name, '')
    this.code = TypeHelper.numberValDef(code, -1)
  }
}

export default GrpcRequestError
