export const SET_ISCAN_REPORTS_PERIOD = 'SET_ISCAN_REPORTS_PERIOD'
export const SET_ISCAN_REPORTS_POSSIBLE_FILTERS =
  'SET_ISCAN_REPORTS_POSSIBLE_FILTERS'
export const SET_ISCAN_REPORTS_SELECTED_FILTERS =
  'SET_ISCAN_REPORTS_SELECTED_FILTERS'
export const SET_ISCAN_REPORTS_POSSIBLE_RP_FILTERS =
  'SET_ISCAN_REPORTS_POSSIBLE_RP_FILTERS'
export const SET_ISCAN_REPORTS_SELECTED_RP_FILTERS =
  'SET_ISCAN_REPORTS_SELECTED_RP_FILTERS'
export const SET_ISCAN_REPORTS_SELECTED_RP_FILTERS_FOR_API =
  'SET_ISCAN_REPORTS_SELECTED_RP_FILTERS_FOR_API'
