import React from 'react'

function RansomwareIcon({ fill = '#45AA5B', width = '12', height = '16' }) {
  return (
    <svg
      data-testid="ransomware-icon"
      width={width}
      height={height}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 10.9512H7.5C7.7125 10.9512 7.89063 10.8801 8.03438 10.7377C8.17813 10.5954 8.25 10.419 8.25 10.2086V7.98078C8.25 7.77038 8.17813 7.594 8.03438 7.45167C7.89063 7.30933 7.7125 7.23817 7.5 7.23817V6.49555C7.5 6.08711 7.35313 5.73746 7.05938 5.44661C6.76563 5.15575 6.4125 5.01032 6 5.01032C5.5875 5.01032 5.23438 5.15575 4.94063 5.44661C4.64688 5.73746 4.5 6.08711 4.5 6.49555V7.23817C4.2875 7.23817 4.10937 7.30933 3.96562 7.45167C3.82187 7.594 3.75 7.77038 3.75 7.98078V10.2086C3.75 10.419 3.82187 10.5954 3.96562 10.7377C4.10937 10.8801 4.2875 10.9512 4.5 10.9512ZM5.25 7.23817V6.49555C5.25 6.28514 5.32187 6.10877 5.46562 5.96644C5.60937 5.8241 5.7875 5.75294 6 5.75294C6.2125 5.75294 6.39063 5.8241 6.53438 5.96644C6.67813 6.10877 6.75 6.28514 6.75 6.49555V7.23817H5.25ZM6 15.3327C5.9125 15.3327 5.83125 15.3265 5.75625 15.3141C5.68125 15.3017 5.60625 15.2832 5.53125 15.2584C3.84375 14.7015 2.5 13.6711 1.5 12.1673C0.5 10.6635 0 9.0452 0 7.31243V3.80357C0 3.49414 0.090625 3.21566 0.271875 2.96813C0.453125 2.72059 0.6875 2.54112 0.975 2.42973L5.475 0.758843C5.65 0.696958 5.825 0.666016 6 0.666016C6.175 0.666016 6.35 0.696958 6.525 0.758843L11.025 2.42973C11.3125 2.54112 11.5469 2.72059 11.7281 2.96813C11.9094 3.21566 12 3.49414 12 3.80357V7.31243C12 9.0452 11.5 10.6635 10.5 12.1673C9.5 13.6711 8.15625 14.7015 6.46875 15.2584C6.39375 15.2832 6.31875 15.3017 6.24375 15.3141C6.16875 15.3265 6.0875 15.3327 6 15.3327ZM6 13.8474C7.3 13.439 8.375 12.6221 9.225 11.3968C10.075 10.1715 10.5 8.81004 10.5 7.31243V3.80357L6 2.13268L1.5 3.80357V7.31243C1.5 8.81004 1.925 10.1715 2.775 11.3968C3.625 12.6221 4.7 13.439 6 13.8474Z"
        fill={fill}
      />
    </svg>
  )
}

export default RansomwareIcon
