import { Nullable } from '@lib/engine-types'
import {
  BackupTimelineStatsRequest,
  CountsList,
} from '@lib/interfaces/backup-timeline.interface'

export const REQUEST_BACKUP_TIMELINE_STATS = 'REQUEST_BACKUP_TIMELINE_STATS'
export const SET_BACKUP_TIMELINE_STATS = 'SET_BACKUP_TIMELINE_STATS'

export const requestBackupTimelineStats = (
  payload: BackupTimelineStatsRequest
) => ({
  type: REQUEST_BACKUP_TIMELINE_STATS,
  payload,
})

export const setBackupTimelineStats = (
  payload: Record<string, Nullable<CountsList>>
) => ({
  type: SET_BACKUP_TIMELINE_STATS,
  payload,
})
