import { ListS3, Pagination } from '@lib/clients/grpc-imports'
import { AbstractListRequest } from '@lib/clients/list-request'

export class ListS3Pagination extends AbstractListRequest<ListS3> {
  #pageToken: string

  constructor(pageToken: string) {
    super()
    this.#pageToken = pageToken
  }

  createGrpcRequest(): ListS3 {
    const grpcRequest = new ListS3()
    grpcRequest.setPagination(new Pagination().setPageToken(this.#pageToken))

    return grpcRequest
  }
}
