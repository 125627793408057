"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.genericHostFactory = void 0;
const generic_1 = require("../../models/assets/generic");
const factories_1 = require("../../use_cases/tests/factories");
const fishery_1 = require("fishery");
const common_1 = require("./common");
class GenericHostFactory extends fishery_1.Factory {
}
exports.genericHostFactory = GenericHostFactory.define(({ transientParams, sequence }) => {
    return new generic_1.GenericHost({
        id: transientParams.id ?? (0, factories_1.uuid)(),
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
        cpuCoresCount: transientParams.cpuCoresCount ?? Math.floor(Math.random() * 10),
        hostname: transientParams.hostname ?? (0, factories_1.name)('hostname', sequence),
        osName: transientParams.osName ?? (0, factories_1.name)('osName', sequence),
        retentionPolicy: transientParams.retentionPolicy ?? [],
    });
});
