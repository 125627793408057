import { Nullable, VIMatrix, VIRow } from '@lib/engine-types'
import RedStackModel from '@lib/models/red-stack.model'
import ValueInterface from '@lib/interfaces/value.interface'
import RedStackStatusConstant from '@lib/constants/red-stack-status.constant'

export const ACTIVATE_SOURCES = 'ACTIVATE_SOURCES'
export const DEACTIVATE_SOURCES = 'DEACTIVATE_SOURCES'
export const DELETE_SOURCES = 'DELETE_SOURCES'
export const SET_ACCOUNT_ALIAS_NAME = 'SET_ACCOUNT_ALIAS_NAME_SOURCES'
export const SET_ACCOUNT_DESCRIPTION = 'SET_ACCOUNT_DESCRIPTION_SOURCES'
export const MARK_REGIONS_FOR_REMOVED = 'MARK_REGIONS_FOR_REMOVED'
export const MARK_REGIONS_FOR_INSTALLATION = 'MARK_REGIONS_FOR_INSTALLATION'
export const IS_EDIT_SUBMIT_ACTIVE = 'IS_EDIT_SUBMIT_ACTIVE'
export const SUBMIT_SOURCE_ID = 'SUBMIT_SOURCE_ID'
export const INIT_SOURCE_ID = 'INIT_SOURCE_ID'
export const SET_SOURCE_ID_TABLE_DATA = 'SET_SOURCE_ID_TABLE_DATA'
export const RESET_SOURCE_ID_SELECTION = 'RESET_SOURCE_ID_SELECTION'
export const CLEAN_SOURCE_ID_FORM = 'CLEAN_SOURCE_ID_FORM'
export const SET_ACCOUNT_RED_STACK_ID = 'SET_ACCOUNT_RED_STACK_ID'

export const initSourceId = (accountId: string) => ({
  type: INIT_SOURCE_ID,
  payload: accountId,
})

export const activateSources = (
  redStackId: string,
  regionName: string,
  accountId: string
) => ({
  type: ACTIVATE_SOURCES,
  payload: {
    redStackId,
    regionName,
    accountId,
  },
})

export const deactivateSources = (
  redStackId: string,
  regionName: string,
  accountId: string
) => ({
  type: DEACTIVATE_SOURCES,
  payload: {
    redStackId,
    regionName,
    accountId,
  },
})

export const deleteSources = (redStackArr: Array<RedStackModel>) => ({
  type: DELETE_SOURCES,
  payload: redStackArr,
})

export const setAccountAliasName = (accountAlias: string) => ({
  type: SET_ACCOUNT_ALIAS_NAME,
  payload: accountAlias,
})

export const setAccountDescription = (accountDescription: string) => ({
  type: SET_ACCOUNT_DESCRIPTION,
  payload: accountDescription,
})

export const markRegionsForInstallation = (
  redStackId: string,
  accountId: string,
  checkboxValue: boolean,
  selectedOption: Nullable<ValueInterface>,
  region: string,
  label: Nullable<string>,
  defaultValueCheckbox: boolean,
  originalStatus: RedStackStatusConstant,
  selectedSubOptions: Nullable<VIRow>
) => ({
  type: MARK_REGIONS_FOR_INSTALLATION,
  payload: {
    redStackId,
    accountId,
    checkboxValue,
    selectedOption,
    region,
    label,
    defaultValueCheckbox,
    originalStatus,
    selectedSubOptions,
  },
})

export const markRegionsForRemoved = (
  redStackId: string,
  label: Nullable<string>,
  region: string,
  checkboxValue: boolean,
  defaultValueCheckbox: boolean,
  originalStatus: RedStackStatusConstant
) => ({
  type: MARK_REGIONS_FOR_REMOVED,
  payload: {
    redStackId,
    label,
    region,
    checkboxValue,
    defaultValueCheckbox,
    status: originalStatus,
  },
})

export const isEditSubmitActiveAction = () => ({
  type: IS_EDIT_SUBMIT_ACTIVE,
  payload: null,
})

export const submitSourceId = () => ({
  type: SUBMIT_SOURCE_ID,
  payload: null,
})

export const setSourceIdTableData = (tableData: VIMatrix) => ({
  type: SET_SOURCE_ID_TABLE_DATA,
  payload: tableData,
})

export const resetSourceIdSelection = () => ({
  type: RESET_SOURCE_ID_SELECTION,
  payload: null,
})

export const cleanSourceIdForm = () => ({
  type: CLEAN_SOURCE_ID_FORM,
  payload: null,
})

export const setAccountRedStackId = (redStackId: string) => ({
  type: SET_ACCOUNT_RED_STACK_ID,
  payload: redStackId,
})
