import React from 'react'

function NotificationNavIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9847 13.4468L14.1026 12.5648V9.14595C14.1026 7.0468 12.9813 5.28954 11.0257 4.82458V4.35963C11.0257 3.7921 10.5676 3.33398 10.0001 3.33398C9.43256 3.33398 8.97444 3.7921 8.97444 4.35963V4.82458C7.01204 5.28954 5.89751 7.03997 5.89751 9.14595V12.5648L5.01546 13.4468C4.58469 13.8776 4.88555 14.616 5.4941 14.616H14.4992C15.1146 14.616 15.4155 13.8776 14.9847 13.4468ZM12.7351 13.2485H7.26504V9.14595C7.26504 7.45022 8.29751 6.06903 10.0001 6.06903C11.7026 6.06903 12.7351 7.45022 12.7351 9.14595V13.2485ZM10.0001 16.6673C10.7522 16.6673 11.3676 16.0519 11.3676 15.2998H8.63256C8.63256 16.0519 9.2411 16.6673 10.0001 16.6673Z"
        fill="#999CAA"
      />
    </svg>
  )
}

export default NotificationNavIcon
