import { GrpcClient } from '@lib/clients/grpc-client'
import { AssetsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import { S3BucketTransformer } from '@lib/clients/assets/transformers'
// eslint-disable-next-line import/no-extraneous-dependencies
import { S3Bucket } from 'blues-corejs/dist'
import { AbstractListRequest } from '@lib/clients/list-request'
import { ListS3 } from '@lib/clients/grpc-imports'

export class ListS3Client extends GrpcClient<AssetsPromiseClient> {
  #assetsClient: AssetsPromiseClient

  constructor(hostName = '') {
    super()
    this.#assetsClient = this.getClient(hostName)
  }

  protected innerClientTypeId(): string {
    return 'ListS3Client'
  }

  protected initClient(hostName = ''): AssetsPromiseClient {
    return new AssetsPromiseClient(hostName, null, null)
  }

  async list(request: AbstractListRequest<ListS3>): Promise<Array<S3Bucket>> {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#assetsClient.listS3(
            request.createGrpcRequest(),
            this.metadata()
          ),
        {
          requestName: 'AssetsPromiseClient/listS3',
        }
      )
    ).toObject()

    return response.bucketsList.map(
      (item) => new S3BucketTransformer(item).transform
    )
  }
}
