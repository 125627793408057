import ActionInterface from '@lib/interfaces/action.interface'
import {
  SET_VOLUME_VULNERABILITIES_REPORTS,
  SET_INSTANCE_VULNERABILITIES_REPORTS,
  SET_SNAPSHOT_VULNERABILITIES_REPORTS,
} from '@store/actions/reports.action'
import { GeneratedReportsState } from '@store/states/generated-report.state'

const initialState: GeneratedReportsState = {
  volumeVulnerabilitiesGeneratedReports: [],
  instanceVulnerabilitiesGeneratedReports: [],
  snapshotVulnerabilitiesGeneratedReports: [],
}

function generatedReportReducer(
  prevState = initialState,
  action: ActionInterface
): GeneratedReportsState {
  switch (action.type) {
    case SET_VOLUME_VULNERABILITIES_REPORTS:
      return {
        ...prevState,
        volumeVulnerabilitiesGeneratedReports: action.payload,
      }
    case SET_INSTANCE_VULNERABILITIES_REPORTS:
      return {
        ...prevState,
        instanceVulnerabilitiesGeneratedReports: action.payload,
      }
    case SET_SNAPSHOT_VULNERABILITIES_REPORTS:
      return {
        ...prevState,
        snapshotVulnerabilitiesGeneratedReports: action.payload,
      }
    default:
      return prevState
  }
}

export default generatedReportReducer
