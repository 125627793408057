/* eslint-disable import/no-extraneous-dependencies */
import { RexClient } from '@lib/clients'
import { SystemHelper } from '@lib/helpers'
import { CloudConnector } from 'blues-corejs/dist'
import { useEffect, useState } from 'react'

const rexClient = new RexClient()

export function useGetCloudConnectorList() {
  const [cloudConnectorList, setCloudConnectorList] = useState<
    Array<CloudConnector>
  >([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchCloudConnectorList() {
      try {
        setIsLoading(true)

        setCloudConnectorList(await rexClient.listGetAllRedstacks())
      } catch (error: any) {
        if ('message' in error) {
          SystemHelper.sendSentryIfProd(error)
        }
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchCloudConnectorList()

    return () => {
      setIsLoading(false)
      setCloudConnectorList([])
    }
  }, [])

  return {
    cloudConnectorList,
    isLoading,
  }
}
