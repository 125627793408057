/* eslint-disable import/no-extraneous-dependencies */
import { Job as ProtobufJob } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/jobs/tenant/job_pb'
import { JobTenant } from 'blues-corejs/dist'
import TimeHelper from '@lib/helpers/time.helper'
import { TenantJobKindTransformer } from './tenant-job-kind'
import { TenantJobStatusTransformer } from './tenant-job-status'

type JobPt = ProtobufJob.AsObject

export class TenantJobTransformer {
  #job: JobPt

  constructor(job: JobPt) {
    this.#job = job
  }

  transform(): JobTenant {
    return this.#transformJob(this.#job)
  }

  #transformJob(job: JobPt): JobTenant {
    return new JobTenant({
      id: job.id,
      ccId: job.ccId,
      name: job.name,
      statusMessage: job.statusMessage,
      parentId: job.parentId,
      status: new TenantJobStatusTransformer(job.status).transform(),
      kind: new TenantJobKindTransformer(job.kind).transform(),
      updatedAt: TimeHelper.secondsToDate(job.updatedAt?.seconds),
      createdAt: TimeHelper.secondsToDate(job.createdAt?.seconds),
      finishedAt:
        job.finishedAt && TimeHelper.secondsToDate(job.finishedAt?.seconds),
      startedAt: TimeHelper.secondsToDate(job.startedAt?.seconds),
      childrenList: job.childrenList.map((child) => this.#transformJob(child)),
    })
  }
}
