import { ModelAttrs, Model } from './model'

export enum ScheduledTenantJobStatus {
  SCHEDULED = 'scheduled',
  STARTING = 'starting',
  INPROGRESS = 'inprogress',
  FAILED = 'failed',
  SUCCEEDED = 'succeeded',
  ABORTED = 'aborted',
}

export enum ScheduledTenantJobKind {
  REPAIR_VAULT = 'repair_vault',
  CREATE_VAULT = 'create_vault',
  INSTALL_CLOUD_CONNECTOR = 'install_cloud_connector',
  REPAIR_CLOUD_CONNECTOR = 'repair_cloud_connector',
  ACTIVATE_CLOUD_CONNECTOR = 'activate_cloud_connector',
  ENABLE_ELASTIO_REPORTS_GATHERING = 'enable_elastio_reports_gathering',
  UPDATE_VAULT_SUBNETS = 'update_vault_subnets',
  UNKNOWN = 'unknown',
}

export interface ScheduledTenantJobAttrs extends ModelAttrs {
  status: ScheduledTenantJobStatus
  kind: ScheduledTenantJobKind
  ccId: string
}

export class ScheduledTenantJob extends Model<ScheduledTenantJobAttrs> {
  #status: ScheduledTenantJobStatus

  #kind: ScheduledTenantJobKind

  #ccId: string

  constructor(attrs: ScheduledTenantJobAttrs) {
    super(attrs)
    this.#status = attrs.status
    this.#kind = attrs.kind
    this.#ccId = attrs.ccId
  }

  get kind(): ScheduledTenantJobKind {
    return this.#kind
  }

  get status(): ScheduledTenantJobStatus {
    return this.#status
  }

  get ccId(): string {
    return this.#ccId
  }

  toAttrs(): ScheduledTenantJobAttrs {
    return {
      id: this.id,
      kind: this.kind,
      status: this.status,
      createdAt: this.createdAt,
      ccId: this.ccId,
    }
  }
}
