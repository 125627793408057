import {
  TimeRangeAction,
  TimeRangeState,
} from '@components-context/time-range/types'
import {
  RESET_TIME_RANGE,
  SET_TIME_RANGE,
} from '@components-context/time-range/consts'

const INITIAL_TIME_RANGE_VALUE = 0
function initialTimeRangeState(): TimeRangeState {
  return {
    timeRange: {
      start: INITIAL_TIME_RANGE_VALUE,
      end: INITIAL_TIME_RANGE_VALUE,
    },
  }
}
export const INITIAL_STATE = initialTimeRangeState()

export function timeRangeReducer(
  state: TimeRangeState,
  action: TimeRangeAction
): TimeRangeState {
  switch (action.type) {
    case SET_TIME_RANGE:
      return {
        ...state,
        timeRange: action.newTimeRange,
      }
    case RESET_TIME_RANGE:
      return initialTimeRangeState()
    default:
      const exhaustiveCheck: never = action
      throw new Error(`Unhandled action type: ${exhaustiveCheck}`)
  }
}
