import { AssetKind } from '@lib/constants/grpc/asset-kind.constant'
import { SelectCategory } from '@lib/constants/retention-policy/select-category.constant'
import { VIRow } from '@lib/engine-types'
import VaultFactory from '@lib/factories/vault.factory'
import DataHelper from '@lib/helpers/data.helper'
import ObjHelper from '@lib/helpers/obj.helper'
import ValueInterface from '@lib/interfaces/value.interface'
import RedStackModel from '@lib/models/red-stack.model'
import RetentionPolicyModel from '@lib/models/retention-policy.model'
import VaultMetricsModel from '@lib/models/vault-metrics.model'
import VaultModel from '@lib/models/vault.model'
import GrpcPechkinService from '@lib/services/grpc/grpc-pechkin.service'
import GrpcRexService from '@lib/services/grpc/grpc-rex.service'
import GrpcVaultService from '@lib/services/grpc/grpc-vault.service'
import { defaultSelectedFilters } from '@store/reducers/assets-select.reducer'
import GrpcRetentionPoliciesService from '../grpc-retention-policies.service'
import { SelectedS3AssetComplex } from '@lib/interfaces/assets-s3-select.interface'
import { EBS, EC2, EFS, S3Bucket } from 'blues-corejs/dist'

import {
  ListAssetsForPolicyCoverage as ListAssetsForPolicies,
  ListAssetsForPolicyCoverageAttrs,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage'
import { SelectedEfsAssetComplex } from '@lib/interfaces/assets-efs-select.interface'
import {
  Asset,
  AssetsForPolicyCoverage,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { ListLiveAssetsClient } from '@lib/clients/assets/list-live-assets'
import { LiveAssets } from '@lib/clients/assets/types'
import { ListEc2Client, ListEc2Filter } from '@lib/clients/assets/list-ec2'
import { ListEbsClient } from '@lib/clients/assets/ebs-client'
import { ListEfsClient } from '@lib/clients/assets/list-efs/list-efs'
import { ListS3Client } from '@lib/clients/assets/list-s3/list-s3'
import { ListEbsFilter } from '@lib/clients/assets/list-ebs'
import { ListEfsFilter, ListS3Filter } from '@lib/clients/assets'
import { PolicyInfoWithSelectedAssets } from './types'

class GrpcMixedService {
  public static async getVaultMetrics(
    vaultId: string
  ): Promise<VaultMetricsModel> {
    const vault = await GrpcVaultService.getVault(vaultId)

    return VaultFactory.buildVaultMetrics(vault)
  }

  public static async assetsLiveWithCanBeProtectedInventoryAttrs(): Promise<ListAssetsForPolicyCoverageAttrs> {
    const liveAssetsClient = new ListLiveAssetsClient()
    const liveAssets: LiveAssets = await liveAssetsClient.list()
    const elastioBackups = liveAssets.lastElastioBackupsMap
    return {
      instances: liveAssets.ec2Instances,
      volumes: liveAssets.ebsVolumes,
      s3Buckets: liveAssets.s3Buckets,
      efs: liveAssets.efs,
      lastBackups: elastioBackups,
    }
  }

  public static async assetsAllWithCanBeProtectedInventoryAttrs(
    token: string
  ): Promise<{
    allAssets: ListAssetsForPolicyCoverageAttrs
    missingInLiveAssets: Array<string>
  }> {
    const liveAssetsClient = new ListLiveAssetsClient()
    const liveAssets: LiveAssets = await liveAssetsClient.list()
    const liveAssetsList = [
      ...liveAssets.ec2Instances,
      ...liveAssets.ebsVolumes,
      ...liveAssets.s3Buckets,
      ...liveAssets.efs,
    ]

    const elastioBackups = liveAssets.lastElastioBackupsMap
    // FIXME: Do we really need to fetch 1000 assets?
    // all EC2 assets
    const listEc2Client = new ListEc2Client(token)
    const listEc2: Array<EC2> = await listEc2Client.list(
      new ListEc2Filter({
        pageSize: 1000,
      })
    )
    // all EBS assets
    const listEbsClient = new ListEbsClient()
    const listEbs: Array<EBS> = await listEbsClient.list(
      new ListEbsFilter({ pageSize: 1000 })
    )
    // all S3 assets
    const listS3Client = new ListS3Client()
    const listS3: Array<S3Bucket> = await listS3Client.list(
      new ListS3Filter({
        pageSize: 1000,
      })
    )
    // all EFS assets
    const listEfsClient = new ListEfsClient()
    const listEfs: Array<EFS> = await listEfsClient.list(
      new ListEfsFilter({
        pageSize: 1000,
      })
    )

    const missingInLiveAssets: Array<string> = []
    const allAssetsList = [...listEc2, ...listEbs, ...listS3, ...listEfs]
    allAssetsList.map((asset) => {
      const foundAsset = liveAssetsList.find(
        (liveAsset) => liveAsset.id === asset.id
      )
      if (!foundAsset) {
        missingInLiveAssets.push(asset.id)
      }
    })

    return {
      allAssets: {
        instances: listEc2,
        volumes: listEbs,
        s3Buckets: listS3,
        efs: listEfs,
        lastBackups: elastioBackups,
      },
      missingInLiveAssets: missingInLiveAssets,
    }
  }

  public static async getPlanInfoWithSelectedAssets(
    id: string
  ): Promise<PolicyInfoWithSelectedAssets> {
    const policyInfo = await GrpcPechkinService.getPlanInfo(id)

    // because filters work in "AND" way between groups
    // and "OR" in one group - for each group we have to do
    // a separate request
    const inventoryAttrs =
      await GrpcMixedService.assetsLiveWithCanBeProtectedInventoryAttrs()
    const inventory = new ListAssetsForPolicies(inventoryAttrs)

    let ec2Assets: AssetsForPolicyCoverage = []
    if (policyInfo.ec2Filters.length > 0) {
      ec2Assets = inventory.execute({ instanceIds: policyInfo.ec2Filters })
    }

    let ebsAssets: AssetsForPolicyCoverage = []
    if (policyInfo.ebsFilters.length > 0) {
      ebsAssets = inventory.execute({ volumeIds: policyInfo.ebsFilters })
    }

    let s3Assets: AssetsForPolicyCoverage = []
    if (policyInfo.s3Filters?.length > 0) {
      s3Assets = inventory.execute({ instanceIds: policyInfo.s3Filters })
    }

    s3Assets?.map((s3: AssetWithRelatedAssets<Asset>) => {
      policyInfo.selectedS3AssetComplex.map(
        (s3SelectedAsset: SelectedS3AssetComplex) => {
          s3SelectedAsset.selectedAsset === s3.asset?.awsId
            ? (s3SelectedAsset.assetModel = s3)
            : null
        }
      )
    })

    let efsAssets: AssetsForPolicyCoverage = []
    if (policyInfo.efsFilters?.length > 0) {
      efsAssets = inventory.execute({ instanceIds: policyInfo.efsFilters })
    }

    efsAssets?.map((efs: AssetWithRelatedAssets<Asset>) => {
      policyInfo.selectedEfsAssetComplex.map(
        (efsSelectedAsset: SelectedEfsAssetComplex) => {
          efsSelectedAsset.selectedAsset === efs.asset?.awsId
            ? (efsSelectedAsset.assetModel = efs)
            : null
        }
      )
    })

    return {
      policyName: policyInfo.policyName,
      schedule: policyInfo.schedule,
      iscan: policyInfo.iscan,
      policyTags: policyInfo.policyTags,
      snapshotImportVariant: policyInfo.snapshotImport,
      isType: policyInfo.isType,
      integrityCheck: policyInfo.integrityCheck,
      protectNewImmediately: policyInfo.protectNewImmediately,
      vaultsList: policyInfo.vaultsList ?? [],
      skipEbsBackup: policyInfo.skipEbsBackup ?? false,
      accountRegionSelectorsList: policyInfo.accountRegionSelectorsList ?? [],
      // added extra
      selectedAssets: [...ec2Assets, ...ebsAssets],
      selectedS3Assets: s3Assets,
      selectedS3AssetComplex: policyInfo.selectedS3AssetComplex,
      selectedEfsAssets: efsAssets,
      selectedEfsAssetComplex: policyInfo.selectedEfsAssetComplex,
      integrityScanOption: policyInfo.integrityScanOption,
      keepDataCopy: policyInfo.keepDataCopy,
      scanWithTimestamps: policyInfo.scanWithTimestamps,
      isEntropyDetectionEnabled: policyInfo.isEntropyDetectionEnabled,
    }
  }

  public static async listVaultsForActiveRedStacks(
    accountId?: string | number
  ): Promise<Array<VaultModel>> {
    const [vaults, redStacks]: [Array<VaultModel>, Array<RedStackModel>] =
      await Promise.all([
        GrpcVaultService.listVaults(accountId),
        GrpcRexService.getAllActiveRedStacks(),
      ])
    return vaults.filter((v) =>
      redStacks.some((rs) => rs.redStackId === v.redStackId)
    )
  }

  public static async listVaultsForAllRedStacks(
    accountId?: string | number
  ): Promise<Array<VaultModel>> {
    const [vaults, redStacks]: [Array<VaultModel>, Array<RedStackModel>] =
      await Promise.all([
        GrpcVaultService.listVaults(accountId),
        GrpcRexService.getAllRedstacks(),
      ])
    return vaults.filter((v) =>
      redStacks.some((rs) => rs.redStackId === v.redStackId)
    )
  }

  public static async getRetentionPolicyWithSelectedAssets(
    id: string
  ): Promise<{
    retentionPolicy: RetentionPolicyModel
    selectedAssets: Array<AssetWithRelatedAssets<Asset>>
    missingAssets: VIRow
    selectedAssetsFilters: VIRow
  }> {
    const retentionPolicy =
      await GrpcRetentionPoliciesService.getRetentionPolicy(id)
    const vaultsList = await GrpcMixedService.listVaultsForAllRedStacks()

    const filters = retentionPolicy.dataForSelectedAssets || []
    const recoveryPointTypes = retentionPolicy.dataForRecoveryPointTypes || []

    const ebsFilters: Array<string> = []
    const ec2Filters: Array<string> = []
    const s3Filters: Array<string> = []
    const genericFilters: Array<string> = []

    filters.map((filter: ValueInterface) => {
      if (filter.type === AssetKind.AWS_EBS) {
        ebsFilters.push(filter.name)
      }
      if (filter.type === AssetKind.AWS_EC2) {
        ec2Filters.push(filter.name)
      }
      if (filter.type === AssetKind.AWS_S3) {
        s3Filters.push(filter.name)
      }
      if (
        filter.type === AssetKind.GENERIC_HOST ||
        filter.type === AssetKind.GENERIC_FS
      ) {
        genericFilters.push(filter.name)
      }
    })

    // because filters work in "AND" way between groups
    // and "OR" in one group - for each group we have to do
    // a separate request
    const inventoryAttrs =
      await GrpcMixedService.assetsLiveWithCanBeProtectedInventoryAttrs()
    const inventory = new ListAssetsForPolicies(inventoryAttrs)

    let ec2Assets: AssetsForPolicyCoverage = []
    if (ec2Filters.length > 0) {
      ec2Assets = inventory.execute({ instanceIds: ec2Filters })
    }

    let ebsAssets: AssetsForPolicyCoverage = []
    if (ebsFilters.length > 0) {
      ebsAssets = inventory.execute({ volumeIds: ebsFilters })
    }

    let s3Assets: AssetsForPolicyCoverage = []
    if (s3Filters?.length > 0) {
      s3Assets = inventory.execute({ instanceIds: s3Filters })
    }

    let genericAssets: AssetsForPolicyCoverage = []
    if (genericFilters.length > 0) {
      genericAssets = inventory.execute({ instanceIds: genericFilters })
    }

    // Find asset names which are selected in Retention policy
    // but already absent in Assets list
    const selectedEc2AssetsNames = ec2Assets.map((asset) => asset.asset.awsId)
    const selectedEbsAssetsNames = ebsAssets.map((asset) => asset.asset.awsId)
    const selectedS3AssetsNames = s3Assets.map((asset) => asset.asset.awsId)

    //TODO check selection of Generic assets (have no awsId)
    const selectedGenericAssetsNames = genericAssets.map(
      (asset) => asset.asset.id
    )

    const missingAssets: VIRow = []

    filters.map((filter: ValueInterface) => {
      if (filter.type === AssetKind.AWS_EC2) {
        if (!selectedEc2AssetsNames.includes(filter.name)) {
          missingAssets.push(filter)
        }
      }
      if (filter.type === AssetKind.AWS_EBS) {
        if (!selectedEbsAssetsNames.includes(filter.name)) {
          missingAssets.push(filter)
        }
      }
      if (filter.type === AssetKind.AWS_S3) {
        if (!selectedS3AssetsNames.includes(filter.name)) {
          missingAssets.push(filter)
        }
      }
      if (
        filter.type === AssetKind.GENERIC_HOST ||
        filter.type === AssetKind.GENERIC_FS
      ) {
        if (!selectedGenericAssetsNames.includes(filter.name)) {
          missingAssets.push(filter)
        }
      }
    })

    const selectedAssets = [
      ...ec2Assets,
      ...ebsAssets,
      ...s3Assets,
      ...genericAssets,
    ]

    // get preselected filters for Assets
    let selectedAssetsFilters: VIRow = defaultSelectedFilters()
    recoveryPointTypes.map((item) => {
      if (
        item.name === SelectCategory.ACCOUNT_IDS ||
        item.name === SelectCategory.REGIONS ||
        item.name === SelectCategory.TAGS
      ) {
        const currentFilter = DataHelper.getSelectedAssetsFiltersRow(
          item.name,
          item.options ?? []
        )
        const currentAllFilters = ObjHelper.cloneDeep(selectedAssetsFilters)
        selectedAssetsFilters = [...currentAllFilters, ...currentFilter]
      } else if (item.name === SelectCategory.VAULTS) {
        const itemOptions = ObjHelper.cloneDeep(item.options)
        itemOptions?.map((option) => {
          const vaultFromList = vaultsList.find(
            (v) =>
              v.name === String(option.value) &&
              v.region === String(option.extraValue) &&
              v.accountId === String(option.defaultValue)
          )
          // get vaultId for using in filters
          if (vaultFromList) {
            option.supplementalValue = vaultFromList.innerId
          }
        })

        const currentFilter = DataHelper.getSelectedAssetsFiltersRow(
          item.name,
          itemOptions ?? []
        )
        const currentAllFilters = ObjHelper.cloneDeep(selectedAssetsFilters)
        selectedAssetsFilters = [...currentAllFilters, ...currentFilter]
      }
    })

    return {
      retentionPolicy,
      selectedAssets,
      missingAssets,
      selectedAssetsFilters,
    }
  }
}

export default GrpcMixedService
