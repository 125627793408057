export enum SelectCategory {
  ACCOUNT_IDS = 'Account IDs',
  REGIONS = 'Regions',
  VAULTS = 'Vaults',
  ASSET_SOURCES = 'Asset Sources',
  BACKUP_TYPES = 'Backup Types',
  TAGS = 'Tags',
  ASSETS = 'Assets',
  ALL = 'All',
}
