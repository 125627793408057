"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EBSTypeString = exports.AssetKind = void 0;
var AssetKind;
(function (AssetKind) {
    AssetKind[AssetKind["UNDEFINED"] = -1] = "UNDEFINED";
    AssetKind[AssetKind["AWS_EC2"] = 0] = "AWS_EC2";
    AssetKind[AssetKind["AZURE_VM"] = 1] = "AZURE_VM";
    AssetKind[AssetKind["VM_WARE_VM"] = 2] = "VM_WARE_VM";
    AssetKind[AssetKind["AWS_EBS"] = 3] = "AWS_EBS";
    AssetKind[AssetKind["AWS_EFS"] = 4] = "AWS_EFS";
    AssetKind[AssetKind["GENERIC_HOST"] = 5] = "GENERIC_HOST";
    AssetKind[AssetKind["GENERIC_FS"] = 6] = "GENERIC_FS";
    AssetKind[AssetKind["AWS_S3"] = 7] = "AWS_S3";
    AssetKind[AssetKind["AWS_S3_OBJECTS"] = 8] = "AWS_S3_OBJECTS";
})(AssetKind = exports.AssetKind || (exports.AssetKind = {}));
var EBSTypeString;
(function (EBSTypeString) {
    EBSTypeString["STANDARD"] = "standard";
    EBSTypeString["GP2"] = "gp2";
    EBSTypeString["GP3"] = "gp3";
    EBSTypeString["IO1"] = "io1";
    EBSTypeString["IO2"] = "io2";
    EBSTypeString["SC1"] = "sc1";
    EBSTypeString["ST1"] = "st1";
})(EBSTypeString = exports.EBSTypeString || (exports.EBSTypeString = {}));
