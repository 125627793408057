import ScheduleInterface from '@lib/interfaces/schedule.interface'
import IntegrityScanInterface from '@lib/interfaces/integrity-scan.interface'
import { VIRow } from '@lib/engine-types'
import { SelectedVariant } from '@lib/constants/app.constant'
import {
  ProtectNewImmediately,
  SelectedCloudConnectorVariant,
  SnapshotImport,
} from '@lib/constants/policies.constant'
import { IntegrityCheckOptions } from '@lib/constants/integrity-check-options.constant'
import { VaultForPolicy } from '@lib/interfaces/vault/vaults-policy'
import { KeepDataCopyInterface } from '@lib/interfaces/keep-data-copy.interface'
import { IntegrityScanOptions } from '@lib/constants/integrity-scan-options.constant'
import ScanWithTimestampsInterface from '@lib/interfaces/scan-with-timestamps.interface'
import { StoreReturnType } from '@store/store'

export const getEditPolicyName = (state: StoreReturnType): string =>
  state.editPolicyState.policyName

export const getEditPolicySchedule = (
  state: StoreReturnType
): ScheduleInterface => state.editPolicyState.schedule

export const getEditIntegrityScan = (
  state: StoreReturnType
): IntegrityScanInterface => state.editPolicyState.integrityScan

export const getIntegrityScanOption = (
  state: StoreReturnType
): IntegrityScanOptions => state.editPolicyState.integrityScanOption

export const getKeepDataCopy = (
  state: StoreReturnType
): KeepDataCopyInterface => state.editPolicyState.keepDataCopy

export const getEditPolicyTags = (state: StoreReturnType): VIRow =>
  state.editPolicyState.policyTags

export const getEditIntegrityCheck = (
  state: StoreReturnType
): IntegrityCheckOptions => state.editPolicyState.integrityCheck

export const getEditSelectedVariant = (
  state: StoreReturnType
): SelectedVariant => state.editPolicyState.selectedVariant

export const getEditSnapshotImportVariant = (
  state: StoreReturnType
): SnapshotImport => state.editPolicyState.snapshotImportVariant

export const getEditProtectNewImmediately = (
  state: StoreReturnType
): ProtectNewImmediately => state.editPolicyState.protectNewImmediately

export const getEditSkipEbsBackup = (state: StoreReturnType): boolean =>
  state.editPolicyState.skipEbsBackup

export const getEditPolicyVaultsList = (
  state: StoreReturnType
): Array<VaultForPolicy> => state.editPolicyState.selectedVaultsListEditPolicy

export const getEditPolicySelectedCloudConnectorVariant = (
  state: StoreReturnType
): SelectedCloudConnectorVariant =>
  state.editPolicyState.selectedCloudConnectorVariant

export const getEditPolicySelectedCloudConnectors = (
  state: StoreReturnType
): VIRow => state.editPolicyState.selectedCloudConnectors

export const getEditScanWithTimestamps = (
  state: StoreReturnType
): ScanWithTimestampsInterface => state.editPolicyState.scanWithTimestamps

export const getIsEntropyDetectionEnabled = (state: StoreReturnType): boolean =>
  state.editPolicyState.isEntropyDetectionEnabled
