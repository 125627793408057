/* eslint-disable import/no-extraneous-dependencies */
import { VIMatrix, VIRow } from '@lib/engine-types'
import PaginationModel from '@lib/models/pagination.model'
import {
  AllS3SelectedAssets,
  SelectSpecificPathsInterface,
} from '@lib/interfaces/assets-s3-select.interface'
import { TabAssetType } from '@lib/constants/app.constant'
import { AllEfsSelectedAssets } from '@lib/interfaces/assets-efs-select.interface'

import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { ListAssetsForPolicyCoverageAttrs } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage'
import { AssetLoadingMarker } from '@store/reducers/assets-select.reducer'

export const REQUEST_ASSETS_SELECT = 'REQUEST_ASSETS_SELECT'
export const REQUEST_ALL_ASSETS_SELECT = 'REQUEST_ALL_ASSETS_SELECT'
export const SET_ASSETS_SELECT = 'SET_ASSETS_SELECT'
export const REQUEST_ASSETS_SELECT_ALL_FILTERS =
  'REQUEST_ASSETS_SELECT_ALL_FILTERS'
export const SET_ASSETS_SELECT_FILTERS = 'SET_ASSETS_SELECT_FILTERS'
export const SET_ASSETS_SELECT_PAGINATION = 'SET_ASSETS_SELECT_PAGINATION'
export const SET_ASSETS_SELECT_SEARCH = 'SET_ASSETS_SELECT_SEARCH'
export const ADD_SELECTED_ASSET = 'ADD_SELECTED_ASSET'
export const REMOVE_SELECTED_ASSET = 'REMOVE_SELECTED_ASSET'
export const SET_ALL_SELECTED_ASSETS = 'SET_ALL_SELECTED_ASSETS'
export const SELECT_ALL_EBS_ASSETS = 'SELECT_ALL_EBS_ASSETS'
export const SELECT_ALL_EC2_ASSETS = 'SELECT_ALL_EC2_ASSETS'
export const SELECT_ALL_S3_ASSETS = 'SELECT_ALL_S3_ASSETS'
export const SELECT_ALL_OTHER_ASSETS = 'SELECT_ALL_OTHER_ASSETS'
export const REQUEST_ASSETS_SELECT_ALL_FILTERS_V3 =
  'REQUEST_ASSETS_SELECT_ALL_FILTERS_V3'
export const SET_MISSING_SELECTED_ASSETS = 'SET_MISSING_SELECTED_ASSETS'
export const SET_ASSETS_SELECT_ENABLED_TABS = 'SET_ASSETS_SELECT_ENABLED_TABS'
export const SET_ASSETS_SELECT_TAB_SELECTED_NUM =
  'SET_ASSETS_SELECT_TAB_SELECTED_NUM'
export const SET_EDIT_MAIN_TAB_ASSETS = 'SET_EDIT_MAIN_TAB_ASSETS'

export const SET_S3_ASSETS_SELECT_ALL_FILTERS =
  'SET_S3_ASSETS_SELECT_ALL_FILTERS'
export const SET_S3_ASSETS_SELECT_FILTERS = 'SET_S3_ASSETS_SELECT_FILTERS'
export const SET_S3_ASSETS_SELECT_PAGINATION = 'SET_S3_ASSETS_SELECT_PAGINATION'
export const SET_S3_ASSETS_SELECT_SEARCH = 'SET_S3_ASSETS_SELECT_SEARCH'
export const ADD_S3_SELECTED_ASSET = 'ADD_S3_SELECTED_ASSET'
export const REMOVE_S3_SELECTED_ASSET = 'REMOVE_S3_SELECTED_ASSET'
export const ADD_S3_SELECTED_ALL_PATHS = 'ADD_S3_SELECTED_ALL_PATHS'
export const REMOVE_S3_SELECTED_ALL_PATHS = 'REMOVE_S3_SELECTED_ALL_PATHS'
export const SET_ALL_S3_SELECTED_ASSETS = 'SET_ALL_S3_SELECTED_ASSETS'
export const RESET_S3_SELECTED_DATA = 'RESET_S3_SELECTED_DATA'
export const ADD_S3_SELECTED_SPECIFIC_PATHS = 'ADD_S3_SELECTED_SPECIFIC_PATHS'
export const REMOVE_S3_SELECTED_SPECIFIC_PATHS =
  'REMOVE_S3_SELECTED_SPECIFIC_PATHS'
export const SET_S3_SELECTED_SPECIFIC_PATHS = 'SET_S3_SELECTED_SPECIFIC_PATHS'

export const SET_EFS_ASSETS_SELECT_ALL_FILTERS =
  'SET_EFS_ASSETS_SELECT_ALL_FILTERS'
export const SET_EFS_ASSETS_SELECT_FILTERS = 'SET_EFS_ASSETS_SELECT_FILTERS'
export const SET_EFS_ASSETS_SELECT_PAGINATION =
  'SET_EFS_ASSETS_SELECT_PAGINATION'
export const SET_EFS_ASSETS_SELECT_SEARCH = 'SET_EFS_ASSETS_SELECT_SEARCH'
export const ADD_EFS_SELECTED_ASSET = 'ADD_EFS_SELECTED_ASSET'
export const REMOVE_EFS_SELECTED_ASSET = 'REMOVE_EFS_SELECTED_ASSET'
export const ADD_EFS_SELECTED_ALL_PATHS = 'ADD_EFS_SELECTED_ALL_PATHS'
export const REMOVE_EFS_SELECTED_ALL_PATHS = 'REMOVE_EFS_SELECTED_ALL_PATHS'
export const SET_ALL_EFS_SELECTED_ASSETS = 'SET_ALL_EFS_SELECTED_ASSETS'
export const RESET_EFS_SELECTED_DATA = 'RESET_EFS_SELECTED_DATA'
export const ADD_EFS_SELECTED_SPECIFIC_PATHS = 'ADD_EFS_SELECTED_SPECIFIC_PATHS'
export const REMOVE_EFS_SELECTED_SPECIFIC_PATHS =
  'REMOVE_EFS_SELECTED_SPECIFIC_PATHS'
export const SET_EFS_SELECTED_SPECIFIC_PATHS = 'SET_EFS_SELECTED_SPECIFIC_PATHS'
export const SET_ASSET_IDS_MISSING_IN_LIVE_ASSETS =
  'SET_ASSET_IDS_MISSING_IN_LIVE_ASSETS'
export const SET_ASSET_LOADING_MARKER = 'SET_ASSET_LOADING_MARKER'

export const requestLiveAssetsSelect = () => ({
  type: REQUEST_ASSETS_SELECT,
  payload: null,
})

export const requestAllAssetsSelect = (token: string) => ({
  type: REQUEST_ALL_ASSETS_SELECT,
  payload: token,
})

export const setAssetsSelect = (
  inventoryAttrs: ListAssetsForPolicyCoverageAttrs
) => ({
  type: SET_ASSETS_SELECT,
  payload: inventoryAttrs,
})

export const requestAssetsSelectAllFilters = () => ({
  type: REQUEST_ASSETS_SELECT_ALL_FILTERS,
  payload: null,
})

export const requestAssetsSelectAllFiltersV3 = () => ({
  type: REQUEST_ASSETS_SELECT_ALL_FILTERS_V3,
  payload: null,
})

export const setAssetsSelectFilters = (filters: VIRow) => ({
  type: SET_ASSETS_SELECT_FILTERS,
  payload: filters,
})

export const setAssetsSelectPagination = (pagination: PaginationModel) => ({
  type: SET_ASSETS_SELECT_PAGINATION,
  payload: pagination,
})

export const setAssetsSelectSearch = (search: string) => ({
  type: SET_ASSETS_SELECT_SEARCH,
  payload: search,
})

export const addSelectedAsset = (asset: AssetWithRelatedAssets<Asset>) => ({
  type: ADD_SELECTED_ASSET,
  payload: asset,
})

export const removeSelectedAsset = (asset: AssetWithRelatedAssets<Asset>) => ({
  type: REMOVE_SELECTED_ASSET,
  payload: asset,
})

export const setAllSelectedAssets = (
  assets: Array<AssetWithRelatedAssets<Asset>>
) => ({
  type: SET_ALL_SELECTED_ASSETS,
  payload: assets,
})

export const selectAllEBSAsset = (valueCheckboxHeader: boolean) => ({
  type: SELECT_ALL_EBS_ASSETS,
  payload: valueCheckboxHeader,
})

export const selectAllEC2Asset = (valueCheckboxHeader: boolean) => ({
  type: SELECT_ALL_EC2_ASSETS,
  payload: valueCheckboxHeader,
})

export const selectAllS3Asset = (valueCheckboxHeader: boolean) => ({
  type: SELECT_ALL_S3_ASSETS,
  payload: valueCheckboxHeader,
})

export const selectAllOtherAsset = (valueCheckboxHeader: boolean) => ({
  type: SELECT_ALL_OTHER_ASSETS,
  payload: valueCheckboxHeader,
})

export const setMissingSelectedAssets = (assets: VIMatrix) => ({
  type: SET_MISSING_SELECTED_ASSETS,
  payload: assets,
})

export const setAssetsSelectEnabledTabs = (tabs: Array<string>) => ({
  type: SET_ASSETS_SELECT_ENABLED_TABS,
  payload: tabs,
})

export const setAssetsSelectTabSelectedNum = (tab: TabAssetType) => ({
  type: SET_ASSETS_SELECT_TAB_SELECTED_NUM,
  payload: tab,
})

export const setEditMainTabAssets = (tab: TabAssetType) => ({
  type: SET_EDIT_MAIN_TAB_ASSETS,
  payload: tab,
})

// s3
export const setS3AssetsSelectAllFilters = (filters: VIRow) => ({
  type: SET_S3_ASSETS_SELECT_ALL_FILTERS,
  payload: filters,
})

export const setS3AssetsSelectFilters = (filters: VIRow) => ({
  type: SET_S3_ASSETS_SELECT_FILTERS,
  payload: filters,
})

export const setS3AssetsSelectPagination = (pagination: PaginationModel) => ({
  type: SET_S3_ASSETS_SELECT_PAGINATION,
  payload: pagination,
})

export const setS3AssetsSelectSearch = (search: string) => ({
  type: SET_S3_ASSETS_SELECT_SEARCH,
  payload: search,
})

export const addS3SelectedAsset = (asset: AssetWithRelatedAssets<Asset>) => ({
  type: ADD_S3_SELECTED_ASSET,
  payload: asset,
})

export const removeS3SelectedAsset = (
  asset: AssetWithRelatedAssets<Asset>
) => ({
  type: REMOVE_S3_SELECTED_ASSET,
  payload: asset,
})

export const addS3SelectedAllPaths = (
  asset: AssetWithRelatedAssets<Asset>
) => ({
  type: ADD_S3_SELECTED_ALL_PATHS,
  payload: asset,
})

export const removeS3SelectedAllPaths = (
  asset: AssetWithRelatedAssets<Asset>
) => ({
  type: REMOVE_S3_SELECTED_ALL_PATHS,
  payload: asset,
})

export const addS3SelectedSpecificPaths = (
  assetWithPaths: SelectSpecificPathsInterface
) => ({
  type: ADD_S3_SELECTED_SPECIFIC_PATHS,
  payload: assetWithPaths,
})

export const removeS3SelectedSpecificPaths = (
  assetWithPaths: SelectSpecificPathsInterface
) => ({
  type: REMOVE_S3_SELECTED_SPECIFIC_PATHS,
  payload: assetWithPaths,
})

export const resetS3SelectedData = () => ({
  type: RESET_S3_SELECTED_DATA,
  payload: null,
})

export const setAllS3SelectedAssets = (assets: AllS3SelectedAssets) => ({
  type: SET_ALL_S3_SELECTED_ASSETS,
  payload: assets,
})

// efs
export const setEfsAssetsSelectAllFilters = (filters: VIRow) => ({
  type: SET_EFS_ASSETS_SELECT_ALL_FILTERS,
  payload: filters,
})

export const setEfsAssetsSelectFilters = (filters: VIRow) => ({
  type: SET_EFS_ASSETS_SELECT_FILTERS,
  payload: filters,
})

export const setEfsAssetsSelectPagination = (pagination: PaginationModel) => ({
  type: SET_EFS_ASSETS_SELECT_PAGINATION,
  payload: pagination,
})

export const setEfsAssetsSelectSearch = (search: string) => ({
  type: SET_EFS_ASSETS_SELECT_SEARCH,
  payload: search,
})

export const addEfsSelectedAsset = (asset: AssetWithRelatedAssets<Asset>) => ({
  type: ADD_EFS_SELECTED_ASSET,
  payload: asset,
})

export const removeEfsSelectedAsset = (
  asset: AssetWithRelatedAssets<Asset>
) => ({
  type: REMOVE_EFS_SELECTED_ASSET,
  payload: asset,
})

export const addEfsSelectedAllPaths = (
  asset: AssetWithRelatedAssets<Asset>
) => ({
  type: ADD_EFS_SELECTED_ALL_PATHS,
  payload: asset,
})

export const removeEfsSelectedAllPaths = (
  asset: AssetWithRelatedAssets<Asset>
) => ({
  type: REMOVE_EFS_SELECTED_ALL_PATHS,
  payload: asset,
})

export const addEfsSelectedSpecificPaths = (
  assetWithPaths: SelectSpecificPathsInterface
) => ({
  type: ADD_EFS_SELECTED_SPECIFIC_PATHS,
  payload: assetWithPaths,
})

export const removeEfsSelectedSpecificPaths = (
  assetWithPaths: SelectSpecificPathsInterface
) => ({
  type: REMOVE_EFS_SELECTED_SPECIFIC_PATHS,
  payload: assetWithPaths,
})

export const resetEfsSelectedData = () => ({
  type: RESET_EFS_SELECTED_DATA,
  payload: null,
})

export const setAllEfsSelectedAssets = (assets: AllEfsSelectedAssets) => ({
  type: SET_ALL_EFS_SELECTED_ASSETS,
  payload: assets,
})

export const setAssetIdsMissingInLiveAssets = (ids: Array<string>) => ({
  type: SET_ASSET_IDS_MISSING_IN_LIVE_ASSETS,
  payload: ids,
})

export const setAssetsLoadingMarker = (marker: AssetLoadingMarker) => ({
  type: SET_ASSET_LOADING_MARKER,
  payload: marker,
})
