import React, { memo, ReactEventHandler, useEffect, useState } from 'react'
import PageMenuInterface from '@lib/interfaces/page-menu.interface'
import { useSelector } from 'react-redux'
import { getEnginePageMeta } from '@store/selectors/engine.selector'
import {
  MAIN_DEMO_MENU,
  MAIN_MENU,
  NO_CLOUD_CONNECTORS_MAIN_MENU,
} from '@lib/constants/menu.constant'
import useDemo from '@lib/hooks/useDemo'
import { RenderSimpleMenu } from '@features/RenderSimpleMenu'
import { RenderMenuWithSubMenu } from '@features/RenderMenuWithSubMenu'

interface Props {
  showOnlyOnboarding?: boolean
  handleMenuMainClose: ReactEventHandler
}

function MainMenu({ showOnlyOnboarding = false, handleMenuMainClose }: Props) {
  const pageMeta = useSelector(getEnginePageMeta)
  const [mainMenu, setMainMenu] = useState<Array<PageMenuInterface>>(
    MAIN_MENU()
  )

  const { modeActive } = useDemo({})

  useEffect(() => {
    if (showOnlyOnboarding) {
      setMainMenu(NO_CLOUD_CONNECTORS_MAIN_MENU())
    } else if (modeActive) {
      setMainMenu(MAIN_DEMO_MENU())
    } else {
      setMainMenu(MAIN_MENU())
    }
  }, [showOnlyOnboarding, modeActive])

  useDemo({
    runIfModeActive: () => {
      setMainMenu(MAIN_DEMO_MENU())
    },
  })

  const filteredMenu = showOnlyOnboarding
    ? NO_CLOUD_CONNECTORS_MAIN_MENU()
    : mainMenu?.filter((menuElem) => !!menuElem && !!menuElem.page)

  return (
    <div data-testid="main-menu">
      <div>
        {filteredMenu
          .filter((menuElem) => !menuElem.page.hideInSubMenu)
          .map((menuElem: PageMenuInterface) => (
            <div key={menuElem.page.path + menuElem.page.name}>
              {menuElem.page.path ? (
                <RenderSimpleMenu
                  menuElem={menuElem}
                  pagePath={pageMeta.path}
                  handleMenuMainClose={handleMenuMainClose}
                />
              ) : (
                <RenderMenuWithSubMenu
                  menuElem={menuElem}
                  pagePath={pageMeta.path}
                  handleMenuMainClose={handleMenuMainClose}
                />
              )}
            </div>
          ))}
      </div>
    </div>
  )
}

export default memo(MainMenu)
