"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckStatus = void 0;
var CheckStatus;
(function (CheckStatus) {
    CheckStatus[CheckStatus["NOT_APPLICABLE"] = 0] = "NOT_APPLICABLE";
    CheckStatus[CheckStatus["UNCHECKED"] = 1] = "UNCHECKED";
    CheckStatus[CheckStatus["GOOD"] = 2] = "GOOD";
    CheckStatus[CheckStatus["BAD"] = 3] = "BAD";
})(CheckStatus = exports.CheckStatus || (exports.CheckStatus = {}));
