export type Region =
  | typeof US_EAST_2
  | typeof US_EAST_1
  | typeof US_WEST_1
  | typeof US_WEST_2
  | typeof AF_SOUTH_1
  | typeof AP_EAST_1
  | typeof AP_SOUTH_1
  | typeof AP_NORTHEAST_3
  | typeof AP_NORTHEAST_2
  | typeof AP_SOUTHEAST_1
  | typeof AP_SOUTHEAST_2
  | typeof AP_NORTHEAST_1
  | typeof CA_CENTRAL_1
  | typeof CN_NORTH_1
  | typeof CN_NORTHWEST_1
  | typeof EU_CENTRAL_1
  | typeof EU_WEST_1
  | typeof EU_WEST_2
  | typeof EU_SOUTH_1
  | typeof EU_WEST_3
  | typeof EU_NORTH_1
  | typeof ME_SOUTH_1
  | typeof SA_EAST_1
  | typeof US_GOV_EAST_1
  | typeof US_GOV_WEST_1

export const US_EAST_2 = 'us-east-2'
export const US_EAST_1 = 'us-east-1'
export const US_WEST_1 = 'us-west-1'
export const US_WEST_2 = 'us-west-2'
export const AF_SOUTH_1 = 'af-south-1'
export const AP_EAST_1 = 'ap-east-1'
export const AP_SOUTH_1 = 'ap-south-1'
export const AP_NORTHEAST_3 = 'ap-northeast-3'
export const AP_NORTHEAST_2 = 'ap-northeast-2'
export const AP_SOUTHEAST_1 = 'ap-southeast-1'
export const AP_SOUTHEAST_2 = 'ap-southeast-2'
export const AP_NORTHEAST_1 = 'ap-northeast-1'
export const CA_CENTRAL_1 = 'ca-central-1'
export const CN_NORTH_1 = 'cn-north-1'
export const CN_NORTHWEST_1 = 'cn-northwest-1'
export const EU_CENTRAL_1 = 'eu-central-1'
export const EU_WEST_1 = 'eu-west-1'
export const EU_WEST_2 = 'eu-west-2'
export const EU_SOUTH_1 = 'eu-south-1'
export const EU_WEST_3 = 'eu-west-3'
export const EU_NORTH_1 = 'eu-north-1'
export const ME_SOUTH_1 = 'me-south-1'
export const SA_EAST_1 = 'sa-east-1'
export const US_GOV_EAST_1 = 'us-gov-east-1'
export const US_GOV_WEST_1 = 'us-gov-west-1'

export const US_EAST_2_HUMANREADABLE = 'Ohio'
export const US_EAST_1_HUMANREADABLE = 'N. Virginia'
export const US_WEST_1_HUMANREADABLE = 'N. California'
export const US_WEST_2_HUMANREADABLE = 'Oregon'
export const AF_SOUTH_1_HUMANREADABLE = 'Cape Town'
export const AP_EAST_1_HUMANREADABLE = 'Hong Kong'
export const AP_SOUTH_1_HUMANREADABLE = 'Mumbai'
export const AP_NORTHEAST_3_HUMANREADABLE = 'Osaka-Local'
export const AP_NORTHEAST_2_HUMANREADABLE = 'Seoul'
export const AP_SOUTHEAST_1_HUMANREADABLE = 'Singapore'
export const AP_SOUTHEAST_2_HUMANREADABLE = 'Sydney'
export const AP_NORTHEAST_1_HUMANREADABLE = 'Tokyo'
export const CA_CENTRAL_1_HUMANREADABLE = 'Canada (Central)'
export const CN_NORTH_1_HUMANREADABLE = 'China (Beijing)'
export const CN_NORTHWEST_1_HUMANREADABLE = 'China (Ningxia)'
export const EU_CENTRAL_1_HUMANREADABLE = 'Frankfurt'
export const EU_WEST_1_HUMANREADABLE = 'Ireland'
export const EU_WEST_2_HUMANREADABLE = 'London'
export const EU_SOUTH_1_HUMANREADABLE = 'Milan'
export const EU_WEST_3_HUMANREADABLE = 'Paris'
export const EU_NORTH_1_HUMANREADABLE = 'Stockholm'
export const ME_SOUTH_1_HUMANREADABLE = 'Bahrain'
export const SA_EAST_1_HUMANREADABLE = 'São Paulo'
export const US_GOV_EAST_1_HUMANREADABLE = 'AWS GovCloud (US-East)'
export const US_GOV_WEST_1_HUMANREADABLE = 'AWS GovCloud (US)'

export const regionToHumanReadable = {
  [US_EAST_2]: US_EAST_2_HUMANREADABLE,
  [US_EAST_1]: US_EAST_1_HUMANREADABLE,
  [US_WEST_1]: US_WEST_1_HUMANREADABLE,
  [US_WEST_2]: US_WEST_2_HUMANREADABLE,
  [AF_SOUTH_1]: AF_SOUTH_1_HUMANREADABLE,
  [AP_EAST_1]: AP_EAST_1_HUMANREADABLE,
  [AP_SOUTH_1]: AP_SOUTH_1_HUMANREADABLE,
  [AP_NORTHEAST_3]: AP_NORTHEAST_3_HUMANREADABLE,
  [AP_NORTHEAST_2]: AP_NORTHEAST_2_HUMANREADABLE,
  [AP_SOUTHEAST_1]: AP_SOUTHEAST_1_HUMANREADABLE,
  [AP_SOUTHEAST_2]: AP_SOUTHEAST_2_HUMANREADABLE,
  [AP_NORTHEAST_1]: AP_NORTHEAST_1_HUMANREADABLE,
  [CA_CENTRAL_1]: CA_CENTRAL_1_HUMANREADABLE,
  [CN_NORTH_1]: CN_NORTH_1_HUMANREADABLE,
  [CN_NORTHWEST_1]: CN_NORTHWEST_1_HUMANREADABLE,
  [EU_CENTRAL_1]: EU_CENTRAL_1_HUMANREADABLE,
  [EU_WEST_1]: EU_WEST_1_HUMANREADABLE,
  [EU_WEST_2]: EU_WEST_2_HUMANREADABLE,
  [EU_SOUTH_1]: EU_SOUTH_1_HUMANREADABLE,
  [EU_WEST_3]: EU_WEST_3_HUMANREADABLE,
  [EU_NORTH_1]: EU_NORTH_1_HUMANREADABLE,
  [ME_SOUTH_1]: ME_SOUTH_1_HUMANREADABLE,
  [SA_EAST_1]: SA_EAST_1_HUMANREADABLE,
  [US_GOV_EAST_1]: US_GOV_EAST_1_HUMANREADABLE,
  [US_GOV_WEST_1]: US_GOV_WEST_1_HUMANREADABLE,
}
