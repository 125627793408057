import React from 'react'

function DeploymentIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12250_362387)">
        <path
          d="M15.6884 13.2293C15.1083 13.2293 14.6151 13.6078 14.4425 14.1307H13.5563V11.9897H14.0406C17.8544 11.8654 18.0009 6.41915 14.2168 6.0762C13.4213 2.55686 8.62535 1.86882 6.87997 5.04025C4.83372 4.7957 2.98665 6.44158 3.00007 8.50388C3.00007 10.4119 4.54105 11.9668 6.44385 11.9894V14.1308H5.5577C5.07405 12.7359 3.02991 13.0465 3.00021 14.5409C3.03004 16.0356 5.07433 16.3457 5.5577 14.9511H7.26416V11.9897H9.58982V14.4344C8.19503 14.9181 8.50555 16.9622 10 16.9919C11.4947 16.9621 11.8048 14.9178 10.4102 14.4344V11.9897H12.736V14.9511H14.4425C14.9263 16.3461 16.9703 16.0352 16.9999 14.5409C17 13.8177 16.4116 13.2293 15.6884 13.2293ZM4.31179 15.0322C4.04092 15.0322 3.82055 14.8118 3.82055 14.5409C3.84524 13.8901 4.77846 13.8903 4.80307 14.5409C4.80307 14.8118 4.5827 15.0322 4.31179 15.0322ZM10 16.1716C9.72914 16.1716 9.50877 15.9512 9.50877 15.6803C9.53346 15.0295 10.4667 15.0297 10.4913 15.6803C10.4913 15.9512 10.2709 16.1716 10 16.1716ZM3.82038 8.50385C3.8013 6.83665 5.40271 5.53899 7.02881 5.89373L7.35024 5.96017L7.48554 5.66111C8.79322 2.80206 13.0396 3.42905 13.4674 6.5407L13.5175 6.92126L13.9005 6.89648C16.829 6.83345 16.9656 11.0396 14.0407 11.1693H6.48585C5.01611 11.1693 3.82038 9.9736 3.82038 8.50385ZM15.6884 15.0322C15.4175 15.0322 15.1971 14.8118 15.1971 14.5409C15.2218 13.8901 16.155 13.8903 16.1797 14.5409C16.1797 14.8118 15.9593 15.0322 15.6884 15.0322Z"
          fill="#999CAA"
        />
      </g>
      <defs>
        <clipPath id="clip0_12250_362387">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DeploymentIcon
