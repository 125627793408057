enum TimeFormatConstants {
  // DATE_FORMAT = 'MM dddd YYYY',
  SHORT_DATE_FORMAT = 'MMM DD, YYYY',
  ONLY_DATE_FORMAT = 'MM/DD/YY',
  ONLY_TIME_FORMAT = 'hh:mm a',
  TIME_FORMAT = 'hh:mm A',
  TIME_FORMAT_UTC = 'hh:mma', // (UTC)
  SHORT_DATETIME_FORMAT = 'MM/DD/YY hh:mm A',
  SHORT_DATETIME_FORMAT_SMALL = 'MM/DD/YY hh:mm a',
  DATETIME = 'YYYY-MM-DDTHH:mm',
  MODERN_DATETIME_FORMAT = 'M/DD/YY @ hh:mm A',
  INTERVAL_DATE_FORMAT = 'MMMM DD, YYYY',
  DATETIME_FORMAT = 'MM/DD/YYYY hh:mm A', // Currently we use this one usually
  // PURE_DATE_FORMAT = 'YYYY-MM-DD',
  // DATETIME_FORMAT = 'YYYY-MM-DD HH:mm',
  UTC_TIMEZONE_VALUE = 'Etc/UTC',
}

export default TimeFormatConstants
