import React, { memo } from 'react'
import ToastErrorIcon from '@inline-img/general/toast/toast-error-icon'
import ToastInfoIcon from '@inline-img/general/toast/toast-info-icon'
import ToastSuccessIcon from '@inline-img/general/toast/toast-success-icon'
import ToastWarningIcon from '@inline-img/general/toast/toast-warning-icon'

interface Props {
  variant: 'info' | 'success' | 'warning' | 'error'
  headerText?: string
  messageText: string
}

export function ToastMessage({
  variant = 'info',
  headerText,
  messageText,
}: Props) {
  const getToastIcon = () => {
    switch (variant) {
      case 'info':
        return <ToastInfoIcon />
      case 'success':
        return <ToastSuccessIcon />
      case 'warning':
        return <ToastWarningIcon />
      case 'error':
        return <ToastErrorIcon />
      default:
        return <ToastInfoIcon />
    }
  }

  const getToastHeader = () => {
    switch (variant) {
      case 'info':
        return 'Info'
      case 'success':
        return 'Success'
      case 'warning':
        return 'Warning'
      case 'error':
        return 'Error'
      default:
        return 'Info'
    }
  }

  return (
    <>
      <div className="customToastHeader">
        {getToastIcon()}
        <span>{headerText ?? getToastHeader()}</span>
      </div>
      <div className="customToastText">{messageText}</div>
    </>
  )
}

export default memo(ToastMessage)
