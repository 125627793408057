import React from 'react'

function AssetItemVolumeIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_13874_173810"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_13874_173810)">
        <path
          d="M9.99883 16.1814C9.83508 16.1814 9.67474 16.153 9.51783 16.0961C9.36091 16.0393 9.21341 15.9603 9.07533 15.8593L3.81093 11.7727C3.64747 11.6488 3.56654 11.4862 3.56814 11.2848C3.56974 11.0834 3.65316 10.9186 3.81841 10.7904C3.93201 10.7049 4.0575 10.6622 4.19489 10.6622C4.33226 10.6622 4.45908 10.7044 4.57533 10.7888L9.83972 14.8625C9.8878 14.8999 9.94123 14.9186 9.99999 14.9186C10.0588 14.9186 10.1122 14.8999 10.1603 14.8625L15.4247 10.7888C15.5409 10.7044 15.6677 10.6622 15.8051 10.6622C15.9425 10.6622 16.068 10.7049 16.1816 10.7904C16.3468 10.9186 16.4302 11.0834 16.4318 11.2848C16.4334 11.4862 16.3525 11.6488 16.1891 11.7727L10.9247 15.8593C10.7861 15.9603 10.6381 16.0393 10.4806 16.0961C10.3232 16.153 10.1626 16.1814 9.99883 16.1814ZM9.99951 12.4859C9.8353 12.4859 9.67474 12.4575 9.51783 12.4006C9.36091 12.3438 9.21341 12.2648 9.07533 12.1638L3.94843 8.17833C3.84715 8.10242 3.77141 8.01154 3.7212 7.90571C3.67098 7.79988 3.64587 7.69093 3.64587 7.57888C3.64587 7.4659 3.67098 7.35606 3.7212 7.24935C3.77141 7.14266 3.84673 7.05165 3.94716 6.97633L9.07168 2.995C9.21086 2.89389 9.3594 2.81476 9.5173 2.7576C9.67519 2.70045 9.83625 2.67188 10.0005 2.67188C10.1647 2.67188 10.3252 2.70029 10.4822 2.75712C10.6391 2.81397 10.7866 2.89292 10.9247 2.99398L16.0516 6.97944C16.1528 7.05535 16.2286 7.14623 16.2788 7.25206C16.329 7.35788 16.3541 7.46682 16.3541 7.57888C16.3541 7.69186 16.329 7.80171 16.2788 7.90842C16.2286 8.01511 16.1533 8.10612 16.0528 8.18144L10.9283 12.1628C10.7891 12.2639 10.6406 12.343 10.4827 12.4002C10.3248 12.4573 10.1637 12.4859 9.99951 12.4859ZM9.83972 11.167C9.8878 11.2044 9.94123 11.2231 9.99999 11.2231C10.0588 11.2231 10.1122 11.2044 10.1603 11.167L14.7917 7.57888L10.1603 3.99075C10.1122 3.95336 10.0588 3.93467 9.99999 3.93467C9.94123 3.93467 9.8878 3.95336 9.83972 3.99075L5.20833 7.57888L9.83972 11.167Z"
          fill="#1A1F28"
        />
      </g>
    </svg>
  )
}

export default AssetItemVolumeIcon
