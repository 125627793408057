import BaseModel from '@lib/models/base-model/base.model'
import TimeHelper from '@lib/helpers/time.helper'
import JobStatusConstant, {
  JOB_STATUS_PROGRESS,
} from '@lib/constants/jobs/job-status.constant'
import JobKindConstant from '@lib/constants/jobs/job-kind.constant'
import { Nullable } from '@lib/engine-types'
import { AssetKind } from '@lib/constants/grpc/asset-kind.constant'

class JobModel extends BaseModel {
  public readonly name: string

  public readonly status: JobStatusConstant

  public readonly kind: JobKindConstant

  public readonly statusMessage: string

  public readonly createdAt: number

  public readonly updatedAt: number

  public readonly childrenList: Nullable<Array<JobModel>>

  public readonly customStatus: string

  public readonly isCloudConnectorJob: boolean

  public readonly isAbortRequested: boolean

  public readonly awsAccountId: string

  public readonly awsRegion: string

  // ADD performer

  // extra

  public readonly cloudConnectorId: string

  public readonly createdAtFormatted: string

  public readonly updatedAtFormatted: string

  public readonly statusMessageWithChildren: string

  public readonly childrenProgressMessage: string

  public readonly recoveryPointAssetId?: string

  public readonly recoveryPointId?: string

  public readonly redStackRecoveryPointId?: string

  public readonly statusMessageWithCustom: string

  public readonly relatedAssetKind: AssetKind

  public readonly relatedAssetAwsAccountId: string

  public readonly relatedAssetRegion: string

  public readonly relatedAssetResourceId: string

  public readonly canBeAborted: boolean

  public readonly isRunning: boolean

  public readonly nameFormatted: string

  public readonly recoveryPointAssetIdsFormatted?: string

  public readonly recoveryPointIdsFormatted?: string

  public readonly redStackRecoveryPointIdsFormatted?: string

  constructor(readonly params: any = {}) {
    super(params)
    // base

    this.name = params.name
    this.status = params.status
    this.kind = params.kind
    this.statusMessage = params.statusMessage
    this.createdAt = params.createdAt
    this.updatedAt = params.updatedAt
    this.childrenList = params.childrenList
    this.customStatus = params.customStatus
    this.cloudConnectorId = params.cloudConnectorId
    this.relatedAssetKind = params.relatedAssetKind
    this.relatedAssetAwsAccountId = params.relatedAssetAwsAccountId
    this.relatedAssetRegion = params.relatedAssetRegion
    this.relatedAssetResourceId = params.relatedAssetResourceId
    this.isCloudConnectorJob = params.isCloudConnectorJob
    this.isAbortRequested = params.isAbortRequested
    this.awsAccountId = params.awsAccountId
    this.awsRegion = params.awsRegion

    // extra

    this.createdAtFormatted = TimeHelper.timestampShortDatetimeFormat(
      this.createdAt
    )
    this.updatedAtFormatted = TimeHelper.timestampShortDatetimeFormat(
      this.updatedAt
    )

    // eslint-disable-next-line quotes
    this.nameFormatted = this.name.replace(/&quot;/g, "'")

    // error message
    this.statusMessageWithChildren = this.statusMessage
    if (
      this.childrenList &&
      this.childrenList.length > 0 &&
      this.statusMessage === 'Child job failed'
    ) {
      const childErrorMessage = this.childrenList
        .filter((c) => c.statusMessage)
        .map((c) => c.statusMessage)
        .join(', ')
      if (childErrorMessage) {
        this.statusMessageWithChildren = childErrorMessage
      }
    }
    // messages with extra data from children
    const messages: Array<string> = []
    if (this.childrenList && this.childrenList.length > 0) {
      this.childrenList.forEach((childJob: JobModel) => {
        const statusName = JobStatusConstant[childJob.status]
        messages.push(
          `[${childJob.updatedAtFormatted}] "${statusName}" - ${childJob.name}`
        )
      })
    }

    this.statusMessageWithCustom = this.statusMessage
    if (this.status === JobStatusConstant.CUSTOM) {
      this.statusMessageWithCustom = this.customStatus ?? 'Custom'
    }

    this.childrenProgressMessage = messages.join('\n')
    this.recoveryPointAssetId = params.recoveryPointAssetId
    this.recoveryPointId = params.recoveryPointId
    this.redStackRecoveryPointId = params.redStackRecoveryPointId
    this.isRunning = JOB_STATUS_PROGRESS.includes(this.status)
    this.canBeAborted =
      !this.isAbortRequested && this.isCloudConnectorJob && this.isRunning

    const recoveryPointAssetIds: Array<string> = []
    const recoveryPointIds: Array<string> = []
    const redStackRecoveryPointId: Array<string> = []
    if (this.childrenList && this.childrenList.length > 0) {
      this.childrenList.forEach((childJob: JobModel) => {
        if (childJob.recoveryPointAssetId) {
          recoveryPointAssetIds.push(childJob.recoveryPointAssetId)
        }
        if (childJob.recoveryPointId) {
          recoveryPointIds.push(childJob.recoveryPointId)
        }
        if (childJob.redStackRecoveryPointId) {
          redStackRecoveryPointId.push(childJob.redStackRecoveryPointId)
        }
      })
    }

    this.recoveryPointAssetIdsFormatted = this.recoveryPointAssetId
      ? this.recoveryPointAssetId
      : recoveryPointAssetIds.join(',')
    this.recoveryPointIdsFormatted = this.recoveryPointId
      ? this.recoveryPointId
      : recoveryPointIds.join(',')
    this.redStackRecoveryPointIdsFormatted = this.redStackRecoveryPointId
      ? this.redStackRecoveryPointId
      : redStackRecoveryPointId.join(',')
  }
}

export default JobModel
