import { AbstractListRequest } from '@lib/clients/list-request'
import { ListEfs } from '@lib/clients/grpc-imports'

type Tag = { key: string; value: string }

interface ListEfsFilterParams {
  assetIdList: Array<string>
  tagsList: Array<Tag>
  excludeTagsList: Array<Tag>
  accountIdsList: Array<string>
  regionsList: Array<string>
  pageSize: number
}

export class ListEfsFilter extends AbstractListRequest<ListEfs> {
  #requestParams: Partial<ListEfsFilterParams>

  constructor(requestParams: Partial<ListEfsFilterParams>) {
    super()
    this.#requestParams = requestParams
  }

  createGrpcRequest(): ListEfs {
    const grpcRequest = new ListEfs()

    const filter = new ListEfs.Initial.Filter()

    const { assetIdList } = this.#requestParams
    if (assetIdList && assetIdList.length) {
      filter.setAssetIdList(assetIdList)
    }

    return grpcRequest.setInitial(
      new ListEfs.Initial()
        .setFilter(filter)
        .setPageSize(
          this.#requestParams.pageSize || AbstractListRequest.DEFAULT_PAGE_SIZE
        )
    )
  }
}
