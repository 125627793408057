import React from 'react'
import Head from 'next/head'
import SiteConstants from '@lib/constants/site.constant'
import { PageMeta } from '@lib/engine-types'

interface Props {
  page: PageMeta
}

function Meta({ page }: Props) {
  return (
    <Head>
      <title>{page.metaTitle}</title>
      <link
        rel="preload"
        as="font"
        crossOrigin=""
        type="font/woff2"
        href="/fonts/font-roboto/roboto/KFOmCnqEu92Fr1Mu4mxK.woff2"
      />
      <link
        rel="preload"
        as="font"
        crossOrigin=""
        type="font/woff2"
        href="/fonts/font-roboto/roboto/KFOlCnqEu92Fr1MmEU9fBBc4.woff2"
      />
      <link
        rel="preload"
        as="font"
        crossOrigin=""
        type="font/woff2"
        href="/fonts/font-roboto/roboto/KFOlCnqEu92Fr1MmSU5fBBc4.woff2"
      />
      <link
        rel="preload"
        as="font"
        crossOrigin=""
        type="font/woff2"
        href="/fonts/font-roboto/roboto/KFOlCnqEu92Fr1MmWUlfBBc4.woff2"
      />
      <link
        rel="preload"
        as="font"
        crossOrigin=""
        type="font/woff2"
        href="/fonts/font-roboto-mono/roboto-mono/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vq_ROW4.woff2"
      />
      <link
        rel="preload"
        as="font"
        crossOrigin=""
        type="font/woff2"
        href="/fonts/material-icons/material/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2"
      />
      <link
        rel="preload"
        as="font"
        crossOrigin=""
        type="font/otf"
        href="/fonts/font-circular-std/circular-std/CircularStd-Book.otf"
      />

      <link
        rel="preload"
        as="font"
        crossOrigin=""
        type="font/otf"
        href="/fonts/font-poppins/poppins/Poppins-Regular.ttf"
      />

      <base href="/" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={SiteConstants.NAME} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="theme-color" content={SiteConstants.THEME_COLOR} />
      <meta property="og:title" content={page.metaTitle} />
      <meta property="og:description" content={page.metaDescription} />
      <meta property="og:url" content={page.path} />
      <meta property="twitter:title" content={page.metaTitle} />
      <meta property="twitter:description" content={page.metaDescription} />
      <meta property="twitter:url" content={page.path} />
      <meta name="description" content={page.metaDescription} />
      <meta name="keywords" content={page.metaKeywords} />
      <link rel="canonical" href={page.path} />

      <link rel="shortcut icon" href="favicon.ico" />

      {/* https://manage.statuspage.io  */}
      {/* this script must be "async" */}
      <script
        async
        src="https://qn29kxd65nnw.statuspage.io/embed/script.js"
      ></script>
    </Head>
  )
}

export default Meta
