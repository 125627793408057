import React from 'react'

export default function ElastioLogoBlueIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 2">
        <g id="Vector">
          <path
            d="M14.6936 29.3842C15.0958 29.3874 15.4919 29.2852 15.8418 29.088L25.4494 23.6279C25.8008 23.4277 26.093 23.1392 26.2969 22.7914C26.5008 22.4436 26.6092 22.0487 26.6111 21.6462V15.4378H24.0437V21.4962L14.6936 26.8023L4.40632 20.8998V9.09844L14.6936 3.19585L23.6816 8.35388L14.2211 13.6831L15.4855 15.8956L25.3409 10.3432C25.6913 10.1442 25.9828 9.85713 26.1862 9.51078C26.3896 9.16443 26.4977 8.77101 26.4997 8.37001C26.5016 7.96902 26.3974 7.57457 26.1974 7.22628C25.9974 6.87798 25.7087 6.58809 25.3603 6.38571L15.8418 0.919856C15.4931 0.72049 15.0978 0.615562 14.6955 0.615562C14.2932 0.615562 13.8979 0.72049 13.5492 0.919856L2.99674 6.96289C2.64756 7.16365 2.35755 7.452 2.15571 7.79908C1.95387 8.14616 1.84729 8.53982 1.84661 8.94068V21.0421C1.84729 21.443 1.95387 21.8366 2.15571 22.1837C2.35755 22.5308 2.64756 22.8192 2.99674 23.0199L13.5492 29.088C13.898 29.2845 14.2926 29.3866 14.6936 29.3842Z"
            fill="#1AA8EA"
          />
          <path
            d="M14.8504 17.3347C13.4367 17.3347 12.2907 16.196 12.2907 14.7913C12.2907 13.3866 13.4367 12.2479 14.8504 12.2479C16.2641 12.2479 17.4101 13.3866 17.4101 14.7913C17.4101 16.196 16.2641 17.3347 14.8504 17.3347Z"
            fill="#1AA8EA"
          />
          <path
            d="M22.6496 15.5513C22.6496 16.9557 23.9023 17.9889 25.3158 17.9889C25.9947 17.9889 26.6457 17.7209 27.1258 17.2439C27.6058 16.7669 27.8755 16.12 27.8755 15.4454C27.8755 14.7709 27.6058 14.124 27.1258 13.647C26.6457 13.17 25.9947 12.902 25.3158 12.902C24.615 12.9219 23.9484 13.2074 23.4526 13.7C22.9569 14.1926 22.6696 14.8549 22.6496 15.5513Z"
            fill="#1AA8EA"
          />
        </g>
      </g>
    </svg>
  )
}
