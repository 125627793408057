import { GetScanFiltersResponse } from '@lib/clients/scans/get-scan-filters'
import { AssetId, Kind, ScanTargetKind, Status } from '@lib/clients/scans/types'
/* eslint-disable import/no-extraneous-dependencies */
import { Response } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/scans/get_scan_filters_pb'

export class ScanFiltersTransformer {
  #convertScanTargetKind(
    scanTargetKind: Response.ScanTargetKind
  ): ScanTargetKind {
    const mapping = {
      [Response.ScanTargetKind.BACKUP]: ScanTargetKind.BACKUP,
      [Response.ScanTargetKind.DIRECT_SCAN]: ScanTargetKind.DIRECT_SCAN,
    }

    return mapping[scanTargetKind]
  }

  #convertStatus(status: Response.Status): Status {
    const mapping = {
      [Response.Status.STATUS_BAD]: Status.BAD,
      [Response.Status.STATUS_GOOD]: Status.GOOD,
    }

    return mapping[status]
  }

  #convertKind(kind: Response.Kind): Kind {
    const mapping = {
      [Response.Kind.KIND_FILE_SYSTEM_CHECK]: Kind.FILE_SYSTEM_CHECK,
      [Response.Kind.KIND_MALWARE]: Kind.MALWARE,
      [Response.Kind.KIND_RANSOMWARE]: Kind.RANSOMWARE,
    }

    return mapping[kind]
  }

  #convertAssetId(assetId: Response.AssetId.AsObject): AssetId {
    return {
      genericHost: assetId.genericHost,
      s3Bucket: assetId.s3Bucket,
      ec2Instance: assetId.ec2Instance,
      ebsVolume: assetId.ebsVolume,
      efsFilesystem: assetId.efsFilesystem,
    }
  }

  transformResponse(response: Response): GetScanFiltersResponse {
    const {
      accountIdsList,
      regionsList,
      scanTargetKindsList,
      humanReadableRecoveryPointIdsList,
      assetIdsList,
      statusesList,
      kindsList,
    } = response.toObject()

    return {
      accountIdsList,
      regionsList,
      scanTargetKindsList: scanTargetKindsList.map(
        this.#convertScanTargetKind.bind(this)
      ),
      humanReadableRecoveryPointIdsList,
      assetIdList: assetIdsList.map(this.#convertAssetId.bind(this)),
      statusesList: statusesList.map(this.#convertStatus.bind(this)),
      kindsList: kindsList.map(this.#convertKind.bind(this)),
    }
  }
}
