import { WebhooksInvocations } from '@lib/interfaces/webhooks.interface'
import WebhookModel, { EventType } from '@lib/models/webhook.model'

export const getWebhooksList = (state: any): Array<WebhookModel> =>
  state.webhookState.webhooksList

export const getSelectedWebhook = (state: any): WebhookModel =>
  state.webhookState.selectedWebhook

export const getEventTypes = (state: any): Array<EventType> =>
  state.webhookState.eventTypes

export const getTestWebhookResult = (state: any): boolean | undefined =>
  state.webhookState.webhookTestedResult

export const getWebhooksInvocations = (
  state: any
): Array<WebhooksInvocations> => state.webhookState.webhooksInvocations
