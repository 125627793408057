import { cloneDeep } from 'lodash/fp'
import { Nullable, VIMatrix, VIRow, VIRowReadonly } from '@lib/engine-types'
import ObjHelper from '@lib/helpers/obj.helper'
import ValueInterface from '@lib/interfaces/value.interface'
import StrHelper from '@lib/helpers/str.helper'
import SearchResultConstant from '@lib/constants/search-result.constant'
import UserRoleModel from '@lib/models/user-role.model'
import DataHelper from '@lib/helpers/data.helper'
import TypeHelper from '@lib/helpers/type.helper'

abstract class ValueHelper {
  // get all values
  public static getValues(arr: Array<ValueInterface>): Array<number> {
    const result: Array<number> = []
    arr.forEach((elem: ValueInterface) => {
      if (!elem.options?.length) {
        result.push(Number(!!elem.value))
      } else {
        elem.options.forEach((v: ValueInterface) => {
          result.push(Number(!!v.value))
        })
      }
    })
    return result
  }

  // mark all values by mask
  public static setValuesByMask(
    arr: Array<ValueInterface>,
    mask: Array<number>
  ): Array<ValueInterface> {
    let generalIndex = 0
    return arr.map((elem: ValueInterface) => {
      const v = cloneDeep(elem)

      if (!v.options?.length) {
        v.value = Boolean(mask[generalIndex++])
      } else {
        v.options?.forEach((opt) => {
          opt.value = Boolean(mask[generalIndex++])
        })
      }

      return v
    })
  }

  public static getSelectedOption(
    value: ValueInterface
  ): Nullable<ValueInterface> {
    if (!value.options) {
      return null
    }
    const result = value.options.find((v) => v.value === value.value)
    if (!result) {
      return null
    }
    return result
  }

  public static getSelectedMultipleOptions(
    value: ValueInterface
  ): Array<ValueInterface> {
    if (!value.options || value.options.length === 0) {
      return []
    }
    const result: Array<ValueInterface> = []
    value.options.forEach((v) => {
      if (v.value) {
        result.push(v)
      }
    })
    return result
  }

  // it gets the new element with a new value,
  // clone the result arr and replace old value with
  // the new one
  public static updateSelectedValue(
    elem: ValueInterface,
    arr: Array<ValueInterface>
  ): Array<ValueInterface> {
    const newArr: Array<ValueInterface> = ObjHelper.cloneDeep(arr)
    const index = newArr.findIndex((v: ValueInterface) => v.name === elem.name)
    if (index !== -1) {
      newArr[index] = elem
    }
    return newArr
  }

  public static updateValue(
    value: ValueInterface,
    newValue: number | string | boolean
  ): ValueInterface {
    const result = ObjHelper.cloneDeep(value)
    result.value = newValue
    return result
  }

  public static updateValueByIndex(
    index: number,
    newValue: number | string | boolean,
    arr: VIRow
  ): VIRow {
    const result: VIRow = ObjHelper.cloneDeep(arr)
    const elem = result[index]
    if (elem) {
      elem.value = newValue
    }
    return result
  }

  public static removeChildFromOptions(
    parent: ValueInterface,
    child: ValueInterface
  ): ValueInterface {
    const result = ObjHelper.cloneDeep(parent)
    result.options = parent.options
      ?.filter((v) => v.name !== child.name)
      .map((v) => ObjHelper.cloneDeep(v))
    return result
  }

  public static addUniqueChildToOptions(
    parent: ValueInterface,
    child: ValueInterface
  ): ValueInterface {
    const result = ObjHelper.cloneDeep(parent)
    if (!result.options) {
      result.options = []
    }
    // add if it is needed
    if (
      !result.options.find(
        (v) => v.name === child.name && v.value === child.value
      )
    ) {
      result.options?.push(ObjHelper.cloneDeep(child))
    }
    return result
  }

  public static toFiltersLabel(
    parent: ValueInterface,
    child: ValueInterface
  ): string {
    // for checkboxes
    if (child.type === 1) {
      return `${parent.label}: ${child.label}`
    }

    let result = ''

    if (parent.label) {
      result += parent.label
    }
    if (child.label) {
      if (result) {
        result += '/'
      }
      result += child.label
    }

    // add the value if it is needed
    if (child.value !== '' && typeof child.value !== 'boolean') {
      if (result) {
        result += ': '
      }
      result += child.value
    }

    return result
  }

  public static textSearch(
    node: ValueInterface,
    searchValue: string,
    parentFields: Array<string>,
    childFields: Array<string>
  ): Nullable<ValueInterface> {
    const result = ObjHelper.cloneDeep(node)

    // search in parent
    let foundInParent = false
    parentFields.forEach((parentField: string) => {
      // @ts-ignore
      if (
        // @ts-ignore
        node[parentField] !== undefined &&
        // @ts-ignore
        StrHelper.isSubstring(node[parentField], searchValue)
      ) {
        foundInParent = true
      }
    })
    if (foundInParent) {
      return result
    }

    if (!result.options) {
      return null
    }

    // search among children
    const childrenOptions = result.options
    result.options = []
    childrenOptions.forEach((childNode: ValueInterface) => {
      let childFound = false
      childFields.forEach((childField: string) => {
        if (
          // @ts-ignore
          childNode[childField] &&
          // @ts-ignore
          StrHelper.isSubstring(childNode[childField], searchValue)
        ) {
          childFound = true
        }
      })
      if (childFound) {
        result.options?.push(childNode)
      }
    })

    if (!result.options.length) {
      return null
    }
    return result
  }

  public static fromSelectedToGlobal(
    selected: Array<ValueInterface>,
    globals: Array<ValueInterface>
  ): Array<ValueInterface> {
    const result = ObjHelper.cloneDeep(globals)

    result.forEach((globalCategory) => {
      const selectedCategoryIndex = selected.findIndex(
        (v) => v.name === globalCategory.name
      )
      if (selectedCategoryIndex === -1) {
        return
      }

      // disable filter
      if (selected[selectedCategoryIndex]?.disabled) {
        globalCategory.disabled = true
      }

      // filter tooltip
      globalCategory.supplementalValue =
        selected[selectedCategoryIndex]?.supplementalValue ?? ''

      globalCategory.options?.forEach((globalElem: ValueInterface) => {
        // @ts-ignore
        const selectedOptionIndex = selected[
          selectedCategoryIndex
        ].options.findIndex((v) => v.name === globalElem.name)
        if (selectedOptionIndex === -1) {
          return
        }

        // update value if it is needed
        /*eslint-disable */
        // @ts-ignore
        const finalValue =
          // @ts-ignore
          selected[selectedCategoryIndex].options[selectedOptionIndex].value
        if (typeof finalValue === 'boolean') {
          globalElem.value = finalValue
        }

        // disable selected element
        const hasDisabledProperty =
          // @ts-ignore
          selected[selectedCategoryIndex].options[selectedOptionIndex].disabled
        if (hasDisabledProperty) {
          globalElem.disabled = true
        }

        /* eslint-enable */
      })
    })

    return result
  }

  public static calcTotalOptionsCount(arr: Array<ValueInterface>): number {
    let result = 0
    arr.forEach((v) => {
      result += v.options ? v.options.length : 0
    })
    return result
  }

  public static removeOptionFromDataSet(
    data: Array<ValueInterface>,
    filterCategoryIndex: number,
    filterElemIndex: number
  ): Array<ValueInterface> {
    let result = ObjHelper.cloneDeep(data)
    result[filterCategoryIndex]?.options?.splice(filterElemIndex, 1)

    // removing categories without children
    result = result.filter((v) => v.options && v.options.length > 0)

    return result
  }

  public static contains(elem: ValueInterface, arr: VIRow): boolean {
    return arr.findIndex((v) => v.name === elem.name) !== -1
  }

  public static addToArr(elem: ValueInterface, arr: VIRow): VIRow {
    const result = ValueHelper.removeFromArr(elem, arr)
    result.push(ObjHelper.cloneDeep(elem))
    return result
  }

  public static removeFromArr(elem: ValueInterface, arr: VIRow) {
    const result = ObjHelper.cloneDeep(arr)
    return result.filter((v) => v.name !== elem.name)
  }

  public static orderDictionaryCategorization(
    arr: VIRow,
    field: string
  ): VIRow {
    const mapCategories: any = {}
    arr.forEach((elem) => {
      // @ts-ignore
      const categoryName: string = String(elem[field][0]).toUpperCase()
      if (!mapCategories[categoryName]) {
        mapCategories[categoryName] = []
      }
      mapCategories[categoryName].push({
        ...elem,
        type: 1,
      })
    })

    /* eslint-disable */
    const result: VIRow = []
    for (const key in mapCategories) {
      result.push({
        name: key,
        label: key,
        options: mapCategories[key].sort((a: any, b: any) =>
          a[field] > b[field] ? 1 : -1
        ),
      })
    }

    return result.sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
    /* eslint-enable */
  }

  public static cloneVIRow(data: VIRow): VIRow {
    return data.map(ObjHelper.cloneDeep)
  }

  public static cloneVIMatrix(data: VIMatrix): VIMatrix {
    return data.map(ValueHelper.cloneVIRow)
  }

  public static updateMatrixValue(
    baseMatrix: VIMatrix,
    // update params
    indexForUpdate: number,
    fieldForUpdate: string,
    valueForUpdate: number | string | boolean
  ): VIMatrix {
    const result = this.cloneVIMatrix(baseMatrix)
    for (let i = 0; i < result.length; ++i) {
      if (result[i]?.[indexForUpdate] === undefined) {
        continue
      }
      // @ts-ignore
      result[i][indexForUpdate][fieldForUpdate] = valueForUpdate
      const subRows = result[i]?.find((v) => !!v.matrix && v.matrix.length > 0)
      if (subRows) {
        // @ts-ignore
        for (let j = 0; j < subRows.matrix.length; ++j) {
          // @ts-ignore
          // eslint-disable-next-line max-len
          subRows.matrix[j][indexForUpdate][fieldForUpdate] = valueForUpdate
        }
      }
    }
    return result
  }

  // it does searching by getting a value from the "row"
  // when it finds the same value in the "baseMatrix" then
  // it will update this VIRow using "update params"
  public static updateMatrixVIRowValue(
    baseMatrix: VIMatrix,
    row: VIRow,
    // search params
    indexForSearch: number,
    fieldForSearch: string,
    // update params
    indexForUpdate: number,
    fieldForUpdate: string,
    valueForUpdate: number | string | boolean
  ): VIMatrix {
    const result = this.cloneVIMatrix(baseMatrix)
    // @ts-ignore
    const rowValueForSearching = row[indexForSearch][fieldForSearch]
    for (let i = 0; i < result.length; ++i) {
      // among parents
      // @ts-ignore
      if (result[i][indexForSearch][fieldForSearch] === rowValueForSearching) {
        // @ts-ignore
        result[i][indexForUpdate][fieldForUpdate] = valueForUpdate
        return result
      }
    }

    for (let i = 0; i < result.length; ++i) {
      // among children
      const matrixIndex = result[i]?.findIndex(
        (v) => !!v.matrix && v.matrix.length > 0
      )
      if (matrixIndex !== -1) {
        // @ts-ignore
        for (let j = 0; j < result[i][matrixIndex].matrix.length; ++j) {
          if (
            // @ts-ignore
            result[i][matrixIndex].matrix[j][indexForSearch][fieldForSearch] ===
            rowValueForSearching
          ) {
            // @ts-ignore
            result[i][matrixIndex].matrix[j][indexForUpdate][fieldForUpdate] =
              valueForUpdate
            return result
          }
        }
      }
    }

    return result
  }

  public static checkMatrixField(
    data: VIMatrix,
    index: number,
    valueField: string,
    value: number | string | boolean
  ): SearchResultConstant {
    let foundAmount = 0
    let totalAmount = 0
    data.forEach((row: VIRow) => {
      const currentElem: Nullable<ValueInterface> = row[index]
      if (currentElem === undefined) {
        return
      }
      totalAmount += 1
      // @ts-ignore
      if (currentElem[valueField] === value) {
        foundAmount += 1
      }

      const subRows = row.find((v) => !!v.matrix && v.matrix.length > 0)
      subRows?.matrix?.forEach((subRow: VIRow) => {
        const currentSubElem: Nullable<ValueInterface> = subRow[index]
        if (currentSubElem === undefined) {
          return
        }
        totalAmount += 1
        // @ts-ignore
        if (currentSubElem[valueField] === value) {
          foundAmount += 1
        }
      })
    })

    if (totalAmount === 0) {
      return SearchResultConstant.NotFound
    }

    if (foundAmount === totalAmount) {
      return SearchResultConstant.FoundAll
    }

    return foundAmount > 0
      ? SearchResultConstant.Found
      : SearchResultConstant.NotFound
  }

  public static removeColumnsFromRow<T>(
    data: Array<T>,
    columnsMask: Array<number>
  ): Array<T> {
    const result: Array<T> = []
    data.forEach((elem: T, index: number) => {
      if (!columnsMask[index]) {
        result.push(elem)
      }
    })
    return result
  }

  public static removeColumnsFromMatrix(
    data: VIMatrix,
    columnsMask: Array<number>
  ): VIMatrix {
    const result: VIMatrix = []
    data.forEach((row: VIRow) => {
      const newRow: VIRow = ValueHelper.removeColumnsFromRow(row, columnsMask)
      const matrixElem = newRow.find((v) => v.matrix !== undefined)
      if (matrixElem) {
        matrixElem.matrix = ValueHelper.removeColumnsFromMatrix(
          matrixElem.matrix ?? [],
          columnsMask
        )
      }
      result.push(newRow)
    })
    return result
  }

  public static fromRole(role: UserRoleModel): ValueInterface {
    return {
      name: role.name,
      value: role.innerId,
      options: DataHelper.groupScopes(role.scopesList),
    }
  }

  public static sort(
    data: VIRow,
    field: keyof ValueInterface = 'value'
  ): VIRow {
    return (
      [...data]
        // @ts-ignore
        .sort((a, b) => ((+a[field] || 0) > (+b[field] || 0) ? 1 : -1))
    )
  }

  public static map(
    nodes: VIRow,
    callback: (node: ValueInterface) => any
  ): VIRow {
    const toIterate = ObjHelper.cloneDeep(nodes)

    return toIterate.map((node) => {
      if (node.matrix) {
        node.matrix = node.matrix.map((value) =>
          ValueHelper.map(value, callback)
        )
      }

      return callback(node)
    })
  }

  public static forEach(
    nodes: VIRow,
    callback: (node: ValueInterface) => any
  ): void {
    const toIterate = ObjHelper.cloneDeep(nodes)

    toIterate.forEach((node) => {
      callback(node)

      if (node.matrix) {
        node.matrix.forEach((value) => ValueHelper.forEach(value, callback))
      }
    })
  }

  public static findNodeById(
    nodeId: ValueInterface['id'],
    nodes: VIRow
  ): Nullable<ValueInterface> {
    return nodes.reduce((acc, node) => {
      if (!!acc) {
        return acc
      }

      if (node.id === nodeId) {
        return node
      }

      if (node.matrix) {
        node.matrix.some((_nodes) => {
          acc = ValueHelper.findNodeById(nodeId, _nodes)
          return !!acc
        })
      }

      return acc
    }, null as Nullable<ValueInterface>)
  }

  public static removeFromArrByValue(
    possibleValues: VIRowReadonly,
    value: ValueInterface['value'],
    keys: ReadonlyArray<keyof Pick<ValueInterface, 'name' | 'value' | 'label'>>
  ): VIRow {
    return possibleValues.filter((elem: ValueInterface) => {
      return !keys.some((key) => {
        if (elem[key] === undefined) {
          return false
        }
        return StrHelper.isSubstring(String(elem[key]), String(value))
      })
    })
  }

  public static filterArrByValue(
    possibleValues: VIRowReadonly,
    value: ValueInterface['value'],
    keys: ReadonlyArray<keyof Pick<ValueInterface, 'name' | 'value' | 'label'>>
  ): VIRow {
    return possibleValues.filter((elem: ValueInterface) => {
      return keys.some((key) => {
        if (elem[key] === undefined) {
          return false
        }
        return StrHelper.isSubstring(String(elem[key]), String(value))
      })
    })
  }

  public static addUniqueByFieldToArr(
    value: ValueInterface,
    arr: VIRowReadonly,
    keys: ReadonlyArray<keyof Pick<ValueInterface, 'name' | 'value' | 'label'>>
  ): VIRow {
    const result = [...arr]
    const found = arr.some((elem: ValueInterface) => {
      return keys.some((key) => {
        if (elem[key] === undefined || value[key] === undefined) {
          return false
        }
        return StrHelper.isSubstring(String(elem[key]), String(value[key]))
      })
    })
    if (!found) {
      result.push(value)
    }
    return result
  }

  public static getArrayOfNumbersFromValue(data: VIRow): Array<number> {
    const numberValues: Array<number> = []
    data.map((item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (key === 'value') {
          numberValues.push(TypeHelper.numberValDef(value, 0))
        }
      })
    })

    return numberValues
  }
}

export default ValueHelper
