import EngineHelper from '@lib/helpers/engine.helper'
import BaseModel from '@lib/models/base-model/base.model'
import TypeHelper from '@lib/helpers/type.helper'

class PaginationModel extends BaseModel {
  public readonly limit: number

  public readonly offset: number

  public readonly total: number

  // extra

  public readonly currentPage: number

  public readonly hasPages: boolean

  public readonly changed: boolean

  constructor(readonly params: any = {}) {
    super(params)

    // base
    this.limit = params.limit
    this.offset = params.offset
    this.total = params.total

    // extra
    this.currentPage = EngineHelper.calcCurrentPaginationPage(
      this.limit,
      this.offset
    )
    this.hasPages = this.total > this.limit
    this.changed = TypeHelper.booleanValDef(params.changed, false)
  }
}

export default PaginationModel
