// took from
// node_modules/blue-stack-libs/rex-grpc-libs/js/rex/rex_pb.d.ts

export enum CfnStatusConstant {
  NOTINSTALLED = 0,
  INSTALLED = 1,
  INSTALLING = 2,
  UPDATEREQUIRED = 3,
  UPDATING = 4,
}
