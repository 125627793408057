"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OvaMspFailedScansReportFormat = exports.OvaMspInfectedFilesReportFormat = exports.OvaMspInfectedAssetsReportFormat = exports.OvaMspScannedAssetsSummaryReportFormat = exports.OvaMspCompletedScansReportFormat = exports.OvaMspAccountSummaryReportFormat = exports.OvaMspTenantSummaryReportFormat = exports.OvaMspOperationalReportFormat = exports.OvaMspExecutiveReportFormat = exports.AwsExecutiveReportFormat = exports.AwsTenantSummaryReportFormat = exports.AwsAccountSummaryReportFormat = exports.AwsCompletedScansReportFormat = exports.AwsScannedAssetsSummaryReportFormat = exports.AwsInfectedAssetsReportFormat = exports.InfectedFilesReportFormat = exports.FailedScansReportFormat = void 0;
var FailedScansReportFormat;
(function (FailedScansReportFormat) {
    FailedScansReportFormat[FailedScansReportFormat["CSV"] = 0] = "CSV";
})(FailedScansReportFormat = exports.FailedScansReportFormat || (exports.FailedScansReportFormat = {}));
var InfectedFilesReportFormat;
(function (InfectedFilesReportFormat) {
    InfectedFilesReportFormat[InfectedFilesReportFormat["CSV"] = 0] = "CSV";
})(InfectedFilesReportFormat = exports.InfectedFilesReportFormat || (exports.InfectedFilesReportFormat = {}));
var AwsInfectedAssetsReportFormat;
(function (AwsInfectedAssetsReportFormat) {
    AwsInfectedAssetsReportFormat[AwsInfectedAssetsReportFormat["CSV"] = 0] = "CSV";
})(AwsInfectedAssetsReportFormat = exports.AwsInfectedAssetsReportFormat || (exports.AwsInfectedAssetsReportFormat = {}));
var AwsScannedAssetsSummaryReportFormat;
(function (AwsScannedAssetsSummaryReportFormat) {
    AwsScannedAssetsSummaryReportFormat[AwsScannedAssetsSummaryReportFormat["CSV"] = 0] = "CSV";
})(AwsScannedAssetsSummaryReportFormat = exports.AwsScannedAssetsSummaryReportFormat || (exports.AwsScannedAssetsSummaryReportFormat = {}));
var AwsCompletedScansReportFormat;
(function (AwsCompletedScansReportFormat) {
    AwsCompletedScansReportFormat[AwsCompletedScansReportFormat["CSV"] = 0] = "CSV";
})(AwsCompletedScansReportFormat = exports.AwsCompletedScansReportFormat || (exports.AwsCompletedScansReportFormat = {}));
var AwsAccountSummaryReportFormat;
(function (AwsAccountSummaryReportFormat) {
    AwsAccountSummaryReportFormat[AwsAccountSummaryReportFormat["PDF"] = 0] = "PDF";
})(AwsAccountSummaryReportFormat = exports.AwsAccountSummaryReportFormat || (exports.AwsAccountSummaryReportFormat = {}));
var AwsTenantSummaryReportFormat;
(function (AwsTenantSummaryReportFormat) {
    AwsTenantSummaryReportFormat[AwsTenantSummaryReportFormat["PDF"] = 0] = "PDF";
})(AwsTenantSummaryReportFormat = exports.AwsTenantSummaryReportFormat || (exports.AwsTenantSummaryReportFormat = {}));
var AwsExecutiveReportFormat;
(function (AwsExecutiveReportFormat) {
    AwsExecutiveReportFormat[AwsExecutiveReportFormat["PDF"] = 0] = "PDF";
})(AwsExecutiveReportFormat = exports.AwsExecutiveReportFormat || (exports.AwsExecutiveReportFormat = {}));
var OvaMspExecutiveReportFormat;
(function (OvaMspExecutiveReportFormat) {
    OvaMspExecutiveReportFormat[OvaMspExecutiveReportFormat["PDF"] = 0] = "PDF";
})(OvaMspExecutiveReportFormat = exports.OvaMspExecutiveReportFormat || (exports.OvaMspExecutiveReportFormat = {}));
var OvaMspOperationalReportFormat;
(function (OvaMspOperationalReportFormat) {
    OvaMspOperationalReportFormat[OvaMspOperationalReportFormat["PDF"] = 0] = "PDF";
    OvaMspOperationalReportFormat[OvaMspOperationalReportFormat["XLSX"] = 1] = "XLSX";
})(OvaMspOperationalReportFormat = exports.OvaMspOperationalReportFormat || (exports.OvaMspOperationalReportFormat = {}));
var OvaMspTenantSummaryReportFormat;
(function (OvaMspTenantSummaryReportFormat) {
    OvaMspTenantSummaryReportFormat[OvaMspTenantSummaryReportFormat["PDF"] = 0] = "PDF";
})(OvaMspTenantSummaryReportFormat = exports.OvaMspTenantSummaryReportFormat || (exports.OvaMspTenantSummaryReportFormat = {}));
var OvaMspAccountSummaryReportFormat;
(function (OvaMspAccountSummaryReportFormat) {
    OvaMspAccountSummaryReportFormat[OvaMspAccountSummaryReportFormat["PDF"] = 0] = "PDF";
})(OvaMspAccountSummaryReportFormat = exports.OvaMspAccountSummaryReportFormat || (exports.OvaMspAccountSummaryReportFormat = {}));
var OvaMspCompletedScansReportFormat;
(function (OvaMspCompletedScansReportFormat) {
    OvaMspCompletedScansReportFormat[OvaMspCompletedScansReportFormat["CSV"] = 0] = "CSV";
})(OvaMspCompletedScansReportFormat = exports.OvaMspCompletedScansReportFormat || (exports.OvaMspCompletedScansReportFormat = {}));
var OvaMspScannedAssetsSummaryReportFormat;
(function (OvaMspScannedAssetsSummaryReportFormat) {
    OvaMspScannedAssetsSummaryReportFormat[OvaMspScannedAssetsSummaryReportFormat["CSV"] = 0] = "CSV";
})(OvaMspScannedAssetsSummaryReportFormat = exports.OvaMspScannedAssetsSummaryReportFormat || (exports.OvaMspScannedAssetsSummaryReportFormat = {}));
var OvaMspInfectedAssetsReportFormat;
(function (OvaMspInfectedAssetsReportFormat) {
    OvaMspInfectedAssetsReportFormat[OvaMspInfectedAssetsReportFormat["CSV"] = 0] = "CSV";
})(OvaMspInfectedAssetsReportFormat = exports.OvaMspInfectedAssetsReportFormat || (exports.OvaMspInfectedAssetsReportFormat = {}));
var OvaMspInfectedFilesReportFormat;
(function (OvaMspInfectedFilesReportFormat) {
    OvaMspInfectedFilesReportFormat[OvaMspInfectedFilesReportFormat["CSV"] = 0] = "CSV";
})(OvaMspInfectedFilesReportFormat = exports.OvaMspInfectedFilesReportFormat || (exports.OvaMspInfectedFilesReportFormat = {}));
var OvaMspFailedScansReportFormat;
(function (OvaMspFailedScansReportFormat) {
    OvaMspFailedScansReportFormat[OvaMspFailedScansReportFormat["CSV"] = 0] = "CSV";
})(OvaMspFailedScansReportFormat = exports.OvaMspFailedScansReportFormat || (exports.OvaMspFailedScansReportFormat = {}));
