/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useRef, useState } from 'react'
import { AssetsClient, ListEC2Filters } from '@lib/clients/assets'
import { EC2 } from 'blues-corejs/dist'
import { ObjHelper } from '@lib/helpers'
import {
  sentryReThrowCatchHandler,
  sentrySimpleReThrowHandler,
} from '@store/epics/epic-func'

const assetsClient = new AssetsClient()

export function useGetEC2List(filters?: ListEC2Filters) {
  const [ec2List, setEc2List] = useState<Array<EC2>>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const previousFilters = useRef<ListEC2Filters | null>(null)

  useEffect(() => {
    async function fetchEC2List() {
      const isFiltersEqual = ObjHelper.isEqual(previousFilters.current, filters)

      if (!filters || isFiltersEqual) {
        return
      }

      try {
        setIsLoading(true)

        const fetchedEC2List = await assetsClient.listEC2(filters)
        setEc2List(fetchedEC2List)

        previousFilters.current = filters
      } catch (error) {
        if (error instanceof Error) {
          sentrySimpleReThrowHandler(error)
        }

        if (typeof error === 'string') {
          sentryReThrowCatchHandler(error)
        }
      } finally {
        setIsLoading(false)
      }
    }

    if (!filters) {
      return
    }

    fetchEC2List()
  }, [filters])

  return {
    ec2List,
    isLoading,
  }
}
