"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMockS3BucketAttributes = exports.S3BucketModel = exports.S3BucketState = void 0;
const threat_status_model_1 = require("./threat-status.model");
var S3BucketState;
(function (S3BucketState) {
    S3BucketState[S3BucketState["STATE_IN_USE"] = 0] = "STATE_IN_USE";
    S3BucketState[S3BucketState["STATE_DELETED"] = 1] = "STATE_DELETED";
    S3BucketState[S3BucketState["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(S3BucketState = exports.S3BucketState || (exports.S3BucketState = {}));
class S3BucketModel extends threat_status_model_1.AssetWithThreat {
    id;
    accountId;
    region;
    cloudConnectorId;
    cloudConnectorAccountId;
    cloudConnectorRegion;
    cloudConnectorAssetId;
    name;
    tags;
    objectLockEnabled;
    publicReadAccess;
    publicWriteAccess;
    objectVersioningEnabled;
    bucketPolicy;
    loggingEnabled;
    createdInAwsAt;
    createdAt;
    updatedAt;
    coveredByPolicies;
    associatedRetentionPolicies;
    coveredByPausedPolicies;
    state;
    objectCount;
    objectSize;
    constructor(attrs) {
        super(attrs);
        this.id = attrs.id;
        this.accountId = attrs.accountId;
        this.region = attrs.region;
        this.cloudConnectorId = attrs.cloudConnectorId;
        this.cloudConnectorAccountId = attrs.cloudConnectorAccountId;
        this.cloudConnectorRegion = attrs.cloudConnectorRegion;
        this.cloudConnectorAssetId = attrs.cloudConnectorAssetId;
        this.name = attrs.name;
        this.tags = attrs.tags;
        this.objectLockEnabled = attrs.objectLockEnabled;
        this.publicReadAccess = attrs.publicReadAccess;
        this.publicWriteAccess = attrs.publicWriteAccess;
        this.objectVersioningEnabled = attrs.objectVersioningEnabled;
        this.bucketPolicy = attrs.bucketPolicy;
        this.loggingEnabled = attrs.loggingEnabled;
        this.createdInAwsAt = attrs.createdInAwsAt;
        this.createdAt = attrs.createdAt;
        this.updatedAt = attrs.updatedAt;
        this.threatStatus = attrs.threatStatus;
        this.coveredByPolicies = attrs.coveredByPolicies;
        this.associatedRetentionPolicies = attrs.associatedRetentionPolicies;
        this.coveredByPausedPolicies = attrs.coveredByPausedPolicies;
        this.state = attrs.state;
        this.objectCount = attrs.objectCount;
        this.objectSize = attrs.objectSize;
    }
    getId() {
        return this.id;
    }
    getAccountId() {
        return this.accountId;
    }
    getRegion() {
        return this.region;
    }
    getCloudConnectorId() {
        return this.cloudConnectorId;
    }
    getCloudConnectorAccountId() {
        return this.cloudConnectorAccountId;
    }
    getCloudConnectorRegion() {
        return this.cloudConnectorRegion;
    }
    getCloudConnectorAssetId() {
        return this.cloudConnectorAssetId;
    }
    getName() {
        return this.name;
    }
    getTags() {
        return Array.from(this.tags);
    }
    getTagsMap() {
        return this.tags;
    }
    getObjectLockEnabled() {
        return this.objectLockEnabled;
    }
    getPublicReadAccess() {
        return this.publicReadAccess;
    }
    getPublicWriteAccess() {
        return this.publicWriteAccess;
    }
    getObjectVersioningEnabled() {
        return this.objectVersioningEnabled;
    }
    getBucketPolicy() {
        return this.bucketPolicy;
    }
    getLoggingEnabled() {
        return this.loggingEnabled;
    }
    getCreatedInAwsAt() {
        return this.createdInAwsAt;
    }
    getCreatedAt() {
        return this.createdAt;
    }
    getUpdatedAt() {
        return this.updatedAt;
    }
    getCoveredByPolicies() {
        return this.coveredByPolicies;
    }
    isCoveredByPolicy() {
        return this.coveredByPolicies.length > 0;
    }
    getAssociatedRetentionPolicies() {
        return this.associatedRetentionPolicies;
    }
    getParsedCloudProviderAssetId() {
        return (this.cloudConnectorAssetId?.split(':')?.pop() ??
            this.cloudConnectorAssetId ??
            '');
    }
    isProtected() {
        return this.isLastRecoveryPointId() && this.isCoveredByPolicy();
    }
    getSize() {
        return '';
    }
    getVpcId() {
        return '';
    }
    isCoveredByPausedPolicies() {
        return this.coveredByPausedPolicies.length > 0;
    }
    getVolumeType() {
        return '';
    }
    getCloudProviderAssetId() {
        return this.getCloudConnectorAssetId();
    }
    getState() {
        return this.state;
    }
    getObjectCount() {
        return this.objectCount;
    }
    getObjectSize() {
        return this.objectSize;
    }
}
exports.S3BucketModel = S3BucketModel;
function createMockS3BucketAttributes(currentId) {
    const region = ['us-east-1', 'us-east-2', 'eu-central-1', 'ap-southeast-1'][Math.floor((Math.random() * 10) % 4)] || 'us-east-1';
    const account = (Math.random() + 1).toString(10).substring(6);
    const s3BucketName = 's3-bucket-' + currentId.toString();
    const policy = 'Policy' + Math.floor(Math.random() * 9).toString();
    const retentionPolicy = 'RetentionPolicy' + Math.floor(Math.random() * 9).toString();
    const pausedPolicy = 'PausedPolicy' + Math.floor(Math.random() * 9).toString();
    return {
        id: 's3-bucket-' + currentId.toString(),
        accountId: (Math.random() + 1).toString(10).substring(6),
        region: region,
        cloudConnectorId: (Math.random() + 1).toString(10).substring(6),
        cloudConnectorAccountId: (Math.random() + 1).toString(10).substring(6),
        cloudConnectorRegion: region,
        cloudConnectorAssetId: `elastio:asset:aws-s3:s:${account}:${region}:${s3BucketName}`,
        name: s3BucketName,
        tags: new Map(),
        objectLockEnabled: null,
        publicReadAccess: null,
        publicWriteAccess: null,
        objectVersioningEnabled: null,
        bucketPolicy: null,
        loggingEnabled: null,
        createdInAwsAt: Math.floor(Math.random() * 100000),
        createdAt: Math.floor(Math.random() * 100000),
        updatedAt: Math.floor(Math.random() * 100000),
        threatStatus: null,
        coveredByPolicies: [policy],
        associatedRetentionPolicies: [retentionPolicy],
        coveredByPausedPolicies: [pausedPolicy],
        state: Math.random() < 0.5 ? 1 : 0,
        objectCount: Math.floor(Math.random() * 1000000),
        objectSize: Math.floor(Math.random() * 100000000000),
    };
}
exports.createMockS3BucketAttributes = createMockS3BucketAttributes;
