import React from 'react'
import LinkMUI from '@mui/material/Link'
import { CellWithButtonLinkOwnProps } from './types'
import { EmptyFunc } from '@lib/constants/app.constant'
import styles from './with-button-link.module.css'

function CellWithButtonLink({
  children,
  onClick = EmptyFunc,
}: CellWithButtonLinkOwnProps) {
  const handleOnClick = () => {
    onClick()
  }

  return (
    <LinkMUI
      underline="none"
      className={styles.root}
      component="button"
      variant="body2"
      onClick={handleOnClick}
    >
      {children}
    </LinkMUI>
  )
}
export default CellWithButtonLink
