import BaseModel from '@lib/models/base-model/base.model'

export class AwsSecurityGroup {
  public readonly groupId: string

  public readonly groupName: string

  constructor(readonly params: any) {
    this.groupId = params.groupId
    this.groupName = params.groupName
  }
}

class AwsSecurityGroupsModel extends BaseModel {
  vpcId: string

  securityGroupsList: Array<AwsSecurityGroup>

  constructor(readonly params: any = {}) {
    super(params)

    this.vpcId = params.vpcId
    this.securityGroupsList = params.securityGroupsList
  }
}

export default AwsSecurityGroupsModel
