"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OvaMspOperationalReportFormat = exports.OvaMspExecutiveReportFormat = exports.AwsExecutiveReportFormat = exports.AwsTenantSummaryReportFormat = exports.AwsAccountSummaryReportFormat = exports.AwsCompletedScansReportFormat = exports.AwsScannedAssetsSummaryReportFormat = exports.AwsInfectedAssetsReportFormat = exports.InfectedFilesReportFormat = exports.FailedScansReportFormat = exports.Schedule = exports.ReportSchedule = void 0;
var report_schedule_1 = require("./report-schedule");
Object.defineProperty(exports, "ReportSchedule", { enumerable: true, get: function () { return report_schedule_1.ReportSchedule; } });
var schedule_1 = require("./schedule");
Object.defineProperty(exports, "Schedule", { enumerable: true, get: function () { return schedule_1.Schedule; } });
var report_formats_1 = require("./report-formats");
Object.defineProperty(exports, "FailedScansReportFormat", { enumerable: true, get: function () { return report_formats_1.FailedScansReportFormat; } });
Object.defineProperty(exports, "InfectedFilesReportFormat", { enumerable: true, get: function () { return report_formats_1.InfectedFilesReportFormat; } });
Object.defineProperty(exports, "AwsInfectedAssetsReportFormat", { enumerable: true, get: function () { return report_formats_1.AwsInfectedAssetsReportFormat; } });
Object.defineProperty(exports, "AwsScannedAssetsSummaryReportFormat", { enumerable: true, get: function () { return report_formats_1.AwsScannedAssetsSummaryReportFormat; } });
Object.defineProperty(exports, "AwsCompletedScansReportFormat", { enumerable: true, get: function () { return report_formats_1.AwsCompletedScansReportFormat; } });
Object.defineProperty(exports, "AwsAccountSummaryReportFormat", { enumerable: true, get: function () { return report_formats_1.AwsAccountSummaryReportFormat; } });
Object.defineProperty(exports, "AwsTenantSummaryReportFormat", { enumerable: true, get: function () { return report_formats_1.AwsTenantSummaryReportFormat; } });
Object.defineProperty(exports, "AwsExecutiveReportFormat", { enumerable: true, get: function () { return report_formats_1.AwsExecutiveReportFormat; } });
Object.defineProperty(exports, "OvaMspExecutiveReportFormat", { enumerable: true, get: function () { return report_formats_1.OvaMspExecutiveReportFormat; } });
Object.defineProperty(exports, "OvaMspOperationalReportFormat", { enumerable: true, get: function () { return report_formats_1.OvaMspOperationalReportFormat; } });
