import { NotificationsConfig } from './../../lib/interfaces/notifications.interface'
import UserNotificationsState from '@store/states/user-notifications.state'

export const REQUEST_USER_NOTIFICATIONS_SETTINGS =
  'REQUEST_USER_NOTIFICATIONS_SETTINGS'
export const SET_USER_NOTIFICATIONS_SETTINGS = 'SET_USER_NOTIFICATIONS_SETTINGS'
export const UPDATE_USER_NOTIFICATIONS_SETTINGS =
  'UPDATE_USER_NOTIFICATIONS_SETTINGS'
export const REQUEST_USER_NOTIFICATIONS_SETTINGS_SHADOW =
  'REQUEST_USER_NOTIFICATIONS_SETTINGS_SHADOW'

export const DISCONNECT_SLACK_FROM_PROFILE = 'DISCONNECT_SLACK_FROM_PROFILE'

export const requestUserNotificationsSettings = () => ({
  type: REQUEST_USER_NOTIFICATIONS_SETTINGS,
  payload: null,
})

export const updateUserNotificationsSettings = (
  config: NotificationsConfig
) => ({
  type: UPDATE_USER_NOTIFICATIONS_SETTINGS,
  payload: config,
})

export const setUserNotificationsSettings = (
  config: UserNotificationsState
) => ({
  type: SET_USER_NOTIFICATIONS_SETTINGS,
  payload: config,
})

export const requestUserNotificationsSettingsShadow = () => ({
  type: REQUEST_USER_NOTIFICATIONS_SETTINGS_SHADOW,
  payload: null,
})

export const requestDisconnectSlackFromProfile = () => ({
  type: DISCONNECT_SLACK_FROM_PROFILE,
  payload: null,
})
