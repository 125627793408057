import SystemHelper from '@lib/helpers/system.helper'
import { NullLogger } from '@features/scheduled-jobs-monitoring/infrastructure/logging/null-logger'

function createLogger() {
    if (SystemHelper.isProductionEnv()) {
        return new NullLogger()
    }
    return console
}

export { createLogger }
