import VpcModel, { Subnet } from '@lib/models/vpc.model'
import NumHelper from '@lib/helpers/num.helper'
import TypeHelper from '@lib/helpers/type.helper'
import ValueInterface from '@lib/interfaces/value.interface'
import LangHelper from '@lib/helpers/lang.helper'

abstract class VpcFactory {
  public static buildFromGrpc(params: any): VpcModel {
    return new VpcModel({
      id: NumHelper.numberHash(params.id),
      innerId: TypeHelper.stringValDef(params.id, ''),
      isDefault: TypeHelper.booleanValDef(params.isDefault, false),
      accountId: TypeHelper.stringValDef(params.accountId, ''),
      region: {
        name: params.regionName,
        label: LangHelper.getAwsRegionSingleTranslation(params.regionName),
      },
      subnetsList: VpcFactory.buildSubnets(params.subnetsList),
      hasPublicSubnets: TypeHelper.booleanValDef(
        params.hasPublicSubnets,
        false
      ),
      tagsList: params.tagsList,
    })
  }

  public static fromModelToVi(model: VpcModel): ValueInterface {
    return {
      name: model.innerId,
      label: model.vpcName !== '' ? model.vpcName : undefined,
    }
  }

  static buildSubnets(params: any): Array<Subnet> {
    const subnets: Array<Subnet> = params.map((subnet: any) => {
      return new Subnet({
        id: subnet.id,
        name: TypeHelper.stringValDef(subnet.name, ''),
        isPublic: TypeHelper.booleanValDef(subnet.isPublic, false),
      })
    })

    return subnets
  }

  public static fromSubnetModelToVi(model: Subnet): ValueInterface {
    const subnetNameLabel = model.name ? `${model.name} ` : ''
    const subnetIdLabel = model.name ? `(${model.id})` : model.id
    const subnetLabel = subnetNameLabel + subnetIdLabel

    return {
      name: model.id,
      label: subnetLabel,
      extraValue: model.isPublic,
    }
  }

  public static fromSubnetModelAndVpcNameToVi(
    model: Subnet,
    vpcName: string,
    regionName: string
  ): ValueInterface {
    return {
      name: model.id,
      label: model.labelName,
      extraValue: model.isPublic,
      defaultValue: vpcName,
      placeholder: regionName,
    }
  }
}

export default VpcFactory
