"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAssetFiltersForPolicyCoverage = void 0;
const types_1 = require("../../models/assets/aws/ebs/types");
const types_2 = require("./types");
class GetAssetFiltersForPolicyCoverage {
    #instances;
    #volumes;
    #s3Buckets;
    #efs;
    #instancesWithRelatedAssets;
    #volumesWithRelatedAssets;
    #s3BucketsWithRelatedAssets;
    #efsWithRelatedAssets;
    constructor(parameters) {
        this.#instances = parameters.instances;
        this.#volumes = parameters.volumes;
        this.#s3Buckets = parameters.s3Buckets;
        this.#efs = parameters.efs;
        this.#instancesWithRelatedAssets ||= this.#getInstancesWithRelatedAssets();
        this.#volumesWithRelatedAssets ||= this.#getVolumesWithRelatedAssets();
        this.#s3BucketsWithRelatedAssets ||= this.#getS3BucketsWithRelatedAssets();
        this.#efsWithRelatedAssets ||= this.#getEfsWithRelatedAssets();
    }
    execute() {
        const accountIds = new Set();
        const regions = new Set();
        const instanceIds = new Set();
        const instanceTypes = new Set();
        const volumeIds = new Set();
        const volumeTypes = new Set();
        const vpcIds = new Set();
        const tags = new Set();
        const assetList = [
            ...this.#instancesWithRelatedAssets,
            ...this.#volumesWithRelatedAssets,
            ...this.#s3BucketsWithRelatedAssets,
            ...this.#efsWithRelatedAssets,
        ];
        assetList.forEach(({ asset }) => {
            for (const { key, value } of asset.tags) {
                const tag = `${key}=${value}`;
                tags.add(tag);
            }
            accountIds.add(asset.awsAccountId);
            regions.add(asset.awsRegion);
        });
        this.#instancesWithRelatedAssets.forEach(({ asset }) => {
            instanceIds.add(asset.awsId);
            vpcIds.add(asset.awsVpcId);
            if (asset.type) {
                instanceTypes.add(this.#getEc2TypeString(asset.type));
            }
        });
        this.#volumesWithRelatedAssets.forEach(({ asset }) => {
            volumeIds.add(asset.awsId);
            volumeTypes.add(this.#convertEbsTypeToString(asset.type));
        });
        return {
            accountIds: [...accountIds],
            regions: [...regions],
            instanceIds: [...instanceIds],
            instanceTypes: [...instanceTypes],
            volumeIds: [...volumeIds],
            volumeTypes: [...volumeTypes],
            vpcIds: [...vpcIds],
            tags: [...tags],
            isProtected: [true, false],
            isPausedInstances: [true, false],
        };
    }
    #getInstancesWithRelatedAssets() {
        const volumeByIdMap = this.#volumes.reduce((map, volume) => {
            map.set(volume.id, volume);
            return map;
        }, new Map());
        return this.#instances.map((instance) => {
            const relatedVolumes = instance.ebsIds
                .map((id) => volumeByIdMap.get(id))
                .filter(Boolean);
            return {
                asset: instance,
                relatedAssets: relatedVolumes,
                type: types_2.AssetKind.AWS_EC2,
            };
        });
    }
    #getVolumesWithRelatedAssets() {
        const instanceByIdMap = this.#instances.reduce((map, instance) => {
            map.set(instance.id, instance);
            return map;
        }, new Map());
        return this.#volumes.map((volume) => {
            const relatedInstances = volume.attachedInstanceIds
                .map((id) => instanceByIdMap.get(id))
                .filter(Boolean);
            return {
                asset: volume,
                relatedAssets: relatedInstances,
                type: types_2.AssetKind.AWS_EBS,
            };
        });
    }
    #getS3BucketsWithRelatedAssets() {
        return this.#s3Buckets.map((s3Bucket) => {
            return {
                asset: s3Bucket,
                relatedAssets: [],
                type: types_2.AssetKind.AWS_S3,
            };
        });
    }
    #getEfsWithRelatedAssets() {
        return this.#efs.map((efs) => {
            return {
                asset: efs,
                relatedAssets: [],
                type: types_2.AssetKind.AWS_EFS,
            };
        });
    }
    #convertEbsTypeToString(type) {
        switch (type) {
            case types_1.EBSType.STANDARD:
                return types_2.EBSTypeString.STANDARD;
            case types_1.EBSType.GP2:
                return types_2.EBSTypeString.GP2;
            case types_1.EBSType.GP3:
                return types_2.EBSTypeString.GP3;
            case types_1.EBSType.IO1:
                return types_2.EBSTypeString.IO1;
            case types_1.EBSType.IO2:
                return types_2.EBSTypeString.IO2;
            case types_1.EBSType.SC1:
                return types_2.EBSTypeString.SC1;
            case types_1.EBSType.ST1:
                return types_2.EBSTypeString.ST1;
            default:
                return '';
        }
    }
    #getEc2TypeString(type) {
        return `${type.family}.${type.size}`;
    }
}
exports.GetAssetFiltersForPolicyCoverage = GetAssetFiltersForPolicyCoverage;
