"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantJobKind = exports.TenantJobStatus = void 0;
var TenantJobStatus;
(function (TenantJobStatus) {
    TenantJobStatus[TenantJobStatus["SCHEDULED"] = 0] = "SCHEDULED";
    TenantJobStatus[TenantJobStatus["STARTING"] = 1] = "STARTING";
    TenantJobStatus[TenantJobStatus["INPROGRESS"] = 2] = "INPROGRESS";
    TenantJobStatus[TenantJobStatus["FAILED"] = 3] = "FAILED";
    TenantJobStatus[TenantJobStatus["SUCCEEDED"] = 4] = "SUCCEEDED";
    TenantJobStatus[TenantJobStatus["ABORTED"] = 5] = "ABORTED";
})(TenantJobStatus = exports.TenantJobStatus || (exports.TenantJobStatus = {}));
var TenantJobKind;
(function (TenantJobKind) {
    TenantJobKind[TenantJobKind["INSTALL_CLOUD_CONNECTOR"] = 0] = "INSTALL_CLOUD_CONNECTOR";
    TenantJobKind[TenantJobKind["CREATE_VAULT"] = 1] = "CREATE_VAULT";
    TenantJobKind[TenantJobKind["DESTROY_VAULT"] = 2] = "DESTROY_VAULT";
    TenantJobKind[TenantJobKind["REFRESH_PLANS"] = 3] = "REFRESH_PLANS";
    TenantJobKind[TenantJobKind["SET_PLAN_STATUS"] = 4] = "SET_PLAN_STATUS";
    TenantJobKind[TenantJobKind["RESYNC"] = 5] = "RESYNC";
    TenantJobKind[TenantJobKind["ACTIVATE_CLOUD_CONNECTOR"] = 6] = "ACTIVATE_CLOUD_CONNECTOR";
    TenantJobKind[TenantJobKind["ANALYZE_CLOUD_CONNECTOR_TARGET_REGION"] = 7] = "ANALYZE_CLOUD_CONNECTOR_TARGET_REGION";
    TenantJobKind[TenantJobKind["EVALUATE_PLANS"] = 8] = "EVALUATE_PLANS";
    TenantJobKind[TenantJobKind["SCAN_INVENTORY"] = 9] = "SCAN_INVENTORY";
    TenantJobKind[TenantJobKind["ENABLE_ELASTIO_REPORTS_GATHERING"] = 10] = "ENABLE_ELASTIO_REPORTS_GATHERING";
    TenantJobKind[TenantJobKind["DISABLE_ELASTIO_REPORTS_GATHERING"] = 11] = "DISABLE_ELASTIO_REPORTS_GATHERING";
    TenantJobKind[TenantJobKind["UPGRADE_CLOUD_CONNECTOR"] = 12] = "UPGRADE_CLOUD_CONNECTOR";
    TenantJobKind[TenantJobKind["UPGRADE_VAULT"] = 13] = "UPGRADE_VAULT";
    TenantJobKind[TenantJobKind["REPAIR_REPORT_GATHERING_INFRA"] = 14] = "REPAIR_REPORT_GATHERING_INFRA";
    TenantJobKind[TenantJobKind["CLEANUP_RED_STACK_DATA"] = 15] = "CLEANUP_RED_STACK_DATA";
    TenantJobKind[TenantJobKind["REPAIR_CLOUD_CONNECTOR"] = 16] = "REPAIR_CLOUD_CONNECTOR";
    TenantJobKind[TenantJobKind["REPAIR_VAULT"] = 17] = "REPAIR_VAULT";
    TenantJobKind[TenantJobKind["UPDATE_VAULT_SUBNETS"] = 18] = "UPDATE_VAULT_SUBNETS";
    TenantJobKind[TenantJobKind["CLOUD_CONNECTOR_INTEGRITY_CHECK"] = 19] = "CLOUD_CONNECTOR_INTEGRITY_CHECK";
    TenantJobKind[TenantJobKind["CLOUD_CONNECTOR_INFRASTRUCTURE_CHECK"] = 20] = "CLOUD_CONNECTOR_INFRASTRUCTURE_CHECK";
    TenantJobKind[TenantJobKind["VAULT_INFRASTRUCTURE_CHECK"] = 21] = "VAULT_INFRASTRUCTURE_CHECK";
    TenantJobKind[TenantJobKind["VAULT_NETWORKING_CHECK"] = 22] = "VAULT_NETWORKING_CHECK";
    TenantJobKind[TenantJobKind["EXECUTE_BACKUP_POLICY"] = 23] = "EXECUTE_BACKUP_POLICY";
    TenantJobKind[TenantJobKind["CLEANUP_BACKUP_POLICY_COVERAGE"] = 24] = "CLEANUP_BACKUP_POLICY_COVERAGE";
    TenantJobKind[TenantJobKind["REFRESH_CONNECTOR_RETENTION_POLICIES"] = 25] = "REFRESH_CONNECTOR_RETENTION_POLICIES";
    TenantJobKind[TenantJobKind["EVALUATE_RETENTION_POLICIES"] = 26] = "EVALUATE_RETENTION_POLICIES";
    TenantJobKind[TenantJobKind["UPDATE_VAULT"] = 27] = "UPDATE_VAULT";
    TenantJobKind[TenantJobKind["EVALUATE_ASSETS_INTEGRITY_STATUS"] = 28] = "EVALUATE_ASSETS_INTEGRITY_STATUS";
    TenantJobKind[TenantJobKind["REFRESH_ASSET_THREAT_STATUSES"] = 29] = "REFRESH_ASSET_THREAT_STATUSES";
    TenantJobKind[TenantJobKind["GENERATE_SCHEDULED_REPORT"] = 30] = "GENERATE_SCHEDULED_REPORT";
    TenantJobKind[TenantJobKind["DELETE_CLOUD_CONNECTOR"] = 31] = "DELETE_CLOUD_CONNECTOR";
})(TenantJobKind = exports.TenantJobKind || (exports.TenantJobKind = {}));
