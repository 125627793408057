import React from 'react'

interface Props {
  fill?: string
}

function TabTableIcon({ fill = '#2F3745' }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_1">
        <g id="Group">
          <g id="Group_2">
            <g id="Group_3">
              <path
                id="Vector"
                d="M23.5219 2.69531H0.478088C0.214088 2.69531 0 2.90935 0 3.1734V20.8268C0 21.0908 0.214088 21.3049 0.478088 21.3049H23.5219C23.7859 21.3049 24 21.0908 24 20.8268V3.1734C24 2.90935 23.7859 2.69531 23.5219 2.69531ZM5.76096 20.3487H0.956175V17.772H5.76096V20.3487ZM5.76096 16.8158H0.956175V14.2391H5.76096V16.8158ZM5.76096 13.2829H0.956175V10.7062H5.76096V13.2829ZM5.76096 9.74997H0.956175V7.18436H5.76096V9.74997ZM11.5219 20.3487H6.71713V17.772H11.5219V20.3487ZM11.5219 16.8158H6.71713V14.2391H11.5219V16.8158ZM11.5219 13.2829H6.71713V10.7062H11.5219V13.2829ZM11.5219 9.74997H6.71713V7.18436H11.5219V9.74997ZM17.2829 20.3487H12.4781V17.772H17.2829V20.3487ZM17.2829 16.8158H12.4781V14.2391H17.2829V16.8158ZM17.2829 13.2829H12.4781V10.7062H17.2829V13.2829ZM17.2829 9.74997H12.4781V7.18436H17.2829V9.74997ZM23.0438 20.3487H18.239V17.772H23.0438V20.3487ZM23.0438 16.8158H18.239V14.2391H23.0438V16.8158ZM23.0438 13.2829H18.239V10.7062H23.0438V13.2829ZM23.0438 9.75002H18.239V7.18441H23.0438V9.75002ZM23.0438 6.2171H0.956175V3.65149H23.0438V6.2171Z"
                fill={fill}
              />
              <path
                id="Vector_2"
                d="M9.99225 5.14368H19.1715C19.4355 5.14368 19.6496 4.92964 19.6496 4.66559C19.6496 4.40154 19.4355 4.1875 19.1715 4.1875H9.99225C9.72825 4.1875 9.51416 4.40154 9.51416 4.66559C9.51416 4.92964 9.72825 5.14368 9.99225 5.14368Z"
                fill={fill}
              />
              <path
                id="Vector_3"
                d="M20.8448 5.14368H21.8966C22.1606 5.14368 22.3747 4.92964 22.3747 4.66559C22.3747 4.40154 22.1606 4.1875 21.8966 4.1875H20.8448C20.5808 4.1875 20.3667 4.40154 20.3667 4.66559C20.3667 4.92964 20.5808 5.14368 20.8448 5.14368Z"
                fill={fill}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default TabTableIcon
