/* eslint-disable import/no-extraneous-dependencies */
import { Request as GrpcListTenantJobsRequest } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/jobs/list_tenant_jobs_pb'
import { Job as JobPt } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/jobs/tenant/job_pb'

import {
  TenantJobKind,
  TenantJobStatus,
} from 'blues-corejs/dist/models/jobs/tenant/job/types'
import { TimeRange } from '@lib/clients/types'
import { ListTenantJobsRequest } from '@lib/clients/jobs/tenant-jobs'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { EnumHelper } from '@lib/helpers'

export interface ListTenantJobsFilterRequestParams {
  kindsList?: Array<TenantJobKind>
  statusesList?: Array<TenantJobStatus>
  jobIdsList?: Array<string>
  ccIdsList?: Array<string>
  timeRange?: TimeRange
  pageSize?: number
}

const DEFAULT_PAGE_SIZE = 25

export class ListTenantJobsFilter implements ListTenantJobsRequest {
  #requestParams?: ListTenantJobsFilterRequestParams

  constructor(filterParams?: ListTenantJobsFilterRequestParams) {
    this.#requestParams = filterParams
  }

  toGrpc(): GrpcListTenantJobsRequest {
    const grpcRequest = GrpcListTenantJobsRequest

    const initial = this.#setInitial()

    if (!this.#requestParams) {
      return new grpcRequest().setInitial(initial)
    }

    const filter = new grpcRequest.Initial.Filter()

    const { kindsList, timeRange, statusesList, jobIdsList } =
      this.#requestParams

    if (timeRange) {
      const timeRangeProto = this.#setupTimeRange({
        start: timeRange.start as number,
        end: timeRange.end as number,
      })

      filter.setTimeRange(timeRangeProto)
    }

    if (statusesList) {
      const statusesProtoList: Array<JobPt.Status> = []

      for (const status of statusesList) {
        statusesProtoList.push(this.#setJobStatus(status))
      }

      filter.setStatusesList(statusesProtoList)
    }

    if (kindsList) {
      const kindsProtoList: Array<JobPt.Kind> = []

      for (const kind of kindsList) {
        kindsProtoList.push(this.#setJobKind(kind))
      }

      filter.setKindsList(kindsProtoList)
    }

    if (jobIdsList) {
      filter.setJobIdsList(jobIdsList)
    }

    return new grpcRequest().setInitial(
      initial
        .setFilter(filter)
        .setPageSize(this.#requestParams?.pageSize || DEFAULT_PAGE_SIZE)
    )
  }

  #setInitial() {
    return new GrpcListTenantJobsRequest.Initial().setPageSize(
      this.#requestParams?.pageSize || DEFAULT_PAGE_SIZE
    )
  }

  #setupTimeRange({ start, end }: { start: number; end: number }) {
    const timeRange = new GrpcListTenantJobsRequest.Initial.Filter.TimeRange()
    if (start) {
      timeRange.setStart(this.#createTimestamp(start))
    }

    if (end) {
      timeRange.setEnd(this.#createTimestamp(end))
    }
    return timeRange
  }

  #createTimestamp(seconds: number) {
    const timestamp = new Timestamp()
    timestamp.setSeconds(seconds)
    return timestamp
  }

  #setJobKind(kind: TenantJobKind) {
    const ProtobufJobKind = JobPt.Kind

    const matchingKind = EnumHelper.getNumericEnumValues(ProtobufJobKind).find(
      (protobufKind) => protobufKind === kind
    )

    if (matchingKind === undefined) {
      throw new Error(`ListTenantJobsFilter: Invalid job kind: ${kind}`)
    }

    return matchingKind
  }

  #setJobStatus(status: TenantJobStatus): JobPt.Status {
    const ProtobufJobStatus = JobPt.Status

    const matchingStatus = EnumHelper.getNumericEnumValues(
      ProtobufJobStatus
    ).find((protobufStatus) => protobufStatus === status)

    if (matchingStatus === undefined) {
      throw new Error(`ListTenantJobsFilter: Invalid job status: ${status}`)
    }

    return matchingStatus
  }
}
