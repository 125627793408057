"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckStatus = exports.StorageTier = exports.EBSSnapshotStatus = void 0;
var EBSSnapshotStatus;
(function (EBSSnapshotStatus) {
    EBSSnapshotStatus[EBSSnapshotStatus["PENDING"] = 0] = "PENDING";
    EBSSnapshotStatus[EBSSnapshotStatus["COMPLETED"] = 1] = "COMPLETED";
    EBSSnapshotStatus[EBSSnapshotStatus["ERROR"] = 2] = "ERROR";
})(EBSSnapshotStatus = exports.EBSSnapshotStatus || (exports.EBSSnapshotStatus = {}));
var StorageTier;
(function (StorageTier) {
    StorageTier[StorageTier["STANDARD"] = 0] = "STANDARD";
    StorageTier[StorageTier["ARCHIVE"] = 1] = "ARCHIVE";
})(StorageTier = exports.StorageTier || (exports.StorageTier = {}));
var CheckStatus;
(function (CheckStatus) {
    CheckStatus[CheckStatus["UNCHECKED"] = 0] = "UNCHECKED";
    CheckStatus[CheckStatus["GOOD"] = 1] = "GOOD";
    CheckStatus[CheckStatus["BAD"] = 2] = "BAD";
})(CheckStatus = exports.CheckStatus || (exports.CheckStatus = {}));
