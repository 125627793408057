import React from 'react'

function MenuIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11014_214800)">
        <path
          d="M26 13H24.6666C24.1144 13 23.6666 13.4477 23.6666 14V15.3333C23.6666 15.8856 24.1144 16.3333 24.6666 16.3333H26C26.5522 16.3333 27 15.8856 27 15.3333V14C27 13.4477 26.5522 13 26 13Z"
          fill="white"
        />
        <path
          d="M20.6667 13H19.3334C18.7811 13 18.3334 13.4477 18.3334 14V15.3333C18.3334 15.8856 18.7811 16.3333 19.3334 16.3333H20.6667C21.219 16.3333 21.6667 15.8856 21.6667 15.3333V14C21.6667 13.4477 21.219 13 20.6667 13Z"
          fill="white"
        />
        <path
          d="M15.3333 13H14C13.4477 13 13 13.4477 13 14V15.3333C13 15.8856 13.4477 16.3333 14 16.3333H15.3333C15.8856 16.3333 16.3333 15.8856 16.3333 15.3333V14C16.3333 13.4477 15.8856 13 15.3333 13Z"
          fill="white"
        />
        <path
          d="M26 23.666H24.6666C24.1144 23.666 23.6666 24.1137 23.6666 24.666V25.9993C23.6666 26.5516 24.1144 26.9993 24.6666 26.9993H26C26.5522 26.9993 27 26.5516 27 25.9993V24.666C27 24.1137 26.5522 23.666 26 23.666Z"
          fill="white"
        />
        <path
          d="M20.6667 23.666H19.3334C18.7811 23.666 18.3334 24.1137 18.3334 24.666V25.9993C18.3334 26.5516 18.7811 26.9993 19.3334 26.9993H20.6667C21.219 26.9993 21.6667 26.5516 21.6667 25.9993V24.666C21.6667 24.1137 21.219 23.666 20.6667 23.666Z"
          fill="white"
        />
        <path
          d="M15.3333 23.666H14C13.4477 23.666 13 24.1137 13 24.666V25.9993C13 26.5516 13.4477 26.9993 14 26.9993H15.3333C15.8856 26.9993 16.3333 26.5516 16.3333 25.9993V24.666C16.3333 24.1137 15.8856 23.666 15.3333 23.666Z"
          fill="white"
        />
        <path
          d="M26 18.334H24.6666C24.1144 18.334 23.6666 18.7817 23.6666 19.334V20.6673C23.6666 21.2196 24.1144 21.6673 24.6666 21.6673H26C26.5522 21.6673 27 21.2196 27 20.6673V19.334C27 18.7817 26.5522 18.334 26 18.334Z"
          fill="white"
        />
        <path
          d="M20.6667 18.334H19.3334C18.7811 18.334 18.3334 18.7817 18.3334 19.334V20.6673C18.3334 21.2196 18.7811 21.6673 19.3334 21.6673H20.6667C21.219 21.6673 21.6667 21.2196 21.6667 20.6673V19.334C21.6667 18.7817 21.219 18.334 20.6667 18.334Z"
          fill="white"
        />
        <path
          d="M15.3333 18.334H14C13.4477 18.334 13 18.7817 13 19.334V20.6673C13 21.2196 13.4477 21.6673 14 21.6673H15.3333C15.8856 21.6673 16.3333 21.2196 16.3333 20.6673V19.334C16.3333 18.7817 15.8856 18.334 15.3333 18.334Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11014_214800">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MenuIcon
