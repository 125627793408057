import BaseDataConstant from '@lib/constants/data/base-data.constant'
import ValueInterface from '@lib/interfaces/value.interface'

class DurationConstantInner extends BaseDataConstant<ValueInterface> {
  get DURATION_UNDEFINED(): ValueInterface {
    return {
      name: 'Undefined',
      value: -1,
    }
  }

  get DURATION_HOURS(): ValueInterface {
    return {
      name: 'Hour/s',
      value: 0,
    }
  }

  get DURATION_DAYS(): ValueInterface {
    return {
      name: 'Day/s',
      value: 1,
    }
  }

  get DURATION_WEEKS(): ValueInterface {
    return {
      name: 'Week/s',
      value: 2,
    }
  }

  get DURATION_MONTHS(): ValueInterface {
    return {
      name: 'Month/s',
      value: 3,
    }
  }

  get DURATION_YEARS(): ValueInterface {
    return {
      name: 'Year/s',
      value: 4,
    }
  }

  get DURATION_INFINITE(): ValueInterface {
    return {
      name: 'Infinite',
      value: 5,
    }
  }
}

const DurationConstant = DurationConstantInner.build(
  DurationConstantInner,
  'DURATION'
)

export default DurationConstant
