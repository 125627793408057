"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SnapshotModel = exports.SnapshotVulnerabilitiesTypeNameV2 = void 0;
const index_1 = require("../index");
var SnapshotVulnerabilitiesTypeNameV2;
(function (SnapshotVulnerabilitiesTypeNameV2) {
    SnapshotVulnerabilitiesTypeNameV2["UNENCRYPTED"] = "Unencrypted";
    SnapshotVulnerabilitiesTypeNameV2["PUBLIC_PERMISSIONS"] = "w. Public Permissions";
    SnapshotVulnerabilitiesTypeNameV2["OLDER_THAN_30_DAYS"] = "Older than 30 days";
})(SnapshotVulnerabilitiesTypeNameV2 = exports.SnapshotVulnerabilitiesTypeNameV2 || (exports.SnapshotVulnerabilitiesTypeNameV2 = {}));
class SnapshotModel {
    id;
    name;
    volumeId;
    cloudProviderVolumeId;
    accountId;
    region;
    cost;
    inventorySize;
    cloudConnectorId;
    cloudProviderSnapshotId;
    storageType;
    createdAt;
    encrypted;
    publicPermissions;
    tags;
    constructor(attrs) {
        this.id = attrs.id;
        this.name = attrs.name;
        this.accountId = attrs.accountId;
        this.volumeId = attrs.volumeId;
        this.cloudProviderVolumeId = attrs.cloudProviderVolumeId;
        this.region = attrs.region;
        this.cost = attrs.cost;
        this.inventorySize = attrs.inventorySize;
        this.cloudConnectorId = attrs.cloudConnectorId;
        this.cloudProviderSnapshotId = attrs.cloudProviderSnapshotId;
        this.storageType = attrs.storageType;
        this.createdAt = attrs.createdAt;
        this.encrypted = attrs.encrypted;
        this.publicPermissions = attrs.publicPermissions;
        this.tags = attrs.tags;
    }
    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getAccountId() {
        return this.accountId;
    }
    getRegion() {
        return this.region;
    }
    getCost() {
        return this.cost;
    }
    getCloudConnectorId() {
        return this.cloudConnectorId;
    }
    getCloudProviderAssetId() {
        return this.cloudProviderSnapshotId;
    }
    getCloudProviderSnapshotId() {
        return this.cloudProviderSnapshotId;
    }
    getStorageType() {
        return this.storageType;
    }
    getCreatedAt() {
        return this.createdAt;
    }
    getVolumeId() {
        return this.volumeId;
    }
    getCloudProviderVolumeId() {
        return this.cloudProviderVolumeId;
    }
    getInventorySize() {
        return this.inventorySize;
    }
    getTags() {
        return Array.from(this.tags);
    }
    getTagsMap() {
        return this.tags;
    }
    isUnencrypted() {
        return !this.encrypted;
    }
    isPublic() {
        return this.publicPermissions === true;
    }
    isOlderThen30Days() {
        const oneDay = 24 * 60 * 60 * 1000;
        const diffDays = Math.round(Math.abs((this.getCreatedAt() - Date.now()) / oneDay));
        return diffDays > 30;
    }
    isOlderThen7Days() {
        const oneDay = 24 * 60 * 60 * 1000;
        const diffDays = Math.round(Math.abs((this.getCreatedAt() - Date.now()) / oneDay));
        return diffDays > 7;
    }
    getAgeInDays() {
        return Math.round(Math.abs((Date.now() - this.createdAt) / (1000 * 3600 * 24)));
    }
    getSnapshotVulnerabilitiesTypesV2() {
        const snapshotVulnerabilitiesType = new Set();
        // Can be extended with new vulnerabilities, cause our design changing very quickly
        const actualVulnerabilities = [
            {
                condition: this.isPublic(),
                type: SnapshotVulnerabilitiesTypeNameV2.PUBLIC_PERMISSIONS,
            },
            {
                condition: this.isUnencrypted(),
                type: SnapshotVulnerabilitiesTypeNameV2.UNENCRYPTED,
            },
            // {
            //   condition: this.isOlderThen30Days(),
            //   type: SnapshotVulnerabilitiesTypeNameV2.OLDER_THAN_30_DAYS,
            // },
        ];
        if (actualVulnerabilities.every((vulnerability) => vulnerability.condition === false)) {
            return [];
        }
        actualVulnerabilities.forEach((vulnerability) => {
            if (vulnerability.condition) {
                snapshotVulnerabilitiesType.add(vulnerability.type);
            }
        });
        return [...snapshotVulnerabilitiesType].sort();
    }
    getSnapshotVulnerabilitiesTypes() {
        const snapshotVulnerabilitiesType = new Set();
        if (!this.isPublic() && !this.isUnencrypted()) {
            return [];
        }
        if (this.isPublic()) {
            snapshotVulnerabilitiesType.add(index_1.SnapshotVulnerabilitiesTypeName.PUBLIC_PERMISSIONS);
        }
        if (this.isUnencrypted()) {
            snapshotVulnerabilitiesType.add(index_1.SnapshotVulnerabilitiesTypeName.UNENCRYPTED);
        }
        return [...snapshotVulnerabilitiesType].sort();
    }
    isCostUnknown() {
        return this.storageType === 'archive' && this.inventorySize === 0;
    }
    getParsedCloudProviderVolumeId() {
        return (this.cloudProviderVolumeId?.split(':')?.pop() ??
            this.cloudProviderVolumeId ??
            '');
    }
    getParsedCloudProviderSnapshotId() {
        return (this.cloudProviderSnapshotId?.split(':')?.pop() ??
            this.cloudProviderSnapshotId ??
            '');
    }
}
exports.SnapshotModel = SnapshotModel;
