/* eslint-disable import/no-extraneous-dependencies */
import { Policy, PolicyMode } from 'blues-corejs/dist'
import {
  IscanOptions,
  ProtectNewImmediately,
  SnapshotImport,
  Vault,
  Selector,
  Schedule,
} from 'blues-corejs/dist/models/policy/types'

import {
  Plan,
  IscanOptions as ProtobufIscanOptions,
  ProtectNewImmediately as ProtobufProtectNewImmediately,
  SnapshotImport as ProtobufSnapshotImport,
  Vault as ProtobufVault,
  PlanMode as ProtobufPlanMode,
  Selector as ProtobufSelector,
  Schedule as ProtobufSchedule,
} from 'blue-stack-libs/pechkin-grpc-libs/js/pechkin/messages/plan_pb'
import { ScheduleTransformer } from '@lib/clients/pechkin/schedule-transformer'

export class PechkinTransformer {
  transformPolicy(plan: Plan.AsObject): Policy {
    return new Policy({
      accountRegionSelectors: plan.accountRegionSelectorsList,
      enabled: true,
      id: plan.id,
      integrityCheck: plan.integrityCheck,
      iscan: this.#convertServerIscanOptions(plan.iscan),
      protectNewImmediately: this.#convertProtectNewImmediately(
        plan.protectNewImmediately
      ),
      schedule: this.#convertSchedule(plan.schedule),
      selectors: this.#convertSelectors(plan.selectorsList),
      skipEbsBackup: plan.skipEbsBackup,
      snapshotImport: this.#convertSnapshotImport(plan.snapshotImport),
      tags: plan.tagsMap,
      vaults: plan.vaultsList.map(this.#convertVault.bind(this)),
      planMode: this.#convertPlanMode(plan.planMode),
    })
  }

  #convertSchedule(schedule?: ProtobufSchedule.AsObject): Schedule | undefined {
    if (!schedule) {
      return
    }
    return new ScheduleTransformer(schedule).transform()
  }

  #convertSelectors(
    selectors: Array<ProtobufSelector.AsObject>
  ): Array<Selector> {
    const convertedSelectors: Array<Selector> = []

    for (const { criteriaList } of selectors) {
      convertedSelectors.push({
        criteriaList: criteriaList.map((criteria) => ({
          isType: criteria.isType,
          hasTags: criteria.hasTags?.tagsMap,
          assets: criteria.assets,
          awsSubAssets: criteria.awsSubAssets,
        })),
      })
    }
    return convertedSelectors
  }

  #convertPlanMode(
    planMode?: ProtobufPlanMode.AsObject
  ): PolicyMode | undefined {
    if (!planMode) {
      return
    }

    return new PolicyMode({
      backup: planMode.backup,
      scanOnly: {
        efs: planMode.scanOnly?.efs,
        ec2OrEbs: planMode.scanOnly?.ec2OrEbs,
        s3: {
          uploadedAfter: planMode.scanOnly?.s3?.uploadedAfter
            ? new Date(planMode.scanOnly.s3.uploadedAfter.seconds * 1000)
            : undefined,
        },
      },
    })
  }

  #convertVault(vault: ProtobufVault.AsObject): Vault {
    return {
      red_stack_id: vault.redStackId,
      vault_name: vault.vaultName,
    }
  }

  #convertServerIscanOptions(options: ProtobufIscanOptions) {
    const mapping = {
      [ProtobufIscanOptions.DISABLED]: IscanOptions.Disabled,
      [ProtobufIscanOptions.FULL]: IscanOptions.Full,
      [ProtobufIscanOptions.MALWARE]: IscanOptions.Malware,
      [ProtobufIscanOptions.RANSOMWARE]: IscanOptions.Ransomware,
      [ProtobufIscanOptions.RANSOMWAREANDENTROPYDETECTION]:
        IscanOptions.RansomwareAndEntropyDetection,
      [ProtobufIscanOptions.FULLANDENTROPYDETECTION]:
        IscanOptions.FullAndEntropyDetection,
    }

    return mapping[options]
  }

  #convertProtectNewImmediately(options: ProtobufProtectNewImmediately) {
    const mapping = {
      [ProtobufProtectNewImmediately.DISABLED]: ProtectNewImmediately.DISABLED,
      [ProtobufProtectNewImmediately.ENABLED]: ProtectNewImmediately.ENABLED,
      [ProtobufProtectNewImmediately.UNSPECIFIED]:
        ProtectNewImmediately.UNSPECIFIED,
    }

    return mapping[options]
  }

  #convertSnapshotImport(options: ProtobufSnapshotImport) {
    const mapping = {
      [ProtobufSnapshotImport.ALL]: SnapshotImport.All,
      [ProtobufSnapshotImport.LATEST]: SnapshotImport.Latest,
      [ProtobufSnapshotImport.SKIP]: SnapshotImport.Skip,
    }

    return mapping[options]
  }
}
