import React, { PropsWithChildren } from 'react'
import Box from '@mui/material/Box'
// eslint-disable-next-line import/no-extraneous-dependencies
import { SxProps, Theme } from '@mui/system'

const disabledStateStyles: SxProps<Theme> = {
  pointerEvents: 'none',
  opacity: 0.5,
}

const enabledStateStyles: SxProps<Theme> = {
  pointerEvents: 'auto',
  opacity: 1,
}

const getDisabledStateStyles = (isDisabled: boolean): SxProps<Theme> => {
  return isDisabled ? disabledStateStyles : enabledStateStyles
}

const DisabledStateWrapper = ({
  isDisabled,
  children,
}: PropsWithChildren<{ isDisabled: boolean }>) => {
  return <Box sx={getDisabledStateStyles(isDisabled)}>{children}</Box>
}

export default DisabledStateWrapper
