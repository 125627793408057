/* eslint-disable import/no-extraneous-dependencies */
import JobModel from '@lib/models/job.model'
import PaginationModel from '@lib/models/pagination.model'
import { ArrayTotalResult, DateRangeTuple } from '@lib/engine-types'
import JobPageConstant from '@lib/constants/jobs/job-page.constant'
import ValueInterface from '@lib/interfaces/value.interface'
import { JobCloudConnector, JobTenant } from 'blues-corejs/dist'

// Server jobs API
export const SET_CC_SERVER_JOBS = 'SET_CC_SERVER_JOBS'
export const SET_TENANT_SERVER_JOBS = 'SET_TENANT_SERVER_JOBS'
export const SET_UPDATED_CC_SERVER_JOBS = 'SET_UPDATED_CC_SERVER_JOBS'
export const SET_UPDATED_TENANT_SERVER_JOBS = 'SET_UPDATED_TENANT_SERVER_JOBS'

export const SET_JOBS_LIST = 'SET_JOBS_LIST'
export const SET_JOBS_LIST_PAGINATION = 'SET_JOBS_LIST_PAGINATION'
export const REQUEST_JOBS_LIST = 'REQUEST_JOBS_LIST'
export const REQUEST_POSSIBLE_JOBS_FILTERS = 'REQUEST_POSSIBLE_JOBS_FILTERS'
export const SET_SELECTED_JOBS_FILTERS = 'SET_SELECTED_JOBS_FILTERS'
export const SET_POSSIBLE_JOBS_FILTERS = 'SET_POSSIBLE_JOBS_FILTERS'
export const SET_JOBS_SEARCH = 'SET_JOBS_SEARCH'
export const SET_JOBS_DATE_RANGE = 'SET_JOBS_DATE_RANGE'
export const SET_CURRENT_JOBS_PAGE = 'SET_CURRENT_JOBS_PAGE'
export const ABORT_JOB = 'ABORT_JOB'
export const SET_JOBS_BACKUP_SUCCESS_FILTERS = 'SET_JOBS_BACKUP_SUCCESS_FILTERS'
export const SET_JOBS_BACKUP_FAILED_FILTERS = 'SET_JOBS_BACKUP_FAILED_FILTERS'

export const setCloudConnectorServerJobs = (
  jobs: Array<JobCloudConnector>
) => ({
  type: SET_CC_SERVER_JOBS,
  payload: jobs,
})

export const setTenantServerJobs = (jobs: Array<JobTenant>) => ({
  type: SET_TENANT_SERVER_JOBS,
  payload: jobs,
})

export const setUpdatedCloudConnectorServerJobs = (
  jobs: Array<JobCloudConnector>
) => ({
  type: SET_UPDATED_CC_SERVER_JOBS,
  payload: jobs,
})

export const setUpdatedTenantServerJobs = (jobs: Array<JobTenant>) => ({
  type: SET_UPDATED_TENANT_SERVER_JOBS,
  payload: jobs,
})

export const setCurrentJobsPage = (payload: JobPageConstant) => ({
  type: SET_CURRENT_JOBS_PAGE,
  payload,
})

export const setJobsList = (payload: ArrayTotalResult<JobModel>) => ({
  type: SET_JOBS_LIST,
  payload,
})

export const setJobsListPagination = (payload: PaginationModel) => ({
  type: SET_JOBS_LIST_PAGINATION,
  payload,
})

export const requestJobsList = () => ({
  type: REQUEST_JOBS_LIST,
  payload: null,
})

export const requestPossibleJobsFilters = () => ({
  type: REQUEST_POSSIBLE_JOBS_FILTERS,
  payload: null,
})

export const setSelectedJobsFilters = (payload: Array<ValueInterface>) => ({
  type: SET_SELECTED_JOBS_FILTERS,
  payload,
})

export const setPossibleJobsFilters = (payload: Array<ValueInterface>) => ({
  type: SET_POSSIBLE_JOBS_FILTERS,
  payload,
})

export const setJobsSearch = (search: string) => ({
  type: SET_JOBS_SEARCH,
  payload: search,
})

export const setJobsDateRange = (payload: DateRangeTuple) => ({
  type: SET_JOBS_DATE_RANGE,
  payload,
})

export const abortJob = (jobId: string) => ({
  type: ABORT_JOB,
  payload: jobId,
})

export const setBackupSuccessFilters = () => ({
  type: SET_JOBS_BACKUP_SUCCESS_FILTERS,
  payload: null,
})

export const setBackupFailedFilters = () => ({
  type: SET_JOBS_BACKUP_FAILED_FILTERS,
  payload: null,
})
