import React from 'react'

function ElastioLogoWhiteIcon() {
  return (
    <svg
      width="100"
      height="40"
      viewBox="0 0 100 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="28"
        height="28"
        rx="14"
        transform="matrix(-1 0 0 1 28 6)"
        fill="#FF1654"
      />
      <path
        d="M13.8836 29.9999C14.1632 30.0021 14.4385 29.9311 14.6818 29.794L21.361 25.9981C21.6053 25.8589 21.8085 25.6584 21.9502 25.4166C22.0919 25.1748 22.1673 24.9003 22.1686 24.6205V20.3044H20.3837V24.5162L13.8836 28.205L6.73188 24.1015V15.8973L13.8836 11.7938L20.132 15.3797L13.5551 19.0846L14.4341 20.6227L21.2856 16.7627C21.5292 16.6243 21.7318 16.4247 21.8732 16.1839C22.0146 15.9432 22.0898 15.6697 22.0911 15.3909C22.0925 15.1121 22.02 14.8379 21.881 14.5958C21.742 14.3536 21.5413 14.1521 21.2991 14.0114L14.6818 10.2115C14.4394 10.0729 14.1646 10 13.8849 10C13.6052 10 13.3304 10.0729 13.088 10.2115L5.75194 14.4127C5.50919 14.5522 5.30757 14.7527 5.16726 14.994C5.02694 15.2353 4.95284 15.5089 4.95237 15.7876V24.2005C4.95284 24.4792 5.02694 24.7529 5.16726 24.9942C5.30757 25.2355 5.50919 25.4359 5.75194 25.5755L13.088 29.794C13.3305 29.9306 13.6048 30.0016 13.8836 29.9999Z"
        fill="white"
      />
      <path
        d="M13.9926 21.6231C13.0098 21.6231 12.2131 20.8315 12.2131 19.855C12.2131 18.8784 13.0098 18.0868 13.9926 18.0868C14.9754 18.0868 15.7721 18.8784 15.7721 19.855C15.7721 20.8315 14.9754 21.6231 13.9926 21.6231Z"
        fill="white"
      />
      <path
        d="M19.4146 20.3833C19.4146 21.3597 20.2855 22.0779 21.2681 22.0779C21.7401 22.0779 22.1927 21.8916 22.5264 21.56C22.8601 21.2284 23.0476 20.7787 23.0476 20.3097C23.0476 19.8408 22.8601 19.391 22.5264 19.0594C22.1927 18.7278 21.7401 18.5415 21.2681 18.5415C20.7809 18.5553 20.3175 18.7538 19.9728 19.0963C19.6282 19.4387 19.4285 19.8992 19.4146 20.3833Z"
        fill="white"
      />
      <path
        d="M37.48 18.7903C38.92 18.7903 40.08 19.7103 40.12 21.1703H34.86C35.08 19.6503 36.14 18.7903 37.48 18.7903ZM42.74 24.2703H39.72C39.36 25.0103 38.7 25.6103 37.5 25.6103C36.1 25.6103 34.98 24.6903 34.84 23.0503H42.94C43 22.6903 43.02 22.3303 43.02 21.9703C43.02 18.6703 40.76 16.4903 37.56 16.4903C34.28 16.4903 32 18.7103 32 22.2103C32 25.6903 34.34 27.9303 37.56 27.9303C40.3 27.9303 42.14 26.3103 42.74 24.2703Z"
        fill="white"
      />
      <path
        d="M45.0638 27.7503H47.8638V12.9503H45.0638V27.7503Z"
        fill="white"
      />
      <path
        d="M49.9102 22.1703C49.9102 25.6303 52.1502 27.9303 54.9502 27.9303C56.7102 27.9303 57.9702 27.0903 58.6302 26.1303V27.7503H61.4502V16.6703H58.6302V18.2503C57.9702 17.3303 56.7502 16.4903 54.9702 16.4903C52.1502 16.4903 49.9102 18.7103 49.9102 22.1703ZM58.6302 22.2103C58.6302 24.3103 57.2302 25.4703 55.6902 25.4703C54.1902 25.4703 52.7702 24.2703 52.7702 22.1703C52.7702 20.0703 54.1902 18.9503 55.6902 18.9503C57.2302 18.9503 58.6302 20.1103 58.6302 22.2103Z"
        fill="white"
      />
      <path
        d="M72.7848 24.5903C72.7048 20.5303 66.5648 21.7903 66.5648 19.7703C66.5648 19.1303 67.1048 18.7103 68.1448 18.7103C69.2448 18.7103 69.9248 19.2903 70.0048 20.1503H72.6848C72.5248 17.9503 70.9048 16.4903 68.2248 16.4903C65.4848 16.4903 63.8448 17.9703 63.8448 19.8103C63.8448 23.8703 70.1048 22.6103 70.1048 24.5903C70.1048 25.2303 69.5048 25.7303 68.4048 25.7303C67.2848 25.7303 66.5048 25.0903 66.4048 24.2503H63.5848C63.7048 26.3103 65.6448 27.9303 68.4248 27.9303C71.1248 27.9303 72.7848 26.4903 72.7848 24.5903Z"
        fill="white"
      />
      <path
        d="M75.5233 24.3103C75.5233 26.8503 76.9433 27.7503 79.0633 27.7503H80.8233V25.3903H79.5233C78.6433 25.3903 78.3433 25.0703 78.3433 24.3303V18.9703H80.8233V16.6703H78.3433V13.9303H75.5233V16.6703H74.2033V18.9703H75.5233V24.3103Z"
        fill="white"
      />
      <path
        d="M82.8372 27.7503H85.6372V16.6703H82.8372V27.7503ZM84.2572 15.3503C85.2372 15.3503 85.9772 14.6303 85.9772 13.7103C85.9772 12.7903 85.2372 12.0703 84.2572 12.0703C83.2572 12.0703 82.5372 12.7903 82.5372 13.7103C82.5372 14.6303 83.2572 15.3503 84.2572 15.3503Z"
        fill="white"
      />
      <path
        d="M99.1036 22.2103C99.1036 18.7103 96.6036 16.4903 93.4036 16.4903C90.2036 16.4903 87.7036 18.7103 87.7036 22.2103C87.7036 25.6903 90.1236 27.9303 93.3236 27.9303C96.5436 27.9303 99.1036 25.6903 99.1036 22.2103ZM90.5436 22.2103C90.5436 19.9903 91.8836 18.9303 93.3636 18.9303C94.8436 18.9303 96.2236 19.9903 96.2236 22.2103C96.2236 24.4103 94.8036 25.4903 93.3236 25.4903C91.8236 25.4903 90.5436 24.4103 90.5436 22.2103Z"
        fill="white"
      />
    </svg>
  )
}

export default ElastioLogoWhiteIcon
