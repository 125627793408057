import React from 'react'

function AssetItemDiskIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16791_42840)">
        <path
          d="M16.2227 6C16.2227 5.81291 16.1864 5.631 16.1148 5.45934C16.1148 5.45922 16.1147 5.45906 16.1147 5.45894L14.552 1.70906C14.3329 1.18341 13.8234 0.84375 13.2539 0.84375H3.19138C2.62191 0.84375 2.11238 1.18341 1.89331 1.70906L0.330469 5.45931C0.258938 5.631 0.222656 5.81291 0.222656 6V7.9375C0.222656 8.29741 0.358719 8.626 0.581875 8.875C0.358719 9.124 0.222656 9.45259 0.222656 9.8125V11.6875C0.222656 12.0474 0.358719 12.376 0.581875 12.625C0.358719 12.874 0.222656 13.2026 0.222656 13.5625V15.4375C0.222656 16.2129 0.8535 16.8438 1.62891 16.8438H14.8164C15.5918 16.8438 16.2227 16.2129 16.2227 15.4375V13.5625C16.2227 13.2026 16.0866 12.874 15.8634 12.625C16.0866 12.376 16.2227 12.0474 16.2227 11.6875V9.8125C16.2227 9.45259 16.0866 9.124 15.8634 8.875C16.0866 8.626 16.2227 8.29741 16.2227 7.9375V6ZM2.75869 2.06969C2.83172 1.89447 3.00156 1.78125 3.19138 1.78125H13.2539C13.4438 1.78125 13.6136 1.89447 13.6866 2.06969L14.7385 4.59375H1.70681L2.75869 2.06969ZM15.2852 15.4375C15.2852 15.696 15.0749 15.9062 14.8164 15.9062H1.62891C1.37044 15.9062 1.16016 15.696 1.16016 15.4375V13.5625C1.16016 13.304 1.37044 13.0938 1.62891 13.0938H14.8164C15.0749 13.0938 15.2852 13.304 15.2852 13.5625V15.4375ZM15.2852 11.6875C15.2852 11.946 15.0749 12.1562 14.8164 12.1562H1.62891C1.37044 12.1562 1.16016 11.946 1.16016 11.6875V9.8125C1.16016 9.55403 1.37044 9.34375 1.62891 9.34375H14.8164C15.0749 9.34375 15.2852 9.55403 15.2852 9.8125V11.6875ZM15.2852 7.9375C15.2852 8.19597 15.0749 8.40625 14.8164 8.40625H1.62891C1.37044 8.40625 1.16016 8.19597 1.16016 7.9375V6C1.16016 5.73931 1.37066 5.53125 1.62891 5.53125H14.8164C15.0755 5.53125 15.2852 5.74013 15.2852 6V7.9375Z"
          fill="black"
        />
        <path
          d="M13.8789 14.0312H5.37891C5.12003 14.0312 4.91016 14.2411 4.91016 14.5C4.91016 14.7589 5.12003 14.9688 5.37891 14.9688H13.8789C14.1378 14.9688 14.3477 14.7589 14.3477 14.5C14.3477 14.2411 14.1378 14.0312 13.8789 14.0312Z"
          fill="black"
        />
        <path
          d="M13.8789 6.46875H5.37891C5.12003 6.46875 4.91016 6.67862 4.91016 6.9375C4.91016 7.19638 5.12003 7.40625 5.37891 7.40625H13.8789C14.1378 7.40625 14.3477 7.19638 14.3477 6.9375C14.3477 6.67862 14.1378 6.46875 13.8789 6.46875Z"
          fill="black"
        />
        <path
          d="M2.56641 6.46875C2.30753 6.46875 2.09766 6.67862 2.09766 6.9375C2.09766 7.19638 2.30753 7.46875 2.56641 7.46875C2.82528 7.46875 3.03516 7.19638 3.03516 6.9375C3.03516 6.67862 2.82528 6.46875 2.56641 6.46875Z"
          fill="black"
        />
        <path
          d="M13.8789 10.2812H5.37891C5.12003 10.2812 4.91016 10.4911 4.91016 10.75C4.91016 11.0089 5.12003 11.2188 5.37891 11.2188H13.8789C14.1378 11.2188 14.3477 11.0089 14.3477 10.75C14.3477 10.4911 14.1378 10.2812 13.8789 10.2812Z"
          fill="black"
        />
        <path
          d="M2.56641 11.2188C2.82529 11.2188 3.03516 11.0089 3.03516 10.75C3.03516 10.4911 2.82529 10.2812 2.56641 10.2812C2.30752 10.2812 2.09766 10.4911 2.09766 10.75C2.09766 11.0089 2.30752 11.2188 2.56641 11.2188Z"
          fill="black"
        />
        <path
          d="M2.56641 14.9688C2.82529 14.9688 3.03516 14.7589 3.03516 14.5C3.03516 14.2411 2.82529 14.0312 2.56641 14.0312C2.30752 14.0312 2.09766 14.2411 2.09766 14.5C2.09766 14.7589 2.30752 14.9688 2.56641 14.9688Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_16791_42840">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.222656 0.84375)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AssetItemDiskIcon
