// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Asset,
  AWSBRecoveryPoint,
  ElastioRecoveryPoint,
  EBSSnapshot,
} from 'blues-corejs/dist'
import router from 'next/router'
import {
  getRecoveryPointMountUrl,
  getRecoveryPointRestoreUrl,
} from '@features/asset-page/modals/recovery-point-details/components/recovery-point-url-composer'
import { pechkinClient } from '@lib/clients/pechkin'
import { Action } from './types'
import { ScheduleCcJobsUseCase } from '@features/scheduled-jobs-monitoring/use-cases'

import { ScheduledCcJobsClient } from '@features/scheduled-jobs-monitoring/clients'
import { ScheduledCcJobsRepository } from '@features/scheduled-jobs-monitoring/repositories'
import { createLogger } from '@features/scheduled-jobs-monitoring/infrastructure/logging'
import ToastHelper from '@lib/helpers/toast.helper'

const scheduleCcJobsUseCase = new ScheduleCcJobsUseCase(
  new ScheduledCcJobsRepository(createLogger()),
  new ScheduledCcJobsClient(),
  ToastHelper
)

export const restoreHandler =
  (asset: Asset, backupId: string): (() => void) =>
  () =>
    router.push(getRecoveryPointRestoreUrl(asset, backupId))

export const mountHandler =
  (asset: Asset, backupId: string): (() => void) =>
  () =>
    router.push(getRecoveryPointMountUrl(asset, backupId))

export const erpScanHandler = (backup: ElastioRecoveryPoint) => async () => {
  const scheduledJobIds = await pechkinClient.scheduleRecoveryPointScan(backup)
  await scheduleCcJobsUseCase.execute(scheduledJobIds)
}

export const awsBackupRpScanHandler =
  (backup: AWSBRecoveryPoint) => async () => {
    const scheduledJobIds = await pechkinClient.awsBackupRpScan(backup)
    await scheduleCcJobsUseCase.execute(scheduledJobIds)
  }

export const awsEbsSnapshotScan = (backup: EBSSnapshot) => async () => {
  const scheduledJobIds = await pechkinClient.awsEbsSnapshotScan(backup)
  await scheduleCcJobsUseCase.execute(scheduledJobIds)
}

export function getMethodFromSingleMenuItem(
  array: Array<Action>,
  methodName: 'onClick'
): () => void {
  if (array.length !== 1) {
    throw new Error('Expected exactly one object in the array')
  }

  const [item] = array
  if (!item) {
    throw new Error('Item is undefined')
  }
  const method = item[methodName]

  if (typeof method !== 'function') {
    throw new Error(`Expected the object to have a '${methodName}' method`)
  }

  return () => item[methodName]()
}
