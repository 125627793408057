import { useState, useEffect } from 'react'

const VISIBILITY_CHANGE_EVENT = 'visibilitychange'
const DOCUMENT_HIDDEN_PROPERTY = 'hidden'

const useVisibility = () => {
  const [isVisible, setIsVisible] = useState(
    !document[DOCUMENT_HIDDEN_PROPERTY]
  )

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document[DOCUMENT_HIDDEN_PROPERTY])
    }

    document.addEventListener(VISIBILITY_CHANGE_EVENT, handleVisibilityChange)

    return () => {
      document.removeEventListener(
        VISIBILITY_CHANGE_EVENT,
        handleVisibilityChange
      )
    }
  }, [])

  return isVisible
}

export { useVisibility }
