import NumHelper from '@lib/helpers/num.helper'
import TypeHelper from '@lib/helpers/type.helper'
import RedStackModel from '@lib/models/red-stack.model'
import BaseModel from '@lib/models/base-model/base.model'
import RedStackStatusConstant from '@lib/constants/red-stack-status.constant'

abstract class RedStackFactory {
  public static buildFromScanRedStack(params: any): RedStackModel {
    return new RedStackModel({
      id: NumHelper.numberHash(params.redStackId),
      innerId: params.redStackId,
      redStackId: TypeHelper.stringValDef(params.redStackId, ''),
      accountAlias: TypeHelper.stringValDef(params.accountAlias, ''),
      awsRegion: TypeHelper.stringValDef(params.awsRegion, ''),
      awsAccount: TypeHelper.stringValDef(params.awsAccount, ''),
      iamRole: TypeHelper.stringValDef(params.iamRole, ''),
      accountDescription: TypeHelper.stringValDef(
        params.accountDescription,
        ''
      ),
      lastErrorMessage: TypeHelper.stringValDef(params.lastErrorMessage, ''),
      lastWarningMessage: TypeHelper.stringValDef(
        params.lastWarningMessage,
        ''
      ),
      status: TypeHelper.enumValDef(
        params.status,
        RedStackStatusConstant.UNDEFINED,
        RedStackStatusConstant
      ),
      version: TypeHelper.stringValDef(params.version, ''),
      awsAccountCfnVersion: TypeHelper.stringValDef(
        params.awsAccountCfnVersion,
        ''
      ),
      lastIntegrityCheckErrorsList: params.lastIntegrityCheckErrorsList,
      cfnUpgradeRequired: params.cfnUpgradeRequired,
    })
  }

  public static clone(source: RedStackModel, params: any = {}): RedStackModel {
    return BaseModel.clone(source, params)
  }
}

export default RedStackFactory
