"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OvaBackupProvider = void 0;
var OvaBackupProvider;
(function (OvaBackupProvider) {
    OvaBackupProvider[OvaBackupProvider["VEEAM"] = 0] = "VEEAM";
    OvaBackupProvider[OvaBackupProvider["COMMVAULT"] = 1] = "COMMVAULT";
    OvaBackupProvider[OvaBackupProvider["RUBRIK"] = 2] = "RUBRIK";
    OvaBackupProvider[OvaBackupProvider["COHESITY"] = 3] = "COHESITY";
    OvaBackupProvider[OvaBackupProvider["VERITAS_NET_BACKUP"] = 4] = "VERITAS_NET_BACKUP";
    OvaBackupProvider[OvaBackupProvider["AWS_BACKUP_VMWARE"] = 5] = "AWS_BACKUP_VMWARE";
})(OvaBackupProvider = exports.OvaBackupProvider || (exports.OvaBackupProvider = {}));
