import React from 'react'
import styles from './index.module.scss'
import { EngineCallbackPure } from '@lib/engine-types'
import { EmptyFunc } from '@lib/constants/app.constant'
import clsx from 'clsx'

interface Props {
  text: string
  onClick?: EngineCallbackPure
  className?: string
}

function Title({ text, onClick = EmptyFunc, className = '' }: Props) {
  return (
    <div className={clsx([styles.title, className])} onClick={onClick}>
      {text}
    </div>
  )
}

export default Title
