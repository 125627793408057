import { DateRangeTuple, Nullable } from '@lib/engine-types'
import GeneratedReportsKindInterface from '@lib/interfaces/generated-reports-kind.interface'
import ReportComplianceDataInterface from '@lib/interfaces/report-compliance-data.interface'
import ReportPeriodInterface from '@lib/interfaces/report-period.interface'
import { ReportScheduleRequestInterface } from '@lib/interfaces/schedule-report.interface'
import ValueInterface from '@lib/interfaces/value.interface'
import ReportScheduleModel from '@lib/models/report-schedule.model'

export const REQUEST_COMPLIANCE_SUMMARY = 'REQUEST_COMPLIANCE_SUMMARY'
export const SET_COMPLIANCE_SUMMARY_REPORT_DATA =
  'SET_COMPLIANCE_SUMMARY_REPORT_DATA'
export const SET_COMPLIANCE_SUMMARY_REPORT_PERIOD =
  'SET_COMPLIANCE_SUMMARY_REPORT_PERIOD'
export const SET_COMPLIANCE_SUMMARY_REPORT_TYPE =
  'SET_COMPLIANCE_SUMMARY_REPORT_TYPE'

export const REQUEST_BACKUP_AUDIT = 'REQUEST_BACKUP_AUDIT'
export const SET_REQUEST_BACKUP_AUDIT_DATA = 'SET_REQUEST_BACKUP_AUDIT_DATA'
export const SET_REQUEST_BACKUP_AUDIT_PERIOD = 'SET_REQUEST_BACKUP_AUDIT_PERIOD'

export const REQUEST_GENERATED_REPORTS = 'REQUEST_GENERATED_REPORTS'

export const SET_GENERATED_REPORTS = 'SET_GENERATED_REPORTS'

export const REQUEST_CREATE_REPORT_SCHEDULE = 'REQUEST_CREATE_REPORT_SCHEDULE'

export const REQUEST_UPDATE_REPORT_SCHEDULE = 'REQUEST_UPDATE_REPORT_SCHEDULE'

export const REQUEST_VOLUME_VULNERABILITIES_GENERATED_REPORTS =
  'REQUEST_VOLUME_VULNERABILITIES_GENERATED_REPORTS'

export const SET_VOLUME_VULNERABILITIES_REPORTS =
  'SET_VOLUME_VULNERABILITIES_REPORTS'

export const REQUEST_INSTANCE_VULNERABILITIES_GENERATED_REPORTS =
  'REQUEST_INSTANCE_VULNERABILITIES_GENERATED_REPORTS'

export const SET_INSTANCE_VULNERABILITIES_REPORTS =
  'SET_INSTANCE_VULNERABILITIES_REPORTS'

export const REQUEST_SNAPSHOT_VULNERABILITIES_GENERATED_REPORTS =
  'REQUEST_SNAPSHOT_VULNERABILITIES_GENERATED_REPORTS'

export const SET_SNAPSHOT_VULNERABILITIES_REPORTS =
  'SET_SNAPSHOT_VULNERABILITIES_REPORTS'

export const requestComplianceSummary = (payload: DateRangeTuple) => ({
  type: REQUEST_COMPLIANCE_SUMMARY,
  payload,
})

export const setComplianceSummaryReport = (
  payload: Nullable<ReportComplianceDataInterface>
) => ({
  type: SET_COMPLIANCE_SUMMARY_REPORT_DATA,
  payload,
})

export const setComplianceSummaryReportPeriod = (
  payload: ReportPeriodInterface
) => ({
  type: SET_COMPLIANCE_SUMMARY_REPORT_PERIOD,
  payload,
})

export const setComplianceSummaryReportType = (
  payload: Readonly<ValueInterface> | undefined
) => ({
  type: SET_COMPLIANCE_SUMMARY_REPORT_TYPE,
  payload,
})

export const requestBackupAudit = (payload: DateRangeTuple) => ({
  type: REQUEST_BACKUP_AUDIT,
  payload,
})

export const setBackupAuditReport = (
  payload: Nullable<{ customer_info: any; backups: Array<any> }>
) => ({
  type: SET_REQUEST_BACKUP_AUDIT_DATA,
  payload,
})

export const setBackupAuditReportPeriod = (payload: ReportPeriodInterface) => ({
  type: SET_REQUEST_BACKUP_AUDIT_PERIOD,
  payload,
})

export const requestVolumeVulnerabilitiesGeneratedReports = (
  payload: GeneratedReportsKindInterface
) => ({
  type: REQUEST_VOLUME_VULNERABILITIES_GENERATED_REPORTS,
  payload,
})

export const requestGeneratedReports = () => ({
  type: REQUEST_GENERATED_REPORTS,
})

export const requestCreateReportSchedule = (
  payload: ReportScheduleRequestInterface
) => ({
  type: REQUEST_CREATE_REPORT_SCHEDULE,
  payload,
})

export const requestUpdateReportSchedule = (
  payload: ReportScheduleRequestInterface
) => ({
  type: REQUEST_UPDATE_REPORT_SCHEDULE,
  payload,
})

export const setGeneratedReports = (payload: Array<ReportScheduleModel>) => ({
  type: SET_GENERATED_REPORTS,
  payload,
})

export const setVolumeVulnerabilitiesReports = (payload: Array<any>) => ({
  type: SET_VOLUME_VULNERABILITIES_REPORTS,
  payload,
})
