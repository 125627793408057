"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.tenantsFactory = exports.inventoriesFactory = void 0;
const fishery_1 = require("fishery");
const models_1 = require("../../../models");
const instances_1 = require("./instances");
const volumes_1 = require("./volumes");
const tenants_1 = require("./tenants");
Object.defineProperty(exports, "tenantsFactory", { enumerable: true, get: function () { return tenants_1.tenantsFactory; } });
const s3buckets_1 = require("./s3buckets");
const generic_hosts_1 = require("./generic_hosts");
class InventoryFactory extends fishery_1.Factory {
    noAssets() {
        return this.transient({
            instanceList: [],
            volumeList: [],
        });
    }
    noBackups(n) {
        return this.transient({
            volumeList: Array(n)
                .fill(0)
                .map(() => volumes_1.volumesFactory.withNoBackups().build()),
            instanceList: Array(n)
                .fill(0)
                .map(() => instances_1.instancesFactory.withNoBackups().build()),
        });
    }
    healthy(n) {
        return this.transient({
            volumeList: Array(n)
                .fill(0)
                .map(() => volumes_1.volumesFactory.healthy().build()),
            instanceList: Array(n)
                .fill(0)
                .map(() => instances_1.instancesFactory.healthy().build()),
        });
    }
    withMalware(params) {
        return this.transient({
            volumeList: Array(params.volumes)
                .fill(0)
                .map(() => volumes_1.volumesFactory.withMalware().build()),
            instanceList: Array(params.instances)
                .fill(0)
                .map(() => instances_1.instancesFactory.withMalware().build()),
        });
    }
    withRansomware(params) {
        return this.transient({
            volumeList: Array(params.volumes)
                .fill(0)
                .map(() => volumes_1.volumesFactory.withRansomware().build()),
            instanceList: Array(params.instances)
                .fill(0)
                .map(() => instances_1.instancesFactory.withRansomware().build()),
        });
    }
    withCorruptedFileSystems(params) {
        return this.transient({
            volumeList: Array(params.volumes)
                .fill(0)
                .map(() => volumes_1.volumesFactory.withCorruptedFileSystem().build()),
            instanceList: Array(params.instances)
                .fill(0)
                .map(() => instances_1.instancesFactory.withCorruptedFileSystems().build()),
        });
    }
    withRunningInstancesWithCorruptedFileSystems(instances) {
        return this.transient({
            instanceList: Array(instances)
                .fill(0)
                .map(() => instances_1.instancesFactory.running().withCorruptedFileSystems().build()),
        });
    }
}
const inventoriesFactory = InventoryFactory.define(({ transientParams }) => {
    return new models_1.InventoryModel({
        instanceList: transientParams.instanceList ?? instances_1.instancesFactory.buildList(10),
        snapshotList: transientParams.snapshotList ?? [],
        tenant: transientParams.tenant ?? tenants_1.tenantsFactory.build({}),
        volumeList: transientParams.volumeList ?? volumes_1.volumesFactory.buildList(10),
        s3BucketList: transientParams.s3BucketList ?? s3buckets_1.s3BucketsFactory.buildList(10),
        genericHostList: transientParams.genericHostList ?? generic_hosts_1.genericHostsFactory.buildList(10),
        efsList: transientParams.efsList ?? [],
    });
});
exports.inventoriesFactory = inventoriesFactory;
__exportStar(require("./common"), exports);
__exportStar(require("./instances"), exports);
__exportStar(require("./volumes"), exports);
__exportStar(require("./asset_threat_statuses"), exports);
__exportStar(require("./snapshots"), exports);
__exportStar(require("./s3buckets"), exports);
__exportStar(require("./generic_hosts"), exports);
__exportStar(require("./efs"), exports);
