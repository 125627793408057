"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jobCloudConnectorFactory = void 0;
const job_1 = require("../../models/jobs/cloud-connector/job");
const fishery_1 = require("fishery");
const common_1 = require("./common");
const types_1 = require("../../models/jobs/cloud-connector/job/types");
const factories_1 = require("../../use_cases/tests/factories");
const models_1 = require("../../models");
const DEFAULT_MAX_RANDOM = 100;
class JobCloudConnectorFactory extends fishery_1.Factory {
    #setProgress(progress) {
        return this.transient({
            progress: new models_1.PercentageProgress({
                fraction: progress / 100,
                rate: progress,
            }),
        });
    }
    child() {
        return this.transient({ childrenList: [] });
    }
    inProgress() {
        return this.transient({ status: types_1.CloudConnectorJobStatus.IN_PROGRESS });
    }
    finished() {
        return this.transient({ status: types_1.CloudConnectorJobStatus.SUCCEEDED });
    }
    pending() {
        return this.transient({ status: types_1.CloudConnectorJobStatus.SCHEDULED });
    }
    failed() {
        return this.transient({ status: types_1.CloudConnectorJobStatus.FAILED });
    }
    setJobId(id) {
        return this.transient({ id });
    }
    setJobKind(kind) {
        return this.transient({ kind });
    }
    setJobStatus(status) {
        return this.transient({ status });
    }
    setAssetId(targetAssetId) {
        return this.transient({ targetAssetId });
    }
    progress(percent) {
        return this.#setProgress(percent);
    }
}
function randomNumber(max = DEFAULT_MAX_RANDOM) {
    return Math.floor(Math.random() * max) + 1;
}
const progress = () => {
    return new models_1.PercentageProgress({
        fraction: randomNumber() / 100,
        rate: randomNumber(),
    });
};
const scanIds = () => Array.from({ length: 10 }, factories_1.uuid);
exports.jobCloudConnectorFactory = JobCloudConnectorFactory.define(({ transientParams, sequence }) => {
    return new job_1.JobCloudConnector({
        id: transientParams.id ?? (0, factories_1.uuid)(),
        status: transientParams.status ?? (0, common_1.randomValueFromEnum)(types_1.CloudConnectorJobStatus),
        kind: transientParams.kind ?? (0, common_1.randomValueFromEnum)(types_1.JobCloudConnectorKind),
        progress: transientParams.progress ?? progress(),
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
        startedAt: transientParams.startedAt ?? (0, common_1.date)(),
        finishedAt: transientParams.finishedAt ?? (0, common_1.date)(),
        abortRequested: transientParams.abortRequested ?? (0, factories_1.bool)(),
        finalized: transientParams.finalized ?? (0, factories_1.bool)(),
        targetAssetId: transientParams.targetAssetId ?? (0, factories_1.awsVolumeId)(),
        scanIds: transientParams.scanIds ?? scanIds(),
        ccJobId: transientParams.ccJobId ?? (0, factories_1.uuid)(),
        ccId: transientParams.ccId ?? (0, factories_1.uuid)(),
        parentId: transientParams.parentId ?? (0, factories_1.uuid)(),
        name: transientParams.name ?? (0, factories_1.name)('Job', sequence),
        statusMessage: transientParams.statusMessage ?? (0, factories_1.name)('Status Message', sequence),
        childrenList: transientParams.childrenList ?? [],
    });
});
