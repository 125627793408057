import {
  SET_CREATED_TENANT_NAME,
  SET_TENANT,
} from '@store/actions/tenant.action'
import TenantState from '@store/states/tenant.state'
import ActionInterface from '@lib/interfaces/action.interface'
import LocalStorageHelper from '@lib/helpers/local-storage.helper'

const initialState: TenantState = {
  createdTenantName: LocalStorageHelper.loadCreatedTenantName(),
  tenant: undefined,
}

function tenantReducer(
  prevState: TenantState = initialState,
  action: ActionInterface
): TenantState {
  switch (action.type) {
    case SET_CREATED_TENANT_NAME:
      return {
        ...prevState,
        createdTenantName: action.payload,
      }

    case SET_TENANT: {
      return {
        ...prevState,
        tenant: action.payload,
      }
    }

    default:
      return prevState
  }
}

export default tenantReducer
