import { Request as ListEbsRequestPb } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/assets/list_ebs_pb'

import { ListEbsFilterParams } from './types'
import { ListEbsRequest } from '@lib/clients/assets/list-ebs'

const DEFAULT_PAGE_SIZE = 25

export class ListEbsFilter implements ListEbsRequest {
  #requestParams: ListEbsFilterParams

  constructor(requestParams: ListEbsFilterParams) {
    this.#requestParams = requestParams
  }

  toGrpc(): ListEbsRequestPb {
    const grpcRequest = new ListEbsRequestPb()

    const filter = new ListEbsRequestPb.Initial.Filter()

    const { assetIdList } = this.#requestParams
    if (assetIdList && assetIdList.length) {
      filter.setAssetIdList(assetIdList)
    }

    return grpcRequest.setInitial(
      new ListEbsRequestPb.Initial()
        .setFilter(filter)
        .setPageSize(this.#requestParams.pageSize || DEFAULT_PAGE_SIZE)
    )
  }
}
