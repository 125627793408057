import { styled, TableCell } from '@mui/material'
import MUITableContainer from '@mui/material/TableContainer'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import { WordBreak } from '@components/table'

const PROPS_TO_NOT_FORWARD = ['isDisabled, wordBreak']

const SCROLLBAR_WIDTH = '4px'

const MAX_HEIGHT = '335px'
const SCROLLBAR_COLOR = 'var(--grey700) transparent'

export const rootBasedStyle = {
  overflow: 'auto',
  maxHeight: `calc(100vh - ${MAX_HEIGHT})`,
  '&::-webkit-scrollbar': {
    width: SCROLLBAR_WIDTH,
  },
  '&::-webkit-scrollbar-thumb': {
    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
  },
  scrollbarColor: SCROLLBAR_COLOR,
}

export const RootTableWithData = styled(Box, {
  shouldForwardProp: (prop) => !PROPS_TO_NOT_FORWARD.includes(prop as string),
})<{ isDisabled?: boolean }>(({ isDisabled }) => ({
  ...rootBasedStyle,
  pointerEvents: isDisabled ? 'none' : 'auto',
  opacity: isDisabled ? 0.5 : 1,
  scrollbarWidth: 'thin',

  '&.table--loading': {
    overflow: 'hidden',
  },
}))

export const StyledTableContainer = styled(MUITableContainer)`
  display: table;
  table-layout: fixed;
  scroll-behavior: auto;
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--grey700) transparent;

  &::-webkit-scrollbar {
    width: ${SCROLLBAR_WIDTH};
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`

export const MUITable = styled(Table)`
  position: relative;
`
MUITable.defaultProps = {
  stickyHeader: true,
}

export const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: var(--grey50);
  }
`

export const TableCellStyled = styled(TableCell, {
  shouldForwardProp: (prop) => !PROPS_TO_NOT_FORWARD.includes(prop as string),
})<{ wordBreak?: WordBreak }>`
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  font-weight: var(--font-weight-normal);
  &.MuiTableCell-root {
    border-bottom: none;
  }
  word-break: ${({ wordBreak }) => wordBreak ?? 'normal'};
`

TableCellStyled.defaultProps = {
  size: 'small',
  height: '50px',
}
