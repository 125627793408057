enum PreloaderConstants {
  EMPTY,
  ADD_GROUP,
  EDIT_GROUP,
  INVITE_USER,
  UPDATE_USER,
  ACCOUNT_PROFILE,
  ACCOUNT_SECURITY,
  REQUEST_TOKEN_LIST,
  REQUEST_REVOKED_TOKEN_LIST,
  REQUEST_TOKEN,
  REQUEST_USER,
  UPDATE_TOKEN,
  ISSUE_TOKEN,
  REVOKE_TOKEN,
  REQUEST_POSSIBLE_SCOPES,
  ADD_TENANT,
  REQUEST_DEPLOYMENT_FILES,
  GET_ALL_RED_STACKS,
  REQUEST_IF_RED_STACK_EXIST,
  REQUEST_USERS_LIST,
  REMOVE_USER,
  DISABLE_USER,
  REQUEST_GROUPS_LIST,
  REMOVE_GROUP,
  REQUEST_UPDATE_USER,
  REQUEST_ASSETS_SELECT,
  REQUEST_ASSETS_SELECT_ALL_FILTERS_V3,
  UPDATE_PASSWORD,
  REQUEST_SESSIONS,
  REQUEST_VAULT_LIST,
  REQUEST_ACCOUNT_VAULT_LIST,
  CREATE_VAULT,
  REQUEST_ACCOUNT_RED_STACKS,
  DEACTIVATE_SOURCES,
  ACTIVATE_SOURCES,
  DELETE_SOURCES,
  REQUEST_POSSIBLE_VPC_LIST,
  REQUEST_AWS_ACCOUNT_INFO_LIST,
  REQUEST_CF_LINK,
  REQUEST_POSSIBLE_ROLES,
  SUBMIT_SOURCE_ID,
  INIT_SOURCE_ID,
  RESEND_INVITE_USER,
  UPDATE_ROLE,
  DELETE_ROLE,
  REQUEST_CFN,
  REQUEST_ALL_ACTIVE_RED_STACKS,
  SET_VAULT_AS_DEFAULT,
  REQUEST_IS_MFA_ENABLED,
  UPDATE_MFA_ENABLED,
  RESET_MFA,
  REQUEST_POSSIBLE_REGIONS_LIST,
  REQUEST_POLICIES,
  ADD_POLICIES,
  ADD_RETENTION_POLICY,
  DELETE_POLICY,
  PAUSE_POLICIES,
  RESUME_POLICIES,
  EXECUTE_POLICY,
  REQUEST_EDIT_POLICY,
  UPDATE_EDIT_POLICY,
  REQUEST_SOURCES_VAULT_LIST,
  ENABLE_USER,
  REQUEST_VAULT,
  REQUEST_VAULT_METRICS,
  UPDATE_VAULT,
  INSTALL_RED_STACKS,
  REPAIR_CLOUD_CONNECTOR,
  REPAIR_VAULT,
  REQUEST_POLICIES_RETENTION,
  DELETE_POLICY_RETENTION,
  UPDATE_STATUS_POLICY_RETENTION,
  REQUEST_EDIT_POLICY_RETENTION,
  REQUEST_ASSETS_POLICY_RETENTION,
  SET_CUSTOM_RETENTION_POLICY_PRIORITIES,
  UPDATE_EDIT_POLICY_RETENTION,
  REQUEST_LIST_AWS_ACCOUNTS,
  UPDATE_EDIT_POLICY_RETENTION_DEFAULT,
  REQUEST_TENANT_NOTIFICATIONS_SETTINGS,
  UPDATE_TENANT_NOTIFICATIONS_SETTINGS,
  REQUEST_USER_NOTIFICATIONS_SETTINGS,
  UPDATE_USER_NOTIFICATIONS_SETTINGS,
  DISCONNECT_SLACK_FROM_TENANT,
  DISCONNECT_SLACK_FROM_USER,
  REQUEST_POSSIBLE_VPC_LIST_BY_REGION_AND_ACCOUNT,
  REQUEST_EDIT_POLICY_RETENTION_DEFAULT,
  REQUEST_LIST_AVAILABILITY_ZONES,
  REQUEST_LIST_AWS_SECURITY_GROUPS,
  REQUEST_COMPLIANCE_SUMMARY,
  REQUEST_BACKUP_AUDIT,
  REQUEST_DASHBOARD_DATA,
  REQUEST_BACKUP_TIMELINE_STATS,
  REQUEST_WEBHOOKS_LIST,
  ADD_WEBHOOK,
  REQUEST_GET_WEBHOOK,
  DELETE_WEBHOOK,
  UPDATE_WEBHOOK,
  TEST_WEBHOOK,
  TEST_EXISTING_WEBHOOK,
  REQUEST_EVENT_TYPES,
  REQUEST_WEBHOOK_INVOCATIONS,
  REQUEST_SCHEDULE_REPORTS_LIST,
  REQUEST_CREATE_REPORT_SCHEDULE,
  REQUEST_UPDATE_REPORT_SCHEDULE,
  REQUEST_VOLUME_VULNERABILITIES_REPORTS_LIST,
  REQUEST_GET_TENANT,
  REQUEST_UPDATE_TENANT,
  REQUEST_ASSETS_SELECT_INVENTORY,
  // [g.js - put new here]
}

export default PreloaderConstants
