import SystemHelper from '@lib/helpers/system.helper'

abstract class BaseDataConstant<ConstantType> {
  private prefix = ''

  private constantKeys: Array<string> = []

  public getByField(field: string, value: string | number): ConstantType {
    // @ts-ignore
    let result: ConstantType = this[`${this.prefix}UNDEFINED`]
    let found = false

    this.constantKeys.forEach((key: string) => {
      // @ts-ignore
      const param: ConstantType = this[key]
      // @ts-ignore
      if (param[field] === value) {
        result = param
        found = true
      }
    })

    if (!found) {
      SystemHelper.throwErrorInLocalEnv(
        `Cannot parse the data constant: ${this.prefix}`
      )
    }

    return result
  }

  public getByValue(value: string | number): ConstantType {
    return this.getByField('value', value)
  }

  public getByName(name: string): ConstantType {
    return this.getByField('name', name)
  }

  public getAll(): Array<ConstantType> {
    // @ts-ignore
    return this.constantKeys.map((key) => this[key])
  }

  public getAllValid(): Array<ConstantType> {
    return (
      this.constantKeys
        .filter((key) => !/UNDEFINED/.test(key))
        // @ts-ignore
        .map((key) => this[key])
    )
  }

  public static build<T extends BaseDataConstant<any>>(
    ClassName: { new (): T },
    prefix: string
  ): T {
    // @ts-ignore
    const result = new ClassName()
    result.prefix = prefix

    // fill possible constant keys
    const re = new RegExp(`^${result.prefix}`)
    result.constantKeys = Object.getOwnPropertyNames(
      ClassName.prototype
    ).filter((v: string) => re.test(v))

    // @ts-ignore
    return result
  }
}

export default BaseDataConstant
