import { LABELS_MAP, LOGO_MAP, LOGO_PATH_PREFIX } from './consts'
import { AuthProviderValue } from './types'

export class OAuthProvider {
  #logoName: string

  #value: AuthProviderValue

  #label: string

  constructor(name: string) {
    this.#value = OAuthProvider.parseValueFromName(name)
    this.#logoName = LOGO_MAP[this.#value]
    this.#label = LABELS_MAP[this.#value]
  }

  get value(): AuthProviderValue {
    return this.#value
  }

  get label(): string {
    return this.#label
  }

  get logoPath(): string {
    return `${LOGO_PATH_PREFIX}/${this.#logoName}.svg`
  }

  get isSSO(): boolean {
    return this.#value !== AuthProviderValue.UsernamePassword
  }

  get isGoogleOrMicrosoft(): boolean {
    return (
      this.#value === AuthProviderValue.Google ||
      this.#value === AuthProviderValue.Microsoft
    )
  }

  get isUsernamePassword(): boolean {
    return this.#value === AuthProviderValue.UsernamePassword
  }

  private static parseValueFromName(name: string): AuthProviderValue {
    switch (true) {
      case /google-oauth2/i.test(name):
        return AuthProviderValue.Google
      case /windowslive/i.test(name):
        return AuthProviderValue.Microsoft
      case /auth0/i.test(name):
        return AuthProviderValue.UsernamePassword
      default:
        return AuthProviderValue.Undefined
    }
  }
}
