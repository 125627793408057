/* eslint-disable import/no-extraneous-dependencies */
import { ListAssetsByIdsClient } from '@lib/clients'
import { ListAssetsByIds } from '@lib/clients/assets/list-assets-by-ids'
import { SystemHelper } from '@lib/helpers'
import { Asset } from 'blues-corejs/dist'
import { useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

const assetsByIdsClient = new ListAssetsByIdsClient()

interface UseAssetsMap {
  assetsList: Array<Asset>
  isAssetsLoading: boolean
}

function convertResponseToAssetMap(
  assetsList: ListAssetsByIds
): Map<string, Asset> {
  const assetsMap = new Map<string, Asset>()

  for (const possibleAsset of assetsList.assetsList) {
    const assets = [
      possibleAsset.ec2Instance,
      possibleAsset.ebsVolume,
      possibleAsset.s3Bucket,
      possibleAsset.efsFilesystem,
      possibleAsset.genericHost,
      possibleAsset.ovaServer,
    ].filter(Boolean) as Array<Asset>

    for (const asset of assets) {
      assetsMap.set(asset.id, asset)
    }
  }
  return assetsMap
}

function getNewAssetIdsNotPresentedInOldAssetIds(
  newAssetIds: Array<string>,
  oldAssetIds: Array<string>
): Array<string> {
  return newAssetIds.filter((newAssetId) => !oldAssetIds.includes(newAssetId))
}

export function useAssetsMap(assetIds: Array<string>): UseAssetsMap {
  const [assetsList, setAssetsList] = useState<Map<string, Asset>>(new Map())
  const [isAssetsLoading, setIsAssetsLoading] = useState(false)

  useDeepCompareEffect(() => {
    async function fetchAssetsByIds() {
      if (!assetIds.length) {
        setAssetsList(new Map())
        return
      }
      setIsAssetsLoading(true)

      try {
        const newUniqueAssetIds = getNewAssetIdsNotPresentedInOldAssetIds(
          assetIds,
          [...assetsList.keys()]
        )

        if (!newUniqueAssetIds.length) {
          return
        }

        const response = await assetsByIdsClient.listAssetsByIds(
          newUniqueAssetIds
        )
        setAssetsList(
          new Map([...assetsList, ...convertResponseToAssetMap(response)])
        )
      } catch (error: any) {
        if ('message' in error) {
          SystemHelper.sendSentryIfProd(
            `Something went wrong useAssetsMap: ${error.message}`
          )
        }
      } finally {
        setIsAssetsLoading(false)
      }
    }

    fetchAssetsByIds()
  }, [assetIds])

  return {
    assetsList: [...assetsList.values()],
    isAssetsLoading,
  }
}
