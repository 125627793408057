// took from
// blue-stack/ui/node_modules/blue-stack-libs/pechkin-grpc-libs/js/pechkin/messages/plan_pb.d.ts
// and from
// blue-stack/ui/node_modules/blue-stack-libs/catalog-grpc-libs/js/catalog/messages/retention_pb.d.ts

export enum PolicyStatus {
  POLICY_STATUS_DISABLED = 0,
  POLICY_STATUS_ENABLED = 1,
}

export enum DefaultRetentionPoliciesConstant {
  DEFAULT = 'Default',
}

export enum TableTabsConstant {
  EBS = 'EBS',
  EC2 = 'EC2',
  S3 = 'S3',
  OTHER = 'Other',
  UNREACHABLE = 'Unreachable',
  EFS = 'EFS',
}

export enum SnapshotImport {
  SKIP = 0,
  LATEST = 1,
  ALL = 2,
}

export enum PoliciesFilterLabel {
  PROTECTED = 'Protected',
  UNPROTECTED = 'Unprotected',
}

export enum ProtectNewImmediately {
  UNSPECIFIED = 0,
  DISABLED = 1,
  ENABLED = 2,
}

export enum SelectedCloudConnectorVariant {
  ALL = 0,
  CUSTOM = 1,
}
