import { ModelAttrs, Model } from './model'

export interface SyncAttrs extends ModelAttrs {
  allowedAt: Date
}

export class Sync extends Model<SyncAttrs> {
  #allowedAt: Date

  constructor(attrs: SyncAttrs) {
    super(attrs)
    this.#allowedAt = attrs.allowedAt
  }

  set allowedAt(date: Date) {
    if (date < new Date() || date <= this.#allowedAt) {
      throw new Error(
        'Allowed at must be in the future and later than the current allowedAt'
      )
    }

    this.#allowedAt = date
  }

  get allowedAt(): Date {
    return this.#allowedAt
  }

  get isAllowed(): boolean {
    return new Date() >= this.#allowedAt
  }

  get isForbidden(): boolean {
    return new Date() < this.#allowedAt
  }

  toAttrs(): SyncAttrs {
    return {
      id: this.id,
      createdAt: this.createdAt,
      allowedAt: this.#allowedAt,
    }
  }
}
