import {
  TimeRange,
  TimeRangeAction,
} from '@components-context/time-range/types'
import {
  RESET_TIME_RANGE,
  SET_TIME_RANGE,
} from '@components-context/time-range/consts'

export function setTimeRangeAction(newTimeRange: TimeRange): TimeRangeAction {
  return {
    type: SET_TIME_RANGE,
    newTimeRange,
  }
}

export function resetTimeRangeAction(): TimeRangeAction {
  return {
    type: RESET_TIME_RANGE,
  }
}
