import React from 'react'
import { Skeleton } from '@mui/material'
import { StyledBox } from './styles'

const NUM_SKELETONS = 4

function SkeletonPreloader() {
  return (
    <>
      {[...Array(NUM_SKELETONS)].map((current, index) => (
        <StyledBox key={index}>
          <Skeleton
            variant="circular"
            width={20}
            height={20}
            key={`skeleton1${current}`}
          />
          <Skeleton height={30} width="40%" key={`skeleton2${current}`} />
          <Skeleton height={30} width="30%" key={`skeleton3${current}`} />
          <Skeleton height={30} width="20%" key={`skeleton4${current}`} />
        </StyledBox>
      ))}
    </>
  )
}

export default SkeletonPreloader
