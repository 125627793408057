"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyMode = exports.Policy = void 0;
const consts_1 = require("./consts");
const types_1 = require("./types");
class Policy {
    #id;
    #tags;
    #selectors;
    #iscan;
    #vaults;
    #snapshotImport;
    #integrityCheck;
    #protectNewImmediately;
    #skipEbsBackup;
    #accountRegionSelectors;
    #enabled;
    #schedule;
    #planMode;
    constructor(parameters) {
        this.#id = parameters.id;
        this.#schedule = parameters.schedule;
        this.#tags = parameters.tags;
        this.#selectors = parameters.selectors;
        this.#iscan = parameters.iscan;
        this.#vaults = parameters.vaults;
        this.#snapshotImport = parameters.snapshotImport;
        this.#integrityCheck = parameters.integrityCheck;
        this.#protectNewImmediately = parameters.protectNewImmediately;
        this.#skipEbsBackup = parameters.skipEbsBackup;
        this.#accountRegionSelectors = parameters.accountRegionSelectors;
        this.#enabled = parameters.enabled;
        this.#planMode = parameters.planMode;
    }
    get id() {
        return this.#id;
    }
    get schedule() {
        return this.#schedule;
    }
    get tags() {
        return this.#tags;
    }
    get selectors() {
        return this.#selectors;
    }
    get iscan() {
        return this.#iscan;
    }
    get vaults() {
        return this.#vaults;
    }
    get snapshotImport() {
        return this.#snapshotImport;
    }
    get integrityCheck() {
        return this.#integrityCheck;
    }
    get protectNewImmediately() {
        return this.#protectNewImmediately;
    }
    get skipEbsBackup() {
        return this.#skipEbsBackup;
    }
    get accountRegionSelectors() {
        return this.#accountRegionSelectors;
    }
    get enabled() {
        return this.#enabled;
    }
    get planMode() {
        return this.#planMode;
    }
    get sla() {
        if (!this.#schedule) {
            return 0;
        }
        const frequency = this.#schedule.frequency;
        const { every15Minutes, every30Minutes, everyHour, every12Hours, daily, weekly, monthly, } = frequency;
        if (every15Minutes) {
            return consts_1.frequenciesConstants[consts_1.EVERY_15_MINUTES];
        }
        if (every30Minutes) {
            return consts_1.frequenciesConstants[consts_1.EVERY_30_MINUTES];
        }
        if (everyHour) {
            return consts_1.frequenciesConstants[consts_1.EVERY_HOUR];
        }
        if (daily) {
            return consts_1.frequenciesConstants[consts_1.DAILY];
        }
        if (every12Hours) {
            return consts_1.frequenciesConstants[consts_1.EVERY_12_HOURS];
        }
        if (weekly) {
            return consts_1.frequenciesConstants[consts_1.WEEKLY];
        }
        if (monthly) {
            return consts_1.frequenciesConstants[consts_1.MONTHLY];
        }
        return 0;
    }
}
exports.Policy = Policy;
class PolicyMode {
    #backup;
    #scanOnly;
    constructor(parameters) {
        this.#backup = parameters.backup;
        this.#scanOnly = parameters.scanOnly;
    }
    get backup() {
        return this.#backup;
    }
    get scanOnly() {
        return this.#scanOnly;
    }
    get policyMode() {
        if (this.#backup) {
            return types_1.ModeCase.BACKUP;
        }
        if (this.#scanOnly) {
            return types_1.ModeCase.SCAN_ONLY;
        }
        return types_1.ModeCase.MODE_NOT_SET;
    }
}
exports.PolicyMode = PolicyMode;
