import React, { memo } from 'react'
import { CellLongTextWithTooltipOwnProps } from './types'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'

const MAX_LENGTH_BY_DEFAULT = 60

function CellLongTextWithTooltip({
  title,
  maxLength = MAX_LENGTH_BY_DEFAULT,
}: CellLongTextWithTooltipOwnProps) {
  return <LongTextTooltip text={title} maxLength={maxLength} />
}
export default memo(CellLongTextWithTooltip)
