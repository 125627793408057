import React from 'react'

function CloudConnectorIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0834 9.41024C13.7056 7.49358 12.0223 6.05469 10 6.05469C8.39448 6.05469 7.00004 6.9658 6.3056 8.29913C4.63337 8.47691 3.33337 9.89358 3.33337 11.6102C3.33337 13.4491 4.82782 14.9436 6.66671 14.9436H13.8889C15.4223 14.9436 16.6667 13.6991 16.6667 12.1658C16.6667 10.6991 15.5278 9.51024 14.0834 9.41024ZM13.8889 13.8325H6.66671C5.43893 13.8325 4.44448 12.838 4.44448 11.6102C4.44448 10.3825 5.43893 9.38802 6.66671 9.38802H7.06115C7.42782 8.10469 8.60004 7.1658 10 7.1658C11.6889 7.1658 13.0556 8.53247 13.0556 10.2214V10.4991H13.8889C14.8111 10.4991 15.5556 11.2436 15.5556 12.1658C15.5556 13.088 14.8111 13.8325 13.8889 13.8325Z"
        fill="#999CAA"
      />
    </svg>
  )
}

export default CloudConnectorIcon
