"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extendedFactoryInstance = exports.serverFactory = void 0;
const server_1 = require("../../models/assets/ova/server");
const ova_backup_provider_1 = require("../../models/ova-backup-provider");
const fishery_1 = require("fishery");
const common_1 = require("./common");
const factories_1 = require("../../use_cases/tests/factories");
class OvaServerFactory extends fishery_1.Factory {
}
exports.serverFactory = OvaServerFactory.define(({ transientParams, sequence }) => {
    return new server_1.OvaServer({
        id: transientParams.id ?? (0, factories_1.uuid)(),
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
        name: transientParams.name ?? `Server-${sequence}`,
        osName: transientParams.osName ?? (0, factories_1.name)('osName', sequence),
        backupProviderAssetId: transientParams.backupProviderAssetId ??
            (0, factories_1.name)('backupProviderAssetId', sequence),
        ovaAccountId: transientParams.ovaAccountId ?? (0, factories_1.uuid)(),
        ccAssetId: transientParams.ccAssetId ?? (0, factories_1.uuid)(),
        backupProvider: transientParams.backupProvider ??
            (0, common_1.randomValueFromEnum)(ova_backup_provider_1.OvaBackupProvider),
    });
});
class ExtendedOvaServerFactory extends fishery_1.Factory {
}
exports.extendedFactoryInstance = ExtendedOvaServerFactory.define(({ sequence }) => {
    return {
        id: (0, factories_1.uuid)(),
        createdAt: (0, common_1.date)(),
        updatedAt: (0, common_1.date)(),
        name: (0, factories_1.name)('Server', sequence),
        osName: (0, factories_1.name)('osName', sequence),
        backupProviderAssetId: (0, factories_1.name)('backupProviderAssetId', sequence),
        ovaAccountId: (0, factories_1.uuid)(),
        ccAssetId: (0, factories_1.uuid)(),
        backupProvider: (0, common_1.randomValueFromEnum)(ova_backup_provider_1.OvaBackupProvider),
        isInfected: (0, factories_1.bool)(),
        threatsIds: Array.from({ length: 10 }, factories_1.uuid),
        lastBackup: (0, common_1.date)(),
    };
});
