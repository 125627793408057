/* eslint-disable import/no-extraneous-dependencies */
import {
  Asset,
  AWSBRecoveryPoint,
  Backup,
  EBSSnapshot,
  ElastioRecoveryPoint,
  OvaBackup,
} from 'blues-corejs/dist'

import {
  awsBackupRpScanHandler,
  awsEbsSnapshotScan,
  erpScanHandler,
  mountHandler,
  restoreHandler,
} from './handlers'
import { Action, Option } from './types'
import { MENU } from '@features/asset-page/tables/recovery-points-table'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'

const createAction = (
  label: 'Scan' | 'Restore' | 'Mount',
  onClick: () => void
): Action => ({
  label,
  onClick,
})

function getAwsEbsSnapshotScanActions(backup: EBSSnapshot) {
  return [
    {
      label: 'Scan',
      onClick: awsEbsSnapshotScan(backup),
    },
  ]
}

const computeElastioRpActions = ({
  asset,
  backup,
  includeScanOnly = false,
}: {
  asset: Asset
  backup: ElastioRecoveryPoint
  includeScanOnly: boolean
}) => {
  const actions: Array<Option> = []
  if (!includeScanOnly) {
    actions.push(createAction('Restore', restoreHandler(asset, backup.id)))
  }
  if (backup.isMountable) {
    createAction('Mount', mountHandler(asset, backup.id))
  }

  if (backup.isScannable) {
    actions.push({
      label: 'Scan',
      onClick: erpScanHandler(backup),
    })
  }

  return actions
}

export function retrieveRpCapabilities({
  asset,
  backup,
  includeScanOnly,
}: {
  asset: Asset
  backup: Backup
  includeScanOnly: boolean
}) {
  if (backup instanceof ElastioRecoveryPoint) {
    return computeElastioRpActions({
      asset,
      backup,
      includeScanOnly,
    })
  }

  if (backup instanceof EBSSnapshot) {
    return getAwsEbsSnapshotScanActions(backup)
  }

  if (backup instanceof AWSBRecoveryPoint) {
    return [
      {
        label: 'Scan',
        onClick: awsBackupRpScanHandler(backup),
      },
    ]
  }

  return [] as Array<Option>
}

export function getAvailableMenuItems(backup: Backup): Array<MENU> {
  if (!(backup instanceof OvaBackup) && backup.isDeleted) {
    return []
  }

  if (backup instanceof ElastioRecoveryPoint) {
    return [MENU.Restore, MENU.Mount, MENU.Scan]
  }
  return backup instanceof AWSBRecoveryPoint || backup instanceof EBSSnapshot
    ? [MENU.Scan]
    : []
}

function getIsRecoveryPointReplicated(backup: Backup, asset: Asset): boolean {
  if (!(backup instanceof AWSBRecoveryPoint) || !(asset instanceof AWSAsset)) {
    return false
  }
  return backup.accountId !== asset.awsAccountId
}

export function isActionButtonHidden(backup: Backup, asset: Asset): boolean {
  if (getIsRecoveryPointReplicated(backup, asset)) {
    return true
  }

  if (!(backup instanceof OvaBackup)) {
    return backup.isDeleted
  }

  return false
}
