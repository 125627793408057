"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OvaBackupSnapshot = void 0;
class OvaBackupSnapshot {
    #id;
    #ccVolumeId;
    #backupProviderVolumeId;
    #totalSize;
    #allocatedSize;
    #malwareCheckStatus;
    #ransomwareCheckStatus;
    #createdAt;
    #updatedAt;
    #ovaVolumeId;
    constructor(parameters) {
        this.#id = parameters.id;
        this.#ccVolumeId = parameters.ccVolumeId;
        this.#backupProviderVolumeId = parameters.backupProviderVolumeId;
        this.#totalSize = parameters.totalSize;
        this.#allocatedSize = parameters.allocatedSize;
        this.#malwareCheckStatus = parameters.malwareCheckStatus;
        this.#ransomwareCheckStatus = parameters.ransomwareCheckStatus;
        this.#createdAt = parameters.createdAt;
        this.#updatedAt = parameters.updatedAt;
        this.#ovaVolumeId = parameters.ovaVolumeId;
    }
    get id() {
        return this.#id;
    }
    get ccVolumeId() {
        return this.#ccVolumeId;
    }
    get backupProviderVolumeId() {
        return this.#backupProviderVolumeId;
    }
    get totalSize() {
        return this.#totalSize;
    }
    get allocatedSize() {
        return this.#allocatedSize;
    }
    get malwareCheckStatus() {
        return this.#malwareCheckStatus;
    }
    get ransomwareCheckStatus() {
        return this.#ransomwareCheckStatus;
    }
    get createdAt() {
        return this.#createdAt;
    }
    get updatedAt() {
        return this.#updatedAt;
    }
    get ovaVolumeId() {
        return this.#ovaVolumeId;
    }
}
exports.OvaBackupSnapshot = OvaBackupSnapshot;
