import {
  createColumnHelper,
  DisplayColumnDef,
  IdentifiedColumnDef,
  RowData,
} from '@tanstack/react-table'

type ColumnOptions<T> =
  | DisplayColumnDef<T, keyof T>
  | IdentifiedColumnDef<T, keyof T>

export function createColumn<T extends RowData>(
  accessor: keyof T,
  options?: ColumnOptions<T>
) {
  const columnHelper = createColumnHelper<T>()
  const defaultOptions: Partial<ColumnOptions<T>> = {
    enableSorting: true,
    cell: ({ getValue }) => getValue(),
  }

  const config = {
    ...defaultOptions,
    ...options,
  }

  // TODO: fix any
  return columnHelper.accessor(accessor as any, {
    header: config.header,
    id: config.id,
    enableSorting: config.enableSorting,
    cell: config.cell,
    meta: config.meta,
    ...config,
  })
}

// TODO: create separate file for each type of column
export function createDisplayColumn<T>(options: DisplayColumnDef<T>) {
  const columnHelper = createColumnHelper<T>()

  return columnHelper.display(options)
}
