import BaseDataConstant from '@lib/constants/data/base-data.constant'
import ValueInterface from '@lib/interfaces/value.interface'

class DemoModeConstantInner extends BaseDataConstant<ValueInterface> {
  get DEMO_DEV(): ValueInterface {
    return {
      name: 'DEV',
      label: 'Development',
      value: false,
    }
  }
}

const DemoModeConstant = DemoModeConstantInner.build(
  DemoModeConstantInner,
  'DEMO_'
)

export default DemoModeConstant
