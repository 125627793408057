import { DefinedDateRangeTuple } from '@lib/engine-types'
import { WebhooksInvocations } from '@lib/interfaces/webhooks.interface'
import WebhookModel, {
  EventType,
  WebhookModelAttributes,
} from '@lib/models/webhook.model'

export const REQUEST_WEBHOOKS_LIST = 'REQUEST_WEBHOOKS_LIST'
export const SET_WEBHOOKS_LIST = 'SET_WEBHOOKS_LIST'
export const ADD_WEBHOOK = 'ADD_WEBHOOK'

export const REQUEST_GET_WEBHOOK = 'REQUEST_GET_WEBHOOK'
export const SET_WEBHOOK = 'SET_WEBHOOK'

export const DELETE_WEBHOOK = 'DELETE_WEBHOOK'

export const REMOVE_SELECTED_WEBHOOK = 'REMOVE_SELECTED_WEBHOOK'

export const UPDATE_WEBHOOK = 'UPDATE_WEBHOOK'

export const TEST_WEBHOOK = 'TEST_WEBHOOK'

export const REQUEST_EVENT_TYPES = 'REQUEST_EVENT_TYPES'

export const SET_EVENT_TYPES = 'SET_EVENT_TYPES'

export const REQUEST_WEBHOOK_INVOCATIONS = 'REQUEST_WEBHOOK_INVOCATIONS'

export const SET_WEBHOOK_INVOCATIONS = 'SET_WEBHOOK_INVOCATIONS'

export const SET_TEST_WEBHOOK_RESULT = 'SET_TEST_WEBHOOK_RESULT'

export const TEST_EXISTING_WEBHOOK = 'TEST_EXISTING_WEBHOOK'

export const requestWebhooksList = () => ({
  type: REQUEST_WEBHOOKS_LIST,
  payload: null,
})

export const requestGetWebhook = (id: string) => ({
  type: REQUEST_GET_WEBHOOK,
  payload: id,
})

export const requestTestWebhook = (
  webhookAttributes: Pick<
    WebhookModelAttributes,
    'name' | 'endpoint' | 'authentication'
  >
) => ({
  type: TEST_WEBHOOK,
  payload: webhookAttributes,
})

export const requestTestExistingWebhook = (webhookId: string) => ({
  type: TEST_EXISTING_WEBHOOK,
  payload: webhookId,
})

export const setWebhooksList = (list: Array<WebhookModel>) => ({
  type: SET_WEBHOOKS_LIST,
  payload: list,
})

export const setWebhook = (webhook: WebhookModel) => ({
  type: SET_WEBHOOK,
  payload: webhook,
})

export const addWebhook = (webhook: WebhookModel) => ({
  type: ADD_WEBHOOK,
  payload: webhook,
})

export const updateWebhook = (webhook: WebhookModel) => ({
  type: UPDATE_WEBHOOK,
  payload: webhook,
})

export const deleteWebhook = (id: string) => ({
  type: DELETE_WEBHOOK,
  payload: id,
})

export const removeSelectedWebhook = () => ({
  type: REMOVE_SELECTED_WEBHOOK,
  payload: null,
})

export const requestEventTypes = () => ({
  type: REQUEST_EVENT_TYPES,
  payload: null,
})

export const setEventTypes = (eventTypes: Array<EventType>) => ({
  type: SET_EVENT_TYPES,
  payload: eventTypes,
})

export const setTestWebhookResult = (result: boolean | undefined) => ({
  type: SET_TEST_WEBHOOK_RESULT,
  payload: result,
})

export const requestWebhooksInvocations = (
  webHooksIds: Array<string>,
  periods: Array<DefinedDateRangeTuple>
) => ({
  type: REQUEST_WEBHOOK_INVOCATIONS,
  payload: {
    webHooksIds,
    periods,
  },
})

export const setWebhooksInvocations = (
  payload: Array<WebhooksInvocations>
) => ({
  type: SET_WEBHOOK_INVOCATIONS,
  payload,
})
