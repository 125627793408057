import React, { memo } from 'react'
import { EngineCallback } from '@lib/engine-types'
import { Tabs, TabsProps } from '@mui/material'

interface Props extends Omit<TabsProps, 'value'> {
  onChangeTab?: EngineCallback<number>
  value: any // From MUI type
}

function CustomTabs(props: Props) {
  const { onChangeTab, ...allOtherProps } = props

  const onChangeInner = (e: React.ChangeEvent<any>, value: any) => {
    if (onChangeTab) {
      onChangeTab(Number(value))
    }
    if (props.onChange) {
      props.onChange(e, value)
    }
  }

  return (
    <Tabs
      {...allOtherProps}
      TabIndicatorProps={{ children: <span /> }}
      className={`v2Tabs ${allOtherProps.className ?? ''}`}
      indicatorColor="primary"
      textColor="primary"
      onChange={onChangeInner}
    />
  )
}

export default memo(CustomTabs)
