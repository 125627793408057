import { EngineCallbackPure, VIRow } from '@lib/engine-types'
import { useEffect, useState } from 'react'
import DemoService from '@lib/services/demo.service'
import DemoModeConstant from '@lib/constants/data/demo/demo-mode.constant'

interface UseDemoInputData {
  // callback if it is a demo domain
  runIfDemoDomain?: EngineCallbackPure
  // callback if at least one mode is active
  runIfModeActive?: EngineCallbackPure
  // we can specify the exact modes, bu default - DEV
  forMods?: VIRow
}

interface UseDemoOutputData {
  // we allow turning on modes only on the specific domains
  isDevDomain: boolean
  isNotDevDomain: boolean
  // if at least one of provided modes is active
  modeActive: boolean
  modeNotActive: boolean
  hideIfNotDemoClass: string
}

function useDemo({
  runIfDemoDomain = () => null,
  runIfModeActive = () => null,
  forMods = [DemoModeConstant.DEMO_DEV],
}: UseDemoInputData): UseDemoOutputData {
  const [isDevDomain, setIsDevDomain] = useState<boolean>(false)
  const [modeActive, setModeActive] = useState<boolean>(false)

  // by default we suppose that we are not in a demo mode, and on the
  // second rendering we are checking if it is a demo mode and then switch
  // parameters if it is needed
  // The main reason - pre rendering should be the same on the backend and front end
  // sides - so for this we are using "useEffect"
  useEffect(() => {
    if (DemoService.isDevDomain) {
      setIsDevDomain(true)
      runIfDemoDomain()
      // checking provided modes
      if (DemoService.isEnabledAny(forMods)) {
        setModeActive(true)
        runIfModeActive()
      }
    }
  }, [])

  return {
    isDevDomain,
    isNotDevDomain: !isDevDomain,
    modeActive,
    modeNotActive: !modeActive,
    hideIfNotDemoClass: modeActive ? '' : 'dNone',
  }
}

export default useDemo
