import { styled, Box } from '@mui/material'

export const StyledBox = styled(Box)`
  display: flex;
  padding: 100px 0 100px;
  align-items: center;
  justify-content: center;
  height: inherit;
  font-size: 24px;
  font-weight: 300;
`
