"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetSnapshotsVulnerabilitiesFilters = void 0;
const vulnerability_1 = require("../../models/vulnerability");
class GetSnapshotsVulnerabilitiesFilters {
    #snapshots;
    #riskFromQuery;
    constructor(parameters) {
        this.#snapshots = parameters.snapshots;
        this.#riskFromQuery = parameters.riskFromQuery;
    }
    execute(riskIdentificationStrategy) {
        const accountIds = new Set();
        const regions = new Set();
        const snapshotsVulnerabilitiesTypes = new Set();
        const riskLevel = new Set();
        if (this.#riskFromQuery !== undefined) {
            riskLevel.add(this.#riskFromQuery);
        }
        this.#getSnapshotsWithVulnerabilities().forEach((snapshot) => {
            accountIds.add(snapshot.awsAccountId);
            regions.add(snapshot.awsRegion);
            const vulnerabilitiesTypes = this.#getSnapshotVulnerabilities(snapshot);
            vulnerabilitiesTypes.forEach((vulnerability) => snapshotsVulnerabilitiesTypes.add(vulnerability));
            const uniqueRiskLevels = [
                ...new Set(vulnerabilitiesTypes.map(riskIdentificationStrategy.identifyRiskLevel)),
            ];
            uniqueRiskLevels.forEach((risk) => riskLevel.add(risk));
        });
        return {
            accountIds: [...accountIds],
            regions: [...regions],
            snapshotVulnerabilityTypes: [...snapshotsVulnerabilitiesTypes],
            riskType: [...riskLevel].sort(),
        };
    }
    #getSnapshotsWithVulnerabilities() {
        return this.#snapshots.filter((snapshot) => this.#getSnapshotVulnerabilities(snapshot).length);
    }
    #getSnapshotVulnerabilities(snapshot) {
        const snapshotVulnerabilities = [];
        if (snapshot.isPubliclyShared) {
            snapshotVulnerabilities.push(vulnerability_1.SnapshotVulnerabilityKind.PUBLIC_PERMISSIONS);
        }
        if (snapshot.isUnencrypted) {
            snapshotVulnerabilities.push(vulnerability_1.SnapshotVulnerabilityKind.UNENCRYPTED);
        }
        return snapshotVulnerabilities;
    }
}
exports.GetSnapshotsVulnerabilitiesFilters = GetSnapshotsVulnerabilitiesFilters;
