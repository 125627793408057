"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OvaAccount = void 0;
class OvaAccount {
    #id;
    #name;
    #backupProvidersList;
    #createdAt;
    #updatedAt;
    constructor(parameters) {
        const { id, name, backupProvidersList, createdAt, updatedAt } = parameters;
        this.#id = id;
        this.#name = name;
        this.#backupProvidersList = backupProvidersList;
        this.#createdAt = createdAt;
        this.#updatedAt = updatedAt;
    }
    get id() {
        return this.#id;
    }
    get name() {
        return this.#name;
    }
    get backupProvidersList() {
        return this.#backupProvidersList;
    }
    get createdAt() {
        return this.#createdAt;
    }
    get updatedAt() {
        return this.#updatedAt;
    }
}
exports.OvaAccount = OvaAccount;
