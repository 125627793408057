"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scanRansomwareFactory = void 0;
const fishery_1 = require("fishery");
const ransomware_1 = require("../../models/scans/ransomware");
const factories_1 = require("../../use_cases/tests/factories");
const common_1 = require("./common");
const types_1 = require("../../models/scans/ransomware/types");
class ScanRansomwareFactory extends fishery_1.Factory {
    withScanTarget(scanTarget) {
        return this.transient({
            scanTarget,
        });
    }
}
const status = () => (0, common_1.randomValueFromEnum)(types_1.RansomwareScanStatus);
const summary = () => ({
    errors: Math.floor(Math.random() * 100 + 1),
    totalFiles: Math.floor(Math.random() * 100 + 1),
    totalRansomware: Math.floor(Math.random() * 100 + 1),
});
const threatsIds = new Array(10).fill(factories_1.uuid);
function scanTarget() {
    return new ransomware_1.RansomwareScanTarget({
        assetId: (0, factories_1.uuid)(),
    });
}
exports.scanRansomwareFactory = ScanRansomwareFactory.define(({ transientParams }) => {
    return new ransomware_1.RansomwareScan({
        id: transientParams.id ?? (0, factories_1.uuid)(),
        scanTarget: transientParams.scanTarget ?? scanTarget(),
        status: transientParams.status ?? status(),
        jobId: transientParams.jobId ?? (0, factories_1.uuid)(),
        finishedAt: transientParams.finishedAt ?? (0, common_1.date)(),
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
        summary: transientParams.summary ?? summary(),
        threatIds: transientParams.threatIds ?? threatsIds,
    });
});
