/* eslint-disable import/no-extraneous-dependencies */
import ValueInterface from '@lib/interfaces/value.interface'
import { EngineFunction } from '@lib/engine-types'
import LangHelper from '@lib/helpers/lang.helper'
import { AssetKind } from '@lib/constants/grpc/asset-kind.constant'
import { CloudConnectorStatus } from '@lib/constants/cloud-connector-status.constant'
import { FilterIdForReports, ReportFilterId } from './report-filter.constant'
import ReportOriginConstant from '../data/report/report-origin.constant'
import DataHelper from '@lib/helpers/data.helper'
import TypeHelper from '@lib/helpers/type.helper'
import JobKindConstant, { getJobKindName } from '../jobs/job-kind.constant'
import SystemHelper from '@lib/helpers/system.helper'
import JobStatusConstant, {
  getJobStatusName,
} from '../jobs/job-status.constant'
import ArrHelper from '@lib/helpers/arr.helper'
import {
  getIntegrityCheckStatusName,
  IntegrityCheckStatusConstant,
} from '../integrity-check-status.constant'
import { PoliciesFilterLabel } from '@lib/constants/policies.constant'
import StrHelper from '@lib/helpers/str.helper'
import { getRiskTypeName } from '../data/data-item/data-item-risk.type.constant'
import { Asset, GenericHost, RiskLevel } from 'blues-corejs/dist'
import {
  FilterNamesConstant,
  getFsCheckScanStatusName,
  getScanStatusName,
  getScanTargetKindName,
} from '../filters/filter-names.constant'
import { ScanTargetKind, Status } from '@lib/clients/scans/types'
import { Asset as AssetType } from '@lib/clients/assets/list-assets-by-ids'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'

interface AssetDataInterface {
  kind: number
  label: string
  field: string
}

interface FilterDataInterface {
  kind: number
  value: ValueInterface
  getFieldFunc: EngineFunction<any, Array<ValueInterface>>
}

//-----------------------------------------------------------
// took from
// blue-stack-libs/catalog-grpc-libs/js/catalog/messages/filters_pb
enum FilterId {
  UNDEFINED = -1,
  ACCOUNT_IDS = 0,
  ASSET_KINDS = 1,
  INSTANCE_IDS = 2,
  INSTANCE_TYPES = 3,
  REGIONS = 4,
  VOLUME_TYPES = 5,
  VPC_IDS = 6,
  RP_STATUSES = 7,
  VOLUME_IDS = 8,
  ASSET_TAGS = 9,
  CLOUD_CONNECTOR_STATUSES = 10,
  PROTECTED = 11,
  PAUSED_PLANS = 12,
  VAULTS = 13,
  QUARANTINED = 14,
  POLICIES = 15,
  INTEGRITY_CHECK_STATUSES = 16,
  ASSOCIATED_RETENTION_POLICIES = 17,
  COVERED_BY_ANY_BACKUP_POLICY = 18,
  FS_CHECK_INTEGRITY_STATUSES = 19,
  ISCAN_INTEGRITY_STATUSES = 20,
  BUCKET_IDS = 21,
  CLOUD_CONNECTOR_IDS = 22,
  PAUSED_INSTANCES = 23,
}

//-----------------------------------------------------------
// taken from
// blue-stack-libs/jobstatus-grpc-libs/js/jobstatus/messages/filters_pb.d.ts
export enum JobFilterId {
  UNDEFINED = -1,
  JOB_KINDS = 0,
  ACCOUNT_IDS = 1,
  ASSET_KINDS = 2,
  INSTANCE_IDS = 3,
  REGIONS = 4,
  VOLUME_IDS = 5,
  STATUSES = 6,
}

//-----------------------------------------------------------

// took from
// blue-stack-libs/catalog-grpc-libs/js/catalog/messages/iscan_reports_pd.d.ts

export enum IscanReportStatus {
  NONE = 0,
  OK = 1,
  MALWARE = 2,
  RANSOMWARE = 3,
  MALWARE_AND_RANSOMWARE = 4,
}

const infectedStatuses = [
  IscanReportStatus.RANSOMWARE,
  IscanReportStatus.MALWARE,
  IscanReportStatus.MALWARE_AND_RANSOMWARE,
]

const cleanStatuses = [IscanReportStatus.OK]

//-----------------------------------------------------------

enum FilterIdDashboard {
  UNDEFINED = -1,
  ACCOUNT_IDS = 0,
  REGIONS = 1,
  COVERED = 2,
  MALWARES = 3,
  RANSOMWARES = 4,
  SNAPSHOT_VULNERABILITY_TYPES = 5,
  EBS_VULNERABILITIES = 6,
  PROTECTED = 7,
  OS_KINDS = 8,
  FS_CHECK = 9,
  RISK_TYPE = 10,
  BACKUPS_ON_SCHEDULE = 11,
  STATE = 12,
  ASSET_ITEM_KIND = 13,
  VOLUME_AWS_ID = 14,
  ATTACHED_INSTANCE_AWS_ID = 15,
}

// -------------------------------------------------------------

const AssetsData = new Map<AssetKind, AssetDataInterface>()

AssetsData.set(AssetKind.AWS_EC2, {
  kind: AssetKind.AWS_EC2,
  label: LangHelper.getAssetKindSingleTranslation(AssetKind.AWS_EC2),
  field: 'awsEc2',
})

AssetsData.set(AssetKind.AZURE_VM, {
  kind: AssetKind.AZURE_VM,
  label: LangHelper.getAssetKindSingleTranslation(AssetKind.AZURE_VM),
  field: 'azureVm',
})

AssetsData.set(AssetKind.VM_WARE_VM, {
  kind: AssetKind.VM_WARE_VM,
  label: LangHelper.getAssetKindSingleTranslation(AssetKind.VM_WARE_VM),
  field: 'vmWareVm',
})

AssetsData.set(AssetKind.AWS_EBS, {
  kind: AssetKind.AWS_EBS,
  label: LangHelper.getAssetKindSingleTranslation(AssetKind.AWS_EBS),
  field: 'awsEbs',
})

AssetsData.set(AssetKind.AWS_EFS, {
  kind: AssetKind.AWS_EFS,
  label: LangHelper.getAssetKindSingleTranslation(AssetKind.AWS_EFS),
  field: 'awsEfs',
})

AssetsData.set(AssetKind.GENERIC_HOST, {
  kind: AssetKind.GENERIC_HOST,
  label: LangHelper.getAssetKindSingleTranslation(AssetKind.GENERIC_HOST),
  field: 'genericHost',
})

AssetsData.set(AssetKind.GENERIC_FS, {
  kind: AssetKind.GENERIC_FS,
  label: LangHelper.getAssetKindSingleTranslation(AssetKind.GENERIC_FS),
  field: 'genericFs',
})

AssetsData.set(AssetKind.AWS_S3, {
  kind: AssetKind.AWS_S3,
  label: LangHelper.getAssetKindSingleTranslation(AssetKind.AWS_S3),
  field: 'awsS3',
})

//-----------------------------------------------------------

function defaultString(params: any): Array<ValueInterface> {
  return params.str.itemsList.map((v: string) => ({
    type: 1,
    name: v,
    label: v,
    value: '',
  }))
}

function defaultKind(params: any): Array<ValueInterface> {
  return params.num.itemsList.map((v: number) => {
    const ak = AssetsData.get(v as AssetKind)
    if (!ak) {
      return {
        name: '',
        label: '',
        value: '',
      }
    }
    return {
      type: 1,
      name: ak.kind.toString(),
      label: ak.label,
      value: '',
    }
  })
}

function defaultJobStatus(params: any): Array<ValueInterface> {
  return params.num.itemsList.map((v: number) => {
    return {
      type: 1,
      name: v.toString(),
      label: getJobStatusName(v as JobStatusConstant),
      value: '',
    }
  })
}

function getCloudConnectorStatusLabel(value: number): string {
  const cloudConnector = CloudConnectorStatus[value]
  if (!cloudConnector) {
    return ''
  }
  const statusName = cloudConnector.toLowerCase()
  if (statusName.length > 0) {
    return statusName[0]?.toUpperCase() + statusName.slice(1)
  }
  return statusName
}

function defaultConnectedStatus(params: any): Array<ValueInterface> {
  return params.num.itemsList.map((v: number) => ({
    type: 1,
    name: v.toString(),
    label: getCloudConnectorStatusLabel(v),
    value: '',
  }))
}

function defaultProtected(params: any): Array<ValueInterface> {
  return params.bool.itemsList.map((v: boolean) => ({
    type: 1,
    name: v.toString(),
    label: v ? PoliciesFilterLabel.PROTECTED : PoliciesFilterLabel.UNPROTECTED,
    value: '',
  }))
}

function defaultReportOrigin(params: any): Array<ValueInterface> {
  return params.num.itemsList.map((v: number) => ({
    type: 1,
    name: v.toString(),
    label: ReportOriginConstant.getByValue(v).label,
    value: '',
  }))
}

function defaultRegion(params: any): Array<ValueInterface> {
  return params.str.itemsList.map((v: string) => ({
    type: 1,
    name: v,
    label: LangHelper.getAwsRegionSingleTranslation(v),
    value: '',
  }))
}

function defaultReportStatus(params: any): Array<ValueInterface> {
  const infectedArr = params.num.itemsList.filter((v: number) =>
    infectedStatuses.includes(v)
  )
  const cleanArr = params.num.itemsList.filter((v: number) =>
    cleanStatuses.includes(v)
  )

  const result = []
  if (cleanArr.length) {
    result.push({
      type: 1,
      name: cleanArr.join(''),
      label: 'Clean',
      value: '',
    })
  }
  if (infectedArr.length) {
    result.push({
      type: 1,
      name: infectedArr.join(''),
      label: 'Infected',
      value: '',
    })
  }

  return result
}

function defaultProtectedObject(params: any): Array<ValueInterface> {
  return ArrHelper.uniqueBy(
    params.str.itemsList.map((v: string) => ({
      type: 1,
      name: v,
      label: DataHelper.protectedObjectIdParsed(v),
      value: '',
    })),
    'label'
  )
}

function defaultJobKind(params: any): Array<ValueInterface> {
  return params.num.itemsList.map((kind: any) => {
    const kindTyped = TypeHelper.enumValDef(
      kind,
      JobKindConstant.UNDEFINED,
      JobKindConstant
    )
    if (kindTyped === JobKindConstant.UNDEFINED) {
      SystemHelper.throwErrorInLocalEnv(
        `Cannot parse the job kind in the factory - ${kind}`
      )
    }
    return {
      type: 1,
      name: String(kind),
      label: getJobKindName(kindTyped),
      value: false,
    }
  })
}

function defaultPaused(params: any): Array<ValueInterface> {
  return params.bool.itemsList.map((v: boolean) => ({
    type: 1,
    name: v.toString(),
    label: v ? 'Paused' : 'Running',
    value: '',
  }))
}

function defaultVault(params: any): Array<ValueInterface> {
  return params.vault.itemsList.map((v: any) => ({
    type: 1,
    name: v.id,
    label: `${v.vaultName} (Account ID: ${v.accountId}, Region: ${v.region})`,
    value: '',
  }))
}

// function defaultQuarantined(params: any): Array<ValueInterface> {
//   return params.bool.itemsList.map((v: boolean) => ({
//     type: 1,
//     name: v.toString(),
//     label: v ? 'Unhealthy' : 'Healthy',
//     value: '',
//   }))
// }

function defaultIntegrityCheckStatus(params: any): Array<ValueInterface> {
  return params.num.itemsList.map((v: number) => {
    return {
      type: 1,
      name: v.toString(),
      label: getIntegrityCheckStatusName(v as IntegrityCheckStatusConstant),
      value: '',
    }
  })
}

function defaultCoveredByAnyBackupPolicy(params: any): Array<ValueInterface> {
  return params.bool.itemsList.map((v: boolean) => ({
    type: 1,
    name: v.toString(),
    label: v
      ? 'Covered by a protection policy'
      : 'Not covered by a protection policy',
    value: '',
  }))
}

function defaultCoveredByPolicy(params: any): Array<ValueInterface> {
  return params.covered?.map((v: boolean) => ({
    type: 1,
    name: v.toString(),
    label: v ? 'Covered' : 'Uncovered',
    value: '',
  }))
}

function defaultBackupsOnSchedule(params: any): Array<ValueInterface> {
  return params.backupsOnSchedule?.map((v: boolean) => ({
    type: 1,
    name: v.toString(),
    label: v ? 'No' : 'Yes',
    value: '',
  }))
}

function defaultAccountId(params: any): Array<ValueInterface> {
  return params.accountIds?.map((v: string) => ({
    type: 1,
    name: v,
    label: v,
    value: '',
  }))
}

function defaultVolumeAwsId(params: any): Array<ValueInterface> {
  return params.volumeAwsIds?.map((v: string) => ({
    type: 1,
    name: v,
    label: v,
    value: '',
  }))
}

function defaultInstanceAwsId(params: any): Array<ValueInterface> {
  return params.attachedInstancesAwsIds?.map((v: string) => ({
    type: 1,
    name: v,
    label: v,
    value: '',
  }))
}

function defaultRegionForScrollFilter(params: any): Array<ValueInterface> {
  return params.regions?.map((v: string) => ({
    type: 1,
    name: v,
    label: LangHelper.getAwsRegionSingleTranslation(v),
    value: '',
  }))
}

function defaultCoveredBySnapshots(params: any): Array<ValueInterface> {
  return params.covered?.map((v: boolean) => ({
    type: 1,
    name: v.toString(),
    label: v ? 'Covered' : 'Not covered',
    value: '',
  }))
}

function defaultProtectedByLastRP(params: any): Array<ValueInterface> {
  return params.protected?.map((v: boolean) => ({
    type: 1,
    name: v.toString(),
    label: v ? 'Protected' : 'Unprotected',
    value: '',
  }))
}

function defaultEbsVulnerabilitiesFilter(params: any): Array<ValueInterface> {
  return params.ebsVulnerabilities?.map((v: string) => ({
    type: 1,
    name: v,
    label: v,
    value: '',
  }))
}

function defaultMalwares(params: any): Array<ValueInterface> {
  return params.malwares?.map((v: string) => ({
    type: 1,
    name: v,
    label: v,
    value: '',
  }))
}

function defaultRansomwares(params: any): Array<ValueInterface> {
  return params.ransomwares?.map((v: string) => ({
    type: 1,
    name: v,
    label: v,
    value: '',
  }))
}

function defaultSnapshotVulnerabilityType(params: any): Array<ValueInterface> {
  return params.snapshotVulnerabilityTypes?.map((v: string) => ({
    type: 1,
    name: v,
    label: v,
    value: '',
  }))
}

function defaultOsKind(params: any): Array<ValueInterface> {
  return params.osKinds?.map((kind: any) => {
    return {
      type: 1,
      name: String(kind),
      label: StrHelper.getOsNameByType(kind),
      value: '',
    }
  })
}

function defaultFsCheckStatus(params: any): Array<ValueInterface> {
  return params.fsCheck?.map((v: string) => ({
    type: 1,
    name: v,
    label: v,
    value: '',
  }))
}

function defaultRiskType(params: any): Array<ValueInterface> {
  return params.riskType?.map((rl: RiskLevel) => ({
    type: 1,
    name: String(rl),
    label: getRiskTypeName(rl),
    value: '',
  }))
}

function defaultInstanceIds(params: any): Array<ValueInterface> {
  return params.instanceIds?.map((v: string) => ({
    type: 1,
    name: v,
    label: v,
    value: '',
  }))
}

function defaultInstanceTypes(params: any): Array<ValueInterface> {
  return params.instanceTypes?.map((v: string) => ({
    type: 1,
    name: v,
    label: v,
    value: '',
  }))
}

function defaultVolumeIds(params: any): Array<ValueInterface> {
  return params.volumeIds?.map((v: string) => ({
    type: 1,
    name: v,
    label: v,
    value: '',
  }))
}

function defaultVolumeTypes(params: any): Array<ValueInterface> {
  return params.volumeTypes?.map((v: string) => ({
    type: 1,
    name: v,
    label: v,
    value: '',
  }))
}

function defaultVpcIds(params: any): Array<ValueInterface> {
  return params.vpcIds?.map((v: string) => ({
    type: 1,
    name: v,
    label: v,
    value: '',
  }))
}

function defaultTags(params: any): Array<ValueInterface> {
  return params.tags?.map((v: string) => ({
    type: 1,
    name: v,
    label: v,
    value: '',
  }))
}

function defaultProtectedAsset(params: any): Array<ValueInterface> {
  return params.isProtected?.map((v: boolean) => ({
    type: 1,
    name: v.toString(),
    label: v ? 'Protected' : 'Unprotected',
    value: '',
  }))
}

function defaultPausedInstance(params: any): Array<ValueInterface> {
  return params.isPausedInstances?.map((v: boolean) => ({
    type: 1,
    name: v.toString(),
    label: v ? 'Paused' : 'Running',
    value: '',
  }))
}

function defaultUseState(params: any): Array<ValueInterface> {
  return params.state?.map((v: boolean) => ({
    type: 1,
    name: v.toString(),
    label: v ? 'In use' : 'Deleted',
    value: '',
  }))
}

function defaultAssetItemKind(params: any): Array<ValueInterface> {
  return params.assetItemKind?.map((kind: any) => {
    return {
      type: 1,
      name: String(kind),
      label: StrHelper.getAssetItemNameByType(kind),
      value: '',
    }
  })
}

//Scan Reports
function defaultScanTargetKind(params: any): Array<ValueInterface> {
  return params.scanTargetKindsList.map((kind: ScanTargetKind) => ({
    type: 1,
    name: kind.toString(),
    label: getScanTargetKindName(kind),
    value: '',
  }))
}

function defaultAccountIdsList(params: any): Array<ValueInterface> {
  return params.accountIdsList?.map((accountId: string) => ({
    type: 1,
    name: accountId,
    label: accountId,
    value: '',
  }))
}

function defaultScanStatus(params: any): Array<ValueInterface> {
  return params.statusesList.map((status: Status) => ({
    type: 1,
    name: status.toString(),
    label: getScanStatusName(status),
    value: '',
  }))
}

function defaultRegionList(params: any): Array<ValueInterface> {
  return params.regionsList.map((region: string) => ({
    type: 1,
    name: region,
    label: LangHelper.getAwsRegionSingleTranslation(region),
    value: '',
  }))
}

function defaultAssetIdObject(params: any): Array<ValueInterface> {
  return ArrHelper.uniqueBy(
    params.assetIdList.map((assetObject: AssetType) => {
      if (assetObject.genericHost) {
        return {
          type: 1,
          name: assetObject.genericHost.id,
          label: assetObject.genericHost.hostname,
          value: '',
        }
      } else {
        const awsAsset =
          assetObject.ebsVolume ||
          assetObject.ec2Instance ||
          assetObject.efsFilesystem ||
          assetObject.s3Bucket
        if (awsAsset) {
          return {
            type: 1,
            name: awsAsset.id,
            label: awsAsset.awsId,
            value: '',
          }
        }
      }
    }),
    'name'
  )
}

function defaultAssetId(params: any): Array<ValueInterface> {
  return ArrHelper.uniqueBy(
    params.assetIdList.map((assetObject: Asset) => {
      if (assetObject instanceof GenericHost) {
        return {
          type: 1,
          name: assetObject.id,
          label: assetObject.hostname,
          value: '',
        }
      } else {
        if (assetObject instanceof AWSAsset) {
          return {
            type: 1,
            name: assetObject.id,
            label: assetObject.awsId,
            value: '',
          }
        }
      }
    }),
    'name'
  )
}

function defaultFsCheckScanStatus(params: any): Array<ValueInterface> {
  return params.statusesList.map((status: Status) => ({
    type: 1,
    name: status.toString(),
    label: getFsCheckScanStatusName(status),
    value: '',
  }))
}

// --------------------------------------------------------------------

const JobStatusFilterData = new Map<JobFilterId, FilterDataInterface>()

JobStatusFilterData.set(JobFilterId.JOB_KINDS, {
  kind: 0,
  value: {
    name: 'kinds',
    label: 'Job kind',
  },
  getFieldFunc: defaultJobKind,
})

JobStatusFilterData.set(JobFilterId.ACCOUNT_IDS, {
  kind: 1,
  value: {
    name: 'accountIds',
    label: 'Account Ids',
    value: '',
  },
  getFieldFunc: defaultString,
})

JobStatusFilterData.set(JobFilterId.ASSET_KINDS, {
  kind: 2,
  value: {
    name: 'assetsKind',
    label: 'Asset Kinds',
    value: '',
  },
  getFieldFunc: defaultKind,
})

JobStatusFilterData.set(JobFilterId.INSTANCE_IDS, {
  kind: 3,
  value: {
    name: 'instanceIds',
    label: 'Instance Ids',
    value: '',
  },
  getFieldFunc: defaultString,
})

JobStatusFilterData.set(JobFilterId.REGIONS, {
  kind: 3,
  value: {
    name: 'regions',
    label: 'Regions',
    value: '',
  },
  getFieldFunc: defaultString,
})

JobStatusFilterData.set(JobFilterId.VOLUME_IDS, {
  kind: 4,
  value: {
    name: 'volumeIds',
    label: 'Volume IDs',
    value: '',
  },
  getFieldFunc: defaultString,
})

JobStatusFilterData.set(JobFilterId.STATUSES, {
  kind: 5,
  value: {
    name: 'jobStatuses',
    label: 'Job Statuses',
    value: '',
  },
  getFieldFunc: defaultJobStatus,
})

// ---------------------------------------------------------

const FilterData = new Map<FilterId, FilterDataInterface>()

FilterData.set(FilterId.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account ID',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultString,
})

FilterData.set(FilterId.ASSET_KINDS, {
  kind: 1,
  value: {
    name: 'assetsKind',
    label: 'Asset Kinds',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultKind,
})

FilterData.set(FilterId.INSTANCE_IDS, {
  kind: 2,
  value: {
    name: 'instanceIds',
    label: 'Instance Ids',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultString,
})

FilterData.set(FilterId.INSTANCE_TYPES, {
  kind: 3,
  value: {
    name: 'instanceTypes',
    label: 'Instance Types',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultString,
})

FilterData.set(FilterId.REGIONS, {
  kind: 4,
  value: {
    name: 'regions',
    label: 'Regions',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultString,
})

FilterData.set(FilterId.VOLUME_TYPES, {
  kind: 5,
  value: {
    name: 'volumeTypes',
    label: 'Volume Types',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultString,
})

FilterData.set(FilterId.VPC_IDS, {
  kind: 6,
  value: {
    name: 'vpcIds',
    label: 'VPC IDs',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultString,
})

// Hide RP_STATUSES filter on UI

// FilterData.set(FilterId.RP_STATUSES, {
//   kind: 7,
//   value: {
//     name: 'rpStatuses',
//     label: 'RP Statuses',
//     value: '',
//   },
//   getFieldFunc: defaultKind,
// })

FilterData.set(FilterId.VOLUME_IDS, {
  kind: 8,
  value: {
    name: 'volumeIds',
    label: 'Volume IDs',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultString,
})

FilterData.set(FilterId.ASSET_TAGS, {
  kind: 9,
  value: {
    name: 'assetTags',
    label: 'Asset Tags',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultString,
})

FilterData.set(FilterId.CLOUD_CONNECTOR_STATUSES, {
  kind: 10,
  value: {
    name: 'cloudConnectorStatuses',
    label: 'Cloud Connector Statuses',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultConnectedStatus,
})

FilterData.set(FilterId.PROTECTED, {
  kind: 11,
  value: {
    name: 'protected',
    label: 'Protection',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultProtected,
})

FilterData.set(FilterId.PAUSED_PLANS, {
  kind: 12,
  value: {
    name: 'pausedPlans',
    label: 'Policies State',
    value: '',
  },
  getFieldFunc: defaultPaused,
})

FilterData.set(FilterId.VAULTS, {
  kind: 13,
  value: {
    name: 'vaults',
    label: 'Vaults',
    value: '',
  },
  getFieldFunc: defaultVault,
})

// TODO 'quarantined' should be completely removed
// after this filter will be removed from API
// FilterData.set(FilterId.QUARANTINED, {
//   kind: 14,
//   value: {
//     name: 'quarantined',
//     label: 'Iscan Status',
//     value: '',
//   },
//   getFieldFunc: defaultQuarantined,
// })

FilterData.set(FilterId.POLICIES, {
  kind: 15,
  value: {
    name: 'policies',
    label: 'Policies',
    value: '',
  },
  getFieldFunc: defaultString,
})

FilterData.set(FilterId.INTEGRITY_CHECK_STATUSES, {
  kind: 16,
  value: {
    name: 'integrityCheckStatuses',
    label: 'Integrity Check Statuses',
    value: '',
  },
  getFieldFunc: defaultIntegrityCheckStatus,
})

FilterData.set(FilterId.ASSOCIATED_RETENTION_POLICIES, {
  kind: 17,
  value: {
    name: 'associatedRetentionPoliciesList',
    label: 'Associated Retention Policies List',
    value: '',
  },
  getFieldFunc: defaultString,
})

FilterData.set(FilterId.COVERED_BY_ANY_BACKUP_POLICY, {
  kind: 18,
  value: {
    name: 'coveredByAnyBackupPolicy',
    label: 'Covered By Any Protection Policy',
    value: '',
  },
  getFieldFunc: defaultCoveredByAnyBackupPolicy,
})

FilterData.set(FilterId.BUCKET_IDS, {
  kind: 19,
  value: {
    name: 's3bucket',
    label: 'S3 Bucket',
    value: '',
  },
  getFieldFunc: defaultString,
})
//-----------------------------------------------------------
const AssetsSelectedFilterData = new Map<FilterId, FilterDataInterface>()

AssetsSelectedFilterData.set(FilterId.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultString,
})

AssetsSelectedFilterData.set(FilterId.INSTANCE_IDS, {
  kind: 2,
  value: {
    name: 'instanceIds',
    label: 'Instance',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultString,
})

AssetsSelectedFilterData.set(FilterId.INSTANCE_TYPES, {
  kind: 3,
  value: {
    name: 'instanceTypes',
    label: 'Instance Type',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultString,
})

AssetsSelectedFilterData.set(FilterId.REGIONS, {
  kind: 4,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultString,
})

AssetsSelectedFilterData.set(FilterId.VOLUME_TYPES, {
  kind: 5,
  value: {
    name: 'volumeTypes',
    label: 'Volume Type',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultString,
})

AssetsSelectedFilterData.set(FilterId.VPC_IDS, {
  kind: 6,
  value: {
    name: 'vpcIds',
    label: 'VPC',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultString,
})

AssetsSelectedFilterData.set(FilterId.VOLUME_IDS, {
  kind: 8,
  value: {
    name: 'volumeIds',
    label: 'Volume',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultString,
})

AssetsSelectedFilterData.set(FilterId.ASSET_TAGS, {
  kind: 9,
  value: {
    name: 'assetTags',
    label: 'Asset Tag',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultString,
})

// Hide Cloud Connector status

// AssetsSelectedFilterData.set(FilterId.CLOUD_CONNECTOR_STATUSES, {
//   kind: 10,
//   value: {
//     name: 'cloudConnectorStatuses',
//     label: 'Cloud Connector Statuses',
//     value: '',
//     type: 1,
//   },
//   getFieldFunc: defaultConnectedStatus,
// })

AssetsSelectedFilterData.set(FilterId.PROTECTED, {
  kind: 11,
  value: {
    name: 'protected',
    label: 'Protection',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultProtected,
})

AssetsSelectedFilterData.set(FilterId.PAUSED_PLANS, {
  kind: 12,
  value: {
    name: 'pausedPlans',
    label: 'Policy State',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultPaused,
})

AssetsSelectedFilterData.set(FilterId.VAULTS, {
  kind: 13,
  value: {
    name: 'vaults',
    label: 'Vault',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultVault,
})

// TODO 'quarantined' should be completely removed
// after this filter will be removed from API
// AssetsSelectedFilterData.set(FilterId.QUARANTINED, {
//   kind: 14,
//   value: {
//     name: 'quarantined',
//     label: 'Iscan Status',
//     value: '',
//     type: 1,
//   },
//   getFieldFunc: defaultQuarantined,
// })

AssetsSelectedFilterData.set(FilterId.INTEGRITY_CHECK_STATUSES, {
  kind: 16,
  value: {
    name: 'integrityCheckStatuses',
    label: 'Integrity Check Status',
    value: '',
  },
  getFieldFunc: defaultIntegrityCheckStatus,
})

AssetsSelectedFilterData.set(FilterId.ASSOCIATED_RETENTION_POLICIES, {
  kind: 17,
  value: {
    name: 'associatedRetentionPoliciesList',
    label: 'Associated Retention Policies List',
    value: '',
  },
  getFieldFunc: defaultString,
})

AssetsSelectedFilterData.set(FilterId.COVERED_BY_ANY_BACKUP_POLICY, {
  kind: 18,
  value: {
    name: 'coveredByAnyBackupPolicy',
    label: 'Covered By Any Protection Policy',
    value: '',
  },
  getFieldFunc: defaultCoveredByAnyBackupPolicy,
})

AssetsSelectedFilterData.set(FilterId.CLOUD_CONNECTOR_IDS, {
  kind: 22,
  value: {
    name: 'cloudConnectorIds',
    label: 'Cloud Connector IDs',
    value: '',
  },
  getFieldFunc: defaultString,
})

//-----------------------------------------------------------
const AssetsS3SelectedFilterData = new Map<FilterId, FilterDataInterface>()

AssetsS3SelectedFilterData.set(FilterId.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account ID',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

AssetsS3SelectedFilterData.set(FilterId.REGIONS, {
  kind: 4,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

AssetsS3SelectedFilterData.set(FilterId.ASSET_TAGS, {
  kind: 9,
  value: {
    name: 'tags',
    label: 'Asset Tag',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultTags,
})

AssetsS3SelectedFilterData.set(FilterId.PROTECTED, {
  kind: 11,
  value: {
    name: 'isProtected',
    label: 'Protection',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultProtectedAsset,
})

// ---------------------------------------------------------

//-----------------------------------------------------------
const AssetsEfsSelectedFilterData = new Map<FilterId, FilterDataInterface>()

AssetsEfsSelectedFilterData.set(FilterId.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account ID',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

AssetsEfsSelectedFilterData.set(FilterId.REGIONS, {
  kind: 4,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

AssetsEfsSelectedFilterData.set(FilterId.ASSET_TAGS, {
  kind: 9,
  value: {
    name: 'tags',
    label: 'Asset Tag',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultTags,
})

AssetsEfsSelectedFilterData.set(FilterId.PROTECTED, {
  kind: 11,
  value: {
    name: 'isProtected',
    label: 'Protection',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultProtectedAsset,
})

// ---------------------------------------------------------
const ReportFilterData = new Map<ReportFilterId, FilterDataInterface>()

ReportFilterData.set(ReportFilterId.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account',
    value: '',
  },
  getFieldFunc: defaultString,
})

ReportFilterData.set(ReportFilterId.REGION_NAMES, {
  kind: 1,
  value: {
    name: 'regionOrigins',
    label: 'Region',
    value: '',
  },
  getFieldFunc: defaultRegion,
})

ReportFilterData.set(ReportFilterId.REPORT_ORIGINS, {
  kind: 2,
  value: {
    name: 'reportOrigins',
    label: 'Report Origins',
    value: '',
  },
  getFieldFunc: defaultReportOrigin,
})

ReportFilterData.set(ReportFilterId.HUMAN_READABLE_RECOVERY_POINT_ID, {
  kind: 3,
  value: {
    name: 'humanReadableRecoveryPointId',
    label: 'Recovery Point ID',
    value: '',
  },
  getFieldFunc: defaultString,
})

ReportFilterData.set(ReportFilterId.PROTECTED_OBJECT, {
  kind: 4,
  value: {
    name: 'protectedObject',
    label: 'Protected Object',
    value: '',
  },
  getFieldFunc: defaultProtectedObject,
})

ReportFilterData.set(ReportFilterId.STATUSES, {
  kind: 5,
  value: {
    name: 'statuses',
    label: 'Statuses',
    value: '',
  },
  getFieldFunc: defaultReportStatus,
})

// ---------------------------------------------------------
const FsCheckScanReportFilterData = new Map<
  FilterIdForReports,
  FilterDataInterface
>()

FsCheckScanReportFilterData.set(FilterIdForReports.ACCOUNT_IDS_LIST, {
  kind: FilterIdForReports.ACCOUNT_IDS_LIST,
  value: {
    name: FilterNamesConstant.ACCOUNT_IDS_LIST,
    label: 'Account',
    value: '',
  },
  getFieldFunc: defaultAccountIdsList,
})

FsCheckScanReportFilterData.set(FilterIdForReports.REGIONS_LIST, {
  kind: FilterIdForReports.REGIONS_LIST,
  value: {
    name: FilterNamesConstant.REGIONS_LIST,
    label: 'Region',
    value: '',
  },
  getFieldFunc: defaultRegionList,
})

FsCheckScanReportFilterData.set(FilterIdForReports.SCAN_TARGET_KINDS_LIST, {
  kind: FilterIdForReports.SCAN_TARGET_KINDS_LIST,
  value: {
    name: FilterNamesConstant.SCAN_TARGET_KINDS_LIST,
    label: 'Report Origins',
    value: '',
  },
  getFieldFunc: defaultScanTargetKind,
})

FsCheckScanReportFilterData.set(FilterIdForReports.ASSET_ID_LIST, {
  kind: FilterIdForReports.ASSET_ID_LIST,
  value: {
    name: FilterNamesConstant.ASSET_ID_LIST,
    label: 'Protected Object',
    value: '',
  },
  getFieldFunc: defaultAssetId,
})

FsCheckScanReportFilterData.set(FilterIdForReports.STATUSES_LIST, {
  kind: FilterIdForReports.STATUSES_LIST,
  value: {
    name: FilterNamesConstant.STATUSES_LIST,
    label: 'Statuses',
    value: '',
  },
  getFieldFunc: defaultFsCheckScanStatus,
})

// ---------------------------------------------------------
const IscanReportFilterData = new Map<FilterIdForReports, FilterDataInterface>()

IscanReportFilterData.set(FilterIdForReports.ACCOUNT_IDS_LIST, {
  kind: FilterIdForReports.ACCOUNT_IDS_LIST,
  value: {
    name: FilterNamesConstant.ACCOUNT_IDS_LIST,
    label: 'Account',
    value: '',
  },
  getFieldFunc: defaultAccountIdsList,
})

IscanReportFilterData.set(FilterIdForReports.REGIONS_LIST, {
  kind: FilterIdForReports.REGIONS_LIST,
  value: {
    name: FilterNamesConstant.REGIONS_LIST,
    label: 'Region',
    value: '',
  },
  getFieldFunc: defaultRegionList,
})

IscanReportFilterData.set(FilterIdForReports.SCAN_TARGET_KINDS_LIST, {
  kind: FilterIdForReports.SCAN_TARGET_KINDS_LIST,
  value: {
    name: FilterNamesConstant.SCAN_TARGET_KINDS_LIST,
    label: 'Report Origins',
    value: '',
  },
  getFieldFunc: defaultScanTargetKind,
})

IscanReportFilterData.set(FilterIdForReports.ASSET_ID_LIST, {
  kind: FilterIdForReports.ASSET_ID_LIST,
  value: {
    name: FilterNamesConstant.ASSET_ID_LIST,
    label: 'Protected Object',
    value: '',
  },
  getFieldFunc: defaultAssetIdObject,
})

IscanReportFilterData.set(FilterIdForReports.STATUSES_LIST, {
  kind: FilterIdForReports.STATUSES_LIST,
  value: {
    name: FilterNamesConstant.STATUSES_LIST,
    label: 'Statuses',
    value: '',
  },
  getFieldFunc: defaultScanStatus,
})

//-----------------------------------------------------------
const VolumeSnapshotCoverageFilterData = new Map<
  FilterIdDashboard,
  FilterDataInterface
>()

VolumeSnapshotCoverageFilterData.set(FilterIdDashboard.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

VolumeSnapshotCoverageFilterData.set(FilterIdDashboard.REGIONS, {
  kind: 1,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

VolumeSnapshotCoverageFilterData.set(FilterIdDashboard.COVERED, {
  kind: 2,
  value: {
    name: 'covered',
    label: 'Covered',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultCoveredBySnapshots,
})

//-----------------------------------------------------------
const VolumeInventoryFilterData = new Map<
  FilterIdDashboard,
  FilterDataInterface
>()

VolumeInventoryFilterData.set(FilterIdDashboard.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

VolumeInventoryFilterData.set(FilterIdDashboard.REGIONS, {
  kind: 1,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

VolumeInventoryFilterData.set(FilterIdDashboard.PROTECTED, {
  kind: 7,
  value: {
    name: 'protected',
    label: 'Protected',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultProtectedByLastRP,
}).set(FilterIdDashboard.COVERED, {
  kind: 2,
  value: {
    name: 'covered',
    label: 'Covered',
    extraValue: 'Policy:',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultCoveredByPolicy,
})

VolumeInventoryFilterData.set(FilterIdDashboard.BACKUPS_ON_SCHEDULE, {
  kind: 11,
  value: {
    name: 'backupsOnSchedule',
    label: 'Backups on schedule',
    extraValue: 'Backups on schedule:',
    placeholder: 'second row',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultBackupsOnSchedule,
})

//-----------------------------------------------------------
const EbsVulnerabilitiesFilterData = new Map<
  FilterIdDashboard,
  FilterDataInterface
>()

EbsVulnerabilitiesFilterData.set(FilterIdDashboard.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

EbsVulnerabilitiesFilterData.set(FilterIdDashboard.REGIONS, {
  kind: 1,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

EbsVulnerabilitiesFilterData.set(FilterIdDashboard.EBS_VULNERABILITIES, {
  kind: 6,
  value: {
    name: 'ebsVulnerabilities',
    label: 'Misconfiguration type',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultEbsVulnerabilitiesFilter,
})

//-----------------------------------------------------------
const QuarantinedVolumesFilterData = new Map<
  FilterIdDashboard,
  FilterDataInterface
>()

QuarantinedVolumesFilterData.set(FilterIdDashboard.MALWARES, {
  kind: 3,
  value: {
    name: 'malwares',
    label: 'Malware kind',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultMalwares,
})

QuarantinedVolumesFilterData.set(FilterIdDashboard.RANSOMWARES, {
  kind: 4,
  value: {
    name: 'ransomwares',
    label: 'Ransomware kind',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRansomwares,
})

//-----------------------------------------------------------------------
const SnapshotVulnerabilitiesFilterData = new Map<
  FilterIdDashboard,
  FilterDataInterface
>()

SnapshotVulnerabilitiesFilterData.set(FilterIdDashboard.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account ID',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

SnapshotVulnerabilitiesFilterData.set(FilterIdDashboard.REGIONS, {
  kind: 1,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

SnapshotVulnerabilitiesFilterData.set(
  FilterIdDashboard.SNAPSHOT_VULNERABILITY_TYPES,
  {
    kind: 5,
    value: {
      name: 'snapshotVulnerabilityTypes',
      label: 'Misconfiguration type',
      value: '',
      type: 2,
    },
    getFieldFunc: defaultSnapshotVulnerabilityType,
  }
)

//-----------------------------------------------------------------------
const SnapshotsInventoryFilterData = new Map<
  FilterIdDashboard,
  FilterDataInterface
>()

SnapshotsInventoryFilterData.set(FilterIdDashboard.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

SnapshotsInventoryFilterData.set(FilterIdDashboard.REGIONS, {
  kind: 1,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

//-----------------------------------------------------------
const Ec2InventoryFilterData = new Map<FilterIdDashboard, FilterDataInterface>()

Ec2InventoryFilterData.set(FilterIdDashboard.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

Ec2InventoryFilterData.set(FilterIdDashboard.REGIONS, {
  kind: 1,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

Ec2InventoryFilterData.set(FilterIdDashboard.OS_KINDS, {
  kind: 8,
  value: {
    name: 'osKinds',
    label: 'OS Kind',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultOsKind,
})

Ec2InventoryFilterData.set(FilterIdDashboard.COVERED, {
  kind: 2,
  value: {
    name: 'covered',
    label: 'Covered',
    extraValue: 'Policy:',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultCoveredByPolicy,
})

Ec2InventoryFilterData.set(FilterIdDashboard.PROTECTED, {
  kind: 7,
  value: {
    name: 'protected',
    label: 'Protected',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultProtectedByLastRP,
})

Ec2InventoryFilterData.set(FilterIdDashboard.BACKUPS_ON_SCHEDULE, {
  kind: 11,
  value: {
    name: 'backupsOnSchedule',
    label: 'Backups on schedule',
    extraValue: 'Backups on schedule:',
    placeholder: 'second row',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultBackupsOnSchedule,
})

//-----------------------------------------------------------
const ActiveInstancesThreatsFilterData = new Map<
  FilterIdDashboard,
  FilterDataInterface
>()

ActiveInstancesThreatsFilterData.set(FilterIdDashboard.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

ActiveInstancesThreatsFilterData.set(FilterIdDashboard.REGIONS, {
  kind: 1,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

ActiveInstancesThreatsFilterData.set(FilterIdDashboard.RANSOMWARES, {
  kind: 4,
  value: {
    name: 'ransomwares',
    label: 'Ransomware',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRansomwares,
})

ActiveInstancesThreatsFilterData.set(FilterIdDashboard.MALWARES, {
  kind: 3,
  value: {
    name: 'malwares',
    label: 'Malware',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultMalwares,
})

ActiveInstancesThreatsFilterData.set(FilterIdDashboard.FS_CHECK, {
  kind: 9,
  value: {
    name: 'fsCheck',
    label: 'File System Integrity',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultFsCheckStatus,
})

//-----------------------------------------------------------
const ActiveVolumesThreatsFilterData = new Map<
  FilterIdDashboard,
  FilterDataInterface
>()

ActiveVolumesThreatsFilterData.set(FilterIdDashboard.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

ActiveVolumesThreatsFilterData.set(FilterIdDashboard.REGIONS, {
  kind: 1,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

ActiveVolumesThreatsFilterData.set(FilterIdDashboard.RANSOMWARES, {
  kind: 4,
  value: {
    name: 'ransomwares',
    label: 'Ransomware',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRansomwares,
})

ActiveVolumesThreatsFilterData.set(FilterIdDashboard.MALWARES, {
  kind: 3,
  value: {
    name: 'malwares',
    label: 'Malware',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultMalwares,
})

ActiveVolumesThreatsFilterData.set(FilterIdDashboard.FS_CHECK, {
  kind: 9,
  value: {
    name: 'fsCheck',
    label: 'File System Integrity',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultFsCheckStatus,
})

//-----------------------------------------------------------

const ActiveEfsThreatsFilterData = new Map<
  FilterIdDashboard,
  FilterDataInterface
>()

ActiveEfsThreatsFilterData.set(FilterIdDashboard.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

ActiveEfsThreatsFilterData.set(FilterIdDashboard.REGIONS, {
  kind: 1,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

ActiveEfsThreatsFilterData.set(FilterIdDashboard.RANSOMWARES, {
  kind: 4,
  value: {
    name: 'ransomwares',
    label: 'Ransomware',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRansomwares,
})

ActiveEfsThreatsFilterData.set(FilterIdDashboard.MALWARES, {
  kind: 3,
  value: {
    name: 'malwares',
    label: 'Malware',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultMalwares,
})
//-----------------------------------------------------------

const ActiveS3ThreatsFilterData = new Map<
  FilterIdDashboard,
  FilterDataInterface
>()

ActiveS3ThreatsFilterData.set(FilterIdDashboard.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

ActiveS3ThreatsFilterData.set(FilterIdDashboard.REGIONS, {
  kind: 1,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

ActiveS3ThreatsFilterData.set(FilterIdDashboard.MALWARES, {
  kind: 3,
  value: {
    name: 'malwares',
    label: 'Malware',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultMalwares,
})
//-----------------------------------------------------------

const AssetsVulnerabilityFilterData = new Map<
  FilterIdDashboard,
  FilterDataInterface
>()

AssetsVulnerabilityFilterData.set(FilterIdDashboard.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

AssetsVulnerabilityFilterData.set(FilterIdDashboard.REGIONS, {
  kind: 1,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

AssetsVulnerabilityFilterData.set(FilterIdDashboard.VOLUME_AWS_ID, {
  kind: 14,
  value: {
    name: 'volumeAwsIds',
    label: 'Volume ID',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultVolumeAwsId,
})

AssetsVulnerabilityFilterData.set(FilterIdDashboard.ATTACHED_INSTANCE_AWS_ID, {
  kind: 15,
  value: {
    name: 'attachedInstancesAwsIds',
    label: 'Instance ID',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultInstanceAwsId,
})

AssetsVulnerabilityFilterData.set(FilterIdDashboard.EBS_VULNERABILITIES, {
  kind: 6,
  value: {
    name: 'ebsVulnerabilities',
    label: 'Misconfiguration Type',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultEbsVulnerabilitiesFilter,
})

AssetsVulnerabilityFilterData.set(FilterIdDashboard.RISK_TYPE, {
  kind: 10,
  value: {
    name: 'riskType',
    label: 'Risk Type',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRiskType,
})

//-----------------------------------------------------------------------
const SnapshotsVulnerabilitiesFilterData = new Map<
  FilterIdDashboard,
  FilterDataInterface
>()

SnapshotsVulnerabilitiesFilterData.set(FilterIdDashboard.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

SnapshotsVulnerabilitiesFilterData.set(FilterIdDashboard.REGIONS, {
  kind: 1,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

SnapshotsVulnerabilitiesFilterData.set(
  FilterIdDashboard.SNAPSHOT_VULNERABILITY_TYPES,
  {
    kind: 5,
    value: {
      name: 'snapshotVulnerabilityTypes',
      label: 'Misconfiguration type',
      value: '',
      type: 2,
    },
    getFieldFunc: defaultSnapshotVulnerabilityType,
  }
)

SnapshotsVulnerabilitiesFilterData.set(FilterIdDashboard.RISK_TYPE, {
  kind: 10,
  value: {
    name: 'riskType',
    label: 'Risk Type',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRiskType,
})

//-----------------------------------------------------------------------

const RecoveryExposureFilterData = new Map<
  FilterIdDashboard,
  FilterDataInterface
>()

RecoveryExposureFilterData.set(FilterIdDashboard.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

RecoveryExposureFilterData.set(FilterIdDashboard.REGIONS, {
  kind: 1,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

RecoveryExposureFilterData.set(FilterIdDashboard.RANSOMWARES, {
  kind: 4,
  value: {
    name: 'ransomwares',
    label: 'Ransomware',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRansomwares,
})

RecoveryExposureFilterData.set(FilterIdDashboard.MALWARES, {
  kind: 3,
  value: {
    name: 'malwares',
    label: 'Malware',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultMalwares,
})

RecoveryExposureFilterData.set(FilterIdDashboard.FS_CHECK, {
  kind: 9,
  value: {
    name: 'fsCheck',
    label: 'File System Integrity',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultFsCheckStatus,
})

RecoveryExposureFilterData.set(FilterIdDashboard.EBS_VULNERABILITIES, {
  kind: 6,
  value: {
    name: 'ebsVulnerabilities',
    label: 'Misconfiguration Type',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultEbsVulnerabilitiesFilter,
})

// ---------------------------------------------------------

const AssetsFilterData = new Map<FilterId, FilterDataInterface>()

AssetsFilterData.set(FilterId.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

AssetsFilterData.set(FilterId.INSTANCE_IDS, {
  kind: 2,
  value: {
    name: 'instanceIds',
    label: 'Instance',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultInstanceIds,
})

AssetsFilterData.set(FilterId.INSTANCE_TYPES, {
  kind: 3,
  value: {
    name: 'instanceTypes',
    label: 'Instance Type',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultInstanceTypes,
})

AssetsFilterData.set(FilterId.VOLUME_IDS, {
  kind: 8,
  value: {
    name: 'volumeIds',
    label: 'Volume',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultVolumeIds,
})

AssetsFilterData.set(FilterId.VOLUME_TYPES, {
  kind: 5,
  value: {
    name: 'volumeTypes',
    label: 'Volume Type',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultVolumeTypes,
})

AssetsFilterData.set(FilterId.REGIONS, {
  kind: 1,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

AssetsFilterData.set(FilterId.VPC_IDS, {
  kind: 6,
  value: {
    name: 'vpcIds',
    label: 'VPC',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultVpcIds,
})

AssetsFilterData.set(FilterId.ASSET_TAGS, {
  kind: 9,
  value: {
    name: 'tags',
    label: 'Asset Tag',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultTags,
})

AssetsFilterData.set(FilterId.PROTECTED, {
  kind: 11,
  value: {
    name: 'isProtected',
    label: 'Protection',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultProtectedAsset,
})

AssetsFilterData.set(FilterId.PAUSED_INSTANCES, {
  kind: 23,
  value: {
    name: 'isPausedInstances',
    label: 'Instance State',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultPausedInstance,
})

//-----------------------------------------------------------
const S3BucketInventoryFilterData = new Map<
  FilterIdDashboard,
  FilterDataInterface
>()

S3BucketInventoryFilterData.set(FilterIdDashboard.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

S3BucketInventoryFilterData.set(FilterIdDashboard.REGIONS, {
  kind: 1,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

S3BucketInventoryFilterData.set(FilterIdDashboard.COVERED, {
  kind: 2,
  value: {
    name: 'covered',
    label: 'Covered',
    extraValue: 'Policy:',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultCoveredByPolicy,
})

S3BucketInventoryFilterData.set(FilterIdDashboard.STATE, {
  kind: 3,
  value: {
    name: 'state',
    label: 'In use',
    extraValue: 'State:',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultUseState,
})

//-----------------------------------------------------------

//-----------------------------------------------------------
const EfsInventoryFilterData = new Map<FilterIdDashboard, FilterDataInterface>()

EfsInventoryFilterData.set(FilterIdDashboard.ACCOUNT_IDS, {
  kind: 0,
  value: {
    name: 'accountId',
    label: 'Account',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAccountId,
})

EfsInventoryFilterData.set(FilterIdDashboard.REGIONS, {
  kind: 1,
  value: {
    name: 'regions',
    label: 'Region',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultRegionForScrollFilter,
})

EfsInventoryFilterData.set(FilterIdDashboard.COVERED, {
  kind: 2,
  value: {
    name: 'covered',
    label: 'Covered',
    extraValue: 'Policy:',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultCoveredByPolicy,
})

EfsInventoryFilterData.set(FilterIdDashboard.STATE, {
  kind: 3,
  value: {
    name: 'state',
    label: 'In use',
    extraValue: 'State:',
    value: '',
    type: 1,
  },
  getFieldFunc: defaultUseState,
})

//-----------------------------------------------------------

//-----------------------------------------------------------
const AssetItemsFilterData = new Map<FilterIdDashboard, FilterDataInterface>()

AssetItemsFilterData.set(FilterIdDashboard.ASSET_ITEM_KIND, {
  kind: 0,
  value: {
    name: 'assetItemKind',
    label: 'Type',
    value: '',
    type: 2,
  },
  getFieldFunc: defaultAssetItemKind,
})

//-----------------------------------------------------------

export {
  FilterId,
  FilterData,
  AssetsData,
  ReportFilterData,
  JobStatusFilterData,
  AssetsSelectedFilterData,
  FilterIdDashboard,
  VolumeSnapshotCoverageFilterData,
  SnapshotVulnerabilitiesFilterData,
  EbsVulnerabilitiesFilterData,
  QuarantinedVolumesFilterData,
  VolumeInventoryFilterData,
  SnapshotsInventoryFilterData,
  Ec2InventoryFilterData,
  AssetsS3SelectedFilterData,
  ActiveInstancesThreatsFilterData,
  ActiveVolumesThreatsFilterData,
  AssetsVulnerabilityFilterData,
  SnapshotsVulnerabilitiesFilterData,
  RecoveryExposureFilterData,
  AssetsFilterData,
  S3BucketInventoryFilterData,
  AssetsEfsSelectedFilterData,
  EfsInventoryFilterData,
  ActiveEfsThreatsFilterData,
  ActiveS3ThreatsFilterData,
  AssetItemsFilterData,
  FsCheckScanReportFilterData,
  IscanReportFilterData,
}
