import React, { ChangeEvent, memo, useState } from 'react'
import { EngineCallback } from '@lib/engine-types'
import clsx from 'clsx'
import ValueInterface from '@lib/interfaces/value.interface'
import ObjHelper from '@lib/helpers/obj.helper'
import { Checkbox, FormControlLabel, Icon, IconButton } from '@mui/material'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'

interface Props {
  data: Array<ValueInterface>
  onChange: EngineCallback<Array<ValueInterface>>
  selectAllLabel?: string
  canFold?: boolean
  folded?: boolean
  disabled?: boolean
  className?: string
}

function CheckboxBlock({
  data,
  onChange,
  selectAllLabel,
  canFold = false,
  folded = true,
  disabled = false,
  className = '',
}: Props) {
  const [innerFolded, setInnerFolded] = useState(folded)

  // EVENT HANDLERS
  const onDataChange =
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const newData = ObjHelper.cloneDeep(data)
      newData[index] = {
        ...(newData[index] ?? { name: '' }),
        value: e.target.checked,
      }
      onChange(newData)
    }
  const onChangeAll = (e: ChangeEvent<HTMLInputElement>) => {
    const newData = [...data].map((v) => ({
      ...v,
      value: e.target.checked,
    }))
    onChange(newData)
  }
  const onFoldToggle = () => {
    setInnerFolded(!innerFolded)
  }

  // HELP DATA CALCULATION
  const selectedTotal = data.filter((v) => v.value).length
  const selectAllChecked = selectedTotal === data.length
  const selectAllIndeterminate =
    selectedTotal > 0 && selectedTotal < data.length

  const renderCheckboxFormLabel = (
    v: ValueInterface,
    index: number
  ): JSX.Element => {
    return (
      <div key={v.name}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled || v.disabled}
              value={v.value}
              name={v.name}
              checked={Boolean(v.value)}
              onChange={onDataChange(index)}
            />
          }
          label={
            v.extraValue ? (
              <>
                <div className="flexRowBetween">
                  {
                    <LongTextTooltip
                      text={v.label ?? v.name}
                      maxLength={60}
                      customClass={
                        v.supplementalValue ? String(v.supplementalValue) : ''
                      }
                    />
                  }
                  <span>{v.extraValue}</span>
                </div>
              </>
            ) : (
              v.label ?? v.name
            )
          }
        />
        {v.placeholder && <div className="description">{v.placeholder}</div>}
      </div>
    )
  }

  return (
    <div
      aria-label="CheckboxBlock"
      className={clsx(`wrap-1603465951135 сheckboxBlockWrapper ${className}`, {
        selectAllLabel: !!selectAllLabel,
      })}
    >
      {selectAllLabel && (
        <div className="flexRowBetween">
          <FormControlLabel
            control={
              <Checkbox
                disabled={disabled}
                name="selectAll"
                indeterminate={selectAllIndeterminate}
                checked={selectAllChecked}
                onChange={onChangeAll}
              />
            }
            label={
              className === 'twoColumnsLabel' ? (
                <>
                  <div className="flexRowBetween">
                    <span>{selectAllLabel}</span>
                    <span className="mr0">{selectAllLabel}</span>
                  </div>
                </>
              ) : (
                selectAllLabel
              )
            }
          />
          {canFold && (
            <IconButton disabled={disabled} onClick={onFoldToggle} size="small">
              <Icon>
                {innerFolded ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
              </Icon>
            </IconButton>
          )}
        </div>
      )}
      {(!canFold || !innerFolded) && (
        <div className="checkboxesBlock">
          {data.map((v, index) => {
            return renderCheckboxFormLabel(v, index)
          })}
        </div>
      )}
    </div>
  )
}
export default memo(CheckboxBlock)
