import GrpcBaseService from '@lib/services/grpc/base/grpc-base.service'
import { UsersPromiseClient } from 'blue-stack-libs/users-grpc-libs/js/users/users_grpc_web_pb'
import UserModel from '@lib/models/user.model'
import {
  ActivateUserRequest,
  Config,
  DeactivateUserRequest,
  DeleteUserRequest,
  Invitee,
  ListRequest,
  UserProfile,
  UserProfileUpdate,
  UserRoleUpdate,
} from 'blue-stack-libs/users-grpc-libs/js/users/messages/users_pb'
import UserFactory from '@lib/factories/user.factory'

class GrpcUsersService extends GrpcBaseService {
  protected static client = new UsersPromiseClient('', null, null)
  protected static clientName = 'Users'

  public static async list(): Promise<Array<UserModel>> {
    const request = new ListRequest()
    const result = await this.handleQueryRetry(this.client.list, request)
    return result.toObject().userProfilesList.map(UserFactory.buildFromGRPC)
  }

  public static async deactivateUser(id: string): Promise<any> {
    const request = new DeactivateUserRequest()
    request.setUserId(id)

    const result = await this.handleQuery(this.client.deactivateUser, request)
    return result.toObject()
  }

  public static async deleteUser(id: string): Promise<void> {
    const request = new DeleteUserRequest()
    request.setUserId(id)

    await this.handleQuery(this.client.deleteUser, request)
  }

  public static async activateUser(id: string): Promise<any> {
    const request = new ActivateUserRequest()
    request.setUserId(id)
    const result = await this.handleQuery(this.client.activateUser, request)
    return result.toObject()
  }

  public static async getUserProfile(): Promise<UserModel> {
    const request = new UserProfile()
    const result = await this.handleQueryRetry(
      this.client.getUserProfile,
      request
    )
    return UserFactory.buildFromGRPC(result.toObject())
  }

  public static async updateUserProfile(user: UserModel): Promise<any> {
    const request = new UserProfileUpdate()
    request.setName(user.name)
    request.setPicture(user.picture)
    request.setNickname(user.nickname)
    request.setPhoneNumber(user.phoneNumber)
    const result = await this.handleQuery(
      this.client.updateUserProfile,
      request
    )
    return result.toObject()
  }

  public static async userInvite(email: string, roleId: string): Promise<any> {
    const request = new Invitee()
    // if the backend sees spaces - it returns "Internal error"
    request.setEmail(email.trim().toLowerCase())
    request.setRoleId(roleId)
    const result = await this.handleQuery(this.client.inviteUser, request)
    return result.toObject()
  }

  public static async updateUserRole(
    userId: string,
    roleId: string
  ): Promise<any> {
    const request = new UserRoleUpdate()
    request.setUserId(userId)
    request.setRoleId(roleId)
    const result = await this.handleQuery(this.client.updateUserRole, request)
    return result.toObject()
  }

  public static async setUserConfig(data: string): Promise<any> {
    const request = new Config()
    request.setJson(data)
    const result = await this.handleQuery(this.client.setUserConfig, request)
    return result.toObject()
  }
}

export default GrpcUsersService
