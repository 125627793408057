"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListInventoryVolumes = void 0;
const models_1 = require("../../models");
const share_1 = require("../share");
class ListInventoryVolumes {
    #volumes;
    #instances;
    #threats;
    #firstBackups;
    #lastBackups;
    #policies;
    #lastEbsSnapshots;
    #lastScans;
    #volumesWithScans;
    constructor(parameters) {
        this.#volumes = parameters.volumes;
        this.#instances = parameters.instances;
        this.#threats = parameters.threats;
        this.#firstBackups = parameters.firstBackups;
        this.#lastBackups = parameters.lastBackups;
        this.#lastEbsSnapshots = parameters.lastEbsSnapshots;
        this.#policies = parameters.policies;
        this.#lastScans = parameters.lastScans;
        this.#volumesWithScans ||= this.#getVolumeWithScans();
    }
    execute(filters) {
        return this.#prepareOutput(this.#findVolumesMatchingFilters(filters));
    }
    #prepareOutput(volumes) {
        const volumesOutput = volumes.map((volume) => ({
            uuid: volume.id,
            isUnhealthy: this.#isUnhealthyVolume(volume),
            volumeId: volume.awsId,
            volumeName: volume.name,
            accountId: volume.awsAccountId,
            region: volume.awsRegion,
            inventorySize: volume.size,
            rpo: this.#getRpo(volume),
            sla: this.#getSla(volume),
            maxBackupRetention: this.#getMaxBackupRetention(volume),
            lastBackup: this.#getLastBackup(volume),
            lastScan: this.#getLastScanForVolume(volume),
            // TODO: add generic backup kind
            policies: volume.backupPolicies,
            awsId: volume.awsId,
        }));
        return {
            volumes: volumesOutput,
            summarizedData: this.#summarizeVolumeData(volumes),
        };
    }
    #isUnhealthyVolume(volume) {
        if (volume.state === models_1.EBSState.DELETED) {
            return false;
        }
        return this.#threats.some((threat) => threat.source.asset?.assetId === volume.id ||
            threat.source.assetItem?.assetId === volume.id);
    }
    #getLastBackup(volume) {
        return this.#lastBackups.get(volume.id);
    }
    #getFirstBackup(volume) {
        return this.#firstBackups.get(volume.id);
    }
    #getVolumeWithScans() {
        const map = new Map();
        for (const scan of this.#lastScans) {
            const volumesScans = map.get(scan.scanTarget.target.assetId);
            if (volumesScans) {
                volumesScans.push(scan);
            }
            else {
                map.set(scan.scanTarget.target.assetId, [scan]);
            }
        }
        return map;
    }
    #getLastScanForVolume(volume) {
        const volumeScans = this.#volumesWithScans.get(volume.id);
        if (!volumeScans || !volumeScans.length) {
            return null;
        }
        const scansTime = volumeScans.map(({ createdAt }) => createdAt.getTime());
        if (!scansTime.length) {
            return null;
        }
        return new Date(Math.max(...scansTime));
    }
    #getRpo(volume) {
        const lastBackup = this.#getLastBackup(volume);
        if (!lastBackup) {
            return null;
        }
        return Date.now() - lastBackup.timestamp.getTime();
    }
    #getSla(volume) {
        const relatedPolicies = this.#policies.filter((policy) => volume.backupPolicies.includes(policy.id));
        if (!relatedPolicies.length) {
            return null;
        }
        const frequencies = relatedPolicies.map(({ sla }) => sla);
        const minFrequency = Math.min(...frequencies);
        return minFrequency;
    }
    #getMaxBackupRetention(volume) {
        const firstBackup = this.#getFirstBackup(volume);
        if (!firstBackup) {
            return null;
        }
        return Date.now() - firstBackup.timestamp.getTime();
    }
    #getVolumeCoveredByPolicies(volume) {
        return volume.backupPolicies.length > 0;
    }
    #summarizeVolumeData(volumes) {
        const volumesCount = new Set([...volumes.map(({ awsId }) => awsId)]).size;
        const accountsCount = new Set([
            ...volumes.map(({ awsAccountId }) => awsAccountId),
        ]).size;
        const regionsCount = new Set([...volumes.map(({ awsRegion }) => awsRegion)])
            .size;
        const inventorySize = volumes.reduce((accum, currVolume) => accum + currVolume.size, 0);
        return {
            volumesCount,
            accountsCount,
            regionsCount,
            inventorySize,
        };
    }
    #getVolumeSearchMatches(searchValue, volume) {
        const idMatches = volume.awsId.includes(searchValue);
        const nameMatches = volume.name.includes(searchValue);
        const tagsMatches = volume.tags.some(({ key, value }) => key.includes(searchValue) || value.includes(searchValue));
        const volumeInstancesMatches = this.#isVolumeInstanceMatchesBySearch(searchValue, volume);
        const volumeSnapshotsMatches = this.#isVolumeSnapshotMatchesBySearch(searchValue, volume);
        return (idMatches ||
            nameMatches ||
            tagsMatches ||
            volumeInstancesMatches ||
            volumeSnapshotsMatches);
    }
    #isVolumeSnapshotMatchesBySearch(searchValue, volume) {
        const volumeSnapshots = this.#lastEbsSnapshots.filter((snapshot) => snapshot.volumeId === volume.id);
        return volumeSnapshots.some((snapshot) => {
            const matchesId = snapshot.id.includes(searchValue);
            const matchesName = snapshot.name.includes(searchValue);
            const matchesTags = snapshot.tags.some(({ key, value }) => key.includes(searchValue) || value.includes(searchValue));
            return matchesId || matchesName || matchesTags;
        });
    }
    #isVolumeInstanceMatchesBySearch(searchValue, volume) {
        const volumesInstances = this.#instances.filter((instance) => instance.ebsIds.includes(volume.id));
        return volumesInstances.some((instance) => {
            const matchesId = instance.awsId.includes(searchValue);
            const matchesName = instance.name.includes(searchValue);
            const matchesTags = instance.tags.some(({ key, value }) => key.includes(searchValue) || value.includes(searchValue));
            return matchesId || matchesName || matchesTags;
        });
    }
    #isVolumeOutOfSchedule(volume) {
        const lastBackup = this.#getLastBackup(volume);
        const volumeSla = this.#getSla(volume);
        if (!lastBackup) {
            return false;
        }
        if (!volumeSla) {
            return false;
        }
        return Date.now() - lastBackup.createdAt.getTime() > volumeSla;
    }
    #findVolumesMatchingFilters(filters) {
        const { accountIds, covered, regions, idSearch, backupsOnSchedule } = filters;
        return this.#volumes.filter((volume) => {
            if (accountIds &&
                !(0, share_1.matchesIncludeFilter)(accountIds, volume.awsAccountId)) {
                return false;
            }
            if (regions && !(0, share_1.matchesIncludeFilter)(regions, volume.awsRegion)) {
                return false;
            }
            if (covered &&
                covered?.includes(true) &&
                !this.#getVolumeCoveredByPolicies(volume)) {
                return false;
            }
            if (covered &&
                covered?.includes(false) &&
                this.#getVolumeCoveredByPolicies(volume)) {
                return false;
            }
            if (idSearch && !this.#getVolumeSearchMatches(idSearch, volume)) {
                return false;
            }
            if (backupsOnSchedule &&
                backupsOnSchedule?.includes(true) &&
                !this.#isVolumeOutOfSchedule(volume)) {
                return false;
            }
            if (backupsOnSchedule &&
                backupsOnSchedule?.includes(false) &&
                this.#isVolumeOutOfSchedule(volume)) {
                return false;
            }
            return true;
        });
    }
}
exports.ListInventoryVolumes = ListInventoryVolumes;
