import { Box, BoxProps, Tooltip } from '@mui/material'
import React, { memo } from 'react'

interface Props extends BoxProps {
  text: string
  maxLength?: number
  customClass?: string
  placement?: 'bottom' | 'left' | 'right' | 'top' | 'top-start'
  customPopperClass?: string
  children?: string
  show?: boolean
  tooltipText?: string
}

function LongTextTooltip({
  text,
  maxLength = 50,
  customClass = '',
  placement = 'bottom',
  customPopperClass = '',
  show = false,
  tooltipText,
  ...containerProps
}: Props) {
  const sliceText = text.toString().slice(0, maxLength)

  return text.length > maxLength || show ? (
    <Tooltip
      title={tooltipText || text}
      placement={placement}
      classes={{ popper: customPopperClass }}
    >
      <Box className={customClass} {...containerProps}>
        {text.length > maxLength ? sliceText.concat('...') : sliceText}
      </Box>
    </Tooltip>
  ) : (
    <Box className={customClass} {...containerProps}>
      {text}
    </Box>
  )
}

export default memo(LongTextTooltip)
