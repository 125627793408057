export default function JobUnscannedIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99984 1.66699C5.39984 1.66699 1.6665 5.40033 1.6665 10.0003C1.6665 14.6003 5.39984 18.3337 9.99984 18.3337C14.5998 18.3337 18.3332 14.6003 18.3332 10.0003C18.3332 5.40033 14.5998 1.66699 9.99984 1.66699ZM9.99984 10.8337C9.5415 10.8337 9.1665 10.4587 9.1665 10.0003V6.66699C9.1665 6.20866 9.5415 5.83366 9.99984 5.83366C10.4582 5.83366 10.8332 6.20866 10.8332 6.66699V10.0003C10.8332 10.4587 10.4582 10.8337 9.99984 10.8337ZM10.8332 14.167H9.1665V12.5003H10.8332V14.167Z"
        fill="var(--grey500)"
      />
    </svg>
  )
}
