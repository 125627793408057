import moment, { Moment } from 'moment-timezone'
import TimeFormatConstants from '@lib/constants/time-format.constant'
import {
  DateRangeTuple,
  DefinedDateRangeTuple,
  Nullable,
} from '@lib/engine-types'
import HourMinuteInterface from '@lib/interfaces/hour-minute.interface'
import ArrHelper from './arr.helper'
import { TimeZoneOption } from '@lib/constants/data/time/types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isBetween from 'dayjs/plugin/isBetween'
import { DEFAULT_TIME_ZONE } from '@lib/constants'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)

type ProtobufTimestamp = { seconds: number }

type TimeRangeInSeconds = {
  start: number
  end: number
}
const MILLISECONDS_IN_SECOND = 1000

abstract class TimeHelper {
  static DATE_REGEX = /^\d{2}\/\d{2}\/\d{2}$/

  static TIME_REGEX = /^\d{2}:\d{2} (AM|PM)$/

  static timezoneConfig: string

  public static getTimezoneConfig(): string {
    return TimeHelper.timezoneConfig ?? DEFAULT_TIME_ZONE().value
  }

  public static initTimezone(timezoneOption?: string) {
    TimeHelper.timezoneConfig = timezoneOption ?? DEFAULT_TIME_ZONE().value
  }

  public static getDateWithTimezone(
    date: number,
    timezoneName?: string
  ): Moment {
    return moment(date).tz(timezoneName ?? TimeHelper.getTimezoneConfig())
  }

  public static formatWithTimeZone(
    date: Nullable<string | number | ProtobufTimestamp | Date>,
    format: string,
    tz: TimeZoneOption
  ) {
    const { value: timeZone } = tz
    if (!date) {
      return ''
    }
    if (date instanceof Date) {
      return moment(date.getTime()).tz(timeZone).format(format)
    }

    return moment(date).tz(timeZone).format(format)
  }

  static isValidDateTime(dateStr: string, timeStr: string): boolean {
    return (
      TimeHelper.DATE_REGEX.test(dateStr) && TimeHelper.TIME_REGEX.test(timeStr)
    )
  }

  public static timestampToMoment(timestamp: number): Moment {
    return moment.utc(timestamp)
  }

  public static timestampToEndOfTheDay(value: number): number {
    // all the time
    return moment(value).utc().endOf('day').toDate().getTime()
  }

  public static timestampToEndOfWeek(value: number): number {
    // all the time
    return moment(value).utc().endOf('week').toDate().getTime()
  }

  public static timestampToEndOfMonth(value: number): number {
    // last day of the month 23:59 all the time
    return moment(value).utc().endOf('month').toDate().getTime()
  }

  public static timestampToEndOfYear(value: number): number {
    // last day of the year December 31, 23:59 all the time
    return moment(value).utc().endOf('year').toDate().getTime()
  }

  public static momentToTimestamp(value: Moment): number {
    return value.utc().toDate().getTime()
  }

  public static diffMinutes(
    startParams: HourMinuteInterface | number,
    endParams: HourMinuteInterface | number
  ): number {
    const start = moment.utc(startParams)
    const end = moment.utc(endParams)
    const result = end.diff(start, 'minutes')

    // this may happen if "start > end"
    if (result < 0) {
      // in this case it can be for example "-120"
      // 1440 - amount of minutes in 24 hours
      return 1440 + result
    }

    return result
  }

  public static currentPlusHours(hours: number): Moment {
    return moment().utc().add(hours, 'hours')
  }

  public static currentMinusHours(hours: number): Moment {
    return moment().utc().subtract(hours, 'hours')
  }

  public static currentMinusMinutes(minutes: number): Moment {
    return moment().utc().subtract(minutes, 'minutes')
  }

  public static currentMinusDays(days: number): Moment {
    return moment().utc().subtract(days, 'days')
  }

  public static currentPlusDays(days: number): Moment {
    return moment().utc().add(days, 'days')
  }

  public static currentMinusWeeks(weeks: number): Moment {
    return moment().utc().subtract(weeks, 'weeks')
  }

  public static currentMinusMonths(months: number): Moment {
    return moment().utc().subtract(months, 'months')
  }

  public static currentMinusYears(years: number): Moment {
    return moment().utc().subtract(years, 'years')
  }

  public static daysInCurrentMonth(): number {
    return moment.utc().daysInMonth()
  }

  public static weekdaysInMonth(weekday: number): number {
    const date = moment.utc()
    date.date(1)
    const dif = ((7 + (weekday - date.weekday())) % 7) + 1
    return Math.floor((date.daysInMonth() - dif) / 7) + 1
  }

  public static timestampFormat(timestamp: number, format: string): string {
    return moment.utc(timestamp).format(format)
  }

  public static timestampShortDatetimeFormat(
    timestamp: Nullable<string | number | ProtobufTimestamp>,
    format = TimeFormatConstants.SHORT_DATETIME_FORMAT
  ): string {
    if (!timestamp) {
      return ''
    }

    let mutableTimestamp = timestamp

    if (TimeHelper.isProtobufTimestamp(timestamp)) {
      mutableTimestamp = TimeHelper.protobufTimestampToJSTimestamp(timestamp)
    }

    return TimeHelper.timestampFormat(+mutableTimestamp, format)
  }

  public static momentShortDatetimeFormat(value: Nullable<Moment>): string {
    if (!value) {
      return ''
    }
    return TimeHelper.momentFormat(
      value,
      TimeFormatConstants.SHORT_DATETIME_FORMAT
    )
  }

  public static momentModernDatetimeFormat(value: Nullable<Moment>): string {
    if (!value) {
      return ''
    }
    return TimeHelper.momentFormat(
      value,
      TimeFormatConstants.MODERN_DATETIME_FORMAT
    )
  }

  public static momentFormat(value: Moment, format: string): string {
    return value.utc().format(format)
  }

  public static momentNow(): Moment {
    return moment().utc()
  }

  // we assume that we get as input the UTC format
  public static fromDatetimeFormat(value: string): Moment {
    return moment.utc(value, [TimeFormatConstants.DATETIME])
  }

  public static toDatetimeFormat(value: Moment): string {
    return value.format(TimeFormatConstants.DATETIME)
  }

  public static protobufTimestampToJSTimestamp(timestamp: {
    seconds: number
  }): number {
    return timestamp.seconds * 1000
  }

  public static isProtobufTimestamp(
    timestamp?: any
  ): timestamp is ProtobufTimestamp {
    return typeof timestamp?.seconds !== 'undefined'
  }

  public static dayMinutes(value: number | HourMinuteInterface): number {
    const newDate = moment.utc(value)
    return newDate.hours() * 60 + newDate.minutes()
  }

  public static minutesToShortDatetimeFormat(minutes: number): string {
    const newDate = TimeHelper.momentNow().startOf('day')
    newDate.add(minutes, 'minutes')
    return newDate.format(TimeFormatConstants.SHORT_DATETIME_FORMAT)
  }

  public static dayOfWeekToShortDatetimeFormat(dayOfWeek: number): string {
    const newDate = TimeHelper.momentNow().startOf('week')
    newDate.add(dayOfWeek, 'days')
    return newDate.format(TimeFormatConstants.SHORT_DATETIME_FORMAT)
  }

  public static dayOfMonthToShortDatetimeFormat(dayOfMonth: number): string {
    const newDate = TimeHelper.momentNow().startOf('month')
    newDate.add(dayOfMonth, 'days')
    return newDate.format(TimeFormatConstants.SHORT_DATETIME_FORMAT)
  }

  public static normalizeMinutesByInterval(
    minutes: number,
    interval: number
  ): number {
    const rest = minutes % interval
    if (rest === 0) {
      return minutes
    }
    return minutes + interval - rest
  }

  public static currentDayDateRange(): DefinedDateRangeTuple {
    const to = TimeHelper.momentNow().endOf('day')
    const from = to.clone().startOf('day')

    return [
      TimeHelper.momentToTimestamp(from),
      TimeHelper.momentToTimestamp(to),
    ]
  }

  public static currentWeekDateRange(): DefinedDateRangeTuple {
    // This week = The current calendar week - from Sunday.
    // So if its Thursday, the range is Sunday-Thursday
    const to = TimeHelper.momentNow().endOf('day')
    const from = to.clone().startOf('week')

    return [
      TimeHelper.momentToTimestamp(from),
      TimeHelper.momentToTimestamp(to),
    ]
  }

  public static currentWeekDateRangeUntilYesterday(): DefinedDateRangeTuple {
    // this calendar week from Sun to yesterday.
    const to = TimeHelper.momentNow().startOf('day')
    const from = to.clone().startOf('week')

    return [
      TimeHelper.momentToTimestamp(from),
      TimeHelper.momentToTimestamp(to),
    ]
  }

  public static transformTupleDateRangeForApi(
    dateRange: DefinedDateRangeTuple
  ): DefinedDateRangeTuple {
    return [Math.round(dateRange[0] / 1000), Math.round(dateRange[1] / 1000)]
  }

  public static currentMonthDateRange(): DefinedDateRangeTuple {
    // This month = the 1st of the month until today.
    const to = TimeHelper.momentNow().endOf('day')
    const from = to.clone().startOf('month')

    return [
      TimeHelper.momentToTimestamp(from),
      TimeHelper.momentToTimestamp(to),
    ]
  }

  public static currentMonthDateRangeWithTimezone(): DefinedDateRangeTuple {
    const to = moment().tz(TimeHelper.getTimezoneConfig()).endOf('day')
    const from = to.clone().startOf('month')

    return [from.valueOf(), to.valueOf()]
  }

  public static currentMonthDateRangeUntilYesterday(): DefinedDateRangeTuple {
    // This month = the 1st of the month until yesterday.
    const to = TimeHelper.momentNow().startOf('day')
    const from = to.clone().startOf('month')

    return [
      TimeHelper.momentToTimestamp(from),
      TimeHelper.momentToTimestamp(to),
    ]
  }

  public static transformDateRangeTupleForApi(
    dateRange: DefinedDateRangeTuple
  ): DefinedDateRangeTuple {
    return [Math.round(dateRange[0] / 1000), Math.round(dateRange[1] / 1000)]
  }

  public static currentYearDateRange(): DefinedDateRangeTuple {
    // This Year = the 1st of the year until today.
    const to = TimeHelper.momentNow().endOf('day')
    const from = to.clone().startOf('year')

    return [
      TimeHelper.momentToTimestamp(from),
      TimeHelper.momentToTimestamp(to),
    ]
  }

  public static currentYearDateRangeUntilYesterday(): DefinedDateRangeTuple {
    // This Year = the 1st of the year until yesterday.
    const to = TimeHelper.momentNow().startOf('day')
    const from = to.clone().startOf('year')

    return [
      TimeHelper.momentToTimestamp(from),
      TimeHelper.momentToTimestamp(to),
    ]
  }

  public static dayBeforeDateRange(): DefinedDateRangeTuple {
    // Yesterday = today - 1
    // can be the next day
    const yesterday = moment().utc().subtract(1, 'days')
    const from = yesterday.clone().startOf('day')
    const to = yesterday.clone().endOf('day')

    return [
      TimeHelper.momentToTimestamp(from),
      TimeHelper.momentToTimestamp(to),
    ]
  }

  public static lastWeekDateRange(): DefinedDateRangeTuple {
    // Last calendar week from Sun-Sat.
    // can be the next day (fist day on next week)
    const fromCurrent = moment().utc().subtract(1, 'weeks')
    const from = fromCurrent.clone().startOf('week')
    const to = fromCurrent.clone().endOf('week')

    return [
      TimeHelper.momentToTimestamp(from),
      TimeHelper.momentToTimestamp(to),
    ]
  }

  public static lastMonthDateRange(): DefinedDateRangeTuple {
    // Last calendar month - example Feb 1-Feb 28
    // can be the next day (fist day on next month)
    const fromCurrent = moment().utc().subtract(1, 'months')
    const from = fromCurrent.clone().startOf('month')
    const to = fromCurrent.clone().endOf('month')

    return [
      TimeHelper.momentToTimestamp(from),
      TimeHelper.momentToTimestamp(to),
    ]
  }

  public static lastAmountMonthsDateRange(
    amount: number
  ): DefinedDateRangeTuple {
    // Amount of Months from this date
    const fromCurrent = moment().utc().subtract(amount, 'months')
    const from = fromCurrent.clone().startOf('day')
    const to = TimeHelper.momentNow().endOf('day')

    return [
      TimeHelper.momentToTimestamp(from),
      TimeHelper.momentToTimestamp(to),
    ]
  }

  public static lastYearDateRange(): DefinedDateRangeTuple {
    // Last Year - Last calendar year - example Jan 1-2021-Dec 31-2021
    // can be the next day (fist day on next year)
    const fromCurrent = moment().utc().subtract(1, 'years')
    const from = fromCurrent.clone().startOf('year')
    const to = fromCurrent.clone().endOf('year')

    return [
      TimeHelper.momentToTimestamp(from),
      TimeHelper.momentToTimestamp(to),
    ]
  }

  public static lastQuarterDateRange(): DefinedDateRangeTuple {
    // Last calender quarter start and end dates
    // example 01-01-2025-03-31-2025 - 1 quarter
    const lastQuarter = moment().utc().subtract(3, 'months').quarter()
    const from = moment().utc().quarter(lastQuarter).startOf('quarter')
    const to = moment().utc().quarter(lastQuarter).endOf('quarter')

    return [
      TimeHelper.momentToTimestamp(from),
      TimeHelper.momentToTimestamp(to),
    ]
  }

  public static currentQuarterDateRangeUntilYesterday(): DefinedDateRangeTuple {
    // Current calender quarter = the 1st day of the quarter until yesterday.
    const currentQuarter = moment().utc().quarter()
    const from = moment().utc().quarter(currentQuarter).startOf('quarter')
    const to = moment().utc().startOf('day')

    return [
      TimeHelper.momentToTimestamp(from),
      TimeHelper.momentToTimestamp(to),
    ]
  }

  public static isDateToday(value: number | HourMinuteInterface): boolean {
    return moment.utc(value).isSame(new Date(), 'day')
  }

  public static generatePeriods(
    [start, end]: DefinedDateRangeTuple,
    periods: number
  ): Array<DefinedDateRangeTuple> {
    const steps = periods > 0 ? periods : 1
    const step = (end - start) / steps

    return ArrHelper.generateArrayWithCallback(steps, (i) => {
      const from = start + i * step
      const to = i === steps - 1 ? end : from + step
      return [
        TimeHelper.momentToTimestamp(TimeHelper.timestampToMoment(from)),
        TimeHelper.momentToTimestamp(TimeHelper.timestampToMoment(to)),
      ]
    })
  }

  public static daysAgo(timestamp: number): string {
    const currentDate = moment()
    const timestampDate = moment(timestamp)

    const daysDiff = currentDate.diff(timestampDate, 'days')

    if (daysDiff === 1) {
      return `${daysDiff} day ago`
    } else {
      return `${daysDiff} days ago`
    }
  }

  public static getAgeInMonthsOrDays(date: number, isCreated: boolean): string {
    const dayNumber = Math.round(
      Math.abs((Date.now() - date) / (1000 * 3600 * 24))
    )
    const monthCount = Math.round(
      Math.abs((Date.now() - date) / (1000 * 3600 * 24 * 30))
    )

    if (dayNumber === 1) {
      return isCreated
        ? String(dayNumber) + ' day ago'
        : String(dayNumber) + ' day'
    } else if ((dayNumber <= 30 && dayNumber > 1) || dayNumber === 0) {
      return isCreated
        ? String(dayNumber) + ' days ago'
        : String(dayNumber) + ' days'
    } else if (monthCount === 1 && dayNumber > 30) {
      return isCreated
        ? String(monthCount) + ' month ago'
        : String(monthCount) + ' month'
    } else if (monthCount > 1) {
      return isCreated
        ? String(monthCount) + ' months ago'
        : String(monthCount) + ' months'
    }
    return isCreated
      ? String(monthCount) + ' months ago'
      : String(monthCount) + ' months'
  }

  public static timeAgo(millis: Nullable<number>): string {
    if (!millis) {
      return 'n/a'
    }

    const seconds = Number((millis / 1000).toFixed(0))
    if (seconds < 60) {
      return `${seconds} sec`
    }

    const minutes = Math.floor(seconds / 60)
    if (minutes < 60) {
      return `${minutes} min`
    }

    const hours = Math.floor(minutes / 60)
    if (hours < 24) {
      const leftMinutes = minutes - 60 * hours
      const minutesLabel = leftMinutes > 0 ? `${leftMinutes} min` : ''

      return `${hours} hr ${minutesLabel}`
    }

    const days = Math.floor(hours / 24)
    const leftHours = hours - 24 * days
    const hoursLabel = leftHours > 0 ? `${leftHours} hr` : ''

    return `${days} d ${hoursLabel}`
  }

  public static secondsToDate(seconds?: number): Date {
    if (!seconds) {
      return new Date(0)
    }

    return new Date(seconds * 1000)
  }

  public static maybeSecondsToDate(seconds?: number): Date | null {
    if (!seconds) {
      return null
    }

    return new Date(seconds * 1000)
  }

  static convertMillisecondsToSeconds(
    milliseconds: number
  ): number | undefined {
    if (isFinite(milliseconds)) {
      return Math.floor(milliseconds / MILLISECONDS_IN_SECOND)
    }
  }

  public static transformDateRangeForApi(
    dateRange: DateRangeTuple
  ): TimeRangeInSeconds {
    return {
      start:
        this.convertMillisecondsToSeconds(
          moment(dateRange[0]).utc().startOf('day').valueOf()
        ) ?? 0,
      end:
        this.convertMillisecondsToSeconds(
          moment(dateRange[1]).utc().endOf('day').valueOf()
        ) ?? 0,
    }
  }

  public static convertToEndOfDayInTimezone(date: number, tz?: string) {
    return moment(date)
      .tz(tz ?? TimeHelper.getTimezoneConfig())
      .endOf('day')
  }
}

export default TimeHelper
