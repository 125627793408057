"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListRecoveryPointsForAssetItemGeneric = void 0;
const types_1 = require("../../models/threats/types");
class ListRecoveryPointsForAssetItemGeneric {
    #assetItem;
    #threats;
    #scans;
    #backups;
    constructor({ assetItem, threats, scans, backups, }) {
        this.#assetItem = assetItem;
        this.#threats = threats;
        this.#scans = scans;
        this.#backups = backups;
    }
    execute() {
        return this.#prepareOutput();
    }
    #threatsForRP(threatIds) {
        const threats = [];
        const allThreatsName = new Set();
        const malwareThreatsName = new Set();
        const ransomwareThreatsName = new Set();
        this.#threats.forEach((threat) => {
            if (threatIds.includes(threat.id)) {
                threats.push(threat);
            }
            if (threatIds.includes(threat.id)) {
                allThreatsName.add(threat.name);
            }
            if (threatIds.includes(threat.id) && threat.kind === types_1.ThreatKind.MALWARE) {
                malwareThreatsName.add(threat.name);
            }
            if (threatIds.includes(threat.id) &&
                threat.kind === types_1.ThreatKind.RANSOMWARE) {
                ransomwareThreatsName.add(threat.name);
            }
        });
        return {
            threats,
            allThreatsName: [...allThreatsName],
            malwareThreatsName: [...malwareThreatsName],
            ransomwareThreatsName: [...ransomwareThreatsName],
        };
    }
    #prepareOutput() {
        const outputData = [];
        this.#backups?.map((backup) => {
            let scansForBackup;
            if (!!backup.scanIds.length && this.#scans === null) {
                scansForBackup = {
                    malwareScansForBackup: [],
                    ransomWareScansForBackup: [],
                    fsCheckScansForBackup: [],
                };
            }
            scansForBackup = {
                malwareScansForBackup: this.#scans
                    ? this.#scans.malwareScansForBackup?.filter((scan) => backup.scanIds.includes(scan.id))
                    : [],
                ransomWareScansForBackup: this.#scans
                    ? this.#scans.ransomWareScansForBackup?.filter((scan) => backup.scanIds.includes(scan.id))
                    : [],
                fsCheckScansForBackup: this.#scans
                    ? this.#scans.fsCheckScansForBackup?.filter((scan) => backup.scanIds.includes(scan.id))
                    : [],
            };
            outputData.push({
                risks: {
                    malwareStatus: backup.malwareCheckStatus,
                    ransomwareStatus: backup.ransomwareCheckStatus,
                    fsCheckStatus: backup.fsCheckStatus,
                },
                completionTime: backup.timestamp,
                threatsNames: this.#threatsForRP(backup.threatIds).allThreatsName,
                malwareThreatsNames: this.#threatsForRP(backup.threatIds)
                    .malwareThreatsName,
                ransomwareThreatsNames: this.#threatsForRP(backup.threatIds)
                    .ransomwareThreatsName,
                backupSize: backup.sizeInfo.optimizedSize,
                backup: backup,
                id: this.#assetItem.id,
                kind: this.#assetItem.kind,
                threatsList: this.#threatsForRP(backup.threatIds).threats,
                scansForBackup: scansForBackup,
            });
        });
        return outputData;
    }
}
exports.ListRecoveryPointsForAssetItemGeneric = ListRecoveryPointsForAssetItemGeneric;
