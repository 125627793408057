import React, { ReactEventHandler } from 'react'
import styles from './RenderSimpleMenu.module.scss'
import PageMenuInterface from '@lib/interfaces/page-menu.interface'
import Link from 'next/link'
import clsx from 'clsx'
import { MenuItem } from '@mui/material'
import { drawMenuIcon, isMenuSelected } from '@features/MainMenu/utilsMenu'

interface Props {
  menuElem: PageMenuInterface
  pagePath: string
  handleMenuMainClose: ReactEventHandler
}

function RenderSimpleMenu({ menuElem, pagePath, handleMenuMainClose }: Props) {
  return (
    <Link key={menuElem.page.path} href={menuElem.page.path}>
      <a
        className={clsx(`${styles.link}`, {
          [String(styles.selectedMenu)]: isMenuSelected(
            menuElem.page.path,
            pagePath
          ),
        })}
        data-testid="simple-row-testid"
      >
        <MenuItem
          key={menuElem.page.path}
          className={styles.menuItemIcon}
          onClick={handleMenuMainClose}
        >
          <div>{drawMenuIcon(menuElem.page)}</div>
          <div>{menuElem.page.name}</div>
        </MenuItem>
      </a>
    </Link>
  )
}

export default RenderSimpleMenu
