import GrpcBaseService from '@lib/services/grpc/base/grpc-base.service'
import {
  SlackNotificationSettings,
  EmailNotificationSettings,
} from 'blue-stack-libs/notifications-grpc-libs/js/notifications/messages/customer_settings_pb'
import { NotificationsPromiseClient } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/notifications_grpc_web_pb'
import {
  DisconnectSlackFromProfileRequest,
  GetCustomerNotificationSettingsRequest,
  UpdateCustomerNotificationSettingsRequest,
} from 'blue-stack-libs/notifications-grpc-libs/js/notifications/notifications_pb'
import { NotificationsConfig } from '@lib/interfaces/notifications.interface'

class GrpcUserNotificationService extends GrpcBaseService {
  protected static client = new NotificationsPromiseClient('', null, null)
  protected static clientName = 'Notifications'

  public static async getCustomerNotificationSettings() {
    const request = new GetCustomerNotificationSettingsRequest()

    const result = await this.handleQuery(
      this.client.getCustomerNotificationSettings,
      request
    )
    return result.toObject()
  }

  public static async updateCustomerNotificationSettings(
    config: NotificationsConfig
  ) {
    const request = new UpdateCustomerNotificationSettingsRequest()

    const { slack, email } = config

    if (!slack.isDisconnected) {
      const slackSettings = new SlackNotificationSettings()

      slackSettings.setSendErrors(slack.sendErrors)
      slackSettings.setSendInfo(slack.sendInfo)
      slackSettings.setSendWarnings(slack.sendWarnings)

      request.setSlackNotificationSettings(slackSettings)
    }

    const emailSettings = new EmailNotificationSettings()

    emailSettings.setSendErrors(email.sendErrors)
    emailSettings.setSendInfo(email.sendInfo)
    emailSettings.setSendWarnings(email.sendWarnings)

    request.setEmailNotificationSettings(emailSettings)

    const result = await this.handleQuery(
      this.client.updateCustomerNotificationSettings,
      request
    )
    return result.toObject()
  }

  public static async disconnectSlackFromProfile() {
    const request = new DisconnectSlackFromProfileRequest()

    const result = await this.handleQuery(
      this.client.disconnectSlackFromProfile,
      request
    )
    return result.toObject()
  }
}

export default GrpcUserNotificationService
