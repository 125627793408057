import React, { memo } from 'react'
import ToastWarningIcon from '@inline-img/general/toast/toast-warning-icon'

interface Props {
  message: string
  className?: string
}

function WarningMessage({ message, className }: Props) {
  return (
    <div className={`wrap-1690454195072 warningMessageBlock  ${className}`}>
      <ToastWarningIcon />
      <span
        className="warningMessage"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </div>
  )
}

export default memo(WarningMessage)
