import { ListEfs, Pagination } from '@lib/clients/grpc-imports'
import { AbstractListRequest } from '@lib/clients/list-request'

export class ListEfsPagination extends AbstractListRequest<ListEfs> {
  #pageToken: string

  constructor(pageToken: string) {
    super()
    this.#pageToken = pageToken
  }

  createGrpcRequest(): ListEfs {
    const grpcRequest = new ListEfs()
    grpcRequest.setPagination(new Pagination().setPageToken(this.#pageToken))

    return grpcRequest
  }
}
