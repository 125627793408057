/* eslint-disable import/no-extraneous-dependencies */
import { JobCloudConnector } from 'blues-corejs/dist'
import { JobsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import { Response as ListJobsResponse } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/jobs/list_cloud_connector_jobs_pb'
import { Request as GetJobRequest } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/jobs/get_cloud_connector_job_pb'
import { Request as GetBackupTimelineStatsRequest } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/jobs/get_backup_timeline_stats_pb'
import { Job as JobPt } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/jobs/cloud_connector/job_pb'
import { GrpcClient } from '@lib/clients/grpc-client'
import { CloudConnectorJobTransformer } from '../assets/transformers'
import { AbstractListRequest } from '../list-request'
import {
  GetCloudConnectorJobsFiltersRequest,
  ListCloudConnectorJobsRequest,
} from '../grpc-imports'
import { Pagination, TimeRange } from '../types'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

import { Response as GetCloudConnectorJobsFiltersResponse } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/jobs/get_cloud_connector_job_filters_pb'
import {
  CloudConnectorJobKindTransformer,
  CloudConnectorJobStatusTransformer,
} from '../assets/transformers/jobs/cloud-connector-jobs'
import { CloudConnectorJobRequestFilters } from '@lib/interfaces/jobs'
import { EnumHelper } from '@lib/helpers'
import JobKindConstant from '@lib/constants/jobs/job-kind.constant'

interface BackupTimelineStatsRequestParams {
  intervalMinutes: number
  kindsList: Array<JobKindConstant>
  redStackIdsList: Array<string>
  startTime: number
  endTime: number
}
interface ListCloudConnectorJobs {
  jobsList: Array<JobCloudConnector>
}

export class CloudConnectorJobsClient extends GrpcClient<JobsPromiseClient> {
  #jobsClient: JobsPromiseClient

  constructor(hostName = '') {
    super()
    this.#jobsClient = this.getClient(hostName)
  }

  async getCloudConnectorJob(id: string): Promise<JobCloudConnector> {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#jobsClient.getCloudConnectorJob(
            new GetJobRequest().setJobId(id),
            this.metadata()
          ),
        {
          requestName: 'JobsPromiseClient/getCloudConnectorJob',
        }
      )
    ).toObject()

    if (!response.job) {
      throw new Error('Cloud connector job not found')
    }

    return new CloudConnectorJobTransformer(response.job).transform()
  }

  protected initClient(hostName: string): JobsPromiseClient {
    return new JobsPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'CloudConnectorJobsClient'
  }

  async listCloudConnectorJobs(
    request: AbstractListRequest<ListCloudConnectorJobsRequest>
  ): Promise<ListCloudConnectorJobs & Pagination> {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#jobsClient.listCloudConnectorJobs(
            request.createGrpcRequest(),
            this.metadata()
          ),
        {
          requestName: 'JobsPromiseClient/listCloudConnectorJobs',
        }
      )
    ).toObject()

    return this.#transformResponse(response)
  }

  async getCloudConnectorJobsFilters(
    timeRange: TimeRange
  ): Promise<CloudConnectorJobRequestFilters> {
    const timeRangeProto = new GetCloudConnectorJobsFiltersRequest.TimeRange()

    const startTimeStamp = new Timestamp()
    const endTimeStamp = new Timestamp()

    if (timeRange.start) {
      timeRangeProto.setStart(startTimeStamp.setSeconds(timeRange.start))
    }

    if (timeRange.end) {
      timeRangeProto.setEnd(endTimeStamp.setSeconds(timeRange.end))
    }

    const response = (
      await this.retryGrpcCall(
        () =>
          this.#jobsClient.getCloudConnectorJobsFilters(
            new GetCloudConnectorJobsFiltersRequest().setTimeRange(
              timeRangeProto
            ),
            this.metadata()
          ),
        {
          requestName: 'JobsPromiseClient/getCloudConnectorJobsFilters',
        }
      )
    ).toObject()

    return this.#transformCloudConnectorJobFilters(response)
  }

  async getBackupTimelineStats({
    redStackIdsList,
    endTime,
    startTime,
    kindsList,
    intervalMinutes,
  }: BackupTimelineStatsRequestParams) {
    const request = new GetBackupTimelineStatsRequest()

    request.setIntervalMinutes(intervalMinutes)
    request.setStart(Timestamp.fromDate(new Date(startTime)))
    request.setEnd(Timestamp.fromDate(new Date(endTime)))
    request.setKindsList(kindsList.map((kind) => this.#setJobKind(kind)))
    request.setCcIdsList(redStackIdsList)

    const response = (
      await this.retryGrpcCall(
        () => this.#jobsClient.getBackupTimelineStats(request, this.metadata()),
        {
          requestName: 'JobsPromiseClient/getBackupTimelineStats',
        }
      )
    ).toObject()

    return response.countsList
  }

  // TODO: get rid of old enum JobKindConstant
  // Right now not good time cause we need to deliver this thing fast
  #setJobKind(kind: JobKindConstant) {
    const ProtobufJobKind = JobPt.Kind

    const matchingKind = EnumHelper.getNumericEnumValues(ProtobufJobKind).find(
      (protobufKind) => protobufKind === kind
    )

    if (matchingKind === undefined) {
      throw new Error(`ListTenantJobsFilter: Invalid job kind: ${kind}`)
    }

    return matchingKind
  }

  #transformResponse(
    response: ListJobsResponse.AsObject
  ): ListCloudConnectorJobs & Pagination {
    return {
      jobsList: response.jobsList.map((job) =>
        new CloudConnectorJobTransformer(job).transform()
      ),
      pageToken: response.pagination?.nextPageToken,
    }
  }

  #transformCloudConnectorJobFilters(
    response: GetCloudConnectorJobsFiltersResponse.AsObject
  ): CloudConnectorJobRequestFilters {
    return {
      ...response,
      kindsList: response.kindsList.map((kind) =>
        new CloudConnectorJobKindTransformer(kind).transform()
      ),
      statusesList: response.statusesList.map((status) =>
        new CloudConnectorJobStatusTransformer(status).transform()
      ),
    }
  }
}
