// took from
// blue-stack-libs/catalog-grpc-libs/js/catalog/catalog_pb.d.ts

import SystemHelper from '@lib/helpers/system.helper'

export enum IntegrityCheckStatusConstant {
  UNSCANNED = 0,
  HEALTHY = 1,
  QUARANTINED = 2,
  MANUALLYRECOVERABLE = 3,
  NONE = 4,
}

export function getIntegrityCheckStatusName(
  status: IntegrityCheckStatusConstant
): string {
  switch (status) {
    case IntegrityCheckStatusConstant.UNSCANNED:
      return 'Unscanned'

    case IntegrityCheckStatusConstant.HEALTHY:
      return 'Healthy'

    case IntegrityCheckStatusConstant.QUARANTINED:
      return 'Quarantined'

    case IntegrityCheckStatusConstant.MANUALLYRECOVERABLE:
      return 'Manually recoverable'

    case IntegrityCheckStatusConstant.NONE:
      return 'Unprotected'

    default:
      SystemHelper.throwErrorInLocalEnv('Wrong integrity check status')
      return ''
  }
}
