"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.s3ItemFactory = void 0;
const fishery_1 = require("fishery");
const s3_item_1 = require("../../models/asset-items/s3-item");
const factories_1 = require("../../use_cases/tests/factories");
const common_1 = require("./common");
const types_1 = require("../../models/asset-items/s3-item/types");
class S3ItemFactory extends fishery_1.Factory {
}
const selector = () => ({
    kind: (0, common_1.randomValueFromEnum)(types_1.S3ItemKind),
    value: (0, factories_1.uuid)(),
});
const selectors = new Array(10).fill(null).map(selector);
exports.s3ItemFactory = S3ItemFactory.define(({ transientParams }) => {
    return new s3_item_1.S3Item({
        id: transientParams.id ?? (0, factories_1.uuid)(),
        assetId: transientParams.assetId ?? (0, factories_1.uuid)(),
        ccAssetId: transientParams.ccAssetId ?? (0, factories_1.uuid)(),
        selectors: transientParams.selectors ?? selectors,
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
    });
});
