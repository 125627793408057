import FsCheckScansReportsState from '@store/states/fs-check-scans-reports.state'
import {
  Kind,
  ListScansByFiltersReqFilterParams,
} from '@lib/clients/scans/types'
import TimeHelper from '@lib/helpers/time.helper'
import ReportDateRangeConstant, {
  getReportDateRangeName,
} from '@lib/constants/report-date-range.constant'
import {
  SET_FS_CHECK_REPORTS_PERIOD,
  SET_FS_CHECK_REPORTS_SELECTED_FILTERS,
  SET_FS_CHECK_REPORTS_SELECTED_FILTERS_FOR_API,
  SET_FS_CHECK_REPORTS_SELECTED_RP_FILTERS,
} from '@store/actions'
import ActionInterface from '@lib/interfaces/action.interface'

export const DATE_RANGE_DEFAULT_PERIOD_FS_CHECK = {
  dateRange: TimeHelper.currentWeekDateRange(),
  period: {
    name: getReportDateRangeName(ReportDateRangeConstant.ThisWeek),
    value: ReportDateRangeConstant.ThisWeek,
  },
}
export const DEFAULT_SELECTED_FILTERS_FOR_API: ListScansByFiltersReqFilterParams =
  {
    accountIdsList: [],
    regionsList: [],
    scanTargetKindsList: [],
    humanReadableRecoveryPointIdsList: [],
    assetIdList: [],
    statusesList: [],
    kindsList: [Kind.FILE_SYSTEM_CHECK],
    timeRange: TimeHelper.transformDateRangeForApi(
      DATE_RANGE_DEFAULT_PERIOD_FS_CHECK.dateRange
    ),
  }

const INITIAL_STATE: FsCheckScansReportsState = {
  reportPeriod: DATE_RANGE_DEFAULT_PERIOD_FS_CHECK,
  selectedFilters: [],
  selectedRPFilters: '',
  selectedFiltersForApi: DEFAULT_SELECTED_FILTERS_FOR_API,
}

function fsCheckScansReportsReducer(
  prevState = INITIAL_STATE,
  action: ActionInterface
): FsCheckScansReportsState {
  switch (action.type) {
    case SET_FS_CHECK_REPORTS_PERIOD:
      return {
        ...prevState,
        reportPeriod: action.payload,
      }
    case SET_FS_CHECK_REPORTS_SELECTED_FILTERS:
      return {
        ...prevState,
        selectedFilters: action.payload,
      }
    case SET_FS_CHECK_REPORTS_SELECTED_RP_FILTERS:
      return {
        ...prevState,
        selectedRPFilters: action.payload,
      }
    case SET_FS_CHECK_REPORTS_SELECTED_FILTERS_FOR_API:
      return {
        ...prevState,
        selectedFiltersForApi: action.payload,
      }
    default:
      return prevState
  }
}

export default fsCheckScansReportsReducer
