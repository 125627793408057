import { PolicyStatus } from '@lib/constants/policies.constant'
import ActionInterface from '@lib/interfaces/action.interface'
import {
  SET_EDIT_POLICY_CURRENT_ASSETS_SELECTED,
  SET_EDIT_POLICY_MISSING_ASSETS_SELECTED,
  SET_EDIT_POLICY_MISSING_ASSETS_SELECTED_INITIAL,
  SET_EDIT_POLICY_RECOVERY_PERIOD,
  SET_EDIT_POLICY_RECOVERY_PERIOD_INITIAL,
  SET_EDIT_POLICY_RETENTION,
  SET_EDIT_POLICY_RETENTION_ACCOUNT_IDS,
  SET_EDIT_POLICY_RETENTION_ACCOUNT_IDS_INITIAL,
  SET_EDIT_POLICY_RETENTION_ASSET_SOURCES,
  SET_EDIT_POLICY_RETENTION_ASSET_SOURCES_INITIAL,
  SET_EDIT_POLICY_RETENTION_BACKUP_TYPES,
  SET_EDIT_POLICY_RETENTION_BACKUP_TYPES_INITIAL,
  SET_EDIT_POLICY_RETENTION_NAME,
  SET_EDIT_POLICY_RETENTION_REGIONS,
  SET_EDIT_POLICY_RETENTION_REGIONS_INITIAL,
  SET_EDIT_POLICY_RETENTION_SELECTED_FILTERS,
  SET_EDIT_POLICY_RETENTION_STATUS,
  SET_EDIT_POLICY_RETENTION_TAGS,
  SET_EDIT_POLICY_RETENTION_TAGS_INITIAL,
  SET_EDIT_POLICY_RETENTION_VAULTS,
  SET_EDIT_POLICY_RETENTION_VAULTS_INITIAL,
  SET_EDIT_POLICY_SELECTED_ASSETS_INITIAL,
} from '@store/actions/edit-policy-retention.action'
import EditPolicyRetentionState from '@store/states/edit-policy-retention.state'

const initialState: EditPolicyRetentionState = {
  policyName: '',
  policyStatus: PolicyStatus.POLICY_STATUS_ENABLED,
  policyRetention: null,
  policyRetentionAccountIds: [],
  policyRetentionRegions: [],
  policyRetentionBackupTypes: [],
  policyRetentionAssetSources: [],
  policyRetentionTags: [],
  policyRetentionVaults: [],
  policyRetentionAccountIdsInitial: [],
  policyRetentionRegionsInitial: [],
  policyRetentionBackupTypesInitial: [],
  policyRetentionAssetSourcesInitial: [],
  policyRetentionTagsInitial: [],
  policyRetentionVaultsInitial: [],
  recoveryPeriod: {
    period: [
      {
        periodValue: 'All',
        countDuration: '',
        duration: '',
        removable: false,
      },
    ],
  },
  recoveryPeriodInitial: {
    period: [
      {
        periodValue: 'All',
        countDuration: '',
        duration: '',
        removable: false,
      },
    ],
  },
  currentAssetsSelected: [],
  selectedAssetsInitial: [],
  missingAssetsSelected: [],
  missingAssetsSelectedInitial: [],
  assetsSelectedFilters: [],
}

function editPolicyRetentionReducer(
  prevState = initialState,
  action: ActionInterface
): EditPolicyRetentionState {
  switch (action.type) {
    case SET_EDIT_POLICY_RETENTION_NAME:
      return {
        ...prevState,
        policyName: action.payload,
      }
    case SET_EDIT_POLICY_RETENTION_STATUS:
      return {
        ...prevState,
        policyStatus: action.payload,
      }

    case SET_EDIT_POLICY_RETENTION:
      return {
        ...prevState,
        policyRetention: action.payload,
      }

    case SET_EDIT_POLICY_RETENTION_ACCOUNT_IDS:
      return {
        ...prevState,
        policyRetentionAccountIds: action.payload,
      }

    case SET_EDIT_POLICY_RETENTION_REGIONS:
      return {
        ...prevState,
        policyRetentionRegions: action.payload,
      }

    case SET_EDIT_POLICY_RETENTION_BACKUP_TYPES:
      return {
        ...prevState,
        policyRetentionBackupTypes: action.payload,
      }

    case SET_EDIT_POLICY_RETENTION_ASSET_SOURCES:
      return {
        ...prevState,
        policyRetentionAssetSources: action.payload,
      }

    case SET_EDIT_POLICY_RETENTION_TAGS:
      return {
        ...prevState,
        policyRetentionTags: action.payload,
      }

    case SET_EDIT_POLICY_RETENTION_VAULTS:
      return {
        ...prevState,
        policyRetentionVaults: action.payload,
      }

    case SET_EDIT_POLICY_RETENTION_ACCOUNT_IDS_INITIAL:
      return {
        ...prevState,
        policyRetentionAccountIds: action.payload,
        policyRetentionAccountIdsInitial: action.payload,
      }

    case SET_EDIT_POLICY_RETENTION_REGIONS_INITIAL:
      return {
        ...prevState,
        policyRetentionRegions: action.payload,
        policyRetentionRegionsInitial: action.payload,
      }

    case SET_EDIT_POLICY_RETENTION_BACKUP_TYPES_INITIAL:
      return {
        ...prevState,
        policyRetentionBackupTypes: action.payload,
        policyRetentionBackupTypesInitial: action.payload,
      }

    case SET_EDIT_POLICY_RETENTION_ASSET_SOURCES_INITIAL:
      return {
        ...prevState,
        policyRetentionAssetSources: action.payload,
        policyRetentionAssetSourcesInitial: action.payload,
      }

    case SET_EDIT_POLICY_RETENTION_TAGS_INITIAL:
      return {
        ...prevState,
        policyRetentionTags: action.payload,
        policyRetentionTagsInitial: action.payload,
      }

    case SET_EDIT_POLICY_RETENTION_VAULTS_INITIAL:
      return {
        ...prevState,
        policyRetentionVaults: action.payload,
        policyRetentionVaultsInitial: action.payload,
      }

    case SET_EDIT_POLICY_RECOVERY_PERIOD:
      return {
        ...prevState,
        recoveryPeriod: action.payload,
      }
    case SET_EDIT_POLICY_RECOVERY_PERIOD_INITIAL:
      return {
        ...prevState,
        recoveryPeriod: action.payload,
        recoveryPeriodInitial: action.payload,
      }

    case SET_EDIT_POLICY_CURRENT_ASSETS_SELECTED:
      return {
        ...prevState,
        currentAssetsSelected: action.payload,
      }

    case SET_EDIT_POLICY_MISSING_ASSETS_SELECTED:
      return {
        ...prevState,
        missingAssetsSelected: action.payload,
      }

    case SET_EDIT_POLICY_SELECTED_ASSETS_INITIAL:
      return {
        ...prevState,
        selectedAssetsInitial: action.payload,
      }

    case SET_EDIT_POLICY_MISSING_ASSETS_SELECTED_INITIAL:
      return {
        ...prevState,
        missingAssetsSelected: action.payload,
        missingAssetsSelectedInitial: action.payload,
      }

    case SET_EDIT_POLICY_RETENTION_SELECTED_FILTERS:
      return {
        ...prevState,
        assetsSelectedFilters: action.payload,
      }

    default:
      return prevState
  }
}

export default editPolicyRetentionReducer
