"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OsKind = exports.EC2State = void 0;
var EC2State;
(function (EC2State) {
    EC2State[EC2State["RUNNING"] = 0] = "RUNNING";
    EC2State[EC2State["STOPPED"] = 1] = "STOPPED";
    EC2State[EC2State["TERMINATED"] = 2] = "TERMINATED";
    EC2State[EC2State["UNKNOWN"] = 3] = "UNKNOWN";
})(EC2State = exports.EC2State || (exports.EC2State = {}));
var OsKind;
(function (OsKind) {
    OsKind[OsKind["OTHER"] = 0] = "OTHER";
    OsKind[OsKind["LINUX"] = 1] = "LINUX";
    OsKind[OsKind["WINDOWS"] = 2] = "WINDOWS";
})(OsKind = exports.OsKind || (exports.OsKind = {}));
