"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.genericHostsFactory = void 0;
const fishery_1 = require("fishery");
const common_1 = require("./common");
const models_1 = require("../../../models");
class GenericHostsFactory extends fishery_1.Factory {
    withNoBackups() {
        return this.transient({
            threatStatus: null,
        });
    }
}
const genericHostsFactory = GenericHostsFactory.define(({ sequence, transientParams }) => {
    const region = transientParams.region ?? (0, common_1.awsRegion)();
    const account = transientParams.accountId ?? (0, common_1.awsAccountId)();
    const genericHostName = transientParams.hostName ?? (0, common_1.name)('TestHost', sequence);
    return new models_1.GenericHostModel({
        id: transientParams.id ?? (0, common_1.uuid)(),
        tenantName: transientParams.tenantName ?? (0, common_1.tenant)(),
        accountId: account,
        region,
        cloudConnectorId: transientParams.cloudConnectorId ?? (0, common_1.uuid)(),
        cloudConnectorAssetId: transientParams.cloudConnectorAssetId ??
            (0, common_1.awsCCAssetId)(account, region, genericHostName, 'generic-host'),
        hostName: genericHostName,
        tags: transientParams.tags ?? new Map(),
        createdAt: transientParams.createdAt ?? Date.now(),
        updatedAt: transientParams.createdAt ?? Date.now(),
        threatStatus: transientParams.threatStatus ?? null,
        coveredByPolicies: transientParams.coveredByPolicies ?? [],
        associatedRetentionPolicies: transientParams.associatedRetentionPolicies ?? [],
        coveredByPausedPolicies: transientParams.coveredByPausedPolicies ?? [],
        osKind: transientParams.osKind ?? 0,
        cpuCores: transientParams.cpuCores ?? 0,
    });
});
exports.genericHostsFactory = genericHostsFactory;
