/* eslint-disable import/no-extraneous-dependencies */
import {
  RedStack,
  Status as RedStackStatusProto,
} from 'blue-stack-libs/rex-grpc-libs/js/rex/messages/list_redstacks_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

import { CloudConnector, CloudConnectorStatus } from 'blues-corejs/dist/models'

export class CloudConnectorTransformer {
  #cloudConnectorProto: RedStack.AsObject

  constructor(cloudConnectorProto: RedStack.AsObject) {
    this.#cloudConnectorProto = cloudConnectorProto
  }

  transform(): CloudConnector {
    return this.#transformCloudConnector(this.#cloudConnectorProto)
  }

  #transformCloudConnector(
    cloudConnectorProto: RedStack.AsObject
  ): CloudConnector {
    return new CloudConnector({
      redStackId: cloudConnectorProto.redStackId,
      awsAccountId: cloudConnectorProto.awsAccount,
      awsRegion: cloudConnectorProto.awsRegion,
      accountAlias: cloudConnectorProto.accountAlias,
      status: this.#transformCloudConnectorStatus(cloudConnectorProto.status),
      lastErrorMessage: cloudConnectorProto.lastErrorMessage,
      iamRole: cloudConnectorProto.iamRole,
      accountDescription: cloudConnectorProto.accountDescription,
      lastWarningMessage: cloudConnectorProto.lastWarningMessage,
      awsAccountCfnVersion: cloudConnectorProto.awsAccountCfnVersion,
      version: cloudConnectorProto.version,
      createdAt: this.#transformTimestamp(cloudConnectorProto.createdAt),
      updatedAt: this.#transformTimestamp(cloudConnectorProto.updatedAt),
      lastIntegrityCheckErrors:
        cloudConnectorProto.lastIntegrityCheckErrorsList,
      tenantName: cloudConnectorProto.tenantName,
      cfnUpgradeRequired: cloudConnectorProto.cfnUpgradeRequired,
    })
  }

  #transformCloudConnectorStatus(
    status: RedStackStatusProto
  ): CloudConnectorStatus {
    const mapping = {
      [RedStackStatusProto.ACTIVE]: CloudConnectorStatus.ACTIVE,
      [RedStackStatusProto.INACTIVE]: CloudConnectorStatus.INACTIVE,
      [RedStackStatusProto.DELETED]: CloudConnectorStatus.DELETED,
      [RedStackStatusProto.UPGRADE_REQUIRED]:
        CloudConnectorStatus.UPGRADE_REQUIRED,
    }

    return mapping[status]
  }

  #transformTimestamp(timestamp?: Timestamp.AsObject): Date | undefined {
    if (!timestamp) {
      return undefined
    }

    return new Date(timestamp.seconds * 1000)
  }
}
