/* eslint-disable import/no-extraneous-dependencies */
import { LiveAssets } from '@lib/clients/assets/types'
import { AWSSliceCriteria } from 'blues-corejs/dist/models/inventory.model'

export const SET_LIVE_ASSETS_STATE = 'SET_MANAGEMENT_VIEW'

export const SET_SLICE = 'SET_SLICE'

export const SET_LIVE_ASSETS_LOADING = 'SET_LIVE_ASSETS_LOADING'

export const setListLiveAssetsState = (payload: {
  liveAssetsResponse: LiveAssets
}) => ({
  type: SET_LIVE_ASSETS_STATE,
  payload,
})

export const setSlice = (payload: AWSSliceCriteria) => ({
  type: SET_SLICE,
  payload,
})

export const setListLiveAssetsLoading = (payload: boolean) => ({
  type: SET_LIVE_ASSETS_LOADING,
  payload,
})
