import StrHelper from '@lib/helpers/str.helper'
import SystemHelper from '@lib/helpers/system.helper'
import TypeHelper from '@lib/helpers/type.helper'

// took from
// ./blue-stack-libs/jobstatus-grpc-libs/js/jobstatus/messages/job_kind_pb.d.ts

enum JobKindConstant {
  UNDEFINED = -1,
  UNKNOWN = 0,
  INSTALL_CLOUD_CONNECTOR = 1,
  CREATE_VAULT = 2,
  EBS_BACKUP = 3,
  EBS_RESTORE = 4,
  EC2_BACKUP = 5,
  EC2_RESTORE = 6,
  EFS_BACKUP = 7,
  ROLLUP = 8,
  DESTROY_VAULT = 9,
  REFRESH_PLANS = 10,
  SET_PLAN_STATUS = 11,
  RESYNC = 12,
  ACTIVATE_CLOUD_CONNECTOR = 13,
  ANALYZE_CLOUD_CONNECTOR_TARGET_REGION = 14,
  EVALUATE_PLANS = 15,
  BLOCK_BACKUP = 16,
  BLOCK_RESTORE = 17,
  FILE_BACKUP = 18,
  FILE_RESTORE = 19,
  STREAM_BACKUP = 20,
  STREAM_RESTORE = 21,
  SCAN_INVENTORY = 22,
  ISCAN = 23,
  ENABLE_ELASTIO_REPORTS_GATHERING = 24,
  DISABLE_ELASTIO_REPORTS_GATHERING = 25,
  UPGRADE_CLOUD_CONNECTOR = 26,
  UPGRADE_VAULT = 27,
  REPAIR_REPORT_GATHERING_INFRA = 28,
  CLEANUP_RED_STACK_DATA = 29,
  REPAIR_CLOUD_CONNECTOR = 30,
  REPAIR_VAULT = 31,
  UPDATE_VAULT_SUBNETS = 32,
  CLOUD_CONNECTOR_INTEGRITY_CHECK = 33,
  CLOUD_CONNECTOR_INFRASTRUCTURE_CHECK = 34,
  VAULT_INFRASTRUCTURE_CHECK = 35,
  VAULT_NETWORKING_CHECK = 36,
  EXECUTE_BACKUP_POLICY = 37,
  CLEANUP_BACKUP_POLICY_COVERAGE = 38,
  REFRESH_CONNECTOR_RETENTION_POLICIES = 39,
  EVALUATE_RETENTION_POLICIES = 40,
  IMPORT_SNAPSHOTS = 41,
  BACKUP_INTEGRITY_CHECK = 42,
  UPDATE_VAULT = 43,
  APPLY_RETENTION_POLICY = 44,
  FS_CHECK = 45,
  POSTGRES_BACKUP = 46,
  POSTGRES_RESTORE = 47,
  AWS_BACKUP_IMPORT = 48,
  VAULT_CATALOG_RESTORE = 49,
  EVALUATE_ASSETS_INTEGRITY_STATUS = 50,
  DATABASE_MAINTENANCE = 51,
  POSTGRES_RECOVERY_TEST = 52,
  S3_BACKUP = 53,
  REFRESH_ASSET_THREAT_STATUSES = 54,
  S3_RESTORE = 55,
  GENERATE_SCHEDULED_REPORT = 56,
  DELETE_CLOUD_CONNECTOR = 57,
  AWS_BACKUP_RP_ISCAN = 58,
  AWS_BACKUP_RP_EC2_SCAN = 59,
  AWS_BACKUP_RP_EBS_SCAN = 60,
  AWS_BACKUP_RP_EFS_SCAN = 61,
  AWS_EFS_SCAN = 62,
  AWS_BACKUP_RP_EC2_IMPORT = 63,
  AWS_BACKUP_RP_EBS_IMPORT = 64,
  VAULT_REPLICATION = 65,
  AWS_EC2_AMI_SCAN = 66,
  AWS_FSX_ONTAP_SCAN = 67,
  AWS_EBS_SCAN = 68,
  AWS_EC2_SCAN = 69,
  AWS_BACKUP_RP_FSX_ONTAP_SCAN = 70,
  AWS_EC2_AMI_IMPORT = 71,
  AWS_EBS_SNAPSHOT_SCAN = 72,
  AWS_S3_SCAN = 73,
}

export function getJobKindName(value: JobKindConstant): string {
  const processedValue = TypeHelper.enumValDef(
    value,
    JobKindConstant.UNDEFINED,
    JobKindConstant
  )
  const kindName = StrHelper.buildHumanLabel(
    JobKindConstant[processedValue] ?? ''
  )

  if (JobKindConstant.UNDEFINED === processedValue) {
    SystemHelper.throwErrorInLocalEnv('Job Kind is not found')
    return ''
  }
  if (JobKindConstant.EC2_BACKUP === processedValue) {
    return 'EC2 Backup'
  }
  if (JobKindConstant.EBS_BACKUP === processedValue) {
    return 'EBS Backup'
  }
  if (JobKindConstant.EBS_RESTORE === processedValue) {
    return 'EBS Restore'
  }
  if (JobKindConstant.EC2_RESTORE === processedValue) {
    return 'EC2 Restore'
  }
  if (JobKindConstant.CLEANUP_RED_STACK_DATA === processedValue) {
    return 'Cleanup Cloud Connector Data'
  }
  if (JobKindConstant.FS_CHECK === processedValue) {
    return 'Filesystem Check'
  }
  if (StrHelper.isSubstring(kindName, 'plans', true)) {
    return kindName.replace('Plans', 'Policies')
  }
  if (StrHelper.isSubstring(kindName, 'plan', true)) {
    return kindName.replace('Plan', 'Policy')
  }
  if (JobKindConstant.AWS_BACKUP_IMPORT === processedValue) {
    return 'AWS Backup Import'
  }
  if (JobKindConstant.EXECUTE_BACKUP_POLICY === processedValue) {
    return 'Execute Protection Policy'
  }
  if (JobKindConstant.CLEANUP_BACKUP_POLICY_COVERAGE === processedValue) {
    return 'Cleanup Protection Policy Coverage'
  }
  if (JobKindConstant.AWS_BACKUP_RP_ISCAN === processedValue) {
    return 'AWS Backup RP Iscan'
  }
  if (JobKindConstant.AWS_BACKUP_RP_EC2_SCAN === processedValue) {
    return 'AWS Backup RP EC2 Scan'
  }

  if (JobKindConstant.AWS_BACKUP_RP_EBS_SCAN === processedValue) {
    return 'AWS Backup RP EBS Scan'
  }

  if (JobKindConstant.AWS_BACKUP_RP_EFS_SCAN === processedValue) {
    return 'AWS Backup RP EFS Scan'
  }

  if (JobKindConstant.AWS_EFS_SCAN === processedValue) {
    return 'AWS EFS Scan'
  }

  if (JobKindConstant.AWS_BACKUP_RP_EC2_IMPORT === processedValue) {
    return 'AWS Backup RP EC2 Import'
  }

  if (JobKindConstant.AWS_BACKUP_RP_EBS_IMPORT === processedValue) {
    return 'AWS Backup RP EBS Import'
  }

  if (JobKindConstant.AWS_EC2_AMI_SCAN === processedValue) {
    return 'AWS EC2 AMI Scan'
  }

  if (JobKindConstant.AWS_FSX_ONTAP_SCAN === processedValue) {
    return 'AWS FSX Ontap Scan'
  }

  if (JobKindConstant.AWS_EBS_SCAN === processedValue) {
    return 'AWS EBS Scan'
  }

  if (JobKindConstant.AWS_EC2_SCAN === processedValue) {
    return 'AWS EC2 Scan'
  }

  if (JobKindConstant.AWS_BACKUP_RP_FSX_ONTAP_SCAN === processedValue) {
    return 'AWS Backup RP FSX Ontap Scan'
  }

  if (JobKindConstant.AWS_EC2_AMI_IMPORT === processedValue) {
    return 'AWS EC2 AMI Import'
  }

  if (JobKindConstant.AWS_EBS_SNAPSHOT_SCAN === processedValue) {
    return 'AWS EBS Snapshot Scan'
  }

  if (JobKindConstant.AWS_S3_SCAN === processedValue) {
    return 'AWS S3 Scan'
  }

  return kindName
}

export default JobKindConstant
