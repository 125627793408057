import { AbstractListRequest } from '@lib/clients/list-request'
import { ListEc2 } from '@lib/clients/grpc-imports'

type Tag = { key: string; value: string }

interface ListEc2FilterParams {
  assetIdList: Array<string>
  tagsList: Array<Tag>
  excludeTagsList: Array<Tag>
  accountIdsList: Array<string>
  regionsList: Array<string>
  vpcIdsList: Array<string>
  pageSize: number
}

export class ListEc2Filter extends AbstractListRequest<ListEc2> {
  #requestParams: Partial<ListEc2FilterParams>

  constructor(requestParams: Partial<ListEc2FilterParams>) {
    super()
    this.#requestParams = requestParams
  }

  createGrpcRequest(): ListEc2 {
    const grpcRequest = new ListEc2()

    const filter = new ListEc2.Initial.Filter()

    const { assetIdList } = this.#requestParams
    if (assetIdList && assetIdList.length) {
      filter.setAssetIdList(assetIdList)
    }

    return grpcRequest.setInitial(
      new ListEc2.Initial()
        .setFilter(filter)
        .setPageSize(
          this.#requestParams.pageSize || AbstractListRequest.DEFAULT_PAGE_SIZE
        )
    )
  }
}
