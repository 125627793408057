import { RefObject, useCallback, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useVisibilityObserver } from '@components/table/use-visibility-observer'

type FetcherFunction = () => Promise<void>

interface UseInfiniteScrollOwnProps<T> {
  initialItems: Array<T>
  fetchNextBatch: FetcherFunction
  observerOptions?: IntersectionObserverInit
  debounceDelay?: number
  pageSize?: number
}

const DEBOUNCE_DELAY = 150
export interface UseInfiniteScrollDataFetch {
  observerElement: RefObject<HTMLTableSectionElement | HTMLTableRowElement>
  isInitialFetched: boolean
}
function useInfiniteScrollDataFetch<T>({
  fetchNextBatch,
  debounceDelay = DEBOUNCE_DELAY,
}: UseInfiniteScrollOwnProps<T>): UseInfiniteScrollDataFetch {
  const [isInitialFetched, setIsInitialFetched] = useState(false)
  const loadMoreItems = useCallback(async () => {
    try {
      await fetchNextBatch()
      setIsInitialFetched(true)
    } catch (err) {
      console.error(err instanceof Error ? err.message : err)
    }
  }, [fetchNextBatch])

  const debouncedLoadMoreItems = useDebouncedCallback(
    loadMoreItems,
    debounceDelay
  )

  const { observedElement } = useVisibilityObserver<HTMLTableSectionElement>(
    debouncedLoadMoreItems
  )

  return {
    observerElement: observedElement,
    isInitialFetched,
  }
}

export { useInfiniteScrollDataFetch }
