import React, { PropsWithChildren } from 'react'
import Box from '@mui/material/Box'

type ParenthesizedDateTimeTooltipOwnProps = PropsWithChildren<{
  parenthesisType?: ''
}>
function ParenthesizedDateTimeTooltip({
  children,
}: ParenthesizedDateTimeTooltipOwnProps = {}) {
  return <Box>({children})</Box>
}

export default ParenthesizedDateTimeTooltip
