import { VIRow } from '@lib/engine-types'
import LangHelper from '@lib/helpers/lang.helper'
import NumHelper from '@lib/helpers/num.helper'

abstract class GeneralFactory {
  public static buildPossibleRegions(data: Array<string>): VIRow {
    return data.map((regionName) => ({
      id: NumHelper.numberHash(regionName),
      name: regionName,
      label: LangHelper.getAwsRegionSingleTranslation(regionName),
    }))
  }
}

export default GeneralFactory
