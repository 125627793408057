import React from 'react'
import { ExpandButtonOwnProps } from '@components/table/core/cell/expand-button/types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import styles from './ExpandButton.module.css'

const StyledExpandButton = styled(Button)(() => ({
  width: 16,
  height: 16,
  background: 'transparent',
  minWidth: '16px !important',
}))

function ExpandButton({ isExpanded, toggleExpanded }: ExpandButtonOwnProps) {
  return (
    <StyledExpandButton
      onClick={toggleExpanded}
      variant="text"
      className={clsx(styles.Button, {
        [String(styles.Active)]: isExpanded,
      })}
    />
  )
}

export default ExpandButton
