import CopyRow from '@components-simple/copy-row/CopyRow'
import React from 'react'
import { CopyCellOwnProps } from '@components/table/core/cell/copy-cell/types'

function copyCell({
  additionalText,
  copyText,
  className = '',
}: CopyCellOwnProps) {
  return (
    <div>
      {additionalText && <div>{additionalText}</div>}
      <CopyRow text={copyText} variant="unstyled" className={className} />
    </div>
  )
}

export default copyCell
