import { GrpcClient } from '@lib/clients/grpc-client'
import { AssetsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import { EBSTransformer } from '@lib/clients/assets/transformers'
// eslint-disable-next-line import/no-extraneous-dependencies
import { EBS } from 'blues-corejs/dist'
import { ListEbsRequest } from '@lib/clients/assets/list-ebs'

export class ListEbsClient extends GrpcClient<AssetsPromiseClient> {
  #assetsClient: AssetsPromiseClient

  constructor(hostName = '') {
    super()
    this.#assetsClient = this.getClient(hostName)
  }

  protected initClient(hostName: string): AssetsPromiseClient {
    return new AssetsPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'ListEbsClient'
  }

  async list(request: ListEbsRequest): Promise<Array<EBS>> {
    const response = (
      await this.retryGrpcCall(
        () => this.#assetsClient.listEBS(request.toGrpc(), this.metadata()),
        {
          requestName: 'AssetsPromiseClient/listEBS',
        }
      )
    ).toObject()

    return response.volumesList.map((item) =>
      new EBSTransformer(item).transform()
    )
  }
}
