/* eslint-disable import/no-extraneous-dependencies */
import TimeHelper from '@lib/helpers/time.helper'
import { Disk as DiskProto } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/asset_items/disk_pb'
import { Disk } from 'blues-corejs/dist'

type DiskPt = DiskProto.AsObject

export class DiskTransformer {
  #disk: DiskPt

  constructor(disk: DiskPt) {
    this.#disk = disk
  }

  transform(): Disk {
    return this.#transformDisk(this.#disk)
  }

  #transformDisk(disk: DiskPt): Disk {
    return new Disk({
      id: disk.id,
      ccAssetId: disk.ccAssetId,
      assetId: disk.assetId,
      deviceName: disk.deviceName,
      createdAt: TimeHelper.secondsToDate(disk.createdAt?.seconds),
      updatedAt: TimeHelper.secondsToDate(disk.updatedAt?.seconds),
    })
  }
}
