"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.randomString = exports.bool = exports.tenant = exports.awsResourceId = exports.awsCost = exports.awsAmiLocation = exports.awsAmiName = exports.awsCCAssetId = exports.awsSnapshotId = exports.awsAmiId = exports.awsInstanceId = exports.awsVolumeId = exports.awsAvailabilityZone = exports.awsRegion = exports.awsAccountId = exports.instanceState = exports.osKind = exports.volumeStatusCheck = exports.volumeStatus = exports.name = exports.uuid = void 0;
const models_1 = require("../../../models");
function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0, v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
    // return crypto.randomUUID()
}
exports.uuid = uuid;
function name(modelName, n) {
    if (bool()) {
        return '';
    }
    return `${modelName}-${n}`;
}
exports.name = name;
function volumeStatus() {
    const statuses = [
        models_1.VolumeStatus.VOLUME_STATUS_UNKNOWN,
        models_1.VolumeStatus.AVAILABLE,
        models_1.VolumeStatus.IN_USE,
        models_1.VolumeStatus.CREATING,
        models_1.VolumeStatus.DELETING,
        models_1.VolumeStatus.ERROR,
    ];
    return statuses[Math.floor(Math.random() * statuses.length)];
}
exports.volumeStatus = volumeStatus;
function volumeStatusCheck() {
    const statuses = [
        models_1.VolumeStatusCheck.VOLUME_STATUS_CHECK_UNKNOWN,
        models_1.VolumeStatusCheck.OK,
        models_1.VolumeStatusCheck.INSUFFICIENT_DATA,
        models_1.VolumeStatusCheck.IMPAIRED,
    ];
    return statuses[Math.floor(Math.random() * statuses.length)];
}
exports.volumeStatusCheck = volumeStatusCheck;
function osKind() {
    const kinds = [models_1.OsKind.LINUX, models_1.OsKind.WINDOWS, models_1.OsKind.OTHER];
    return kinds[Math.floor(Math.random() * kinds.length)];
}
exports.osKind = osKind;
function instanceState() {
    const states = [
        models_1.InstanceState.INSTANCE_STATE_UNKNOWN,
        models_1.InstanceState.INSTANCE_STATE_STOPPED,
        models_1.InstanceState.INSTANCE_STATE_RUNNING,
    ];
    return states[Math.floor(Math.random() * states.length)];
}
exports.instanceState = instanceState;
function awsAccountId() {
    return randomString('0123456789', 12);
}
exports.awsAccountId = awsAccountId;
function awsRegion() {
    const regions = ['us-east-1', 'us-east-2', 'us-west-1', 'us-west-2'];
    return regions[Math.floor(Math.random() * regions.length)];
}
exports.awsRegion = awsRegion;
function awsAvailabilityZone(region) {
    const zones = ['a', 'b', 'c'];
    return `${region}${zones[Math.floor(Math.random() * zones.length)]}`;
}
exports.awsAvailabilityZone = awsAvailabilityZone;
function awsVolumeId() {
    return `vol-${awsResourceId()}`;
}
exports.awsVolumeId = awsVolumeId;
function awsInstanceId() {
    return `i-${awsResourceId()}`;
}
exports.awsInstanceId = awsInstanceId;
function awsAmiId() {
    return `ami-${awsResourceId()}`;
}
exports.awsAmiId = awsAmiId;
function awsSnapshotId() {
    return `snap-${awsResourceId()}`;
}
exports.awsSnapshotId = awsSnapshotId;
function awsCCAssetId(account, region, assetName, type) {
    return `elastio:asset:${type}:s:${account}:${region}:${assetName}`;
}
exports.awsCCAssetId = awsCCAssetId;
function awsAmiName() {
    return `amazon/amzn2-ami-kernel-5.10-hvm-2.${Math.random()}.1-x86_64-gp2`;
}
exports.awsAmiName = awsAmiName;
function awsAmiLocation(name) {
    return `amazon/${name}`;
}
exports.awsAmiLocation = awsAmiLocation;
function awsCost() {
    return Math.floor(Math.random() * 1000000);
}
exports.awsCost = awsCost;
function awsResourceId() {
    return randomString('0123456789abcdef', 17);
}
exports.awsResourceId = awsResourceId;
function tenant() {
    return `dev.${randomString('abcdefghij', 7)}.elastio.us`;
}
exports.tenant = tenant;
function bool() {
    return Math.random() > 0.5;
}
exports.bool = bool;
function randomString(alphabet, length) {
    let randomValue = '';
    const alphabetLength = alphabet.length;
    let i = 0;
    while (i < length) {
        randomValue += alphabet.charAt(Math.floor(Math.random() * alphabetLength));
        i += 1;
    }
    return randomValue;
}
exports.randomString = randomString;
