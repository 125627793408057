"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.S3Item = void 0;
const asset_item_1 = require("../../asset-items/asset-item");
class S3Item extends asset_item_1.AssetItem {
    #selectors;
    constructor(parameters) {
        const { selectors, ...assetItemParameters } = parameters;
        super(assetItemParameters);
        this.#selectors = selectors;
    }
    get selectors() {
        return [...this.#selectors];
    }
}
exports.S3Item = S3Item;
