import LayoutConstant from '@lib/constants/layout.constant'
import { PageMeta } from '@lib/engine-types'
import getConfig from 'next/config'
import SystemHelper from '@lib/helpers/system.helper'

export const MAX_VAULT_NUMBERS = 7
export const ZE_WEB_MESSENGER_KEY = '66417910-4a1c-4658-8a8a-0dc41153f83f'
export const FULL_STORY_ORG_ID = '1666TT'

export const EmptyFunc = () => {}

export const EMPTY_PAGE_META = (): PageMeta => ({
  path: '',
  metaTitle: SystemHelper.getCurrentPageName(''),
  metaDescription: '',
  metaKeywords: '',
  layout: LayoutConstant.Empty,
})

const publicRuntimeConfig = getConfig()?.publicRuntimeConfig
export const BUILD_DATE = publicRuntimeConfig?.BUILD_DATE ?? ''

export enum SelectedVariant {
  AllEC2Variant = 0,
  AllEBSVariant = 1,
  AllEC2EBSVariant = 2,
  AssetsVariant = 3,
  TagsVariant = 4,
  S3Variant = 5,
  EfsVariant = 6,
  AllS3Variant = 7,
}

export enum S3SelectedProtectVariant {
  SpecificAssets = 0,
  AllS3Buckets = 1,
}

export enum TabAssetType {
  Ec2andEbs = 0,
  S3 = 1,
  EFS = 2,
}

export const emptySelectedSpecificPaths = () => {
  return {
    path: [],
    prefix: [],
    glob: [],
  }
}

export const EmptyAllS3SelectedAssets = () => {
  return {
    allSelected: [],
    allSelectedComplex: [
      {
        selectedAsset: '',
        selectedAllPath: false,
        selectedSpecificPath: emptySelectedSpecificPaths(),
      },
    ],
  }
}

export const EmptyAllEfsSelectedAssets = () => {
  return {
    allSelected: [],
    allSelectedComplex: [
      {
        selectedAsset: '',
        selectedAllPath: false,
        selectedSpecificPath: emptySelectedSpecificPaths(),
      },
    ],
  }
}
