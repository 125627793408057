import React from 'react'

function UpgradeRequiredIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.20833 17.5C9.625 17.5 9.99166 17.1917 10.0333 16.775C10.375 13.2167 13.2083 10.3833 16.7667 10.0417C17.1833 10 17.5 9.64167 17.5 9.21667C17.5 8.725 17.075 8.33333 16.5833 8.38333C12.2583 8.81667 8.81666 12.2583 8.38333 16.575C8.33333 17.0667 8.71666 17.5 9.20833 17.5ZM15 17.5H17.5V15C16.1167 15 15 16.1167 15 17.5ZM12.575 17.5C12.9833 17.5 13.325 17.2 13.3917 16.7917C13.6917 15.0583 15.0583 13.6917 16.7917 13.3917C17.2 13.325 17.5 12.9833 17.5 12.575C17.5 12.0667 17.05 11.6667 16.55 11.7417C14.0833 12.1417 12.1417 14.0833 11.7417 16.55C11.6583 17.05 12.0667 17.5 12.575 17.5ZM10.8083 2.51667C10.3917 2.51667 10.025 2.825 9.98333 3.24167C9.64166 6.8 6.80833 9.63333 3.25 9.975C2.83333 10.0167 2.51666 10.375 2.51666 10.8C2.51666 11.2917 2.94166 11.6833 3.43333 11.6333C7.75833 11.2 11.2 7.75833 11.6333 3.44167C11.6917 2.95833 11.3083 2.51667 10.8083 2.51667ZM5.025 2.51667H2.525V5.01667C3.90833 5.01667 5.025 3.9 5.025 2.51667ZM7.45 2.51667C7.04166 2.51667 6.7 2.81667 6.63333 3.225C6.33333 4.95833 4.96666 6.325 3.23333 6.625C2.825 6.7 2.525 7.03333 2.525 7.45C2.525 7.95833 2.975 8.35833 3.475 8.28333C5.94166 7.88333 7.88333 5.94167 8.28333 3.475C8.35833 2.96667 7.95 2.51667 7.45 2.51667Z"
        fill="#FFAB2D"
      />
    </svg>
  )
}

export default UpgradeRequiredIcon
