// eslint-disable-next-line import/no-extraneous-dependencies
import { RecoveryPointModel } from 'blues-corejs/dist'
import { DateRangeTuple } from '@lib/engine-types'

const actionPrefix = 'listRecoveryPointsActionTypes/'
export const REQUEST_ASSET_LIST_RECOVERY_POINTS = `${actionPrefix}REQUEST_ASSET_LIST_RECOVERY_POINTS`
export const SET_ASSET_LIST_RECOVERY_POINTS = `${actionPrefix}SET_ASSET_LIST_RECOVERY_POINTS`

export const RESET_CORE_LIST_RECOVERY_POINTS_STATE = `${actionPrefix}RESET_CORE_LIST_RECOVERY_POINTS_STATE`

export interface RequestAssetListRecoveryPointsAction {
  type: typeof REQUEST_ASSET_LIST_RECOVERY_POINTS
  payload: {
    readonly dateRange: DateRangeTuple
    redStackAssetId: string
  }
}
export interface ResetCoreListRecoveryPointsStateAction {
  type: typeof REQUEST_ASSET_LIST_RECOVERY_POINTS
}
export interface SetAssetListRecoveryPointsAction {
  type: typeof SET_ASSET_LIST_RECOVERY_POINTS
  payload: Array<RecoveryPointModel>
}
export type AssetListRecoveryPointsAction =
  | RequestAssetListRecoveryPointsAction
  | ResetCoreListRecoveryPointsStateAction
  | SetAssetListRecoveryPointsAction
