import { useState } from 'react'
import {
  EngineAsyncCallback,
  EngineCallback,
  EngineCallbackPure,
  Nullable,
} from '@lib/engine-types'
import { useDispatch, useSelector } from 'react-redux'
import { isModalOpened } from '@store/selectors/modal.selector'
import Modal from '@lib/constants/modal.constant'
import { hideModal, showModal } from '@store/actions/modal.action'

// inputModalData - data, which will be available for a modal component
//  on opening
// outputModalData - the result of modal success callback will provide
//  the output data

// "DialogModal" component proxying the input data to the output data, but
//   other modal components can pass other data, different from input

interface UseModalResult<T, P> {
  openModal: EngineCallback<T>
  modalProps: {
    data: Nullable<T>
    show: boolean
    onSubmit: EngineCallback<P>
    onCancel: EngineCallbackPure
  }
}

export function useModal<T, P = T>(
  modalName: Modal,
  onModalSubmit: EngineCallback<P> | EngineAsyncCallback<P>,
  closeOnSubmit = true
): UseModalResult<T, P> {
  const dispatch = useDispatch()
  const show = useSelector(isModalOpened(modalName))

  const [inputModalData, setInputModalData] = useState<Nullable<T>>(null)

  const openModal = (newData: T) => {
    dispatch(showModal(modalName))
    setInputModalData(newData)
  }

  const onSubmitCallback = async (outputModalData: P) => {
    const maybePromise = onModalSubmit(outputModalData)

    if (closeOnSubmit) {
      dispatch(hideModal(modalName))
    }

    if (maybePromise instanceof Promise) {
      await maybePromise
    }
  }

  const onCancelCallback = () => {
    dispatch(hideModal(modalName))
  }

  const modalProps = {
    data: inputModalData,
    show,
    onSubmit: onSubmitCallback,
    onCancel: onCancelCallback,
  }

  return {
    openModal,
    modalProps,
  }
}
