"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EBSSnapshot = void 0;
const aws_backup_1 = require("../aws-backup");
const types_1 = require("./types");
const backup_1 = require("../../backup");
class EBSSnapshot extends aws_backup_1.AwsBackup {
    #awsId;
    #arn;
    #fsCheckStatus;
    #malwareCheckStatus;
    #ransomwareCheckStatus;
    #awsAccountId;
    #awsRegion;
    #description;
    #status;
    #encrypted;
    #publiclyShared;
    #storageTier;
    #size;
    #volumeId;
    #correspondingBackup;
    #jobId;
    #backupPolicyName;
    #deletedAt;
    #threatIds;
    #scanIds;
    constructor(parameters) {
        const { awsId, arn, awsAccountId, awsRegion, description, status, encrypted, publiclyShared, storageTier, size, volumeId, correspondingBackup, jobId, backupPolicyName, deletedAt, threatIds, ransomwareCheckStatus, scanIds, fsCheckStatus, malwareCheckStatus, ...assetParameters } = parameters;
        super(assetParameters);
        this.#fsCheckStatus = fsCheckStatus;
        this.#malwareCheckStatus = malwareCheckStatus;
        this.#ransomwareCheckStatus = ransomwareCheckStatus;
        this.#awsId = awsId;
        this.#arn = arn;
        this.#awsAccountId = awsAccountId;
        this.#awsRegion = awsRegion;
        this.#description = description;
        this.#status = status;
        this.#encrypted = encrypted;
        this.#publiclyShared = publiclyShared;
        this.#storageTier = storageTier;
        this.#size = size;
        this.#volumeId = volumeId;
        this.#correspondingBackup = Object.freeze({ ...correspondingBackup });
        this.#jobId = jobId;
        this.#backupPolicyName = Object.freeze(backupPolicyName);
        this.#deletedAt = deletedAt;
        this.#threatIds = Object.freeze(threatIds);
        this.#scanIds = Object.freeze(scanIds);
    }
    get awsId() {
        return this.#awsId;
    }
    get arn() {
        return this.#arn;
    }
    get awsAccountId() {
        return this.#awsAccountId;
    }
    get awsRegion() {
        return this.#awsRegion;
    }
    get description() {
        return this.#description;
    }
    get status() {
        return this.#status;
    }
    get encrypted() {
        return this.#encrypted;
    }
    get publiclyShared() {
        return this.#publiclyShared;
    }
    get storageTier() {
        return this.#storageTier;
    }
    get size() {
        return this.#size;
    }
    get volumeId() {
        return this.#volumeId;
    }
    get correspondingBackup() {
        return { ...this.#correspondingBackup };
    }
    get jobId() {
        return this.#jobId;
    }
    get backupPolicyName() {
        return [...this.#backupPolicyName];
    }
    get deletedAt() {
        return this.#deletedAt;
    }
    get threatIds() {
        return [...this.#threatIds];
    }
    get scanIds() {
        return [...this.#scanIds];
    }
    get fsCheckStatus() {
        return this.#fsCheckStatus;
    }
    get malwareCheckStatus() {
        return this.#malwareCheckStatus;
    }
    get ransomwareCheckStatus() {
        return this.#ransomwareCheckStatus;
    }
    get name() {
        const TAG_KEY = 'name';
        const tag = this.tags.find((tag) => tag.key.toLowerCase() === TAG_KEY);
        if (!tag) {
            return '';
        }
        return tag.value;
    }
    get summaryCheckStatus() {
        return [
            this.fsCheckStatus,
            this.malwareCheckStatus,
            this.ransomwareCheckStatus,
        ].reduce((acc, curr) => {
            if (curr === types_1.CheckStatus.BAD) {
                return backup_1.SummaryCheckStatus.BAD;
            }
            if (curr === types_1.CheckStatus.GOOD &&
                (acc === backup_1.SummaryCheckStatus.GOOD ||
                    acc === backup_1.SummaryCheckStatus.UNCHECKED)) {
                return backup_1.SummaryCheckStatus.GOOD;
            }
            return acc;
        }, backup_1.SummaryCheckStatus.UNCHECKED);
    }
    get isPubliclyShared() {
        return this.#publiclyShared;
    }
    get isEncrypted() {
        return this.#encrypted;
    }
    get isUnencrypted() {
        return !this.isEncrypted;
    }
    get age() {
        return Date.now() - this.createdAt.getTime();
    }
    get ransomwareIsNotDetected() {
        return this.ransomwareCheckStatus === types_1.CheckStatus.GOOD;
    }
    get malwareIsNotDetected() {
        return this.malwareCheckStatus === types_1.CheckStatus.GOOD;
    }
    get filesystemIsNotCorrupted() {
        return this.fsCheckStatus === types_1.CheckStatus.GOOD;
    }
    get ransomwareIsDetected() {
        return this.ransomwareCheckStatus === types_1.CheckStatus.BAD;
    }
    get malwareIsDetected() {
        return this.malwareCheckStatus === types_1.CheckStatus.BAD;
    }
    get filesystemIsCorrupted() {
        return this.fsCheckStatus === types_1.CheckStatus.BAD;
    }
    get ransomwareIsNotChecked() {
        return this.ransomwareCheckStatus === types_1.CheckStatus.UNCHECKED;
    }
    get malwareIsNotChecked() {
        return this.malwareCheckStatus === types_1.CheckStatus.UNCHECKED;
    }
    get filesystemIsNotChecked() {
        return this.fsCheckStatus === types_1.CheckStatus.UNCHECKED;
    }
    get isMountable() {
        return false;
    }
    get isRestorable() {
        return false;
    }
    get isScannable() {
        return true;
    }
    get isDeleted() {
        return !!this.deletedAt;
    }
}
exports.EBSSnapshot = EBSSnapshot;
