"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackupStatus = exports.Provider = exports.StatusScanFilesystemCheck = exports.CheckStatus = void 0;
var CheckStatus;
(function (CheckStatus) {
    CheckStatus[CheckStatus["NO_FILESYSTEM"] = 0] = "NO_FILESYSTEM";
    CheckStatus[CheckStatus["FILESYSTEM_IS_NOT_SUPPORTED"] = 1] = "FILESYSTEM_IS_NOT_SUPPORTED";
    CheckStatus[CheckStatus["ABORTED"] = 2] = "ABORTED";
    CheckStatus[CheckStatus["FAILED"] = 3] = "FAILED";
    CheckStatus[CheckStatus["NO_ERRORS"] = 4] = "NO_ERRORS";
    CheckStatus[CheckStatus["ERRORS_FIXED"] = 5] = "ERRORS_FIXED";
    CheckStatus[CheckStatus["ERRORS_NOT_FIXED"] = 6] = "ERRORS_NOT_FIXED";
})(CheckStatus = exports.CheckStatus || (exports.CheckStatus = {}));
var StatusScanFilesystemCheck;
(function (StatusScanFilesystemCheck) {
    StatusScanFilesystemCheck[StatusScanFilesystemCheck["GOOD"] = 0] = "GOOD";
    StatusScanFilesystemCheck[StatusScanFilesystemCheck["BAD"] = 1] = "BAD";
})(StatusScanFilesystemCheck = exports.StatusScanFilesystemCheck || (exports.StatusScanFilesystemCheck = {}));
var Provider;
(function (Provider) {
    Provider[Provider["ELASTIO"] = 0] = "ELASTIO";
    Provider[Provider["AWS"] = 1] = "AWS";
    Provider[Provider["OVA_VEEAM"] = 2] = "OVA_VEEAM";
    Provider[Provider["OVA_COMMVAULT"] = 3] = "OVA_COMMVAULT";
    Provider[Provider["OVA_RUBRIK"] = 4] = "OVA_RUBRIK";
    Provider[Provider["OVA_COHESITY"] = 5] = "OVA_COHESITY";
    Provider[Provider["OVA_VERITAS_NET_BACKUP"] = 6] = "OVA_VERITAS_NET_BACKUP";
    Provider[Provider["OVA_AWS_BACKUP_VMWARE"] = 7] = "OVA_AWS_BACKUP_VMWARE";
})(Provider = exports.Provider || (exports.Provider = {}));
var BackupStatus;
(function (BackupStatus) {
    BackupStatus[BackupStatus["GOOD"] = 0] = "GOOD";
    BackupStatus[BackupStatus["BAD"] = 1] = "BAD";
})(BackupStatus = exports.BackupStatus || (exports.BackupStatus = {}));
