type FontSizes = {
  dateSize: number | string
  timeSize: number | string
}

export type Direction = 'row' | 'column'

const DEFAULT_FONT_SIZES: Record<Direction, FontSizes> = {
  row: {
    dateSize: 'inherit',
    timeSize: 'inherit',
  },
  column: {
    dateSize: 14,
    timeSize: 11,
  },
}

export const getFontSizes = (direction: Direction | undefined): FontSizes => {
  return direction ? DEFAULT_FONT_SIZES[direction] : DEFAULT_FONT_SIZES.row
}
