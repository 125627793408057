import ObjHelper from '@lib/helpers/obj.helper'
import ValueInterface from '@lib/interfaces/value.interface'
import ValueHelper from '@lib/helpers/value.helper'
import { Nullable, VIRow } from '@lib/engine-types'
import SystemHelper from '@lib/helpers/system.helper'
import LocalStorageHelper from '@lib/helpers/local-storage.helper'

class DemoServiceInner {
  private configs: VIRow = []

  public modeBodyClasses: Array<string> = []

  public isDevDomain = false

  public isNotDevDomain = true

  private calcDevDomainParams(hostname: string) {
    if (!hostname) {
      return
    }
    this.configs = []
    this.modeBodyClasses = []
    this.isDevDomain = SystemHelper.shouldAddDebugCode(hostname)

    this.isNotDevDomain = !this.isDevDomain
    if (this.isDevDomain) {
      this.configs = LocalStorageHelper.getDemoConfigs()
      this.modeBodyClasses = this.configs
        .filter((elem) => elem.value)
        .map((elem) => `${elem.name}Mode`)
    }
  }

  constructor() {
    this.calcDevDomainParams(globalThis?.location?.hostname)
  }

  public getConfigs() {
    return ObjHelper.cloneDeep(this.configs)
  }

  private changeModeWithReloading(
    mode: ValueInterface,
    modeValue: boolean,
    location: Nullable<string> = null
  ) {
    const value = ValueHelper.updateValue(mode, modeValue)
    const newConfigs = ValueHelper.updateSelectedValue(value, this.configs)
    this.updateConfigsWithReloading(newConfigs, location)
  }

  public turnOnModeWithReloading(
    mode: ValueInterface,
    location: Nullable<string> = null
  ) {
    this.changeModeWithReloading(mode, true, location)
  }

  public turnOffModeWithReloading(
    mode: ValueInterface,
    location: Nullable<string> = null
  ) {
    this.changeModeWithReloading(mode, false, location)
  }

  public updateConfigsWithReloading(
    data: VIRow,
    location: Nullable<string> = null
  ) {
    if (this.isNotDevDomain) {
      return
    }
    LocalStorageHelper.saveDemoConfigs(data)
    if (location) {
      SystemHelper.pureNavigate(location)
    } else {
      document.location.reload()
    }
  }

  public isEnabled(mode: ValueInterface): boolean {
    if (this.isNotDevDomain) {
      return false
    }
    const config = this.configs.find(
      (item: ValueInterface) => item.name === mode.name
    )
    if (!config) {
      return false
    }
    return Boolean(config.value)
  }

  public isEnabledAny(modes?: Array<ValueInterface>): boolean {
    if (this.isNotDevDomain) {
      return false
    }
    let result = false
    modes?.forEach((mode) => {
      if (result) {
        return
      }
      result = this.isEnabled(mode)
    })
    return result
  }
}

const DemoService = new DemoServiceInner()

export default DemoService
