"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalVolume = void 0;
const asset_item_1 = require("../../asset-items/asset-item");
class LocalVolume extends asset_item_1.AssetItem {
    #deviceName;
    constructor(parameters) {
        const { deviceName, ...assetItemParameters } = parameters;
        super(assetItemParameters);
        this.#deviceName = deviceName;
    }
    get deviceName() {
        return this.#deviceName;
    }
}
exports.LocalVolume = LocalVolume;
