import BaseModel from '@lib/models/base-model/base.model'

class AvailabilityZoneModel extends BaseModel {
  public readonly azId: string

  public readonly azName: string

  constructor(readonly params: any = {}) {
    super(params)

    this.azId = params.azId
    this.azName = params.azName
  }
}

export default AvailabilityZoneModel
