import ActionInterface from '@lib/interfaces/action.interface'
import { SET_POLICIES_RETENTION } from '@store/actions/policies-retention.action'
import PoliciesRetentionState from '@store/states/policies-retention.state'

const initialState: PoliciesRetentionState = {
  policiesRetention: [],
  policiesRetentionCount: 0,
}

function policiesRetentionReducer(
  prevState: PoliciesRetentionState = initialState,
  action: ActionInterface
): PoliciesRetentionState {
  switch (action.type) {
    case SET_POLICIES_RETENTION:
      return {
        ...prevState,
        policiesRetention: action.payload.list,
        policiesRetentionCount: action.payload.total,
      }

    default:
      return prevState
  }
}

export default policiesRetentionReducer
