import { useCallback } from 'react'

import { ScheduleCcJobUseCase } from '@features/scheduled-jobs-monitoring/use-cases'
import { ScheduledCcJobsClient } from '@features/scheduled-jobs-monitoring/clients'
import { ScheduledCcJobsRepository } from '@features/scheduled-jobs-monitoring/repositories'
import { createLogger } from '@features/scheduled-jobs-monitoring/infrastructure/logging'
import ToastHelper from '@lib/helpers/toast.helper'

const scheduledCcJobUseCase = new ScheduleCcJobUseCase(
  new ScheduledCcJobsRepository(createLogger()),
  new ScheduledCcJobsClient(),
  ToastHelper
)

export const useScheduleCcJob = () => {
  const scheduleCcJob = useCallback(async (jobId: string) => {
    if (!jobId) {
      return
    }

    try {
      await scheduledCcJobUseCase.execute(jobId)
    } catch (error) {
      console.error('Failed to execute scheduled cc job:', error)
    }
  }, [])

  return {
    scheduleCcJob,
  }
}
