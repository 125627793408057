import { AbstractListRequest } from '@lib/clients/list-request'
import {
  GetJobRequest,
  ListCloudConnectorJobsRequest,
  Timestamp,
} from '@lib/clients/grpc-imports'
import {
  ScheduledTenantJobKind,
  ScheduledTenantJobStatus,
} from '@features/scheduled-jobs-monitoring/models'

export interface TimeRange {
  start?: number
  end?: number
}

export interface ScheduledTenantJobsFilterParams {
  pageSize: number
  kindsList: Array<ScheduledTenantJobKind>
  statusesList: Array<ScheduledTenantJobStatus>
  ccIdsList: Array<string>
  jobIdsList: Array<string>
  timeRange?: TimeRange
}

export class ScheduledTenantJobFilter extends AbstractListRequest<GetJobRequest> {
  #jobId: string

  constructor(jobId: string) {
    super()
    this.#jobId = jobId
  }

  createGrpcRequest(): GetJobRequest {
    return new GetJobRequest().setJobId(this.jobId)
  }

  get jobId(): string {
    return this.#jobId
  }
}

export class ScheduledCcJobsFilter extends AbstractListRequest<ListCloudConnectorJobsRequest> {
  #requestParams: Partial<ScheduledTenantJobsFilterParams>

  constructor(requestParams: Partial<ScheduledTenantJobsFilterParams>) {
    super()
    this.#requestParams = requestParams
  }

  #setupTimeRange({ start, end }: { start: number; end: number }) {
    const timeRange =
      new ListCloudConnectorJobsRequest.Initial.Filter.TimeRange()
    if (start) {
      timeRange.setStart(this.#createTimestamp(start))
    }

    if (end) {
      timeRange.setEnd(this.#createTimestamp(end))
    }
    return timeRange
  }

  #createTimestamp(seconds: number) {
    const timestamp = new Timestamp()
    timestamp.setSeconds(Math.round(seconds / 1000))
    return timestamp
  }

  createGrpcRequest(): ListCloudConnectorJobsRequest {
    const filter = new ListCloudConnectorJobsRequest.Initial.Filter()

    const { jobIdsList, timeRange } = this.#requestParams

    if (jobIdsList && jobIdsList.length) {
      filter.setJobIdsList(jobIdsList)
    }

    filter.setTimeRange(
      this.#setupTimeRange({
        start: timeRange?.start as number,
        end: timeRange?.end as number,
      })
    )

    return new ListCloudConnectorJobsRequest().setInitial(
      new ListCloudConnectorJobsRequest.Initial()
        .setFilter(filter)
        .setPageSize(
          this.#requestParams.pageSize || AbstractListRequest.DEFAULT_PAGE_SIZE
        )
    )
  }
}
