import GrpcBaseService from '@lib/services/grpc/base/grpc-base.service'
import { PersonalAccessTokensPromiseClient } from 'blue-stack-libs/users-grpc-libs/js/users/users_grpc_web_pb'
import AccessTokenModel from '@lib/models/access-token.model'
import {
  IssueRequest,
  ListRequest,
  RevokeRequest,
  TokenDetailsByIdRequest,
  UpdateRequest,
} from 'blue-stack-libs/users-grpc-libs/js/users/messages/pat_pb'
import AccessFactory from '@lib/factories/access.factory'
import TokenIssueResultInterface from '@lib/interfaces/token-issue-result.interface'
import SystemHelper from '@lib/helpers/system.helper'

class GrpcPatService extends GrpcBaseService {
  protected static client = new PersonalAccessTokensPromiseClient(
    '',
    null,
    null
  )
  protected static clientName = 'PersonalAccessToken'

  public static async list(): Promise<Array<AccessTokenModel>> {
    const request = new ListRequest()
    const result = await this.handleQueryRetry(this.client.list, request)
    return result.toObject().tokensDetailsList.map(AccessFactory.buildFromGRPC)
  }

  public static async listRevoked(): Promise<Array<AccessTokenModel>> {
    const request = new ListRequest()
    const result = await this.handleQueryRetry(this.client.listRevoked, request)
    return result.toObject().tokensDetailsList.map(AccessFactory.buildFromGRPC)
  }

  public static async getTokenDetailsById(
    id: string
  ): Promise<AccessTokenModel> {
    const request = new TokenDetailsByIdRequest()
    request.setTokenId(id)
    const result = await this.handleQueryRetry(
      this.client.getTokenDetailsById,
      request
    )
    return AccessFactory.buildFromGRPC(result.toObject())
  }

  public static async issue(
    tokenName: string,
    scopes: Array<string>
  ): Promise<TokenIssueResultInterface> {
    const request = new IssueRequest()
    request.setName(tokenName)
    request.setScopesList(scopes)
    const result = await this.handleQuery(this.client.issue, request)
    const resultObj = result.toObject()
    if (!resultObj || !resultObj.details) {
      SystemHelper.throwErrorInLocalEnv('Token Issue error')
      return {
        secretToken: '',
        generatedToken: AccessFactory.buildEmpty(),
      }
    }
    return {
      secretToken: resultObj.token,
      generatedToken: AccessFactory.buildFromGRPC(resultObj.details),
    }
  }

  public static async revoke(tokens: Array<string>): Promise<any> {
    const request = new RevokeRequest()
    request.setTokensIdsList(tokens)
    const result = await this.handleQuery(this.client.revoke, request)
    return result.toObject()
  }

  public static async update(
    tokenId: string,
    tokenName: string,
    scopes: Array<string>
  ): Promise<any> {
    const request = new UpdateRequest()
    request.setTokenId(tokenId)
    request.setName(tokenName)
    request.setScopesList(scopes)
    const result = await this.handleQuery(this.client.update, request)
    return result.toObject()
  }
}

export default GrpcPatService
