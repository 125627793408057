import TimeHelper from '@lib/helpers/time.helper'
import { DateTimeDisplayFormats } from '@lib/constants/date-time-format'

import { DateOrTimestamp, FormattedDateTime } from './types'
import { TimeZoneOption } from '@lib/constants/data/time/types'

export const defaultDateTimeFormatter = (
  date: DateOrTimestamp,
  tz: TimeZoneOption
): FormattedDateTime => {
  try {
    const formattedDate = TimeHelper.formatWithTimeZone(
      date,
      DateTimeDisplayFormats.DATE,
      tz
    )
    const formattedTime = TimeHelper.formatWithTimeZone(
      date,
      DateTimeDisplayFormats.TIME,
      tz
    )

    if (!TimeHelper.isValidDateTime(formattedDate, formattedTime)) {
      throw new Error('Invalid date or time')
    }

    return {
      date: formattedDate,
      time: formattedTime,
    }
  } catch (error) {
    console.error('Error in formatting date/time:', error)
    return {
      date: 'Invalid Date',
      time: 'Invalid Time',
    }
  }
}
