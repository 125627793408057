// eslint-disable-next-line max-len
import UserRoleModel from '@lib/models/user-role.model'
import { RowSimpleUnit } from '@lib/engine-types'

// eslint-disable-next-line max-len
export const getDefaultRolesNames = (state: any): Array<string> =>
  state.managementRolesState.defaultRoles.map((r: UserRoleModel) => r.name)
// eslint-disable-next-line max-len
export const getCustomRolesNames = (state: any): Array<string> =>
  state.managementRolesState.customRoles.map((r: UserRoleModel) => r.name)
// eslint-disable-next-line max-len
export const getScopesCategories = (state: any): Array<string> =>
  state.managementRolesState.scopesCategories
// eslint-disable-next-line max-len
export const getDefaultRolesTableData = (state: any): Array<RowSimpleUnit> =>
  state.managementRolesState.defaultRolesTableData
// eslint-disable-next-line max-len
export const getCustomRolesTableData = (state: any): Array<RowSimpleUnit> =>
  state.managementRolesState.customRolesTableData
// eslint-disable-next-line max-len
export const getCustomRoles = (state: any): Array<UserRoleModel> =>
  state.managementRolesState.customRoles
// eslint-disable-next-line max-len
export const getEditColumnIndex = (state: any): number =>
  state.managementRolesState.editColumnIndex
export const getRoleForEdit = (state: any): UserRoleModel =>
  state.managementRolesState.roleForEdit
// eslint-disable-next-line max-len
export const canRoleForEditBeRemoved = (state: any): boolean =>
  state.managementRolesState.roleForEdit &&
  state.managementRolesState.roleForEdit.innerId
// eslint-disable-next-line max-len
export const canRoleForEditBeSubmitted = (state: any): boolean =>
  state.managementRolesState.roleForEdit &&
  state.managementRolesState.roleForEdit.scopesList.length > 0
